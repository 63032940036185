<div class="modal-header">
    <h3>Create a Role</h3>
</div>

<div class="modal-body form-horizontal">

    <div class="form-group">
        <div class="col-xs-3">
            <label for="" class="control-label">Role name</label>
        </div>

        <div class="col-xs-4">
            <input type="text" autofocus data-id="name" class="form-control">
        </div>
    </div>

</div>

<div class="modal-footer">
    <button class="btn btn-success" data-action="save">
        <i class="fa fa-floppy-o"></i>
        Create
    </button>
</div>