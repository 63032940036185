'use strict';

var CopyButton = React.createClass({

    componentDidMount () {
        var zc = new ZeroClipboard(this.getDOMNode());
        zc.on( 'ready', function( readyEvent ) {
            zc.on( 'aftercopy', function( event ) {
                window.toastr.info('Copied to clipboard!', null, { 'positionClass': 'toast-top-right' });
            } );
        } );
    },

    render () {
        return React.addons.cloneWithProps(this.props.children, {
            'data-clipboard-text': this.props.text,
            'title': 'Click to copy content'
        });
    }
});

module.exports = CopyButton;