'use strict';

var layoutTemplate = require('./templates/layout');
var infoTemplate = require('./templates/info');
var app = require('scripts/core').app;

/**
 * Create a fill graph for ad or campaign
 *
 * It display accumulative count of impressions/clicks over time
 * A target line is drawn as a indication of the expected progress
 *
 * Progress that fall short of target for more than 10% will have the graph rendered in red
 * Progress that fall short of target but less than 10% will have the graph rendered in yellow
 * Otherwise, it will be in green
 *
 * @class
 *
 */

var FillGraph = Mario.Layout.extend({
    className: 'fill-graph',

    template: layoutTemplate,

    onShow: function () {
        var padding = {
            top: 30, left: 30, right: 30, bottom: 50
        };

        var fullWidth = this.$el.width();
        var fullHeight = this.$el.height();

        Object.defineProperties(this, {

            'padding': {
                value: padding
            },

            'width': {
                get: function () {
                    return this.$el.width() - padding.left - padding.right;
                }
            },
            'height': {
                get: function () {
                    return fullHeight - padding.top - padding.bottom;
                }
            },
            'graph': {
                get: function () {
                    var node = this.$('.graph').get(0);
                    var graph = d3.select(node);

                    graph.style({
                        'background-color': '#eee',
                        'width': fullWidth,
                        'height': fullHeight
                    });

                    return graph.select('g.inner').attr('transform', 'translate(' + padding.left + ', ' + padding.top + ')');
                }
            },

            'barWidth': {
                get: function () {
                    return Math.min(this.width / this.getIntervalCount(this.options.start, this.options.end) * 0.8, 80);
                }
            },

            'infoPaneWidth': {
                value: 200,
                writable: false
            },

            'infoPaneHeight': {
                value: 100,
                writable: false
            }
        })
    },

    /**
     * Set value of this graph
     * @param {Stat[]} raw          raw data stream to display
     * @param {timestamp} start     start date timestamp of ad/campaign
     * @param {timestamp} end       end date timestamp of ad/campaign
     * @param {number} targetCount  target number of impression/clicks, this is the Y axis
     * @param {string} type         accessor for getting key attribute from data. It doesn't support mixed for now.
     * @param {string} status       performance status of the ad
     */
    setValues: function (raw, start, end, targetCount, type, status) {

        _.defaults(this.options, {
            raw: raw,
            start: start,
            end: end,
            targetCount: targetCount,
            type: type,
            status: status
        });

        if (_.isEmpty(this.options.raw) || type === 'mixed') {
            return;
        }

        this.data = processData(this.options.raw, this.options.start, this.options.end);

        this.renderGraph();
    },

    renderGraph: function () {

        this.$el.off();

        this.initAxis();

        this.drawAxis();

        this.drawBars();

        this.drawLines();

        this.drawTargetLine();

        this.drawMouseLine();
    },

    initAxis: function () {
        var startTime = moment.utc(this.options.start).startOf('day').add(-12, 'hours').toDate();
        var endTime = moment.utc(this.options.end).endOf('day').add(12, 'hours').toDate();
        var targetCount = this.options.targetCount;

        this.xScale = d3.time.scale.utc().domain([startTime, endTime]).range([0, this.width]);
        this.yScale = d3.scale.linear().domain([targetCount, 0]).range([0, this.height]);
    },

    drawAxis: function () {
        var tickCount = Math.min(this.getIntervalCount(this.options.start, this.options.end), 20);

        var xAxis = d3.svg.axis()
            .scale(this.xScale)
            .orient('botttom')
            .ticks(tickCount)
            .tickFormat(function (d, i) {
                var date = d.getUTCDate();
                if (date === 1 || i == 0) {
                    var format = d3.time.format.utc('%b');
                    return format(d) + ' ' + date;
                } else {
                    return date;
                }
            });

        var that = this;
        var ticks = _.filter(this.xScale.ticks(tickCount), function (v, i) {return i % 2 == 0;});
        this.graph.append('g').classed('guides', true).selectAll('path.guide')
            .data(ticks).enter()
            .append('path').classed('guide', true)
            .attr('d', function (d) {
                return d3.svg.line()([[that.xScale(d), 0], [that.xScale(d), that.height]]) ;
            });

        this.graph.append('g')
            .attr('class', 'x axis')
            .attr('transform', 'translate(0,' + (this.height ) + ')')
            .call(xAxis);
    },

    getIntervalCount: function (startTime, endTime, interval) {
        interval = interval || 3600 * 24 * 1000; // default to 1 day
        return Math.ceil((moment.utc(endTime) - moment.utc(startTime)) / interval);
    },

    drawBars: function () {
        var that = this;
        var rect = d3.svg.area().y0(this.height);

        this.graph.append('g').classed('bars ' + this.options.status, true).selectAll('path').data(this.data).enter()
            .append('path')
            .attr('data-id', function (d) {
                return moment.utc(d.timestamp).format('YYYY-MM-DD');
            })
            .attr('d', function (d) {
                var x = that.xScale(d.timestamp);
                var y = that.yScale(d[that.options.type]);
                return rect([[x - that.barWidth / 2, y], [x + that.barWidth / 2, y]]);
            });
    },

    drawLines: function () {
        var lineData = this.data;
        var today = moment.utc().add(1, 'day').startOf('day').valueOf();
        var idx = _.findIndex(this.data, {timestamp: today});
        if (idx !== -1) {
            lineData = this.data.slice(0, idx);
        }
        var data = _.map(lineData, function (d) {
            return [this.xScale(moment.utc(d.timestamp).toDate()) , this.yScale(d[this.options.type])];
        }, this);

        var line = d3.svg.line()(data);
        this.graph.append('path').classed('line', true).attr('d', line);
    },

    drawTargetLine: function () {
        var that = this;
        this.graph.append('g').classed('target-line', true).append('path')
            .attr({
                'stroke-dasharray':'5,5',
                'd': function (d) {
                    return d3.svg.line()([
                        [that.xScale(moment.utc(that.options.start)), that.yScale(0)],
                        [that.xScale(moment.utc(that.options.end)), that.yScale(that.options.targetCount)]
                    ])
                }
            });
    },

    getExpected: function (d) {
        var start = moment.utc(this.options.start);
        var end = moment.utc(this.options.end);
        var s = moment.utc(d.timestamp).endOf('day');

        var percent = (s - start) / (end - start);
        percent = Math.min(1, Math.max(0, percent));

        return this.options.targetCount * percent;
    },

    drawMouseLine: function () {
        var points = [[0, this.yScale(0)], [0, this.yScale(this.options.targetCount)]]
        var line = d3.svg.line()(points);

        var mouseLine = this.graph.append('g').classed('mouse-line', true);

        var infoPane = mouseLine.append('g').classed('info', true);
        infoPane.append('rect').attr({width: this.infoPaneWidth, height: this.infoPaneHeight, opacity: 0});
        infoPane.append('text').classed('dates', true).attr({'text-anchor': 'start', y: 30, x: 20});
        infoPane.append('text').classed('expected', true).attr({'text-anchor': 'start', y: 60, x: 20});
        infoPane.append('text').classed('actual', true).attr({'text-anchor': 'start', y: 80, x: 20});
        mouseLine.append('path').attr({'d': line, 'stroke-dasharray': '3,3'});

        var that = this;
        var updateText = function (d) {
            var dateStr = moment.utc(d.timestamp).format('MMM.DD.YYYY');
            var expected = that.getExpected(d);
            var actual = d[that.options.type];
            infoPane.select('rect').attr('opacity', 0.8);
            infoPane.select('text.dates').text(dateStr);
            infoPane.select('text.expected').text('Expected : ' + numeral(expected).format('0,0'));
            infoPane.select('text.actual').text('Actual : ' + numeral(actual).format('0,0') + '(' + numeral(actual / expected).format('0.00%') + ')');
        };

        d3.select(this.el).on('mousemove', function () {
            var coord = d3.mouse(this);
            var x = coord[0] - that.padding.left;

            if (x < 0 || x > that.width) {
                return;
            }

            mouseLine.attr('transform', 'translate(' + x + ', 0)');

            if (x + that.infoPaneWidth > that.width && !mouseLine.select('g.info').classed('left')) {
                mouseLine.select('g.info').classed('left', true).transition().attr('transform', 'translate( -' + that.infoPaneWidth + ', 0)');
            }

            if (x < that.infoPaneWidth && mouseLine.select('g.info').classed('left')) {
                mouseLine.select('g.info').classed('left', false).transition().attr('transform', 'translate( 0, 0)');
            }

            var xStamp = that.xScale.invert(x - that.barWidth / 2);

            var datum = _.find(that.data, function (d) {
                return moment(d.timestamp).startOf('day').isSame(moment(xStamp).startOf('day'));
            });
            if (datum) {
                updateText(datum);
                that.graph.select('g.bars path.active').classed('active', false);
                that.graph.select('g.bars [data-id="' + moment.utc(datum.timestamp).format('YYYY-MM-DD') + '"]').classed('active', true);
            }
        }).on('click', function () {
            var date = that.$('g.bars path.active').data('id');
            that.trigger('clicked-date', date);
            that.updateIndicator(date);
        });

        this.$el.on('mouseout', function () {
            mouseLine.attr('transform', 'translate(-1000, 0)');
        });
    },

    updateIndicator: function (d) {
        var timestamp = moment.utc(d).valueOf();
        var pos = this.xScale(timestamp);
        var w = 20, h = 10;
        var triangle = d3.svg.line()([[0, 0], [0 + w / 2, h], [0 - w / 2, h]]);

        var indicator = this.graph.select('g.selected-indicator');

        if (indicator.empty()) {
            indicator = this.graph.append('g').classed('selected-indicator', true)
                .attr('transform', 'translate(0, ' + ( this.height  + this.padding.bottom - h) + ' )');

            indicator.append('path').attr('d', triangle).style('fill', '#003366');
        }
        indicator.select('path').transition().attr('transform', 'translate(' + pos +', 0)');
    }
});

function processData(raw, start, end) {

    var start = moment.utc(start).startOf('day'),
        end = moment.utc(end).endOf('day'), i,
        now = moment.utc().endOf('day');

    var initial = {
        impressions: 0,
        clicks: 0
    };
    var accumulatedData = [];

    for (i = start.clone(); i < end; i.add(1, 'day')) {

        if (i > now) {
            accumulatedData.push({timestamp: i.valueOf(), impressions: 0, clicks: 0});
            continue;
        }

        var dateStr = i.startOf('day').format('YYYY-MM-DD');
        initial.timestamp = i.valueOf();
        // accumulate all data in raw on the date "dateStr"
        var dateData = _.reduce(raw, function (acc, d) {
            if (d.date === dateStr) {
                acc.impressions += d.impressions;
                acc.clicks += d.clicks;
            }
            return acc;
        }, initial);

        accumulatedData.push(_.clone(initial));
    }

    return accumulatedData;
}


module.exports = FillGraph;
