'use strict';

var DataTable = Mario.Behavior.extend({

    onShow: function () {

        var that = this.view;

        that.on('add:child', function (view) {
            that.$('table').DataTable().row.add(view.$el).draw();
        });

        that.on('remove:child', function (view) {
            that.$('table').DataTable().row(view.el).remove().draw();
        });

        that.on('before:destroy', function () {
            var api = that.$('table').DataTable();
            api.destroy();
        });
    }
});


window.Behaviors.DataTable = DataTable;
