'use strict';

var flux = require('scripts/core/flux');
var http = require('scripts/core/http');


var actions = {
    fetchProfile: function () {
        var self = this;

        http.get('user')
            .then(function (userProfile) {
                self.dispatch(
                    flux.constants.userProfile_fetch_success,
                    userProfile
                );

                return http.get('organizations/' + userProfile.organization);
            })
            .then(function (organizationProfile) {
                self.dispatch(
                    flux.constants.organizationProfile_fetch_success,
                    organizationProfile
                );
            });
    }
};

flux.addActions(actions);