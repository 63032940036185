'use strict';
var sidebarTemplate = require('./templates/sidebar');
var Profile = require('./profile');
var DDMenu = React.createFactory(require('scripts/widgets/dropdown-menu'));
var TabList = React.createFactory(require('scripts/widgets/tab-list'));

module.exports = Mario.Layout.extend({
    events: {
        'click [data-action=show-profile]' : 'showProfile',
    },

    template: sidebarTemplate,

    addMenuCount: function (count) {
        var counter = $('<span class="label label-info">' + count + '</span>');
        this._lastAddedItem.append(counter);
        return counter;
    },

    onRender: function () {

        var that = this;
        Backbone.history.on('route', function () {
            that.renderMenuItems(that.options.components);
        });

        var pages = globalCh.request('pages');
        this.renderTabList(pages);
        this.listenTo(pages, 'destroy change add remove', function () {that.renderTabList(pages);});
    },

    renderTabList: function (pages) {
        var Workspace = require('scripts/layouts/workspace');
        var tabItems = pages.filter(function (p) {
            return p.get('type') === Workspace.PAGE_TYPES.TAB_PAGE;
        });

        React.render(TabList({tabs: tabItems}), this.$('.tab-list-region').get(0));
    },

    renderMenuItems: function (components) {
        var needMenu = function (c) {return c.get('add_to_menu') === true;};
        var selected = 0;

        var items = components.filter(needMenu).map(function (c, idx) {
            if (c.get('matcher').test(window.location.pathname) === true) {
                selected = idx;
            }
            return _.extend({}, c.get('menu_config'), { url: c.get('url')}, { enable: true });
        });
        React.render(DDMenu({menuItems: items, selected: selected}), this.$('.master-menu-region').get(0));
    }
});
