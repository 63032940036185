'use strict';

// This component is used only by'signin.html'.

var region = new Mario.Region({el: '.form'});

var router = new Backbone.Router();

router.route(/.*/, function () {
    Backbone.history.navigate('/signin', {replace: true});
    var SigninView = require('scripts/components/auth/parts/signin');
    region.show(new SigninView());
});

router.route('signin/forgot', function () {
    var ForgotView = require('scripts/components/auth/parts/forgot');
    region.show(new ForgotView());
});

router.route('signin/activation/:activationToken', function (activationToken) {
    var ActivationView = require('scripts/components/auth/parts/activation');

    var req = $.ajax({
        type: 'get',
        url: 'activation/' + activationToken
    });

    req.then(function (data) {

        region.show(new ActivationView({
            activationToken: activationToken,
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name
        }))
        ;
    }, function (e) {
        // var resData = e.responseJSON;
        // if (e.status === 404) {
        //     toastr.error(resData.join('\n'));
        // }
    });


});


router.route('signin/reset/:key', function (key) {
    var ForgotView = require('scripts/components/auth/parts/reset');
    region.show(new ForgotView({resetToken: key}));
});

Backbone.history.start({pushState: true});
