'use strict';

var STORE_ID = 'ef-pages';
var PAGE_TYPES = {
    TAB_PAGE: 'tab page',
    NORMAL_PAGE: 'normal page'
};
var parseJSONArray = function (str) {
    try {
        return JSON.parse(str);
    } catch (e) {
        return [];
    }
};

var serializeArray = function (arr) {
    if (!_.isArray(arr)) {
        return '[]';
    }
    else {
        try {
            return JSON.stringify(arr);
        } catch (e) {
            return '[]';
        }
    }
};

// each page should be identified by main url
var PageObject = Backbone.Model.extend({
    defaults: {
        main_url: '',
        sub_url: '',
        type: PAGE_TYPES.TAB_PAGE,
        active: false,
        last_access: 0,
        resource_name: '',
        resource_id: '',
        tab_visible: true
    },

    toJSON: function () {
        var ext = {
            url: this.getUrl()
        };
        return _.extend(Backbone.Model.prototype.toJSON.apply(this), ext);
    },

    getUrl: function () {
        return _.compact([this.get('main_url'), this.get('sub_url')]).join('/');
    },

    idAttribute: 'main_url'
});

var PageCollection = window.PageCollection = Backbone.Collection.extend({
    model: PageObject,
    initialize: function () {
        this.fetch({reset: true});

        // tracking
        if (typeof uTracking !== undefined) {
            this.on('add reset remove', function () {
                uTracking('send', 'state', {tabCount : this.getTabPages().length});
            }, this);
        }
    },
    getTabPages: function () {
        return this.filter(function (p) {
            return p.get('type') === PAGE_TYPES.TAB_PAGE && p.get('tab_visible');
        });
    },
    localStorage: new Backbone.LocalStorage(STORE_ID),
    comparator: 'last_access'
});

var PageView = Mario.Layout.extend({
    template: _.template('<div class="page-container"></div>'),
    className: 'page',
    _hasInit: false,

    regions: {
        container: '.page-container'
    },

    initialize: function () {
        this.ch = Backbone.Radio.channel('page-' + this.cid);
    },

    /**
     * @function initViewFn
     * @param {Channel} ch - channel of the page that should be referenced
     * by every component in the page
     * @return BackboneView
     */
    /**
     * @function updateView
     * @param {BackboneView} view - reference to the existing view
     */

    /**
     * @param {initViewFn} initView
     * @param {updateViewFn} updateView
     * @return undefined
     */
    show: function (initView, updateView) {
        if (!this._hasInit ) {
            if (_.isFunction (initView)) {
                var pageContentView = initView(this.ch);
                this.container.show(pageContentView);
                this.linkToContent(pageContentView);
                this._hasInit = true;
            }
        } else if (_.isFunction(updateView)) {
            updateView(this.container.currentView);
        }
        this.ch.trigger('shown');
    },

    linkToContent: function (content) {

        this.listenTo(this.ch, 'update-page-meta', function (obj) {
            this.model.save(obj);
        });

        this.listenTo(content, 'destroy-page', function () {
            this.model.destroy();
        });
    },

    modelEvents: {
        'change:active': 'toggleVisibility',
        'destroy': 'destroy'
    },

    toggleVisibility: function (m, active) {
        if (active === true) {
            this.$el.show();
        } else {
            this.$el.hide();
        }
    },

    onRender: function () {
        // hide by default
        this.$el.hide();
        this.$el.attr('data-url', this.model.get('main_url'));
    }
});


/**
 * workspace manager
 * It manages an unique array of page objects
 *
 * It shows one page at a time
 */
var pages = new PageCollection();

globalCh.reply('pages', function () {
    return pages;
});

var Manager = Mario.CollectionView.extend({
    childView: PageView,
    className: 'workspace-container',
    initialize: function () {
        this.collection = pages;
        globalCh.reply('available-page', function (options) {
            return this.getPageFor(options);
        }, this);

    },

    /**
     * Return a page view *
     */
    getPageFor: function (options) {
        var main_url = options.main_url;
        var sub_url = options.sub_url;
        var page = this.collection.get(main_url);
        var pageType = /^\/campaigns/.test(main_url)? PAGE_TYPES.TAB_PAGE : PAGE_TYPES.NORMAL_PAGE;

        if (!page) {
            this.collection.create(_.extend(options, {type: pageType}), {sort: false});
            page = this.collection.get(main_url);
        }

        if (!_.isEmpty(sub_url)) {
            page.save({sub_url: sub_url});
        }
        this.activatePage(page);
        return this.children.findByModel(page);
    },

    collectionEvents: {
        'change:tab_visible': 'activateNextTabPage'
    },

    activateNextTabPage: function (m, visibility) {
        if (visibility === false && m.get('type') === PAGE_TYPES.TAB_PAGE) {
            var visibleTabs = this.collection.filter(function (t) {
                return t.get('tab_visible') === true && t.get('type') === PAGE_TYPES.TAB_PAGE;
            });

            if (visibleTabs.length === 0) {
                Backbone.history.navigate('/campaigns', {trigger: true});
            } else {
                var sorted = _.sortBy(visibleTabs, function (t) {return t.get('last_access');});
                Backbone.history.navigate(_.last(sorted).getUrl(), {trigger: true});
            }
        }
    },

    activatePage: function (page) {
        this.collection.each(function (p) {
            if (p.get('active') === true) {
                p.set('active', false);
            }
        });

        page.save({'active': true, 'tab_visible': true, 'last_access': Date.now()});
    }
});

Manager.PAGE_TYPES = PAGE_TYPES;
Manager.pages = pages;

module.exports = Manager;
