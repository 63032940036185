'use strict';

var app          = require('scripts/core').app;
var formTemplate = require('./templates/form');
var helpers      = require('scripts/common/helpers');
var Form         = require('scripts/common/mixins/form');
var Role         = require('scripts/entities/collections/roles').Role;

/**
 * options
 * roles<Collection<Role>>
 */

module.exports = Mario.ItemView.extend({
    tagName: 'fieldset',
    className: 'form-horizontal role-form',
    template: formTemplate,
    initialize: function (options) {
        this.options = options;
        this.model = new Role(null, {urlRoot: this.collection.url});
    },

    bindings: {
        '[data-id=name]': 'name'
    },

    onShow: function () {
        this.stickit();
        Backbone.Validation.bind(this);
    },

    onDestroy: function () {
        Backbone.Validation.unbind(this);
    },

    onCancel: function () {
        globalCh.trigger('popup:close');
    },

    onSave: function () {
        var that = this;
        var attrs = helpers.stickitGetValues.call(this, ['name']);
        this.model.save(attrs, {
            success: function (m) {
                that.collection.set([m], {remove: false});
                toastr.success('Role is created successfully.');
                globalCh.trigger('popup:close');
            }
        });
    },

    behaviors: {
        Form: {
            autoAddCancelButton: true,
            enterToSubmit: true
        }
        
    }
});
