'use strict';

var CREATIVE_FORMATS = ['standard', 'video', 'mraid', 'custom_html', 'native'];
var MARGIN_TYPES = ['fixed', 'percentage', 'flat'];

var Organization = Mario.Model.extend({
    modelName: 'Organization',
    initialize: function () {
        // permitted actions
        // applicable for user's organization only
        this._actions = [];
    },

    urlRoot: 'organizations/',

    /**
     * NOTE:
     * Auto_approve denotes the auditing relationship between children and parent
     * It can only be set by parent
     *
     * If true in children, any audit coming from children will be auto-approved
     * If false in children, any audit coming from children will require manual approval
     *
     */

    defaults: {
        name: '',
        default_max_cpm_rate: 0,
        currency: 'USD',
        credit_limit: -1,
        auditors: [],
        auditees: [],
        _relationship: 'child',
        creatives: {
            standard : {
                allow_create: false,
                margin_type: 'fixed',
                margin_rate: 0,
                rates: [
                    { size: '300x50',   default_max_bid_cpm: 0, rate: 0},
                    { size: '320x50',   default_max_bid_cpm: 0, rate: 0 },
                    { size: '728x90',   default_max_bid_cpm: 0, rate: 0 },
                    { size: '300x250',  default_max_bid_cpm: 0, rate: 0 },
                    { size: '320x480',  default_max_bid_cpm: 0, rate: 0 },
                    { size: '480x320',  default_max_bid_cpm: 0, rate: 0 },
                    { size: '768x1024', default_max_bid_cpm: 0, rate: 0 },
                    { size: '1024x768', default_max_bid_cpm: 0, rate: 0 }
                ],
                auto_approve: false
            },
            mraid: {
                allow_create: false,
                margin_type: 'fixed',
                margin_rate: 0,
                rates: [
                    { size: '300x50',   default_max_bid_cpm: 0, rate: 0},
                    { size: '320x50',   default_max_bid_cpm: 0, rate: 0 },
                    { size: '728x90',   default_max_bid_cpm: 0, rate: 0 },
                    { size: '300x250',  default_max_bid_cpm: 0, rate: 0 },
                    { size: '320x480',  default_max_bid_cpm: 0, rate: 0 },
                    { size: '480x320',  default_max_bid_cpm: 0, rate: 0 },
                    { size: '768x1024', default_max_bid_cpm: 0, rate: 0 },
                    { size: '1024x768', default_max_bid_cpm: 0, rate: 0 }
                ],
                auto_approve: false
            },
            video: {
                allow_create: false,
                margin_type: 'fixed',
                margin_rate: 0,
                rates: [
                    { size: '320x480',  default_max_bid_cpm: 0, rate: 0 },
                    { size: '480x320',  default_max_bid_cpm: 0, rate: 0 },
                    { size: '768x1024', default_max_bid_cpm: 0, rate: 0 },
                    { size: '1024x768', default_max_bid_cpm: 0, rate: 0 }
                ],
                auto_approve: false
            },
            custom_html: {
                allow_create: false,
                margin_rate: 0,
                margin_type: 'fixed',
                rates: [
                    { size: '300x50',   default_max_bid_cpm: 0, rate: 0 },
                    { size: '320x50',   default_max_bid_cpm: 0, rate: 0 },
                    { size: '728x90',   default_max_bid_cpm: 0, rate: 0 },
                    { size: '300x250',  default_max_bid_cpm: 0, rate: 0 },
                    { size: '320x480',  default_max_bid_cpm: 0, rate: 0 },
                    { size: '480x320',  default_max_bid_cpm: 0, rate: 0 },
                    { size: '768x1024', default_max_bid_cpm: 0, rate: 0 },
                    { size: '1024x768', default_max_bid_cpm: 0, rate: 0 }
                ],
                auto_approve: false
            },
            'native': {
                allow_create: false,
                margin_rate: 0,
                margin_type: 'fixed',
                rates: [
                    { size: '0x0', default_max_bid_cpm: 0, rate: 0 }
                ],
                auto_approve: false
            }
        }
    },

    validation: {
        name: {
            required: true
        },
        default_max_cpm_rate: {
            required: true,
            pattern: 'number'
        },
        currency: {
            required: true
        }
    },

    hasFormatEnabled: function (format) {
        var f = this.get('creatives')[format];
        return f && f.allow_create;
    },

    getAvailableFormats: function () {
        var formatsPossesed = _.pluck(this.get('creatives'), 'format');
        return _.difference(CREATIVE_FORMATS, formatsPossesed);
    },

    getCreativeFormats: function () {
        return CREATIVE_FORMATS;
    },

    getMarginTypes: function () {
        return MARGIN_TYPES;
    },

    getEnabledCreativeFormats: function () {
        var formats = [];
        _.each(this.get('creatives'), function (val, key) {
            if (val.allow_create) {
                formats.push(key);
            }
        });

        return formats;
    },

    getOrderedCreatives: function () {
        var creatives = _.clone(this.get('creatives'));
        var ret = [];
        _.each(CREATIVE_FORMATS, function (format) {
            var creative = creatives[format];
            creative['format'] = format;
            ret.push(creative);
        });

        return ret;
    },

    saveCreatives: function (creatives, success) {

        if (!_.isFunction(success)) {
            success = function() {};
        }

        if (this.isNew()) {
            this.set('creatives', _.defaults(creatives, this.defaults.creatives), {silent: true});
        } else {
            this.set('creatives', _.defaults(creatives, this.defaults.creatives), {silent: true});
            this.save(null, {patch: true, success: success, silent: true});
        }
    },

    deleteCreativeFormat: function (format, success) {
        var creatives = _.clone(this.get('creatives'));

        _.each(creatives, function (creative) {
            _.defaults(creative, {
                margin_type: 'fixed',
                margin_rate: 0
            });
        });

        var newCreatives = _.reject(creatives, function (creative) {
            return creative.format === format;
        });

        if (!_.isFunction(success)) {
            success = function() {};
        }

        success = _.wrap(success, function (fn, m) {
            m.trigger('change');
            return fn();
        });

        this.save('creatives', newCreatives, {wait: true, success: success});
    },

    isFormatEnabled: function (format) {
        return this.get('creatives')[format] && this.get('creatives')[format]['allow_create'];
    },

    isChild: function () {
        return this.get('_relationship') === 'child';
    },

    isSelf: function () {
        return this.get('_relationship') === 'self';
    },

    isDescendant: function () {
        return this.get('_relationship') === 'descendant';
    },

    computed: function () {
        return {
            _child: this.isChild()
        };
    },

    // set permitted actions
    setPermission: function (actions) {
        this._actions = actions;
    },

    can: function (op) {
        if (op === 'edit') {
            op = 'update';
        }
        return _.contains(this._actions, op);
    }
});

var Organizations = Mario.Collection.extend({
    model: Organization,
    url: 'organizations/'
});

exports = module.exports = Organizations;
exports.Organization = Organization;
