'use strict';
var layoutTemplate = require('./templates/layout');
var fns = require('scripts/common/fns');
var adRowTemplate = require('./templates/ad-row');
var adsTemplate = require('./templates/by-ad');
var groupsTemplate = require('./templates/by-group');
var groupRowTemplate = require('./templates/group-row');
var groupTableTemplate = require('./templates/group-table');
var SummaryView = require('../ads/summary');

var AdRowView = Mario.ItemView.extend({
    tagName: 'tr',
    template: adRowTemplate,

    onShow: function () {
        this.initAdDetailPopover();
    },

    initAdDetailPopover: function () {
        var that = this;
        var summary = new SummaryView({
            model: that.model,
            creatives: that.options.creatives,
            embed: true
        });

        var options = {
            title: '<span class="btn">' + this.model.get('name') + ' </span>',
            content: function () {
                var wrapper = $('<div class="ad-detail"></div>');
                var region = new Mario.Region({el: wrapper});
                region.show(summary);
                return region.el;
            },
            html: true
        };

        this.$('[data-toggle=popover]').popover(options);
    },

    templateHelpers: function () {
        return _.extend({
            cid: this.options.campaign.id
        }, getAdRowVariables(this.model), {kpis: this.options.groupedAttributes});
    }
});

var AdsView = Mario.CompositeView.extend({
    className: 'traffic-sheet row',
    template: adsTemplate,
    childViewContainer: 'tbody',
    childView: AdRowView,

    initialize: function () {
        this.on('composite:rendered', function () {
            this.$('table').DataTable({
                paging: false,
                info: false,
                searching: false,
                columnDefs: [{
                    targets: ['add-column'],
                    orderable: false,
                    width: '10px'
                }],
                autoWidth: false,
                order: [0, 'desc']
            });
        });
    },

    events: {
        'shown.bs.popover': hideOtherPopovers,
        'change [type=checkbox]': 'toggleColumn'
    },

    toggleColumn: function (ev) {
        var kpi = $(ev.currentTarget).data('check-column');
        var th = this.$('th[data-column="' + kpi + '"]');
        var idx = th.index();
        th.toggleClass('hide');

        this.$('tbody tr').find('>:nth-child(' + (idx + 1) + ')').toggleClass('hide');

        if (this.$('[type=checkbox]:checked').length > 4) {
            this.$('[type=checkbox]:not(:checked)').prop('disabled', true);
        } else {
            this.$('[type=checkbox]:disabled').prop('disabled', false);
        }

        if (this.$('[type=checkbox]:checked').length < 2) {
            this.$('[type=checkbox]:checked').prop('disabled', true);
        } else {
            this.$('[type=checkbox]:disabled').prop('disabled', false);
        }
    },

    onShow: function () {
        this.initColumnToggle();
    },

    templateHelpers: function () {
        var showUntilInde = this.options.groupings.showUntilIndex();
        var columns = this.options.groupings.getApplicableColumns();
        return {
            columns: columns
        };
    },

    initColumnToggle: function () {
        var that = this;
        var options = {
            title: 'Toggle Columns',
            content: function () {
                var content = $('<div></div>');
                var ul = $('<ul class="list-unstyled" style="width: 150px;"></ul>');
                var columns = that.options.groupings.getApplicableColumns();

                _.each(columns, function (column) {
                    var checkbox = $('<li><div class="checkbox"><label><input type="checkbox" data-check-column="' + column.name + '"> '
                    + column.label + '</label></div></li>');

                    if (that.$('thead th[data-column="' + column.name + '"]').is(':visible')) {
                        checkbox.find('input').attr('checked', true);
                    }

                    ul.append(checkbox);
                });

                content.append(ul);
                content.append('<small>You can select maximum 5 columns at one time.</small>');

                return content;
            },
            html: true
        };

        this.$('.add-column [data-toggle=popover]').popover(options);
    },

    childViewOptions: function (item, index) {
        var groupedAttributes = this.options.groupings.findGroupedAttributesForAd(item.id);
        return {
            creatives: this.options.campaign.creatives,
            campaign: this.options.campaign,
            groupedAttributes: groupedAttributes
        };
    }
});

var GroupRowView = Mario.ItemView.extend({
    template: groupRowTemplate,
    tagName: 'tr',
    templateHelpers: function () {
        var variables = getAdRowVariables(this.model);
        return _.extend(variables, {cid: this.options.campaign.id});
    },

    onShow: function () {
        var summary = new SummaryView({
            model: this.model,
            creatives: this.options.campaign.creatives,
            embed: true
        });

        var options = {
            title: '<span class="btn">' + this.model.get('name') + ' </span>',
            content: function () {
                var wrapper = $('<div class="ad-detail"></div>');
                var region = new Mario.Region({el: wrapper});
                region.show(summary);
                return region.el;
            },
            html: true
        };

        this.$('[data-toggle=popover]').popover(options);
    }
});

var GroupView = Mario.CompositeView.extend({
    template: groupTableTemplate,
    childView: GroupRowView,
    childViewContainer: 'tbody',
    initialize: function () {
        // convert ads to grouped collection
        this.ads = this.collection;
        this.collection = convertToGroupCollection(this.ads, this.options.grouping, this.options.campaign.creatives);
    },
    childViewOptions: function () {
        return {
            collection: this.ads,
            campaign: this.options.campaign
        };
    },

    events: {
        'shown.bs.popover': hideOtherPopovers
    }
});

var GroupsView = Mario.Layout.extend({
    template: groupsTemplate,

    onShow: function () {
        this.options.groupings.each(this.renderGrouping, this);
    },

    events: {
        'click .kpi-name, th': 'toggleGrouping'
    },

    toggleGrouping: function (ev) {
        $(ev.currentTarget).closest('.grouping').toggleClass('off');
    },

    renderGrouping: function (grouping) {
        // skip ad grouping
        if (grouping.get('kpi') === 'ad') {
            return;
        }

        var template = _.template('<div class="row grouping"><div class="col-sm-2">' +
            '<h4 class="kpi-name"><%=kpi%></h4></div>' +
            '<div class="col-sm-10 <%=kpi%>-grouping kpi-table"></div></div>');

        var el = $(template({kpi: grouping.get('kpi')}));

        if (grouping.get('groups').length <=1) {
            el.addClass('off');
        }

        this.$el.append(el);

        var region = this.addRegion(grouping, '.' + grouping.get('kpi') + '-grouping');

        region.show(new GroupView({
            collection: this.collection,
            grouping: grouping,
            campaign: this.options.campaign
        }));

    }
});


module.exports = Mario.Layout.extend({

    template: layoutTemplate,

    initialize: function () {
        this.collection = this.options.campaign.ads;
        this.groupings = this.options.campaign.groupings;
    },

    regions: {
        trafficContentRegion: '.traffic-sheet-content'
    },

    events: {
        'click [data-view=by-ad]': 'viewByAd',
        'click [data-view=by-group]': 'viewByGroup'
    },

    viewByAd: function () {
        this.$('[data-view]').removeClass('active');
        this.$('[data-view=by-ad]').addClass('active');
        this.trafficContentRegion.show(new AdsView({collection: this.collection, campaign: this.options.campaign, groupings: this.groupings}));
    },

    viewByGroup: function () {
        this.$('[data-view]').removeClass('active');
        this.$('[data-view=by-group]').addClass('active');
        this.trafficContentRegion.show(new GroupsView({collection: this.collection, campaign: this.options.campaign, groupings: this.groupings}));
    },

    onShow: function () {
        var that = this;

        var tasks = [];

        tasks.push(Q(this.groupings.fetch()));

        this.delegateEvents();

        Q.all(tasks).then(function () {
            return that.viewByAd();
        }).done();
    }
});

/**
 * create a collection of wrappers around each ad
 * wrapper contains information about the grouping
 *
 * @param ads
 * @param grouping - returned by grouping API
 * @param creatives
 * @returns {Mario.Collection}
 */

function convertToGroupCollection(ads, grouping, creatives) {

    // build lookup table first
    // {aid: group_name} mapping
    var adLookup = _.reduce(grouping.get('groups'), function (table, group) {
        _.each(group.ads, function (aid) {
            if (!table[aid]) {
                table[aid] = group.name;
            }
        });
        return table;
    }, {});

    var ret = ads.map(function (ad) {
        return _.extend({
            group_name: adLookup[ad.id]
        }, ad.toJSON());
    });

    // have to sort, so ads belonging to same group will be together
    ret = _.sortBy(ret, 'group_name');

    // store ref to the first ad in the group
    var lastHeadId;

    // now ad is POJO
    _.each(ret, function (ad, idx) {

        /**
         * Wrapper object
         * @property {string} group_name
         * @property {int} group_count - count number of ad in this group
         *
         * ... and all attributes from ad
         */

        if (!idx || ad.group_name !== ret[idx - 1].group_name) {
            lastHeadId = idx;
            ad.group_count = 1;
        } else {
            ret[lastHeadId].group_count += 1;
        }

    });


    return new Backbone.Collection(ret);
}

function getAdRowVariables (m) {
    /* jshint maxcomplexity */
    var goal = _.any(m.pick('max_total_impressions', 'max_daily_impressions'))? 'impressions' : 'clicks';

    var ret = {
        click_target_disabled:  goal !== 'clicks',
        click_daily_cap_disabled: goal !== 'clicks',
        impression_target_disabled: goal !== 'impressions',
        impression_daily_cap_disabled: goal !== 'impressions'
    };

    if (goal === 'impressions') {
        ret['max_total_impressions'] = m.get('max_total_impressions') || '&infin;';
        ret['max_daily_impressions'] = m.get('max_daily_impressions') || '-';
    }

    if (goal === 'clicks') {
        ret['max_total_clicks'] = m.get('max_total_clicks') || '&infin;';
        ret['max_daily_clicks'] = m.get('max_daily_clicks') || '-';
    }

    return ret;
}

function hideOtherPopovers (ev) {
    var triggeringEl = $(ev.target);
    this.$('[data-toggle=popover]').each(function () {
        if (!$(this).is(triggeringEl)) {
            $(this).popover('hide');
        }
    })
}

