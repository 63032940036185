<td><a href="#" data-toggle="popover" data-trigger="click">#{{id}} {{name}}</a></td>

{{#each kpis}}
    <td {{#if hidden}} class="hide"{{/if}}>{{name}}</td>
{{/each}}

<td class="add-column"></td>

{{#if impression_target_disabled}}
    <td class="disabled"></td>
{{else}}
    <td>{{{format_number_thousands max_total_impressions}}}</td>
{{/if}}

{{#if impression_daily_cap_disabled}}
    <td class="disabled"></td>
{{else}}
    <td>{{{format_number_thousands max_daily_impressions}}}</td>
{{/if}}

{{#if click_target_disabled}}
    <td class="disabled"></td>
{{else}}
    <td>{{{format_number_thousands max_total_clicks}}}</td>
{{/if}}

{{#if click_daily_cap_disabled}}
    <td class="disabled"></td>
{{else}}
    <td>{{{format_number_thousands max_daily_clicks}}}</td>
{{/if}}
