'use strict';
var Status = require('scripts/widgets/status');
var PrivateChannel = require('scripts/common/mixins/private-channel');
var Tooltip = require('scripts/widgets/tooltip');

var BasicInfo = React.createClass({
    mixins: [PrivateChannel],
    componentDidMount() {
        this.privateCh.listenTo(this.props.ch, 'shown', ()=>this.shortenNotes());
        this.shortenNotes();
    },

    shortenNotes() {
        var node = this.getDOMNode();
        $(node).find('[data-id=notes]').dotdotdot({
            wrap: 'word', height: 40
        });
    },

    onShowHistory() {
        this.props.ch.trigger(`campaign:${this.props.campaign.id}:show:history`);
        return false;
    },

    render: function () {
        var campaign = this.props.campaign;
        var status = campaign.get('status');

        return (
            <div>
                <h4>
                    <a href={"/campaigns/" + campaign.id} data-track data-tracking-section="campaign" data-tracking-section-id={campaign.id}>
                        <span className="text-muted" data-id="id">#{campaign.id}</span>
                    &nbsp;&nbsp;{campaign.get('name')}
                    </a>
                </h4>
                <Status status={status}></Status>
                <blockquote className="text-small" data-id="notes">{campaign.get('notes')}</blockquote>
                <div className="ef-toolbar">

                    <Tooltip text="Real-Time Delivery">
                        <a type="button" title="Real-Time Delivery" href={'/campaigns/' + campaign.id + '/delivery' }>
                            <i className="fa fa-fw fa-line-chart"></i>
                            <span>Real-Time Delivery</span>
                        </a>
                    </Tooltip>

                    <Tooltip text="Analytics">
                        <a type="button" title="Analytics" href={'/campaigns/' + campaign.id + '/report' }>
                            <i className="fa fa-fw fa-area-chart"></i>
                            <span>Analytics</span>
                        </a>
                    </Tooltip>

                    {campaign.can('edit')? 
                    <Tooltip text="Progress">
                        <a type="button" title="Edit" href={'/campaigns/' + campaign.id }>
                            <i className="fa fa-fw fa-pencil"></i>
                            <span>Edit</span>
                        </a>
                    </Tooltip>: null}

                    <Tooltip text="Progress">
                        <a type="button" title="History" href={'/campaigns/' + campaign.id + '/history' } onClick={this.onShowHistory} data-bypass>
                            <i className="fa fa-fw fa-clock-o"></i>
                            <span>History</span>
                        </a>
                    </Tooltip>
                </div>
            </div>
        );
    }
});

module.exports = BasicInfo;
