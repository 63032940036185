<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">How do I read a progress graph?</h4>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-xs-10 col-xs-offset-1">
                <h3>Time Progress</h3>
                <div class="row">
                    <div class="col-xs-12">
                        <p> The time track represents the amount of time remaining in the campaign.</p>
                        <p> Imagine the blue track as a burning wick; the track starts off as dark blue (pending ad or campaign) and moves rightward until the track is completely grey (ended ad or campaign). Each day, the blue track will move by the amount of the light blue section.</p>
                        <p> For longer campaigns, the light blue area will be proportionately smaller.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-3"><strong>Pending</strong>
                        <div class="ef-progress" >
                            <div style="width:100%;" class="ef-progress-remaining "></div>
                            <div style="width:  0%;" class="ef-progress-today"></div>
                        </div>
                    </div>
                    <div class="col-xs-3"><strong>Day 1</strong>
                        <div class="ef-progress" >
                            <div style="width: 90%;" class="ef-progress-remaining "></div>
                            <div style="width: 10%;" class="ef-progress-today"></div>
                        </div>
                    </div>
                    <div class="col-xs-3"><strong>Day 2</strong>
                        <div class="ef-progress" >
                            <div style="width: 80%;" class="ef-progress-remaining "></div>
                            <div style="width: 10%;" class="ef-progress-today"></div>
                        </div>
                    </div>
                    <div class="col-xs-3"><strong>Ended</strong>
                        <div class="ef-progress" >
                            <div style="width: 0%;" class="ef-progress-remaining "></div>
                            <div style="width: 0%;" class="ef-progress-today"></div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-xs-12">
                        <p> <span class="legend-graph-bullet"><span class="legend-progress-remaining"><span></span></span>
                            </span> The dark blue section represents the remaining time and impressions/clicks.</p>
                        <p> <span class="legend-graph-bullet"><span class="legend-progress-today"><span></span></span>
                            </span> The light blue section represents today's time and scheduled impression/click target.</p>
                    </div>
                </div>
                <h3>Delivery Progress</h3>
                <div class="row">
                    <div class="col-xs-12">
                        <p> The delivery bar shows the delivered impressions/clicks, relative to total booked impressions / clicks. This bar is only shown when the total booked impressions/clicks is provided.</p>
                        <p> If delivery is right on schedule, the bar should land within the light blue area of the track. To maintain smooth delivery, the delivery bar <i>chases</i> the tail of the time track as it progresses to the right.</p>
                        <p> The color of the delivery bar will vary depending on its position relative to the time track. </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-3"><strong>On track</strong>
                        <div class="ef-progress " >
                            <div style="width: 40%;" class="ef-progress-remaining "></div>
                            <div style="width: 10%;" class="ef-progress-today"></div>
                            <div style="width: 55%;" class="ef-progress-success ef-progress-target"></div>
                        </div>
                    </div>
                    <div class="col-xs-3"><strong>Behind schedule</strong>
                        <div class="ef-progress " >
                            <div style="width: 40%;" class="ef-progress-remaining "></div>
                            <div style="width: 10%;" class="ef-progress-today"></div>
                            <div style="width: 45%;" class="ef-progress-warning ef-progress-target"></div>
                        </div>
                    </div>
                    <div class="col-xs-3"><strong>Critical</strong>
                        <div class="ef-progress " >
                            <div style="width: 40%;" class="ef-progress-remaining "></div>
                            <div style="width: 10%;" class="ef-progress-today"></div>
                            <div style="width: 30%;" class="ef-progress-danger ef-progress-target"></div>
                        </div>
                    </div>
                    <div class="col-xs-3"><strong>Ahead of schedule</strong>

                        <div class="ef-progress " >
                            <div style="width: 40%;" class="ef-progress-remaining "></div>
                            <div style="width: 10%;" class="ef-progress-today"></div>
                            <div style="width: 65%;" class="ef-progress-success ef-progress-target"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button data-dismiss="modal" class="btn btn-default">Thanks!</button>
    </div>
</div>
