'use strict';

var IBACategories = React.createClass({
    render: function() {
        var items = this.props.iab_categories;
        var items_test = [ 'swiss-cheess', 'green-egg', 'greasy-butt' , 'green-egg', 'greasy-butt',
            'swiss-cheess', 'green-egg', 'greasy-butt' , 'green-egg', 'greasy-butt'];
        return(
            <dd>
                { items.map( (item)=>{ return <span key={_.uniqueId()} className="label label-default">{ item }</span>; }) }
            </dd>
        )
    }
})

var CampaignSummary = React.createClass({
    render: function () {
        return (
            <div className="campaign-summary">
                <div className="panel-body">

                    <div className="panel-heading">
                        <h3 className="panel-title">Campaign Summary</h3>
                    </div>
                    <h6>Basics</h6>
                        <dl className="dl-horizontal">
                            <div className="item-group">
                                <dt>Name</dt>
                                <dd summary-id="name">{this.props.campaignJson.name}</dd>
                            </div>
                            <div className="item-group">
                                <dt>Advertiser Domain</dt>
                                <dd>{this.props.campaignJson.advertiser_domain}</dd>
                            </div>
                            <div className="item-group">
                                <dt>IAB categories</dt>
                                <IBACategories iab_categories={this.props.campaignJson.iab_categories}/>
                            </div>
                        </dl>
                    <h6>Note</h6>
                    <div className="note-text">
                        {this.props.campaignJson.notes} </div>
                </div>
            </div>
        );
    }
});


module.exports = CampaignSummary;

