'use strict';

// available role permissions
var STANDARD_PERMISSIONS = ['list', 'view', 'update', 'create', 'suspend', 'delete'];
var CAMPAIGN_PERMISSIONS = ['create', 'list', 'view', 'suspend', 'update', 'suspend',
'delete', 'access', 'share', 'delivery', 'price', 'cost', 'audit'];


var Role = Mario.Model.extend({
    modelName: 'Role',
    defaults: {
        name : '',
        label: '',
        description: '',
        suspended: false,
        permissions: []
    },

    validation: {
        name: {
            required: true
        }
    },

});
var Roles = Mario.Collection.extend({
    model: Role ,
    url: 'roles'
});

exports = module.exports = Roles;

exports.Role = Role;

exports.STANDARD_PERMISSIONS = STANDARD_PERMISSIONS;
exports.CAMPAIGN_PERMISSIONS = CAMPAIGN_PERMISSIONS;