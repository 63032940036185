<div class="map"></div>

<div class="controls">

    <div class="row">
        <div class="col-sm-12">
            <button class="btn btn-default show-all-boxes">See All Boxes</button>
            <button class="btn btn-default remove-all-boxes">Remove All Boxes</button>
            <button class="btn btn-default remove-selected-box">Remove Selected Boxes</button>
        </div>

        <div class="col-sm-12">
            <div class="input-group pull-right">
                <span class="input-group-addon"> <i class="fa fa-search"></i> </span>
                <input type="text" class="form-control" id="map-search">
            </div>
        </div>
    </div>
</div>
