'use strict';

// status names need to match server-side
var AUDIT_STATUS = {
    NO_AUDIT     : 'no_audit',
    APPROVED     : 'approved',
    PENDING      : 'pending',
    REJECTED     : 'rejected'
};

var Model = Mario.Model.extend({
    modelName: 'Creative',
    // @ Documentation
    // https://docs.google.com/a/addictivemobility.com/spreadsheets/d/1P9rcxwtx-BE7HSyBRw8DO9FpGUQfK5zbC1gTr7ykOUY/edit#gid=0
    // https://github.com/amoa/adsushi/issues/478
    // https://github.com/amoa/adsushi/wiki/Creative-Builder

    initialize: function (attrs, options) {
        _.extend( this,
            _.pick(options, 'urlRoot'),
            { assetAssociation: {
                // AssetAssociation is true when attributes in a
                // section is synced with an item in Asset helper.
                 icon: false,
                 main_img: false,
                 banner: false,
                 reference_img: false,
                 video: false,
                 companion_ad: false
            }}
        );

        // Keep the following for future debuggin purpose:
            // this.on('change', function() {
            //     console.log('model changed', this.toJSON());
            // })

    },

    // @TODO cast video duration from num to string in parse(), that way
    //       I don't need to hack it in backbone stick with "empty"

    // parse: function(data) {
    //     console.log('in bb parse: ', data);
    //     return data;
    // },

    // toJSON: function(){
    //     var json = _.clone(this.attributes);
    //     console.log('in bb.model toJSON: ', json);
    //     return json;
    // },

    defaults: {
        // standard, video, mraid, custom_html, native
        format                      : '',

        // 320x50, 300x50, 320x480, ....
        size                        : '',
        name                        : '',
        content_html                : '',

        // Icon image | used-by:native
        icon_image_asset            : '', //(new)
        icon_image_url              : '', //(old+new)
        icon_image_preview_url      : '', //(old+new)

        // Main Image | used-by:native
        main_image_asset            : '',
        main_image_url              : '',
        main_image_preview_url      : '',

        // Banner
        image_asset                 : '', //(new)     used-by: standard
        image_url                   : '', //(old+new) used-by: standard
        image_preview_url           : '', //(new)     used-by: standard video

        // Reference
        reference_image_asset       : '', //(new)     used-by: mraid custom_html
        reference_image_preview_url : '', //(new)     used-by: mraid custom_html

        video_asset                 : '', //(new)     used-by:video
        video_url                   : '', //(old+new) used-by:video
        video_preview_url           : '', //(new)     used-by:video
        video_duration              : '', //(old+new)

        // (new) companion ad | used-by: video
        companion_asset             : '',
        companion_url               : '',
        companion_preview_url       : '',

        // (old and new) content | used-by: native
        title                       : '',
        text                        : '',
        call_to_action              : '',
        star_rating                 : '',

        // used-by: all
        clickthrough_url            : '',
        third_party_pixels          : [],
        third_party_clickthrough    : false,
        landing_page                : '',

        audit_status             : AUDIT_STATUS.NO_AUDIT
    },

    validation: {

        format: function (val, attr) {
            if (this.isNew() && _.isEmpty(val)) {
                this.trigger('creativeBuilder:tabNavigate', {tabNavigateTo:'format'});
                return 'Format is required.';
            }
            return null;
        },

        size : function(val, attr, computedState){
            var format = computedState.format;
            if ( format !== 'native' ) {
                if ( _.isEmpty(val) ) {
                    this.trigger('creativeBuilder:tabNavigate', {tabNavigateTo:'format'});
                    return 'Size is required.';
                }
            }
            return null;
        },

        name : {
            required: true
        },

        content_html: function (val, attr, computedState) {
            var format = computedState.format;
            if (format === 'custom_html' || format === 'mraid') {
                if (_.isEmpty(val)) { return 'Custom html is required.'; }
            }
            return null;
        },

        // section name  : Icon image
        // use by format :  native
        // asset section : icon

            icon_image_asset: function(val, attr, computedSate ){
                return null
            },

            // Icon Image URL
            icon_image_url: function (val, attr, computedState) {
                var format = computedState.format;
                if (format === 'native') {
                    if (_.isEmpty(val))    { return 'Icon image url is required.'; }
                    if (!validateUrl(val)) { return 'Icon image url is not valid.'; }
                }
                return null;
            },

            // Icon Preview URL
            icon_image_preview_url: function (val, attr, computedState) {
                var format = computedState.format;
                if (format === 'native') {
                    if ( !_.isEmpty(val) && !validateUrl(val) ) { return 'Icon preview url is not valid.'; }
                }
                return null;
            },


        // section name   : Main Image
        // used by format : native
        // asset section  : main_img

            main_image_asset: function(val, attr, computedState) {
                return null;
            },

            // Main Image URL
            main_image_url: function (val, attr, computedState) {
                var format = computedState.format;
                if (format === 'native') {
                    if (_.isEmpty(val))    { return 'Main url is required.'; }
                    if (!validateUrl(val)) { return 'Main url is not valid.'; }
                }
                return null;
            },

            // Main Image Preview URL
            main_image_preview_url: function (val, attr, computedState) {
                var format = computedState.format;
                if (format === 'native') {
                    if ( !_.isEmpty(val) && !validateUrl(val)) { return 'Main preview url is not valid.'; }
                }
                return null;
            },


        // section name   : Banner
        // used by format : standard
        // asset section  : banner

            image_asset: function(val, attr, computedState) {
                return null;
            },

            // Banner URL
            image_url: function (val, attr, computedState) {
                /* jshint maxcomplexity: 20 */

                var format = computedState.format;
                if (format === 'standard') {
                    if (_.isEmpty(val))    { return 'Image url is required.'; }
                    if (!validateUrl(val)) { return 'Image url is not valid.'; }
                }
                return null;
            },


        // section name   : Reference Image
        // used by format : mraid, custom_html
        // asset section  : reference_img

            reference_image_asset: function(val, attr, computedState) {
                return null;
            },


            reference_image_preview_url: function (val, attr, computedState) {
                var format = computedState.format;
                var label = '';
                if ( format === 'mraid' ||  format === 'custom_html' ) {
                    label = 'Reference ';
                    if (_.isEmpty(val)   ) { return label + 'url is required.'; }
                    if (!validateUrl(val)) { return label + 'url is not valid.'; }
                }
                return null;
            },


        // section name   : Banner,   Video
        // used by format : standard, video
        // asset section  : banner,   video

            image_preview_url: function (val, attr, computedState) {
                var format = computedState.format;
                var label = '';
                // Banner Preview url
                if ( format === 'standard' ) {
                    if ( !_.isEmpty(val) && !validateUrl(val)) { return label + 'url is not valid.'; }
                    label = 'banner'
                }
                // Video Preview url
                if ( format === 'video') {
                    label = 'Reference Screenshot ';
                    if (_.isEmpty(val)   ) { return label + 'url is required.'; }
                    if (!validateUrl(val)) { return label + 'url is not valid.'; }
                }
                return null;
            },

        // section name   : Video
        // used by format : video
        // asset section  : video

            video_asset: function(val, attr, computedState) {
                return null;
            },

            // Video Preview url
            video_url: function (val, attr, computedState) {
                var format = computedState.format;
                if (format === 'video') {
                    if ( !_.isEmpty(val) &&  !validateUrl(val)) { return 'Video url is not valid.'; }
                }
                return null;
            },

            // Reference Screenshot
            video_preview_url: function (val, attr, computedState) {
                var format = computedState.format;
                if (format === 'video') {
                    if (_.isEmpty(val)) { return 'Video preview url is required.'; }
                    if (!validateUrl(val)) { return 'Video preview url is not valid.'; }
                }
                return null;
            },

            // Duration
            video_duration: function (val, attr, computedState) {
                var format = computedState.format;
                var duration = parseFloat(val);
                var durationStr = val.toString();

                if (format === 'video') {
                    if ( /^\d+$/.test(durationStr) ) {
                        // is digit and out of bound
                        if (  duration < 1 || duration > 30 ) {
                           return 'Video duration must be a number between 1 and 30';
                        }
                    }
                    if ( durationStr === "empty" ) {
                        return 'Video duration is required.';
                    }
                    if ( durationStr === "NaN" ) {
                        return 'Video duration must be a number';
                    }
                }
                return null;
            },

        // section name   : Companion
        // used by format : video
        // asset section  : companion_ad

            companion_asset: function(val, attr, computedState) {
                return null;
            },

            // Companion Banner url
            companion_url: function (val, attr, computedState) {
                var format = computedState.format;
                if (format === 'video') {
                    if (_.isEmpty(val))    { return 'Companion banner url is required.'; }
                    if (!validateUrl(val)) { return 'Companion banner url is not valid.'; }
                }
                return null;
            },

            // Companion Banner Preview url
            companion_preview_url: function (val, attr, computedState) {
                var format = computedState.format;
                if (format === 'video') {
                    if ( !_.isEmpty(val) && !validateUrl(val) ) { return 'Companion banner preview url is not valid.'; }
                }
                return null;
            },


        // section name   : Content
        // used by format : native
        // asset section  : content

            // Ad Title
            title: function (val, attr, computedState) {
                var format = computedState.format;
                if (format === 'native') {
                    if (_.isEmpty(val)) { return 'Title is required.'; }
                }
                return null;
            },

            // Main Text
            text: function (val, attr, computedState) {
                var format = computedState.format;
                if (format === 'native') {
                    if (_.isEmpty(val)) { return 'Text is required.'; }
                }
                return null;
            },

            // Call To Action
            call_to_action : function (val, attr, computedState) {
                var format = computedState.format;
                if (format === 'native') { }
                return null;
            },

            // Star Rarting
            star_rating : function (val, attr, computedState) {
                var format = computedState.format;
                if (format === 'native') { }
                return null;
            },

        // used by format: all

            clickthrough_url: function (val, attr, computedState) {
                var format = computedState.format;
                if (computedState.third_party_clickthrough === true ) {
                    if ( (format === 'mraid') || (format==='custom_html')) {
                        return null;
                    } else {
                        if (_.isEmpty(val)) { return 'Clickthrough url is required.'; }
                        if (!validateUrl(val)) { return 'Clickthrough url is not valid.'; }
                    }
                }

                return null;
            },

            landing_page: function (val, attr, computedState) {
                // exempt for custom_html and mraid
                // if (computedState.format === 'custom_html' || computedState.format === 'mraid' ) {
                //     return null;
                // }

                if (_.isEmpty(val)) {
                    return 'Landing page url is required.';
                }

                if (computedState.third_party_clickthrough && val === computedState.clickthrough_url) {
                    return 'Landing page url should be different from clickthrough url.';
                }

                if (!validateUrl(val)) {
                    return 'Landing page url is not valid.';
                }

                return null;
            },

            // boolean
            third_party_clickthrough: function (val, attr, computedState) {
                return null;
            },

            third_party_pixels: function (urls, attr, computedState) {

                var ret = _.map(urls, validateUrl);

                if (_.every(ret)) {
                    return null;
                }

                // concatenated true,false,true...
                return ret.toString();
            },


    },

    duplicate: function (cb) {
        var that = this;
        $.ajax(this.url() + '/duplicate', {
            type: 'POST',
            dataType: 'json',
            success: function (data) {
                var m = new that.constructor(data);
                cb.call(that, m);
            }
        });
    },

    /**
     * search term
     * @param term {string} can be name (case insensitive), format or size
     * @returns {boolean}
     */
    searchTerm: function (term) {
        if (typeof term !== 'string') return true;
        return _.contains(this.get('name').toLowerCase(), term.toLowerCase()) || term === this.get('format') || this.get('size') === term;
    },

    sendForApprove: function (options) {
        options = options || {};

        options = _.defaults(options, {
            success: function () {},
            patch: true,
            wait: true
        });

        this.save('audit_status', AUDIT_STATUS.PENDING, options);
    },

    computed: function (m) {
        var format = this.get('format');
        return {};
    },

    getSizes: function () {
        if (this.collection) {
            return this.collection.getSizesByFormat(this.get('format'));
        } else {
            return null;
        }
    },

    getPreviewHtml: function () {
        switch (this.get('format')) {
            case 'standard':
                var template = '<a href="<%- clickthrough_url %>"><img src="<%- image_url%>"></a>';
                return _.template(template, this.toJSON());
            case 'mraid':
            case 'custom_html':
                return _.template(this.get('content_html'), this.toJSON());
        }
    },

    getPreviewDevices: function () {
        /* jshint maxcomplexity: 11 */
        // width, height
        var iphonePortrait = [320, 568];
        var iphoneLandscape = [iphonePortrait[1], iphonePortrait[0]];
        var ipadPortrait = [768, 1024];
        var ipadLandscape = [ipadPortrait[1], ipadPortrait[0]];

        var devices = {};

        switch (this.get('size')) {
            // mobile
            case '320x50':
            case '300x250':
                devices['iPhone Portrait'] = iphonePortrait;
                devices['iPhone Landscape'] = iphoneLandscape;
                break;
            // mobile interstitial
            case '320x480':
                devices['iPhone Portrait'] = iphonePortrait;
                break;
            // mobile interstitial
            case '480x320':
                devices['iPhone Landscape'] = iphoneLandscape;
                break;

            // tablet
            case '728x90':
                devices['iPad Portrait'] = ipadPortrait;
                devices['iPad Landscape'] = ipadLandscape;
                break;
            // table interstitial
            case '768x1024':
                devices['iPad Portrait'] = ipadPortrait;
                break;
            // table interstitial
            case '1024x768':
                devices['iPad Landscape'] = ipadLandscape;
                break;
        }

        if (this.get('format') === 'video' || this.get('format') === 'mraid') {
            return null;
        } else {
            return devices;
        }
    },
    hasNoAudit: function () {
        return this.get('audit_status') === AUDIT_STATUS.NO_AUDIT;
    },
    isSettled: function () {
        return this.get('audit_status') === AUDIT_STATUS.APPROVED ||
            this.get('audit_status') === AUDIT_STATUS.REJECTED;
    },

    getAssociatedAds: function () {
        var ads = objectPath.get(this, 'collection.campaign.ads');
        return _.filter(ads.models, function (ad) {return ad.get('creative') === this.id;}, this);
    },

    getCampaign: function () {
        return objectPath.get(this, 'collection.campaign');
    },

    getDuplicateAttributes: function () {
        return _.omit(this.toJSON(), ['id', 'audit_status']);

    },

    getHistory: function () {
        var that = this;
        return new Promise(function (res, rej) {
            return $.ajax({url: that.url() + '/history', dataType: 'json', success: res, error: rej});
        }).then(function (data) {
            // check data
            // data should be an array of history object {snapshot: Object}
            if (!_.every(data, function (h) {return _.has(h, 'snapshot');}))
                throw new Error('This creative does not have a snapshot.');
            else
                return data;
        });
    },

    can: function (op) {
        // delegate to campaign
        var campaign = this.getCampaign();

        if (!campaign) {
            return false;
        }

        return campaign.can(op);
    }

});

var Collection = Mario.Collection.extend({
    model: Model,
    initialize: function (models, options) {
        if (!_.has(options, 'collectionId')) {
            throw 'Missing params: collectionId for Creatives';
        }
        this.url = 'campaigns/' + options.collectionId + '/creatives';
        this.campaign = options.campaign;
    },
    comparator: function (a, b) {
        // newly added will be in front
        return b.get('_etag') > a.get('_etag') ? 1 : -1;
    },

    AUDIT_STATUS : AUDIT_STATUS,

    getSizesByFormat: function (format) {
        /* jshint maxcomplexity: 20 */

        switch (format) {
            case 'standard':
            case 'mraid':
            case 'custom_html':
                return {
                    Mobile: [
                        {value: '320x50', label: '320x50 (Banner)'},
                        {value: '300x50', label: '300x50 (Banner)'},
                        {value: '320x480', label: '320x480 (Interstitial)'},
                        {value: '480x320', label: '480x320 (Interstital)'}
                    ],
                    Tablet: [
                        {value: '728x90', label: '728x90 (Banner)'},
                        {value: '768x1024', label: '768x1024 (Interstital)'},
                        {value: '1024x768', label: '1024x768 (Interstitial)'}
                    ],
                    'Mobile / Tablet': [
                        {value: '300x250', label: '300x250 (Banner / Interstitial)'},
                    ]
                };
            case 'video':
                return {
                    Mobile: [
                        {value: '320x480', label: '320x480 (Interstitial)'},
                        {value: '480x320', label: '480x320 (Interstital)'}
                    ],
                    Tablet: [
                        {value: '768x1024', label: '768x1024 (Interstital)'},
                        {value: '1024x768', label: '1024x768 (Interstitial)'}
                    ]
                };
            default:
                return {};
        }
    },

    refresh: function () {
        this.trigger('refresh:start');

        var that = this;
        return new Promise(function (resolve, reject) {
            that.fetch({reset: true, success: resolve, error: reject});
        }).finally(function () {
            that.trigger('refresh:done');
        });
    }
});

exports = module.exports = Collection;
exports.AUDIT_STATUS = AUDIT_STATUS;
exports.Creative = Model;

function validateUrl(url) {
    /* jshint maxcomplexity: 20 */
    var parts = url.split(':');
    var isValid = false;
    url = url.toLowerCase();

    if (parts.length > 0) {
        var scheme = parts[0];
        var other = parts[1];

        // simplify url checking
        switch (scheme) {
            case 'tel':
            case 'sms':
                // https://github.com/amoa/adsushi/issues/112#issuecomment-47117446
                isValid = true; // /[\\+]?[0-9.-]+/.test(other);
                break;
            case 'http':
            case 'https':
                isValid = true; // /\/\/([\da-z\.-]+)\.([a-z\.]{2,6})(\/.*)?$/.test(other);
                break;
            default:
                isValid = S(url).contains(':');
        }
    }

    return isValid;
}
