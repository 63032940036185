'use strict';

var app = require('scripts/core').app;
var ListView = require('./views/dashboard');
var CampaignView = require('./views/campaign');
var NewCampaignView = require('./views/form');
var fns = require('scripts/common/fns');

var campaignRouteMatcher = /campaigns\/(\d{4})(?:\/(creatives|ads|assets|progress|delivery|files|traffic-sheet|report)(?:\/(.*))?)?/;
var Campaigns = require('scripts/entities/collections/campaigns');
var Campaign = Campaigns.prototype.model;

var campaigns = require('scripts/core/campaign-collection');

var Controller = Mario.Controller.extend({
    showCampaigns: function () {
        var page = globalCh.request('available-page', {main_url: '/campaigns'});
        var orgsReq = globalCh.request('cache', 'organizations');

        orgsReq.done(function (orgs) {
            page.show(function (ch) {
                return new ListView({organizations: orgs, collection: campaigns, ch: ch});
            });
        });
    },

    showCampaign: function (id, section, sectionId) {
        var campaign = campaigns.get(id);
        section = section || 'ads';

        if (typeof campaign === 'undefined') {
            campaigns.add({id: id}, {silent: true});
            campaign = campaigns.get(id);
        }
        var page = globalCh.request('available-page', {
            main_url: '/campaigns/' + id,
            sub_url: _.compact([section, sectionId]).join('/'),
            resource_id: campaign.id,
            resource_name: campaign.name
        });

        globalCh.trigger('route:campaign', campaign, id, section, sectionId);
        var orgsReq = globalCh.request('cache', 'organizations');

        orgsReq.done(function (orgs) {
            page.show(function (ch) {
                return new CampaignView({
                    collection: campaigns,
                    organizations: orgs,
                    model     : campaign,
                    section   : section,
                    sectionId : sectionId,
                    ch        : ch
                });
            }, function (existingView) {
                _.extend(existingView.options, {section: section, sectionId: sectionId});
            });
        });

    },

    newCampaign: function () {
        var page = globalCh.request('available-page', {main_url: '/campaigns/new'});
        var campaign = new Campaign({}, {urlRoot: 'campaigns'});

        campaign.once('sync', function () {
            campaigns.push(campaign);
            campaigns.sort();
        });

        campaigns.once('reset', function () {
            if (!campaign.isNew()) {
                var cid = campaign.id;
                campaigns.remove(campaign, {silent: true});
                campaigns.push(campaign, {silent: true});
                campaigns.sort();
            }
        });

        var orgsReq = globalCh.request('cache', 'organizations');

        orgsReq.done(function (orgs) {
            page.show(function (ch) {
                return new NewCampaignView({collection: campaigns, model: campaign, organizations: orgs, ch: ch});
            });
        });

    }
});
var Router = Mario.AppRouter;

var cont = new Controller();

exports.start = function () {
    debug('start campaigns component');

    var appRoutes = {
        'campaigns'    : 'showCampaigns',
        'campaigns/new': 'newCampaign'
    };

    this.router = new Router({controller: cont, appRoutes: appRoutes});

    this.router.appRoute(campaignRouteMatcher, 'showCampaign');

    return {
        add_to_menu: true,
        menu_config: {icon: 'fa-home', name: 'Campaigns'},
        matcher    : /\/campaigns/,
        url        : '/campaigns',
        key        : 'campaigns'
    };
};
