<td>
    <span class="name">
        {{name}}
    </span>
    <div class="input-group hide input-group-sm">
        <input type="text" value="{{name}}" class="form-control" data-id="name">
        <span class="input-group-btn">
            <button class="btn btn-default" type="button" data-action="save">
                <i class="fa fa-floppy-o"></i>
            </button>
            <button class="btn btn-default" type="button" data-action="cancel">
                cancel
            </button>
        </span>
    </div>

    {{#if can_update}}
    <div class="ops">
        <a href="#" class="btn btn-default btn-xs" data-action="edit">
            <i class="fa fa-pencil"></i>
            Edit
        </a>
        <a href="#" class="btn btn-default btn-xs" data-action="remove">
            <i class="fa fa-trash-o"></i>
            Remove
        </a>
    </div>
    {{/if}}
</td>

<td class="preview">
  <div title="{{type}}">
    <a target="blank" href="{{url}}" data-bypass>
      {{#is_equal type "txt"}}
        <i class="fa fa-file-text-o fa-3x"></i>
      {{/is_equal}}
      {{#is_equal type "xls"}}
        <i class="fa fa-file-excel-o fa-3x"></i>
      {{/is_equal}}
      {{#is_equal type "xlsx"}}
        <i class="fa fa-file-excel-o fa-3x"></i>
      {{/is_equal}}
      {{#is_equal type "doc"}}
        <i class="fa fa-file-word-o fa-3x"></i>
      {{/is_equal}}
      {{#is_equal type "docx"}}
        <i class="fa fa-file-word-o fa-3x"></i>
      {{/is_equal}}
      {{#is_equal type "pdf"}}
        <i class="fa fa-file-pdf-o fa-3x"></i>
      {{/is_equal}}
    </a>
  </div>
</td>
