
<div class="col-sm-12">
    <table class="table table-bordered text-center">
        <thead>
        <tr>
            <th class="text-center">Ad </th>

            {{#each columns}}
                <th class="text-center {{#if hidden}} hide {{/if}}" data-column="{{name}}">{{label}}</th>
            {{/each}}

            <th class="text-center add-column" width="50px">
                <i class="fa fa-plus" data-toggle="popover" data-trigger="click"></i>
            </th>

            <th width="10%" class="text-right">Impression Target</th>

            <th width="6%" class="text-left">
                <small class="text-muted">Daily Cap</small></th>

            <th width="10%" class="text-right">Click Target</th>

            <th width="6%" class="text-left">
                <small class="text-muted">Daily Cap</small></th>
        </tr>
        </thead>
        <tbody>

        </tbody>
    </table>
</div>
