["TIMESTAMP",
"RANDOM",
"CITY",
"REGION",
"COUNTRY",
"CARRIER",
"LATITUDE",
"LONGITUDE",
"DEVICE_OS",
"DEVICE_MODEL",
"CLICKTHROUGH",
"CLICKTHROUGH_ESC",
"REDIRECT",
"REDIRECT_ESC",
"CLICKTRACKER",
"CLICKTRACKER_ESC"]
