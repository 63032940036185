'use strict';

var app = require('scripts/core').app;
var EditOrganization = require('./form');
var showTemplate = require('./templates/show2');
var CreativesView = require('./creatives');
var fns = require('scripts/common/fns');


/**
 * options
 * model<Organization>
 * organizations<Collection<Organization>>
 */

module.exports = Mario.Layout.extend({

    template: showTemplate,
    className: 'show2',
    regions: {
        creativesRegion: '.creatives-region'
    },

    bindings: {
        '[data-id=name]' : 'name',
        '[data-id=credit_limit]' : {
            observe: 'credit_limit',
            onGet: function (val) {
                if (val == -1) {
                    return 'Unlimited';
                } else {
                    return fns.formatCurrency(val);
                }
            }
        },
        '[data-id=default_max_cpm_rate]': {
            observe: 'default_max_cpm_rate',
            onGet: function (val) {
                return fns.formatCurrency(val);
            }
        }
    },

    events: {
        'click [data-action=edit]': 'editOrganization',
        'click [data-action=cancel]' : 'close',
    },

    editOrganization: function () {
        var form = new EditOrganization({
            model: this.model,
            organizations: this.options.organizations
        });

        $('#popup').one('shown.bs.modal', function () {
            $('#popup input[data-id=name]').focus();
        });

        var popup = globalCh.request('popup');
        popup.show(form);
    },

    onShow: function () {

        this.creativesRegion.show(new CreativesView({
            model: this.model
        }));

        this.stickit();
    },

    templateHelpers: function () {

        return {
            can_delete_organization: app.can('delete', 'organizations'),
            can_update: app.can('update', 'organizations')
        };
    }
});
