'use strict';
/**
 * This layout consist of header, list of items, footer *
 *
 * @property collection
 * @property ch
 * @property ItemClass
 *
 * @property [sortBy]
 * @property [searchBy]
 * @property [FooterClass]
 * @property [HeaderClass]
 * * *
 *
 *
 * This component will handle the refresh:done, remove, add events* * *
 */

var PrivateChannel = require('scripts/common/mixins/private-channel');

var List = React.createClass({
    mixins:[PrivateChannel],
    getInitialState: function () {
        return {
            showType: this.loadViewType(),
            expandType: this.loadExpandType(),
            isLoading: true,
            searchBy: this.props.searchBy,
            sortBy: this.props.sortBy,
            ids: this.props.collection.pluck('id')
        };
    },

    // this is specific for creatives right now
    loadExpandType() {
        var vPref = globalCh.request('view-pref');
        var prefId = `show_type_for_${this.props.itemType}`;
        return vPref[prefId] || 'compact';
    },

    // this is specific for ads, campaigns
    loadViewType() {
        var vPref = globalCh.request('view-pref');
        var prefId = `show_type_for_${this.props.itemType}`;
        return vPref[prefId] || 'percentages';
    },

    toggleExpand() {
        var toggleTo = this.state.expandType === 'expanded' ? 'compact': 'expanded';

        this.setState({expandType: toggleTo});

        var pref = {};
        var prefId = `show_type_for_${this.props.itemType}`;
        pref[prefId] = toggleTo;
        globalCh.command('save-view-pref', pref);

    },

    componentWillReceiveProps(nextProps) {
        var {searchBy, sortBy} = nextProps;
        this.setState({searchBy, sortBy});
    },

    toggleDisplay() {
        var toggleTo = this.state.showType === 'percentages'? 'values' : 'percentages';
        var pref = {};
        var prefId = `show_type_for_${this.props.itemType}`;
        pref[prefId] = toggleTo;

        globalCh.command('save-view-pref', pref);
    },

    componentDidMount() {
        this.expandEmptyviewHeight();
        this.initEvents();
    },

    initEvents() {
        this.privateCh.listenTo(this.props.collection, 'reset add remove', ()=>this.setState({ids: this.props.collection.pluck('id')}));
        this.privateCh.listenTo(globalCh, 'saved-view-pref', ()=>this.setState({
            showType: this.loadViewType(),
            expandType: this.loadExpandType()
        }));
    },


    /**
     * make sure order and content are compared
    */
    isDifferent(ids1, ids2) {
        if (ids1.length != ids2.length ) {
            return true;
        } else {
            for (var i = 0, l = ids1.length; i < l; i ++) {
                var v = ids1[i];
                if (v != ids2[i]) {
                    return true;
                }
            }
        }
        return true;
    },

    shouldComponentUpdate(nextProps, nextState) {
        var newIds = nextProps.collection.pluck('id');
        var changed = this.isDifferent(newIds, this.state.ids);
        var {showType, expandType, isLoading, searchBy, sortBy} = this.state;
        var itemType =  nextProps.itemType;

        return changed  || 
            itemType    != this.props.itemType  ||
              showType  != nextState.showType   ||
             expandType != nextState.expandType ||
             searchBy   != nextState.searchBy   ||
             sortBy     != nextState.sortBy     ||
             isLoading  != nextState.isLoading;
    },

    componentWillMount() {
        this.privateCh.reset();
    },

    componentDidUpdate() {
        this.initEvents();
        this.expandEmptyviewHeight();
    },

    expandEmptyviewHeight() {
        var $el = $(this.getDOMNode());
        if ($el.find('.empty').length === 0) {
            return;
        }
        var footer = $el.find('.list-footer');
        var offset = $(window).height() - footer.offset().top - footer.outerHeight() - 20;
        var empty = $el.find('.empty');
        empty.height(empty.height() + offset);
    },

    render: function () {
        var ItemClass = this.props.ItemClass;
        var content, sortParam = this.state.sortBy;
        var filterFn = (i)=>i.searchTerm(this.state.searchBy);
        var sortFn = (i1, i2)=>i1.get(sortParam) > i2.get(sortParam) ? 1: -1;

        var showingLength = 0;

        if (this.props.collection != null && !this.props.collection.isEmpty()) {
            var itemsToDisplay = this.props.collection.filter(filterFn);
            if (this.props.sortBy) {
                itemsToDisplay = itemsToDisplay.sort(sortFn);
            }

            if (itemsToDisplay.length === 0) {
                content = <div className="empty">No Entries found</div>
            } else {
                content = itemsToDisplay.map(function (item) {
                    return <ItemClass key={`campaign-${item.id}`} model={item} showType={this.state.showType} ch={this.props.ch} showDetail={this.state.expandType === 'expanded'}/>;
                }, this);
            }
            showingLength = itemsToDisplay.length;
        } else {
            content = <div className="empty">No Entries found</div>
        }

        var ListHeader =this.props.HeaderClass || require('./list-header');
        var ListFooter = this.props.FooterClass || require('./list-footer');

        return (
            <div className="ef-list-layout" key={_.uniqueId('list-layout')}>

                <ListHeader ch={this.props.ch}
                    collection={this.props.collection}
                    itemType={this.props.itemType}
                    canEdit={this.props.canEdit}
                    filteredCount={showingLength} />

                <div className="list-inner">{content}</div>

                <ListFooter ch={this.props.ch}
                    collection={this.props.collection}
                    itemType={this.props.itemType}
                    showType={this.state.showType}
                    isExpanded={this.state.expandType === 'expanded'}
                    toggleDisplay={this.toggleDisplay}
                    toggleExpand={this.toggleExpand} />
            </div>
        );
    }
});

module.exports = List;
