'use strict';

var layoutTemplate = require('./templates/layout');

var ConfirmView = Mario.Layout.extend({
    template: layoutTemplate,

    done: function () {
        this.trigger('popup:close');
    },

    events: {
        'click [data-action=proceed]' : 'proceed'
    },

    proceed: function () {
        this.trigger('proceed');
    }
});

module.exports = ConfirmView;
