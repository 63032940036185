<div class="modal-header">
    <h3>Create a new creative [{{format}}]</h3>
</div>

<div class="modal-body">

    <div class="row">

        <div class="col-xs-6">

            <div class="row">
                <!-- size is required by all -->
                <div class="col-xs-6">
                    <div class="form-group">
                        <label class="control-label">Size</label>
                        <select class="form-control" data-id="size"></select>

                        <div class="help-block "></div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-xs-10">
                    <div class="form-group">
                        <label class="control-label">Name</label>
                        <input class="form-control" type="text" data-id="name"/>

                        <div class="help-block "></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-10">
                    <div class="form-group field native-required hide">
                        <label class="control-label">Title</label>
                        <input class="form-control" type="text" data-id="title"/>

                        <div class="help-block "></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-10">
                    <div class="form-group field native-required hide">
                        <label class="control-label">Text</label>
                        <textarea class="form-control" type="text" data-id="text"></textarea>

                        <div class="help-block "></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-10">
                    <small class="pull-right">Tips: click the assets to insert link.</small>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-10">
                    <div class="form-group field video-required hide">
                        <label class="control-label">Video Url</label>
                        <input class="form-control" type="text" data-id="video_url"/>

                        <div class="help-block "></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-10">
                    <div class="form-group field video-required hide">
                        <label class="control-label">Video Preview Url
                            <small>(optional)</small>
                        </label>
                        <input class="form-control" type="text" data-id="video_preview_url"/>

                        <div class="help-block "></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">
                    <div class="form-group field video-required hide">

                        <div class="row">
                            <div class="col-xs-4">
                                <label class="control-label" style="margin-top: 23px;">Video Duration </label>
                            </div>
                            <div class="col-xs-4" style="border: 1px solid #CCC; border-radius: 4px; margin-left: 15px;">
                                <!-- auto select in when it's an video asset on our server -->
                                <div class="radio">
                                    <label>
                                        <input type="radio" name="video_duration" data-id="video_duration" value="15">
                                        15 seconds
                                    </label>
                                </div>

                                <div class="radio">
                                    <label>
                                        <input type="radio" name="video_duration" data-id="video_duration" value="30">
                                        30 seconds
                                    </label>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-10">
                    <div class="form-group field native-required">
                        <label class="control-label">Icon image url</label>
                        <input class="form-control" type="text" data-id="icon_image_url"/>

                        <div class="help-block "></div>
                    </div>
                </div>
            </div>

            <!-- image preview url -->
            <div class="row">
                <div class="col-xs-10">
                    <div class="form-group field native-required">
                        <label class="control-label">Icon image preview url</label>
                        <input class="form-control" type="text" data-id="icon_image_preview_url"/>

                        <div class="help-block "></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-10">
                    <div class="form-group field standard-required native-required">
                        <label class="control-label">Image Url</label>
                        <input class="form-control" type="text" data-id="image_url"/>

                        <div class="help-block "></div>
                    </div>
                </div>
            </div>

            <!-- image preview url -->
            <div class="row">
                <div class="col-xs-10">
                    <div class="form-group">
                        <label class="control-label">Image Preview Url</label>
                        <input class="form-control" type="text" data-id="image_preview_url"/>

                        <div class="help-block "></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-5">
                    <div class="form-group field native-required hide">
                        <label class="control-label">Call to action</label>
                        <input class="form-control small" type="text" data-id="call_to_action"/>

                        <div class="help-block "></div>
                    </div>
                </div>
                <div class="col-xs-5">
                    <div class="form-group field native-required hide">
                        <label class="control-label">Star rating</label>
                        <input class="form-control small" type="text" data-id="star_rating"/>

                        <div class="help-block "></div>
                    </div>
                </div>
            </div>

            <!-- clickthrough url is required by standard and video, optional for the rest -->
            <div class="row">
                <div class="col-xs-10">
                    <div class="form-group">
                        <label class="control-label">Clickthrough Url</label>
                        <input class="form-control" type="text" data-id="clickthrough_url"/>

                        <div class="help-block "></div>
                    </div>
                </div>

                <div class="col-xs-10">
                    <div class="form-group">
                        <div class="checkbox click-tracker">
                            <label class="control-label">Third party click tracker
                                <input type="checkbox" data-id="third_party_clickthrough">
                            </label>
                        </div>

                        <div class="{{#unless third_party_clickthrough}} hide {{/unless}}">
                            <label class="control-label">Landing Page</label>
                            <input type="text" class="form-control" data-id="landing_page"
                                   value="{{#if third_party_clickthrough}}{{landing_page}}{{/if}}">

                            <div class="help-block "></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-10">
                    <div class="form-group markup field mraid-required custom_html-required hide">
                        <label class="control-label">Custom HTML</label>

                        <div class=" embed"></div>
                        <div class="help-block "></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-10">
                    <div class="form-group field third-party-pixels standard-required video-required hide">
                        <label class="control-label">Third Party Pixels
                            <small>(optional)</small>
                        </label>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-xs-6 assets-helper">
        </div>

    </div>


</div>

<div class="modal-footer">

    <div class="row">
        <div class="col-xs-10">
            <button class="btn btn-success" data-action="save-and-audit">
                <i class="fa fa-floppy-o"></i>
                {{#if id}}
                    Save and Audit
                {{else}}
                    Create and Audit
                {{/if}}
            </button>

            <button class="btn btn-primary" data-action="save">
                <i class="fa fa-floppy-o"></i>
                {{#if id}}
                    Save
                {{else}}
                    Create
                {{/if}}
            </button>
        </div>
    </div>
</div>