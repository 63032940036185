'use strict';

var summaryTemplate = require('./templates/summary');
var fns = require('scripts/common/fns');
var langCodes = require('scripts/common/constants/language-codes');
var countries = require('scripts/common/constants/countries');
var appCategoryTargeting = require('scripts/common/constants/app-category-targeting');

var SummaryView = Mario.Layout.extend({
    template: summaryTemplate,
    className: 'ad-summary panel panel-info',

    initialize: function () {
        // cache store
        this._store = {};
    },

    templateHelpers: function () {
        return {
            embed: this.options.embed
        };
    },

    onRender: function () {
        if (this.options.embed) {
            this.$el.addClass('embed').removeClass('panel panel-info');
        }
    },

    onShow: function () {
        this.listenTo(this.model, 'updateFormValue', this.update);
        // initialize
        var attrs = this.model.attributes;
        _.each(attrs, this.update, this);
    },

    update: function (val, attr) {
        /* jshint maxcomplexity: 30 */
        var formatted, template;
        var that = this;
        var dateformat = globalCh.request('settings:dateformat');
        this._store[attr] = val;

        switch (attr) {
            case 'notes':
                formatted = S(val).wrapHTML('span').s;
                break;
            case 'start':
            case 'end':
                if (_.isEmpty(val)) {
                    formatted = '';
                } else if (val === 'now') {
                    formatted = 'As soon as possible.';
                } else {
                    var timezone = this._store['timezone'];

                    if (_.isEmpty(timezone)) {
                        timezone = this.model.get('timezone');
                    }
                    // val is ISO string in UTC
                    // display it using user set timezone
                    this.updateTimezoneDisplays(timezone);
                    formatted = moment(val).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
                }
                break;
            case 'max_bid_cpm':
            case 'billing_rate':
                formatted = fns.formatCurrency(val);
                break;
            case 'max_daily_impressions':
            case 'max_total_impressions':
            case 'max_daily_clicks':
            case 'max_total_clicks':
            case 'max_daily_spend':
            case 'max_total_spend':
                if (val !== 0) {
                    formatted = fns.formatNumber(val, 'thousands');
                } else {
                    formatted = 'Unlimited';
                }
                break;
            case 'target_device_os':
                formatted = _.compact(val).join(', ');
                break;
            case 'target_device_language':
                if (_.isEmpty(val)) {
                    formatted = 'Any language';
                } else {
                    formatted = langCodes[val];
                }
                break;
            case 'dayparts':
                if (_.isEmpty(val)) {
                    formatted = 'Full day';
                } else {
                    val = fns.convertDaypartsByTimezone(val, 'UTC', this._store['timezone']);
                    val = fns.mergeTimeranges(val);
                    template = _.template('<li><%=start%> <span data-abbr="timezone daypart"><%=tz%></span>~<%=end%> <span data-abbr="timezone daypart"><%=tz%></span></li>');
                    formatted = _.map(val, function (part) {
                        var tz = moment().tz(that._store['timezone']).format('z');
                        return template(_.extend(part, {tz: tz}));
                    });
                    formatted = S(formatted.join('')).wrapHTML('ul').s;
                }
                break;
            case 'weekparts':
                var names = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
                formatted = _.compact(_.map(val, function (bool, idx) {return bool ? names[idx] : '';})).join(', ');
                break;
            case 'geotargets':
                formatted = S(_.map(val, formatGeoCountry).join('')).wrapHTML('ul').s;
                break;
            case 'geoboxes':
                template = _.template('<div>E: <%=E%>, W: <%=W%>, S: <%=S%>, N: <%=N%></div> ');
                formatted = _.map(val, function (box) {
                    var shorter = {};
                    _.each(box, function (val, key) {
                        shorter[key] = val.toFixed(2);
                    });
                    return template(shorter);
                }).join('');
                break;
            case 'creative':
                var creative = this.options.creatives.get(val);
                formatted = creative ? creative.get('name') : '';

                break;
            case 'target_genders':
                if (_.isArray(val)) {
                    if (val.length === 0 ) {
                        formatted = 'Everyone';
                    } else {
                        var displayTextArray = [];
                        _.forEach(val, function(item){
                            if (item === 'M') {
                                displayTextArray.push( 'Male' );
                            } else if (item === 'F') {
                                displayTextArray.push( 'Female' );
                            } else if (item === 'D') {
                                displayTextArray.push( 'Unknown' );
                            } else {
                                throw new Error( 'Gender array is not empthy and contains unregonize value');
                            }
                        })
                        formatted = displayTextArray.join(', ');
                    } // End else
                }
                break;

            case 'target_age_groups':
                if (val.length === 0) {
                    formatted = 'Everyone';
                } else {
                    var displayTextArray = [];
                    _.forEach(val, function(item){
                        switch (item) {
                            case 1:
                                displayTextArray.push( '13-17' );
                                break;
                            case 2:
                                displayTextArray.push( '18-24' );
                                break;
                            case 3:
                                displayTextArray.push( '25-34' );
                                break;
                            case 4:
                                displayTextArray.push( '35+' );
                                break;
                            case -1:
                                displayTextArray.push( 'Unknown' );
                                break;
                            default:
                                throw new Error( 'Age group array is not empthy and contains unregonize value');
                        }
                    })
                    formatted = displayTextArray.join('<br>');
                } // End else
                break;
            case 'timezone':
                this.updateTimezoneDisplays(val);
                formatted = val;
                break;
            case 'target_app_categories':
                if (_.isArray(val)) {
                    formatted = _.map(val, function(i) { return appCategoryTargeting[i]}).join(', ');
                } else {
                    formatted = val;
                }

                break;
            default:
                formatted = val;
        }

        if (_.isEmpty(val) || _.isEmpty(formatted)) {
            //formatted = 'Not set';
        }

        this.$el.find('[summary-id="' + attr + '"]').html(formatted);

        if (!this.options.embed) {
            this.$el.find('[summary-id="' + attr + '"]').animate({'background-color': '#FFF5CD'}, {
                duration : 1000,
                complete: function () {
                    $(this).animate({'background-color': 'white'});
                },
                queue   : false
            });
        }

    },

    updateTimezoneDisplays: function (tz) {
        var start = this._store['start'];
        var end = this._store['end'];

        if (!_.isEmpty(start)) {
            // dayparts timezone follow start date's timezone
            this.$('[data-abbr="timezone daypart"]').text(moment(start).tz(tz).format('z'));
            this.$('[data-abbr="timezone start"]').text(moment(start).tz(tz).format('z'));
        }

        if (!_.isEmpty(end)) {
            this.$('[data-abbr="timezone end"]').text(moment(end).tz(tz).format('z'));
        }
    }
});

function formatGeoCountry(obj) {
    var html = '', content, tpl, countryName = fns.getCountryName(obj.country);

    if (!_.isEmpty(obj.include)) {
        tpl =   '<div class="regions">' +
                    '<div><span class="label label-success">i</span></div><div title="<%=content%>"><%=content%></div>' +
                '</div>';

        content = _.compact(_.map(obj.include, _.partial(fns.formatGeoRegionCity, obj.country))).join(', ');
        html += _.template(tpl)({content: content});
    }

    if (!_.isEmpty(obj.exclude)) {
        tpl =   '<div class="regions">' +
                    '<div><span class="label label-danger">e</span></div><div title="<%=content%>"><%=content%></div>' +
                '</div>';

        content = _.compact(_.map(obj.exclude, _.partial(fns.formatGeoRegionCity, obj.country))).join(', ');
        html += _.template(tpl)({content: content});
    }

    tpl = '<li><div><u><%=country_name%></u></div><%=content%></li>';

    return _.template(tpl)({country_name: countryName, content: html});
}

module.exports = SummaryView;
