{{#unless embed}}
    <div class="panel-heading"><h3 class="panel-title">Summary</h3></div>
    <div class="panel-body">
{{/unless}}
    <h6>Basics</h6>

    <dl class="dl-horizontal">
        <dt>Name</dt>
        <dd summary-id="name"></dd>

        <dt>Start</dt>
        <dd><span summary-id="start"></span> <span data-abbr="timezone start"></span></dd>

        <dt>End</dt>
        <dd><span summary-id="end"></span> <span data-abbr="timezone end"></span></dd>

        <dt>Timezone</dt>
        <dd summary-id="timezone"></dd>

        <dt>Notes</dt>
        <dd summary-id="notes"></dd>
    </dl>

    <h6>Budget</h6>

    <dl class="dl-horizontal">
        <dt>Max Bid</dt>
        <dd summary-id="max_bid_cpm">$0.00</dd>

        <dt>Impressions Daily Cap</dt>
        <dd summary-id="max_daily_impressions">0</dd>

        <dt>Impressions Total Target</dt>
        <dd summary-id="max_total_impressions">0</dd>

        <dt>Clicks Daily Cap</dt>
        <dd summary-id="max_daily_clicks">0</dd>

        <dt>Clicks Total Target</dt>
        <dd summary-id="max_total_clicks">0</dd>

        <dt>Spend Daily Cap</dt>
        <dd summary-id="max_daily_spend">0</dd>

        <dt>Spend Total Target</dt>
        <dd summary-id="max_total_spend">0</dd>
    </dl>

    <h6>Revenue Management</h6>
    <dl class="dl-horizontal">

        <dt>Billing Terms</dt>
        <dd summary-id="billing_terms">CPC</dd>

        <dt>Billing Rate</dt>
        <dd summary-id="billing_rate">$0.00</dd>
    </dl>


    <h6>Geotargeting</h6>
    <dl class="dl-horizontal">

        <dt>Geotargeting</dt>
        <dd summary-id="geotargets"></dd>
    </dl>
    <h6>Demographics</h6>
    <dl class="dl-horizontal">
        <dt>Age Groups</dt>
        <dd summary-id="target_age_groups"></dd>

        <dt>Gender</dt>
        <dd summary-id="target_genders"></dd>
    </dl>

    <h6>Advanced</h6>
    <dl class="dl-horizontal">
        <dt>User Language</dt>
        <dd summary-id="target_device_language"></dd>

        <dt>Operating System</dt>
        <dd summary-id="target_device_os"></dd>

        <dt>Dayparting</dt>
        <dd>
            <div summary-id="dayparts"></div>
        </dd>

        <dt>Weekparting</dt>
        <dd summary-id="weekparts"></dd>

        <dt>Geofencing</dt>
        <dd summary-id="geoboxes"></dd>

        <dt>User Frequency</dt>
        <dd summary-id="max_user_frequency"></dd>

        <dt>App Categories</dt>
        <dd summary-id="target_app_categories"></dd>

    </dl>

    <h6>Creative</h6>
    <dl class="dl-horizontal">
        <dt>Creative</dt>
        <dd summary-id="creative"></dd>
    </dl>

{{#unless embed}}
    </div>
{{/unless}}
