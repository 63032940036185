'use strict';

var flux = require('scripts/core/flux');
var campaigns = window.campaigns = require('scripts/core/campaign-collection');

var _campaigns = {};
var _campaignsSidebarProjection = [];
var _campaignsOverviewProjection = [];

var CampaignsStore = Fluxxor.createStore({

    initialize: function (options) {
        this.bindActions(
            flux.constants.campaign_pause, 'onCampaign_pause',
            flux.constants.campaigns_refresh, 'onCampaigns_refresh',
            flux.constants.campaignsOverview_fetch_success, 'onCampaignsOverview_fetch_success'
        );
    },

    onCampaigns_refresh: function () {
        // campaigns.refresh();
    },

    onCampaignsOverview_fetch_success: function (overviewCampaigns) {
        mergeCampaigns(overviewCampaigns);

        _campaignsOverviewProjection = _.pluck(overviewCampaigns, 'id');

        this.emit('change');
    },

    onCampaign_pause: function (campaignId) {
        _campaigns[campaignId].paused = true;

        this.emit('change');
    },

    getStartDate: function (campaignId) {
        var campaign = campaigns.get(campaignId);

        if (!campaign) {
            return null;
        } else {
            return campaign.get('start');
        }
    },

    getById: function (campaignId) {
        var campaign = campaigns.get(campaignId);

        if (!campaign) {
            return null;
        } else {
            return campaign.toJSON();
        }
    }
});

function mergeCampaigns (newCampaigns) {
    newCampaigns.forEach(function (campaign) {
        _campaigns[campaign.id] = campaign;
    });
}

flux.addStore('campaigns', new CampaignsStore());
