'use strict';

/**
 * patch Marionette classes
 *
 */

var Mario = window.Mario = {};
var flux = require('scripts/core/flux');
var _currentRoute = {};

globalCh.reply('currentRoute', function () {
    return _currentRoute;
});

Mario.Controller = Marionette.Controller.extend();
Mario.Application = Marionette.Application.extend();
Mario.AppRouter = Marionette.AppRouter.extend({
    onRoute: function (name, path, args) {
        _currentRoute = {
            name: name,
            path: path,
            args: args,
            location: window.location.pathname
        };

        flux.actions.router.navigate(_currentRoute);
        globalCh.trigger('route', name, path, args);
    }
});
Mario.ItemView = Marionette.ItemView.extend();
Mario.View = Marionette.View.extend();
Mario.Layout = Marionette.LayoutView.extend();
Mario.CollectionView = Marionette.CollectionView.extend();
Mario.CompositeView = Marionette.CompositeView.extend();
Mario.Region = Marionette.Region.extend();
Mario.Behavior = Marionette.Behavior.extend();


window.Behaviors = {};
Marionette.Behaviors.behaviorsLookup = function() {
    return window.Behaviors;
};

// holder a reference to original constructor
/*
var MVContor = Marionette.View.prototype.constructor;
Marionette.View.prototype.constructor = function (options) {

    MVContor.apply(this, arguments);

    // inject mixins here
    this.listenTo(this, 'show', function () {

        var that = this;

        // need to defer to next frame so it fires after `onShow` method
        _.defer(function () {
            if (that.mixins) {
                _.each(that.mixins, function (mixin) {
                    mixin._init.call(that);
                }, that);
                that.delegateEvents();
            }
        });
    });

};

*/

/**
 * Filter function for collection/composite view
 *
 * views using filter need to set a filterSetup attribute
 * these functions determine the behaviors of the matched/unmatched view
 * {
 *   matchedFn: function () {},
 *   notMatchedFn: function () {}
 * }
 *
 * views model need to have a `matchFilter` method
 *
 * To trigger filter, just trigger the `filter` event of the view and supplying conditions object
 * @param conditions - attrs of model to match
 */
var collectionViewFilter = function (conditions) {
    // remember filter
    this._filterConditions = conditions;

    var setup = this.filterSetup;

    if (!_.isObject(setup) || this.collection.length === 0) {
        return;
    }

    this.children.filter(function (childView) {

        if (_.isObject(childView.model) &&
            _.isFunction(childView.model.matchFilter) &&
            childView.model.matchFilter(conditions) &&
            _.isFunction(setup.matchedFn))

        {
            setup.matchedFn.call(childView);
        } else if (_.isFunction(setup.notMatchedFn)) {
            // reset if notMatchFn is given
            setup.notMatchedFn.call(childView);
        }
    });
};

/* patch CollectionView */
/*
Mario.CollectionView = Marionette.CollectionView.extend({

    removeItemView: function (item) {
        var view = this.children.findByModel(item);
        this.removeChildView(view);
        this.checkEmpty();
        this._updateIndices(view, false);
    },

    _initialEvents: function () {
        if (this.collection) {
            this.listenTo(this.collection, 'add', this.addChildView);
            this.listenTo(this.collection, 'remove', this.removeItemView);
            this.listenTo(this.collection, 'reset', this.render);
            this.listenTo(this.collection, 'sort', this._sortView);

            this._filterConditions = null;
            this.listenTo(this.collection, 'filter', this.filter, this);
            this.listenTo(this.collection, 'sync', function () {
                this.filter(this._filterConditions);
            }, this);
        }
    },

    filter: collectionViewFilter,

    addItemView: function (item, ItemView, index) {
        var childViewOptions = Marionette.getOption(this, 'childViewOptions');

        if (_.isFunction(childViewOptions)) {
            childViewOptions = childViewOptions.call(this, item, index);
        }

        var view = this.buildItemView(item, ItemView, childViewOptions);

        this.addChildViewEventForwarding(view);

        this.triggerMethod('before:item:added', view);

        // ADDED: update index
        this._updateIndices(view, true, index);

        this.children.add(view);

        this.renderItemView(view, index);

        if (this._isShown && !this.isBuffering) {
            Marionette.triggerMethod.call(view, 'show');
        }

        this.triggerMethod('after:item:added', view);

        return view;
    },

    appendHtml: function (collectionView, childView, index) {
        if (collectionView.isBuffering) {
            collectionView.elBuffer.appendChild(childView.el);
            collectionView._bufferedChildren.push(childView);
        } else {
            var currentView = this.children.find(function (view) {
                return view._index === index + 1;
            });

            if (currentView) {
                currentView.$el.before(childView.el);
            } else {
                collectionView.$el.append(childView.el);
            }
        }
    },

    _updateIndices: function (view, increment, index) {

        if (!view) {
            return;
        }

        if (increment) {
            view._index = index;

            this.children.each(function (laterView) {
                if (laterView._index >= view._index) {
                    laterView._index = laterView._index + 1;
                }
            });
        } else {
            this.children.each(function (laterView) {
                if (laterView._index >= view._index) {
                    laterView._index = laterView._index - 1;
                }
            });
        }
    },

    _sortView: function () {
        var orderChanged = this.collection.find(function (item, index) {
            var view = this.children.findByModel(item);
            return view && view._index !== index;
        }, this);

        if (orderChanged) {
            this.render();
        }
    }
});

var orginalCompositeViewProto = Marionette.CompositeView.prototype;

var newCompositeViewProto = _.extend(orginalCompositeViewProto, {
    constructor: function () {
        Mario.CollectionView.prototype.constructor.apply(this, arguments);

        this._filterConditions = null;

        this.listenTo(this.collection, 'filter', this.filter, this);

        this.listenTo(this.collection, 'sync', function () {
            this.filter(this._filterConditions);
        }, this);
    },

    filter: collectionViewFilter,

    _insertAfter: function (childView) {
        var $container = this.getChildViewContainer(this);
        $container.append(childView.el);
    },

    _renderChildren: function() {
        if (this.isRendered) {
            this.triggerMethod('composite:collection:before:render');
            Mario.CollectionView.prototype._renderChildren.call(this);
            this.triggerMethod('composite:collection:rendered');
        }
    },

    _initialEvents: function () {
        this.once('render', function () {
            if (this.collection) {
                this.listenTo(this.collection, 'add', this.addChildView);
                this.listenTo(this.collection, 'remove', this.removeItemView);
                this.listenTo(this.collection, 'reset', this._renderChildren);
                this.listenTo(this.collection, 'sort', this._sortView);
            }
        });
    }
});

Mario.CompositeView = Mario.CollectionView.extend(newCompositeViewProto);
*/


Mario.Model = Backbone.Model.extend({

    constructor: function (attributes, options) {

        if (_.has(options, 'urlRoot')) {
            this.urlRoot = options.urlRoot;
        } else if (_.has(options, 'collection')) {
            this.urlRoot = options.collection.url;
        }

        this.lastUpdate = null;

        this.on('sync', function () {
            this.lastUpdate = Date.now();
        });

        Backbone.Model.apply(this, arguments);
    },

    /**
     * check whether this model matches given conditions
     * @param {object} conditions - attribute pairs
     * @returns {boolean}
     */
    matchFilter: function (conditions) {
        if (!_.isObject(conditions)) {
            return true;
        }
        return _.every(conditions, function (val, key) {
            return this.get(key) === val;
        }, this);
    },

    // support query string
    url: function() {
        /* jshint maxcomplexity: 10 */
        var base =
            _.result(this, 'urlRoot') ||
            _.result(this.collection, 'url');

        if (!base) {
            throw new Error('A "url" property or function must be specified');
        }

        if (this.isNew()) {
            return base;
        }

        var split = base.split('?');
        if (split.length === 1) {
            return base.replace(/([^\/])$/, '$1/') + encodeURIComponent(this.id);
        } else if (split.length === 2) {
            return split[0].replace(/([^\/])$/, '$1/') + encodeURIComponent(this.id) + '?' + split[1];
        } else {
            throw new Error('Invalid model url: ' + base);
        }
    },

    get: function(attr) {
        if (_.isFunction(this.getters[attr])) {
            return this.getters[attr].call(this);
        }
        return Backbone.Model.prototype.get.call(this, attr);
    },

    set: function (key, value, options) {
        /* jshint maxcomplexity: 20 */
        var attrs, attr;
        if (_.isObject(key) || key == null) {
            attrs = key;
            options = value;
        } else {
            attrs = {};
            attrs[key] = value;
        }
        options = options || {};
        for (attr in attrs) {
            if (_.isFunction(this.setters[attr])) {
                attrs[attr] = this.setters[attr].call(this, attrs[attr], options);
            }
        }
        return Backbone.Model.prototype.set.call(this, attrs, options);
    },
    getters: {},
    setters: {},

    toString: function () {
        return JSON.stringify(this.toJSON());
    },

    isChanged: function (json) {

        var keys = _.keys(this.attributes);

        var changed = _.find(keys, function (k) {
            var oldVal = this.attributes[k];
            var newVal = json[k];

            if (typeof oldVal != typeof newVal) {
                return true;
            }

            switch (typeof oldVal) {
                case 'string':
                case 'number':
                    return oldVal !== newVal;
                case 'object':
                    if (_.isArray(oldVal)) {
                        return !_.isEmpty(_.difference(oldVal, newVal));
                    } else {
                        try {
                            return JSON.stringify(newVal) !== JSON.stringify(oldVal);
                        } catch (e) {
                            // object is not comparable
                            // always return true
                            return true;
                        }
                    }
            }
        }, this);

        return changed != null;

    },

    checkChangedAttr: function (val, name) {
        var changed = this.has(name) && !_.isEqual(this.get(name), val);
        return changed;
    },

    hasSynced: function () {
        return this.lastUpdate !== null;
    },

    save: function(key, val, options) {
        // Note: Copied value detection from backbone source

        // Handle both `"key", value` and `{key: value}` -style arguments.
        var attrs;
        if (key == null || typeof key === 'object') {
            attrs = key;
            options = val;
        } else {
            (attrs = {})[key] = val;
        }

        if (_.contains(['Campaign', 'Ad', 'Creative'], this.modelName)) {
            if (options === undefined) {
                options = {};
            }
            options.ifMatch = this.get('_etag');
        }

        // Proxy the call to the original save function
        Backbone.Model.prototype.save.call(this, attrs, options);
    }
});

Mario.Collection = Backbone.PageableCollection.extend({
    toString: function () {
        return JSON.stringify(this.toJSON());
    },

    constructor: function (models, options) {
        Backbone.Collection.apply(this, arguments);

        this.lastUpdate = null;
        var that = this;

        this.on('sync', function () {
            this.lastUpdate = Date.now();
        });

        this.on('reset', function () {

            this.each(function (m) {
                m.lastUpdate = that.lastUpdate;
            });
        });
    },

    hasSynced: function () {
        return this.lastUpdate !== null;
    }
});
/*
Mario.Region = Marionette.Region.extend({

    open: function(view) {
        this.$el.empty().append(view.el).children(':last').hide().fadeIn(400);
    }

});*/
