'use strict';
/**
 *
 * Show a message specified,  after a specified amount of time inform
 * user timeout and trigger 'loading:timeout' event via radio channel.
 *
 * @property {integer} displayTimeout  - number of mili-second to trigger timeout
 * @property {string}  displayMessage  - message to display
 * @property {string}  displayPosition - center | top-left
 * @property {object}  ch              - backbone.radio
 */

var PrivateChannel = require('scripts/common/mixins/private-channel');

var LoadingLabel = React.createClass({

    mixins: [PrivateChannel],

    getInitialState: function(){
        return {displayMessage: this.props.displayMessage }
    },

    updateMessage: function(){
        this.setState ({displayMessage: 'Something is wrong with communication, please try again later.' })
    },

    componentDidMount(){

        var that = this;
        if (this.props.ch) {
            this.timeoutHandle = setTimeout( function() {
                that.props.ch.trigger('loading:timeout');
                that.updateMessage();
            }, this.props.displayTimeout );
        }
    },

    componentWillUnmount(){

        if (this.timeoutHandle !== null ) {
            clearTimeout( this.timeoutHandle )
        }

    },
    render:function(){
        return (
            <div className={'ef-loading-label ' + this.props.displayPosition} >
                <div className="content-outer">
                    <div className="content">
                        <div className="content-inner">
                            <div className="animation"></div>
                            <div className="message">
                                <div className="message-inner">
                                    {this.state.displayMessage}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
});

module.exports = LoadingLabel;
