'use strict';
var flux = require('scripts/core/flux');
var CustomFluxMixin = require('scripts/common/mixins/CustomFluxMixin');
var D3Chart = require('./d3Chart');
var testData = require('./dummyData');

var ChartExplorerSummary = React.createClass({

    // ===================
    // Component setup and tear down
    // ===================
    componentWillMount() {
        this.debug = false;
    },

    componentDidMount() {
        const that = this;
        var el = this.el = this.getDOMNode();
        var $el = this.$el = $(el);
        this.$tooltip = $el.find('.am-reports-toolTip');

        this.d3Chart =  new D3Chart;

        // For Debug
        // if (window.rpt) {
        //     window.rpt.push(this.d3Chart);
        // } else {
        //     window.rpt = [];
        //     window.rpt.push(this.d3Chart);
        // }


        this.stringifiedData = JSON.stringify(this.props);

        var state = this.prepareGraphState(this.props);
        this.d3Chart.create(el, state);

        this.caculateElementOffsets();
        window.addEventListener('resize', this.windowHandleResize);
        window.addEventListener('scroll', this.windowHandleScroll);

        this.updateMessage(this.props, {isCreating: true});
    },

    componentWillUnmount () {
        const el = this.el;

        window.removeEventListener('resize', this.windowHandleResize);
        window.removeEventListener('scroll', this.windowHandleScroll);
        this.d3Chart.distroy(el);
    },



    // ===================
    // tooltips coordinate
    // ===================
    caculateElementOffsets () {
        this.el_offset_viewPort = this.$el[0].getBoundingClientRect();
        this.el_offset = this.$el.offset();
    },

    updateToolTipsCoordinate() {
        if (this.el && this.el.toolTipsPosition ) {
            const left = this.el.toolTipsPosition.x - this.el_offset.left;
            const top  = this.el.toolTipsPosition.y - this.el_offset_viewPort.top;

            const toolTipWidth = this.$tooltip.width();
            const componentWidth = this.$el.width();
            const leftOverSpace = componentWidth - left;

            let left_adjusted;
            if (leftOverSpace >  (toolTipWidth + this.el.toolTipsOffset) ) {
                left_adjusted = left;
            } else {
                left_adjusted = left - toolTipWidth - 2*this.el.toolTipsOffset ;
            }

            this.$tooltip.css({left:left_adjusted, top});
        }
    },



    // ===================
    // Window event's callbacks
    // ===================
    windowHandleScroll(e) {
        this.caculateElementOffsets();
        this.updateToolTipsCoordinate();
    },

    windowHandleResize(e) {
        this.caculateElementOffsets();
        this.updateToolTipsCoordinate();
    },




    // ===================
    // React component event's callbacks
    // ===================
    reactHandleMouseMove (e) {
        // const x = e.pageX;    //<----  comented out because mouse coordinate here is reading from svg user coordinate
        // const y = e.pageY;
        this.caculateElementOffsets();
        this.updateToolTipsCoordinate();
    },

    reactHandleMouseOver (e) {
        if (!this._isLoading) this.$tooltip.css({display:'block'});
    },

    reactHandleMouseOut (e) { this.$tooltip.css({display:'none'}); },




    // ===================
    // Chart's overlay visibility control
    // ===================
    chartOverlay_show_message: function(opts) {
        this.$el.find('.am-reports-timeSeriesGraph-overlay').hide();
        this.$el.find('.am-reports-timeSeriesGraph-overlay-message').show();

        this.$el.find('.am-reports-timeSeriesGraph-messageContainer-title').html(opts.title);
        this.$el.find('.am-reports-timeSeriesGraph-messageContainer-body').html(opts.message);
        this.$el.find('.am-reports-timeSeriesGraph-messageContainer-icon').attr('src', opts.img);
    },

    chartOverlay_show_loader: function() {
        this.$el.find('.am-reports-timeSeriesGraph-overlay').hide();
        this.$el.find('.am-reports-timeSeriesGraph-overlay-loading').show();
    },

    chartOverlay_hide_all: function() {
        this.$el.find('.am-reports-timeSeriesGraph-overlay').hide();
    },





    // ==================
    // Chart data
    // ==================
    shouldComponentUpdate: function (nextProps, nextState) {
        // dont re-render if nothing changed
        var newStringifiedData = JSON.stringify(nextProps);
        if (newStringifiedData === this.stringifiedData) { return false; }

        this.updateMessage(nextProps);

        this.stringifiedData = newStringifiedData;

        var state = this.prepareGraphState(nextProps);

        const isLoading = nextProps.isLoading;
        _.assign(state, {isLoading});
        this._isLoading = isLoading;

        this.d3Chart.update(this.el, state);

        return false;
    },

    prepareGraphState(props) {
        var allData = props.data;
        // console.log('data will be process for chart ---------------------: ', allData)
        var data = _.map(allData, function(d) {
            var ret = {
                date: {
                    key: 'date-time',
                    value: moment(d.date).toDate(),
                    displayName: 'Date'
                },
                y: [
                    {
                        key: 'unfiltered_impressions',
                        value: d.unfiltered_impressions,
                        displayName: 'Total impression'
                    },
                    {
                        key: 'filtered_impressions',
                        value: d.filtered_impressions,
                        displayName: 'Filtered impression'
                    },
                    {
                        key: 'filtered_clicks',
                        value: d.filtered_clicks* 1,
                        displayName: 'Filtered clicks'
                    },
                    {
                        key: 'ctr',
                        value: ( d.filtered_impressions === 0 ) ? 0 : d.filtered_clicks / d.filtered_impressions,
                        displayName: 'ctr'
                    },
                    // spend
                    {
                        key: 'filtered_spend',
                        value: d.filtered_spend* 1,
                        displayName: 'Filtered spend'
                    },
                    {
                        key: 'eCPM',
                        value: ( d.filtered_impressions === 0 ) ? 0 : d.filtered_spend / (d.filtered_impressions / 1000),
                        displayName: 'eCPM'
                    },
                    {
                        key: 'eCPC',
                        value: ( d.filtered_clicks === 0 ) ? 0 : d.filtered_spend / d.filtered_clicks,
                        displayName: 'eCPC'
                    }
                ],
                // id: _.uniqueId()
                id: moment(d.date).toISOString()
            };
            return ret;
        });

        // START :: for debug
            // var dataDev = _(data).map((d) => {
            //     return {
            //         id: d.id,
            //         date: d.date.value,
            //         impressions: _(d.y).where({key: 'filtered_impressions'}).value()[0].value,
            //         clicks: _(d.y).where({key: 'filtered_clicks'}).value()[0].value,
            //         spend: _(d.y).where({key: 'filtered_spend'}).value()[0].value,
            //         ctr: _(d.y).where({key: 'ctr'}).value()[0].value,
            //         eCPM: _(d.y).where({key: 'eCPM'}).value()[0].value,
            //         eCPC: _(d.y).where({key: 'eCPC'}).value()[0].value,
            //     };
            // }).value();
            // console.table(dataDev);
        // END :: for Debug

        var meta = {};
        meta.dataIndex = {
            unfilteredImpressions : 0,
            filteredImpressions   : 1,
            filteredClicks        : 2,
            ctr                   : 3,
            filteredSpend         : 4,
            eCPM                  : 5,
            eCPC                  : 6,
        };
        meta.statsMetricsType = props.meta.statsMetricsType;

        var state = {};

        // testData.forEach(function(item){
        //     item.date.value = new Date(item.date.value);
        // });
        // console.log('test data for chart -----: ', testData);
        // console.log('data for chart data-----: ', data);

        _.extend(state, { data: data }, {meta: meta});
        // _.extend(state, { data: testData }, {meta: meta});

        return state;
    },





    // ==================
    // Views
    // ==================
    updateMessage (props, opts) {
        var debug = this.debug;
        var M_startDate = moment(props.startDate);

        var isCreating = (opts && opts.isCreating) ? true : false;
        var startDateIsNull = (props.startDate === null);
        var campaignHasNotStarted = (moment().isBefore(M_startDate) ) ? true : false;
        var isLoading = props.isLoading;
        var noData = props.data.length ?  false: true ;

        var totalImpressions = props.data.reduce((sum, item) => { return item.filtered_impressions + sum; }, 0),
            zeroImpressions = (totalImpressions === 0);

        // For debug:
            // var isCreating = false;
            // var startDateIsNull = false;
            // var campaignHasNotStarted = true;
            // var isLoading = false;
            // var noData = false;

        var message = {};
        message.campaignHasNotStarted = {
            title: 'A little impatient, aren\'t we? ;-)',
            message: 'This campaign is scheduled to start on ' + M_startDate.format('MMMM DD, YYYY') + '.<br>You might want to check back then.',
            img: '/images/report/icon-hourglass.png'
        };
        message.noData = {
            title: 'We haven\'t matched any users yet',
            message: 'If the campaign just began, give it some time -<br>our systems are working hard to acquire the right users.<br>Your targeting may also be too narrow. Try:<br><ul><li>Loosening your criteria</li><li>Increasing the max bid</li></ul>',
            img: '/images/report/strict-target.png'
        };

        switch (true) {
        case isCreating:
            this.chartOverlay_show_loader();
            if ( debug ) console.log('.... is creating');
            break;

        case startDateIsNull:
            this.chartOverlay_show_loader();
            if ( debug ) console.log('.... start date is null');
            break;

        case campaignHasNotStarted:
            this.chartOverlay_show_message(message.campaignHasNotStarted);
            if ( debug ) console.log('.... campaign has not started');
            break;

        case isLoading:
            this.chartOverlay_show_loader();
            if ( debug )  console.log('.... is loading');
            break;

        case noData:
            this.chartOverlay_show_message(message.noData);
            if ( debug )  console.log('.... data is empty');
            break;

        case zeroImpressions:
            this.chartOverlay_show_message(message.noData);
            break;

        default:
            this.chartOverlay_hide_all();
        }
    },

    render: function() {
        return (
            <div
                className="am-reports-timeSeriesGraph"
                onMouseMove={this.reactHandleMouseMove}
                onMouseOver={this.reactHandleMouseOver}
                onMouseOut ={this.reactHandleMouseOut}
            >
                    <div className="am-reports-timeSeriesGraph-inner">
                        <svg></svg>
                        <div className="am-reports-timeSeriesGraph-overlay am-reports-timeSeriesGraph-overlay-loading">
                            <div className="am-verticalCenter">
                                <div className="am-verticalCenter-inner">
                                    <i className="fa fa-spinner fa-spin fa-3x"></i>
                                </div>
                            </div>
                        </div>

                        <div className="am-reports-timeSeriesGraph-overlay am-reports-timeSeriesGraph-overlay-message">
                            <div className="am-verticalCenter">
                                <div className="am-verticalCenter-inner">
                                    <div className="am-reports-timeSeriesGraph-messageContainer">
                                        <img className="am-reports-timeSeriesGraph-messageContainer-icon" src="" />
                                        <div className="am-reports-timeSeriesGraph-messageContainer-text">
                                            <div className="am-reports-timeSeriesGraph-messageContainer-title"></div>
                                            <div className="am-reports-timeSeriesGraph-messageContainer-body"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="am-reports-toolTip"></div>
                    </div>
                </div>
        );
    }
});


module.exports = ChartExplorerSummary;

