<!-- file: organization/templates/form.hbs -->
<!-- modal -->
<div class="modal-header">
    {{#if id}}
        <h3>Edit Organization</h3>
    {{else}}
        <h3>Create Organization</h3>
    {{/if}}
</div>

<div class="modal-body">

    {{#if id}}

        <div class="form-group">
            <div class="col-xs-5">
                <label class="control-label">Name</label>
            </div>
            <div class="col-xs-7">
                <input class="form-control" data-id="name" placeholder="Organization name"/>

                <div class="help-block"></div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-xs-5">
                <label class="control-label">Default CPM Rate</label>
            </div>
            <div class="col-xs-7">
                <input class="form-control small monetary" type="text" data-id="default_max_cpm_rate" placeholder="Cost per impression"/>

                <div class="help-block"></div>
            </div>
        </div>


        <div class="form-group credit-limit">
            <div class="col-xs-5">
                <label class="control-label">Credit Limit</label>
            </div>
            <div class="col-xs-3">
                <input class="form-control monetary" data-id="credit_limit" type="text"/>
            </div>
            <div class="col-xs-4">
                <div class="checkbox">
                    <label>
                        <input type="checkbox"/> Unlimited
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-xs-5">
                <label class="control-label">Auditors</label>
            </div>
            <div class="col-xs-7">
                <select multiple data-id="auditors" style="width: 300px;"></select>
            </div>
        </div>

        <div class="form-group">
            <div class="col-xs-5">
                <label class="control-label">Auditees</label>
            </div>
            <div class="col-xs-7">
                <select multiple data-id="auditees" style="width: 300px;"></select>
            </div>
        </div>
    {{else}}

        <div class="form-group">
            <div class="col-xs-6">
                <label class="control-label">Name</label>
                <input class="form-control" data-id="name" placeholder="Organization name"/>
                <div class="help-block"></div>
            </div>

            <div class="col-xs-6">
                <label class="control-label">Default CPM Rate</label>
                <input class="form-control small monetary" type="text" data-id="default_max_cpm_rate" placeholder="Cost per impression"/>

                <div class="help-block"></div>
            </div>
        </div>

        <div class="form-group credit-limit">
            <div class="col-xs-4">
                <label class="control-label">Credit Limit</label>
                <input class="form-control monetary" data-id="credit_limit" type="text"/>
            </div>
            <div class="col-xs-4">
                <label class="control-label"></label>
                <div class="checkbox">
                    <label class="control-label">
                        <input type="checkbox"/> Unlimited
                    </label>
                </div>
            </div>
        </div>


        <div class="clearfix creatives-for-new-organization" data-id="creatives"></div>

    {{/if}}
</div>

<div class="modal-footer">
    <button class="btn btn-success" data-action="save">
        <i class="fa fa-floppy-o"></i>
        {{#if id}} Save {{else}} Create {{/if}}
    </button>
</div>
