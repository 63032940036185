'use strict';

var Tooltip = React.createClass({

    getDefaultProps () {
        return {
            text: '',
            placement: 'top',
            viewportSelector: 'body'
        };
    },

    componentDidMount () {
        $(this.getDOMNode()).tooltip({
            title: this.props.text,
            placement: this.props.placement,
            container: 'body',
            viewport: this.props.viewportSelector
        });
    },

    componentWillUnmount () {
        $(this.getDOMNode()).tooltip('destroy');
    },

    render () {
        return this.props.children;
    }
});


module.exports = Tooltip;


