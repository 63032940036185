'use strict';

var app = require('scripts/core').app;
var appController = {
    _navigateToAppropriateLandingPage: function () {
        if (this.can('list', 'view', 'campaigns')) {
            this.navigate('/campaigns', {trigger: true});
        } else if (this.can('list', 'view', 'roles') || this.can('list', 'view', 'users') ) {
            this.navigate('/admin', {trigger: true});
        } else {
            this.navigate('/welcome', {trigger: true, replace: true});
        }
    },

    routeToLandingPage: function (route) {
        if (this.session.isValid()) {
            this._navigateToAppropriateLandingPage();
        } else {
            localStorage.setItem('_flash', 'Unauthorized, please sign in the continue.');
            window.location.href = '/signin';
        }
    },

    routeNotFound: function (route) {
        this.navigate('/not-found', {trigger: true, replace: true});
    },

    signout: function () {
        this.session.destroy();
        for (var k in localStorage) {
            localStorage.removeItem(k);
        }
        document.cookie = 'authorization=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/';
    }
};
module.exports = appController;
