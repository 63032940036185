'use strict';

var app                    = require('scripts/core').app;
var newTemplate            = require('../templates/form');
var CategoriesSelectorView = require('./categories-selector');
var helpers                = require('scripts/common/helpers');
var FileUploader           = require('../parts/assets/file-uploader');
var Form                   = require('scripts/common/mixins/form');
var Loader                 = require('scripts/common/mixins/loader');

var Campaign               = require('scripts/entities/collections/campaigns').prototype.model;

/**
 * Form used for creating and editing campaign
 * @param {object} options
 *        model : {Mario.Model} campaign model. If it's not present, an empty campaign is created.
 */
var CampaignForm = Mario.Layout.extend({
    behaviors: {
        Form: {}
    },

    className: 'campaign-form row',

    template: newTemplate,
    templateHelpers: function () {
        return {
            with_uploader: this.model.isNew(),
            has_child_organizations: this.options.organizations && this.options.organizations.length > 1
        };
    },

    events: {
        'focus .form-control:not([data-id=iab_categories])' : 'hideCategoriesMenu',
        'click [data-id=iab_categories] .display'           : 'showCategoriesMenu',
        'focus [data-id=iab_categories]'                    : 'showCategoriesMenu'
    },

    regions: {
        categoriesRegion: '[data-id=iab_categories]',
        uploadRegion: '.file-upload-region'
    },

    showCategoriesMenu: function (ev) {
        this.categoriesSelector.showMenu();
        return false;
    },

    hideCategoriesMenu: function () {
        this.categoriesSelector.hideMenu();
    },

    onRender: function () {
        if (this.model.isNew()) {
            this.$el.addClass('campaign-new');
        }
    },

    onShow: function () {
        this.categoriesSelector = new CategoriesSelectorView({model: this.model});
        this.categoriesRegion.show(this.categoriesSelector);
        this.trigger('update-page-meta', {resource_name: 'New campaign'});
        this.stickit();

        if (this.model.isNew() && this.uploadRegion.el) {
            var fileUploader = new FileUploader();
            this.uploadRegion.show(fileUploader);

            this.on('saved', function () {
                fileUploader.startUpload(this.model.id);
            });
        }

        this.$('[data-toggle=popover]').popover();
        this.$('.notice-region').html(globalCh.request('upload-notice'));

        Backbone.Validation.bind(this);

        var $field_advertiser_domain = this.$el.find('[data-id=advertiser_domain]');
        $field_advertiser_domain.on('blur', this.checkAdvertiserDomain );

    },

    checkAdvertiserDomain : function(e){

        var $field = $(e.target);
        var val = $field.val();
        var valCorrected = '';
        // strip 'http://', 'https://', 'www.' prefixes
        var r1 = /^https?:\/\/(.*)$/;
        var r2 = /^w{3}\.(.*)/;
        if (typeof val === 'string') {
            val = S(val).trim().s;
            valCorrected = val.replace(r1, '$1' ).replace(r2, '$1');
        } else {
            throw new Error('Error while processing new campaign form.');
        }
        $field.val(valCorrected);
    },

    onDestroy: function () {
        Backbone.Validation.unbind(this);
    },

    getFormData: function () {
        // manually trigger stickit
        var data = helpers.stickitGetValues.call(this, ['name', 'advertiser_domain', 'iab_categories', 'notes', 'owner']);
        return data;
    },

    onSave: function () {
        var values = this.getFormData();
        var isNew = this.model.isNew(), that = this;

        // set save button state
        this.$('[data-action=save]').prop('disabled', true).find('i')
            .removeClass('fa-check').addClass('fa-refresh fa-spin').end()
            .find('span').html('&nbsp;Saving ...');

        var resetSaveBtn = _.bind(function () {

            this.$('[data-action=save]').prop('disabled', false).find('i')
                .addClass('fa-check').removeClass('fa-refresh fa-spin').end()
                .find('span').html('Create Campaign');
            this.model.off('validated:invalid');

        }, this);

        this.model.on('validated:invalid', resetSaveBtn);

        this.model.save(values, {
            wait: true,
            patch: !isNew,
            success: function () {
                toastr.success('Campaign is created successfully.');
                // redirect after all events are done
                _.defer(function () {
                    app.navigate(that.model.url(), {trigger: true});
                });
                // for file upload
                that.trigger('saved');
                that.trigger('destroy-page');
            },
            error: resetSaveBtn
        });
    },

    onCancel: function () {
        if (this.model.isNew()) {
            this.model.destroy();
            this.trigger('destroy-page');
        } else {
            this.destroy();
        }
    },

    bindings: {
        '[data-id=name]'              : 'name',
        '[data-id=advertiser_domain]' : {
            observe: 'advertiser_domain',
            getVal: function ($el, event, options) {
                var val = $el.val();
                return val;
            }
        },

        '[data-id=notes]'             : 'notes',

        '[data-id=iab_categories]'        : {
            observe: 'iab_categories',
            getVal: function () {
                return this.categoriesSelector.selectedCategories.map(function (cat) {return cat.id;});
            },

            update: function ($el, val, model) {
                return this.categoriesSelector.setSelected(val);
            }
        },

        '[data-id=owner]' : {
            observe: 'owner',
            selectOptions: {
                collection: function () {
                    return this.options.organizations.map(function (org) {
                        return {
                            label: org.get('name'),
                            value: org.id
                        };
                    })
                }
            }
        }
    }
});

module.exports = CampaignForm;
