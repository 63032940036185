'use strict';

var Status = require('scripts/widgets/status');
var Preview = require('./preview');
var Detail = require('./detail');
var History = require('./history');
var PrivateChannel = require('scripts/common/mixins/private-channel');
var Tooltip = require('../../../../../widgets/tooltip');

var Overview = React.createClass({
    mixins: [PrivateChannel],

    getInitialState() {
        return {
            showDetail: this.props.showDetail || false,
            showHistory: false,
            json: this.props.model.toJSON()
        };
    },

    componentDidMount() {
        this.privateCh.listenTo(this.props.model, 'change', ()=>this.setState({json: this.props.model.toJSON()}));
        this.props.ch.comply(`campaign:creatives:${this.props.model.id}:focus`, ()=>this.onFocus());
    },

    componentWillUnmount() {
        this.props.ch.stopComplying(`campaign:creatives:${this.props.model.id}:focus`);
    },

    shouldComponentUpdate(nextProps, nextState) {
        var {showDetail, showHistory} = this.state;
        return this.props.model.isChanged(this.state.json) || showDetail != nextState.showDetail ||
            showHistory != nextState.showHistory;
    },

    onFocus() {
        var $el = $(this.getDOMNode());
        var top = $el.offset().top;
        $('html, body').animate({scrollTop: top + 'px'});
        $el.animateHighlight({highlightColor:'rgba(204, 238, 255, 0.5)', isReact:true, duration: 2000 });
    },

    onCloseDetail() {
        this.setState({showDetail: false});
    },

    onToggleDetail() {
        this.setState({showDetail: !this.state.showDetail});
    },

    onClickEdit() {
        this.props.ch.trigger('campaign:creatives:edit', this.props.model);
    },

    onClickDuplicate() {
        this.props.ch.trigger('campaign:creatives:duplicate', this.props.model);
    },

    onClickDelete() {
        this.props.ch.trigger('campaign:creatives:delete', this.props.model);
    },

    onClickAudit() {
        this.props.ch.trigger('campaign:creatives:audit', this.props.model);
    },

    onClickSimulate() {
        this.props.ch.trigger('campaign:creatives:simulate', this.props.model);
    },

    onClickHistory() {
        this.setState({showHistory: true});
    },

    onCloseHistory() {
        this.setState({showHistory: false});
    },

    render() {
        var creative = this.props.model;
        var campaign = creative.getCampaign();

        var buttonGroup = (
            <div className="ef-toolbar">

                {creative.can('edit')?
                <Tooltip text="Edit">
                    <button type="button" title="Edit" onClick={this.onClickEdit}>
                        <i className="fa fa-fw fa-pencil"></i>
                        <span>Edit</span>
                    </button>
                </Tooltip> : null}

                {creative.can('edit')?
                <Tooltip text="Duplicate">
                    <button type="button" title="Duplicate" onClick={this.onClickDuplicate}>
                        <i className="fa fa-fw fa-copy"></i>
                        <span>Duplicate</span>
                    </button>
                </Tooltip>: null}

                <Tooltip text="History">
                    <button type="button" title="History" onClick={this.onClickHistory}>
                        <i className="fa fa-fw fa-clock-o"></i>
                        <span>History</span>
                    </button>
                </Tooltip>

                {creative.can('edit')?
                <Tooltip text="Delete">
                    <button type="button" title="Delete" onClick={this.onClickDelete}>
                        <i className="fa fa-fw fa-trash"></i>
                        <span>Delete</span>
                    </button>
                </Tooltip>: null}
            </div>
        );

        var ads = creative.getAssociatedAds();

        var assocAds;

        if (ads.length != 0)
            assocAds = ads.map(function (ad, idx) {
                    return (
                        <li key={_.uniqueId()}>
                            <small>
                                <a href={`/campaigns/${campaign.id}/ads/${ad.id}`}>
                                    #
                                    <span data-type="id">{ad.id}</span>
                                    &nbsp;
                                    <span data-type="name"> {ad.get('name')}</span>
                                </a>
                            </small>
                        </li>
                    );
            });
        else
            assocAds = <span>None</span>;

        var preview = <Preview creative={creative.toJSON()}/>;
        var auditStatus = creative.get('audit_status');
        var isNotAudited = auditStatus === 'no_audit';
        var isRejected = auditStatus === 'rejected';
        var rejectReason = creative.get('rejection_reason') || 'No reason is given';

        return (
            <div className="ef-creative-row" key={_.uniqueId()} >
                <div className="row">
                    <div className="colm-info col-xs-8 col-lg-6">
                        <a href='#' onClick={this.onToggleDetail}>
                            <h4>
                                <span className="text-muted">#{creative.id}</span>&nbsp;{creative.get('name')}
                                <small className="hidden-lg">({creative.get('format')}, {creative.get('size')})</small>
                            </h4>
                        </a>
                        <div className="rw-status-label">
                            <Status status={auditStatus} />
                            {isNotAudited? <button className="btn btn-success btn-sm btn-audit-submission" onClick={this.onClickAudit}>Submit Now</button> : null}
                        </div>
                        <div className="rw-message">
                            {isRejected? <div className="alert alert-danger well-sm">{rejectReason}</div>: null}
                        </div>
                        <div className="rw-assoc">
                            <div className="c1">
                                <small>Ads:&nbsp;</small>
                            </div>
                            <div className="c2">
                                <ul>
                                    {assocAds}
                                </ul>
                            </div>
                            <br/>
                        </div>
                        <div className="rw-toolbar">
                            {buttonGroup}
                        </div>
                    </div>
                    <div className="colm-preview col-xs-4 col-lg-3" >
                        {preview}
                    </div>
                    <div className="colm-format    col-lg-1 visible-lg" >{creative.get('format')}</div>
                    <div className="colm-size      col-lg-1 visible-lg" >{creative.get('size')}</div>
                    <div className="colm-simulator col-lg-1 visible-lg" >
                        <button className="btn btn-xs btn-default" onClick={this.onClickSimulate}>
                            <i className="fa fa-mobile-phone"></i>
                            Simulate
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="rw">
                            {this.state.showDetail? <Detail creative={this.props.model} onClose={this.onCloseDetail}/>: null}
                        </div>
                    </div>
                </div>
                {this.state.showHistory? <History creative={this.props.model} onClose={this.onCloseHistory} ch={this.props.ch}/> : null}
            </div>
        )
    }
});


module.exports = Overview;
