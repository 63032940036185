{
    "image/jpeg"                                                              : "image",
    "image/gif"                                                               : "image",
    "image/png"                                                               : "image",
    "video/mp4"                                                               : "video",
    "application/pdf"                                                         : "pdf",
    "text/plain"                                                              : "txt",
    "application/msword"                                                      : "doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" : "docx",
    "application/vnd.ms-excel"                                                : "xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"       : "xlsx"
}