'use strict';
var op = objectPath;

var CustomFluxMixin = require('scripts/common/mixins/CustomFluxMixin');
var flux = require('scripts/core/flux');

var SummaryTable = require('scripts/components/v2/widgets/summary-table');
var HealthMenu = require('scripts/components/v2/widgets/health-menu');
var SummaryPopoverTemplate = require('scripts/components/v2/widgets/summary-popover-template');
var PopoverButton = require('scripts/components/v2/widgets/popover-button');
var CampaignOverallProgress = require('scripts/components/v2/widgets/campaign-overall-progress');
var RefreshButton = require('scripts/components/v2/widgets/refresh-button');
var AdProgress = require('scripts/components/v2/widgets/campaign-progress-ads');

var TimeTraveler = require('scripts/components/v2/widgets/time-traveler');

var ProgressPage = React.createClass({

    propTypes: {
        campaignId: React.PropTypes.number.isRequired,
        adId: React.PropTypes.string
    },

    mixins: [CustomFluxMixin({
        'onStoreChanged': 'app-store',
    })],

    getInitialState () {
        return flux.store('app-store').getState(`ui.campaigns.progress.schema`);
    },

    onStoreChanged () {
        const state = flux.store('app-store').getState(`ui.campaigns.progress.instances.${this.props.campaignId}`, null);
        if (state !== null) {
            this.setState(state);
        }
    },

    fetchProgressPageData (campaignId, adId) {
        var activeTab = flux.store('app-store').getState(`session.campaigns.progress.activeInstance`);
        if (activeTab !== campaignId) {
            return;
        }

        flux.actions.progressPage.fetchProgressPageData(campaignId, adId);
    },

    render () {
        const {
            adsMenu,
            statsTable,
            summaryPopup,
            campaignProgressGraph,
            target,
            title,
            adProgressGraph,
            refresh
        } = this.state;

        return (
            <div className="am-progress">
                <div className="am-progress-sidebar">
                    <HealthMenu
                        header={op.get(adsMenu, 'header', { title: '', link: '', selected: false})}
                        menuItems={op.get(adsMenu, 'ads', [])} />
                </div>
                <div className="am-progress-main">
                    <div className="am-progress-header">
                        <div className="am-progress-header-refresh">
                            <RefreshButton
                                pause={false}
                                refreshInterval={op.get(refresh, 'interval', 3 * 60 * 1000)}
                                refresh={()=>this.fetchProgressPageData(this.props.campaignId, this.props.adId)} />

                        </div>
                        <h3>{title}</h3>

                    </div>
                    {target === 'campaign'
                        ? <CampaignOverallProgress
                            campaignId={this.props.campaignId}
                            ads={op.get(campaignProgressGraph, 'ads', [])}
                            description={op.get(campaignProgressGraph, 'description', '')}
                            start={op.get(campaignProgressGraph, 'flight.start', '')}
                            end={op.get(campaignProgressGraph, 'flight.end', '')}
                            now={op.get(campaignProgressGraph, 'now', new Date())} />
                        :
                    <AdProgress
                        adAttr={op.get(adProgressGraph, 'adAttr', {})}
                        meta={op.get(adProgressGraph, 'dataGraph.meta', {})}
                        data={op.get(adProgressGraph, 'dataGraph.data', [])}
                    />
                    }
                    <div className="am-progressTable-header">
                        <PopoverButton placement="left" text={'Summary'}>
                            <SummaryPopoverTemplate
                                title={op.get(summaryPopup, 'details.title', '')}
                                secondaryTitle={op.get(summaryPopup, 'details.secondaryTitle', '')}
                                callToActions={op.get(summaryPopup, 'details.callToActions', [])}
                                groups={op.get(summaryPopup, 'details.groups', [])} />
                        </PopoverButton>
                        <h4 className="am-progressTable-header-title">{op.get(statsTable, 'title', '')}</h4>
                    </div>
                    <div>
                        <SummaryTable
                            isLiveStats={op.get(statsTable, 'stats.isLiveStats', false)}
                            headerItems={op.get(statsTable, 'stats.headerItems', [])}
                            bodyItems={op.get(statsTable, 'stats.bodyItems', [])}
                            isLoading={op.get(statsTable, 'stats.isLoading', false)} />
                    </div>

                    <TimeTraveler />
                </div>
            </div>
        );
    }
});


module.exports = ProgressPage;
