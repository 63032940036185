'use strict';

var env = require('scripts/env');

var Simulator = React.createClass({

    statics: {
        openInNewWindow: openInNewWindow
    },

    reloadIframe (creative) {

        if (creative.third_party_clickthrough === 'false' &&
            creative.landing_page &&
            creative.landing_page.indexOf('http://') === 0) {
            $('.creative-simulator-warning').show();

            console.log('A');

        } else if (creative.third_party_clickthrough === 'true' &&
            creative.clickthrough_url &&
            creative.clickthrough_url.indexOf('http://') === 0) {
            $('.creative-simulator-warning').show();
            console.log('A');


        } else {
            $('.creative-simulator-warning').hide();

            console.log('C');
        }

        generateForm(creative).submit();
    },

    openInNewWindow () {
        openInNewWindow(this.props.creative);
    },

    componentDidMount () {

        this.debouncedReloadIframe = _.debounce(this.reloadIframe, 800);

        this.debouncedReloadIframe(this.props.creative);
    },

    componentDidUpdate () {
        this.debouncedReloadIframe(this.props.creative);
    },

    render () {

        return (
            <div className="row">
                <div className="col-lg-12">
                    <iframe className="creative-simulator" name="creative-simulator" />
                </div>
                <div className="col-lg-12 creative-simulator-warning">
                    <div className="alert alert-warning text-center">
                        <p>
                            It looks like your Landing Page is an insecure page (http://).
                            That's okay &mdash; but for technical reasons, we're not able to embed an insecure page here.
                        </p>
                        <p>
                            If you wish to preview the clickthrough action, <a href="#" onClick={this.openInNewWindow}>click here</a> to preview in a new window.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
});

function openInNewWindow (creative) {
    var form = generateForm(creative, 'simulator-window', true);

    window.open('', 'simulator-window', 'width=700,height=850,status=yes,resizable=yes,scrollbars=yes');

    form.submit();
}


function generateForm (creative, target, useHTTP) {
    var form = document.createElement('form');

    var endpoint = env.SIMULATOR_ENDPOINT;

    if (useHTTP || isNotProductionOrDemoEnv()) {
        endpoint = endpoint.replace('https://', 'http://');
    }

    form.method = 'POST';
    form.target = target || 'creative-simulator';
    form.action = endpoint;

    Object
        .keys(creative)
        .map(generateInputFieldsFrom(creative))
        .forEach(function appendToForm (field) {
            form.appendChild(field);
        });

    return form;
}

function isNotProductionOrDemoEnv () {
    return !_.contains(['production', 'demo'], env.NAME);
}

function generateInputFieldsFrom (creative) {
    return function (fieldName) {
        return createField(fieldName, creative[fieldName]);
    };
}


function createField (name, value) {
    var field = document.createElement('input');

    field.type = 'text';
    field.name = name;
    field.value = value;

    return field;
}

module.exports = Simulator;


