'use strict';

var routeReducer = require('./route');
var campaignsReducer = require('./campaigns');


function reducer (state = {}, action) {

    return {
        sidebar: {},
        route: routeReducer(state.route, action),
        campaigns: campaignsReducer(state.campaigns, action)
    };
}


module.exports = reducer;