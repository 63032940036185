'use strict';

// http://facebook.github.io/react/docs/reusable-components.html
var SetIntervalMixin = {
    componentWillMount() {
        this.intervals = [];
    },
    setInterval() {
        this.intervals.push(setInterval.apply(null, arguments));
    },
    componentWillUnmount() {
        this.intervals.map(clearInterval);
    }
};

var PrivateChannel = require('scripts/common/mixins/private-channel');

var RefreshButton = React.createClass({
    mixins: [SetIntervalMixin, PrivateChannel],
    getInitialState() {
        return {
            lastUpdated: 0,
            isRunning: false,
            lastRefreshed: Date.now()
        }
    },

    startRunning() {
        this.setState({isRunning: true});
    },

    stopRunning() {
        this.setState({isRunning: false, lastRefreshed: Date.now()});
    },

    componentDidMount () {

        if (this.props.collection != null) {
            this.privateCh.listenTo(this.props.collection, 'refresh:start', ()=>this.isMounted() && this.startRunning());
            this.privateCh.listenTo(this.props.collection, 'refresh:done', ()=>this.isMounted() && this.stopRunning());
        }
        this.setInterval(this.tick, 10000);
    },

    tick () {
        this.setState({lastUpdated: Date.now()});
    },

    refreshNow (e) {
        e.preventDefault();
        if (this.props.collection != null) {
            this.props.collection.refresh({reset: false});
        }
    },

    emptyClickEvent(e) {
        e.preventDefault();
    },

    render () {
        var textStyle = {marginLeft: '4px'};
        if (this.state.isRunning) {
            return (
                <div className="ef-refresh-button">
                    <button title="Refresh now" className="btn btn-link btn-sm toolbar-refresh" onClick={this.emptyClickEvent}>
                        <i className="fa fa-refresh fa-spin"></i>
                        Updating now ...
                    </button>
                </div>
            )
        } else {
            var elapse = moment(this.state.lastRefreshed).fromNow(true);

            return (
                <div className="ef-refresh-button">
                    <button title="Refresh now" className="btn btn-link btn-sm toolbar-refresh" onClick={this.refreshNow}>
                        <i className="fa fa-refresh"></i>
                        Updated {elapse} ago
                    </button>
                </div>
            );
        }
    }
});

module.exports = RefreshButton;
