'use strict';
var Popup = require('scripts/widgets/popup');
var Status = require('scripts/widgets/status');
var HistoryWidget = require('scripts/widgets/history');
var PrivateChannel = require('scripts/common/mixins/private-channel');

var HistoryView = React.createClass({
    mixins: [PrivateChannel],
    getInitialState() {
        return {
            data: {}
        };
    },

    componentDidMount() {
        this.props.creative.getHistory().then((history)=>this.setState({data: history})).catch(toastr.error);
        this.privateCh.listenTo(this.props.ch, 'popup:close', this.props.onClose);
    },

    render() {
        var data = this.state.data;

        if (_.isEmpty(data)) {
            return null;
        }
        var historyData = massageDataForCreative(data, this.props.creative.get('format'));

        return (
            <Popup ch={this.props.ch}>
                <h1>Creative History <small>{this.props.creative.get('name')}</small></h1>
                <HistoryWidget history={historyData} />
                <div className="row"><div className="col-xs-3"><a className="btn btn-success" onClick={this.props.onClose}>Close</a></div></div>
            </Popup>
        );
    }
}) ;

module.exports = HistoryView;

var fns = require('scripts/common/fns');
function massageDataForCreative(data, format) {

    var attrMappings = {};
    
    switch (format) {
        case 'standard':
            attrMappings = {
                name                     : 'Name',
                size                     : 'Size',
                format                   : 'Format',
                image_url                : 'Image URL', // main image for native format
                image_preview_url        : 'Image Preview URL',

                landing_page             : 'Landing Page',
                third_party_clickthrough : 'Third Party Clickthrough',
                clickthrough_url         : 'Clickthrough URL',

                third_party_pixels       : 'Third Party Pixels',
                audit_status             : 'Audit Status' 
            }
            break;

        case 'custom_html':
        case 'mraid':
            attrMappings = {

                name                     : 'Name',
                size                     : 'Size',
                format                   : 'Format',
                image_preview_url        : 'Image Preview URL',
                content_html             : 'Content HTML',

                landing_page             : 'Landing Page',
                third_party_clickthrough : 'Third Party Clickthrough',
                clickthrough_url         : 'Clickthrough URL',

                audit_status             : 'Audit Status' 
            };
            break;

        case 'video':
            attrMappings = {

                name                     : 'Name',
                size                     : 'Size',
                format                   : 'Format',
                image_preview_url        : 'Image Preview URL',
                video_url                : 'Video URL',
                video_preview_url        : 'Video Preview URL',
                video_duration           : 'Video Duration',

                landing_page             : 'Landing Page',
                third_party_clickthrough : 'Third Party Clickthrough',
                clickthrough_url         : 'Clickthrough URL',

                audit_status             : 'Audit Status' 
            };
            break;

        case 'native':
            attrMappings = {
                name                     : 'Name',
                size                     : 'Size',
                format                   : 'Format',
                image_url                : 'Image URL', // main image for native format
                image_preview_url        : 'Image Preview URL',
                icon_image_url           : 'Icon Image URL',
                icon_image_preview_url   : 'Icon Image Preview URL',
                title                    : 'Title',
                text                     : 'Text',
                call_to_action           : 'Call to Action',

                landing_page             : 'Landing Page',
                third_party_clickthrough : 'Third Party Clickthrough',
                clickthrough_url         : 'Clickthrough URL',

                star_rating              : 'Star Rating',
                audit_status             : 'Audit Status' 
            };
            break;
    };

    var formatMappings = {
        standard: 'Standard',
        video: 'Video',
        custom_html: 'Custom HTML',
        mraid: 'MRAID',
        native: 'Native'
    };

    function formatter(key, snapshot) {
        switch (key) {
            case 'third_party_clickthrough':
                return function (v) {return S(v.toString()).capitalize().s;};
            break;
            case 'audit_status':
                return function (v) {return <Status status={v}/>};
            break;
            case 'third_party_pixels':
                return function (v) {return v.map((p)=><div key={_.uniqueId()}>{p}</div>)};
            break;
            case 'format':
                return function (v) {return formatMappings[v];}; 
            break;
            case 'content_html':
                return function (v) {return <pre><code>{v}</code></pre>;}; 
            break;

            return false;
        }
    }

    return fns.historyFormatter(data, attrMappings, formatter);
}


