'use strict';

var AssetItem            = require('./item').AssetItem;
var DocumentItem         = require('./item').DocumentItem;
var layoutTemplate       = require('./templates/layout');
var assetListTemplate    = require('./templates/asset-list-layout');
var documentListTemplate = require('./templates/document-list-layout');
var emptyTemplate        = require('./templates/empty');
var contentTypes         = require('scripts/common/constants/content-types');
var app                  = require('scripts/core').app;
var Refresh              = require('scripts/common/mixins/refresh');


var EmptyView = Mario.ItemView.extend({
    className: 'empty',
    template: emptyTemplate
});


/**
 * shared cade used by DocumentsLayout and AssetsLayout views
 */
var shared = {
    emptyView: EmptyView,

    regions: {
        list: '.list'
    },

    childViewContainer: '.list',

    initialize: function (options) {
        this.meta_creative = this.options.meta_creative;
        this.campaign = options.campaign;
        this.embed = options.embed || false;

        this.listenTo(this.options.ch, 'campaign:files:search', this.onSearch); // this is not triggered anywhere
        this.on('filter', this.updateFilter);
        //      'filter' event is triggered in parents view's method 'updateAssetsHelper'
        //      This event is triggered when format or size has changed
    },

    onSearch: function (by) {
        this.collection.each(function (m) {
            var v = this.children.findByModel(m);
            if (m.searchTerm(by)) {
                v.$el.show();
            } else {
                v.$el.hide();
            }
        }, this);
    }
};


/**
 * Assets List (aka Media list)
 *
 * This view is also embed in creative form to provide a way for user
 * to selecting assets (media).
 *
 * options
 * @param {Collection<Asset>} collection
 */
var AssetsLayout = Mario.CompositeView.extend({
    childView: AssetItem,
    template: assetListTemplate,

    childViewOptions: function () {
        var format;

        if (this.options.embed && this.options.creative) {
            format = this.options.creative.get('format');
            if (_.isEmpty(format)) {
                format = 'standard';
            }
        }
        return {
            embed: this.embed,
            format: format,
            campaign: this.campaign,
            meta_creative: this.meta_creative
        };
    },

    className: 'assets',

    updateFilter: function (format, size) {
        this._filterFormat = format;
        this.render();
    },

    templateHelpers: function () {
        return {
            can_update: _.include(this.campaign.get('_permissions'), 'update'),
            embed: this.embed,
            files_type: 'Assets'
        };
    }
});
_.extend(AssetsLayout.prototype, shared);


/**
 * Documents List
 *
 * Not embed in creative builder form
 *
 * Options:
 * @param {Collection<Document>} collection
 */
var DocumentsLayout = Mario.CompositeView.extend({
    className: 'documents',
    childView: DocumentItem,
    template: documentListTemplate,

    childViewOptions: function () {
        return {
            campaign: this.campaign
        };
    },

    // dummy implementation
    updateFilter: function () {
    },

    // dummy implementation
    toggleChildView: function () {
    },

    templateHelpers: function () {
        return {
            can_update: _.include(this.campaign.get('_permissions'), 'update'),
            files_type: 'Documents'
        };
    }
});
_.extend(DocumentsLayout.prototype, shared);




/**
 * Main view to display documents and assets (media)
 *
 * This Layout has two regions:
 *  1. assetsRegion is used to show assetLayout for displaying list of media
 *  2. docuementsRegions is used to show docuemntLayout to display list of relevent document
 *
 * When embed is false (as in the case for "File" tab) both region is display
 * When embed is true (as in the case in the asset helpler for creative form) only
 *      assetsRegion is on.
 *
 * Options:
 * @param {Campaign} campaign
 * @param {Collection<Asset>} assets
 * @param {boolean} embed if it's embedded, only show assets
 */
module.exports = Mario.Layout.extend({
    template: layoutTemplate,

    className: 'asset-layout',

    initialize: function (options) {
        this.campaign = options.campaign;
        this.collection = this.campaign.assets;

        this.listenTo(this.collection, 'reset', function (files) {
            files.each(function (file) {
                if (file.isDoc()) {
                    this.docs.set([file], {merge: true, remove: false});
                } else {
                    this.assets.set([file], {merge: true, remove: false});
                }
            }, this);
        });

        // surrogate collections
        this.docs = new Mario.Collection();
        this.assets = new Mario.Collection();

        this.embed = !!options.embed;

        // note: 'filter' event is triggered whenever creative format or size changed
            // because format and size has changed asset list display has to be
            // updated according to the filter business logic
        this.on('filter', this.updateAssetsDisplay);

        this.listenTo(this.options.ch, 'campaign:files:new', this.uploadFile);

        // note: The variable "meta_creative" store information used for filtering
            // media according to type of creative, section, dimension etc.
            // [!] value in meta_creative will not be populated if
            //     this Layout is instentiated by a new craetive (there are no 'size' and
            //     'format' to setup meta_creative). In this situation.
            //     meta_creative wll have to wait for 'filter' event to have it values
            //     populated.
        this.meta_creative = {};
        if (this.embed && this.options.creative ) {
            _.extend( this.meta_creative, this.setupCreativeMeta() );
        }
    },

    /**
     * Set up parameter (meta_creative) to be used on filtering media display
     * @param format {string}
     * @param size {string}
     *
     * If the creative model is new, then size and format are required as parameters.
     */
    setupCreativeMeta: function(format, size) {
        size = size || this.options.creative.get('size');
        format = format || this.options.creative.get('format');

        var result;
        var section = this.options.section;

        if (format === 'native') {
            // size for 'icon' and 'main image' section is hard wired here
            switch (section){
                case 'icon':
                    size = '80x80';
                    break;
                case 'mainImg':
                    size = '1200x627';
                    break;
            }
        }
       result = {
            format : format,
            section: section,
            creativeSize : size,
            creativeWidth :  this.getCreativeDimension(size).width,
            creativeHeight :  this.getCreativeDimension(size).height,
            creativeAspectRatio : this.getCreativeDimension(size).aspectRatio
        };
        return result;
    },

    regions: {
        assetsRegion: '.assets-region',
        documentsRegion: '.documents-region'
    },

    onBeforeShow: function () {
        this.collection.refresh();
    },

    onShow: function () {
        this.setupCollections();

        this.assetsLayout = new AssetsLayout({
            collection: this.assets,
            campaign: this.options.campaign,
            embed: this.options.embed,
            creative: this.options.creative,
            ch: this.options.ch,
            meta_creative: this.meta_creative
        });

        this.assetsRegion.show(this.assetsLayout);

        if (!this.embed) {
            this.documentsRegion.show(new DocumentsLayout({
                collection: this.docs,
                campaign: this.options.campaign,
                ch: this.options.ch
            }));
        } else {
            var RefreshButton = React.createFactory(require('scripts/widgets/refresh-button'));
            React.render(RefreshButton({collection: this.collection}), this.$('.refresh-button-region').get(0));
        }

        this.$('.notice-region').html(globalCh.request('upload-notice'));
    },

    setupCollections: function () {
        this.listenTo(this.collection, 'add', function (model) {
            if (model.isDoc()) {
                this.docs.add(model);
            } else {
                this.assets.add(model);
            }
        }, this);
        this.listenTo(this.collection, 'remove', function (model) {
            if (model.isDoc()) {
                this.docs.remove(model);
            } else {
                this.assets.remove(model);
            }
        }, this);

        var assets = this.collection.assets();
        this.assets.reset(assets, {silent: true});

        var docs = this.collection.documents();
        this.docs.reset(docs, {silent: true});
    },

    onDestroy: function () {
        React.unmountComponentAtNode(this.$('.refresh-button-region').get(0));
    },

    updateAssetsDisplay: function (format, size) {
        if (this.assetsLayout) {
            _.extend( this.meta_creative, this.setupCreativeMeta(format, size) );
            this.assetsLayout.trigger('filter', format, size);
        }
    },

    templateHelpers: function () {
        return {
            can_update: _.include(this.campaign.get('_permissions'), 'update'),
            formats: _.keys(contentTypes).join(','),
            embed: this.embed
        };
    },

    events : {
        'click .upload': 'uploadFile',
        'change input[type=file]': 'doUpload'
    },

    /**
     * @param {string} size - String that discribe demension of a media in
     *                 format like '100x100' (<width>x<height>).
     * @return {object} width, height,  aspect ratio
     */
    getCreativeDimension : function(size) {
        var width  = '';
        var height = '';
        var regExp = new RegExp('^(\\d+)x(\\d+)$');
        var result = regExp.exec(size);
        if (result != null) {
            var width  = +result[1];
            var height = +result[2];
            var aspectRatio = width/height;
        }
        return { 'width': width, 'height': height, 'aspectRatio': aspectRatio };
    },


    doUpload: function (ev) {
        var files = this.$('input[type=file]').get(0).files;
        var that = this;

        if (files.length > 0) {
            globalCh.request('file-upload', this.campaign.id, files).done(function () {
                that.collection.fetch({remove: false, merge: true});
            });
        }
    },

    uploadFile: function () {
        this.$('[type=file]').click();
        return false;
    }


});
