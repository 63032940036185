'use strict';

var AuditsTemplate = require('./templates/layout');
var AuditRow = require('./row');
var DataTable = require('scripts/common/mixins/datatable');
var Refresh = require('scripts/common/mixins/refresh');
var Paginator = require('scripts/widgets/paginator');

var ToolbarView = Mario.Layout.extend({
    className: 'toolbar row',
    template: require('./templates/toolbar'),

    initialize: function () {
        var that = this;

        this.trySearch = _.debounce(function () {
            that.onFilterAttr();
        }, 500);
    },

    ui: {
        'statusFilter': '.filter-status',
        'refreshBtn': '.toolbar-refresh',
        'attrFilterBtn': '.attr-filter [type=button]',
        'filterField': '.attr-filter input[type=text]'
    },

    events: {
        'change @ui.statusFilter': 'onFilterStatus',
        'click @ui.refreshBtn': 'onRefreshClick',
        'click [data-filter]': 'changeFilter',
        'keyup @ui.filterField': 'onKeyupFilter'
    },

    onKeyupFilter: function () {
        this.trySearch();
    },

    changeFilter: function (ev) {
        var filter = $(ev.target).data('filter');
        this.ui.attrFilterBtn.text(filter);
        this.onFilterAttr();
    },

    onFilterAttr: function () {
        var val = this.ui.filterField.val();
        var filterType = this.ui.attrFilterBtn.text();
        switch (filterType) {
            case 'Campaign ID':
                this.collection.setFilter({campaign: val});
                break;
            case 'Creative ID':
                this.collection.setFilter({creative: val});
                break;
            case 'Creative Name':
                this.collection.setFilter({search: val});
                break;
        }
    },

    onRefreshClick: function () {
        this.collection.refresh();
    },

    onFilterStatus: function () {
        var status = this.ui.statusFilter.find(':checked').val();
        this.collection.filterStatus(status);
    }
});

var auditCh = Backbone.Radio.channel('audits');


var EmptyAudits = Mario.ItemView.extend({
    template: _.template('<td colspan="7">No record is found.</td>'),
    tagName: 'tr',
    className: 'empty'
})

var ListView = Mario.CompositeView.extend({
    childViewContainer: 'tbody',
    template: require('./templates/list'),
    childView: AuditRow,
    emptyView: EmptyAudits,
    className: 'audit-table',

    collectionEvents: {
        'refresh:done': 'onRefreshDone'
    },

    onRefreshDone: function () {
        auditCh.command('update:pager');
    },

    onShow: function () {

    },

    behaviors: {
        Refresh: {}
    }
});

var AuditsView = Mario.Layout.extend({
    template: AuditsTemplate,

    regions: {
        'toolbarRegion': '.toolbar-region',
        'listRegion': '.audit-list-region',
        'paginatorRegion': '.audit-paginator-region'
    },

    onBeforeShow: function () {

        this.toolbarRegion.show(new ToolbarView({collection: this.collection}));
        this.listRegion.show(new ListView({collection: this.collection}));

        var paginator = new Paginator({collection: this.collection});

        auditCh.comply('update:pager', function () {
            paginator.trigger('update:pager');
        });

        this.paginatorRegion.show(paginator);
    }
});

module.exports = AuditsView;
