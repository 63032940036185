'use strict';

var countries          = require('scripts/common/constants/countries');
var fns                = require('scripts/common/fns');
var targetingTemplate  = require('./templates/layout');
var countryTemplate    = require('./templates/country');

/**
 * Internal data structure
 *
 * @typedef {Country}
 *
 * @param {string} country - country code
 * @param {Array[Region|City]} includes
 * @param {Array[Region|City]} excludes
 *
 */

/**
 * @typedef {Region}
 *
 * @param {string} name
 * @param {string} region - region code
 * @param {string} text - display name
 */

/**
 * @typedef {City}
 *
 * @param {string} city - city code
 * @param {string} region - region code
 * @param {string} text - display name
 * @param {Array[number]} location - lat/lng of city
 */




/**
 * Implements getValues and setValues
 */

var Country = Mario.Model.extend({

    defaults: {
        'include': [],
        'exclude': [],
        'is_custom': false
    },

    parse: function (resp, options) {
        resp.is_custom = !_.isEmpty(resp.include) || !_.isEmpty(resp.exclude);
        return resp;
    },

    formatData: function () {

        var func = function (i) {
            var keys = _.intersection(['region', 'city'], _.keys(i));
            keys.unshift(i);
            return _.pick.apply(_, keys);
        };

        return {
            country: this.get('country'),
            include: !this.get('is_custom')? [] : _.map(this.get('include'), func),
            exclude: !this.get('is_custom')? [] : _.map(this.get('exclude'), func)
        };
    }
});

var Countries = Mario.Collection.extend({
    model: Country,

    formatData: function () {
        return this.invoke('formatData');
    }
});

var CountryView = Mario.Layout.extend({
    className: 'country panel panel-default collapsed',
    template: countryTemplate,
    events: {
        'click [type=checkbox]' : 'toggle',
        'change .where': 'updateSummary',
        'click .close' : 'removeCountry'
    },

    removeCountry: function () {
        this.model.collection.remove(this.model);
    },

    updateSummary: function () {
        var iData = this.$('.where.include').select2('data');
        var eData = this.$('.where.exclude').select2('data');

        this.model.set({'include': iData, 'exclude': eData});
    },

    toggle: function (ev) {
        var isCustom = $(ev.currentTarget).is(':checked');
        this.model.set('is_custom', isCustom);
        if (isCustom) {
            this.$el.removeClass('collapsed');
        } else {
            this.$el.addClass('collapsed');
        }
    },

    onShow: function () {
        if (this.model.get('is_custom')) {
            this.$el.removeClass('collapsed');
        }
        var that = this;
        this.$('.where').select2({
            placeholder: 'Search for a city/region',
            minimumInputLength: 2,
            multiple: true,
            dropdownCssClass: 'bigdrop',
            ajax: {
                url: 'geo',
                quietMillis: 300,
                data: function (term, page) {
                    return {
                        query: term,
                        page: page,
                        country: that.model.get('country')
                    };
                },

                results: function (data, page, self) {
                    // filter selected data
                    var selected = $(self).select2('data');
                    data = _.reject(data, function (d) {
                        return _.find(selected, function (s) {
                            var regionCode = s.region;
                            if (!S(regionCode).startsWith(that.model.get('country'))) {
                                regionCode = that.model.get('country') + '-' + regionCode;
                            }
                            return s.city === d.city && regionCode === d.region;
                        });
                    });
                    var more = data.length >= 50;
                    var all = _.map(data, function (d, i) {
                        var displayText = (d.city) ?
                            // If city, include name of city plus decoded region name
                            d.name + ', ' + fns.decodeRegion(d.region) :
                            // If region, just include region name
                            d.name;
                        return _.extend({}, d, {id: _.uniqueId('location-'), text: displayText});
                    });
                    var results = [
                        {text: 'Regions', children: []},
                        {text: 'Cities', children: []}
                    ];
                    _.each(all, function (d) {
                        results[_.has(d, 'city') ? 1 : 0 ].children.push(d);
                    });
                    if (_.isEmpty(results[1].children)) {
                        results.splice(1, 1);
                    }
                    if (_.isEmpty(results[0].children)) {
                        results.splice(0, 1);
                    }
                    return {results: results, more: more};
                }
            }
        });

        var country = this.model.get('country');
        // load existing data
        var existingIncludes = this.model.get('include'), existingExcludes = this.model.get('exclude');
        this.$('.where.include').select2('data', _.map(existingIncludes, function (inc) {
            return _.extend({}, inc, {id: _.uniqueId('location-'), text: fns.formatGeoRegionCity(country, inc)});
        })).trigger('change');
        this.$('.where.exclude').select2('data', _.map(existingExcludes, function (exc) {
            return _.extend({}, exc, {id: _.uniqueId('location-'), text: fns.formatGeoRegionCity(country, exc)});
        })).trigger('change');
    },

    templateHelpers: function () {
        return {
            country_name: fns.getCountryName(this.model.get('country'), countries)
        };
    }
});

var SelectionsView = Mario.CollectionView.extend({
    childView: CountryView
});

module.exports = Mario.Layout.extend({
    className: 'widget-geotargets row',
    initialize: function (options) {

        this.collection = new Countries();

        this.listenTo(this.collection, 'add remove', function (model) {
            // update the country list
            var selected = this.collection.pluck('country');
            this.$('.countries').select2({
                data: _.reject(countries, function (c) { return _.contains(selected, c.id);}),
                placeholder: 'Add a country'
            });
        });

    },

    template: targetingTemplate,
    events: {
        'change .countries': 'addCountry'
    },

    regions: {
        selectionsRegion: '.selections'
    },

    onShow: function () {

        this.$('.countries').select2({
            data: countries,
            placeholder: 'Add a country'
        }).on('change', function (e) {
            // reset the selection after each selection
            $(this).select2('data', null);
        });

        var selectionView = new SelectionsView({collection: this.collection});
        this.selectionsRegion.show(selectionView);
    },

    setValues: function (geotargets) {
        this.collection.reset(geotargets, {reset: true, parse: true});

        this.listenTo(this.collection, 'change remove', function () {
            this.$el.trigger('change');
        });

    },

    getValues: function () {
        var formatted = this.collection.formatData();
        return formatted;
    },

    addCountry: function (ev, existing) {

        var ccode = ev.val;

        var found = this.collection.find(function (s) {
            return s.get('country') === ccode;
        });

        var target = {country: ccode};

        if (existing) {
            _.extend(target, existing);
        }

        if (!found) {
            this.collection.add(target);
        }
    }

});
