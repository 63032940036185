'use strict';

var app = require('scripts/core').app;
var helpers = require('scripts/common/helpers');
var formTemplate = require('./templates/form');
var popupFormTemplate = require('./templates/popup-form');
var EmbededAssetsView = require('../assets/index');
var editor = require('scripts/widgets/editor');
var Simulator = require('scripts/widgets/simulator');
var Creatives = require('scripts/entities/collections/creatives');
var Creative = Creatives.Creative;
var tppTemplate = require('./templates/third-party-pixel');
var fns = require('scripts/common/fns');
var Form = require('scripts/common/mixins/form');
var Popup = require('scripts/common/mixins/popup');
var Macros = require('scripts/common/constants/macros');


var FORMAT_DESCRIPTIONS = {
    undefined: 'Choose a format...',
    standard: 'Standard',
    mraid: 'MRAID (Rich Media)',
    custom_html: 'HTML/JavaScript (Rich Media)',
    video: 'Video',
    native: 'Native'
};

var debug_bindings = false;
var debug_assetHelper = false;

var CreativeForm = Mario.Layout.extend({
    behaviors: {
        Form: {
            formBehaviorNoInputKeys : true
        },
        Popup: {}
    },

    template: formTemplate,

    templateHelpers: function() {
        return {
            in_popup: this.options.inPopup
        };
    },

    className: 'creative-form ef-creative-builder',

    regions: {
        region_AssetsHelper_icon         : '.region-assets-helper-icon',
        region_AssetsHelper_mainImg      : '.region-assets-helper-main-img',
        region_AssetsHelper_banner       : '.region-assets-helper-banner',
        region_AssetsHelper_referenceImg : '.region-assets-helper-reference-img',
        region_AssetsHelper_video        : '.region-assets-helper-video',
        region_AssetsHelper_companionAd  : '.region-assets-helper-companion-ad'
    },

    events: {
        'change [data-id=format]'                  : 'onFormatChanged',
        'change [data-id=size]'                    : 'onUiSizeChanged',
        'click [data-action=save-and-audit]'       : 'saveAndAudit',
        'click [data-action=add-pixel]'            : 'addRowForPixel',
        'click [data-action=remove-pixel]'         : 'removeRowForPixel',
        'click [data-action=cancel]'               : 'onCancel',
        'change [data-id=third_party_clickthrough]': 'toggleLandingPageField',
        'click [js-selectable=true]'               : 'insertAsset',
        'blur [data-id=clickthrough_url]'          : 'autoCorrect',
        'blur [data-id=landing_page]'              : 'autoCorrect',
        'keydown :input'                           : 'keydown',
        'keyup :input'                             : 'keyup',
        'keyup [data-assets-section] :input'       : 'checkAssociation',
        'paste input[data-id]'                     : 'onPaste'
    },

    onPaste: function(e) {
        var $el = $(e.target);
        setTimeout(function () {
            var regEx1 = /(\[timestamp\]|\[TIMESTAMP\]|%time%|%time2%|Insert_Time_Date_Stamp_Here|INSERT_TIMESTAMP_HERE)/gm;
            var regEx2 = /ord=%n/gm;
            var text = $el.val();
            var replacementText = text.replace( regEx1, '%%TIMESTAMP%%').replace( regEx2, 'ord=%%TIMESTAMP%%' );
            $el.val(replacementText);

            if ( regEx1.test(text) || regEx2.test(text) ) {
                var regEx3 = /^.*(\[timestamp\]|\[TIMESTAMP\]|%time%|%time2%|Insert_Time_Date_Stamp_Here|INSERT_TIMESTAMP_HERE|ord=%n).*$/gm;
                var matchedSubString = text.replace(regEx3, '$1');
                var replacedSubString = '%%TIMESTAMP%%'
                if (matchedSubString === 'ord=%n' ) {  replacedSubString = 'ord=%%TIMESTAMP%%' };
                toastr.success('"' + matchedSubString + '" has been automatically replaced with "' + replacedSubString + '"', 'Macro Detected');
            }

        }, 100);
    },

    keyup: function (e) {
        e.preventDefault;
        this.reloadSimulator();

        return false;
    },

    keydown: function(ev) {
        // Unless it is ace editor we want to prevent default form's behaviour.
        var className = ev.target.className;
        var isAceEditor = ( className.search(/\s*ace_text-input\s*/) === 0 )? true : false ;

        if( !isAceEditor && event.keyCode == 13) {
            ev.preventDefault();
            return false;
        }
    },

    autoCorrect : function(ev) {
        var $target = $(ev.target);
        var targetDataId = $target.attr('data-id')
        var val = $target.val();
        var mode = 'unregconized';
        var correctionVal = '';

        var regex_phoneNumber            = /^([\d;,\*\#\+\-\.pw]+)$/;
        var regex_clickToCall            = /^(tel:(\/\/)?[\d;,\*\#\+\-\.pw]+)$/;
        var regex_protocol               = /^(tel:|[\-\w]+:\/\/)/;

        if ( regex_clickToCall.test(val) || regex_phoneNumber.test(val) ) {
            mode = 'clickToCall';
        } else {
            mode = 'url';
        };

        switch (mode) {
            case 'clickToCall':
                if ( regex_phoneNumber.test(val) ) {
                    correctionVal = val.replace(regex_phoneNumber, 'tel:$1'  );
                } if ( regex_clickToCall.test(val))
                    correctionVal = val;
                break;
            case 'url':
                if ( !regex_protocol.test(val) && ( val !== '' ) ) {
                   correctionVal = 'http://' + val;
                } else {
                    correctionVal = val;
                }
                break;
        }
        $target.val(correctionVal);
    },

    initializeAssociation: function() {
         this.$el.find('[data-assets-section] input')
            .filter('[data-id]')
            .filter('[type!=hidden]')
            .trigger('keyup');
    },

    checkAssociation: function(ev) {
        var debug_local = false;
        var that           = this;
        var $target        = $(ev.target); // target is the form input

        var $assetsSection = $target.closest('[data-assets-section]');       // jQwrapper of current working section.
        var currentSection = $assetsSection.attr('data-assets-section');     // String that identify current working section.
        var currentKey     = $target.attr('data-id');                        // String that identify the attribute of current working field.
        var value_form     = ($target.val()) ? S($(ev.target).val()).s : ''; // Value of the current working form.
        var id_form        = '';                                             // Asset id for which the current working form could be associate with.

        var assets         = this.options.campaign.assets;                   // A collection that hold assets upload by user.
        var $assetsHelper  = $assetsSection.find('[class^=region-assets-helper]');  // jQwrapper of current asset helper
        var $assetsRows     = $assetsHelper.find('.assets tbody tr.asset');   // jQwrapper of table row in current assets helper

        // dictionary to map variable names in model and assetHelper
        var keyTranslation = function( key, section ) {
            var dictionary = {
                'icon' : {
                    'icon_image_asset'       : 'id',
                    'icon_image_url'         : 'url',
                    'icon_image_preview_url' : 'preview_url',
                },
                'main_img' : {
                    'main_image_asset'       : 'id',
                    'main_image_url'         : 'url',
                    'main_image_preview_url' : 'preview_url',
                },
                'banner' : {
                    'image_asset'       : 'id',
                    'image_url'         : 'url',
                    'image_preview_url' : 'preview_url',
                },
                'reference_img' : {
                    'reference_image_asset'       : 'id',
                    'reference_image_preview_url' : 'preview_url',
                },
                'video' : {
                    'video_asset'       : 'id',
                    'video_url'         : 'url',
                    'video_preview_url' : 'preview_url',
                    'image_preview_url' : 'thumbnail_url',
                    'video_duration'    : 'duration'
                },
                'companion_ad' : {
                    'companion_asset'       : 'id',
                    'companion_url'         : 'url',
                    'companion_preview_url' : 'preview_url'
                }
            }
            return dictionary[section][key];
        }

        // isDirty() return true if form is associate with the correspondent item in Asset helper.
        var isDirty = function( id_form ) {
            var debug_local = false;
            if (debug_local) console.log('-------- in isDirty(id_form) current section: ', currentSection);

            var asset;
            var value_asset;

            if ( id_form === '' ) {
                if (debug_local) console.log('case A: model from server did not provide id');
                return true;
            } else {
                // Form has an asset id: Either server has provided
                // id or user has filled in the form with asset helper.
                // In this case we check if item with this id exist in assets help.

                asset = assets.findWhere({id:id_form});

                if ( asset ) {
                    // Item of this id exist in asset helper, we need to compare if the value in the from match the value in the asset helper.
                    value_asset = S(asset.get( keyTranslation(currentKey, currentSection) )).s;
                    if ( value_asset === value_form ) {
                        if (debug_local) console.log('case B: Assets are equal, this field is associated with asset helper of the same id.');
                        return false;
                    } else {
                        if (debug_local) console.log('case C, Assets are NOT equal, this field is NOT associated with asset helper of the same id.');
                        return true;
                    }
                } else {
                    if (debug_local) console.log('case D,  item with this id does NOT exist in asset helper');
                    return true;
                }
            }
        };
        // END :: isDirty()

        switch (currentSection){
            case 'icon':
                id_form = $assetsSection.find('[data-id=icon_image_asset]').val();
                $target.attr( 'data-isdirty', isDirty(id_form) );
                break;
            case 'main_img':
                id_form = $assetsSection.find('[data-id=main_image_asset]').val();
                $target.attr( 'data-isdirty', isDirty(id_form) );
                break;
            case 'banner':
                id_form = $assetsSection.find('[data-id=image_asset]').val();
                $target.attr( 'data-isdirty', isDirty(id_form) );
                break;
            case 'reference_img':
                id_form = $assetsSection.find('[data-id=reference_image_asset]').val();
                $target.attr( 'data-isdirty', isDirty(id_form) );
                break;
            case 'video':
                id_form = $assetsSection.find('[data-id=video_asset]').val();
                $target.attr( 'data-isdirty', isDirty(id_form) );
                break;
            case 'companion_ad':
                id_form = $assetsSection.find('[data-id=companion_asset]').val();
                $target.attr( 'data-isdirty', isDirty(id_form) );
                break;
        }

        var $fieldDirtys = $assetsSection.find('[data-isdirty]');
        var fieldDirtys =_.map(  $fieldDirtys, function(item){
            var strTrueOrFalse = $(item).attr('data-isdirty');
            var bool = ( strTrueOrFalse === "true" );
            return bool;
        });
        if (debug_local) console.log('fieldDirtys', fieldDirtys);

        var sectionIsDirty = _.reduce( fieldDirtys, function(acc, fieldDirty, key, coll){
            return acc || fieldDirty;
        });

        $assetsSection.attr('data-section-isdirty', sectionIsDirty);

        this.model.assetAssociation[currentSection] = !sectionIsDirty;

        if (sectionIsDirty) {
            $assetsHelper.find('tbody tr.asset.isAssociate').removeClass('isAssociate');
            if (!this.model.isNew()) {
                // naviate to url tab
                $assetsSection.find('ul a[data-target$=b]').tab('show');
            }

        } else {
            $assetsRows.each(function(index){
                var $assetsRow = $(this);
                var id = $assetsRow.attr('data-id');
                if (id_form === id) {
                   $assetsRow.addClass('isAssociate');
                }
            })
            // naviate to assets tab
            $assetsSection.find('ul a[data-target$=a]').tab('show');
        }

        if (debug_local) console.log('currentSection: ', currentSection);
        if (debug_local) console.log('sectionIsDirty: ', sectionIsDirty);
        if (debug_local) console.log('association: ', this.model.assetAssociation[currentSection] );
        if (debug_local) console.log('associations: ', this.model.assetAssociation );
    },

    onCancel: function() {
        // app.request('popup:close');  // keep this for now, till figure out diff btw global vs app.
        globalCh.trigger('popup:close');
    },

    initialize: function() {
        /* jshint maxcomplexity: 10 */
        window.v = this;

        if (!this.collection || !this.collection instanceof Creatives) {
            throw new Error('Missing params: opti/on.collection {Creatives}');
        }

        var Creative = this.collection.constructor.prototype.model;
        if (this.options.duplicate) {
            this.model = new Creative(this.options.duplicate, {
                urlRoot: this.collection.url
            });
        } else if (!this.model) {
            this.model = new Creative(null, {
                urlRoot: this.collection.url
            });
        }

        this.initPixelValidationHandler();

        Object.defineProperty(this, 'selectedFormat', {
            get: function() {
                return this.$('[data-id=format] option:selected').val();
            }
        });

        this.initializeAssetsHelper();

        this.listenTo(this.model,'creativeBuilder:tabNavigate', function(){
            $('#creative-tab a[data-target="#format"]').tab('show');
        });

    },

    initializeAssetsHelper : function() {
        this._sectionCatalogue = ['icon', 'mainImg', 'banner', 'referenceImg', 'video', 'companionAd'];
        // this._sectionCatalogue = ['companionAd', 'video'];
        // this._sectionCatalogue = ['icon', 'mainImg'];
        this._views_AssetsHelper = {};
        this._sectionCatalogue.forEach(function(sectionItem) {
            this._views_AssetsHelper[sectionItem] = new EmbededAssetsView({
                    campaign: this.options.campaign,
                    embed: true,
                    creative: this.model,
                    ch: this.options.ch,
                    section: sectionItem
            });
        }, this);
    },

    // need to handle in a special way, because it's an array of values
    initPixelValidationHandler: function() {
        var tpl = _.template('<div class="help-block"><%=msg%></div>');

        this.listenTo(this.model, 'invalid', function(m, errors, options) {
            if (errors.third_party_pixels) {

                var pixelErrors = _.map(errors.third_party_pixels.split(','),
                    function(boolStr) {
                        return boolStr === 'true' ? null : 'Invalid pixel url';
                    });

                this.$('[data-id="third_party_pixels"]:visible').each(function(idx) {
                    $(this).removeClass('valid').next('.help-block').remove();

                    if (pixelErrors[idx] !== null) {
                        $(this).after(tpl({
                            msg: pixelErrors[idx]
                        }));
                    } else {
                        $(this).addClass('valid');
                    }
                });
            }
        });
    },

    onShow: function() {
        _.bindAll(this);
        var that = this;

        Q.fcall(this.loadFormats)       // load formats available
        .then(this.renderFormatSelector)// build format select html
        .then(this.initFormat)          // Set the format in form
        .then(this.initEditor)          //
        .then(this.stickit)             // Populate form with model's values
        .done(function() {
            that.initializeAssociation();
            that.toggleLandingPageField();
            that.loadSimulatorImage();
        });

        this.$('[data-toggle=popover]').popover();
        this.showAssetsHelper();
        Backbone.Validation.bind(this);

        // Tab Navigation
        $('#creative-tab a[data-target="#format"]').tab('show');

        this.$el.find('[data-action=next], [data-target=#customize]').click(function(e){
            e.preventDefault();
            that.initSimulator();
            var format = that.model.get('format');
            var size = that.model.get('size');

            // Cannot navigate to customized tab if no format is selected
            var $groupFormat = that.$el.find('[data-id=format]').closest('.form-group');
            if ( format !== 'undefined' && format !== '' ) {
                $groupFormat.removeClass('has-error');
                $groupFormat.find('.help-block').html('');
                if (format !== 'native') {
                    // Cannot navigate to customized tab if no size is selected
                    var $groupSize = that.$el.find('[data-id=size]').closest('.form-group');
                    if ( size !== 'undefined' && size !== '' ) {
                        $groupSize.removeClass('has-error');
                        $groupSize.find('.help-block').html('');
                        that.navigateToCustomTab();
                    } else {
                        $groupSize.addClass('has-error');
                        $groupSize.find('.help-block').html('Size is required');
                    }
                } else {
                    that.navigateToCustomTab();
                };
            } else {
                $groupFormat.addClass('has-error');
                $groupFormat.find('.help-block').html('format is required');
            }
        })

        this.$el.find('[data-target=#format]').click(function(e){
            e.preventDefault();
            that.navigateToFormatTab();
        })

        //apollo @TODO clean up required
            //@TODO
                // 1. click events should be merge into one
                // 2. use $el instead of $
            $('#asset-1-tab a').click(function(e) {
                e.preventDefault();
                $(this).tab('show');
            });
            $('#asset-2-tab a').click(function(e) {
                e.preventDefault();
                $(this).tab('show');
            });
            $('#asset-3-tab a').click(function(e) {
                e.preventDefault();
                $(this).tab('show');
            });
            $('#asset-4-tab a').click(function(e) {
                e.preventDefault();
                $(this).tab('show');
            });
            $('#asset-5-tab a').click(function(e) {
                e.preventDefault();
                $(this).tab('show');
            });
            $('#asset-6-tab a').click(function(e) {
                e.preventDefault();
                $(this).tab('show');
            });
        //apollo

        if (this.model.isNew() && !this.options.hasOwnProperty('duplicate') ) {
            //
        } else {
            this.updateAssetSizeLabelInFrom(this.model.get('format'), this.model.get('size'))
            // skip format tab if not new creative
            $('#creative-tab a[data-target="#customize"]').tab('show');
            this.initSimulator();
        }
    },

    navigateToFormatTab: function() {
        // Cache the value of size in form, it will be used to check for change when navigate back to custom tab.
        this._previousSize = this.$el.find('[data-id=size]').val();
        $('#creative-tab a[data-target="#format"]').tab('show');
    },

    navigateToCustomTab: function(){
        var model = this.model;

        if (this._previousSize !== this._selectedSizeInUi ) {
            // Size has changgd, need to clear value of all size related item in the form

            this.$el.find('[data-id=icon_image_asset]').val('');
            this.$el.find('[data-id=icon_image_url]').val('');
            this.$el.find('[data-id=icon_image_preview_url]').val('');

            this.$el.find('[data-id=main_image_asset]').val('');
            this.$el.find('[data-id=main_image_url]').val('');
            this.$el.find('[data-id=main_image_preview_url]').val('');

            this.$el.find('[data-id=image_asset]').val('');
            this.$el.find('[data-id=image_url]').val('');
            this.$el.find('[data-id=image_preview_url]').val('');

            this.$el.find('[data-id=reference_image_asset]').val('');
            this.$el.find('[data-id=reference_image_preview_url]').val('');

            this.$el.find('[data-id=video_asset]').val('');
            this.$el.find('[data-id=video_url]').val('');
            this.$el.find('[data-id=video_preview_url]').val('');

            this.$el.find('[data-id=companion_asset]').val('');
            this.$el.find('[data-id=companion_url]').val('');
            this.$el.find('[data-id=companion_preview_url]').val('');

            // Before navigate to customize, refresh the simulator by reloading it.
            this.reloadSimulator();
        };

        // Check association for all asset sections.
        this.initializeAssociation();

        // Navigate back to customize tab.
        $('#creative-tab a[data-target="#customize"]').tab('show');
    },

    initSimulator: function () {
        if (this.simulator) {
            return;
        }

        var c = {
            format: this.model.get('format'),
            size: this.model.get('size')
        };
        var factory = React.createFactory(Simulator);
        this.simulator = React.render(factory({ creative: c }), document.querySelector('.simulator'));
    },

    showAssetsHelper: function() {
        this._sectionCatalogue.forEach(function(sectionItem) {
            var regionName = 'region_AssetsHelper_' + sectionItem;
            this[regionName].show( this._views_AssetsHelper[sectionItem] );
        }, this);
    },

    onDestroy: function() {
        Backbone.Validation.unbind(this);
    },

    initFormat: function() {
        if (this.model.isNew() && !this.options.hasOwnProperty('duplicate') ) {
            this.$('[data-id=format] option').first().click().change();
        } else {
            // select and disable
            this.$('[data-id=format] option')
                .filter('[value=' + this.model.get('format') + ']')
                .click().change()
                .parent().prop('disabled', true);
        }
    },

    initEditor: function() {
        this.editor = editor.getEditor(this.$('[data-id=content_html]'));
    },

    toggleLandingPageField: function() {
        var hasTPC = this.$('[data-id=third_party_clickthrough]:checked').val() === 'true';
        this.$('[data-id=clickthrough_url]').closest('.form-group').toggle(hasTPC);
        this.reloadSimulator();
    },

    removeRowForPixel: function(ev) {
        var $pixelRows = $('.section-third-party-pixel .pixel-input-container .pixel-row');
        var l = $pixelRows.length;
        if (l > 2) {
            $(ev.currentTarget).closest('.pixel-row').remove();
        } else if ( l === 2 ) {
            // remove second last, and change btn label from 'remove' to 'clear'
            $(ev.currentTarget).closest('.pixel-row').remove();
            $pixelRows.find('[data-action=remove-pixel] span').html('clear');
        } else if ( l === 1 ) {
            // clear form value
            $pixelRows.find('input[data-id=third_party_pixels]').val('');
        }

        this.reloadSimulator();
        return false;
    },

    addRowForPixel: function(ev) {
        var $pixelInputContainer = this.$('.section-third-party-pixel .pixel-input-container');
        var $newRow = $('.section-third-party-pixel .pixel-template').clone(false);
        $newRow.find('input').attr('name', 'third_party_pixels-' + _.random(9999));

        var $newInput = $newRow.find('input');

        var $pixelRows = $('.section-third-party-pixel .pixel-input-container .pixel-row');
        var l = $pixelRows.length;

        if (l > 1) {
            $pixelInputContainer.append($newRow.html());
            this.initAtWhoFor($newInput);
        } else if ( l === 1 ) {
            // there is only one row, change btn label from 'clear' to 'remove'
            $pixelRows.find('[data-action=remove-pixel] span').html('remove');
            // append more row
            $pixelInputContainer.append($newRow.html());
            this.initAtWhoFor($newInput);
        }


        return false;
    },

    loadFormats: function() {
        if (this.model.get('format') !== '') {
            return [this.model.get('format')];
        }

        var def = Q.defer();

        // var organizationReq = app.request('cache', 'organizations', this.options.campaign.get('organization'));
        var organizationReq = globalCh.request('cache', 'organizations', this.options.campaign.get('organization'));
        organizationReq.done(function (organization) {
            def.resolve(organization.getEnabledCreativeFormats());
        });

        return def.promise;
    },

    renderFormatSelector: function(formats) {
        formats.unshift('undefined');
        var tpl = _.template('<option value="<%=format%>"><%=desc%></option>');
        var options = _.map(formats, function(format) {
            return tpl({
                format: format,
                desc: FORMAT_DESCRIPTIONS[format]
            });
        });
        this.$('[data-id=format]').html(options.join(''));
    },

    onFormatChanged: function(format) {
        var selectedFormat;
        if (_.isString(format)) {
            // Format is a type string
            selectedFormat = format;
        } else {
            // Format is an event object
            selectedFormat = $(format.target).val();
        }

        if (this.model.isNew()) {
            this.$('[data-id=size] option:first-child').prop('selected', true).change();
        } else {
            var size = this.model.get('size');
            this.$('[data-id=size] option[value="' + size + '"]').prop('selected', true).change();
        }

        this.model.set({ format : selectedFormat } );

        if (selectedFormat === 'native' ) {
            // because native does not have size
            // we need to manually trigger change in size's selection dropdown
            this.$el.find('[data-id=size]').trigger('change');

            this.$el.find('[data-id=size]').closest('.form-group').hide();
        } else {
            this.$el.find('[data-id=size]').closest('.form-group').show();
        }

        this.updateSizeField(selectedFormat);
        this.updateRequiredFieldMarker(selectedFormat);
        this.showAndHideSection(selectedFormat);
        this.updateAssetsHelper(selectedFormat);
    },

    onUiSizeChanged: function(ev) {
        // Beaware that in additional to user trigger onUiSizeChanged event, onFormatchanged
        // also trigger onUiSizeChanged event.  This is because
        // onFormatChanged will call updateSizeField() to
        // alter contents in selection dropdown (alteration of dropdown content is a change event).

        var selectedFormat = this.model.get('format');
        this._selectedSizeInUi = $(ev.target).val();

        this.loadSimulatorImage( this._selectedSizeInUi );
        this.updateAssetsHelper(selectedFormat, this._selectedSizeInUi);
        this.updateAssetSizeLabelInFrom(selectedFormat, this._selectedSizeInUi);

        if (this.model.isNew()) {
            this.updateModelSize(this._selectedSizeInUi);
        }
    },

    updateModelSize: function(selectedSize) {
        var selectedFormat = this.model.get('format');
        if (selectedFormat === 'native' ) {
            this.model.set('size', '80x80');
        } else {
            this.model.set({ size : selectedSize } );
        }
    },

    updateAssetSizeLabelInFrom: function(selectedFormat, selectedSize){
        var translate = {
            'standard' : 'Standard',
            'video' : 'Video',
            'mraid' : 'MRAID',
            'custom_html' : 'HTML JavaScript',
            'native' : 'Native'
        };
        var displayFormatText = translate[selectedFormat];

        if (selectedFormat === 'native' ) {
            this.$el.find('.standard-n-size-text').html(displayFormatText);
            this.$el.find('[data-assets-section=icon] .asset-wrap-header-asset-size').html('(80x80)');
            this.$el.find('[data-assets-section=main_img] .asset-wrap-header-asset-size').html('(1200x627)');
        } else {
            this.$el.find('.standard-n-size-text').html(displayFormatText+' / '+selectedSize);
            this.$el.find('.asset-wrap-header-asset-size').html('(' + selectedSize + ')');
        }
    },

    loadSimulatorImage: function(selectedSize) {
        var sizeInModel = this.model.get('size');
        if (selectedSize === void 0) {
            selectedSize = sizeInModel;
        }

        var selectedFormat = this.model.get('format');
        var urlRoot = window.location.origin + '/images/creative-builder/';
        var imgFileName;
        if (  selectedFormat == 'native' && selectedSize =='undefined') {
            imgFileName = 'choose-a-format' + '.png';
        } else if ( selectedFormat !== 'undefined' && selectedSize !=='') {
            imgFileName = selectedFormat + '__' + selectedSize + '.png';
        } else {
            imgFileName = 'choose-a-format' + '.png';
        }
        var imgUrl = urlRoot + imgFileName;
        var imgElement = this.$el.find('.simulator-region img').get(0);
        imgElement.src = imgUrl;
    },

    updateRequiredFieldMarker: function(format) {
        // clickthrough_url is only required by 'standard', 'native' and 'video'
        var ctuRequied = _.contains(['standard', 'native', 'video'], format);
        this.$('[data-id=clickthrough_url], [data-id=landing_page]')
            .closest('.form-group')
            .find('.fa-asterisk.text-danger')
            .toggle(ctuRequied);
    },

    updateAssetsHelper: function(selectedFormat, selectedSize) {
        this._sectionCatalogue.forEach(function(sectionItem) {
            this._views_AssetsHelper[sectionItem].trigger('filter', selectedFormat, selectedSize);
        }, this);
    },

    onRender: function() {
        var currentFormat = this.model.get('format');
        this.showAndHideSection(currentFormat);
        this.initAtWhoFor(this.$('input[type=text], textarea'));
    },

    showAndHideSection: function(format) {
        // comment next three line to reveal hidden sections
        this.$('[data-assets-section]').hide();
        this.$('[data-section]').hide();
        this.$('.section-third-party-pixel').hide();

        switch (format) {
            case 'standard':
                this.$('[data-assets-section=banner]').show();
                this.$('.section-third-party-pixel').show();
                break;
            case 'mraid':
                this.$('[data-assets-section=reference_img]').show();
                this.$('[data-section=html_editor]').show();
                break;
            case 'custom_html':
                this.$('[data-assets-section=reference_img]').show();
                this.$('[data-section=html_editor]').show();
                break;
            case 'video':
                this.$('[data-assets-section=video]').show();
                this.$('[data-assets-section=companion_ad]').show();
                this.$('.section-third-party-pixel').show();
                break;
            case 'native':
                this.$('[data-assets-section=icon]').show();
                this.$('[data-assets-section=main_img]').show();
                this.$('[data-section=content]').show();
                break;
        }
    },

    initAtWhoFor: function(el) {
        el.atwho({
            at: '%%',
            data: Macros,
            limit: Macros.length,
            tpl: '<li data-value="${atwho-at}${name}">${name}</li>',
            suffix: '%%',
            start_with_space: false,
            hide_without_suffix: true
        });
    },

    /**
     *  When an item in the asset is clicked, get the name
     *  of the section being clicked from "data-assets-section" attribute in html,
     *  then fill in the form under the same section.
     */
    insertAsset: function(ev) {
        /* jshint maxcomplexity: 20 */

        var $currentTarget = $(ev.currentTarget);

        $currentTarget.siblings().removeClass('isAssociate');
        $currentTarget.addClass('isAssociate');

        var $el_currentSection = $currentTarget.closest('[data-assets-section]')
        var currentSection = $el_currentSection.attr('data-assets-section');

        // extract values from model
        var asset = $currentTarget.data('asset');
        var id_asset = asset.get('id');
        var url_preview = asset.get('preview_url');
        var url_thumbnail = asset.get('thumbnail_url');
        var url_asset = asset.get('url');
        var videoDuration = asset.get('duration');

        switch (currentSection) {
            case 'icon':
                $el_currentSection.find('[data-id=icon_image_asset]').val(id_asset);
                $el_currentSection.find('[data-id=icon_image_url]').val(url_asset).attr( 'data-isdirty', false );
                $el_currentSection.find('[data-id=icon_image_preview_url]').val(url_preview).attr( 'data-isdirty', false );
                this.model.assetAssociation.icon = true;
                break;
            case 'main_img':
                $el_currentSection.find('[data-id=main_image_asset]').val(id_asset);
                $el_currentSection.find('[data-id=main_image_url]').val(url_asset).attr( 'data-isdirty', false );
                $el_currentSection.find('[data-id=main_image_preview_url]').val(url_preview).attr( 'data-isdirty', false );
                this.model.assetAssociation.main_img = true;
                break;
            case 'banner':
                $el_currentSection.find('[data-id=image_asset]').val(id_asset );
                $el_currentSection.find('[data-id=image_url]'  ).val(url_asset).attr( 'data-isdirty', false );

                this.$el.find('[data-id=image_preview_url]').val(url_preview).attr( 'data-isdirty', false );
                this.model.assetAssociation.banner = true;
                break;
            case 'reference_img':
                $el_currentSection.find('[data-id=reference_image_asset]').val(id_asset);
                $el_currentSection.find('[data-id=reference_image_preview_url]').val(url_preview).attr( 'data-isdirty', false );
                this.model.assetAssociation.reference_img = true;
                break;
            case 'video':
                $el_currentSection.find('[data-id=video_asset]'      ).val(id_asset);
                $el_currentSection.find('[data-id=video_url]'        ).val(url_asset).attr( 'data-isdirty', false );
                $el_currentSection.find('[data-id=video_preview_url]').val(url_preview).attr( 'data-isdirty', false );
                $el_currentSection.find('[data-id=video_duration]').val(videoDuration).attr( 'data-isdirty', false );

                this.$el.find('[data-id=image_preview_url]').val(url_thumbnail).attr( 'data-isdirty', false );
                this.model.assetAssociation.video = true;
                break;
            case 'companion_ad':
                $el_currentSection.find('[data-id=companion_asset]').val(id_asset);
                $el_currentSection.find('[data-id=companion_url]').val(url_asset).attr( 'data-isdirty', false );
                $el_currentSection.find('[data-id=companion_preview_url]').val(url_preview).attr( 'data-isdirty', false );
                this.model.assetAssociation.companion_ad = true;
                break;
        }

        this.reloadSimulator();
    },

    updateSizeField: function(format) {
        var currentSize = this.model.get('size');

        // populate with applicable sizes
        // specifically, for video, only interstitials are applicable
        var sizes = this.collection.getSizesByFormat(format);

        // update sizes
        sizes['opt_labels'] = _.keys(sizes);

        var config = Backbone.Stickit.getConfiguration(this.$('[data-id=size]'), this.bindings);
        config['[data-id=size]']['selectOptions']['collection'] = sizes;

        var updateFn = config.update;
        updateFn.call(this, this.$('[data-id=size]'), sizes, this.model, config['[data-id=size]']);
        // Whatever it is doing last two lines is setting the model size attribute !!!
        // hack it for now --- set it back:
        if (currentSize !== '') {
            this._selectedSizeInUi = currentSize;
        } else {
            // Empty size because it is a new creative
            this.model.set('size', '');
            this._selectedSizeInUi = '';
        }
    },

    saveAndAudit: function(ev) {
        return this.onSave(ev, true);
    },

    onSave: function(ev, withAudit) {
        var that = this;
        this.isSaving = true;
        var AUDIT_STATUS = Creatives.AUDIT_STATUS;
        var usePatch = !this.model.isNew();
        // var usePatch = false; // cannot use patch ??
        var data = helpers.stickitGetValues.call(this, this.model.keys(), {
            patch: usePatch
        });


        if (withAudit) {
            data['audit_status'] = AUDIT_STATUS.PENDING;
        } else {
            data['audit_status'] = AUDIT_STATUS.NO_AUDIT;
        }

        data['comment'] = this.$('.change-comment').val();

        if (this.options.duplicate_from && this.options.duplicate) {
            data.duplicate_from = this.options.duplicate_from;
        }

        this.model.save(data, {
            wait: true,
            patch: usePatch,
            success: function(model) {
                toastr.success('Creative saved successfully');
                that.collection.set([model], {
                    remove: false
                });
                //  app.request('popup:close');
                globalCh.trigger('popup:close');
            },
            error: function(model, response, options) {
                // @TODO handling model save error
                console.log('model save fail', arguments);
            },
            complete: function () {
                that.isSaving = false;
            }
        });

        return false;
    },

    /**
     * implement form close confirm
     * @returns {string|boolean}
     */
    canClose: function() {
        return false;
    },

    reloadSimulator: function () {

        if (!this.simulator) {
            return;
        }

        if (this.isSaving) {
            return;
        }

        var formData = $('form')
            .serializeArray()
            .filter(function (field) {
                return field.value !== '';
            });

        var parsedData = _.reduce(formData, function (sum, field) {

            // pure h4x - image_preview_url is repeated by multiple creative formats, but we only need 1 value
            if (sum[field.name] && field.name !== 'image_preview_url') {
                if (!Array.isArray(sum[field.name])) {
                    sum[field.name] = [sum[field.name]];
                }

                sum[field.name].push(field.value);
            } else {
                sum[field.name] = field.value;
            }

            return sum;
        }, {});

        parsedData.format = this.model.get('format');
        parsedData.size = this._selectedSizeInUi;
        parsedData.content_html = this.editor.getValue();
        parsedData.mraid = this.editor.getValue();

        this.simulator.setProps({ creative: parsedData });
    },

    // Backbone.stickit bindings to form
    bindings: {
        '[data-id=format]': {
            observe: 'format',
            updateModel: function(val) {
                if (this.model) { this.model.trigger('updateFormValue', val, 'format'); }
                return false;
            },
            getVal: function($el) {
                var value = $el.val() ? S($el.val()).trim().s : '';
                var returnValue = value;
                if (debug_bindings) console.log( 'format value:        ', value );
                if (debug_bindings) console.log( 'format return value: ', returnValue );
                return returnValue;
            }
        },

        '[data-id=size]': {
            observe: 'size',
            selectOptions: {
                defaultOption: {
                    label: 'Choose a size...',
                    value: null
                },
                collection: function() { return []; }
            },
            updateModel: function(val) {
                if (this.model) {
                    this.model.trigger('updateFormValue', val, 'size');
                }
                return false;
            },
            getVal: function($el, val) {
                var returnVal;
                if (this.selectedFormat === 'native') {
                    returnVal =  '80x80';
                } else {
                    returnVal =  $el.find('option:selected').val();
                }
                return  returnVal;
            }
        },

        '[data-id=name]': 'name',

        '[data-id=content_html]': {
            observe: 'content_html',
            update: function($el, val) { this.editor.setValue(val); },
            getVal: function() {
                if (this.selectedFormat === 'native') { return 'native ad'; }
                if (this.editor) { return this.editor.getValue(); }
            }
        },

        // section name   : Icon image
        // used by format : native
        // asset section  : icon

            '[data-id=icon_image_asset]': {
                observe: 'icon_image_asset',
                getVal: function($el) {
                    var format = this.model.get('format');
                    if  (format === 'native')  {
                        var value = $el.val() ? S($el.val()).trim().s : '';
                        var association = this.model.assetAssociation.icon;
                        var returnValue = (value && association) ?  value : null;
                        if (debug_bindings) console.log( 'icon_image_asset assetAssoctiation: ', association );
                        if (debug_bindings) console.log( 'icon_image_asset value:             ', value );
                        if (debug_bindings) console.log( 'icon_image_asset return value:      ', returnValue );
                        return returnValue;
                    } else {
                        return '';
                    }
                }
            },

            '[data-id=icon_image_url]': {
                observe: 'icon_image_url',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    var returnValue = value;
                    if (debug_bindings) console.log( 'icon_image_url value:        ', value );
                    if (debug_bindings) console.log( 'icon_image_url return value: ', returnValue );
                    return returnValue;
                }
            },

            '[data-id=icon_image_preview_url]': {
                observe: 'icon_image_preview_url',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    var returnValue = value;
                    if (debug_bindings) console.log( 'icon_image_preview_url value:        ', value );
                    if (debug_bindings) console.log( 'icon_image_preview_url return value: ', returnValue );
                    return returnValue;
                }
            },

        // section name   : Main Image
        // used by format : native
        // asset section  : main_img

            '[data-id=main_image_asset]': {
                observe: 'main_image_asset',
                getVal: function($el) {
                    var format = this.model.get('format');
                    if  (format === 'native')  {
                        var value = $el.val() ? S($el.val()).trim().s : '';
                        var association = this.model.assetAssociation.main_img;
                        var returnValue = (value && association) ?  value : null;
                        if (debug_bindings) console.log( 'main_image_asset assetAssoctiation: ', association );
                        if (debug_bindings) console.log( 'main_image_asset value:             ', value );
                        if (debug_bindings) console.log( 'main_image_asset return value:      ', returnValue );
                        return returnValue;
                    } else {
                        return '';
                    }
                }
            },

            '[data-id=main_image_url]': {
                observe: 'main_image_url',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    var returnValue = value;
                    if (debug_bindings) console.log( 'main_image_url value:        ', value );
                    if (debug_bindings) console.log( 'main_image_url return value: ', returnValue );
                    return returnValue;
                }
            },

            '[data-id=main_image_preview_url]': {
                observe: 'main_image_preview_url',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    var returnValue = value;
                    if (debug_bindings) console.log( 'main_image_preview_url value:        ', value );
                    if (debug_bindings) console.log( 'main_image_preview_url return value: ', returnValue );
                    return returnValue;
                }
            },

        // section name   : Banner
        // used by format : standard
        // asset section  : banner

            '[data-assets-section=banner] [data-id=image_asset]': {
                observe: 'image_asset',
                getVal: function($el){
                    var format = this.model.get('format');
                    if  (format === 'standard')  {
                        var returnValue;
                        var value = $el.val() ? S($el.val()).trim().s : '';
                        var association = this.model.assetAssociation.banner;

                        if (value) {
                            returnValue = association ?  value : null;
                        } else {
                            returnValue = '';
                        }

                        if (debug_bindings) console.log( 'image_asset assetAssoctiation: ', association );
                        if (debug_bindings) console.log( 'image_asset value:             ', value );
                        if (debug_bindings) console.log( 'image_asset return value:      ', returnValue );

                        return returnValue;
                    } else {
                        return '';
                    }

                }
            },

            '[data-id=image_url]': {
                observe: 'image_url',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    var returnValue = value;
                    if (debug_bindings) console.log( 'image_url value:        ', value );
                    if (debug_bindings) console.log( 'image_url return value: ', returnValue );
                    this.reloadSimulator();
                    return returnValue;
                }
            },

            '[data-assets-section=banner] [data-id=image_preview_url]': {
                observe: 'image_preview_url',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    var returnValue = value;
                    // need to sync up other section with same data-id
                    this.$el.find('[data-id=image_preview_url]')
                        .not('[data-assets-section=banner] [data-id=image_preview_url]').val(value);
                    if (debug_bindings) console.log( 'banner image_preview_url: value:        ', value );
                    if (debug_bindings) console.log( 'banner image_preview_url: return value: ', returnValue );
                    return returnValue;
                }
            },

        // section name   : Reference Image
        // used by format : mraid, custom_html
        // asset section  : reference_img

            '[data-assets-section=reference_img] [data-id=reference_image_asset]': {
                observe: 'reference_image_asset',
                getVal: function($el){
                    var format = this.model.get('format');
                    if ( (format === 'mraid' ) || (format === 'custom_html') ) {
                        var returnValue;
                        var value = $el.val() ? S($el.val()).trim().s : '';
                        var association = this.model.assetAssociation.reference_img;

                        if (value) {
                            returnValue = association ?  value : null;
                        } else {
                            returnValue = '';
                        }

                        if (debug_bindings) console.log( 'image_asset assetAssoctiation: ', association );
                        if (debug_bindings) console.log( 'image_asset value:             ', value );
                        if (debug_bindings) console.log( 'image_asset return value:      ', returnValue );

                        return returnValue;
                    } else {
                        return '';
                    }
                }
            },

            '[data-id=reference_image_preview_url]': {
                observe: 'reference_image_preview_url',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    // need to sync up other section with same data-id (but exclude itself)
                    // this.$el.find('[data-id=image_preview_url]')
                    //     .not('[data-assets-section=reference_img] [data-id=image_preview_url]').val(value);
                    var returnValue = value;
                    if (debug_bindings) console.log( 'mraid/content_html image_preview_url value:        ', value );
                    if (debug_bindings) console.log( 'mraid/content_html image_preview_url return value: ', returnValue );
                    return returnValue;
                }
            },

        // section name   : Video
        // used by format : video
        // asset section  : video

            '[data-id=video_asset]': {
                observe: 'video_asset',
                getVal: function($el) {
                    var format = this.model.get('format');
                    if  (format === 'video')  {
                        var value = $el.val() ? S($el.val()).trim().s : '';
                        var association = this.model.assetAssociation.video;
                        var returnValue = (value && association) ?  value : null;

                        if (debug_bindings) console.log( 'video_asset assetAssoctiation: ', association );
                        if (debug_bindings) console.log( 'video_asset value:             ', value );
                        if (debug_bindings) console.log( 'video_asset return value:      ', returnValue );
                        return returnValue;
                    } else {
                        return '';
                    }

                }
            },

            '[data-id=video_url]': {
                observe: 'video_url',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    var returnValue = value;
                    if (debug_bindings) console.log( 'video_url value:        ', value );
                    if (debug_bindings) console.log( 'video_url return value: ', returnValue );
                    return returnValue;
                }
            },

            '[data-id=video_preview_url]': {
                observe: 'video_preview_url',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    var returnValue = value;
                    if (debug_bindings) console.log( 'video_preview_url value:        ', value );
                    if (debug_bindings) console.log( 'video_preview_url return value: ', returnValue );
                    return returnValue;
                }
            },

            '[data-assets-section=video] [data-id=image_preview_url]': {
                observe: 'image_preview_url',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    // need to sync up other section with same data-id
                    this.$el.find('[data-id=image_preview_url]')
                        .not('[data-assets-section=video] [data-id=image_preview_url]').val(value);
                    var returnValue = value;
                    if (debug_bindings) console.log( 'video image_preview_url value:        ', value );
                    if (debug_bindings) console.log( 'video image_preview_url return value: ', returnValue );
                    return returnValue;
                }
            },

            '[data-id=video_duration]': {
                observe: 'video_duration',
                getVal: function($el) {
                    var format = this.model.get('format');
                    if  (format === 'video')  {
                        var value = $el.val() ? S($el.val()).trim().s : '';
                        var returnValue = '';
                        if ( _.isEmpty(value) ) {
                            returnValue  = 'empty'
                        } else {
                            returnValue = parseFloat(value);
                        }
                        if (debug_bindings) console.log( 'video_duration value:        ', value );
                        if (debug_bindings) console.log( 'video_duration return value: ', returnValue );
                        return returnValue;
                    } else {
                        return '';
                    }
                }
            },

        // section name   : Companion
        // used by format : video
        // asset section  : companion_ad

            '[data-id=companion_asset]': {
                observe: 'companion_asset',
                getVal: function($el) {
                    var format = this.model.get('format');
                    if  (format === 'video')  {
                        var value = $el.val() ? S($el.val()).trim().s : '';
                        var association = this.model.assetAssociation.companion_ad;
                        var returnValue = (value && association) ?  value : null;

                        if (debug_bindings) console.log( 'companion_asset assetAssociation: ', association );
                        if (debug_bindings) console.log( 'companion_asset value:            ', value );
                        if (debug_bindings) console.log( 'companion_asset return value:     ', returnValue );
                        return returnValue;
                    } else {
                        return '';
                    }
                }
            },


            '[data-id=companion_url]': {
                observe: 'companion_url',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    var returnValue = value;
                    if (debug_bindings) console.log( 'companion_url value:        ', value );
                    if (debug_bindings) console.log( 'companion_url return value: ', returnValue );
                    return returnValue;
                }
            },

            '[data-id=companion_preview_url]': {
                observe: 'companion_preview_url',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    var returnValue = value;
                    if (debug_bindings) console.log( 'companion_preview_url value:        ', value );
                    if (debug_bindings) console.log( 'companion_preview_url return value: ', returnValue );
                    return returnValue;
                }
            },

        // section name   : Content
        // used by format : native
        // asset section  : content

            '[data-id=title]': {
                observe: 'title',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    var returnValue = value;
                    if (debug_bindings) console.log( 'title value:        ', value );
                    if (debug_bindings) console.log( 'title return value: ', returnValue );
                    return returnValue;
                }
            },

            '[data-id=text]': {
                observe: 'text',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    var returnValue = value;
                    if (debug_bindings) console.log( 'text value:        ', value );
                    if (debug_bindings) console.log( 'text return value: ', returnValue );
                    return returnValue;
                }
            },

            '[data-id=call_to_action]': {
                observe: 'call_to_action',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    var returnValue = value;
                    if (debug_bindings) console.log( 'call_to_action value:        ', value );
                    if (debug_bindings) console.log( 'call_to_action return value: ', returnValue );
                    return returnValue;
                }
            },

            '[data-id=star_rating]': {
                observe: 'star_rating',
                getVal: function($el) {
                    var value = $el.val() ? S($el.val()).trim().s : '';
                    var returnValue = value;
                    if (debug_bindings) console.log( 'star_rating value:        ', value );
                    if (debug_bindings) console.log( 'star_rating return value: ', returnValue );
                    return returnValue;
                }
            },

        //@TODO  have not read the following

            '[data-id=third_party_clickthrough]': {
                observe: 'third_party_clickthrough',
                update: function($el, val) {
                    $el.filter(function() {
                        return $(this).val() === val + '';
                    }).prop('checked', true);
                },
                getVal: function($el) {
                    return $el.filter(function() {
                        return $(this).is(':checked');
                    }).val() === 'true';
                }
            },

            '[data-id=landing_page]': {
                observe: 'landing_page',
                getVal: function($el) {
                    if ($el.val()) {
                        return S($el.val()).trim().s;
                    }
                }
            },

            '[data-id=clickthrough_url]': {
                observe: 'clickthrough_url',
                update: function($el, val) {
                    if ( this.model.get('third_party_clickthrough') ) {
                        $el.val(val);
                    } else {
                        $el.val('');
                    }
                },
                getVal: function($el) {
                    var thirdPartyClickthrough = this.$el.find('#third_party_clickthrough_true').is(':checked')
                    if ( thirdPartyClickthrough ) {
                        if ($el.val()) {
                            return S($el.val()).trim().s;
                        }
                    } else {
                        var clickthrough_url = this.$el.find('[data-id=landing_page]').val()
                        if ( clickthrough_url ) return S( clickthrough_url ).trim().s;
                    }

                }
            },

            '[data-id=third_party_pixels]': {
                observe: 'third_party_pixels',
                getVal: function($el) {
                    var nonTemplate = function() {
                        return !$.contains($('.pixel-template').get(0), this);
                    };
                    var urls = this.$('[data-id=third_party_pixels]').filter(nonTemplate).map(function() {
                        return $(this).val();
                    }).get();
                    return _.compact(urls);
                },

                update: function($el, pixels) {
                    if (pixels.length > 0) {
                        $el.val(pixels[0]);
                        _.each(pixels.slice(1), function(p) {
                            this.addRowForPixel();
                            this.$('.pixel-input-container [data-id=third_party_pixels]').last().val(p);
                        }, this);
                    }
                }
            }
    }
});


module.exports = CreativeForm;
