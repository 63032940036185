'use strict';
var env = require('scripts/env');

// global channel for communcating application level messages
var globalCh = window.globalCh = Backbone.Radio.channel('global');

if (!env.DEBUG || !window.console) {
    window.log = function () {
    };
    window.debug = function () {
    };
    window.warn = function () {
    };
} else {
    window.log = _.bind(console.log, console);
    window.debug = function () {};// _.bind(console.debug, console);
    window.warn = _.bind(console.warn, console);
    window.debug.time = _.bind(console.time, console);
    window.debug.timeEnd = _.bind(console.timeEnd, console);

    Backbone.Radio.DEBUG = true;
    Backbone.Radio.tuneIn('global');
}

ZeroClipboard.config({swfPath: '/ZeroClipboard.swf'});


if (env.ANALYTICS_ENABLED) {
    require('scripts/setup/tracking.es6');
    uTracking('init', env);
}

$('html, body').on('click', function (ev) {
    // if the click target is not a .popover or popover trigger
    // and it's not inside a popover
    // then close all open popover
    if (!$(ev.target).is('.popover, [data-toggle=popover]') &&
        !$(ev.target).closest('.popover, [data-toggle=popover]').length
    ) {
        $('.popover:visible').each(function () {
            $(this).data('bs.popover').hide();
        });
    }


    // close other popovers
    var triggerEle = $(ev.target).closest('[data-toggle=popover]');

    if (triggerEle.length) {

        $('.popover:visible').each(function () {
            var $element = $(this).data('bs.popover').$element;
            if (!triggerEle.is($element)) {
                $(this).data('bs.popover').hide();
            }
        });

    }
    //@TODO .focus will be deprecated after ui-style integration
    if (!$(ev.target).is('.focus') && !$(ev.target).closest('.focus').length) {
        $('.focus').removeClass('focus');
    }
    if (!$(ev.target).is('.is-focus') && !$(ev.target).closest('.is-focus').length) {
        $('.is-focus').removeClass('is-focus');
    }
});

$('.topbar button').on('click', function (ev) {
    $('.body-inner').addClass('slidein');
    $('.workspace').on('click', function (ev) {
        $('.workspace').unbind('click');
        $('.body-inner').removeClass('slidein');
    });
});

Backbone.history.on('route', function () {
    if (globalCh != null) {
        // need to hide all popup, popover
        globalCh.trigger('pops:close');
    }
});

require('./overrides');
require('./mario');
require('scripts/common/hbs-helpers');
require('./ajax-filters');
require('./timezones');
require('./ui');
require('./flux');