<div class="modal-header">
    <h3>Cancel the Audit</h3>
</div>

<div class="modal-body">
    <p>
        You are about to cancel this creative <strong>{{name}}</strong>.
    </p>
    <p>
        Proceed?
    </p>
</div>

<div class="modal-footer">
    <button class="btn btn-action" data-action="proceed">Proceed</button>
    <a href="" data-dismiss="modal">close</a>
</div>