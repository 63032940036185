'use strict';

var { statsReducer } = require('./stats');
var campaignsReducer = require('./campaigns');


function reducer (state = {}, action) {
    return {
        campaigns: campaignsReducer(state.campaigns, action),
        stats: statsReducer(state.stats, action)
    };
}

module.exports = reducer;