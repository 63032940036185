var env = require('scripts/env');
var profileTemplate = require('./templates/profile');

module.exports = Mario.ItemView.extend({
    className: 'user-profile',
    template: profileTemplate,

    onShow: function () {
        var that =  this;
        var app = require('scripts/core').app;
        var userReq = globalCh.request('cache', 'user');

        userReq.done(function (user) {
            that.model = user;
            that.render();
        });
    },

    templateHelpers: function () {
        var app = require('scripts/core').app;
        return _.extend(env,
            {
                session: app.session.toJSON(),
                API_VERSION: app['X-API-Version']
            });
    }
});
