'use strict';

var initalState = {
    'schema': {
        'liveDaily': [],
        'liveHourly': [],
        'standardDaily': [],
        'standardHourly': []
    },
    'entities': {}
};

function adsReducer (state = initalState, action) {
    var entityLookup = (adId) => objectPath.get(state, `entities.${adId}`, state.schema);

    return {
        ...state,
        entities: entitiesReducer(state.entities, action, entityLookup)
    };
}



function entitiesReducer (state, action, entityLookup) {
    switch (action.type) {
        case 'progress_ad_stats_recieved':
        case 'progress_ad_live_stats_recieved':

            const adStatsMerged = [].concat(action.payload.responses)
                .reduce((map, response) => {

                    const adId = response.adId;
                    const entity = entityLookup(adId);

                    const stats = _.pick(response, [
                        'liveDaily',
                        'liveHourly',
                        'standardDaily',
                        'standardHourly'
                    ]);

                    return {
                        ...map,
                        [adId]: {
                            ...entity,
                            ...stats
                        }
                    };
                }, {});

            return {
                ...state,
                ...adStatsMerged
            };

        default:
            return state;

    }
}



module.exports = adsReducer;