<table class="table table-bordered table-hover">
    <thead>
    <tr>
        <th>Resource</th>
        {{#each combinedPermissions}}
            {{#if campaignOnly}}
                <th title="campaign only">{{name}}*</th>
            {{else}}
                <th>{{name}}</th>
            {{/if}}

        {{/each}}
    </tr>
    </thead>

    <tbody></tbody>
</table>

<div class="row form-horizontal">
    <div class="col-md-6 form-inline form-group">
        {{#if can_access_resources}}
            <div class="select-resource" style="display: inline-block"></div>
        {{/if}}

        {{#if can_delete_role}}
            <button class="btn btn-danger" data-action="delete">
                <i class="fa fa-trash-o" title="delete role"></i>Delete this role
            </button>
        {{/if}}

    </div>
    <div class="col-md-6 role-operations">
        <button class="btn pull-right" data-action="cancel">cancel</button>
        <button class="btn btn-primary pull-right" data-action="save">Save Changes</button>
    </div>
</div>
