'use strict';

var flux = require('scripts/core/flux');

function getCookie(name) {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
}

var SessionStore = Fluxxor.createStore({

    actions: {

    },

    initialize: function (options) {
        this._sessionData = JSON.parse(localStorage.getItem('sess-sdk9sak11-8dkdj2115kj'));
        this._authToken = getCookie('authorization');
    },

    getAuthToken: function () {
        return this._authToken;
    },

    getOrganizationalId: function () {
        return this._sessionData.organization.id;
    },

    getOwnUserId: function () {
        return this._sessionData.user.id;
    },

    getPermissions: function () {
        return this._sessionData.permissions;
    },

    getRole: function () {
        return this._sessionData.role;
    },

    getUserProfile: function () {
        return this._sessionData.user;
    }

});

flux.addStore('session', new SessionStore());
