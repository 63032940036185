'use strict';
var flux = require('scripts/core/flux');
var http = require('scripts/core/http');

flux.addActions({

    campaigns: {

        refresh: function () {
            // var campaigns = flux.store('campaigns').getCampaigns();
            // this.collection.refresh().then(function () {
            //     this.dispatch(flux.constants.campaigns_refresh);
            // });
        },

        fetchCampaignsByPage: function (pageNumber) {
            // var self = this;

            // http.get('campaigns')
            //     .then(function (userProfile) {
            //         self.dispatch(
            //             flux.constants.userProfile_fetch_success,
            //             userProfile
            //         );

            //         return http.get('organizations/' + userProfile.organization);
            //     });
        }
    }
});