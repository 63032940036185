'use strict';
var flux = require('scripts/core/flux');
var http = require('scripts/core/http');
var fc = flux.constants;
var op = objectPath;


function toInt (value) {
    return parseInt(value, 10);
}

var hourMapping = {
    0: ['0',   '1',  '2',  '3',  '4',  '5'],
    1: ['6',   '7',  '8',  '9', '10', '11'],
    2: ['12', '13', '14', '15', '16', '17'],
    3: ['18', '19', '20', '21', '22', '23']
};

flux.addActions({
    reportPage: {

        scopeSelection_change: function (payload) {
            this.dispatch(fc.report_scopeSelection_change, payload);
        },

        dimensionSelection_change: function (payload) {
            this.dispatch(fc.report_dimensionSelection_change, payload);
        },

        dimensionTwoSelections_change: function (payload) {
            this.dispatch(fc.report_dimensionTwoSelections_change, payload);
        },

        filterSummary_change: function(payload) {
            this.dispatch(fc.report_filterSummary_change, payload);
        },

        // Control

        clearSingleScopeFilter: function (payload) {
            this.dispatch(fc.report_clearSingleScopeFilters, payload);
        },

        clearAllScopeFilters: function (payload) {
            this.dispatch(fc.report_clearAllScopeFilters, payload);
        },

        clearSingleDimensionFilter: function (payload) {
            this.dispatch(fc.report_clearSingleDimensionFilter, payload);
        },

        clearAllDimensionFilters: function (payload) {
            this.dispatch(fc.report_clearAllDimensionFilter, payload);
        },

        changeStatsMetricsType: function (payload) {
            this.dispatch(fc.report_statsMetricsType_change, payload);
        },

        changeStatsUnitSelection: function (payload) {
            this.dispatch(fc.report_statsUnitSelection_change, payload);
        },

        changeTabSelection: function(payload) {
            this.dispatch(fc.report_controlTabSelection_change, payload);
        },

        addSplitToPivotTable: function (payload) {
            this.dispatch(fc.reportStatsAddSplitToPivotTable, payload);
        },
        // END Control

        removeSplitToPivotTable: function (payload) {
            this.dispatch(fc.reportStatsRemoveSplitToPivotTable, payload);
        },

        expandPivotTableRow: function (payload) {
            this.dispatch(fc.reportPivotTableExpandRow, payload);
        },

        collapsePivotTableRow: function (payload) {
            this.dispatch(fc.reportPivotTableCollapseRow, payload);
        },

        sortPivotTable: function (payload) {
            this.dispatch(fc.reportPivotTableSort, payload);
        },

        focusOnRow: function (payload) {
            this.dispatch(fc.reportPivotTableFocusOnRow, payload);
        },

        fetchScopeDetails: function (payload) {
            var self = this;
            http.get('campaigns/' + payload.campaign_id + '/report-scopes')
                .then(function (responseData) {
                    self.dispatch(fc.reportScopeDetail_fetch_success, {
                        campaign_id: payload.campaign_id,
                        responseData: responseData
                    });
                });
        },

        fetchScopeStats: function (payload) {

            var scopes = Object.keys(payload.scopesConfig);

            scopes.forEach(function (scope) {

                var requestBody = {
                    'match': createMatch(payload),
                    'groups': {
                        'dimensions': [],
                        'scope': scope
                    }
                };

                StreamManager
                    .forCampaign(payload.campaign_id)
                    .getScopeStream(scope)
                    .push({
                        scope: scope,
                        campaign_id: payload.campaign_id,
                        requestBody: requestBody
                    });
            });
        },

        fetchExploreStats: function (payload) {
            var scopes = Object.keys(op.get(payload, 'scopesConfig', {}));
            var dimensionNames = Object.keys(op.get(payload, 'dimensionConfig', {}));

            var matchScopes = scopes.reduce(function (sum, scopeName) {
                sum[scopeName] = op.get(payload, ['scopesConfig', scopeName, 'selected'], []);
                return sum;
            }, {});

            var requestBody = {
                match: createMatch(payload)
            };


            _.each(dimensionNames, function (dimensionName) {
                var clone = _.cloneDeep(requestBody);

                clone.groups = {
                    dimensions: dimensionName.split('|')
                };

                StreamManager.forCampaign(payload.campaign_id)
                    .getDimensionStream(dimensionName)
                    .push({
                        campaignId: payload.campaign_id,
                        dimensionName: dimensionName,
                        requestBody: clone
                    });
            });
        },

        fetchPivotTableStats: function (payload) {

            var requestOptions = {
                match: createMatch(payload),
                groups: {
                    dimensions: payload.selectedSplit.map(item => item.value)
                },
                type: 'pivot'
            };

            StreamManager.forCampaign(payload.campaign_id)
                .getPivotTableStream()
                .push(requestOptions);
        },

        fetchDimensionStats: function (payload) {

            var requestBody = {
                match: createMatch(payload)
            };

            _.each(payload.components, function (component) {
                var requestBody_copy = _.cloneDeep(requestBody);

                requestBody_copy.groups = {
                    dimensions: component.group
                };

               StreamManager
                    .forCampaign(payload.campaign_id)
                    .getDimensionStream(component.name)
                    .push({
                        campaignId: payload.campaign_id,
                        dimensionName: component.name,
                        requestBody: requestBody_copy
                    });
            });
        },

        fetchHeatmapStats: function (payload) {

            var requestBody = {
                match: createMatch(payload)
            };

            _.each(payload.components, function( component ){
                var requestBody_copy = _.cloneDeep(requestBody);

                requestBody_copy.groups = {
                    dimensions: component.group
                };

                StreamManager
                    .forCampaign(payload.campaign_id)
                    .getDimensionStream(component.name)
                    .push({
                        campaignId: payload.campaign_id,
                        dimensionName: component.name,
                        requestBody: requestBody_copy
                    });
            });
        }
    }
});

function createMatch (payload) {
    var scopes = Object.keys(op.get(payload, 'scopesConfig', {})); // ['ad', 'creatives', 'flight']

    var matchScopes = scopes.reduce(function (sum, scopeName) {
        sum[scopeName] = op.get(payload, ['scopesConfig', scopeName, 'selected'], []);
        return sum;
    }, {});

    return {
        campaign_id: payload.campaign_id,
        dimensions: {
            gender:             op.get(payload, 'configDimensions.gender'            , []),
            geo_country:        op.get(payload, 'configDimensions.geo_country'       , []),
            device_os:          op.get(payload, 'configDimensions.device_os'         , []),
            age:          _.map(op.get(payload, 'configDimensions.age'               , []), toInt),
            geo_country_region: op.get(payload, 'configDimensions.geo_country_region', []),
            hour:               op.get(payload, 'configDimensions.hour'              , []),
            day_of_week:        op.get(payload, 'configDimensions.day_of_week'       , [])
        },
        scopes: matchScopes
    };
}



var StreamManager = (function () {
    var streams = {};

    function createStreams () {

        var scopeStreams = {
            'ad': new Bacon.Bus(),
            'flight': new Bacon.Bus(),
            'creatives': new Bacon.Bus(),
            'geotargets': new Bacon.Bus(),
            'weekparts': new Bacon.Bus(),
            'dayparts': new Bacon.Bus(),
            'devices': new Bacon.Bus(),
            'languages': new Bacon.Bus(),
            'ages': new Bacon.Bus(),
            'genders': new Bacon.Bus(),
            'creative formats': new Bacon.Bus(),
            'creative sizes': new Bacon.Bus()
        };

        _.each(scopeStreams, function (stream, scopeSname) {
            stream.debounce(300)
                .flatMapLatest(requestScopeData)
                .onValue(function (responseData) {
                    flux.dispatcher.dispatch({
                        type: fc.reportScopeStats_fetch_success,
                        payload: responseData
                    });
                });
        });

        var dimensionStreams = {
            'gender': new Bacon.Bus(),
            'geo_country': new Bacon.Bus(),
            'device_os': new Bacon.Bus(),
            'age': new Bacon.Bus(),
            'geo_country_region': new Bacon.Bus(),
            'hour|day_of_week': new Bacon.Bus(),
            'date': new Bacon.Bus()
        };

        _.each(dimensionStreams, function (stream, scopeName) {
            stream.debounce(300)
                .flatMapLatest(requestDimensionData)
                .onValue(function (responseData) {
                    flux.dispatcher.dispatch({
                        type: fc.reportDimensionStats_fetch_success,
                        payload: responseData
                    });
                });
        });

        var pivotTableStream = new Bacon.Bus();

        pivotTableStream.debounce(300)
            .flatMapLatest(pivotTableStatsAjaxRequest)
            .onValue(function (responseData) {
                flux.dispatcher.dispatch({
                    type: fc.reportPivotTableStats_fetch_success,
                    payload: responseData
                });
            });


        return {
            scopeStreams: scopeStreams,
            dimensionStreams: dimensionStreams,
            pivotTableStream: pivotTableStream
        };
    }

    return {
        forCampaign: function (campaignId) {
            if (!streams[campaignId]) {
                streams[campaignId] = createStreams();
            }

            return {
                getScopeStream: function (streamName) {
                    return streams[campaignId].scopeStreams[streamName];
                },

                getDimensionStream: function (streamName) {
                    return streams[campaignId].dimensionStreams[streamName];
                },

                getPivotTableStream: function () {
                    return streams[campaignId].pivotTableStream;
                }
            };
        }
    };
}());





function requestScopeData (payload) {
    flux.dispatcher.dispatch({
        type: fc.reportScopeStats_fetch_start,
        payload: {
            campaign_id: payload.campaign_id,
            scope: payload.scope
        }
    });

    return Bacon.fromPromise(http.post('report-stats', payload.requestBody), true);
}


function requestDimensionData (payload) {

    _([payload.dimensionName])
        .flatten()
        .each(name =>
            flux.dispatcher.dispatch({
                type: fc.reportStats_fetch_start,
                payload: {
                    campaign_id: payload.campaignId,
                    dimensionName: name
                }
            })
        );


    return Bacon.fromPromise(http.post('report-stats', payload.requestBody));
}


function pivotTableStatsAjaxRequest (requestOptions) {

    flux.dispatcher.dispatch({
        type: fc.reportPivotTableStats_fetch_start,
        payload: {
            campaign_id: requestOptions.match.campaign_id
        }
    });

    var promise = http.post('report-stats', requestOptions);

    return Bacon.retry({
        source: function () {
            return Bacon.fromPromise(promise, true);
        },
        retry: 3
    });
}
