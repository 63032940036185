'use strict';

var adsReducer = require('./ads'),
    campaignsReducer = require('./campaigns');

function progressReducer (state = {}, action) {
    return {
        ads: adsReducer(state.ads, action),
        campaigns: campaignsReducer(state.campaigns, action)
    };
}

module.exports = progressReducer;