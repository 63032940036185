<table class="table table-bordered text-center">
    <thead>
    <tr>
        <th class="col-xs-3 text-center">Group</th>
        <th class="col-xs-3 text-center">Ads</th>
        <th width="15%" class="text-right">Impression Target</th>
        <th width="10%" class="text-left">
            <small class="text-muted">Daily Cap</small>
        </th>
        <th width="15%" class="text-right">Click Target</th>
        <th width="10%" class="text-left">
            <small class="text-muted">Daily Cap</small>
        </th>
    </tr>
    </thead>
    <tbody>
    </tbody>
</table>
