'use strict';

var rowTemplate = require('./templates/row');
var CreativeView = require('./preview-creative');
var app = require('scripts/core').app;
var Statuses = require('scripts/entities/collections/creatives').AUDIT_STATUS;

var Row = Mario.ItemView.extend({
    tagName: 'tr',
    template: rowTemplate,

    initialize: function () {
        this.listenTo(this.model, 'change', this.render);
    },

    events: {
        'click [data-action=preview]' : 'preview'
    },

    preview: function () {
        var popup = globalCh.request('popup', {backdrop: true});
        popup.show(new CreativeView({model: this.model}));
    },

    templateHelpers: function () {
        return Statuses;
    },

    onRender: function () {
        this.$el.removeClass(_.values(Statuses).join(' ')).addClass(this.model.get('status'));
    }
});

module.exports = Row;
