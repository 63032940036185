'use strict';

var app = require('/scripts/core').app;
var settingsTemplate = require('./templates/settings');
var UsersView = require('./parts/users');
var RolesView = require('./parts/roles');

/**
 * options
 * model<Organization> selected organization
 * organizations<Collection<Organization>>
 */

var SettingsView = Mario.Layout.extend({
    template: settingsTemplate,

    setRegions: function () {
        this.addRegion('rolesRegion', '#org-roles-' + this.model.id);
        this.addRegion('usersRegion', '#org-users-' + this.model.id);
    },

    events: {
        'shown.bs.tab' : 'tabShown'
    },

    tabShown: function (ev) {
        var li = $(ev.target).closest('li');
        var idx = li.index();

        var ref = ['users', 'roles'];

        if (ref[idx] === 'users' ) {
            this.showUsers();
        }
        if (ref[idx] === 'roles') {
            this.showRoles();
        }
    },

    showUsers: function () {
        this.loadUsersAndRoles(function (options) {
            var usersView = new UsersView({
                collection    : options.users,
                roles         : options.roles,
                users         : options.users,
                organization  : options.organization,
                organizations : options.organizations
            });

            this.usersRegion.show(usersView);
        });
    },

    showRoles: function () {
        this.loadUsersAndRoles(function (options) {
            var rolesView = new RolesView({
                collection    : options.roles,
                roles         : options.roles,
                users         : options.users,
                organization  : options.organization,
                organizations : options.organizations
            });

            this.rolesRegion.show(rolesView);
        });
    },

    onShow: function () {
        this.setRegions();
        this.showUsers();
    },

    loadUsersAndRoles: function (callback) {
        var options = {
            organization: this.model,
            organizations: this.options.organizations
        };

        var usersReq = globalCh.request('cache', 'organization-users', null, this.model.id);
        var rolesReq = globalCh.request('cache', 'organization-roles', null, this.model.id);

        var that = this;
        $.when(usersReq, rolesReq).done(function (users, roles) {
            options = _.extend(options, {
                users : users[0],
                roles : roles[0]
            });

            callback.call(that, options);
        });
    },

    templateHelpers: function () {
        return {
            can_access_users: app.can( 'list', 'view', 'users'),
            can_access_roles: app.can( 'list', 'view', 'roles'),
            can_access_orgs: app.can( 'list', 'view', 'organizations'),
            org_id: this.model.id
        };
    }

});

module.exports = SettingsView;
