<div class="col-md-11">
    <span class="list-group-item-heading pull-left" data-id="creative-name"></span>
</div>

<div class="col-md-1">
    <div class="pull-right">
        <button class="btn btn-xs btn-default" data-action="edit">
            <i class="fa fa-pencil"></i></button>
    </div>
</div>

<div class="col-md-12">
    <p class="list-group-item-text">
        <span class="label label-default" data-id="creative-size"></span>
        <span class="label label-default" data-id="creative-format"></span>
    </p>
</div>
