'use strict';

var flux = require('scripts/core/flux');

var FilterSummaryItem = React.createClass({

    resetOptions () {
        if (this.props.onClearItem) {
            this.props.onClearItem(this.props.groupingName);
        }
    },

    render() {
        return (
            <div className="am-reports-filterBar-filters-item" onClick={this.resetOptions}>
                <span className="am-reports-filterBar-key">{this.props.groupingPresentationName}</span>
                {this.props.groups.map((group, index) =>
                    <div className="am-reports-filterBar-values" key={group.name}>
                        {index !== 0 ? ',' : null}
                        <span className="am-reports-filterBar-values-value" title={group.title}>{ group.title }</span>
                    </div>
                )}
                <i className="fa fa-close"></i>
            </div>
        );
    }
});


var FilterSummary = React.createClass({

    clearAllFilter() {
        if (this.props.onClearAllFilters) {
            this.props.onClearAllFilters();
        }
    },

    render() {
        var filterItems = this.props.filterItems;

        var shouldShowClearButton = (filterItems.length > 1);

        var someSelected = (filterItems && filterItems.length);

        if (!someSelected) {
            return null;
        }

        return (
            <div className="am-reports-filterBar-row">
                <label>{this.props.title}</label>
                <div className="am-reports-filterBar-filters">
                    {filterItems.map(filterItem =>
                    <FilterSummaryItem
                        key={_.uniqueId()}
                        groups={filterItem.groups}
                        groupingPresentationName={filterItem.groupingPresentationName}
                        groupingName={filterItem.groupingName}
                        onClearItem={this.props.onClearItem} /> )}

                    {shouldShowClearButton ?
                    <span className="am-reports-filterBar-clearAllBtn" onClick={this.clearAllFilter}>
                        <span>
                            Clear All
                        </span>
                    </span>: null}
                </div>
            </div>
        );
    }
});

module.exports = FilterSummary;

