'use strict';

var PrivateChannel = require('scripts/common/mixins/private-channel');
var LoadingLabel = require('scripts/widgets/loading-label');
var SectionDivider = require('./section-divider');
var SectionContainer = require('./section-container');

var CampaignSetupRegion = React.createClass({

    mixins: [PrivateChannel],
    getInitialState: function() {
        return {isLoaded: false};
    },
    onCampaignDataLoaded: function() {
        this.setState({isLoaded: true})
    },
    componentDidMount(){
        var that = this;
        this.privateCh.listenTo( this.props.ch , 'campaign:data:loaded', function() {
            that.onCampaignDataLoaded();
        } );
    },
    componentWillUnmount(){
        this.privateCh.stopListening(this.props.ch);
    },
    render() {
        var campaign = this.props.campaign;
        if (this.state.isLoaded){
            return (
                <div>
                    <SectionDivider campaign={campaign} ch={this.props.ch}  section={this.props.section} sectionId={this.props.sectionId}></SectionDivider>
                    <SectionContainer campaign={campaign} ch={this.props.ch} section={this.props.section} sectionId={this.props.sectionId}></SectionContainer>
                </div>
            );
        } else {
            return (
                <div>
                    <SectionDivider campaign={campaign} ch={this.props.ch}  section={this.props.section} sectionId={this.props.sectionId}></SectionDivider>
                    {_.contains(['ads', 'creatives'], this.props.section)? 
                    <LoadingLabel displayMessage='Loading ...' displayPosition='center' displayTimeout={10000} ch={this.props.ch}/>
                    :null}
                </div>
            );
        }
    }
});


module.exports = CampaignSetupRegion;
