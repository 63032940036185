'use strict';

/**
 * @param {[HeaderComponent, ContentComponent, FooterComponent]} props.children
 */

var PrivateChannel = require('scripts/common/mixins/private-channel');

var Popup = React.createClass({

    mixins: [PrivateChannel],

    componentDidMount() {
        this._originalOverflowY = $('html').css('overflowY');
        $('html').css({overflowY: 'hidden'});

        var $el = $(this.getDOMNode());

        if (_.has(this.props, 'ch')) {
            // return true if clicked inside the popup content
            $el.on('click', (ev)=>
                   !$.contains($el.find('.inner').get(0), ev.target)?
                       this.props.ch.trigger('popup:close'): true
                  );
        }

        if (globalCh != null) {
            this.privateCh.listenTo(globalCh, 'pops:close', ()=>React.unmountComponentAtNode(this.getDOMNode()));
        }
    },

    componentWillUnmount() {
         $('html').css({overflowY: this._originalOverflowY});
        var $el = $(this.getDOMNode());
        $el.off();
    },

    render() {
        if (this.props.children.length < 3) {
            return null;
        }
        return (
            <div className="ef-popup">
                <div className="inner-bg"/>
                <div className="inner-wrapper container">

                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 inner">
                            <header>
                                {this.props.children[0]}
                            </header>
                            <hr/>
                            <div className="content">
                                {this.props.children[1]}
                            </div>
                            <hr/>
                            <footer>
                                {this.props.children[2]}
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});


module.exports = Popup;
