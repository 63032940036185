[
 {"date": {"key":"date-time","value":"2015-07-14T04:00:00.000Z"}, "y":[ {"key":"unfiltered_impressions"}, {"key":"filtered_impressions","value":38738 }, {"key":"filtered_clicks", "value":189}, {"key":"ctr", "value":0.004878930249367546  }], "id":"212"}
,{"date": {"key":"date-time","value":"2015-07-15T04:00:00.000Z"}, "y":[ {"key":"unfiltered_impressions"}, {"key":"filtered_impressions","value":38235 }, {"key":"filtered_clicks", "value":282}, {"key":"ctr", "value":0.007375441349548843  }], "id":"213"}
,{"date": {"key":"date-time","value":"2015-07-16T04:00:00.000Z"}, "y":[ {"key":"unfiltered_impressions"}, {"key":"filtered_impressions","value":42396 }, {"key":"filtered_clicks", "value":323}, {"key":"ctr", "value":0.007618643268232852  }], "id":"214"}
,{"date": {"key":"date-time","value":"2015-07-17T04:00:00.000Z"}, "y":[ {"key":"unfiltered_impressions"}, {"key":"filtered_impressions","value":99064 }, {"key":"filtered_clicks", "value":313}, {"key":"ctr", "value":0.003159573608980053  }], "id":"215"}
,{"date": {"key":"date-time","value":"2015-07-18T04:00:00.000Z"}, "y":[ {"key":"unfiltered_impressions"}, {"key":"filtered_impressions","value":230843}, {"key":"filtered_clicks", "value":427}, {"key":"ctr", "value":0.0018497420324636224 }], "id":"216"}
,{"date": {"key":"date-time","value":"2015-07-19T04:00:00.000Z"}, "y":[ {"key":"unfiltered_impressions"}, {"key":"filtered_impressions","value":230691}, {"key":"filtered_clicks", "value":477}, {"key":"ctr", "value":0.002067700950622261  }], "id":"217"}
,{"date": {"key":"date-time","value":"2015-07-20T04:00:00.000Z"}, "y":[ {"key":"unfiltered_impressions"}, {"key":"filtered_impressions","value":229962}, {"key":"filtered_clicks", "value":833}, {"key":"ctr", "value":0.0036223376036040736 }], "id":"218"}
,{"date": {"key":"date-time","value":"2015-07-21T04:00:00.000Z"}, "y":[ {"key":"unfiltered_impressions"}, {"key":"filtered_impressions","value":220013}, {"key":"filtered_clicks", "value":646}, {"key":"ctr", "value":0.002936190134219342  }], "id":"219"}
,{"date": {"key":"date-time","value":"2015-07-22T04:00:00.000Z"}, "y":[ {"key":"unfiltered_impressions"}, {"key":"filtered_impressions","value":191604}, {"key":"filtered_clicks", "value":495}, {"key":"ctr", "value":0.002583453372580948  }], "id":"220"}
,{"date": {"key":"date-time","value":"2015-07-23T04:00:00.000Z"}, "y":[ {"key":"unfiltered_impressions"}, {"key":"filtered_impressions","value":150338}, {"key":"filtered_clicks", "value":269}, {"key":"ctr", "value":0.0017893014407535021 }], "id":"221"}
,{"date": {"key":"date-time","value":"2015-07-24T04:00:00.000Z"}, "y":[ {"key":"unfiltered_impressions"}, {"key":"filtered_impressions","value":150330}, {"key":"filtered_clicks", "value":319}, {"key":"ctr", "value":0.002121998270471629  }], "id":"222"}
,{"date": {"key":"date-time","value":"2015-07-25T04:00:00.000Z"}, "y":[ {"key":"unfiltered_impressions"}, {"key":"filtered_impressions","value":150312}, {"key":"filtered_clicks", "value":334}, {"key":"ctr", "value":0.002222044813454681  }], "id":"223"}
,{"date": {"key":"date-time","value":"2015-07-26T04:00:00.000Z"}, "y":[ {"key":"unfiltered_impressions"}, {"key":"filtered_impressions","value":100429}, {"key":"filtered_clicks", "value":227}, {"key":"ctr", "value":0.0022603032988479425 }], "id":"224"}

]
