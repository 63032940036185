'use strict';
var ListLayout = require('scripts/widgets/list-layout');
var CreativeOverview = require('scripts/components/campaigns/parts/creatives/overview');
var AdOverview = require('scripts/components/campaigns/parts/ads/overview');

var PrivateChannel = require('scripts/common/mixins/private-channel');
var SectionContainer = React.createClass({
    mixins: [PrivateChannel],
    getInitialState() {
        return {
            ads      : {},
            creatives: {}
        };
    },

    componentDidMount() {
        var ch = this.props.ch;
        this.privateCh.listenTo(ch, 'campaign:ads:sort campaign:ads:search', (settings)=> this.setState({ads: settings}));
        this.privateCh.listenTo(ch, 'campaign:creatives:sort campaign:creatives:search', (settings)=> this.setState({creatives: settings}));
        this.tryFocusItem();
    },

    componentDidUpdate() {
        this.tryFocusItem();
    },

    tryFocusItem() {
        var ch = this.props.ch;
        var {section, sectionId} = this.props;
        if ((section === 'ads' || section === 'creatives') && sectionId != null) {
            ch.command(`campaign:${section}:${sectionId}:focus`);
        }
    },

    render() {
        switch (this.props.section) {
            case 'ads':
                return (
                    <ListLayout collection={this.props.campaign.ads} canEdit={this.props.campaign.can('edit')} sortBy={this.state.ads.sort} searchBy={this.state.ads.search}
                        itemType="ads" ItemClass={AdOverview} ch={this.props.ch}/>
                );
            case 'creatives':
                var HeaderClass = require('scripts/components/campaigns/parts/creatives/list-header');
                var creatives = this.props.campaign.creatives;

                return (
                    <ListLayout collection={creatives} ch={this.props.ch} HeaderClass={HeaderClass} ItemClass={CreativeOverview} searchBy={this.state.creatives.search} itemType="creatives" />
                )
        }
        return null;
    }
});


module.exports = SectionContainer;
