'use strict';
// load handlebar helpers
var app = require('scripts/core').app;
var env = require('scripts/env');
var apiBase = env.ADSUSHI_API_BASE;
// ajax setups

var ADSUSHI_API_BASES = require('scripts/env').ADSUSHI_API_BASES;
var bases = ADSUSHI_API_BASES.slice();

function getNextBase () {
    var item = bases.shift();
    bases.push(item);
    return item;
}

$.support.cors = true;

$.ajaxPrefilter(function (option, originalOption, jqXHR) {

    if (option.headers == null) {
        option.headers = {
            'content-type': 'application/json',
            'accept' : 'application/json'
        };
    }
    option.headers['X-API-Client'] = 'EngageFront-' + env.VERSION;

    if (option.type.match(/put/i)) {
        option.type = 'PATCH';
    }

    if (option.type === 'PATCH' && option.ifMatch) {
        option.headers['If-Match'] = option.ifMatch;
    }

    if (!/^http/.test(option.url)) {
        option.url = getNextBase() + option.url;
    }
    // always remove extra tail slash
    option.url = option.url.replace(/\/$/, '');
    // always use cross domain
    option.crossDomain = true;
    option.async = true;

});

var ajaxCounter = 0;

var ajaxSend = function (ev, xhr, settings) {
    ajaxCounter += 1;
    if (ajaxCounter > 0) {
        $('#ajax-loader').show();
    }
};

var ajaxComplete = function (ev, xhr, settings) {
    respondToStatus.call(xhr);
    app['X-API-Version'] = xhr.getResponseHeader('X-API-Version');
    ajaxCounter -= 1;
    if (ajaxCounter <= 0) {
        $('#ajax-loader').hide();
    }
};

$(document).bind('ajaxSend', ajaxSend).bind('ajaxComplete', ajaxComplete);

if (env.BUG_REPORTING_ENABLED) {
    $(document).ajaxError(function (event, jqxhr, settings, thrownError) {
        Bugsnag.notify('AjaxError', thrownError);
    });
}
function handle401() {
    localStorage.setItem('_flash', 'Unauthorized, please sign in the continue.');
    window.location.href = '/signin';
}

function handleErrors(resJSON) {
    if (_.isArray(resJSON)) {
        toastr.error(_.pluck(resJSON, 'message').join('\n'));
    } else {
        toastr.error(JSON.stringify(resJSON));
    }
}

function handle404() {
    toastr.error('Requested resource is not found.');
}

function handle412() {
    toastr.error('Resource was modified by another user. To prevent overwriting, your changes have not been saved. Please refresh the page and try again.');
}

function handle0(xhr) {

    if (xhr.statusText == 'abort') return;

    globalCh.trigger('error:server', arguments);


    // option request didn't pass through
    // probably because the server is down
    if ($('.loading:visible').length > 0) {
        var message = '<div class="unavailable">Service is temporarily unavailable. Please try again later.</div>';
        $('.loading:visible').parent().append(message);
        $('.loading:visible').remove();
    } else if (!$('div.unavailable').is(':visible')) {
        toastr.error('Service is temporarily unavailable. Please try again later.');
    }
}

function respondToStatus() {
    /* jshint maxcomplexity: 6 */
    var resJSON = this.responseJSON;
    switch (this.status) {
        case 401:
            handle401();
            break;
        case 400:
        case 500:
            handleErrors(resJSON);
            break;
        case 404:
            handle404();
            break;
        case 412:
            handle412();
        case 0:
            handle0(this);
    }
}
