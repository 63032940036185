{{#each menu_groups }}
  {{#if this.name}}
    <li class="head">
      {{this.name}}
    </li>
  {{/if}}


  {{#each items}}
    <li class="item">
      <a href="{{this.url}}">{{this.name}}</a>
    </li>
  {{/each}}
{{/each}}
