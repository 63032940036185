'use strict';

var env = require('scripts/env');
var AccountingPage = require('./accounting-page');


module.exports = Mario.ItemView.extend({

    className: 'accounting-page-container',

    template: _.template('<div id="accounting-page"></div>'),

    onShow: function () {
        var accountingPage = React.createFactory(AccountingPage);

        React.render(accountingPage({}), document.querySelector('#accounting-page'));
    },

    pageDidReload: function () {

    }
});
