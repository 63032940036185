'use strict';

/*
    prefix 'M_' is the moment wrapper
 */

var D3Chart = function(){ };

D3Chart.prototype.init = function(el, data, meta) {
    var margin = {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        },
        padding = {
            top: 40,
            right: 75,
            bottom: 100,
            left: 75
        };

    this._conf = {};
    this._conf.margin = margin;
    this._conf.padding = padding;
    this._conf.w_svg = 3000;
    this._conf.h_svg = 1000;
    this._conf.w_wrap = this._conf.w_svg - margin.left - margin.right;

    this._conf.h_wrap = this._conf.h_svg - margin.top - margin.bottom;
    this._conf.width =  this._conf.w_wrap - padding.left - padding.right;
    this._conf.height = this._conf.h_wrap - padding.top - padding.bottom;

    this._conf.dataIndex = {};
    this._conf.dataIndex = meta.dataIndex;

    this._cache = {};
    if (data.length) this.updateCache(data, meta);

    this._selected = {};
};

D3Chart.prototype.updateCache = function(data) {
    var that = this;
    var _rawData = data;

    // Ordinate (data axis)
        // Basic values
        var dataUnfilteredImpression = data.map(function(d){ return d.y[that._conf.dataIndex.unfilteredImpressions].value; });
        var dataFilteredImpressions = data.map(function(d){ return d.y[that._conf.dataIndex.filteredImpressions].value; });
        var dataFilteredClinks = data.map(function(d){ return d.y[that._conf.dataIndex.filteredClicks].value; });

        var dataFilteredSpend = data.map(function(d){ return d.y[that._conf.dataIndex.filteredSpend].value; });


        // Calculate presentation data values
            var maxFilteredImpression = d3.max(dataFilteredImpressions, function(d){return d;});
            var minFilteredImpression = d3.min(dataFilteredImpressions, function(d){return d;});
            var maxFilteredClick = d3.max(dataFilteredClinks, function(d){return d;});
            var maxFilteredSpend = d3.max(dataFilteredSpend, function(d){return d;});

            var offSet_head = 0.2 * maxFilteredImpression;
            var offSet_floor = 0;

            if (maxFilteredImpression !== 0 && minFilteredImpression !== 0 ) {
                // with head room and floor space
                that._cache.headRoom = offSet_head;
                that._cache.floorOffset = offSet_floor;

                that._cache.maxDataY = maxFilteredImpression;
                that._cache.minDataY = minFilteredImpression;
                that._cache.data  =  scaleFilteredClicksDataForDisplay(data);
                that._cache.data  =  scaleFilteredSpendDataForDisplay(that._cache.data);

            } else if (maxFilteredImpression !== 0 && minFilteredImpression ===0 ) {
                // with head room and no floor space
                that._cache.headRoom = offSet_head;
                that._cache.floorOffset = 0;

                that._cache.maxDataY = maxFilteredImpression;
                that._cache.minDataY = 0;
                that._cache.data  =  scaleFilteredClicksDataForDisplay(data);
                that._cache.data  =  scaleFilteredSpendDataForDisplay(that._cache.data);

            } else if (maxFilteredImpression === 0 && minFilteredImpression ===0 ) {
                // no head room and no floor space
                that._cache.headRoom = 0;
                that._cache.floorOffset = 0;

                that._cache.maxDataY = 100;
                that._cache.minDataY = 0;

                // data is a flatline
                that._cache.data  = flateLine(data);
            }

            function scaleFilteredClicksDataForDisplay(_data){
                if (_data.length){
                    var scalingFactor = 0.25;  // for example if 0.5, maxFilteredClick is half as tall as maxFilteredImpression
                                              // or if 1, maxFilteredClick is as tall as maxFilteredImpression
                    var FilteredClick_displayScaling = (maxFilteredClick !==0) ? (maxFilteredImpression / maxFilteredClick) * scalingFactor : 0;
                    var clone_data = _.clone(_data, true);
                    var ret = _.map(clone_data, function(item){
                        item.y[that._conf.dataIndex.filteredClicks].value = item.y[that._conf.dataIndex.filteredClicks].value * FilteredClick_displayScaling;
                        return item;
                    });
                    return ret;
                }
            }

            function scaleFilteredSpendDataForDisplay(_data){
                if (_data.length){
                    var scalingFactor = 0.25;  // for example if 0.5, maxFilteredClick is half as tall as maxFilteredImpression
                                              // or if 1, maxFilteredClick is as tall as maxFilteredImpression
                    var FilteredSpend_displayScaling = (maxFilteredSpend !==0) ? (maxFilteredImpression / maxFilteredSpend) * scalingFactor : 0;
                    var clone_data = _.clone(_data, true);
                    var ret = _.map(clone_data, function(item){
                        item.y[that._conf.dataIndex.filteredSpend].value = item.y[that._conf.dataIndex.filteredSpend].value * FilteredSpend_displayScaling;
                        return item;
                    });
                    return ret;
                }
            }

            function flateLine(_data){
                if (_data.length){
                    var clone_data = _.clone(_data, true);
                    var ret = _.map(clone_data, function(item){
                        item.y[that._conf.dataIndex.filteredImpressions].value = that._cache.maxDataY*0.05;
                        item.y[that._conf.dataIndex.filteredClicks].value = that._cache.maxDataY*0.02;
                        item.y[that._conf.dataIndex.ctr].value = 0;
                        return item;
                    });
                    return ret;
                }
            }

        var ordinateRange = this._cache.maxDataY - this._cache.minDataY;
        var deltaHeight_per_dataPoints =parseInt( this._conf.height/ordinateRange);
        this._cache.deltaHeight_per_dataPoints = deltaHeight_per_dataPoints;

    // Abscissa (date axis) is calculated based on unfiltered impressions
        var dataX = data.map(function(d){ return d.date.value; });
        var maxDataX = d3.max(dataX, function(d){ return d; });
        var minDataX = d3.min(dataX, function(d){ return d; });

        // prefix 'm_' is the moment wrapper
        var M_maxDataX = moment(d3.max(dataX, function(d){ return d; }));
        var M_minDataX = moment(d3.min(dataX, function(d){ return d; }));
        var howManyDays_btw_startEndDate = M_maxDataX.diff(M_minDataX, 'days') + 1; // include end point
        this._cache.dataResolutionOnXAxis = howManyDays_btw_startEndDate;

        // narrower plot area if less data point
        if ( this._cache.dataResolutionOnXAxis > 10 ){
            this._cache.plotAreaWidth = this._conf.width * 1;
        } else if ( 9 < this._cache.dataResolutionOnXAxis && this._cache.dataResolutionOnXAxis <= 10 ){
            this._cache.plotAreaWidth = this._conf.width * 0.9;
        } else if ( 7 < this._cache.dataResolutionOnXAxis && this._cache.dataResolutionOnXAxis <= 9 ){
            this._cache.plotAreaWidth = this._conf.width * 0.7;
        } else if ( 5 < this._cache.dataResolutionOnXAxis && this._cache.dataResolutionOnXAxis <= 7 ){
            this._cache.plotAreaWidth = this._conf.width * 0.5;
        } else if ( 3 < this._cache.dataResolutionOnXAxis && this._cache.dataResolutionOnXAxis <= 5 ){
            this._cache.plotAreaWidth = this._conf.width * 0.3;
        } else if ( 1 < this._cache.dataResolutionOnXAxis && this._cache.dataResolutionOnXAxis <= 3 ){
            this._cache.plotAreaWidth = this._conf.width * 0.2;
        } else if ( 1 === this._cache.dataResolutionOnXAxis && this._cache.dataResolutionOnXAxis <= 3 ){
            this._cache.plotAreaWidth = this._conf.width * 0.2;
        } else {
            this._cache.plotAreaWidth = this._conf.width * 1;
        }

        this._cache.plottingAreaLeftOffset = (this._conf.width - this._cache.plotAreaWidth)/2;

        var deltaWidth_per_dataPoint =parseInt( this._cache.plotAreaWidth/howManyDays_btw_startEndDate);

        this._cache.deltaWidth_per_dataPoint = deltaWidth_per_dataPoint;

        this._cache.data_dates = dataX;
        this._cache.dateEnd = maxDataX;
        this._cache.dateStart = minDataX;

    // For ctr
        var dataCtr = data.map(function(d){ return d.y[that._conf.dataIndex.ctr].value; });
        var data_ctr_max= d3.max(dataCtr, function(d){ return d; });
        var data_ctr_min = d3.min(dataCtr, function(d){ return d; });
        this._cache.data_ctr_max = data_ctr_max;
        this._cache.data_ctr_min = data_ctr_min;

    // For eCPM
        var data_eCPM = data.map(function(d){ return d.y[that._conf.dataIndex.eCPM].value; });
        var data_eCPM_max= d3.max(data_eCPM, function(d){ return d; });
        var data_eCPM_min = d3.min(data_eCPM, function(d){ return d; });
        this._cache.data_eCPM_max = data_eCPM_max;
        this._cache.data_eCPM_min = data_eCPM_min;

    // For eCPC
        var data_eCPC = data.map(function(d){ return d.y[that._conf.dataIndex.eCPC].value; });
        var data_eCPC_max= d3.max(data_eCPC, function(d){ return d; });
        var data_eCPC_min = d3.min(data_eCPC, function(d){ return d; });
        this._cache.data_eCPC_max = data_eCPC_max;
        this._cache.data_eCPC_min = data_eCPC_min;

    // need to merge raw data back for display in tooltips
    _.each(this._cache.data, function(d, i) {
        _.each(d.y, function(dd, ii){
            const rawValue = _rawData[i].y[ii].value;
            dd.rawValue = rawValue;
        });
    });
};




///////////////////////
// public method     //
///////////////////////
D3Chart.prototype.create = function(el, state) {
    var data = state.data;
    var meta = state.meta;

    this.init(el, data, meta);


    // =================
    // == Create Area ==
    // =================
    this._selected.component = el;

    var viewBoxMaxX = this._conf.w_svg;
    var viewBoxMaxY = this._conf.h_svg;

    var svgContainer = d3.select(el)
        .select('svg')
        .attr('class', 'd3')
        .attr('width', '100%').attr('height', '100%')
        .attr('viewBox', '0 0 ' + viewBoxMaxX + ' ' + viewBoxMaxY);
    this._selected.svg = svgContainer;

    var wrap = svgContainer
        .append('g').classed('wrap', true)
        .attr('transform', 'translate(' + this._conf.margin.left + ',' + this._conf.margin.top + ')');

    wrap.append('rect')
        .attr('class', 'wrap-background')
        .attr('width', this._conf.w_wrap)
        .attr('height', this._conf.h_wrap)
        .attr('fill', 'transparent');

    var plotArea = wrap.append('g').classed('plot-area', true)
        .attr('transform', 'translate(' + this._conf.padding.left + ',' + this._conf.padding.top + ')');
    this._selected.plotArea = plotArea;

    plotArea.append('rect')
        .attr('class', 'plot-area-background')
        .attr('width', this._conf.width)
        .attr('height', this._conf.height)
        .attr('fill', 'transparent');

    var dataPoints_impressions = plotArea.append('g').classed('data-points-impressions', true);
    var dataPoints_clicks      = plotArea.append('g').classed('data-points-clicks', true);
    var dataPoints_spend       = plotArea.append('g').classed('data-points-spend', true);
    var dataPoints_ctr         = plotArea.append('g').classed('data-points-ctr', true);

    var dataLine_ctr           = plotArea.append('g').classed('data-line-ctr', true).append('path');
    var dataLine_eCPM          = plotArea.append('g').classed('data-line-eCPM', true).append('path');
    var dataLine_eCPC          = plotArea.append('g').classed('data-line-eCPC', true).append('path');

    this._prepareXAxisContainer(el);
    this._prepareYAxisContainer(el);

    var dataPoints_mouseBands = plotArea.append('g').classed('data-points-mouseBands', true);
    this._selected.mouseBands = dataPoints_mouseBands;

    // mouse line
    const lineLength = this._conf.height;
    const mouseLineGroup = plotArea.append('g').classed('mouse-line', true);
    mouseLineGroup
        .append('line')
        .attr('x1', '0')
        .attr('y1', '0')
        .attr('x2', '0')
        .attr('y2', lineLength)
        .attr('stroke-dasharray', '5, 5')
        .attr('stroke-width', '2')
        .attr('fill', 'none');
    this._selected.mouseLineGroup = mouseLineGroup;

    this._events_mount(el);
};

D3Chart.prototype.update = function(el, state) {
    var that = this;
    var data = state.data;
    var meta = state.meta;

    this._isLoading = state.isLoading;

    this._updateClassForStatsMetricsType(el, state);

    if (data.length) {
        this.updateCache(data);

        var plotArea = d3.select(el).select('.plot-area')
            .attr('transform', 'translate(' + (parseInt(this._conf.padding.left) + this._cache.plottingAreaLeftOffset) + ',' + this._conf.padding.top + ')')
            .attr('width', this._cache.plotAreaWidth)
            .attr('height', this._conf.height);

        plotArea.select('.plot-area-background').attr('width', this._cache.plotAreaWidth);

        this._updateXAxis(el);
        // this._updateYAxis(el);  // don't show y axis

        // filtered impressions
        var options_filteredImpressions = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.filteredImpressions,
            el: d3.select(el).select('.data-points-impressions'),
            scales: this._scalesFilteredImpressions(data),
            colorFill: '#D4E157',
            colorBorder: '#D4E157',
            fillOpacity: (meta.statsMetricsType === 'impressions') ? '1' : '.5',
            strokeOpacity: (meta.statsMetricsType === 'impressions') ? '1' : '.5',
            slimingFactor:1
        };
        this._plotBar(options_filteredImpressions);

        // filtered clicks
        var options_filteredClicks = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.filteredClicks,
            el: d3.select(el).select('.data-points-clicks'),
            scales: this._scalesFilteredClicks(data),
            colorFill: '#4DB6AC',
            colorBorder: '#4DB6AC',
            fillOpacity: (meta.statsMetricsType === 'clicks') ? '1' : '.7',
            strokeOpacity: (meta.statsMetricsType === 'clicks') ? '1' : '.7',
            slimingFactor:2
        };
        this._plotBar(options_filteredClicks);

        // filtered spend
        var options_filteredSpend = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.filteredSpend,
            el: d3.select(el).select('.data-points-spend'),
            scales: this._scalesFilteredSpend(data),
            colorFill: '#3949AB',
            colorBorder: '#3949AB',
            fillOpacity: (meta.statsMetricsType === 'spend') ? '1' : '.5',
            strokeOpacity: (meta.statsMetricsType === 'spend') ? '1' : '.5',
            slimingFactor: 3
        };
        this._plotBar(options_filteredSpend);

        // ctr line (filtered data)
        var options_ctr = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.ctr,
            el: d3.select(el).select('.data-line-ctr > path'),
            scales: this._scalesCtr(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#9C27B0',
            strokeOpacity: (meta.statsMetricsType === 'ctr') ? '1' : '.3',
            strokeWidth: '8',
            strokeDashArray: []
        };
        this._plotLine(options_ctr);

        // eCPM line (filtered data)
        var options_eCPM = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.eCPM,
            el: d3.select(el).select('.data-line-eCPM > path'),
            scales: this._scales_eCPM(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#9575CD',
            strokeOpacity: (meta.statsMetricsType === 'ecpm') ? '1' : '.5',
            strokeWidth: '8',
            strokeDashArray: [10,10]
        };
        this._plotLine(options_eCPM);

        // eCPC line (filtered data)
        var options_eCPC = {
            data: that._cache.data,
            dataIndex: this._conf.dataIndex.eCPC,
            el: d3.select(el).select('.data-line-eCPC > path'),
            scales: this._scales_eCPC(data),
            fillColor: 'none',
            fillOpacity: '1',
            strokeColor: '#F06292',
            strokeOpacity: (meta.statsMetricsType === 'ecpc') ? '1' : '.5',
            strokeWidth: '8',
            strokeDashArray: []
        };
        this._plotLine(options_eCPC);

        // // ctr point (filtered data)
        // var options_ctr_scatter = {
        //     data: that._cache.data,
        //     dataIndex: this._conf.dataIndex.ctr,
        //     color: 'blue',
        //     el: d3.select(el).select('.data-points-ctr'),
        //     scales: this._scalesCtr(data)
        // };
        // this._plotScatterPoints(options_ctr_scatter);

        // Mouse band
        var options_mouseBand = {
            data: that._cache.data,
            el: d3.select(el).select('.data-points-mouseBands'),
            scales: this._scale_abscissa(),
            meta: state.meta,
        };
        this._plotMouseBand(options_mouseBand);
    }
};

D3Chart.prototype.distroy = function(el) {
    this._events_unMount(el);
};







/////////////////////////////////
// setup and tear down evetns  //
/////////////////////////////////
D3Chart.prototype._events_mount = function(el) {
    const that = this,  selected = this._selected;

    // selected.plotArea.on('mousemove', function(d){
    //     const p = d3.mouse(this);
    //     const x = p[0];
    //     // console.log('events mount mouseover, x: ', x) ;
    //     // selected.mouseLineGroup.attr('transform', 'translate('+x+','+0+')');
    // });

    // Area events
    selected.plotArea.on('mouseenter', function(e){ that._plot_mouseLine('mouseenter'); });
    selected.plotArea.on('mouseleave', function(e){ that._plot_mouseLine('mouseleave'); });

    // Window events
    window.addEventListener('scroll', this._onDocumentScroll.bind(this));
    window.addEventListener('resize', this._onWindowResize.bind(this));
};

D3Chart.prototype._events_unMount = function(el) {
    selected.plotArea.on('mouseenter', null);
    selected.plotArea.on('mouseleave', null);

    window.removeEventListener('scroll', this._onDocumentScroll.bind(this));
    window.removeEventListener('resize', this._onWindowResize.bind(this));

    // unbind event on mounse bands
    this._selected.mouseBands.selectAll('.data-point').on('mouseenter', null);
    this._selected.mouseBands.selectAll('.data-point').on('mouseleave', null);
};






//////////////////////////
// misc. helper        //
//////////////////////////
D3Chart.prototype._onDocumentScroll = function(el) {
    this._updateToolTipPositionInClient();
};

D3Chart.prototype._onWindowResize = function(el) {
    this._updateToolTipPositionInClient();
};

D3Chart.prototype._updateClassForStatsMetricsType = function(el, state) {
    var that = this;
    var data = state.data;
    var meta = state.meta;

    this._cache.statsMetricsType = meta.statsMetricsType;

    d3.select(el).select('.data-points-impressions').classed( 'isActive', ()=>{
        return (meta.statsMetricsType === 'impressions') ? true : false;
    });

    d3.select(el).select('.data-points-clicks').classed( 'isActive', ()=>{
        return (meta.statsMetricsType === 'clicks') ? true : false;
    });

    d3.select(el).select('.data-points-spend').classed( 'isActive', ()=>{
        return (meta.statsMetricsType === 'spend') ? true : false;
    });

    d3.select(el).select('.data-line-ctr').classed( 'isActive', ()=>{
        return (meta.statsMetricsType === 'ctr') ? true : false;
    });

    d3.select(el).select('.data-line-eCPM').classed( 'isActive', ()=>{
        return (meta.statsMetricsType === 'ecpm') ? true : false;
    });

    d3.select(el).select('.data-line-eCPC').classed( 'isActive', ()=>{
        return (meta.statsMetricsType === 'ecpc') ? true : false;
    });
};

D3Chart.prototype._plot_mouseLine = function(mode) {
    const that = this,  selected = this._selected;
    const strokeColor = 'red';

    const mouseLine= selected.mouseLineGroup.select('line');

    if (mode === 'mouseenter' ) {
        mouseLine.attr('stroke', strokeColor);
    }

    if (mode === 'mouseleave' ) {
        mouseLine.attr('stroke', 'none');
    }
};





/////////////////////////
//  plotting data      //
/////////////////////////
D3Chart.prototype._plotBar = function(opts){
    var that = this;

    var data = opts.data;
    var dataIndex = opts.dataIndex;
    var colorBorder = opts.colorBorder;
    var colorFill = opts.colorFill;
    var fillOpacity = opts.fillOpacity;
    var strokeOpacity = opts.strokeOpacity;
    var el = opts.el;
    var scales = opts.scales;
    var slimingFactor = opts.slimingFactor;

    const  slimingCoefficient = {
        '1' : 1,
        '2' : 3/5,
        '3' : 1/5,
    };

    // var halfBandWidth = parseInt(this._cache.deltaWidth_per_dataPoint/2);
    // var halfBandWidth = this._cache.deltaWidth_per_dataPoint/2;
    var BandWidth = this._cache.deltaWidth_per_dataPoint;
    var spaceBtwBar = BandWidth * 0.15;
    var barWidth = (BandWidth - spaceBtwBar)*slimingCoefficient[slimingFactor];

    var points = el;

    var point = points.selectAll('g').classed('data-point', true)
        .data(data, function(d) {return d.id;});

    point.enter()
        .append('g').classed('data-point', true);

    var dataContainer = point
        .attr('transform', function(d){
            var dataDate = d.date.value;
            var dataX = scales.x(dataDate);
            var x = dataX;
            return 'translate('+x+','+0+')';
        });

    const hasNoRect = dataContainer.select('rect').empty();

    if (hasNoRect) {
        dataContainer.append('rect')
            // .attr( 'fill', colorFill)             // done in css
            // .attr( 'fill-opacity', fillOpacity)   // done in css
            // .attr( 'stroke', colorBorder)
            // .attr( 'stroke-opacity', strokeOpacity)
            // .attr( 'stroke-width', '4')
            .attr( 'width', barWidth )
            .attr( 'x', -1*barWidth/2 )
            .attr( 'y', '0')
            .attr( 'transform', function(d,i){
                const dataY = 0;
                const y = that._conf.height - dataY;
                return 'translate('+0+','+y+')';
            })
            .attr( 'height', 0)
            .transition().duration(750)
            .attr( 'transform', function(d,i){
                const dataY = scales.y(d.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate('+0+','+y+')';
            })
            .attr( 'height', function(d){
                var o = scales.y(d.y[dataIndex].value);
                return o;
            });
    } else {
        dataContainer.selectAll('rect')
            .transition().duration(750)
            .attr( 'transform', function(d,i){
                const dataY = scales.y(d.y[dataIndex].value);
                const y = that._conf.height - dataY;
                return 'translate('+0+','+y+')';
            })
            .attr( 'height', function(d){
                var o = scales.y(d.y[dataIndex].value);
                return o;
            });
    }

    // dataContainer.append('circle')
    //     .attr( 'r', 20 )
    //     .attr( 'x', '0' )
    //     .attr( 'y', '0')
    //     .attr( 'transform', function(d,i){
    //         const dataY = scales.y(d.y[dataIndex].value);
    //         const y = that._conf.height - dataY;
    //         return 'translate('+0+','+y+')';
    //     })
    //     .attr( 'stroke', 'red')
    //     .attr( 'stroke-width', '2')
    //     .attr( 'fill', 'transparent')
    //     .attr( 'class', 'origin' );

    point.exit().remove();
};

D3Chart.prototype._plotLine = function(opts){
    var that = this;
    var data = opts.data;
    var dataIndex = opts.dataIndex;
    var el = opts.el;
    var fillColor = opts.fillColor;
    var fillOpacity = opts.fillOpacity;
    var strokeColor = opts.strokeColor;
    var strokeOpacity = opts.strokeOpacity;
    var strokeWidth = opts.strokeWidth;
    var strokeDashArray = opts.strokeDashArray;

    var scales = opts.scales;

    // draw line
    var valueline = d3.svg.line()
        .x(function(d) {
            var dataDate = d.date.value;
            var dataX = parseInt(scales.x(dataDate));
            return dataX;
        })
        .y(function(d) {
            var dataY = that._conf.height - scales.y(d.y[dataIndex].value);
            return dataY;
        })
        .interpolate('monotone');
        // .interpolate("cardinal");
        // .interpolate('basis');

    var path = el
        // .attr('fill', fillColor)
        // .attr('fill-opacity', fillOpacity)
        // .attr('stroke', strokeColor)
        // .attr('stroke-opacity', strokeOpacity)
        // .attr('stroke-width', strokeWidth)
        // .attr('stroke-dasharray', strokeDashArray)
        .transition()
        .duration(700)
        .attr('d', valueline(data));
};

D3Chart.prototype._plotScatterPoints = function(opts){
    var that = this;

    var data = opts.data;
    var dataIndex = opts.dataIndex;
    var color = opts.color;
    var el = opts.el;
    var scales = opts.scales;

    var points = el;

    var point = points.selectAll('g').classed('data-point', true)
        .data(data, function(d) {return d.id;});

    point.enter()
        .append('g').classed('data-point', true);

    var dataContainer = point.attr('transform', function(d){
            var dataDate = d.date.value;
            var dataX = parseInt(scales.x(dataDate));
            var dataY = parseInt(scales.y(d.y[dataIndex].value));
            var x = dataX;
            var y = that._conf.height - dataY;
            return 'translate('+x+','+y+')';
        });

    dataContainer.append('circle')
        .attr('class', 'origin')
        .attr('r', 20)
        .attr('stroke', color)
        .attr('stroke-width', '2')
        .attr('fill', 'none');

    point.exit().remove();
};

D3Chart.prototype._plotMouseBand = function(opts){
    const that = this,  selected = this._selected;

    var data = opts.data;
    var meta = opts.meta;

    var points = opts.el;
    var scales = opts.scales;

    var bandWidth = this._cache.deltaWidth_per_dataPoint;

    var point = points.selectAll('g').classed('data-point', true)
        .data(data, function(d) {return d.id;});

    point.enter().append('g').classed('data-point', true);
    const point_exit = point.exit();
    point_exit.remove();

    var dataContainer = point
        .attr('transform', function(d){
            var dataDate = d.date.value;
            var dataX = scales(dataDate);
            var x = dataX;
            return 'translate('+x+','+0+')';
        });


    const hasNoRect = dataContainer.select('rect').empty();
    if (hasNoRect) {
        dataContainer.append('rect')
            .attr( 'x', -1*bandWidth/2 )
            .attr( 'y', '0')
            .attr( 'width', bandWidth )
            .attr( 'height', that._conf.height)
            // Debug
                // .attr( 'fill', '#aa0044')
                // .attr( 'fill-opacity', 0.2)
                // .attr( 'stroke', 'black')
                // .attr( 'stroke-opacity', 0.5)
                // .attr( 'stroke-width', '2')
            .attr( 'fill', 'transparent')
            .attr( 'fill-opacity', 1)
            .attr( 'stroke', 'none')
            .attr( 'stroke-opacity', 1);
    }


    // Start :: Events
        // mount event
        dataContainer.on('mouseenter', cb_onMouseenter);
        dataContainer.on('mouseleave', cb_onMouseleave);
        // unmount event on exit
        point_exit.on('mouseenter', null);
        point_exit.on('mouseleave', null);

        this._cache.mouseLineX;

        function cb_onMouseenter(d, i) {
            const dataDate = d.date.value;
            const x = scales(dataDate);
            that._cache.mouseLineX = x;

            selected.mouseLineGroup.attr('transform', 'translate('+x+','+0+')');
            that._updateToolTips.call(that, d);
            that._updateToolTipPositionInClient();

            const r = 10;//(bandWidth/3)/2;
            let dataIndex;
            let scalesMetric;

            // Filtered impression
            dataIndex = 1;
            scalesMetric = that._scalesFilteredImpressions();

            d3.select(this).append('circle')
                .attr( 'class', 'mouseScatter-filteredImpressions' )
                .attr( 'r', r ).attr( 'x', '0' ).attr( 'y', '0')
                .attr( 'transform', function( dd, ii ){
                    const dataY = scalesMetric.y( dd.y[dataIndex].value );
                    const y = that._conf.height - dataY;
                    return 'translate('+0+','+y+')';
                })
                // .attr( 'stroke', 'red')
                // .attr( 'stroke-width', '4')
                // .attr( 'fill', 'transparent')
                .classed( 'isActive', ()=>{
                    return (meta.statsMetricsType === 'impressions') ? true : false;
                });


            // Filtered Clicks
            dataIndex = 2;
            scalesMetric = that._scalesFilteredClicks();

            d3.select(this).append('circle')
                .attr( 'class', 'mouseScatter-filteredClicks' )
                .attr( 'r', r ).attr( 'x', '0' ).attr( 'y', '0')
                .attr( 'transform', function( dd, ii ){
                    const dataY = scalesMetric.y( dd.y[dataIndex].value);
                    const y = that._conf.height - dataY;
                    return 'translate('+0+','+y+')';
                })
                // .attr( 'stroke', 'green')
                // .attr( 'stroke-width', '4')
                // .attr( 'fill', 'transparent')
                .classed( 'isActive', ()=>{
                    return (meta.statsMetricsType === 'clicks') ? true : false;
                });

            // Ctr
            dataIndex = 3;
            scalesMetric = that._scalesCtr();

            d3.select(this).append('circle')
                .attr( 'class', 'mouseScatter-filteredCtr' )
                .attr( 'r', r ).attr( 'x', '0' ).attr( 'y', '0')
                .attr( 'transform', function( dd, ii ){
                    const dataY = scalesMetric.y( dd.y[dataIndex].value);
                    const y = that._conf.height - dataY;
                    return 'translate('+0+','+y+')';
                })
                // .attr( 'stroke', 'blue')
                // .attr( 'stroke-width', '4')
                // .attr( 'fill', 'transparent')
                .classed( 'isActive', ()=>{
                    return (meta.statsMetricsType === 'ctr') ? true : false;
                });

            // Filter Spend
            dataIndex = 4;
            scalesMetric = that._scalesFilteredSpend();

            d3.select(this).append('circle')
                .attr( 'class', 'mouseScatter-filteredSpend' )
                .attr( 'r', r ).attr( 'x', '0' ).attr( 'y', '0')
                .attr( 'transform', function( dd, ii ){
                    const dataY = scalesMetric.y( dd.y[dataIndex].value);
                    const y = that._conf.height - dataY;
                    return 'translate('+0+','+y+')';
                })
                // .attr( 'stroke', 'yellow')
                // .attr( 'stroke-width', '4')
                // .attr( 'fill', 'transparent')
                .classed( 'isActive', ()=>{
                    return (meta.statsMetricsType === 'spend') ? true : false;
                });


            // eCPM
            dataIndex = 5;
            scalesMetric = that._scales_eCPM();

            d3.select(this).append('circle')
                .attr( 'class', 'mouseScatter-eCPM' )
                .attr( 'r', r ).attr( 'x', '0' ).attr( 'y', '0')
                .attr( 'transform', function( dd, ii ){
                    const dataY = scalesMetric.y( dd.y[dataIndex].value);
                    const y = that._conf.height - dataY;
                    return 'translate('+0+','+y+')';
                })
                // .attr( 'stroke', 'magenta')
                // .attr( 'stroke-width', '4')
                // .attr( 'fill', 'transparent')
                .classed( 'isActive', ()=>{
                    return (meta.statsMetricsType === 'ecpm') ? true : false;
                });

            // eCPC
            dataIndex = 6;
            scalesMetric = that._scales_eCPC();

            d3.select(this).append('circle')
                .attr( 'class', 'mouseScatter-eCPC' )
                .attr( 'r', r ).attr( 'x', '0' ).attr( 'y', '0')
                .attr( 'transform', function( dd, ii){
                    const dataY = scalesMetric.y( dd.y[dataIndex].value);
                    const y = that._conf.height - dataY;
                    return 'translate('+0+','+y+')';
                })
                // .attr( 'stroke', 'cyan')
                // .attr( 'stroke-width', '4')
                // .attr( 'fill', 'transparent')
                .classed( 'isActive', ()=>{
                    return (meta.statsMetricsType === 'ecpc') ? true : false;
                });
        }// End :: cb_onMouseenter

        function cb_onMouseleave(d, i) {
            d3.select(this).selectAll('circle').remove();
        }// End :: cb_onMouseleave

    // End   :: Events
};





/////////////////////////
//  axises             //
/////////////////////////
D3Chart.prototype._updateXAxis = function(el){
    var that = this;
    var startDate = this._cache.dateStart;
    var endDate = this._cache.dateEnd;

    // For testing
        // startDate = new Date("01-Jun-15");
        // endDate = new Date("04-Jun-15");

    // parameter for you to tweek
        var minorTickIntervalInHour = 12;
        var numberOfTicksWDate = 15; // this is an approximate number

    // setup scale for axis
        var xAxisMargin =  parseInt(this._cache.deltaWidth_per_dataPoint/2);

        var dS= startDate;
        var dE= endDate;
        var rS= 0 + xAxisMargin;
        var rE= this._cache.plotAreaWidth - xAxisMargin;
        var axisScale = d3.time.scale()
            .domain([dS,dE]).range([rS,rE]);

    // calculate details
        var M_startDate = moment(startDate);
        var M_endDate = moment(endDate);
        var howManyDays_btw_startEndDate = M_endDate.diff(M_startDate, 'days') + 1; // include end point
        var n = howManyDays_btw_startEndDate; // put it into shorter variable name

    // adjustment for edge cases
        var numOfDataPointsToSkipLabel = ( n <= numberOfTicksWDate ) ? 0 : parseInt(n/numberOfTicksWDate);
        var numberOfTicks = (n === 1) ? n : n-1; // a hack to take care of case when n is unity

    // major ticks
        var xAxis = d3.svg.axis()
            .scale(axisScale)
            .orient('bottom')
            .ticks( numberOfTicks )
            .tickSize(30, 0)  // this ticksize also govern default label distance to axis
            .tickFormat(function(d, index) {
                var mod = index % (numOfDataPointsToSkipLabel + 1);
                var out = (mod === 0) ? d3.time.format('%b %d')(d) : undefined;
                return  out;
            });

    var xAxis_g = d3.select(el).select('g.x.axis').call(xAxis);

    // minor ticks
        xAxis_g.selectAll('line')
            .data(
                axisScale.ticks(d3.time.hour, minorTickIntervalInHour),
                function(d) {
                    // console.log('minor tick loop: ', arguments)
                    return d;
                }
            )
            .enter()

            .append('line')
            .attr('class', 'minor')
            .attr('y1', 0)
            .attr('y2', 3)
            .attr('x1', function(d) {
                // console.log('x1: ', d);
                return axisScale(d);
            })
            .attr('x2', function(d) {
                // console.log('x2: ', d);
                return axisScale(d);
            });

    // major ticks with label
        xAxis_g.selectAll('.tick line')
            .classed('tick-w-label',
                function(d, index) {
                    var mod = (index) % (numOfDataPointsToSkipLabel + 1);
                    if (mod === 0) { return d; }
                })
                .attr('y2', function(d,index){
                    // console.log('attr for y2: ', d)
                    var mod = (index) % (numOfDataPointsToSkipLabel + 1);
                    if (mod !== 0) {
                        return 0;
                    } else {
                        return 20;
                    }
                });
};


D3Chart.prototype._updateYAxis = function(el){
    var maxDataY = this._cache.maxDataY;
    var minDataY = this._cache.minDataY;

    var dS= maxDataY + this._cache.headRoom;
    var dE= 0 - this._cache.floorOffset;

    var rS= 0;
    var rE= this._conf.height;

    var AxisScale = d3.scale.linear().domain([dS,dE]).range([rS,rE]);

    var yAxis = d3.svg.axis()
        .scale(AxisScale)
        .orient('left')
        //.ticks(this._cache.dataResolutionOnYAxis)
        .tickSize(10);

     d3.select(el).select('g.y.axis').call(yAxis);
};


D3Chart.prototype._prepareXAxisContainer = function(el){
    var axisLocationY = this._conf.height;
    d3.select(el).select('g.plot-area').append('g')// Add the X Axis
        .attr('class', 'x axis')
        .attr('transform', 'translate(0, '+ axisLocationY +')');
};


D3Chart.prototype._prepareYAxisContainer = function(el){
    var axisLocationY = 0;
    var axisLocationX = 0;
    d3.select(el).select('g.plot-area').append('g')
        .attr('class', 'y axis')
        .attr('transform', 'translate('+axisLocationX+', '+axisLocationY+')');
};






/////////////////////////
//  Scales             //
/////////////////////////
D3Chart.prototype._scalesFilteredImpressions = function(data) {
    var that = this;

    var x = this._scale_abscissa(data);

    // var dataIndexY = this._conf.dataIndex.unfilteredImpressions;
    // var dataY = data.map(function(d){ return d.y[dataIndexY].value; });
    var maxDataY = this._cache.maxDataY;
    var minDataY = this._cache.minDataY;

    var dS_y = 0 - this._cache.floorOffset;
    var dE_y = maxDataY + this._cache.headRoom;
    var rS_y = 0;
    var rE_y = this._conf.height;
    var y = d3.scale.linear().domain([ dS_y, dE_y ]).range([ rS_y, rE_y ]);

    return {x:x, y:y};
};

D3Chart.prototype._scalesFilteredClicks = function(data) {
    var that = this;

    var x = this._scale_abscissa(data);

    // var dataIndexY = this._conf.dataIndex.unfilteredImpressions;
    // var dataY = data.map(function(d){ return d.y[dataIndexY].value; });
    var maxDataY = this._cache.maxDataY;
    var minDataY = this._cache.minDataY;

    var dS_y = 0 - this._cache.floorOffset;
    var dE_y = maxDataY + this._cache.headRoom;
    var rS_y = 0;
    var rE_y = this._conf.height;
    var y = d3.scale.linear().domain([ dS_y, dE_y ]).range([ rS_y, rE_y ]);

    return {x:x, y:y};
};

D3Chart.prototype._scalesFilteredSpend = function(data) {
    var that = this;

    var x = this._scale_abscissa(data);

    // var dataIndexY = this._conf.dataIndex.unfilteredImpressions;
    // var dataY = data.map(function(d){ return d.y[dataIndexY].value; });
    var maxDataY = this._cache.maxDataY;
    var minDataY = this._cache.minDataY;

    var dS_y = 0 - this._cache.floorOffset;
    var dE_y = maxDataY + this._cache.headRoom;
    var rS_y = 0;
    var rE_y = this._conf.height;
    var y = d3.scale.linear().domain([ dS_y, dE_y ]).range([ rS_y, rE_y ]);

    return {x:x, y:y};
};

D3Chart.prototype._scalesCtr = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_ctr_max;
    var minDataY = this._cache.data_ctr_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height/3;
    var y = d3.scale.linear().domain([ dS_y, dE_y ]).range([ rS_y, rE_y ]);

    return {x:x, y:y};
};

D3Chart.prototype._scales_eCPM = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_eCPM_max;
    var minDataY = this._cache.data_eCPM_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height/3;
    var y = d3.scale.linear().domain([ dS_y, dE_y ]).range([ rS_y, rE_y ]);

    return {x:x, y:y};
};

D3Chart.prototype._scales_eCPC = function(data) {
    var that = this;
    var x = this._scale_abscissa(data);

    var maxDataY = this._cache.data_eCPC_max;
    var minDataY = this._cache.data_eCPC_min;

    var dS_y = 0;
    var dE_y = maxDataY;
    var rS_y = 0;
    var rE_y = this._conf.height/3;
    var y = d3.scale.linear().domain([ dS_y, dE_y ]).range([ rS_y, rE_y ]);

    return {x:x, y:y};
};

D3Chart.prototype._scale_abscissa = function() {
    var that = this;

    var maxDataX = this._cache.dateEnd;
    var minDataX = this._cache.dateStart;

    var pointDistance = this._cache.deltaWidth_per_dataPoint;
    var startRangeX = 0  + pointDistance/2;
    var endRangeX   = this._cache.plotAreaWidth- pointDistance/2;

    var x = d3.time.scale()
        .domain([minDataX, maxDataX])
        .range([startRangeX, endRangeX]);

    return x;
};






///////////////////////////
// Tool Tips             //
///////////////////////////
D3Chart.prototype._updateToolTips = function(d) {
    const data = d.y;
    const dataIndex = this._conf.dataIndex;
    const presentation_date = moment(d.date.value).format('MMM DD, YYYY') + ' UTC';

    const filtered_impressions = data[dataIndex.filteredImpressions].rawValue;
    const filtered_clicks      = data[dataIndex.filteredClicks     ].rawValue;
    const filtered_spend       = data[dataIndex.filteredSpend      ].rawValue;
    const ctr                  = data[dataIndex.ctr                ].rawValue;
    const eCPM                 = data[dataIndex.eCPM               ].rawValue;
    const eCPC                 = data[dataIndex.eCPC               ].rawValue;

    const presentation_filtered_impressions = numeral( filtered_impressions ).format('0,0');
    const presentation_filtered_clicks      = numeral( filtered_clicks ).format('0,0');
    const presentation_filtered_spend       = '$' + numeral( filtered_spend).format('0,0.00');
    const presentation_ctr                  = numeral( ctr *100 ).format('0,0.00')+'%';
    const presentation_eCPM                 = '$' + numeral( eCPM ).format('0,0.00');
    const presentation_eCPC                 = '$' + numeral( eCPC ).format('0,0.00');

    const selected = this._selected;
    const tooltip = d3.select(selected.component).select('.am-reports-toolTip');


    const svgRect     = '<rect x="3" y="0" width="10" height="10" ></rect>';
    const svgDash     = '<rect x="3" y="3" width="10" height="3" ></rect>';
    const svgEllipsis     = `<g>
        <circle r="1.5" cx="4"  cy="5" ></circle>
        <circle r="1.5" cx="8"  cy="5" ></circle>
        <circle r="1.5" cx="12" cy="5" ></circle>
    </g>`;

    const html = `
    <div class="am-reports-toolTip-inner">
        <div class="am-reports-toolTip-title">${presentation_date}</div>
        <table>
            <tr class="filtered-impressions"><td><svg>${svgRect}    </svg></td><td>Impressions:   </td><td>${presentation_filtered_impressions}</td> </tr>
            <tr class="filtered-clicks"     ><td><svg>${svgRect}    </svg></td><td>Clicks: </td><td>${presentation_filtered_clicks}     </td> </tr>
            <tr class="filtered-spend"      ><td><svg>${svgRect}    </svg></td><td>Spend:  </td><td>${presentation_filtered_spend}      </td> </tr>
            <tr class="ctr"                 ><td><svg>${svgDash}    </svg></td><td>CTR:    </td><td>${presentation_ctr}                 </td> </tr>
            <tr class="eCPM"                ><td><svg>${svgEllipsis}</svg></td><td>eCPM:   </td><td>${presentation_eCPM}                </td> </tr>
            <tr class="eCPC"                ><td><svg>${svgDash}    </svg></td><td>eCPC:   </td><td>${presentation_eCPC}                </td> </tr>
        </table>
    </div>
    `;

    tooltip.html(html) ;
};

D3Chart.prototype._updateToolTipPositionInClient = function(el){
    const that = this,  selected = this._selected;

    const bandWidth = this._cache.deltaWidth_per_dataPoint;
    const offSetSoYouCanSeeCircle = 15;// bandWidth/4;

    // x,y is the coordinate of tooltip in svg
    const y = 100;
    const x = this._cache.mouseLineX + offSetSoYouCanSeeCircle;
    const toolTipsPosition_clientCoord = this._getClientCoordinate({x, y}); // Transform the above coordinates to client
    selected.component.toolTipsPosition = toolTipsPosition_clientCoord;     // Mount it as a property of react component

    // Mouse line coordinate
    const mouseLineCoord_client = this._getClientCoordinate( {x:this._cache.mouseLineX, y:0} );
    selected.component.mousLineCoord = mouseLineCoord_client;

    // Offset in client
    const toolTipsOffset_client = toolTipsPosition_clientCoord.x - mouseLineCoord_client.x;
    selected.component.toolTipsOffset = toolTipsOffset_client;
};






///////////////////////////
//  Transformation       //
///////////////////////////
D3Chart.prototype._getClientCoordinate = function( { x= null, y = null } ) {
    const selected = this._selected;

    const svgNode = selected.svg.node();
    const p_user = svgNode.createSVGPoint();
    p_user.x =  x;
    p_user.y =  y;

    const plotAreaNode = selected.plotArea.select('.plot-area-background').node();
    const m = plotAreaNode.getScreenCTM();   // Transformation metrix;

    const p_client = p_user.matrixTransform(m);
    return { 'x': p_client.x, 'y': p_client.y};
};


module.exports = D3Chart;
