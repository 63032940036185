'use strict';

var SummaryTable = React.createClass({
    propTypes: {
        headerItems: React.PropTypes.array.isRequired,
        bodyItems: React.PropTypes.array.isRequired,
        isLoading: React.PropTypes.bool.isRequired,
        isLiveStats: React.PropTypes.bool.isRequired,
    },

    getInitialState () {
        return {};
    },

    render () {
        return (
            <table className="am-progressTable">
                <thead>
                    <tr>
                        {this.props.headerItems.map((item, i) =>
                        <th key={item.name + i}>{item.name}</th>)}
                    </tr>
                </thead>
                <tbody>
                    <tr className="am-progressTable-totalRow">
                        {this.props.headerItems.map((item, i) =>
                        <td key={item.value + i}>{item.value}</td>)}
                    </tr>
                    {this.props.bodyItems.map((rowItem, i) =>
                        <tr key={i}>
                            {rowItem.map((cellItem, j) =>
                                <td key={cellItem.name + i + j}>
                                    {cellItem.value}
                                    {(i === 0 && j === 0 && this.props.isLiveStats) ?
                                        <span className="am-progressTable-liveStatsLabel"> Live</span>
                                    :null}
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
});


module.exports = SummaryTable;