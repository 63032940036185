'use strict';

var app = require('scripts/core').app;
var Creatives = require('scripts/entities/collections/creatives');

var PreviewView = Mario.Layout.extend({
    className: 'preview-creative',
    getTemplate: function () {
        return require('./templates/preview-' + this.model.get('creative').format);
    },

    regions: {
    },

    events: {
        'click [data-action=approve]' : 'approve',
        'click [data-action=reject]' : 'reject',
        'click [data-action=change]' : 'change',
        'click [data-action=cancel-change]' : 'cancelChange'
    },

    onShow: function () {
    },

    change: function () {
        this.$('[data-action=change]').hide();
        this.$('[data-action=change] + div').removeClass('hide');
    },

    cancelChange: function () {
        globalCh.trigger('popup:close');
    },

    approve: function () {
        this.model.approve(this.$('textarea').val());
        this.closePopup();
    },

    reject: function () {
        var explanation = this.$('textarea').val();

        if (_.isEmpty(explanation)) {
            toastr.error('Rejection must be provided with explanation.');
            return false;
        }

        this.model.reject(explanation);
        this.closePopup();
    },

    closePopup: function () {
        globalCh.trigger('popup:close');
    },

    templateHelpers: function () {
        return Creatives.AUDIT_STATUS;
    }
});

module.exports = PreviewView;
