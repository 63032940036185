'use strict';
var userTemplate = require('./templates/profile');
var env = require('scripts/env');

module.exports = Mario.ItemView.extend({
    className: 'user-profile',
    template: userTemplate,
    onShow: function () {
        var that =  this;
        var app = require('scripts/core').app;
        var userReq = globalCh.request('cache', 'user');
        userReq.done(function (user) {
            that.model = user;
            that.render();
        });
    },

    events: {
        'click [data-action=cancel]' : 'closePopup'
    },

    closePopup: function () {
        var app = require('scripts/core').app;
        globalCh.trigger('popup:close');
    },

    templateHelpers: function () {
        var app = require('scripts/core').app;
        return _.extend(env,
            {
                session: app.session.toJSON(),
                API_VERSION: app['X-API-Version']
            });
    }
});
