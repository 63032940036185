<div class="form-auth activation" role="form">
    <h2 class="form-auth-heading">Account Details</h2>
    <div class="row">
        <div class="col-xs-12">
            <div class="form-group">
                <label class="control-label">E-mail Address</label>
                <p class="form-control-static">{{email}}</p>
            </div>
            <div class="form-group">
                <label class="control-label">First Name <i class="fa fa-asterisk text-danger"></i></label>
                <input class="form-control user-first_name" type="text" data-id="first_name" placeholder="First name" value="{{first_name}}"/>
                <span class="help-block "></span>

            </div>
            <div class="form-group">
                <label class="control-label">Last Name <i class="fa fa-asterisk text-danger"></i></label>
                <input class="form-control user-last_name" type="text" data-id="last_name" placeholder="Last Name" value="{{last_name}}" />
                <span class="help-block"></span>
            </div>

            <div class="form-group">
                <label class="control-label">Password <i class="fa fa-asterisk text-danger"></i></label>
                <input type="password" class="form-control user-password" placeholder="Password" value="" required autofocus autocomplete="password">
                <span class="help-block"></span>

            </div>

            <div class="form-group">
                <label class="control-label">Confirm Password <i class="fa fa-asterisk text-danger"></i></label>
                <input type="password" class="form-control user-confirm-password" placeholder="Password" value="" required autofocus autocomplete="password">
                <span class="help-block">Passwords do not match</span>

            </div>

            <div class="form-group">
                <button class="btn btn-lg btn-primary btn-block" data-action="save">Activate Account</button>
            </div>


        </div>
    </div>
</div>