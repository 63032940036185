'use strict';


var Preview = React.createClass({
    render() {
        var {format, url} = this.props.creative;

        var sharedStyle = {
            width             : '100%',
            height            : '100px',
            backgroundSize    : 'contain',
            backgroundRepeat  : 'no-repeat',
            backgroundPosition: 'center'
        };

        switch (format) {
            case 'video':
                var {video_preview_url, video_url, image_preview_url} = this.props.creative, style = sharedStyle;
                return <video src={video_url} style={style} controls="controls" preload='none' poster={image_preview_url}/>;
            case 'native':
                var {main_image_preview_url} = this.props.creative;
                var style = _.extend(sharedStyle, {backgroundImage   : `url(${main_image_preview_url})`});
                return <div style={style} />;
            case 'custom_html':
                var {reference_image_preview_url} = this.props.creative;
                var style = _.extend(sharedStyle, {backgroundImage   : `url(${reference_image_preview_url})`});
                return <div style={style} />;
            case 'mraid':
                var {reference_image_preview_url} = this.props.creative;
                var style = _.extend(sharedStyle, {backgroundImage   : `url(${reference_image_preview_url})`});
                return <div style={style} />;
            default:
                var {image_preview_url} = this.props.creative;
                var style = _.extend(sharedStyle, {backgroundImage   : `url(${image_preview_url})`});
                return <div style={style} />;
        }

        return null;
    }
});

module.exports = Preview;
