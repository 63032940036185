'use strict';

var campaignsReducer = require('./campaigns');

function uiReducer (state = {}, resources, session) {
    return {
        campaigns: campaignsReducer(state.campaigns, resources, session)
    };
}

module.exports = uiReducer;