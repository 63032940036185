'use strict';
var op = objectPath;
var ProgressCalculator = require('scripts/common/progress-calculator');

var statsTableReducer = require('./stats-table'),
    adsMenuReducer = require('./ads-menu'),
    summaryPopupReducer = require('./summary-popup'),
    adProgressGraphReducer = require('./ad-progress-graph'),
    campaignProgressGraphReducer = require('./campaign-progress-graph'),
    refreshReducer = require('./refresh');

var initialState = {
    'schema': {},
    'instances': { }
};


function progressReducer (state = initialState, resources, session) {

    // Only process when route contains '/delivery'
    if (!(/\/delivery/.test(window.location.pathname))) {
        return state;
    }

    const progressSession = op.get(session, 'campaigns.progress', {});
    const activeCampaignId = progressSession.activeInstance;
    const uiInstance = op.get(state, `instances.${activeCampaignId}`, state.schema);

    if (!activeCampaignId) {
        return state;
    }

    const targetAdId = op.get(session, `campaigns.progress.instances.${activeCampaignId}.adId`);
    const isAdPage = !!targetAdId;
    const targetAd = op.get(resources, `campaigns.entities.${activeCampaignId}.ads.entities.${targetAdId}.attr`, {});

    const adsResource = op.get(resources, `campaigns.entities.${activeCampaignId}.ads`, {});

    const adsHealth = _.reduce(adsResource.entities, (map, entity) => {
        const adId = entity.attr.id;
        const adLiveStats = op.get(resources, `stats.progress.ads.entities.${adId}.liveHourly`, []);
        const previousState = op.get(state, `instances.${activeCampaignId}.adsHealth.${adId}`);

        const adLiveStatsDep = op.get(previousState, 'deps.adLiveStats');

        // Don't recalculate if stats have not changed
        if (adLiveStatsDep === adLiveStats) {
            return {
                ...map,
                [adId]: previousState
            };
        }

        const calculator = new ProgressCalculator(entity.attr);

        const goal = calculator.getGoal();

        const now = new Date();
        const totalImpressions = [].concat(adLiveStats)
            .reduce((sum, statItem) => sum + Number(statItem[goal]), 0);

        const health = calculator.getPacedFillHealthForEndOfSecond(now, totalImpressions);

        return {
            ...map,
            [adId]: {
                health,
                deps: {
                    adLiveStats: adLiveStats
                }
            }
        };
    }, {});


    return {
        ...state,
        instances: {
            ...state.instances,
            [activeCampaignId]: {
                statsTable:             statsTableReducer(uiInstance.statsTable, resources, session, activeCampaignId, adsHealth),
                adsMenu:                adsMenuReducer(uiInstance.adsMenu, resources, session, activeCampaignId, adsHealth),
                summaryPopup:           summaryPopupReducer(uiInstance.summaryPopup, resources, session, activeCampaignId, adsHealth),
                adProgressGraph:        adProgressGraphReducer(uiInstance.adProgressGraph, resources, session, activeCampaignId, adsHealth),
                campaignProgressGraph:  campaignProgressGraphReducer(uiInstance.campaignProgressGraph, resources, session, activeCampaignId, adsHealth),
                refresh:                refreshReducer(uiInstance.refresh, resources, session, activeCampaignId),
                adsHealth,
                target: isAdPage ? 'ad' : 'campaign',
                title: isAdPage ? `#${targetAd.id} ${targetAd.name}` : 'Campaign Overall Progress'
            }
        }
    };
}


module.exports = progressReducer;
