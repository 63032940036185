'use strict';

var ToggleButtons = React.createClass({
    getInitialState() {
        return {
            btn1: {
                active: false,
                content: '%'
            },
            btn2: {
                active: false,
                content: '00'
            },
            onClick: this.props.onClick
        };
    },

    componentWillMount() {
        this.setState(this.props);
    },

    render: function () {

        return (
            <div className="btn-group btn-group-sm">
                <button onClick={this.state.onClick} className={`btn btn-default ${this.state.btn1.active? 'active': ''}` }>{this.state.btn1.content}</button>
                <button onClick={this.state.onClick} className={`btn btn-default  ${this.state.btn2.active? 'active': ''}`}>{this.state.btn2.content}</button>
            </div>
        );
    }
});

module.exports = ToggleButtons;
