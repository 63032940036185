'use strict';
var env = require('scripts/env');
var flux = require('scripts/core/flux');

// var state = require('./initial-state');
var _state = {};
var rootReducer = require('../reducers');

var _actionHistory = [];

var ApplicationStore = Fluxxor.createStore({

    initialize: function (options) {
        this.bindActions('reduce_action', 'onReducerAction');
    },

    onReducerAction: function (action) {
        var previousState = _state;
        try {
            // console.time('reducer_time');
            _actionHistory.push(action);
            _state = rootReducer(_state, action);
            // console.timeEnd('reducer_time');
            window.megastate = _state;

            this.emit('change');

        } catch (e) {
            _state = previousState;

            if (env.DEBUG) {
                window.toastr.error(e.stack, e.message);
                // console.error(e, action);
            }

            throw e;
            // log message on server?
        }
    },

    replayActionHistoryTo: function (toAction) {

        let index = 0;
        let state = {};
        while (true) {
            const action = _actionHistory[index];

            state = rootReducer(state, action);

            if (action === toAction) {
                break;
            }

            index += 1;
        }

        window.megastate = _state = state;

        this.emit('change');
    },

    getActionHistory: function () {
        return _actionHistory;
    },

    getState: function (path, def) {
        return objectPath.get(_state, path, def);
    },

    getStateAsString: function (path, def) {
        return JSON.stringify(objectPath.get(_state, path, def));
    }

});

flux.addStore('app-store', new ApplicationStore());


