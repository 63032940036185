'use strict';

var flux = require('scripts/core/flux');

var RouteStore = Fluxxor.createStore({

    initialize: function (options) {
        this._currentRoute = '';

        this.bindActions(
            flux.constants.navigate, 'onNavigate'
        );
    },

    onNavigate: function (routeName) {
        this._currentRoute = routeName;
        this.emit('change');
    },

    getCurrentRoute: function () {
        return this._currentRoute;
    }

});

flux.addStore('route', new RouteStore());
