'use strict';
const flux = require('scripts/core/flux');

const DayWeekCell = React.createClass({
    render() {
        const presentation_group0 = this.props.presentation_group0;
        const presentation_group1 = this.props.presentation_group1;
        const presentation_stats = this.props.presentation_stats;

        const dayWeekIndex_to_dataIndex = function(index_hour, index_weekDay) {
            const _index_hour = +index_hour;
            const _index_weekDay = +index_weekDay;
            return (_index_weekDay * 4) + _index_hour;
        };
        const dataIndex = dayWeekIndex_to_dataIndex( this.props.index_hour, this.props.index_weekDay );

        if (presentation_stats.length !==0 ) {
            const stat = presentation_stats[dataIndex];

            const statsMetrics         = stat.statsMetrics;
            const statsMetrics_numType = stat.statsMetrics_numType;
            const qScale               = this.props.opts.qScale;
            const colorScale           = qScale(statsMetrics_numType);
            const content              = statsMetrics;
            const className            = (stat.selected) ? ('is-quartile-' + colorScale) : 'is-inactive';

            return (
                <td
                    className={className}
                    key={dataIndex}
                    data-id={'data-'+dataIndex}
                    data-toggle={content}
                    >{/* content */}</td>);

        } else {
            return (
                <td
                    className={''}
                    key={dataIndex}
                    data-id={'data-'+dataIndex}
                    data-toggle={''}
                ></td>);
        }
    }
});

const DayWeekRow = React.createClass({
    render() {
        const presentation_group0 = this.props.presentation_group0;
        const presentation_group1 = this.props.presentation_group1;
        const presentation_stats = this.props.presentation_stats;

        const Component_tableCells = [];

        _.forEach(presentation_group1,  function(item) {
            const index_weekDay = item.name;
            Component_tableCells.push( React.createElement( DayWeekCell, {
                key:           index_weekDay,
                index_hour:    this.props.index_hour,
                index_weekDay: index_weekDay,
                opts:          this.props.opts,

                presentation_group0: presentation_group0,
                presentation_group1: presentation_group1,
                presentation_stats: presentation_stats
            }));
        }, this);

        return (
            <tr>
                <th>
                    <label>
                        <input data-selection-group="group_0"
                            id={ this.props.index_hour }
                            type="checkbox"
                            name={ this.props.index_hour }
                            onChange={this.props.handleClick_group0}
                            checked={presentation_group0[this.props.index_hour].selected}
                        />
                        {presentation_group0[this.props.index_hour].presentationName}
                    </label>
                </th>
                {Component_tableCells}
            </tr>
        );
    }
});

const DayWeekBody = React.createClass({
    render() {
        const presentation_group0 = this.props.presentation_group0;
        const presentation_group1 = this.props.presentation_group1;
        const presentation_stats = this.props.presentation_stats;

        const Component_tableRows = [];

        presentation_group0.forEach(function(hour) {
            const index_hour = hour.name;
            Component_tableRows.push(
                React.createElement( DayWeekRow, {
                    key:         index_hour,
                    index_hour:  index_hour,
                    handleClick_group0:  this.props.handleClick_group0,
                    opts:                this.props.opts,
                    presentation_group0: presentation_group0,
                    presentation_group1: presentation_group1,
                    presentation_stats:  presentation_stats
                })
            );
        }, this);

        return ( <tbody> {  Component_tableRows } </tbody> );
    }
});

const DayWeekPartsGraph = React.createClass({
    componentWillMount() {
        this._qScale;
    },

    handleClick_group0() {
        const selected_g0 = [];
        $(this.getDOMNode()).find('[data-selection-group="group_0"]').each( function() {
            const $el = $(this);
            const isChecked = $el.is(':checked');
            if (isChecked) {
                 const name = $el.attr('name');
                 selected_g0.push(name);
            }
        });
        this.props.onCheckboxSelected_group0(selected_g0);
    },

    handleClick_group1() {
        const selected_g1 = [];
        $(this.getDOMNode()).find('[data-selection-group="group_1"]').each( function() {
            const $el = $(this);
            const isChecked = $el.is(':checked');
            if (isChecked) {
                 const name = +$el.attr('name');
                 selected_g1.push(name);
            }
        });
        this.props.onCheckboxSelected_group1(selected_g1);
    },

    resetOptions () {
        this.props.onReset();
    },

    // shouldComponentUpdate (nextProps, nextState) {
    //     return true;
    // },

    render () {
        const presentation_group0 = this.props.presentation_group0;  // interval
        const presentation_group1 = this.props.presentation_group1;  // day_of_week
        const presentation_stats = this.props.presentation_stats;

        // Calculating quantile scale for color in heat map
        // We only want to calculate max min value base on the data point that has been selected
        const stats_heatmapSelected = _.filter(presentation_stats, (stat_heatmap) =>{
            return stat_heatmap.selected;
        });
        const statsMinMax_heatmap = d3.extent(_.pluck(stats_heatmapSelected,'statsMetrics_numType'));
        const qScale = this._qScale = d3.scale.quantile().domain(statsMinMax_heatmap).range(['1', '2', '3', '4']);
        const opts = {qScale: this._qScale};

        // is loading
        const isLoading_day_of_week = this.props.configDimensions.day_of_week.isLoading;
        const isLoading_hour = this.props.configDimensions.hour.isLoading;
        const isLoading = this.props.isLoading; // isLoading_day_of_week || isLoading_hour;

        // selection
        const someSelected_hour = _.some(this.props.presentation_group0, { selected: true });
        const someSelected_dayOfWeek = _.some(this.props.presentation_group1, { selected: true });
        const someSelected = someSelected_hour || someSelected_dayOfWeek;

        if ( presentation_stats && presentation_stats.length === 0 && !isLoading) {
            return (
                <div>
                    <div className="am-dimensions-tile-header">
                        <h5>Day of Week &times; Time of Day {isLoading ? <i className="fa fa-spinner fa-spin"></i> : ''}</h5>
                    </div>
                    <div className="am-dimensions-tile-dataUnavailable">
                        Data not available
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="am-reportTile-header">
                        {someSelected ? <a href="#" className="am-reportTile-reset" onClick={this.resetOptions}>Reset</a> : ''}
                        <h5>Day of Week &times; Time of Day {isLoading ? <i className="fa fa-spinner fa-spin"></i> : ''}</h5>
                    </div>
                    <div className="am-heatmap">
                        <form>
                            <table>
                                <thead>
                                    <tr>
                                        <td></td>
                                        { _.map(presentation_group1 , function(item, index) {
                                            return (
                                                <th key={index} >
                                                    <label>
                                                        <input
                                                            data-selection-group="group_1"
                                                            type="checkbox"
                                                            name={item.name}
                                                            id={item.name}
                                                            onChange={this.handleClick_group1}
                                                            checked={item.selected}
                                                        />
                                                        {item.presentationName}
                                                    </label>
                                                </th>
                                            );
                                        },this) }
                                    </tr>
                                </thead>
                                <DayWeekBody
                                    handleClick={this.handleClick}
                                    handleClick_group0={ this.handleClick_group0}
                                    presentation_group0={presentation_group0}
                                    presentation_group1={presentation_group1}
                                    presentation_stats={presentation_stats}
                                    opts={opts}
                                />
                            </table>
                        </form>
                    </div>
                </div>
            );
        } // End if
    }
});

module.exports = DayWeekPartsGraph;
