'use strict';
var Status = require('scripts/widgets/status');
var AdSummary = require('../ad-summary');
var ToggleSwitch = require('scripts/widgets/toggle-switch');
var History = require('./history');
var Tooltip = require('../../../../../widgets/tooltip');

var SummaryTitle = React.createClass({
    render() {
        return (
            <div className="summary-title" id={`ad-summary-${this.props.id}`}>
                <span className="btn">{this.props.name}</span>
                {this.props.canEdit?
                <div className="pull-right">
                    <button className="btn btn-default btn-sm">
                        <i className="fa fa-pencil"></i>Edit</button>
                </div> : null}
                <div className="clearfix"></div>
            </div>
        );
    }
});

var PrivateChannel = require('scripts/common/mixins/private-channel');
var BasicInfo = React.createClass({

    mixins: [PrivateChannel],

    getInitialState() {
        return {
            showHistory: false
        };
    },

    componentDidMount() {
        var popoverContainer = this.getPopoverContainer();
        popoverContainer.on('click', ()=>this.mountPopoverContent());


        var adJson = this.props.ad.toJSON();
        var content = React.renderToStaticMarkup(React.createFactory(AdSummary)({adJson, ad: this.props.ad}));
        $('body').on('click.ad-summary', `#ad-summary-${adJson.id} button`, ()=>this.onClickEdit());
        this.privateCh.listenTo(this.props.ch, 'popover-shown', (aid)=> this.hideOtherPopover(aid));
    },

    mountPopoverContent() {
        // mounting popover
        var popoverContainer = this.getPopoverContainer();

        var ad = this.props.ad;
        var adJson = ad.toJSON();
        var canEdit = ad.can('edit')
        var {name, id} = adJson;

        var title = React.renderToStaticMarkup(React.createFactory(SummaryTitle)({name, id, canEdit}));
        var content = React.renderToStaticMarkup(React.createFactory(AdSummary)({adJson, ad: this.props.ad}));


        // set content and title dynamically
        popoverContainer.attr('data-content', content);
        popoverContainer.attr('data-original-title', title);

        popoverContainer.popover('show');
        popoverContainer.one('shown.bs.popover', ()=>this.props.ch.trigger('popover-shown', this.props.ad.id));
    },

    getPopoverContainer() {
        var $el = $(this.getDOMNode());
        return $el.find('[data-toggle=popover]');
    },
    hideOtherPopover(aid) {
        if (aid !== this.props.ad.id) {
            this.getPopoverContainer().popover('hide');
        }
    },

    componentWillUnmount() {
        $('body').off('click.ad-summary');

        // mounting popover
        var popoverContainer = this.getPopoverContainer();
        popoverContainer.popover('destroy');
    },

    onClickProgress() {
        this.props.ch.trigger('campaign:ads:progress', this.props.ad);
    },

    onClickEdit() {
        this.props.ch.trigger('campaign:ads:edit', this.props.ad);
        this.getPopoverContainer().popover('hide');
    },

    onClickDuplicate() {
        this.props.ch.trigger('campaign:ads:duplicate', this.props.ad);
    },

    onClickDelete() {
        this.props.ch.trigger('campaign:ads:delete', this.props.ad);
    },

    onClickHistory() {
        this.setState({showHistory: true});
    },

    onCloseHistory() {
        this.setState({showHistory: false});
    },

    render: function () {
        var ad = this.props.ad;
        var creative = ad.getCreative();

        return (
            <div className="row">
                {ad.can('edit')?
                <div className="col-xs-3 col-xs-push-9 col-lg-3 col-lg-push-0">
                    <ToggleSwitch ch={this.props.ch} targetEntity={ad} />
                </div>
                : null }

                <div className={ad.can('edit')?
                    "col-xs-9 col-xs-pull-3 col-lg-9 col-lg-pull-0" : "col-xs-12"}>

                    <a href="#" data-toggle="popover" tabIndex="0" data-html="true" data-trigger="manual" data-container="body" data-placement="right">
                        <h4 title={ad.get('name')}>
                            <span className="text-muted">#{ad.id}</span>&nbsp;{ad.get('name')}
                        </h4>
                    </a>
                    <Status status={ad.getDisplayStatus()}></Status>
                    <small>Creative:
                    {creative? <a href={`/campaigns/${ad.getCampaignId()}/creatives/${creative.id}`}>{creative.get('name')}</a>: <a href={`/campaigns/${ad.getCampaignId()}/creatives`}>none</a> }
                    </small>
                    <br/>
                   <div className="ef-toolbar" style={{marginTop: '6px'}}>

                        <Tooltip text="Real-Time Delivery">
                            <a type="button" title="Real-Time Delivery" href={`/campaigns/${ad.getCampaignId()}/delivery/${ad.id}`}>
                                <i className="fa fa-fw fa-line-chart"></i>
                                <span>Real-Time Delivery</span>
                            </a>
                        </Tooltip>

                        {ad.can('edit')?
                        <Tooltip text="Edit">
                            <button type="button" title="Edit"  onClick={this.onClickEdit}>
                                <i className="fa fa-fw fa-pencil"></i>
                                <span>Edit</span>
                            </button>
                        </Tooltip>: null}

                        {ad.can('edit')?
                        <Tooltip text="Duplicate">
                            <button type="button" title="Duplicate"  onClick={this.onClickDuplicate}>
                                <i className="fa fa-fw fa-copy"></i>
                                <span>Duplicate</span>
                            </button>
                        </Tooltip>: null}

                        <Tooltip text="History">
                            <button type="button" title="History"  onClick={this.onClickHistory}>
                                <i className="fa fa-fw fa-clock-o"></i>
                                <span>History</span>
                            </button>
                        </Tooltip>

                        {ad.can('edit')?
                        <Tooltip text="Delete">
                            <button type="button" title="Delete" onClick={this.onClickDelete}>
                                <i className="fa fa-fw fa-trash"></i>
                                <span>Delete</span>
                            </button>
                        </Tooltip>: null}
                    </div>
                </div>
                {this.state.showHistory? <History ch={this.props.ch} ad={this.props.ad} onClose={this.onCloseHistory}/> : null}
            </div>
        );

    }
});


module.exports = BasicInfo;
