<td>
    {{creative.name}}
</td>

<td>
    {{creative.format}}
</td>

<td>
    <a href="/campaigns/{{campaign_id}}">{{campaign_id}}</a>
</td>

<td>
    {{#each campaign.iab_categories}}
    {{decode_IAB_category this}}
    {{/each}}
</td>

<td>
    {{#is_equal status APPROVED}}
        <i class="fa fa-check"></i>
    {{else}}
        {{#is_equal status REJECTED}}
            <i class="fa fa-times"></i>
        {{else}}
            <i class="fa fa-circle-o"></i>
        {{/is_equal}}
    {{/is_equal}}
    {{humanize status}}
</td>

<td>
    {{#is_equal assessment_status APPROVED}}
        <i class="fa fa-check"></i>
    {{else}}
        {{#is_equal assessment_status REJECTED}}
            <i class="fa fa-times"></i>
        {{else}}
            <i class="fa fa-circle-o"></i>
        {{/is_equal}}
    {{/is_equal}}
    {{humanize assessment_status}}
</td>

<td>
    {{#if created}}
        {{nice_time created}}
    {{else}}
        {{nice_time _created}}
    {{/if}}
</td>

<td>
    <a href data-action="preview">Preview</a>
</td>

