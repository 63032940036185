{{number_of_records}} records are found
<ul class="list-unstyled">
    {{#each search_terms}}
    <li>
        <span>{{humanize @key}}</span>:
        <span>{{this}}</span>
    </li>
    {{/each}}
</ul>

<button type="button" class="close reset" aria-label="Close" title="Reset search">
    <span aria-hidden="true">&times;</span>
</button>
<span class="close-text reset pull-right">Close</span>
