<h3>User Profile</h3>

<table class="table">
    <tr>
        <th>Email</th>
        <td>{{email}}</td>
    </tr>
    <tr>
        <th>ID</th>
        <td>{{id}}</td>
    </tr>
    <tr>
        <th>IP</th>
        <td>{{client_ip}}</td>
    </tr>
    <tr>
        <th>Organization</th>
        <td>{{session.organization.name}}</td>
    </tr>
    <tr>
        <th>Role</th>
        <td>{{session.role.name}}</td>
    </tr>
    <tr>
        <th>Suspended</th>
        <td>{{# if suspended}} Yes {{else}} No {{/if}}</td>
    </tr>
    <tr>
        <th>Dashboard version</th>
        <td><span class="label label-default">{{VERSION}}</span></td>
    </tr>
    {{#if API_VERSION}}
        <tr>
            <th>Dashboard API version</th>
            <td><span class="label label-default">{{API_VERSION}}</span></td>
        </tr>
    {{/if}}
</table>


<a href="/signout" class="btn btn-warning">Sign out</a>
