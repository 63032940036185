'use strict';

var StatsPreview = require('scripts/widgets/stats-preview');
var BulletGraph = require('scripts/widgets/bullet-graph');
var fns = require('scripts/common/fns');
var PrivateChannel = require('scripts/common/mixins/private-channel');
var Tooltip = require('scripts/widgets/tooltip');

var CampaignDetailRegion = React.createClass({
    mixins: [PrivateChannel],

    componentDidMount() {
        var campaign = this.props.campaign;
        // it's okay to use forceUpdate here since it's not a big tree
        this.privateCh.listenTo(campaign, 'change stats:loaded', ()=>this.forceUpdate());
    },


    render() {
        var campaign = this.props.campaign;
        var campaignShowType = globalCh.request('view-pref')['show_type_for_campaigns'];

        var bgSettings = campaign.getBulletGraphAttributes();
        var statsSettings = campaign.computeDisplayData();

        var categories = campaign.get('iab_categories').map((cat, idx) =>
                <span className="label label-default" style={{marginLeft: 0, marginRight: '0.5em', display: 'inline-block'}} key={`campaign-${idx}-category`}>{fns.decodeIABCategory(cat)}</span>
        );

        return (
            <div className="row" >
                <div className="col-lg-4">

                    <div className="row">
                        <div className="col-xs-6 col-lg-12">
                            <dl>
                                <dt>Advertiser Domain</dt>
                                <dd>{campaign.get('advertiser_domain')}</dd>
                            </dl>
                        </div>
                        <div className="col-xs-6 col-lg-12">
                            <dl>
                                <dt>IAB Categories</dt>
                                <dd>
                                    {categories}
                                </dd>
                            </dl>
                        </div>
                        <div className="col-xs-12 col-lg-12">
                            <div className="notes">
                            {campaign.get('notes')}
                            </div>
                        </div>
                    </div>

                    <div className="ef-toolbar">

                        <Tooltip text="Real-Time Delivery">
                            <a type="button" title="Real-Time Delivery" href={`/campaigns/${campaign.id}/delivery`}>
                                <i className="fa fa-fw fa-line-chart"></i>
                                <span>Real-Time Delivery</span>
                            </a>
                        </Tooltip>


                        <Tooltip text="Analytics">
                            <a type="button" title="Analytics" href={`/campaigns/${campaign.id}/report`}>
                                <i className="fa fa-fw fa-area-chart"></i>
                                <span>Analytics</span>
                            </a>
                        </Tooltip>

                        {campaign.can('edit')? 
                        <Tooltip text="Edit">
                            <button type="button" title="Edit"  onClick={this.props.onEditCampaign} data-track data-tracking-section="edit-campaign" data-tracking-section-id={campaign.id}>
                                <i className="fa fa-fw fa-pencil"></i>
                                <span>Edit</span>
                            </button>
                        </Tooltip>: null}
                        
                        {/* remove delete campaign first
                        <button type="button" title="Delete" onClick={this.props.onDeleteCampaign}>
                            <i className="fa fa-fw fa-trash"></i>
                            <span>Delete</span>
                        </button>
                        */}

                        <Tooltip text="History">
                            <button type="button" title="History" onClick={this.props.onClickHistory}>
                                <i className="fa fa-fw fa-clock-o"></i>
                                <span>History</span>
                            </button>
                        </Tooltip>
                    </div>

                </div>
                <div className="col-lg-3">
                    <BulletGraph settings={bgSettings} paused={campaign.get('status') === 'paused'} withProgressLabel={true}></BulletGraph>
                </div>
                <div className="col-lg-5">
                    <StatsPreview d={statsSettings} showType={campaignShowType} withLabel={true}></StatsPreview>
                </div>
            </div>
        )
    }
});


module.exports = CampaignDetailRegion;
