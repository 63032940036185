'use strict';

var PrivateChannel = require('scripts/common/mixins/private-channel');
var ToggleSwitch = React.createClass({

    mixins: [PrivateChannel],
    
    getInitialState() {
        return {
            isPaused: this.props.targetEntity.get('paused')
        }
    },
    componentDidMount() {
        this.privateCh.listenTo(this.props.targetEntity, 'change:paused', ()=>this.setState({isPaused: this.props.targetEntity.get('paused')}));
    },
    
    toggleStatus: function () {
        var $el = $(this.getDOMNode());
        var isPause = $el.find('input[type="checkBox"]').is(':checked') ? true : false;
        if (this.props.ch) {
            this.props.ch.trigger('ToggleSwitch:change:isPause', this.props.targetEntity, isPause);
        }
        return false;
    },
    render: function () {
        var targetEntity = this.props.targetEntity;
        return (
            <div className="ef-toggle-switch">
                <input id={`ts-${targetEntity.id}`} type="checkbox" checked={this.state.isPaused} className="ef-toggle-switch-style-round ef-toggle-switch-style-red"  onChange={this.toggleStatus}/>
                <label htmlFor={`ts-${targetEntity.id}`} ></label>
            </div>
        );
    }
});

module.exports = ToggleSwitch;
