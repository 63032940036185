'use strict';

var app = require('scripts/core').app;
var layoutTemplate = require('./templates/layout');
var optionTemplate = require('./templates/option');
var OrganizationShow = require('./show');
var OrganizationForm = require('./form');

var CreativesView = require('./creatives');
var OrganizationShow2 = require('./show2');

/**
 * options
 * model<Organization>
 */

var OrgView = Mario.ItemView.extend({
    template: optionTemplate,
    tagName: 'option',
    events: {
        'click' : 'toggleDetail'
    },

    onRender: function () {
        this.$el.data('organization', this.model);
        if (app.session.get('organization').id === this.model.id) {
            this.$el.attr('selected', true);
        }
    },

});


/**
 * options
 * collection<Collection<Organization>>
 */

module.exports = Mario.CompositeView.extend({
    initialize: function (options) {
        this.listenTo(this.collection, 'add', function (m) {
            this.showDetail(m);
        });
    },

    childView: OrgView,
    template: layoutTemplate,
    className: 'am-admin',
    childViewContainer: 'select',
    events: {
        'change select.switch-organization' : 'changeOrganization',
        'click .new-organization' : 'showCreate'
    },

    changeOrganization: function (ev) {
        var organization = $(ev.currentTarget).find('option:selected').data('organization');
        this.showDetail(organization);
    },

    showDetail: function (organization) {
        this.detailRegion = this.detailRegion || new Mario.Region({el: this.$('.organization-detail')});
        this.detailRegion.show(new OrganizationShow({
            model: organization,
            organizations: this.collection
        }));

        this.regionForShow2 = this.regionForShow2 || new Mario.Region({el: this.$('.region-for-show2')});
        this.regionForShow2.show( new OrganizationShow2({
            model: organization,
            organizations: this.collection
        }));

        this.trigger('change-organization', organization);
    },

    onDestroy: function () {
        if (this.detailRegion) {
            this.detailRegion.destroy();
        }
        if (this.createRegion) {
            this.createRegion.destroy();
        }
    },

    getSelectedOrg: function () {
        return this.$('select.switch-organization option:selected').data('organization');
    },

    onShow: function () {
        var that = this;
        globalCh.reply('my-organization', function () {
            if (!app.session.get('organization')) {
                return null;
            }
            var org = that.collection.get(app.session.get('organization').id);

            if (org != null) {
                var perm = _.find(app.session.get('permissions'), {resource: 'organizations'});

                if (perm != null && _.isArray(perm.actions)) {
                    org.setPermission(perm.actions);
                }
            }

            return org;
        });


        // the following is use in develop to jump to specific organization
        // var dropdown = this.$('select.switch-organization');
        // console.log(dropdown);
        // dropdown.find(':contains(Root)').removeAttr();
        // // dropdown.find(':contains(t1)').attr('selected', 'selected');
        // dropdown.find(':contains(Resolution)').attr('selected', 'selected');
        // console.log('organization dropdown: ', dropdown.html());

        var selectedOrg = this.getSelectedOrg();

        this.showDetail(selectedOrg);
    },

    showCreate: function () {
        var form = new OrganizationForm({
            organizations: this.collection
        });

        $('#popup').one('shown.bs.modal', function () {
            $('#popup input[data-id=name]').focus();
        });

        var popup = globalCh.request('popup');
        popup.show(form);
    },

    templateHelpers: function () {
        return {
            can_create_org: app.can('create', 'organizations')
        };
    }
});
