'use strict';

var initialState = {
    'schema': {
        'summaryPopup': {
            'isOpen': false
        },
        'campaignId': null,
        'adId': null,
        'adsMenu': {
            'adsLoading': []
        },
        'statsTable': {
            'isLoading': false
        }
    },
    'activeInstance': null,
    'instances': {}
};

function progressReducer (state = initialState, action) {
    let campaignId,
        instance;
    switch (action.type) {
        case 'campaign_progress_page_activate':
            campaignId = action.payload.campaignId;
            instance = objectPath.get(state, `instances.${campaignId}`, state.schema);

            return {
                ...state,
                activeInstance: campaignId,
                instances: {
                    ...state.instances,
                    [campaignId]: {
                        ...instance,
                        campaignId,
                        adId: action.payload.adId
                    }
                }
            };

        case 'campaign_progress_page_loaded':
            const adId = action.payload.adId;
            campaignId = action.payload.campaignId;
            instance = objectPath.get(state, `instances.${campaignId}`, state.schema);

            return {
                ...state,
                // activeInstance: campaignId,
                instances: {
                    ...state.instances,
                    [campaignId]: {
                        ...instance,
                        campaignId,
                        adId,
                        adsMenu: {
                            adsLoading: _.pluck(action.payload.ads, 'id')
                        },
                        statsTable: {
                            isLoading: true
                        }
                    }
                }
            };

        case 'progress_ad_live_stats_recieved':
            campaignId = action.payload.campaignId;
            instance = objectPath.get(state, `instances.${campaignId}`, state.schema);

            const adsFinishedLoading = _.pluck(action.payload.responses, 'adId');
            const adsLoading = objectPath.get(instance, 'adsMenu.adsLoading', []);

            return {
                ...state,
                instances: {
                    ...state.instances,
                    [campaignId]: {
                        ...instance,
                        adsMenu: {
                            adsLoading: _.difference(adsLoading, adsFinishedLoading)
                        },
                        statsTable: {
                            isLoading: _.contains(adsFinishedLoading, (instance.adId || instance.campaignId))
                        }
                    }
                }
            };

        default:
            return state;
    }
}


module.exports = progressReducer;