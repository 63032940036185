'use strict';

var core = require('scripts/core');
var app = core.app;
var editor = require('scripts/widgets/editor');
var ConfirmView = require('scripts/widgets/confirm');
var fns = require('scripts/common/fns');

/**
* a simple interface for requesting data
* @param    {model/collection}  model/collection that is to be fetched
* @param    {id}                id of the model, if the target is a model
* @param    {cid}               id of the collection, if the target is a collection
* @param    {object}            options, passed to the collection/model
* @return   {deferred object}
*/
globalCh.reply('cache', function (name, id, cid, options) {
    if (id != null) {
        return app.cache.getItem.apply(app.cache, arguments);
    } else {
        return app.cache.getCollection.apply(app.cache, arguments);
    }
});

// show and return popup region

// use {backdrop: true} to allow click background to close popup
globalCh.reply('popup', function (options) {
    options = _.extend({
        show    : true,
        keyboard: false,
        backdrop: 'static'}, options);

    $('#popup .modal-dialog').removeClass('modal-lg modal-sm');

    if (options.size === 'large') {
        $('#popup .modal-dialog').addClass('modal-lg');
    }

    var $popup = $('#popup');
    $popup.data('bs.modal').options = options;
    $popup.modal();
    return app.popup;
});


globalCh.on('popup:close', function () {
    $('#popup').modal('hide');
    globalCh.trigger('popup:closed');
});

globalCh.reply('settings:dateformat', function (container) {
    return app.session.get('dateformat');
});

globalCh.reply('file-upload', function (id, files) {

    if (_.isEmpty(files)) {
        return;
    }

    var def = Q.defer();

    var data = {
        files: files
    };

    var fileEle = $('<input type="file" name="asset"/>');

    fileEle.fileupload({
        dataType: 'json',
        crossDomain: true,
        url: 'campaigns/' + id + '/files',
        maxChunkSize: null
    });

    var content = '<div class="upload-progress ">' +
        '<div class="progress" style="width: 100%; height: 20px;">' +
        '<div class="progress-bar"></div></div></div>';

    var uploadProgress = toastr.info(content, 'Uploading files...', {
        timeOut: 10000
    });

    var count = 0, percentage = '0%';
    var success = 0;

    fileEle.bind('fileuploadalways', function () {
        count = count + 1;
        percentage = (count / files.length * 100).toFixed(1) ;
        uploadProgress.find('.upload-progress .progress-bar')
            .css('width', percentage + '%');

        uploadProgress.find('.upload-progress .progress-bar').animateNumber({
            number: percentage,
            numberStep: function (now, tween) {
                var num = (parseFloat(now) + 0.1).toFixed(1);
                if (num > 100) {
                    num = 100;
                }
                var target = $(tween.elem);
                target.text(num + '%');
            }
        });

        if (count === files.length) {
            def.resolve();

            var fail = count - success;
            uploadProgress.find('.upload-progress').append('<div>File upload is done: <br>' +
                success + ' files ' + fns.plural(success) + ' uploaded <br>' +
                fail + ' '  + fns.plural(fail, 'have') +  ' failed</div>');
        }
    });

    fileEle.bind('fileuploaddone', function () {
        success = success + 1;
    });

    fileEle.fileupload('add', data);

    return def.promise;
});


globalCh.reply('upload-notice', function () {
    var template = require('scripts/common/templates/upload-notice');
    return template({
        video_formats: ['mp4'].join(','),
        image_formats: ['jpg/jpeg', 'gif', 'png'].join(', '),
        doc_formats: ['pdf', 'txt', 'doc/docx', 'xsl/xslx'].join(', '),
        size_limit: '20mb'
    });
})


globalCh.reply('query-params', function () {

    // from http://stackoverflow.com/questions/1131630/the-param-inverse-function-in-javascript-jquery
    function queryStringToHash  (query) {
        var query_string = {};
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            pair[0] = decodeURIComponent(pair[0]);
            pair[1] = decodeURIComponent(pair[1]);
            // If first entry with this name
            if (typeof query_string[pair[0]] === "undefined") {
                query_string[pair[0]] = pair[1];
                // If second entry with this name
            } else if (typeof query_string[pair[0]] === "string") {
                var arr = [ query_string[pair[0]], pair[1] ];
                query_string[pair[0]] = arr;
                // If third or later entry with this name
            } else {
                query_string[pair[0]].push(pair[1]);
            }
        }
        return query_string;
    }

    var url = window.location.href;
    var qmpos = url.indexOf('?');

    if (qmpos === -1) {
        return {};
    }

    var query = url.slice(qmpos + 1);
    return queryStringToHash(query);

});


globalCh.reply('filter-state', function (which) {
    return app.session.getFilterState(which);
});

globalCh.comply('save-filter', function (which, state) {
    app.session.saveFilterState(which, state);
});

globalCh.reply('view-pref', function () {
    return app.session.getViewPreference();
});

globalCh.comply('save-view-pref', function (change) {
    return app.session.saveViewPreference(change);
});


// close everything this is like a popup, e.g. popover
globalCh.on('pops:close', function () {
    $('.popover:visible').each(function () {
        var d = $(this).data('bs.popover');
        if (d != null) {
            d.hide();
        }
    });
    globalCh.trigger('popup:close');
});

globalCh.once('app:update:available', function (version) {
    toastr.warning('<p>We\'ve just released an update which contains new features or bug fixes. Reload the page to get the latest version.</p>' +
                   '<button onclick="window.location.reload();" class="btn btn-warning" style="background-color:#F89406">Update now (recommended) </button>',
                    'A new version of EngageFront is available.',
                {
                    tapToDismiss: false,
                    showDuration: 1000,
                    showMethod: 'slideDown',
                    timeOut: 0,
                    extendedTimeOut: 0,
                    positionClass: 'toast-bottom-left'
                });
});

