<table class="table" cellspacing="0" width="100%">
    <thead>
    <tr>
        <th>Name</th>
        <th>Format</th>
        <th>Campaign ID</th>
        <th>Categories</th>
        <th>Overall Status</th>
        <th title='Your own assessment'>Assessment</th>
        <th>Created</th>
        <th></th>
    </tr>
    </thead>

    <tbody>

    </tbody>

</table>
