'use strict';

const initalState = {
    interval: 3 * 60 * 1000
};

const SECOND = 1000;

function refreshReducer (state = initalState, resources, session, activeCampaignId) {
    const ads = objectPath.get(resources, `campaigns.entities.${activeCampaignId}.ads.entities`, {});


    return {
        ...state,
        interval: adsToInterval(ads)
    };
}

function adsToInterval(ads) {
    const numberOfAds = Object.keys(ads).length;
    const interval = numberOfAds * 4 * SECOND;

    if (interval < 20 * SECOND) {
        return 20 * SECOND;
    }

    if (interval > 120 * SECOND) {
        return 120 * SECOND
    }

    return interval;
}

module.exports = refreshReducer;