'use strict';

/**
 * Campaign list view
 *
 */

var app = require('scripts/core').app;
var dashboardTemplate = require('../templates/dashboard');
var fns = require('scripts/common/fns');
var Refresh = require('scripts/common/mixins/refresh');
var categories = require('scripts/common/constants/IAB-categories');
var dashboardCh = Backbone.Radio.channel('dashboard');
var searchTemplate = require('../templates/search');
var LoadingLabel = require('scripts/widgets/loading-label');
var flux = require('scripts/core/flux');

/**
 * delay filter invocation for subsequent key presses
 * @param context
 * @returns {Function}
 */
function invokeSimpleSearch(search) {
    return _.debounce(function () {
        var searchTerm = search.ui.search.val();

        var shouldFire = !search.isShowingAdvanceSearch();
        if (!_.isEmpty(searchTerm)) {
            search.collection.doSimpleSearch(searchTerm, shouldFire);
        } else {
            // if the search val is empty
            // reset the simple search
            search.resetSimpleSearch(shouldFire);
        }
    }, 500);
}

var AdvanceSearchView = Mario.Layout.extend({
    template: searchTemplate,

    templateHelpers: function () {
        var majorCategories = _.reduce(_.keys(categories), function (acc, key) {
            if (/^IAB\d+$/.test(key)) {
                acc[key] = categories[key];
            }
            return acc;
        }, {});

        return {
            organization: app.session.getOrganizationId(),
            categories: _.map(_.sortBy(_.pairs(majorCategories), '1'), _.partial(_.zipObject, ['key', 'val'])),
            state: this.collection.state,
            query_params: this.collection.queryParams
        };
    },

    className: 'search-container',

    initialize: function () {
        this.trySimpleSearch = invokeSimpleSearch(this);
        this.options.ch.comply('reset-search', this.resetSearch, this);
    },

    ui: {
        'setupToggle': '.search-toggle',
        'closeBtn': '.search-box .close',
        'optionStatus': '[data-search-option=status]',
        'optionCategory': '[data-search-option=category]',
        'optionCreativeType': '[data-search-option=creative-type]',
        'advanceSearchBtn': '[data-action=do-advance-search]',
        'setup': '.search-setup',
        'search': '#search-campaigns',
        'resetBtn': '[data-action=reset]',
    },

    events: {
        'keyup @ui.search'      : 'onSimpleSearch',
        'click @ui.setupToggle': 'toggleAdvanceSetup',
        'click @ui.closeBtn': 'hideAdvanceSetup',
        'click @ui.advanceSearchBtn': 'doAdvanceSearch',
        'click @ui.resetBtn': 'resetAdvanceSearch'
    },

    collectionEvents: {
        'refresh:done': 'onRefreshDone'
    },

    onSimpleSearch: function () {
        this.trySimpleSearch();
    },

    onRefreshDone: function () {
        this.updateLabel();
    },

    updateLabel: function () {
        this.options.ch.command('update-search-label');
    },

    onBeforeShow: function () {
        this.ui.optionCategory.select2();
    },

    onShow: function () {
        var that = this;
        $('body').on('click', function (ev) {
            if (!$.contains(that.el, ev.target)) {
                that.hideAdvanceSetup();
            }
        });
    },

    getSearchTerms: function () {

        var terms = {};

        var searchTerm = this.ui.search.val();
        if (!_.isEmpty(searchTerm)) {
            terms.search = searchTerm;
        }

        var status = this.ui.optionStatus.find('option:selected').val();
        if (!_.isEmpty(status)) {
            terms.status = status;
        }

        var category = this.ui.optionCategory.find('option:selected').val();
        if (!_.isEmpty(category)) {
            terms.category = category;
        }

        var creativeType = this.ui.optionCreativeType.find('option:selected').val();
        if (!_.isEmpty(creativeType)) {
            terms.creative_type = creativeType;
        }

        return terms;
    },

    doAdvanceSearch: function () {
        this.hideAdvanceSetup();
        var that = this;
        _.defer(function () {
            that.collection.doAdvanceSearch(that.getSearchTerms(), true);
        });
    },

    showAdvanceSetup: function () {
        this.$el.addClass('show-setup');
    },

    hideAdvanceSetup: function () {
        this.$el.removeClass('show-setup');
    },

    toggleAdvanceSetup: function () {
        this.$el.toggleClass('show-setup');
    },

    resetAdvanceSearch: function () {
        this.resetAdvanceSetup();
        this.hideAdvanceSetup();
        var that = this;
        _.defer(function () {
            that.collection.resetAdvanceSearch(true);
        });
    },

    /**
     * Reset both simple and advance search
     */
    resetSearch: function () {
        this.resetAdvanceSetup();
        this.hideAdvanceSetup();
        this.ui.search.val('');
        this.collection.resetSearch(true);
    },

    resetSimpleSearch: function (fire) {
        this.ui.search.val('');
        this.collection.resetSimpleSearch(fire);
    },

    resetAdvanceSetup: function () {
        // trigger select 2 change event
        this.ui.optionCategory.find(':eq(0)').prop('selected', true).change();
        this.ui.optionStatus.find(':eq(0)').prop('selected', true);
        this.ui.optionCreativeType.find(':eq(0)').prop('selected', true);
    },

    isShowingAdvanceSearch: function () {
        return this.$el.is('.show-setup');
    }
});

var CampaignListView = Marionette.ItemView.extend({
    template: _.template('<div class="campaign-list"></div>'),

    collectionEvents: {
        'refresh:done': function () {
            this.collection.loadCombinedStats();
        }
    },

    initialize: function () {
        var ch = this.options.ch;
        this.listenTo(ch, 'loading:timeout', function(){ this.onLoadingTimeout() });
    },

    onLoadingTimeout: function() {
    },

    onShow: function () {
        var CampaignList = React.createFactory(require('scripts/widgets/list-layout'));
        var Overview = require('./overview');
        var that = this;
        // Show loading label before data refresh
        if (this.el) {
            React.render(React.createFactory(LoadingLabel)({
                displayMessage: 'Loading ...',
                displayPosition: 'top-left',
                displayTimeout: 10000,
                ch: this.options.ch
            }), this.el);
        }
        // TODO brian: move data fetch to router
        // flux.actions.campaigns.refresh();
        this.collection.refresh().then(function () {
            if (that.el) {
                // Remove loading label before rendering content
                React.unmountComponentAtNode(that.el);
            }
            React.render(CampaignList({collection: that.collection, ch: that.options.ch, itemType: 'campaigns', ItemClass: Overview}), that.el);
        });
    }
});

var ToolBarView = Mario.Layout.extend({
    className: 'toolbar  ef-all-campaign',
    template: require('../templates/dashboard-toolbar'),

    ui: {
        'myCampaignsBtn': '[data-owner=self]',
        'otherCampaignsBtn': '[data-owner=other]'
    },

    events: {
        'click @ui.myCampaignsBtn': 'showMyCampaigns',
        'click @ui.otherCampaignsBtn': 'showOtherCampaigns'
    },

    regions: {
        'searchRegion': '.search-region',
        'refreshButtonRegion': '.refresh-button-region'
    },

    showMyCampaigns: function () {
        this.collection.doFilter({owner: 'self'}, true);
        this.ui.myCampaignsBtn.addClass('active').siblings('.active').removeClass('active');
    },

    showOtherCampaigns: function () {
        this.collection.doFilter({owner: 'other'}, true);
        this.ui.otherCampaignsBtn.addClass('active').siblings('.active').removeClass('active');
    },

    onBeforeShow: function () {
        this.searchRegion.show(new AdvanceSearchView({collection: this.collection, ch: this.options.ch}));
        var RefreshButton = React.createFactory(require('scripts/widgets/refresh-button'));

        React.render(RefreshButton({collection: this.collection}), this.$('.refresh-button-region').get(0));
    },

    onDestroy: function () {
        React.unmountComponentAtNode(this.$('.refresh-button-region').get(0));
    },

    templateHelpers: function () {
        return {
            can_create_campaign: app.can('create', 'campaigns'),
            state: this.collection.state,
            query_params: this.collection.queryParams
        };
    }
});

var SearchLabelView = Mario.ItemView.extend({
    template: require('../templates/search-label'),

    className: 'well well-sm search-label',

    ui: {
        'resetSearchBtn': '.reset'
    },

    events: {
        'click @ui.resetSearchBtn': 'resetSearch'
    },

    initialize: function () {
        this._searchObj = {};
        this.options.ch.comply('update-search-label', this.updateSearch, this);
    },

    resetSearch: function () {
        this.hide();
        this.options.ch.command('reset-search');
    },

    updateSearch: function () {
        this._searchObj = _.omit(this.collection.getSearchTerms(), 'owner');

        if (_.isEmpty(this._searchObj)) {
            this.hide();
        } else {
            this.render();
            this.show();
        }
    },

    hide: function () {
        this.$el.hide();
    },

    onBeforeShow: function () {
        this.updateSearch();
    },

    show: function () {
        this.$el.show();
    },

    templateHelpers: function () {
        if (_.has(this._searchObj, 'category')) {
            this._searchObj.category = categories[this._searchObj.category];
        }

        return {
            number_of_records: ~~this.collection.state.totalRecords,
            search_terms: this._searchObj
        }
    }

});


var Dashboard = Mario.Layout.extend({
    template: dashboardTemplate,
    className: 'dashboard-container',
    regions: {
        'toolbarRegion': '.toolbar-region',
        'campaignListRegion': '.campaign-list-region',
        'searchLabelRegion': '.search-label-region'
    },

    onBeforeShow: function () {
        this.toolbarRegion.show(new ToolBarView({collection: this.collection, ch: this.options.ch}));
        this.campaignListRegion.show(new CampaignListView({collection: this.collection, ch: this.options.ch}));
        this.searchLabelRegion.show(new SearchLabelView({collection: this.collection, ch: this.options.ch}));

        var that = this;
        this.options.ch.reply('organizations', function () {return that.options.organizations;});
        this.options.ch.on('dashboard:pagesize:changed', function (size) {
            that.collection.setState('pageSize', size);
        });
    }
});

module.exports = Dashboard;
