'use strict';
var op = objectPath;
var ProgressCalculator = require('scripts/common/progress-calculator');

var initialState = {
    'header': {
        'title': '',
        'link': '/'
    },
    'ads': [],
    'deps': {}
};


function adsMenuReducer (state = initialState, resources, session, campaignId, adsHealth) {
    const adsResource = op.get(resources, `campaigns.entities.${campaignId}.ads`, {});
    const progressSession = op.get(session, `campaigns.progress.instances.${campaignId}`, {});

    const { adId, adsMenu } = progressSession;

    const adsFormatted = _(adsResource.entities)
        .map((entity) => {
            const entityId = entity.attr.id;

            const health = op.get(adsHealth, `${entityId}.health`, null);

            return {
                id: entityId,
                selected: String(adId) === String(entityId),
                name: `#${entityId} ${entity.attr.name}`,
                link: `/campaigns/${campaignId}/delivery/${entityId}`,
                health: health,
                isLoading: _.contains(adsMenu.adsLoading, entityId)
            };
        })
        .sortBy('id')
        .value();

    return {
        ...state,
        header: {
            ...state.header,
            title: 'Campaign Overall Progress',
            link: `/campaigns/${campaignId}/delivery`,
            selected: !adId
        },
        ads: adsFormatted,
        deps: {
            ...state.deps
        }
    };
}


module.exports = adsMenuReducer;