'use strict';

var CustomFluxMixin = require('scripts/common/mixins/CustomFluxMixin');

var AccountingPage = React.createClass({

    mixins: [
        CustomFluxMixin({
            'onOrganizationsStoreChanged': 'organizations',
            'onSpendStatsStoreChanged': 'spend-stats'
        })
    ],

    getInitialState () {
        var months = this.getMonthDropdownItems();
        var currentMonth = months[0];
        return {
            stats: this.getStatsForSelectedMonth(currentMonth),
            organizationalProfile: this.flux.store('organizations').getOwnOrganization(),
            monthDropdownItems: months,
            selectedMonth: currentMonth
        };
    },

    onOrganizationsStoreChanged () {
        this.setState({
            monthDropdownItems: this.getMonthDropdownItems(),
            organizationalProfile: this.flux.store('organizations').getOwnOrganization()
        });
    },

    getStatsForSelectedMonth (month) {
        var regex = new RegExp(month.yearMonthIdentifier);
        return this.flux
            .store('spend-stats')
            .getSpendStats()
            .filter(stat => regex.test(stat.date));
    },

    getMonthDropdownItems () {
        var organization = this.flux.store('organizations').getOwnOrganization();

        var start = moment(organization._created).startOf('month');
        var end   = moment();
        var range = moment.range(start, end);

        var list = [];

        range.by('months', function (time) {
            list.push({
                yearMonthIdentifier: time.format('YYYY-MM'),
                display: time.format('MMMM, YYYY')
            });
        });

        return list.reverse();
    },

    handleMonthSelection (month) {
        var list = this.getStatsForSelectedMonth(month);

        this.setState({
            stats: list,
            selectedMonth: month
        });

        $(this.getDOMNode())
            .find('.dropdown')
            .removeClass('open');
    },

    onSpendStatsStoreChanged () {
        this.setState({
            stats: this.getStatsForSelectedMonth(this.state.selectedMonth)
        });
    },

    render () {

        var monthlyCredit = this.state.organizationalProfile.credit_limit;
        var totalAddictiveBillingRate = this.state.stats.reduce(sumWith('addictive_billing_rate'), 0).toFixed(2);
        var totalPrice = this.state.stats.reduce(sumWith('price'), 0).toFixed(2);
        var totalAmount = 0;
        var remainingCreditLimit;

        totalAddictiveBillingRate = '$' + numberWithCommas(totalAddictiveBillingRate);
        totalPrice = '$' + numberWithCommas(totalPrice);

        var tableBodyMarkup = this.state.stats.map(stat => {
            var spend = numberWithCommas((stat.total_cost) / 1000);
            var price = numberWithCommas(stat.price / 1000);
            var addictive_billing_rate = numberWithCommas(stat.addictive_billing_rate/ 1000);
            totalAmount += (stat.total_cost);
            return (
                <tr key={stat._id}>
                    <td>{stat.date}</td>
                    <td>${spend}</td>
                </tr>
            );
        });

        if (monthlyCredit === -1) {
            monthlyCredit = 'Unlimited';
            remainingCreditLimit = 'Unlimited';
        } else {
            remainingCreditLimit = '$' + numberWithCommas(Number(monthlyCredit) - Number(totalAmount / 1000));

            monthlyCredit = '$' + numberWithCommas(monthlyCredit);
        }

        totalAmount = '$' + numberWithCommas(totalAmount / 1000);

        return (

            <div className="am-accounting">

                <h1 className="am-accounting-header">Account</h1>

                <div className="am-accounting-control">
                    <div className="am-accounting-control-monthSelector dropdown">
                        <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown">
                            <i className="fa fa-calendar"></i>
                            <span className="am-accounting-control-monthSelector-monthName">{this.state.selectedMonth.display}</span>
                            <span className="caret"></span>
                        </button>
                        <ul className="dropdown-menu" role="menu">
                            {this.state.monthDropdownItems.map((month, index) => {
                                return (
                                    <li role="presentation" key={index}>
                                        <a role="menuitem" tabIndex="-1" href="#" onClick={this.handleMonthSelection.bind(this, month)}>{month.display}</a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>

                <hr/>

                <div className="am-accounting-content">

                    <div className="am-accounting-content-sidebar">
                        <h4>Summary for {this.state.selectedMonth.display}</h4>
                        <div className="am-accounting-content-sidebar-content">
                            <table>
                                <tr>
                                    <th>Monthly Credit Limit</th>
                                    <td>{monthlyCredit}</td>
                                </tr>
                                <tr>
                                    <th>Amount Due</th>
                                    <td>{totalAmount}</td>
                                </tr>
                                <tr>
                                    <th>Remaining Credit Limit</th>
                                    <td>{remainingCreditLimit}</td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div className="am-accounting-content-main">
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableBodyMarkup}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total Spend</td>
                                    <td>{totalAmount}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

            </div>

        );
    }
});

function numberWithCommas (x) {
    var x = parseFloat(x || 0).toFixed(2);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function sumAddictiveBillingRate (sum, a) {
    var aSpend = (a.addictive_billing_rate / 1000);
    return sum + aSpend;
}

function sumWith (field) {
    return function (sum, obj) {
        return sum + (obj[field] / 1000);
    };
}


module.exports = AccountingPage;
