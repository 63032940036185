<div class="row clearfix">

    <div class="col-sm-12">
        <div class="pull-left">
            <h2>Cumulative Fill <small><label class="label label-success label-sm">realtime</label></small></h2>
            <div class="flight"></div>
        </div>
        <div class="pull-right">
            <button title="Refresh now" class="toolbar-refresh btn btn-link pull-right" style="margin-top:15px;">
                <i class="fa fa-refresh"></i>
                <small>Updated a few seconds ago</small>
            </button>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-xs-12 col-md-9 graph-cumulative-fill">
    </div>
    <div class="col-xs-12 col-md-3">
        <ul class="progress-list list-unstyled">
            <li data-id="{{id}}" class="row">
                <div class="col-xs-12">
                    Overall Progress
                </div>
            </li>
            <li class="ad-list-region row"></li>
        </ul>
    </div>
    <div class="col-xs-12 hourly-graph-region-wrapper" style="display:none">
        <div class="hourly-graph-region" ></div>
        <btn class="hourly-graph-hide-btn"><i class="fa fa-times"></i></btn>
    </div>
</div>

<hr/>
<div class="row">

    <div class="col-md-6 col-md-push-6 progress-bullet-graphs-region"></div>

    <!--data table-->
    <div class="col-md-6 col-md-pull-6 progress-data-table">

        <div class="row">
            <div class="col-xs-12 progress-data-table-title">
                <div class="data-title">Campaign Overall Stats</div>
                <div class="button-wrap">
                    <button class="btn btn-default" type="submit"
                        data-container="body"
                        data-toggle="popover"
                        data-placement="left"
                        data-content="hello world"
                        data-html="true"
                    >Summary</button>
                </div>
            </div>
        </div>

        <div class="row summary">
            <div class="col-xs-6">
                <div class="inner">
                    <div>Impressions</div>
                    <div class="total-impressions number"></div>
                </div>
            </div>
            <div class="col-xs-3">
                <div class="inner">
                    <div>Clicks</div>
                    <div class="total-clicks number"></div>
                </div>
            </div>
            <div class="col-xs-3">
                <div class="inner">
                    <div>CTR</div>
                    <div class="total-ctr number"></div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <table class="table table-condensed table-right table-hover daily-stats">
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Impressions</th>
                        <th>Clicks</th>
                        <th>CTR</th>
                    </tr>
                    </thead>
                    <tbody></tbody>
                    <tfoot></tfoot>
                </table>
            </div>
        </div>

    </div>
</div>
