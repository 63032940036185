export default class ProgressCalculator {

    constructor (properties) {
        this.start = properties.start._isAMomentObject ?
            properties.start.toDate() :
            new Date(properties.start);

        this.end = properties.end._isAMomentObject ?
            properties.end.toDate() :
            new Date(properties.end);

        this.weekparts = properties.weekparts || [];
        this.dayparts = properties.dayparts || [];

        this.max_total_impressions = properties.max_total_impressions || 0;
        this.max_daily_impressions = properties.max_daily_impressions || 0;
        this.max_total_clicks = properties.max_total_clicks || 0;
        this.max_daily_clicks = properties.max_daily_clicks || 0;
        this.max_total_spend = properties.max_total_spend || 0;
        this.max_daily_spend = properties.max_daily_spend || 0;

        this._cache = {};
    }

    getGoal () {
        // In the future, ads may only have 1 goal: either impressions, clicks or spend
        // To support legacy ads which have multiple goals set, the goal used for visualization
        // will take the following precedence: impressions > clicks > spend
        switch (true) {
        case (this.max_total_impressions > 0 || this.max_daily_impressions > 0):
            return 'impressions';
        case (this.max_total_clicks > 0 || this.max_daily_clicks > 0):
            return 'clicks';
        case (this.max_total_spend > 0 || this.max_daily_spend > 0):
            return 'spend';
        }
    }

    hasTotalTarget () {
        const goal = this.getGoal();
        return (this[`max_total_${goal}`] > 0);
    }

    hasDailyCap () {
        const goal = this.getGoal();
        return (this[`max_daily_${goal}`] > 0);
    }

    // Returns array of 7 booleans representing whether the ad is active that day
    // Indexed by ISO day of week number
    // e.g. Weekdays only: [false, true, true, ture, true, true, false]
    _getIsoWeekparts () {
        if (!this.weekparts.length) {
            return [true, true, true, true, true, true, true];
        }
        const [monday, tuesday, wednesday, thursday, friday, saturday, sunday] = this.weekparts;
        return [sunday, monday, tuesday, wednesday, thursday, friday, saturday];
    }

    // Date information is dropped here
    // It is assumed that the start and end times are on the same date
    _getActiveSecondsOfTimeRangeInSameDay ({ start, end }) {
        if (!this.dayparts.length) {
            return ProgressCalculator.getNumberOfSecondsInRange({ start, end });
        }

        // Use an abritrary date
        // The date is not important, we're just using to intersect the time
        const arbitraryDate = new Date();

        const rangeStart = new Date(arbitraryDate);
        rangeStart.setUTCHours(start.getUTCHours());
        rangeStart.setUTCMinutes(start.getUTCMinutes());
        rangeStart.setUTCSeconds(start.getUTCSeconds());
        rangeStart.setUTCMilliseconds(0);

        const rangeEnd = new Date(arbitraryDate);
        rangeEnd.setUTCHours(end.getUTCHours());
        rangeEnd.setUTCMinutes(end.getUTCMinutes());
        rangeEnd.setUTCSeconds(end.getUTCSeconds());
        rangeEnd.setUTCMilliseconds(0);

        // `start` and `end` are reusable date objects
        // to be used for intersection only
        const daypartStart = new Date(arbitraryDate);
        const daypartEnd = new Date(arbitraryDate);

        // Dayparting is an array of start/end pairs formatted as a string
        // e.g. for 10AM-12:30PM + 3:45PM-5:00PM
        // [
        //     { start: '10:00:00', end: '12:30:00' },
        //     { start: '15:45:00', end: '17:00:00' },
        // ]
        const activeSeconds = this.dayparts.reduce((accumulatedSeconds, daypart) => {
            const startHour = parseInt(daypart.start.slice(0, 2));
            const startMinute = parseInt(daypart.start.slice(3, 5));
            const startSecond = parseInt(daypart.start.slice(6, 8));
            daypartStart.setUTCHours(startHour);
            daypartStart.setUTCMinutes(startMinute);
            daypartStart.setUTCSeconds(startSecond);

            const endHour = parseInt(daypart.end.slice(0, 2));
            const endMinute = parseInt(daypart.end.slice(3, 5));
            const endSecond = parseInt(daypart.end.slice(6, 8));
            daypartEnd.setUTCHours(endHour);
            daypartEnd.setUTCMinutes(endMinute);
            daypartEnd.setUTCSeconds(endSecond);

            const activePart = ProgressCalculator.getIntersection(
                { start: rangeStart, end: rangeEnd },
                { start: daypartStart, end: daypartEnd }
            );
            if (!activePart) {
                return accumulatedSeconds;
            }
            const secondsInActivePart = ProgressCalculator.getNumberOfSecondsInRange(activePart);

            return accumulatedSeconds + secondsInActivePart;
        }, 0);

        return activeSeconds;
    }

    // Similar to ProgressCalculator#_getActiveSecondsOfTimeRangeInSameDay,
    // but assumes a "typical" day, where the range start and end are
    // 00:00:00.000 and 23:59:59.999, so some calculations can be done more efficiently
    _getActiveSecondsOfTypicalActiveDay () {
        if (this._cache.secondsOfTypicalActiveDay) {
            return this._cache.secondsOfTypicalActiveDay;
        }

        if (!this.dayparts.length) {
            return 24 * 60 * 60;
        }

        // `start` and `end` are reusable date objects
        // to be used for intersection only
        // Use an abritrary date
        // The date is not important, we're just using to intersect the time
        const start = new Date();
        const end = new Date();

        // Dayparting is an array of start/end pairs formatted as a string
        // e.g. for 10AM-12:30PM + 3:45PM-5:00PM
        // [
        //     { start: '10:00:00', end: '12:30:00' },
        //     { start: '15:45:00', end: '17:00:00' },
        // ]
        const activeSeconds = this.dayparts.reduce((accumulatedSeconds, daypart) => {
            const startHour = parseInt(daypart.start.slice(0, 2));
            const startMinute = parseInt(daypart.start.slice(3, 5));
            const startSecond = parseInt(daypart.start.slice(6, 8));
            start.setUTCHours(startHour);
            start.setUTCMinutes(startMinute);
            start.setUTCSeconds(startSecond);

            const endHour = parseInt(daypart.end.slice(0, 2));
            const endMinute = parseInt(daypart.end.slice(3, 5));
            const endSecond = parseInt(daypart.end.slice(6, 8));
            end.setUTCHours(endHour);
            end.setUTCMinutes(endMinute);
            end.setUTCSeconds(endSecond);

            const secondsInDaypart = ProgressCalculator.getNumberOfSecondsInRange({ start, end });
            return accumulatedSeconds + secondsInDaypart;
        }, 0);

        this._cache.secondsOfTypicalActiveDay = activeSeconds;

        return activeSeconds;
    }

    _getTotalActiveSecondsOfRange (range) {
        const { start: rangeStart, end: rangeEnd } = range;

        const weekparts = this._getIsoWeekparts();

        const typicalActiveSeconds = this._getActiveSecondsOfTypicalActiveDay();

        const flightDays = ProgressCalculator.getDayIterator(range);
        const lastFlightDayIndex = flightDays.length - 1;

        const totalActiveSeconds = flightDays.reduce((accumulator, flightDay, index) => {
            const dayOfWeek = flightDay.getUTCDay();
            const dayIsActive = weekparts[dayOfWeek];
            if (!dayIsActive) {
                return accumulator;
            }

            // Special case for first day and last day which are atypical because
            // they could be incomplete and therefore require additional intersection
            if (index === 0) {
                const firstDay = {
                    start: rangeStart,
                    end: ProgressCalculator.getUtcEndOfDay(rangeStart)
                };
                const projectablePartOfFirstDay = ProgressCalculator.getIntersection(range, firstDay);
                if (!projectablePartOfFirstDay) {
                    return accumulator;
                }
                return accumulator + this._getActiveSecondsOfTimeRangeInSameDay(projectablePartOfFirstDay);

            } else if (index === lastFlightDayIndex) {
                const lastDayRange = {
                    start: ProgressCalculator.getUtcStartOfDay(rangeEnd),
                    end: rangeEnd
                };
                return accumulator + this._getActiveSecondsOfTimeRangeInSameDay(lastDayRange);

            // Case for typical days
            // Typical day = any day which is not the start or end date
            } else {
                return accumulator + typicalActiveSeconds;
            }
        }, 0);

        return totalActiveSeconds;
    }

    getIdealPacedFillForEndOfSecond (projectionDate) {
        if (!this.hasTotalTarget()) {
            return null;
        }

        let secondsTotal;
        if (this._cache.totalActiveSeconds) {
            secondsTotal = this._cache.totalActiveSeconds;
        } else {
            const flightRange = {
                start: this.start,
                end: this.end
            };
            secondsTotal = this._getTotalActiveSecondsOfRange(flightRange);
            this._cache.totalActiveSeconds = secondsTotal;
        }

        const projectionRange = {
            start: this.start,
            end: projectionDate
        };
        let secondsElapsed = this._getTotalActiveSecondsOfRange(projectionRange);
        secondsElapsed = Math.min(secondsElapsed, secondsTotal);

        const goal = this.getGoal();
        const idealPacedFill = this[`max_total_${goal}`] * (secondsElapsed / secondsTotal);
        return idealPacedFill;
    }

    getPacedFillHealthForEndOfSecond (projectionDate, projectionDateFill) {
        if (!this.hasTotalTarget()) {
            return null;
        }
        if (projectionDate.valueOf() < this.start.valueOf()) {
            return 'pending';
        }

        const idealFilled = this.getIdealPacedFillForEndOfSecond(projectionDate);

        const goal = this.getGoal();
        const idealRemaining = this[`max_total_${goal}`] - idealFilled;

        const goodFillThreshold = idealFilled - (idealRemaining * 0.1);
        const okFillThreshold = idealFilled - (idealRemaining * 0.2);

        if (projectionDateFill >= goodFillThreshold) {
            return 'good';
        }

        if (projectionDateFill >= okFillThreshold) {
            return 'ok';
        }

        return 'bad';
    }

    getIdealUnpacedFillForEndOfSecond (projectionDate, yesterday, yesterdayFilled) {
        if (!this.hasDailyCap()) {
            return null;
        }

        const startOfYesterday = ProgressCalculator.getUtcStartOfDay(yesterday);
        const startOfToday = new Date(startOfYesterday.valueOf() + (24 * 60 * 60 * 1000));

        // If the ad has started, project from today onwards;
        // If the ad is scheduled/pending, project from start day onwards.
        const startOfProjectableRange = new Date(Math.max(this.start, startOfToday));

        const endOfEndDate = ProgressCalculator.getUtcEndOfDay(this.end);

        if (projectionDate.valueOf() < startOfProjectableRange.valueOf()) {
            return null;
        }

        const goal = this.getGoal();
        const weekparts = this._getIsoWeekparts();

        const projectableDays = ProgressCalculator.getDayIterator({ start: startOfProjectableRange, end: endOfEndDate });
        const projectedUnpacedFill = projectableDays.reduce((accumulator, flightDay) => {
            if (flightDay.valueOf() > projectionDate.valueOf()) {
                return accumulator;
            }
            const dayOfWeek = flightDay.getUTCDay();
            const dayIsActive = weekparts[dayOfWeek];
            if (!dayIsActive) {
                return accumulator;
            }
            return accumulator + this[`max_daily_${goal}`];
        }, yesterdayFilled);

        return projectedUnpacedFill;
    }
}


ProgressCalculator.getDayIterator = ({ start, end }) => {
    // Use start of start date and end of end date
    // in order to iterate through incomplete UTC days
    const startOfStartDate = ProgressCalculator.getUtcStartOfDay(start);
    const endOfEndDate = ProgressCalculator.getUtcEndOfDay(end);

    const days = [];
    let date = startOfStartDate;
    while (date.valueOf() < endOfEndDate.valueOf()) {
        days.push(date);
        date = new Date(date.valueOf() + (24 * 60 * 60 * 1000));
    }

    return days;
};


ProgressCalculator.getIntersection = ({ start: start1, end: end1 }, { start: start2, end: end2 }) => {
    if ((start1 > end1) || (start2 > end2)) {
        throw new Error('Ranges not correct');
    }
    if ((end1 < start2 && end1 < end2) || (end2 < start1 && end2 < end1)) {
        return null;
    }
    var maxStart = Math.max(start1.valueOf(), start2.valueOf());
    var minEnd = Math.min(end1.valueOf(), end2.valueOf());
    return {
        start: new Date(maxStart),
        end: new Date(minEnd)
    };
};


ProgressCalculator.getNumberOfSecondsInRange = ({ start, end }) => {
    const rangeStartUnix = Math.floor(start.valueOf() / 1000);
    const rangeEndUnix = Math.floor(end.valueOf() / 1000);
    return (rangeEndUnix - rangeStartUnix) + 1;
};


ProgressCalculator.getUtcStartOfDay = (date) => {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();

    // year, month, day, hour, minute, second, millisecond
    return new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
};

ProgressCalculator.getUtcEndOfDay = (date) => {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();

    // year, month, day, hour, minute, second, millisecond
    return new Date(Date.UTC(year, month, day, 23, 59, 59, 0));
};