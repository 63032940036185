'use strict';

var resourcesReducer = require('./resources'),
    sessionReducer = require('./session'),
    uiReducer = require('./ui');


function rootReducer (state = {}, action = {}) {

    var resources = resourcesReducer(state.resources, action),
        session = sessionReducer(state.session, action);


    var ui = uiReducer(state.ui, resources, session);

    return { resources, session, ui };
}

window.rootReducer = rootReducer;


module.exports = rootReducer;