'use strict';

var Popup = require('scripts/widgets/popup');
var Status = require('scripts/widgets/status');
var CopyButton = require('scripts/widgets/copy-button');
var Detail = React.createClass({

    componentDidMount() {
        this._originalOverflowY = $('html').css('overflowY');

        if (this.props.show == true) {
            $('html').css({overflowY: 'hidden'});
            var $el = $(this.getDOMNode());
            var that = this;
            $el.on('click', '.inner-wrapper, .inner-wrapper > .row', function (ev) {
                    if (ev.currentTarget === ev.target) {
                        that.props.onClose();
                    }
                }
            );
            var $el = $(this.getDOMNode());
            $el.find('.url').each(function () {
                $(this).html(`<a href=${$(this).text()} target="blank" data-bypass>${$(this).text()}</a>`);
            })
        }
    },

    componentWillUnmount() {
        $('html').css({overflowY: this._originalOverflowY});
        var $el = $(this.getDOMNode());
        $el.off();
    },


    render() {

        var m = this.props.creative;
        var show = this.props.show;
        var onClose = this.props.onClose;

        if (show === false) {
            return null;
        }

        return (
            <div className="creative-detail">
                <dl className="row" data-format={m.get('format')}>


                    <dt className="visible-video visible-standard visible-custom_html visible-mraid visible-native col-sm-4 col-lg-2">
                        <div className="hidden-sm hidden-md hidden-lg">Format</div>
                        <div className="hidden-xs text-right">Format</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10">{m.get('format')}</dd>
                    <div className="clearfix"/>



                    <dt className="visible-video visible-standard visible-custom_html visible-mraid col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Size</div>
                        <div className="hidden-xs text-right">Size</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10">{m.get('size')}</dd>
                    <div className="clearfix"/>



                    <dt className="visible-standard col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Banner URL</div>
                        <div className="hidden-xs text-right">Banner URL</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10 url">
                        <a data-bypass="true" href={m.get('image_url')} target="_blank">
                            {m.get('image_url')}
                        </a>
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-standard col-sm-4 col-lg-2">
                        <div className="hidden-sm hidden-md hidden-lg">Banner Preview URL</div>
                        <div className="hidden-xs text-right">Banner Preview URL</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10 url">
                        <a data-bypass="true" href={m.get('image_preview_url')} target="_blank">
                            {m.get('image_preview_url')}
                        </a>
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-native col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Icon Input URL</div>
                        <div className="hidden-xs text-right">Icon Input URL</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10 url">
                        <a data-bypass="true" href={m.get('icon_image_url')} target="_blank">
                            {m.get('icon_image_url')}
                        </a>
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-native col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Icon Preview URL</div>
                        <div className="hidden-xs text-right">Icon Preview URL</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10 url">
                        <a data-bypass="true" href={m.get('icon_image_preview_url')} target="_blank">
                            {m.get('icon_image_preview_url')}
                        </a>
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-native col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Main Image URL</div>
                        <div className="hidden-xs text-right">Main Image URL</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10 url">
                        <a data-bypass="true" href={m.get('main_image_url')} target="_blank">
                            {m.get('main_image_url')}
                        </a>
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-native col-sm-4 col-lg-2">
                        <div className="hidden-sm hidden-md hidden-lg">Main Image Preview URL</div>
                        <div className="hidden-xs text-right">Main Image Preview URL</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10 url">
                        <a data-bypass="true" href={m.get('main_image_preview_url')} target="_blank">
                            {m.get('main_image_preview_url')}
                        </a>
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-video col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Video URL</div>
                        <div className="hidden-xs text-right">Video URL</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10 url">
                        <a data-bypass="true" href={m.get('video_url')} target="_blank">
                            {m.get('video_url')}
                        </a>
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-video col-sm-4 col-lg-2">
                        <div className="hidden-sm hidden-md hidden-lg">Video Preview URL</div>
                        <div className="hidden-xs text-right">Video Preview URL</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10 url">
                        <a data-bypass="true" href={m.get('video_preview_url')} target="_blank">
                            {m.get('video_preview_url')}
                        </a>
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-video col-sm-4 col-lg-2">
                        <div className="hidden-sm hidden-md hidden-lg">Reference Screenshot</div>
                        <div className="hidden-xs text-right">Reference Screenshot</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10 url">
                        <a data-bypass="true" href={m.get('image_preview_url')} target="_blank">
                            {m.get('image_preview_url')}
                        </a>
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-video col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Video Duration (sec)</div>
                        <div className="hidden-xs text-right">Video Duration (sec)</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10">{m.get('video_duration')}</dd>
                    <div className="clearfix"/>



                    <dt className="visible-video col-sm-4 col-lg-2">
                        <div className="hidden-sm hidden-md hidden-lg">Companion Banner Url</div>
                        <div className="hidden-xs text-right">Companion Banner Url</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10 url">
                        <a data-bypass="true" href={m.get('companion_url')} target="_blank">
                            {m.get('companion_url')}
                        </a>
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-video col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Companion Banner Preview Url</div>
                        <div className="hidden-xs text-right">Companion Banner Preview Url</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10 url">
                        <a data-bypass="true" href={m.get('companion_preview_url')} target="_blank">
                            {m.get('companion_preview_url')}
                        </a>
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-custom_html visible-mraid col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Content HTML</div>
                        <div className="hidden-xs text-right">Content HTML</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10">
                        <div className="copy-button-container">
                            <pre>
                                <code>{m.get('content_html')}</code>
                            </pre>
                            <CopyButton text={m.get('content_html')}>
                                <button className="copy-button">Copy</button>
                            </CopyButton>
                        </div>
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-custom_html visible-mraid col-sm-4 col-lg-2">
                        <div className="hidden-sm hidden-md hidden-lg">Reference Screenshot</div>
                        <div className="hidden-xs text-right">Reference Screenshot</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10 url">
                        <a data-bypass="true" href={m.get('reference_image_preview_url')} target="_blank">
                            {m.get('reference_image_preview_url')}
                        </a>
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-native col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Ad Title</div>
                        <div className="hidden-xs text-right">Ad Title</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10">{m.get('title')}</dd>
                    <div className="clearfix"/>



                    <dt className="visible-native col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Main Text</div>
                        <div className="hidden-xs text-right">Main Text</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10">{m.get('text')}</dd>
                    <div className="clearfix"/>



                    <dt className="visible-native col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Call To Action</div>
                        <div className="hidden-xs text-right">Call To Action</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10">{m.get('call_to_action')}</dd>
                    <div className="clearfix"/>



                    <dt className="visible-native col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Star Rating</div>
                        <div className="hidden-xs text-right">Star Rating</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10">{m.get('star_rating')}</dd>
                    <div className="clearfix"/>



                    <dt className="visible-standard visible-video visible-custom_html visible-mraid visible-native col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Click Through URL</div>
                        <div className="hidden-xs text-right">Click Through URL</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10 url">
                        <a data-bypass="true" href={m.get('clickthrough_url')} target="_blank">
                            {m.get('clickthrough_url')}
                        </a>
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-standard visible-video visible-custom_html visible-mraid visible-native col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Landing Page URL</div>
                        <div className="hidden-xs text-right">Landing Page URL</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10 url">
                        <a data-bypass="true" href={m.get('landing_page')} target="_blank">
                            {m.get('landing_page')}
                        </a>
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-standard visible-video visible-custom_html visible-mraid visible-native  col-sm-4 col-lg-2" >
                        <div className="hidden-sm hidden-md hidden-lg">Third Party Pixels</div>
                        <div className="hidden-xs text-right">Third Party Pixels</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10">
                        {m.get('third_party_pixels').map( (p)=>
                            <div key={_.uniqueId()} className="url">
                                <a data-bypass="true" href={p} target="_blank">
                                    {p}
                                </a>
                            </div>
                        )}
                    </dd>
                    <div className="clearfix"/>



                    <dt className="visible-video visible-standard visible-custom_html visible-mraid visible-native col-sm-4 col-lg-2">
                        <div className="hidden-sm hidden-md hidden-lg">Audit Status</div>
                        <div className="hidden-xs text-right">Audit Status</div>
                    </dt>
                    <dd className="col-sm-8 col-lg-10">
                        <Status status={m.get('audit_status')}/>
                    </dd>
                </dl>
            </div>
        )
    }
});

module.exports = Detail;
