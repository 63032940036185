'use strict';

var flux = require('scripts/core/flux');

var BarGraph = React.createClass({

    propTypes: {
        title: React.PropTypes.string,

        groups: React.PropTypes.array,

        isLoading: React.PropTypes.bool,

        onReset: React.PropTypes.func,

        onCheckboxSelected: React.PropTypes.func
    },

    getInitialState () {
        return {
            randomId: _.random(99999)
        };
    },

    getCheckboxSelections () {
        return $(this.getDOMNode()).find('form')
            .serializeArray()
            .map(f => f.name);
    },

    handleChange () {
        this.props.onCheckboxSelected(this.getCheckboxSelections());
    },

    render () {
        var self = this;

        var someSelected = _.some(this.props.groups, { selected: true });

        return (
            <div>
                <div className="am-reportTile-header">
                    {someSelected ? <a href="#" className="am-reportTile-reset" onClick={this.props.onReset}>Reset</a> : null}
                    <h5>{this.props.title} {this.props.isLoading ? <i className="fa fa-spinner fa-spin"></i> : null}</h5>
                </div>
                <form className="am-distributionTable">
                    {this.props.groups.map((group, index) =>
                    <label
                        htmlFor={group.name + self.state.randomId}
                        className={ 'am-distributionTable-row '+group.name}
                        key={index}
                        title={group.presentationName}>
                            <div className="am-distributionTable-row-item am-distributionTable-row-checkbox">
                                <input
                                    id={group.name + self.state.randomId}
                                    name={group.name}
                                    checked={group.selected}
                                    type="checkbox"
                                    onChange={self.handleChange} />
                            </div>
                            <div className="am-distributionTable-row-item am-distributionTable-row-key">
                                {group.presentationName}
                            </div>
                            <div className="am-distributionTable-row-item am-distributionTable-row-barGraph">
                                <div className="am-htmlBarGraph" >
                                    <div className="am-htmlBarGraph-bar" style={{width:group.barGraphLength+'%'}}></div>
                                </div>
                            </div>
                            <div className="am-distributionTable-row-item am-distributionTable-row-value">
                                {group.statsMetrics}
                            </div>
                    </label>)}
                </form>
            </div>
        );
    }
});


module.exports = BarGraph;
