<div class="my-organization-creatives col-xs-12">
    <div>
        <label>Creatives enabled: </label>
    </div>
    <div>
        {{#each creatives}}
            <span>
                <i class="fa fa-check"></i>
                {{humanize format}}</span>
        {{/each}}
    </div>
</div>
