'use strict';
var Popup = require('scripts/widgets/popup');
var HistoryWidget = require('scripts/widgets/history');
var AdSummary = require('scripts/components/campaigns/parts/ads/ad-summary');
var PrivateChannel = require('scripts/common/mixins/private-channel');

var {
    formatTimezoneAbbr,
    formatDate,
    formatWeekparts,
    formatDayparts,
    formatTargetAppCats,
    formatGeoCountry,
    formatGeoBoxes ,
    formatAgeGroup,
    formatDeviceLanguage,
    formatTargetGenders} = AdSummary;

var HistoryView = React.createClass({
    mixins: [PrivateChannel],
    getInitialState() {
        return {
            data: {}
        };
    },

    componentDidMount() {
        this.props.ad.getHistory().then((history)=>this.setState({data: history})).catch(toastr.error);
        this.privateCh.listenTo(this.props.ch, 'popup:close', ()=>this.props.onClose());
    },

    render() {
        var data = this.state.data;
        var ad = this.props.ad;4185

        if (_.isEmpty(data)) {
            return null;
        }
        var campaign = ad.getCampaign();
        var creatives = [];
        if (campaign) {
            creatives = campaign.creatives.toJSON();
        }
        var historyData = massageDataForAd(data, creatives);

        return (
            <Popup ch={this.props.ch}>
                <h1>Ad History <small>{ad.get('name')}</small></h1>
                <HistoryWidget history={historyData} />
                <div className="row"><div className="col-xs-3"><a className="btn btn-success" onClick={this.props.onClose}>Close</a></div></div>
            </Popup>
        );
    }
}) ;

module.exports = HistoryView;

var fns = require('scripts/common/fns');
function massageDataForAd(data, creatives) {

    var attrMappings = {
        name                  : 'Name',
        paused                : 'Paused',
        notes                 : 'Notes',
        start                 : 'Start Date',
        end                   : 'End Date',
        timezone              : 'Timezone',
        max_total_impressions : 'Max Total Impressions',
        max_daily_impressions : 'Max Daily Impressions',
        max_total_clicks      : 'Max Total Clicks',
        max_daily_clicks      : 'Max Daily Clicks',
        max_total_spend       : 'Max Total Spend',
        max_daily_spend       : 'Max Daily Spend',
        creative              : 'Creative',
        geotargets            : 'Geo Targets',
        geoboxes              : 'Geo Boxes',
        weekparts             : 'Week parts',
        dayparts              : 'Day parts',
        max_user_frequency    : 'Max User Frequency',
        max_bid_cpm           : 'Max Bid CPM',
        billing_terms         : 'Billing Terms',
        billing_rate          : 'Billing Rate',
        target_device_language: 'Target Device Language',
        target_device_os      : 'Target Device OS',
        target_age_groups     : 'Target Age Groups',
        target_genders        : 'Target Genders',
        target_app_categories : 'Target App Categories'
    };

    function formatter(key, snapshot) {
        switch (key) {
            case 'billing_rate':
                var type = snapshot['billing_terms'];
                return function (v) {
                    var v = type === 'CPM'? snapshot['ppm_rate'] : snapshot['ppc_rate'];
                    return '$' + numeral(v).format('0.0');
                };
            case 'start':
                var timezone = snapshot['timezone'];
                var start = snapshot['start'];
                return function (v) {return formatDate(v, timezone) + ' ' + formatTimezoneAbbr(start, timezone);};
            case 'end':
                var timezone = snapshot['timezone'];
                var end = snapshot['end'];
                return function (v) {return formatDate(v, timezone) + ' ' + formatTimezoneAbbr(end, timezone);};
            case 'max_total_impressions':
            case 'max_daily_impressions':
            case 'max_total_clicks':
            case 'max_daily_clicks':
                return function (v) {return numeral(v).format('0,0');}
            case 'max_total_spend':
            case 'max_daily_spend':
            case 'max_bid_cpm':
                return function (v) {return '$' + numeral(v).format('0.0');}
            case 'geotargets':
                return function (v) {
                    return <ul className="list-unstyled" key={_.uniqueId()}>{v.map(formatGeoCountry)}</ul>;
                };
            case 'geoboxes':
                return formatGeoBoxes;
            case 'paused':
                return function (v) {return S(v.toString()).capitalize().s;};
            case 'target_device_os':
                return function (v) {return v.join(', ');};
            case 'target_age_groups':
                return formatAgeGroup;
            case 'target_device_language':
                return formatDeviceLanguage;
            case 'target_genders':
                return formatTargetGenders;
            case 'target_app_categories':
                return formatTargetAppCats;
            case 'dayparts':
                return _.partialRight(formatDayparts, snapshot['timezone']);
            case 'weekparts':
                return formatWeekparts;
            case 'creative':
                return function (v) {return objectPath.get(_.find(creatives, {id: _.first(v)}), 'name');}
        }

        return false;
    }

    var alias = {
        'billing_rate': function (snapshot) {
            var type = snapshot['billing_terms'];
            return type === 'CPM'? 'ppm_rate' : 'ppc_rate';
        }
    };

    return fns.historyFormatter(data, attrMappings, formatter, alias);
}


