'use strict';

var CustomFluxMixin = require('scripts/common/mixins/CustomFluxMixin');
var Chart_explorerSummary = require('./chart-explorer-summary');

var DayWeekPartsGraph = require('./day-week-parts');
var Control = require('./control');
var PivotTable = require('./pivot-table');
var flux = require('scripts/core/flux');
var BarGraph = require('./graphs/bar');

const domainSetting_dayWeekParts = require('scripts/components/campaigns/views/report/day-week-parts/domain-setting');
var FilterSummary = require('./filter-summary2');

var op = objectPath;
var CampaignReport = React.createClass({
    propTypes: {
        campaignId: React.PropTypes.number
    },

    mixins: [CustomFluxMixin({
        'onCampaignsChanged': 'campaigns',
        'onCampaignReportingChanged': 'campaign-reporting'
    })],

    getInitialState () {
        var reportConfiguration = this.flux.store('campaign-reporting').getReportConfiguration(this.props.campaignId);
        var control  = objectPath.get(reportConfiguration, ['control']);

        return _.assign(this.getStoreState(), {
            reportConfiguration: reportConfiguration,
            configDimensions: this.getConfig_dimension(),
            pivotTableConfig: this.getConfig_pivot(),
            scopesConfig: this.getConfig_scopes()
        });
    },

    getStoreState () {
        return {
            campaign: this.flux.store('campaigns').getById(this.props.campaignId),
            overallStats: this.flux.store('campaign-reporting').getOverallStats(this.props.campaignId),

            totalFilteredStats: this.flux.store('campaign-reporting').getTotalFilteredStats_accretedByDate(this.props.campaignId),
            control: this.flux.store('campaign-reporting').getControlState(this.props.campaignId),

            pivotTableData: this.flux.store('campaign-reporting').getPivotTableConfiguration(this.props.campaignId),
            scopeData: buildScopeGraphs(this.props.campaignId),
            dimensionFilterItems: buildDimensionFilterItems(this.props.campaignId),
            scopeFilterItems: buildScopeFilterItems(this.props.campaignId),

            components: this.flux.store('campaign-reporting').getComponents(this.props.campaignId)
        };
    },

    onCampaignsChanged () {
        if (!this.flux.store('campaigns').getById(this.props.campaignId)) {
            return;
        }
        this.setState(this.getStoreState());
    },

    getConfig_pivot (reportConfig) {
        return this.flux.store('campaign-reporting').getConfig_pivotTable(this.props.campaignId);
    },

    getConfig_dimension (reportConfig) {
        return this.flux.store('campaign-reporting').getConfig_dimensions(this.props.campaignId);
    },

    getConfig_scopes () {
        return this.flux.store('campaign-reporting').getConfig_scopes(this.props.campaignId);
    },

    hasCampaignStarted () {
        return !!this.state.overallStats.startDate;
    },

    fetchComponentStats (nextDimensionConfig, nextScopesConfig) {
        this.flux.actions.reportPage.fetchDimensionStats({
            campaign_id: this.props.campaignId,
            scopesConfig: nextScopesConfig,
            configDimensions: nextDimensionConfig,
            components: this.state.components
        });
    },

    onCampaignReportingChanged () {
        if (!this.flux.store('campaigns').getById(this.props.campaignId)) {
            return;
        }

        var self = this,
            newState = this.getStoreState(),
            nextDimensionConfig = this.getConfig_dimension(),
            nextPivotTableConfig = this.getConfig_pivot(),
            nextScopesConfig = this.getConfig_scopes(),
            hasConfigChanged_dimension = function () {
                return !_.isEqual(self.state.configDimensions, nextDimensionConfig);
            },
            hasConfigChanged_pivot = function () {
                return !_.isEqual(self.state.pivotTableConfig, nextPivotTableConfig);
            },
            hasConfigChanged_scopes = function () {
                return !_.isEqual(self.state.scopesConfig, nextScopesConfig);
            };


        if ((hasConfigChanged_dimension() || hasConfigChanged_scopes()) && this.hasCampaignStarted()) {
            this.fetchComponentStats(nextDimensionConfig, nextScopesConfig);
        }


        if ((hasConfigChanged_pivot() || hasConfigChanged_dimension() || hasConfigChanged_scopes()) && this.hasCampaignStarted()) {
            this.flux.actions.reportPage.fetchPivotTableStats({
                selectedSplit: nextPivotTableConfig.selectedSplit,
                configDimensions: nextDimensionConfig,
                campaign_id: this.props.campaignId,
                scopesConfig: nextScopesConfig,
                dimensions: this.getConfig_dimension(),
                components: this.state.components
            });
        }


        if (hasConfigChanged_scopes() || hasConfigChanged_dimension()) {

            this.flux.actions.reportPage.fetchScopeStats({
                campaign_id: this.props.campaignId,
                configDimensions: nextDimensionConfig,
                scopesConfig: nextScopesConfig
            });
        }

        _.assign(newState, {
            configDimensions: nextDimensionConfig,
            pivotTableConfig: nextPivotTableConfig,
            scopesConfig: nextScopesConfig
        });


        this.setState(newState);
    },

    componentDidMount () {

        if (this.hasCampaignStarted()) {
            this.flux.actions.reportPage.fetchExploreStats({
                campaign_id: this.props.campaignId.toString()
            });

            this.flux.actions.reportPage.fetchPivotTableStats({
                campaign_id: this.props.campaignId,
                selectedSplit: objectPath.get(this.state.reportConfiguration, ['pivotTable', 'selectedSplit'])
            });

            this.flux.actions.reportPage.fetchScopeDetails({
                campaign_id: this.props.campaignId
            });
        }
    },

    handleClearSingleDimensionFilter (dimensionName) {
        this.flux.actions.reportPage.clearSingleDimensionFilter({
            campaignId: this.props.campaignId,
            dimensionName: dimensionName
        });
    },

    handleClearAllDimensionFilters () {
        this.flux.actions.reportPage.clearAllDimensionFilters({
            campaignId: this.props.campaignId
        });
    },

    handleClearSingleScopeFilter (scope) {
        flux.actions.reportPage.clearSingleScopeFilter({
            campaignId: this.props.campaignId,
            scope: scope
        });
    },

    handleClearAllScopeFilters () {

        flux.actions.reportPage.clearAllScopeFilters({
            campaignId: this.props.campaignId
        });
    },

    createComponent (component) {
        switch (component.type) {
            case 'bar':
                var bargraph = this.createBarGraphData(component, this.props.campaignId);
                return (
                    <BarGraph
                        title={bargraph.title}
                        groups={bargraph.groups}
                        isLoading={bargraph.isLoading}
                        onReset={bargraph.handleReset}
                        onCheckboxSelected={bargraph.handleCheckboxSelected}/>
                );
            case 'heatmap':
                return this.createHeatmap(component, this.props.campaignId);
        }
    },

    createBarGraphData (component, campaignId) {
        var rawData = flux.store('campaign-report-stats-resource').getAllDimensionData(campaignId);
        var settings = flux.store('campaign-reporting').getDimensionSettings(campaignId);

        var dimensionName = component.name;
        var setting = settings[dimensionName];

        var data = op.get(rawData, dimensionName, {});
        var reportDictionary = flux.store('campaign-reporting').getDictionary();

        var handleCheckboxSelected = selection => {
            flux.actions.reportPage.dimensionSelection_change({
                campaignId: campaignId,
                selection: selection,
                dimensionName: dimensionName
            });
        };

        var handleReset = () => {
            flux.actions.reportPage.dimensionSelection_change({
                campaignId: campaignId,
                selection: [],
                dimensionName: dimensionName
            });
        };

        var groups = generateBarGraphGroups(campaignId, data, setting.selected)
            .map((item, index) => {
                var unknownValue;
                switch (dimensionName) {
                    case 'geo_country_region':
                        var valueSplit = item.name.split('-');

                        if (valueSplit.length >= 2 && valueSplit[1] === '??') {
                            const countryName = objectPath.get(reportDictionary, ['geo_country', valueSplit[0]], '');
                            unknownValue = `Unknown (${countryName})`;
                        }
                        break;
                    default:
                        unknownValue = 'Unknown';
                }
                var dictValue = op.get(reportDictionary, `${dimensionName}.${item.name}`, unknownValue);

                if (_.isObject(dictValue)) {
                    item.presentationName = dictValue.value;
                } else {
                    item.presentationName = dictValue;
                }

                item.order = op.get(reportDictionary, `${dimensionName}.${item.name}.order`, item.presentationName);
                return item;
            });

        var sorted = _.sortBy(groups, 'order', 'presentationName');

        return {
            title: op.get(reportDictionary, `dimensionDisplayName.${dimensionName}`, dimensionName),
            isLoading: component.isLoading,
            groups: sorted,
            handleCheckboxSelected: handleCheckboxSelected,
            handleReset: handleReset
        };
    },

    createHeatmap (component, campaignId) {
        const rawData = flux.store('campaign-report-stats-resource').getAllDimensionData(campaignId);
        const settings = flux.store('campaign-reporting').getDimensionSettings(campaignId);
        const statsMetricType = flux.store('campaign-reporting').getStatsMetricType(campaignId);
        const isRelativePercentage = flux.store('campaign-reporting').isRelativePercentage(campaignId);
        const reportDictionary = flux.store('campaign-reporting').getDictionary();


        if (this.state) {
            return React.createElement( DayWeekPartsGraph , {
                campaignId:           campaignId,
                configDimensions:     settings,
                statsMetricType:      statsMetricType,
                isRelativePercentage: isRelativePercentage,
                rawData:              rawData,
                reportDictionary:     reportDictionary,
                isLoading:            component.isLoading
            });
        }
    },

    render () {
        var self = this;
        var statsMetricsType = this.state.control.statsMetricsType,
            tabType = this.state.control.tabType,
            rootNodeClasses = classNames({
                    'is-mode-explore': tabType === 'dimension',
                    'is-mode-pivotTable': tabType === 'pivotTable',
                    'is-dimension-impressions': statsMetricsType === 'impressions',
                    'is-dimension-clicks': statsMetricsType === 'clicks',
                    'is-dimension-ctr': statsMetricsType === 'ctr',
                    'is-dimension-spend': statsMetricsType === 'spend',
                    'is-dimension-ecpm': statsMetricsType === 'ecpm',
                    'is-dimension-ecpc': statsMetricsType === 'ecpc'
                },
                'am-reports'
            );
        var isSumamryLoading = _.find(this.state.components, { name: 'date' }).isLoading;
        var hasFilter = !!( this.state.dimensionFilterItems.length || this.state.scopeFilterItems.length);

        return (
            <div className={rootNodeClasses}>
                <div className="am-reports-inner">
                    <div className="am-reports-inner-head">
                        <Chart_explorerSummary
                            data={this.state.overallStats.stats}
                            isLoading={isSumamryLoading}
                            startDate={this.state.overallStats.startDate}
                            meta={ { statsMetricsType } }
                        />
                        <div className="am-reports-controls">
                            <div className="am-reports-filterBar" style={{display: hasFilter ? 'block' : 'none' }}>
                                <FilterSummary
                                    title={"Dimensions"}
                                    filterItems={this.state.dimensionFilterItems}
                                    onClearItem={this.handleClearSingleDimensionFilter}
                                    onClearAllFilters={this.handleClearAllDimensionFilters}/>
                                <FilterSummary
                                    title={"Ad Groups"}
                                    filterItems={this.state.scopeFilterItems}
                                    onClearItem={this.handleClearSingleScopeFilter}
                                    onClearAllFilters={this.handleClearAllScopeFilters}/>
                            </div>
                            <Control ref="control"
                                stats={ { totalFilteredStats: this.state.totalFilteredStats } }
                                control={ this.state.control }
                                campaignId={this.props.campaignId} />
                        </div>
                    </div>
                    <div className="am-reports-inner-sidebar">
                        <div className="am-reports-inner-sidebar-inner">
                            <div className="am-scopes">
                                <h4>Ad Groups</h4>
                                {_.map(this.state.scopeData, scope =>
                                <div className="am-scopes-reportTile" key={scope.title}>
                                    <div className="am-scopes-reportTile-inner">
                                        <BarGraph
                                            title={scope.title}
                                            groups={scope.groups}
                                            isLoading={scope.isLoading}
                                            onReset={scope.handleReset}
                                            onCheckboxSelected={scope.handleCheckboxSelected}
                                        />
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                    <div className="am-reports-inner-body">
                        <div className="am-dimensions">
                            {_.map(this.state.components, component => {
                                if ( component.type === 'bar' || component.type ==='heatmap' ) {
                                    return (
                                        <div className="am-dimensions-reportTile" key={component.name}>
                                            <div className="am-dimensions-reportTile-inner">
                                                {this.createComponent(component)}
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                        <PivotTable
                            campaignId={this.props.campaignId}
                            settings={this.state.pivotTableData.settings}
                            stats={this.state.pivotTableData.stats}
                        />
                    </div>
                </div>
            </div>
        );
    }
});

function buildScopeFilterItems (campaignId) {
    var settings = flux.store('campaign-reporting').getScopeSettings(campaignId);
    var reportDictionary = flux.store('campaign-reporting').getDictionary();
    var selected = _.pick(settings, (setting, scopeName) => setting.selected.length > 0);

    var mapped = _.map(selected, (scope, scopeName) => ({
        groupingName: scopeName,
        groupingPresentationName: op.get(reportDictionary, `scopes.${scopeName}`, scopeName),
        groups: scope.selected.map(selection => ({
            id: selection,
            name: selection,
            title: scope.nameLookup[selection]
        }))
    }));

    return mapped;
}

function buildDimensionFilterItems (campaignId) {
    var settings = flux.store('campaign-reporting').getDimensionSettings(campaignId);
    var reportDictionary = flux.store('campaign-reporting').getDictionary();
    var selected = _.pick(settings, (setting) => op.get(setting, 'selected.length', 0) > 0);

    var mapped = _.map(selected, (v, dimensionName) => {
        if (dimensionName === 'hour') {
            const selected_hours = v.selected;
            const selected_intervals = domainSetting_dayWeekParts.convertSelection_hoursToInterval(selected_hours);

            const selected_binNames = selected_intervals.map(
                (selected_interval) => domainSetting_dayWeekParts.hour.binKeyToBinName[selected_interval]
            );

            v.selected = selected_binNames;
        }

        return {
            groupingName: dimensionName,
            groupingPresentationName: op.get(reportDictionary, `dimensionDisplayName.${dimensionName}`, dimensionName),
            groups: v.selected.map( (selection) => {
                let  _dimensionName;
                switch (dimensionName) {
                    case 'hour':
                        _dimensionName = 'hourInterval';
                        break;
                    case 'day_of_week':
                        _dimensionName = 'day_of_week_short';
                        break;
                    default:
                        _dimensionName = dimensionName;
                }

                let unknownValue;

                switch (dimensionName) {
                    case 'geo_country_region':
                        const valueSplit = selection.split('-');

                        if (valueSplit.length >= 2 && valueSplit[1] === '??') {
                            const countryName = objectPath.get(reportDictionary, ['geo_country', valueSplit[0]], '');
                            unknownValue = `Unknown (${countryName})`;
                        }
                        break;
                    default:
                        unknownValue = 'Unknown';
                }

                const dictValue = objectPath.get(reportDictionary, `${_dimensionName}.${selection}`, unknownValue);

                let title = '';
                if (_.isObject(dictValue)) {
                    title = dictValue.value;
                } else {
                    title = dictValue;
                }



                return {
                    id: selection,
                    name: selection,
                    title: title
                };
            })
        };
    }
    );

    return mapped;
}

function buildScopeGraphs (campaignId) {
    var rawData = flux.store('campaign-report-stats-resource').getAllScopeData(campaignId);

    if (!rawData) {
        return void 0;
    }

    var settings = flux.store('campaign-reporting').getScopeSettings(campaignId);

    var barGraphs = _.map(settings, (setting, scopeName) => {
        var data = rawData[scopeName];

        var reportDictionary = flux.store('campaign-reporting').getDictionary();

        var handleCheckboxSelected = selection => {
            flux.actions.reportPage.scopeSelection_change({
                campaignId: campaignId,
                selection: selection,
                scopeName: scopeName
            });
        };

        var handleReset = () => {
            flux.actions.reportPage.scopeSelection_change({
                campaignId: campaignId,
                selection: [],
                scopeName: scopeName
            });
        };

        var groups = generateBarGraphGroups(campaignId, data, setting.selected)
            .map(item => {
                item.presentationName = op.get(setting.nameLookup, item.name, 'Unknown');
                return item;
            });

        var sorted = _.sortBy(groups, 'presentationName');

        return {
            title: op.get(reportDictionary, `scopes.${scopeName}`, scopeName),
            isLoading: setting.isLoading,
            groups: sorted,
            handleCheckboxSelected: handleCheckboxSelected,
            handleReset: handleReset
        };

    });

    return barGraphs;
}

function generateBarGraphGroups (campaignId, groupData, selectedItems) {

    if (!groupData || !groupData.stats) {
        return [];
    }

    var stats = groupData.stats;

    var someSelected = _.some(selectedItems);

    var isRelativePercentage = flux.store('campaign-reporting').isRelativePercentage(campaignId),
        statsMetricsType = flux.store('campaign-reporting').getStatsMetricType(campaignId);


    var uiItems = [],
        totalFilteredStats = [],
        groupName;



    var toUiItem = function (_distinctValue) {
        // diff
        var stat = _.find(stats, function (item) { return item.group[groupName] === _distinctValue; });

        var _name;
        var _selected;
        var _presentationName;
        var _statsMetrics;
        var _statsMetrics_numType;
        var _barGraphLength;

        if (stat) {
            var key                  = _distinctValue;
            var isSelected           = _.contains( selectedItems.map(x => x.toString()), key.toString() );
            var shouldShowStats      = someSelected ? isSelected : true;
            var shouldShowAsSelected = someSelected ? isSelected : false;

            _name = _distinctValue;
            _selected = shouldShowAsSelected;

            // absolute values
            var clicks      = shouldShowStats ? stat.clicks : 0;
            var impressions = shouldShowStats ? stat.impressions : 0;
            var ctr         = (impressions !== 0) ? clicks/impressions : 0;
            var spend       = shouldShowStats ? stat.spend : 0;
            var eCPM        = shouldShowStats ? (impressions ? (stat.spend * 1000) / impressions : 0) : 0;
            var eCPC        = shouldShowStats ? (clicks ? spend / clicks : 0) : 0;

            // total values
            var totalClicks      = totalFilteredStats.clicks;
            var totalImpressions = totalFilteredStats.impressions;
            var totalCtr         = totalFilteredStats.ctr;
            var totalSpend       = totalFilteredStats.spend;
            var totalEcpm        = totalFilteredStats.eCPM;
            var totalEcpc        = totalFilteredStats.eCPC;

            // calculate percentage
            var impressions_relativePercentage = (totalImpressions !== 0 ) ? impressions/totalImpressions*100  : 0;
            var clicks_relativePercentage      = (totalClicks !== 0 ) ? clicks/totalClicks*100  : 0;
            var ctr_inPercentage               = ctr * 100;
            var spend_inPercentage             = (totalSpend !== 0) ? spend/totalSpend*100 : 0;
            var eCPM_inPercentage              = (totalEcpm !== 0) ? eCPM/totalEcpm*100 : 0;
            var eCPC_inPercentage              = (totalEcpc !== 0) ? eCPC/totalEcpc*100 : 0;

            switch (statsMetricsType) {
                case 'impressions':
                    _statsMetrics  = isRelativePercentage
                        ? numeral(impressions_relativePercentage).format('0,0.00') + '%'
                        : numeral(impressions).format('0,0');
                    _barGraphLength  = impressions_relativePercentage;
                    _statsMetrics_numType = +impressions;
                    break;
                case 'clicks':
                    _statsMetrics  = isRelativePercentage
                        ? numeral(clicks_relativePercentage).format('0,0.00') + '%'
                        : numeral(clicks).format('0,0');
                    _barGraphLength  = clicks_relativePercentage;
                    _statsMetrics_numType = +clicks;
                    break;
                case 'ctr':
                    _statsMetrics  = numeral(ctr_inPercentage).format('0,0.00') + '%';
                    _barGraphLength  = (totalCtr !== 0) ? ctr/totalCtr*100 : 0;
                    _statsMetrics_numType = +ctr_inPercentage;
                    break;
                case 'spend':
                    _statsMetrics  = isRelativePercentage
                        ? numeral(spend_inPercentage).format('0,0.00') + '%'
                        : numeral(spend).format('$0,0.00');
                    _barGraphLength  = spend_inPercentage;
                    _statsMetrics_numType = +spend;
                    break;
                case 'ecpm':
                    _statsMetrics  = isRelativePercentage
                        ? numeral(eCPM_inPercentage).format('0,0.00') + '%'
                        : numeral(eCPM).format('$0,0.00');
                    _barGraphLength  = eCPM_inPercentage;
                    _statsMetrics_numType = +eCPM;
                    break;
                case 'ecpc':
                    _statsMetrics  = isRelativePercentage
                        ? numeral(eCPC_inPercentage).format('0,0.00') + '%'
                        : numeral(eCPC).format('$0,0.00');
                    _barGraphLength  = eCPC_inPercentage;
                    _statsMetrics_numType = +eCPC;
                    break;
            }
        } else {
            _name = _distinctValue;
            _selected = _.contains(selectedItems, _distinctValue.toString());

            switch (statsMetricsType) {
                case 'impressions':
                case 'clicks':
                case 'spend':
                case 'ecpm':
                case 'ecpc':
                    _statsMetrics  = isRelativePercentage
                        ? numeral(0).format('0,0.00') + '%'
                        : numeral(0).format('0,0');
                    break;
                case 'ctr':
                    _statsMetrics  = numeral(0).format('0,0.00') + '%';
                    break;

            }

            _statsMetrics_numType = 0;
            _barGraphLength  = 0;
        }

        return {
            name: _name,
            selected: _selected,
            presentationName: _presentationName || 'todo',
            statsMetrics: _statsMetrics,
            barGraphLength: _barGraphLength,
            statsMetrics_numType: _statsMetrics_numType
        };
    };


    _.each(groupData.distinctValues, (distinctValues, __groupName) => {

        groupName = __groupName;
        totalFilteredStats = accrete_group(stats, groupName, selectedItems);


        uiItems = distinctValues.map(toUiItem);
    });

    return uiItems;
}

function accrete_group (stats, groupName, selectedItems) {
    var total = _.reduce( stats, function( accretion, item){

        var isSelected = _.contains( selectedItems, item.group[groupName].toString() );

        if ( isSelected || !selectedItems.length ){
            var itemCtr = (item.impressions !==0 ) ? item.clicks/item.impressions : 0;
            var itemEcpm = (item.impressions !==0 ) ? (item.spend * 1000) / item.impressions : 0;
            var itemEcpc = (item.clicks !==0 ) ? item.spend / item.clicks : 0;
            return {
                clicks : accretion.clicks + item.clicks,
                impressions :  accretion.impressions + item.impressions,
                ctr: accretion.ctr + itemCtr,
                spend: accretion.spend + item.spend,
                eCPM: accretion.eCPM + itemEcpm,
                eCPC: accretion.eCPC + itemEcpc
            };
        }

        return accretion;

    }, {
        clicks: 0,
        impressions: 0,
        ctr: 0,
        spend: 0,
        eCPM: 0,
        eCPC: 0
    });

    return _.assign({}, total);
}

module.exports = CampaignReport;
