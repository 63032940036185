'use strict';

import processor_dataGraph from './data-graph';

var intialState = {
    deps: {},
};

function adProgressGraphReducer (state = intialState, resources, session, campaignId) {
    // const campaign_attr = objectPath.get(resources, `campaigns.entities.${campaignId}.attr`);
    const adId = objectPath.get(session, `campaigns.progress.instances.${campaignId}.adId`);

    if (!adId) {
        // Page is not on the Ad progess page
        return state;
    }

    const ad_attr = objectPath.get(resources, `campaigns.entities.${campaignId}.ads.entities.${adId}.attr`);
    const stats = objectPath.get(resources, `stats.progress.ads.entities.${adId}.liveHourly`);

    // All the dependencies that this reducer depends on to generate UI state
    const newDeps = {
        stats,
        adAttr: ad_attr,
    };

    // Rerendering required if any new deps does not match the old dep
    const rerenderingRequired = _.any(newDeps, (dep, key) => {
        return dep !== state.deps[key];
    });
    if (!rerenderingRequired) {
        return state;
    }

    let adAttr = _(ad_attr).pick(
        'max_daily_clicks',
        'max_daily_impressions',
        'max_daily_spend',

        'max_total_clicks',
        'max_total_impressions',
        'max_total_spend',

        'end',
        'start',
        'timezone',

        'weekparts',
        'dayparts'
    ).value();

    adAttr = _.assign( adAttr, {adId:adId} );

    let dataGraph;
    if ( adAttr ) {
        dataGraph = processor_dataGraph({meta:adAttr, stats});
        dataGraph.meta.adId = adId;
    }

    return {
        ...state,
        deps: newDeps,
        adAttr: adAttr,
        stats: stats,
        dataGraph: dataGraph
    };
}


module.exports = adProgressGraphReducer;


// attr: Object
// _etag: "ae7dfe4c97acfff20dfe120f08f41026"
// billing_rate: 0
// billing_terms: "PPM"
// billing_type: "fixed"
// creative: "JP3GEG"
// dayparts: Array[0]
// end: "2015-05-22T23:59:59.000Z"
// geoboxes: Array[0]
// geotargets: Array[1]
// id: 10072
// interest_target: Array[0]
// max_bid_cpm: 2.5
// max_daily_clicks: 0
// max_daily_impressions: 0
// max_daily_spend: 0
// max_total_clicks: 0
// max_total_impressions: 200
// max_total_spend: 0
// max_user_frequency: 4
// name: "Lipton Test"
// notes: ""
// paused: true
// ppc_rate: 0
// ppm_rate: 0
// restrict_ips: Array[0]
// start: "2015-05-19T00:00:00.000Z"
// status: "ended"
// target_age_groups: Array[0]
// target_app_categories: Array[0]
// target_carriers: Array[0]
// target_connection_types: Array[0]
// target_device_language: null
// target_device_os: Array[2]
// target_genders: Array[0]
// timezone: "UTC"
// weekparts: Array[7]
