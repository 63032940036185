'use strict';

import progressReducer from './progress';

export const statsReducer = (state = {}, action) => {
    return {
        progress: progressReducer(state.progress, action)
    };
};

export function dollar (value) {
    return numeral(value).format('$0,0.00');
}

export function percentage (value) {
    return value;
}

// Read
export function getCtr (stat) {
    return (stat.impressions !== 0) ? stat.clicks / stat.impressions * 100 : 0;
}

export function getEcpm (stat) {
    return (stat.impressions !== 0 ) ? stat.spend * 1000 / stat.impressions : 0;
}

export function getEcpc (stat) {
    return (stat.clicks !== 0 ) ? stat.spend / stat.clicks : 0;
}


// Write
export function addCtr (stat) {
    return {
        ...stat,
        ctr: getCtr(stat)
    };
}

export function addEcpm (stat) {
    return {
        ...stat,
        ecpm: getEcpm(stat)
    };
}

export function addEcpc (stat) {
    return {
        ...stat,
        ecpc: getEcpc(stat)
    };
}
