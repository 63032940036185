<div class="col-xs-1">
    <i class="fa {{icon}}"></i>
</div>

<div class="col-xs-10">
    <span>{{name}}</span> {{#if unsupported}} (unsupported format) {{/if}}
</div>

<div class="col-xs-1">
    <i class="fa fa-times"></i>
</div>
