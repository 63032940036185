'use strict';

var layoutTemplate = require('../templates/progress');
var adSelectorTemplate = require('../templates/ad-progress-selector');
var adActiveSelectorTemplate = require('../templates/ad-progress-selector-active');
var fns = require('scripts/common/fns');
var Graph = require('scripts/widgets/fill-graph');
var HourlyGraph = require('scripts/widgets/hourly-graph');
var Refresh = require('scripts/common/mixins/refresh');
var app = require('scripts/core').app;
var PrivateChannel = require('scripts/common/mixins/private-channel');
var LoadingLabel = require('scripts/widgets/loading-label');

var AdSummary = require('scripts/components/campaigns/parts/ads/ad-summary');
var CampaignSummary = require('scripts/components/campaigns/views/campaign-summary');

var AdHistory = require('../parts/ads/overview/history');
var CampaignHistory = require('./history');

var BulletGraph = require('scripts/widgets/bullet-graph');
var BulletBreakdown = React.createClass({
    mixins: [PrivateChannel],

    componentDidMount() {
        // it's okay to use forceUpdate here since it's not a big tree
        this.privateCh.listenTo(this.props.target, 'livestats:loaded', ()=>this.forceUpdate());
    },

    render() {
        var target = this.props.target;
        var settings = target.getBulletGraphAttributes()

        var totalFilledCount = target.getTotalFilledCount();
        var totalFilledRate = target.getTotalFilled();
        var totalBooked = target.getTotalTarget();
        var totalLeftCount = totalBooked - totalFilledCount;

        var time_elapsed             = target.formatTimeElapsed('nice');
        var time_elapsed_percentage  = target.formatTimeElapsed('percentage');
        var time_remaining           = target.formatTimeRemaining('nice');
        var time_remaining_percentage= target.formatTimeRemaining('percentage');
        var duration = target.getDuration();

        return (
            <div className="margin-vertical-30">
                <h4>Time</h4>
                <small>{moment.duration(duration).format('d [days], h [hours]', 1)}</small>
                <BulletGraph settings={settings} variation={'time-only'} withoutDateLabels={true} />
                <div className="clearfix">
                    <small className=" pull-left">{time_elapsed} elapsed ({time_elapsed_percentage})</small>
                    <small className=" pull-right">{time_remaining} left ({time_remaining_percentage})</small>
                </div>
                <h4 style={{textTransform: 'capitalize'}}>{this.props.target.goal()}</h4>
                <small>{numeral(totalBooked).format('0,0')} booked</small>
                <BulletGraph settings={settings} variation={'progress-only'} withoutDateLabels={true}/>
                <div className="clearfix">
                    <small className=" pull-left">{numeral(totalFilledCount).format('0,0')} delivered ({numeral(totalFilledRate).format('0[.]0%')})</small>
                    <small className=" pull-right">{numeral(totalLeftCount).format('0,0')} left</small>
                </div>
            </div>
        )
    }
});

var ProgressBulletGraphs = React.createClass({
    mixins: [PrivateChannel],

    componentDidMount() {
        if (this.props.target.isCampaign()) {
            // it's okay to use forceUpdate here since it's not a big tree
            this.privateCh.listenTo(this.props.target.ads, 'stats:loaded', ()=>this.forceUpdate());
        }

    },

    getAdView(ad) {
        var totalFilledCount = ad.getTotalFilledCount();
        var totalFilledRate = ad.getTotalFilled();
        var totalLeftCount = ad.getTotalTarget() - totalFilledCount;
        return (
            <div key={_.uniqueId()} >
                <h4>#{ad.id} - <span className="name">{ad.get('name')}</span></h4>
                <BulletGraph withoutDateLabels={true} settings={ad.getBulletGraphAttributes()} />

                <div className="clearfix">
                    <small className=" pull-left" data-id="total-delivered">{numeral(totalFilledCount).format('0,0')} delivered ({numeral(totalFilledRate).format('0[.]0%')})</small>
                    <small className=" pull-right" data-id="total-remaining">{numeral(totalLeftCount).format('0,0')} left</small>
                </div>

            </div>
            );
    },

    render() {
        if (this.props.target.isCampaign()) {
            var adsProgress = this.props.target.ads.map((ad)=>this.getAdView(ad));
            return (
                <div>
                    <h3>Progress</h3>
                    <BulletBreakdown target={this.props.target} />
                    <h3>Ads Progress</h3>
                    {adsProgress}
                </div>
            )
        } else {
            return (
                <div>
                    <h3>Progress</h3>
                    <BulletBreakdown target={this.props.target} />
                </div>
            )
        }
    }
})
var LoadingGraph = Mario.ItemView.extend({
    template: _.template('Loading Graph ...'),
    className: 'loading'
});

var EmptyGraph = Mario.ItemView.extend({
    template: _.template('<%=label%>'),
    className: 'empty-graph',
    render: function () {
        var label = this.options.label || 'No data available';
        this.$el.html(this.template({label}));
        return this;
    }
});

var AdProgressListItem = Mario.ItemView.extend({
    template: _.template(`<div class="col-xs-12" title="#<%=id%> <%=name%>"> #<%=id%> <%=name%></div>
                         <i class="indicator fa fa-circle"></i>`),
    tagName: 'li',
    className: 'row',

    initialize: function() {
        this.listenTo(this.collection, 'livestats:loaded', this.updateIndicator);
    },

    onAttach : function () {
        this.$el.data('id', this.model.id);
        this.updateIndicator();
    },

    updateIndicator: function () {
        var goal = this.model.goal();
        var delivered = this.model.stats.getTotalDelivered(goal);
        var status = this.model.performanceStatus(delivered)
        this.$('.indicator').removeClass('ok danger warning').addClass(status);
    }

});

var AdProgressList = Mario.CollectionView.extend({
    tagName       : 'ul',
    className     : 'ad-list list-unstyled col-xs-12',
    childView     : AdProgressListItem,
    events        : {
        'click li': 'showAdProgress'
    },

    showAdProgress: function (ev) {
        var id = $(ev.currentTarget).data('id');
        var ad = this.collection.get(id);
        if (ad != null) {
            ad.trigger('show-progress');
        }
    },

    childViewOptions: function () {
        return {
            ch: this.options.ch,
            collection: this.collection
        };
    }
});


var CampaignSummaryTitle = React.createClass({

    mixins: [PrivateChannel],

    getInitialState() {
        return {
            showHistory: false
        };
    },

    handleHistoryClick () {
        this.setState({showHistory: true});
    },

    onCloseHistory () {
        this.setState({showHistory: false});
    },

    componentWillMount () { },

    render() {
        return (
            <div className="summary-title" id={`campaign-summary-${this.props.id}`}>
                <span className="btn">{this.props.name}</span>
                {// --- unfinished work  edit button  --//
                    this.props.canEdit ?
                    <div className="pull-right">
                        <button onClick={this.handleHistoryClick} className="btn btn-default btn-sm" data-action="edit-campaign-summary" data-id={`${this.props.id}`}>
                            <i className="fa fa-fw fa-clock-o"></i></button>
                    </div> :
                        null
                    }

                <div className="clearfix"></div>
                {this.state.showHistory? <CampaignHistory ch={this.props.ch} campaign={this.props.campaign} onClosePopup={this.onCloseHistory}/> : null}
            </div>
        );
    }
});

var AdSummaryTitle = React.createClass({

    mixins: [PrivateChannel],

    getInitialState() {
        return {
            showHistory: false
        };
    },

    handleEditClick () {
        this.props.ch.trigger('campaign:ads:edit', this.props.ad.toJSON());
        app.navigate('/campaigns/' + this.props.campaignId + '/ads');
    },

    handleHistoryClick () {
        this.setState({showHistory: true});
    },

    onCloseHistory () {
        this.setState({showHistory: false});
    },

    componentWillMount () { },

    render() {

        return (
            <div className="summary-title" id={`ad-summary-${this.props.id}`}>
                <span className="btn">{this.props.name}</span>
                {// --- unfinished work  edit button  --//
                    this.props.canEdit ?
                    <div className="pull-right">

                        <button onClick={this.handleEditClick} className="btn btn-default btn-sm" data-action="edit-ad-summary" data-id={`${this.props.id}`}>
                            <i className="fa fa-pencil"></i>Edit</button>
                        &nbsp;
                        <button onClick={this.handleHistoryClick} className="btn btn-default btn-sm" data-action="edit-ad-summary" data-id={`${this.props.id}`}>
                            <i className="fa fa-fw fa-clock-o"></i></button>
                    </div> :
                        null
                    }

                <div className="clearfix"></div>
                {this.state.showHistory? <AdHistory ch={this.props.ch} ad={this.props.ad} onClose={this.onCloseHistory}/> : null}
            </div>
        );
    }
});

var ProgressView = Mario.Layout.extend({
    className: 'progress-page',
    template : layoutTemplate,

    regions: {
        'hourlyGraphRegion' : '.hourly-graph-region',
        'graphRegion'       : '.graph-cumulative-fill',
        'progressListRegion': '.ad-list-region'
    },

    initialize: function () {
        var that = this;
        var ch = this.options.ch;

        this._debugNote = {ctor: 'ProgressView'}

        Object.defineProperties(this, {
            'selected': {
                get: function () {
                    return this._selectedAd || null;
                },

                /**
                 * set target for progress graph
                 * @param target, can be ad/campaign
                 */
                set: function (target) {

                    // unset first
                    if (this.selected != null) {
                        this.stopListening(this.selected);
                        this.unstickit(this.selected);
                        this.trigger('refresh:reset');
                    }

                    if (target == null || !this.model.ads.contains(target)) {
                        target = this.model; // set to campaign by default;
                    }

                    this._selectedAd = this.refreshable = target;
                    this.updateAdsMenu(target.id);
                    this.listenTo(target, 'stats:loaded', this.updateToRealtimeData);
                    this.updateToRealtimeData();
                }
            }
        });

        this.listenTo(ch, 'loading:timeout', this.onLoadingTimeout);
        this.listenTo(ch, 'campaign:ads:data:loaded', this.mountPopoverForSummary);

        $('body').on('click', '.summary-title [data-action="edit-ad-summary"]', this.editAdSummary);
    },

    onLoadingTimeout: function() { },

    editAdSummary: function(e) {
        var id = $(e.target).attr('data-id');
    },

    updateToRealtimeData() {
        this.selected.loadRealTimeStats(()=>this.onStatsLoaded(this.selected));
    },

    behaviors: {
        Refresh: {
            noRefreshOnStart: true
        }
    },

    events: {
        'click @ui.progressList li:first-child': 'switchProgress',
        'click @ui.progressList ul li'         : 'switchProgress',
        'click .hourly-graph-hide-btn'         : 'hideHourlyGraph',
    },

    ui: {
        'progressList': '.progress-list'
    },

    hideHourlyGraph: function(e) {
        e.preventDefault();
        var stats = this.selected.stats;
        this.updateFillGraph(stats);
        if (this.hourlyGraphRegion) {
            this.hourlyGraphRegion.reset();
        }
        this.$el.find('.hourly-graph-region-wrapper').hide();
    },

    onStatsLoaded: function (target) {
        this.updateStats();
        this.toggleAdsBulletGraphs(target === this.model);

        this.stickit(target);

        React.unmountComponentAtNode(this.bulletListRegion);
        React.render(React.createFactory(ProgressBulletGraphs)({target: this.selected}), this.bulletListRegion);
    },

    initPage: function () {
        if (this.options.sectionId != null) {
            this.showProgress(this.findTarget(this.options.sectionId));
        } else {
            this.showProgress(this.model);
        }
        this.mountPopoverForSummary();
    },

    toggleAdsBulletGraphs: function (toggle) {
        this.$('.ad-bullet-list-region').toggle(toggle);
        if (toggle) {
            this.$('.data-title').text('Campaign Overall Stats');
        } else {
            this.$('.data-title').text('#' + this.selected.id + ' ' + this.selected.get('name'));
        }
    },

    onBeforeShow: function () {
        this.showAdList();
    },

    showAdList() {
        this.progressListRegion.show(new AdProgressList({collection: this.collection, ch: this.options.ch}));
    },

    onShow: function () {
        this.showGraphLoading();
        _.bindAll(this, 'initPage');
        var target = this.findTarget(this.options.sectionId);
        this.bulletListRegion = this.$('.progress-bullet-graphs-region').get(0);
        this.initPage();
    },

    onDestroy() {
        if (this.$('.graph-cumulative-fill').length > 0) {
            React.unmountComponentAtNode(this.$('.graph-cumulative-fill').get(0));
        }
    },

    findTarget: function (id) {
        // target maybe a campaign or ad
        return _.find(this.collection.models.concat(this.model), {id: _.parseInt(id)});
    },

    switchProgress: function (ev) {
        var id = $(ev.currentTarget).data('id');
        var target = this.findTarget(id);

        if (target != null) {
            this.showProgress(target);
        }

        // model is campaign
        if (target === this.model) {
            app.navigate('/campaigns/' + this.model.id + '/progress');
        } else {
            app.navigate('/campaigns/' + this.model.id + '/progress/' + target.id);
        }

        this.mountPopoverForSummary();
    },

    mountPopoverForSummary: function() {
        // Mounting popover for summary
        var $popOverMountPoint = this.$el.find('[data-toggle="popover"]');
        var dataForPopover = this.selected;

        var ReactSummaryTitle;
        var ch = this.options.ch;
        var campaignId = this.model.id;

        if (dataForPopover && dataForPopover.ads) {
            //-- Summary for Campaign  --//
            var campaignJson = dataForPopover.toJSON();
            var {name, id} = campaignJson;
            var ReactCampaignSummary = React.createFactory(CampaignSummary)({ campaignJson });
            var content = React.renderToStaticMarkup(ReactCampaignSummary);
            ReactSummaryTitle = React.createFactory(CampaignSummaryTitle)({
                name:name,
                id:id,
                canEdit: dataForPopover.can('edit'),
                ch: ch,
                campaign: dataForPopover,
                campaignId: campaignId }); // canEdit is not use for this iteration
            var title = React.renderToString(ReactSummaryTitle);
            $popOverMountPoint.attr('data-content', content);
            $popOverMountPoint.attr('data-original-title', title);
            $popOverMountPoint.popover();
        } else {
            //-- Summary for Ads --//
            var adJson = dataForPopover.toJSON();
            var ad = dataForPopover;
            var canEdit = dataForPopover.can('edit');
            var {name, id} = adJson;
            var ReactAdSummary = React.createFactory(AdSummary)({adJson, ad:dataForPopover})
            var content = React.renderToStaticMarkup(ReactAdSummary);
            ReactSummaryTitle = React.createFactory(AdSummaryTitle)({name, id, canEdit, ch, ad, campaignId})
            var title = React.renderToString(ReactSummaryTitle);
            // -- set content and title dynamically --//
            $popOverMountPoint.attr('data-content', content);
            $popOverMountPoint.attr('data-original-title', title);
            $popOverMountPoint.popover();
        }

        $popOverMountPoint.one('shown.bs.popover', function () {
            React.render(ReactSummaryTitle, document.querySelector('h3.popover-title'));
        });
    },

    showProgress: function (target) {
        this.selected = target;
    },

    updateAdsMenu: function (id) {
        this.$('.progress-list li.active').removeClass('active');
        this.$('.progress-list li').filter(function () {
            return $(this).data('id') == id;
        }).addClass('active');
    },

    updateStats: function () {
        this.updateProgress();
        this.updateFillGraph();
        this.renderStatsRows();
        this.renderStatsFooter();
        this.setupResizeEvents();
        this.hourlyGraphRegion.reset();
        this.$el.find('.hourly-graph-region-wrapper').hide();
    },

    setupResizeEvents: function () {
        var stats = this.selected.stats;
        var that = this;

        $(window).on('resize.' + this.cid, function () {
            that.updateFillGraph(stats);
            if (that.hourlyGraphRegion) {
                that.hourlyGraphRegion.reset();
            }
            that.$el.find('.hourly-graph-region-wrapper').hide();
        });

        this.on('close', function () {
            $(window).off('resize.' + that.cid);
        });
    },

    showGraphLoading: function () {
        React.render(React.createFactory(LoadingLabel)({
            displayMessage: 'Loading ...',
            displayPosition: 'center',
            displayTimeout: 10000,
            ch: this.options.ch
        }), this.$('.graph-cumulative-fill').get(0));
    },

    updateFillGraph: function () {

        // must unmout React component before show graph,
        if (this.$('.graph-cumulative-fill').length > 0) {
            React.unmountComponentAtNode(this.$('.graph-cumulative-fill').get(0));
        }

        var stats = this.selected.stats;

        // prevent early invocation of the method
        if (typeof this.graphRegion === 'undefined') {
            return;
        }

        if (stats.isEmpty()) {
            this.graphRegion.show(new EmptyGraph());
            return;
        }

        var goal = this.selected.goal();
        var delivered = this.selected.stats.getTotalDelivered(goal);
        var total = this.selected.getTotalTarget();

        if (total === Infinity) {
            total = this.selected.get('max_daily_' + goal) * this.selected.getDuration() / 24 / 3600 / 1000;
        }

        if (total === 0) {
            if (goal === 'mixed') {
                this.graphRegion.show(new EmptyGraph({label: 'No graph is available for mixed goal campaign'}));
            } else {
                this.graphRegion.show(new EmptyGraph());
            }
            return;
        }

        var graph = new Graph();
        this.graphRegion.show(graph);

        graph.setValues(
            this.selected.stats.toJSON(),
            moment.utc(this.selected.get('start')).valueOf(),
            moment.utc(this.selected.get('end')).valueOf(),
            total, this.selected.goal(),
            this.selected.performanceStatus(delivered)
        );

        this.listenTo(graph, 'clicked-date', this.onClickDate);

        return stats;
    },

    onClickDate: function (d) {
        this.selected.stats.fetchHourlyData(d).then(_.bind(this.renderHourlyGraph, this, d));
    },

    renderHourlyGraph: function (d, hourlyData) {
        var goal = this.selected.goal();
        var totalTarget = this.selected.getTotalTarget();
        if (totalTarget === Infinity) {
            totalTarget = this.selected.get('max_daily_' + goal) * this.selected.getDuration() / 24 / 3600 / 1000;
        }
        var durationInHour = this.selected.getDuration() / 1000 / 3600;

        this.$el.find('.hourly-graph-region-wrapper').show();
        this.hourlyGraphRegion.show(new HourlyGraph({
            collection: new Backbone.Collection(hourlyData),
            average   : Math.floor(totalTarget / durationInHour),
            type      : goal,
            date      : d
        }));
    },

    updateProgress: function () {
        var stats = this.selected.stats;

        var goal = this.selected.goal();
        var statusClass = this.selected.performanceStatus(stats.getTotalDelivered(goal));
        this.$('.ad-progress:eq(0)').removeClass('ok danger warning').addClass(statusClass);

        return stats;
    },

    renderStatsRows: function () {
        var stats = this.selected.stats;

        var trTpl = _.template('<tr>' +
        '<td><%=date%></td>' +
        '<td><%=impressions%></td>' +
        '<td><%=clicks%></td><td><%=ctr%></td>' +
        '</tr>');

        // make it daily
        var startDate = moment.utc(this.selected.get('start'));
        var endDate = moment.utc(Math.min(moment.utc(), moment.utc(this.selected.get('end'))));
        var formattedDates = [];
        var todayDate = moment.utc().format('YYYY-MM-DD');

        while (startDate <= endDate.endOf('day')) {
            var formatted = startDate.format('YYYY-MM-DD');
            formattedDates.push(formatted);
            startDate.add(1, 'day');
        }

        var statsToShow = _.map(formattedDates, function (date) {

            var sums = stats.reduce(function (sums, stat) {
                if (stat.get('date') === date) {
                    sums[0] += stat.get('impressions');
                    sums[1] += stat.get('clicks');
                }
                return sums;
            }, [0, 0]);
            var clicks = sums[1],
                impressions = sums[0];

            if (date === todayDate) {
                // mark today (live)
                date = date + '*';
            }
            return trTpl({
                date       : date,
                impressions: numeral(impressions).format('0,0'),
                clicks     : numeral(clicks).format('0,0'),
                ctr        : impressions ? numeral(clicks / impressions).format('0.00%', Math.floor) : '0.00%'
            });
        });

        var statsToShowLatestFirst = statsToShow.reverse();
        var statsRows = statsToShowLatestFirst.join('');
        this.$('.daily-stats tbody').html(statsRows);

        return stats;
    },

    renderStatsFooter: function () {
        var stats = this.selected.stats;

        if (stats.isEmpty()) {
            this.$('.daily-stats tfoot').html('<tr><td colspan="4">No stats</td></tr>');
            this.$('.total-impressions').text(0);
            this.$('.total-clicks').text(0);
            this.$('.total-ctr').text('0.0%');
            return true;
        }

        var totalImpressions = stats.getTotalImpressions();
        var totalClicks = stats.getTotalClicks();

        this.$('.total-impressions').text(numeral(totalImpressions).format('0,0'));
        this.$('.total-clicks').text(numeral(totalClicks).format('0,0'));
        var ctr = (totalImpressions === 0 ) ? '-' :  numeral(totalClicks / totalImpressions).format('0.00%');
        this.$('.total-ctr').text(ctr);
        return stats;
    },

    templateHelpers: function () {

        var ret = {
            campaign_id: this.model.id,
            ads: this.model.ads.toJSON(),
        };

        if (this.selected) {
            var ad = this.selected;
            var goal = ad.goal();
            var total = ad.getTotalTarget();
            var filled = ad.stats.getTotalFilled(total, goal);
            var delivered = ad.stats.getTotalDelivered(goal);

            ret = _.extend(ret, {
                inited                   : ad.hasSynced(),
                goal                     : S(goal).capitalize(),
                ad_start                 : moment.utc(ad.get('start')).format('YYYY-MM-DD HH:mm'),
                ad_end                   : moment.utc(ad.get('end')).format('YYYY-MM-DD HH:mm z'),
                duration                 : ad.formatDuration(),
                time_elapsed             : ad.formatTimeElapsed('nice'),
                time_elapsed_percentage  : ad.formatTimeElapsed('percentage'),
                time_remaining           : ad.formatTimeRemaining('nice'),
                time_remaining_percentage: ad.formatTimeRemaining('percentage'),
                time_today_percentage    : ad.formatDailyElapsed(),
                total_target             : numeral(total).format('0,0'),
                total_delivered          : numeral(delivered).format('0,0'),
                total_filled             : numeral(filled).format('0.00%', Math.floor),
                total_remaining          : numeral(total - delivered).format('0,0')
            });
        }

        return ret;
    },

    bindings: {
        '.flight'                            : {
            observe: ['start', 'end'],
            update : function ($el, vals) {
                var template = _.template('<time><%=start%></time> to <time><%=end%></time>');
                $el.html(template({
                    start: moment.utc(vals[0]).format('YYYY-MM-DD HH:mm'),
                    end  : moment.utc(vals[1]).format('YYYY-MM-DD HH:mm')
                }))
            }
        }
    }
});



module.exports = ProgressView;
