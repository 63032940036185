'use strict';
var flux = require('scripts/core/flux');
var http = require('scripts/core/http');

flux.addActions({
    router: {
        navigate: function (route) {
            this.dispatch('router_navigate', route);
        }
    }
});