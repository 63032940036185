'use strict';
var dividerTemplate = require('./templates/slider-divider');
var sliderTemplate = require('./templates/slider');
var sliderEmptyTemplate = require('./templates/slider-empty');

/**
 * Implements getValues and setValues
 */


/**
* get data for building slider
* 24 hours each of which is divided by 4, so smallest unit is 15 mins
*
* only whole hour has a label
* @return [{id: __, title: __, label: __ }]
*/
var hs = _.map(_.range(24), function (num) {return num < 10 ? '0' + num : '' + num;});
var ms = ['00', '15', '30', '45'];

var daypartScales = (function () {
    // 24 hours
    var options = _.map(_.range(24), function (h) {

        // divide each hour into 4 parts, each part is 15 minutes
        return _.map(_.range(4), function (m) {
            var option = {id: h * 4 + m, title: hs[h] + ':' + ms[m]};

            // give a label to mark the start of the hour
            if (m === 0 && _.parseInt(h) % 4 === 0) {
                _.extend(option, {label: hs[h]});
            }

            return option;

        }, this);

    }, this);

    return _.flatten(options);
})();


var Model = Mario.Model.extend({
    defaults: {
        // start label
        'start': '',

        // end label
        'end': '',

        // position on slider in px
        'x0': 0,
        'x1': 0
    },

    getScaleIndex: function (which) {

        var label = this.get(which);

        if (label.match(/24:00/)) {
            return 0;
        }
        return hs.indexOf(label.slice(0, 2)) * 4 + ms.indexOf(label.slice(3, 5));
    },

    // based on label
    setPositions: function (unit) {
        var startIdx = this.getScaleIndex('start'),
            endIdx = this.getScaleIndex('end');

        if (endIdx === 0) 
            endIdx = hs.length * 4 + 1;

        this.set('x0', unit * startIdx, {silent: true});
        this.set('x1', unit * endIdx, {silent: true});
    },

    // based on position
    setLabels: function (unit) {

        var startIdx = Math.ceil(this.get('x0') / unit);
        var endIdx = Math.ceil(this.get('x1') / unit);

        this.set('start', daypartScales[startIdx].title, {silent: true});
        if (endIdx >= daypartScales.length) {
            this.set('end', '24:00');
        }  else {
            this.set('end', daypartScales[endIdx].title, {silent: true});
        }
    }

});

var DaypartsCollection = Mario.Collection.extend({model: Model});


var DividerView = Mario.ItemView.extend({

    template: dividerTemplate,
    className: 'divider',
    attributes: {
        'title': '',
        'data-animation': false,
        'data-toggle': 'tooltip',
        'data-placement': 'top'
    },

    events: {
        'click .remove' : 'removeDivider'
    },

    removeDivider: function () {
        this.model.collection.remove(this.model);
    },

    templateHelpers: function () {
        return {read_only: this.options.readOnly};
    },


    initialize: function () {
        this.listenTo(this.model, 'change:x0 change:x1', function (m) {
            var unit = this.$el.closest('.widget-slider').find('.time-unit').outerWidth(true);
            m.setLabels(unit);
            this.updateLabel();
        });
        this.listenTo(this.model, 'change:timezone', function () {
            this.updateLabel();
        });
    },

    updateLabel: function (model) {
        var startText = this.model.get('start');
        var endText = this.model.get('end');
        var tz = this.model.get('timezone');

        this.$el.attr('title', startText + ' ~ ' + endText + ' ' + tz)
            .tooltip({trigger: 'manual'})
            .tooltip('fixTitle').tooltip('show');
    },

    onRender: function () {

        var x0 = this.model.get('x0');
        var x1 = this.model.get('x1');

        this.$el.css({left: x0 + 'px'});

        this.$el.width(x1 - x0);

        var that = this;
        if (!this.options.readOnly) {
            this.$el.resizable({
                handles: 'w, e',
                containment: 'parent',
                resize: function (ev, ui) {
                    that.model.set({
                        'x0': $(ui.element).position().left,
                        'x1': $(ui.element).position().left + $(ui.element).width()
                    });
                    return true;
                }
            });
            this.$el.draggable({
                axis: 'x',
                containment: 'parent',
                drag: function (ev, ui) {
                    that.model.set({
                        'x0': $(this).position().left,
                        'x1': $(this).position().left + $(this).width()
                    });
                    return true;
                }
            });
        }
    },

    onShow: function () {
        this.updateLabel();

        if (this.options.readOnly) {
            this.undelegateEvents();
        } else {
            this.delegateEvents();
        }
    },

    onDestroy: function () {
        this.$el.tooltip('destroy');
    }
});

/**
 * @class Slider
 *
 * Slider widget for selecting day parts
 *
 * @param {boolean} readOnly
 */

var Slider = Mario.CompositeView.extend({

    childViewContainer: '.dividers',
    childView: DividerView,

    attributes: {
        'data-id' : 'dayparts'
    },

    className: 'widget-slider hide',

    childViewOptions: function () {
        return {
            readOnly: this.readOnly
        };
    },

    initialize: function (options) {
        options = options || {};
        this.readOnly = options.readOnly;
        this.collection = new DaypartsCollection();
        this.listenTo(this.collection, 'change', function () {
            this.$el.trigger('change');
        });

        this._timezone = this.options.timezone;

        this.listenTo(this.collection, 'add', function (m) {
            m.set('timezone', this._timezone);
        });


        this.on('timezone', function (timezone) {
            this._timezone = timezone;

            this.collection.each(function (divider) {
                divider.set('timezone', timezone);
                this._timezone = timezone;
            }, this);
        });
    },

    getTemplate: function () {
        return sliderTemplate;
    },

    templateHelpers: function () {
        return {units: daypartScales};
    },

    events: {
        'click      .add'      : 'addDivider',
        'mousemove  .dividers' : 'moveAdd',
        'mouseleave .dividers' : 'hideAdd',
        'mouseover  .divider'  : 'hideAdd',
        'click      .dividers' : 'clickDivider'
    },

    onShow: function () {
        this.on('realshow', this.onRealshow);
    },

    /**
     * this should be called by parent view by triggering a realshow event
     *
     * this way helps to layout the units correctly
     *
     */
    onRealshow: function () {
        this.$el.removeClass('hide').fadeIn();

        // current width
        var cw = this.$el.parent().width();
        var adjustedWidth = 0, unitWidth = 0;

        do {
            adjustedWidth += daypartScales.length;
            unitWidth += 1;
        } while (adjustedWidth <= cw);

        // deduct away the 1px border
        this.$('.time-unit').width(unitWidth - 1);
        this.$el.width(adjustedWidth);

        if (this.readOnly) {
            this.undelegateEvents();
        } else {
            this.delegateEvents();
        }
    },

    /**
    * add divider UI
    * @param {[type]} ev, can be null
    */
    addDivider: function (ev) {

        var m = new Model();
        m.set({
            'x0': ev.pageX - this.$el.offset().left,
            'x1': ev.pageX + 10 - this.$el.offset().left
        });

        this.collection.add(m);
    },

    getUnitLength: function () {
        return this.$el.outerWidth(true) / daypartScales.length;
    },

    initRanges: function () {
        this.collection.each(function (part) {
            part.setPositions(this.getUnitLength());
        }, this);
    },

    clickDivider: function (ev) {
        if (!ev || ev.target === ev.currentTarget) {
            if (this.$('.add').is(':visible')) {
                return false;
            }
        }
    },

    hideAdd: function (ev) {
        if (!ev) {
            this.$('.add').stop(true, true).fadeOut(100);
        } else {
            var mx = ev.pageX - this.$el.offset().left, my = ev.pageY - this.$el.offset().top;
            var ax = this.$('.add').position().left, ay = this.$('.add').position().top;
            var dx = mx - ax, dy = my - ay;
            var isOverAddSpan = dx >= 0 && dx < this.$('.add').width() && dy >= 0 && dy < this.$('.add').height();
            if (!isOverAddSpan) {
                this.$('.add').stop(true, true).fadeOut(100);
            }
        }
    },

    moveAdd: function (ev) {
        if (ev.target === ev.currentTarget ) {
            if (_.isEmpty(this.$('.ui-resizable-resizing')) &&
                _.isEmpty(this.$('.ui-draggable-dragging'))) {
                var rects = ev.target.getBoundingClientRect(),
                xOffset = ev.clientX - rects.left;
                if (xOffset < 0) {
                    xOffset = rects.left;
                }
                this.$('.add').show();
                this.$('.add').css({left: xOffset - 4});
            } else {
                this.hideAdd();
            }
        }
    },

    getValues: function () {
        return _.compact(this.collection.map(function (m) {
            if (!_.isEmpty(m.get('start')) && !_.isEmpty(m.get('end'))) {
                return m.pick('start', 'end');
            } else {
                return null;
            }
        }, this));
    },

    setValues: function (dayparts) {

        this.collection.reset(dayparts, {silent: true});
        this.collection.each(function (m) {
            var unit = this.$('.time-unit').outerWidth(true);
            m.setPositions(unit);
            m.set('timezone', this._timezone);
        }, this);

        this.collection.trigger('reset', this.collection);
    }
});


module.exports = Slider;
