'use strict';

var appController = require('./controller');
var cache = require('./cache');
var env = require('scripts/env');
var SidebarView = require('scripts/layouts/sidebar');
var Session = require('scripts/entities/session');
var WorkspaceView = require('scripts/layouts/workspace');
var flux = require('scripts/core/flux');
/**
 * Initialize application object.
 */

var app = new Mario.Application();

app.onBeforeStart = onBeforeStart;
app.addInitializer(initializeCache);
app.addInitializer(initializeBugReport);
app.addInitializer(initializeSession);
app.addInitializer(initializeAppRoutes);
app.addInitializer(initializeUI);
app.addInitializer(initializeComponents);
app.onStart = onStart;


exports.start = function () {
    app.start();
};

exports.app = app;

/**
 * wrap on Backbone.history provide easier interface
 */
app.navigate = _.wrap(Backbone.history.navigate, function (fn, path, options) {
    options = _.defaults({}, options, {trigger: true, replace: false});
    return fn.call(Backbone.history, path, options);
});

var Components = Mario.Collection.extend({idAttribute: 'key', comparator: function (a, b) {
    var order = ['campaigns', 'audits', 'admin', 'accounting', 'profile'];
    var aIdx = order.indexOf(a.get('key')), bIdx = order.indexOf(b.get('key'));
    return aIdx < bIdx ? -1 : 1;
}});

function onBeforeStart() {
    this.components = new Components();
    require('./hooks');
}

function onStart() {
    flux.actions.fetchProfile();

    this.workspace.show(new WorkspaceView());

    // start routing, trigger the handler matching current url
    Backbone.history.start({pushState: true});
}

/**
 * Initialize bug tracking and analytics
 * @return {null}
 */
function initializeBugReport() {

    if (env.BUG_REPORTING_ENABLED) {
        Bugsnag.appVersion = env.VERSION;
    }
}

/**
 * Initialize Cache
 *
 * Cache is the gateway for serving model/collection data
 *
 * @return {null}
 */
function initializeCache() {
    debug('initializeCache');

    this.cache = cache;
    this.cache.reset();
}

/**
 * Initialize UI
 *
 * Add main regions, they will be used in the subsequent initialization steps.
 *
 * @return {null}
 */
function initializeUI() {
    debug('initializeUI');

    this.addRegions({
        sidebar: '.sidebar',
        workspace: '.workspace',
        popup: '#popup .modal-content'
    });
    this.sidebar.show(new SidebarView({model: this.session, components: this.components}));

    var that = this;
    $('#popup').on('hide.bs.modal', function () {
        that.popup.destroy();
    });
}

/**
 * Initialize application router
 *
 * Controller is the application object itself
 *
 * @return {null}
 */
function initializeAppRoutes() {
    debug('initializeAppRoutes');

    _.extend(this, appController);

    this.router = new Mario.AppRouter({
        appRoutes: {
            'signout': 'signout',
            ''       : 'routeToLandingPage',
            '*splat' : 'routeNotFound'
        },

        controller: this
    });
}

/**
 * Initialize Session Object
 *
 * Session will be fetched from local storage upon initialized
 *
 * @return {null}
 */
function initializeSession() {
    debug('initializeSession');
    this.session = new Session();

    // delegate `app.can` to session object's `can` method
    this.can = _.bind(this.session.can, this.session);
}

/**
 * Initialize Components
 *
 * It will check permissions before loading the each component.
 *
 * @return {null}
 */
function initializeComponents() {
    debug('initializeComponents');
    var errorsComponent = require('scripts/components/errors');
    var profileComponent = require('scripts/components/profile');

    // always load errors
    loadComponent(errorsComponent);
    loadComponent(profileComponent);
    checkAndLoadCampaigns();
    checkAndLoadAdmin();
    checkAndLoadAudits();
    loadAccounting();

    if (this.session.isValid()) {
        this.session.startHeartbeating();
    }
}

/**
 * Load component
 *
 * @param {Object} comp component to load
 * @param {string} key key to identify this component, used in the menu
 */
function loadComponent(comp) {

    if (app.components.contains(comp)) { return; }

    // start the component
    var compObj = comp.start();
    app.components.add(compObj);
}

function checkAndLoadAudits() {
    var auditComponent = require('scripts/components/audits');
    if (app.session.isValid() && app.session.get('has_child')) {
        if (app.can('audit', 'campaigns')) {
            loadComponent(auditComponent, 'audit');
            loadAuditCount();
        }
    }
}

function loadAuditCount() {
    $.ajax({
        url: 'audits/count?status=pending',
        dataType: 'json',
        crossDomain: true
    }).done(function (resp) {
        if (_.isNumber(resp.count)) {
            // TODO add back audit count to the menu
            // app.sidebar.currentView.addMenuCount(resp.count);
        }
    });
}

function checkAndLoadAdmin() {
    var adminComponent = require('scripts/components/admin');

    if (app.can('list', 'view', 'roles') || app.can('list', 'view', 'users')) {
        loadComponent(adminComponent, 'admin');
    }
}

function checkAndLoadCampaigns() {
    var campaignsComponent = require('scripts/components/campaigns');

    if (app.can('list', 'view', 'campaigns')) {
        loadComponent(campaignsComponent, 'campaigns');
    }
}

function loadAccounting () {
    var accountingComponent = require('scripts/components/accounting');

    loadComponent(accountingComponent, 'accounting');
}