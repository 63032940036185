'use strict';
var template = require('./templates/editor');
var Macros = require('scripts/common/constants/macros');

exports.getEditor = function (container) {
    var id = _.uniqueId('_editor-');
    container.html(template({id: id}));
    var editor = ace.edit(id);
    editor.session.setOption('useWorker', false);
    editor.getSession().setMode('ace/mode/html');
    editor.getSession().setTabSize(2);
    editor.getSession().on('change', function(e) {
        $('#' + id).trigger('change');
    });

    editor.setShowInvisibles(true);
    editor.setDisplayIndentGuides(true);
    editor.getSession().setUseWrapMode(true);

    var tools = ace.require('ace/ext/language_tools');
    var Range = ace.require('ace/range').Range;

    editor.setOptions({enableBasicAutocompletion: true, enableLiveAutocompletion: true});

    var rhymeCompleter = {
        getCompletions: function(editor, session, pos, prefix, callback) {
            // if (prefix.length === 0) { callback(null, []); return }
            callback(null, Macros.map(function(macro)  {
                return {caption: '%%' + macro + '%%', value: macro + '%%', meta: 'Macro'};
            }));
        }
    };

    editor.commands.on('afterExec', function(e) {
        if (e.command.name === 'insertstring') {
            var pos = editor.getCursorPosition();
            var r = new Range(pos.row, pos.column - 2, pos.row, pos.column);
            var lastTwo = editor.getSession().getDocument().getTextRange(r);

            if (lastTwo === '%%') {
                editor.execCommand('startAutocomplete');
            }
        }
    });

    tools.addCompleter(rhymeCompleter);
    return editor;
};
