<div class="row">
    <div class="col-xs-9">
        <input type="text" class="form-control" value="{{url}}">
    </div>
    <div class="col-xs-3">
        <i class="fa fa-plus btn"></i>
        <i class="fa fa-minus btn"></i>
    </div>

    <div class="col-xs-12">
        <div class="help-block"></div>
    </div>
</div>
