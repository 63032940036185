<!-- file: organization/templates/layout.hbs -->
<div class="row">
    <div class="col-sm-8 ">
        <div class="organization-detail">
        </div>
    </div>

    <div class="col-sm-4">
        {{#if can_create_org}}
        <div style="margin-bottom: 10px;">
            <button class="btn-primary btn new-organization">
                <i class="fa fa-building"></i>
                New Organization
            </button>
            <div class="organization-new"></div>
        </div>
        {{/if}}

        <div class="form-horizontal">
            <label for="">Switch Organization</label>
            <select class="form-control switch-organization"></select>
        </div>
    </div>
</div>
<div class="row">
    <div class="region-for-show2"></div>
</div>
