'use strict';
var flux = require('scripts/core/flux');

var CustomFluxMixin = function (handlerToStoreMap) {
    var storeNames = Array.prototype.slice.call(arguments);

    return {

        componentDidMount: function() {
            var self = this;

            Object
                .keys(handlerToStoreMap)
                .forEach(function (handlerName) {
                    var storeName = handlerToStoreMap[handlerName];

                    flux.store(storeName).on('change', self[handlerName]);
                });
        },

        componentWillUnmount: function() {
            var self = this;

            Object
                .keys(handlerToStoreMap)
                .forEach(function (handlerName) {
                    var storeName = handlerToStoreMap[handlerName];

                    flux.store(storeName).removeListener('change', self[handlerName]);
                });
        },

        getInitialState: function () {
            this.flux = flux;
        }
    };
};

CustomFluxMixin.listenTo = function (eventName, handlerName, storeName) {
    return {
        componentDidMount: function() {
            flux.store(storeName).on(eventName, this[handlerName]);
        },

        componentWillUnmount: function() {
            flux.store(storeName).removeListener(eventName, this[handlerName]);
        },

        getInitialState: function () {
            this.flux = flux;
        }
    };
};


module.exports = CustomFluxMixin;