'use strict';

// @TODO: selected should be one dimensional
var debugMode = true;
var flux = require('scripts/core/flux');
var fc = flux.constants;
var campaigns = require('scripts/core/campaign-collection'); // to be deprecated
var contries = require('scripts/common/constants/countries');
var regions = require('scripts/common/constants/regions');
var op = objectPath;

var configurationSchema = {
    // isLoading: false,
    hasErrors: false,
    campaignStatus: '',
    errors: [],
    statsMatricsType: 'clicks',   // @TODO I think this is no longer in used, to check and remove it
    control: {
        'statsMetricsType': 'impressions',
        'statsMetricsUnitType': 'absolute', // absolute, relativePercentage
        'tabType': 'dimension' //dimension, pivotTable
    },
    stats_overall: [],
    stats_overall_isLoading: false,
    stats_accretedByDate: [],
    overall: {
        stats: [],
        isLoading: false
    },

    scopes: {
        'ad': {
            auto_hidden: false,
            nameLookup: {},
            selected: [],
            isLoading: false
        },
        'flight': {
            auto_hidden: false,
            nameLookup: {},
            selected: [],
            isLoading: false
        },
        'creatives': {
            auto_hidden: false,
            nameLookup: {},
            selected: [],
            isLoading: false

        },
        'geotargets': {
            auto_hidden: false,
            nameLookup: {},
            selected: [],
            isLoading: false

        },
        'weekparts': {
            auto_hidden: false,
            nameLookup: {},
            selected: [],
            isLoading: false

        },
        'dayparts': {
            auto_hidden: false,
            nameLookup: {},
            selected: [],
            isLoading: false

        },
        'devices': {
            auto_hidden: false,
            nameLookup: {},
            selected: [],
            isLoading: false

        },
        'languages': {
            auto_hidden: false,
            nameLookup: {},
            selected: [],
            isLoading: false

        },
        'ages': {
            auto_hidden: false,
            nameLookup: {},
            selected: [],
            isLoading: false

        },
        'genders': {
            auto_hidden: false,
            nameLookup: {},
            selected: [],
            isLoading: false

        },
        'creative formats': {
            auto_hidden: false,
            nameLookup: {},
            selected: [],
            isLoading: false

        },
        'creative sizes': {
            auto_hidden: false,
            nameLookup: {},
            selected: [],
            isLoading: false

        }
    },

    components: [
        { 'name': 'geo_country',        'type': 'bar',      'group': ['geo_country'],         'isLoading': false },
        { 'name': 'geo_country_region', 'type': 'bar',      'group': ['geo_country_region'],  'isLoading': false },
        { 'name': 'device_os',          'type': 'bar',      'group': ['device_os'],           'isLoading': false },
        { 'name': 'gender',             'type': 'bar',      'group': ['gender'],              'isLoading': false },
        { 'name': 'age',                'type': 'bar',      'group': ['age'],                 'isLoading': false },
        { 'name': 'hour|day_of_week',   'type': 'heatmap',  'group': ['hour', 'day_of_week'], 'isLoading': false },
        { 'name': 'date',               'type': 'summary',  'group': ['date'],                'isLoading': false }
    ],

    dimensions2: {
        date: {
            isLoading: false,
            selected: []
        },
        gender: {
            isLoading: false,
            // selected: ['M']
            selected: []
        },
        age: {
            isLoading: false,
            selected: []
        },
        geo_country: {
            isLoading: false,
            selected: []
        },
        device_os: {
            isLoading: false,
            selected: []
        },
        geo_country_region: {
            isLoading: false,
            selected: []
        },
        hour: {
            isLoading: false,
            // selected: ["0", "1", "2", "3", "4", "5", "6", "7" , "8", "9", "10", "11"]
            selected: []
        },
        day_of_week: {
            isLoading: false,
            // selected: [2]
            selected: []
        }
    },
    filterSummaryItems: [],
    filterSummaryItems_scope: [],
    pivotTable: {
        sortDirection: 1, // descending === -1
        sortColumn: 'dimension',
        maxSplits: 8,
        selectedSplit: [{
            label: 'Date',
            value: 'date'
        }],
        availableSplitOptions: [
            {
                label: 'Age',
                value: 'age',
                visible: true
            }, {
                label: 'Date',
                value: 'date',
                visible: false
            }, {
                label: 'Day Of Week',
                value: 'day_of_week',
                visible: true
            }, {
                label: 'Device OS',
                value: 'device_os',
                visible: true
            }, {
                label: 'Gender',
                value: 'gender',
                visible: true
            }, {
                label: 'Country',
                value: 'geo_country',
                visible: true
            }, {
                label: 'Region',
                value: 'geo_country_region',
                visible: true
            }, {
                label: 'Hour',
                value: 'hour',
                visible: true
            }, {
                label: 'Ad Group',
                visible: true,
                value: [
                    {
                        label: 'Ad',
                        value: 'ad',
                        parent: 'Ad Group',
                        visible: true
                    }, {
                        label: 'Ages',
                        value: 'ages',
                        parent: 'Ad Group',
                        visible: true
                    }, {
                        label: 'Creative Formats',
                        value: 'creative formats',
                        parent: 'Ad Group',
                        visible: true
                    }, {
                        label: 'Creative Sizes',
                        value: 'creative sizes',
                        parent: 'Ad Group',
                        visible: true
                    }, {
                        label: 'Creatives',
                        value: 'creatives',
                        parent: 'Ad Group',
                        visible: true
                    }, {
                        label: 'Dayparts',
                        value: 'dayparts',
                        parent: 'Ad Group',
                        visible: true
                    }, {
                        label: 'Devices',
                        value: 'devices',
                        parent: 'Ad Group',
                        visible: true
                    }, {
                        label: 'Flight',
                        value: 'flight',
                        parent: 'Ad Group',
                        visible: true
                    }, {
                        label: 'Genders',
                        value: 'genders',
                        parent: 'Ad Group',
                        visible: true
                    }, {
                        label: 'Geotargets',
                        value: 'geotargets',
                        parent: 'Ad Group',
                        visible: true
                    }, {
                        label: 'Languages',
                        value: 'languages',
                        parent: 'Ad Group',
                        visible: true
                    }, {
                        label: 'Weekparts',
                        value: 'weekparts',
                        parent: 'Ad Group',
                        visible: true
                    }
                ]
            }],
        expandedRows: { },
        focusedParents: [],
        focusedRow: null,
        totalImpressions: 0,
        totalClicks: 0,
        canAddMoreSplits: true,
        isLoading: false
    }
};

var dictionary = prepareDictionary();
if (debugMode) window.dic = dictionary;

var configuration = (function () {
    var _state = {};
    if (debugMode) window.ss = _state;

    return function (indexName) {
        if (!indexName) {
            throw new Error('@indexName is required');
        }

        if (!_state[indexName]) {
            _state[indexName] = _.cloneDeep(configurationSchema);
        }

        return {
            get: function (path, defaultValue) {
                var stringified = JSON.stringify(objectPath.get(_state[indexName], path, defaultValue));
                if (stringified === undefined) {
                    return undefined;
                } else {
                    return JSON.parse(stringified);
                }
            },

            set: function (path, value) {
                objectPath.set(_state[indexName], path, value);
            },

            del: function (path) {
                objectPath.del(_state[indexName], path);
            }
        };
    };
}());


var ReportStatsStore = Fluxxor.createStore({
    initialize: function (options) {
        var self = this;

        this.bindActions(
            fc.reportStats_fetch_start,             'onDimensionStats_fetch_start',
            fc.reportDimensionStats_fetch_success,  'onDimensionStats_fetch_success',

            fc.reportPivotTableStats_fetch_start,   'onReportPivotTableStats_fetch_start',
            fc.reportPivotTableStats_fetch_success, 'onReportPivotTableStats_fetch_success',
            fc.reportStats_fetch_fail,              'onReportStats_fetch_fail',

            fc.report_clearSingleDimensionFilter,   'onReport_clearSingleDimensionFilter',
            fc.report_clearAllDimensionFilter,      'onReport_clearAllDimensionFilter',
            fc.report_dimensionSelection_change,    'onDimensionSelectionChange',
            fc.report_dimensionTwoSelections_change,'onDimensionTwoSelectionsChange',

            fc.reportPivotTableExpandRow,           'onReportPivotTableExpandRow',
            fc.reportPivotTableCollapseRow,         'onReportPivotTableCollapseRow',

            fc.reportPivotTableSort,                'onReportPivotTableSort',
            fc.reportStatsAddSplitToPivotTable,     'onReportStatsAddSplitToPivotTable',
            fc.reportStatsRemoveSplitToPivotTable,  'onReportStatsRemoveSplitFromPivotTable',
            fc.reportPivotTableFocusOnRow,          'onReportPivotTableFocusOnRow',

            fc.report_statsMetricsType_change,      'onReport_statsMetricsType_change',
            fc.report_statsUnitSelection_change,    'onReport_statsUnitSelection_change',
            fc.report_controlTabSelection_change,   'onReport_controlTabSelection_change',

            fc.report_scopeSelection_change,        'onReport_scopeSelection_change',
            fc.report_clearAllScopeFilters,         'onReport_clearAllScopeFilters',
            fc.report_clearSingleScopeFilters,      'onReport_clearSingleScopeFilters',

            fc.reportScopeDetail_fetch_success,     'onReportScopeDetail_fetch_success',
            fc.reportScopeStats_fetch_success,      'onReportScopeStats_fetch_success',
            fc.reportScopeStats_fetch_start,        'onReportScopeStats_fetch_start'
        );

        this.debouncedEmitChange = _.debounce(function () {
            self.emit('change');
        }, 1);
    },


    //  -----------------------------------------------------------------------  //
    //  START: Ajax
    onDimensionStats_fetch_success: function (payload) {
        this.waitFor(['campaign-report-stats-resource'], function() {

            // var dimensionName = payload.group[0];
            var dimensionName = payload.group.join('|'),
                campaignId = payload.campaign_id;

            if (dimensionName === 'date') { // overall stats

                UIBuilder.stats_overall(payload.campaign_id);
                UIBuilder.stats_accretedByDate(payload.campaign_id);

            }

            var components = configuration(campaignId).get('components');
            var componentIndex = _.findIndex(components, { name: dimensionName });
            configuration(campaignId).set(`components.${componentIndex}.isLoading`, false);

            this.debouncedEmitChange();
        });
    },

    onDimensionStats_fetch_start: function (payload) {
        var { campaign_id, dimensionName } = payload;

        var components = configuration(campaign_id).get('components');
        var componentIndex = _.findIndex(components, { name: dimensionName });
        configuration(campaign_id).set(`components.${componentIndex}.isLoading`, true);

        this.debouncedEmitChange();
    },

    onReportPivotTableStats_fetch_start: function (payload) {
        PivotTableBuilder.loadingStarted(payload.campaign_id);
        this.debouncedEmitChange();
    },

    onReportPivotTableStats_fetch_success: function (payload) {
        this.waitFor(['campaign-report-stats-resource'], function () {
            PivotTableBuilder.loadingDone(payload.campaign_id);
            PivotTableBuilder.tryAutoExpandTopLevel(payload.campaign_id);

            this.debouncedEmitChange();
        });
    },

    onReportScopeDetail_fetch_success: function (payload) {
        ScopeBuilder.createScopeNameLookup(payload.campaign_id, payload.responseData);
        ScopeBuilder.assignHiddenScopes(payload.campaign_id, payload.responseData);
        this.debouncedEmitChange();
    },

    onReportScopeStats_fetch_success: function (payload) {
        this.waitFor(['campaign-report-stats-resource'], function (resourceStore) {

            var scopeName = payload.scope,
                campaignId = payload.campaign_id;

            configuration(campaignId).set(['scopes', scopeName, 'isLoading'], false);

            this.debouncedEmitChange();
        });
    },

    onReportScopeStats_fetch_start: function (payload) {
        configuration(payload.campaign_id).set(['scopes', payload.scope, 'isLoading'], true);

        this.debouncedEmitChange();
    },
    //  END: Ajax
    //  -----------------------------------------------------------------------  //



    //  -----------------------------------------------------------------------  //
    //  START: UI Action Handlers
    onDimensionSelectionChange: function (payload) {
        var { campaignId, dimensionName, selection } = payload;
        configuration(campaignId).set(`dimensions2.${dimensionName}.selected`, selection);
        this.debouncedEmitChange();
    },

    onDimensionTwoSelectionsChange: function (payload) {
        const { campaignId, selections } = payload;
        _.each(selections, (selection, dimensionName) => {
            configuration(campaignId).set(`dimensions2.${dimensionName}.selected`, selection);
        });
        this.debouncedEmitChange();
    },
    //  END: UI Action Handlers
    //  -----------------------------------------------------------------------  //



    //  -----------------------------------------------------------------------  //
    //  START: Action handlers component (Control)
    onReport_statsMetricsType_change: function(payload) {
        var campaignId = payload.campaignId;

        configuration(campaignId).set(['control', 'statsMetricsType'], payload.statsMetricsType);

        // var dimensions = DimensionManager.getAllDimensions(campaignId);

        this.debouncedEmitChange();
    },

    onReport_statsUnitSelection_change: function(payload) {
        var campaignId = payload.campaignId;

        configuration(campaignId).set(['control', 'statsMetricsUnitType'], payload.statsMetricsUnitType);

        // var dimensions = DimensionManager.getAllDimensions(campaignId);

        this.debouncedEmitChange();
    },

    onReport_controlTabSelection_change: function(payload) {
        configuration(payload.campaignId)
            .set(['control', 'tabType'], payload.tabType);

        this.debouncedEmitChange();
    },

    onReport_clearSingleDimensionFilter: function (payload) {
        var { campaignId, dimensionName } = payload;

        configuration(campaignId).set(`dimensions2.${dimensionName}.selected`, []);

        this.debouncedEmitChange();
    },

    onReport_clearAllDimensionFilter: function (payload) {
        var campaignId = payload.campaignId;
        var dimensions = configuration(campaignId).get('dimensions2');


        _.each(dimensions, (v, dimensionName) =>
            configuration(campaignId).set(`dimensions2.${dimensionName}.selected`, []));

        this.debouncedEmitChange();
    },

    onReport_clearSingleScopeFilters: function (payload) {
        var scopeName = payload.scope,
            campaignId = payload.campaignId;

        configuration(campaignId).set(`scopes.${scopeName}.selected`, []);

        this.debouncedEmitChange();
    },

    onReport_clearAllScopeFilters: function (payload) {
        _.each(configuration(payload.campaignId).get('scopes', {}), (v1, k1) => {

            // Remove scope settings
            configuration(payload.campaignId).set(`scopes.${k1}.selected`, []);
            // _.each(op.get(v1, 'selected'), (v2, k2) => {
            //     configuration(payload.campaignId).set(`scopes.${k1}.selected`, []);
            // });
        });

        this.debouncedEmitChange();
    },

    onReport_scopeSelection_change: function (payload) {
        configuration(payload.campaignId).set(['scopes', payload.scopeName, 'selected'], payload.selection);

        this.debouncedEmitChange();
    },
    //  END: Action handlers component (Control)
    //  -----------------------------------------------------------------------  //



    //  -----------------------------------------------------------------------  //
    //  START: Pivot Table
    onReportStatsAddSplitToPivotTable: function (payload) {
        PivotTableBuilder.addSplit(payload.campaignId, payload.selection);
        this.debouncedEmitChange();
    },

    onReportStatsRemoveSplitFromPivotTable: function (payload) {
        PivotTableBuilder.removeSplit(payload.campaignId, payload.selection);
        this.debouncedEmitChange();
    },

    onReportPivotTableExpandRow: function (payload) {
        PivotTableBuilder.expandRow(payload.campaignId, payload.rowKey);

        this.debouncedEmitChange();
    },

    onReportPivotTableCollapseRow: function (payload) {
        PivotTableBuilder.collapseRow(payload.campaignId, payload.rowKey);

        this.debouncedEmitChange();
    },

    onReportPivotTableSort: function (payload) {
        PivotTableBuilder.sortColumn(payload.campaignId, payload.sortColumn);

        this.debouncedEmitChange();
    },

    onReportPivotTableFocusOnRow: function (payload) {
        PivotTableBuilder.highlightParent(payload.campaignId, payload.rowKey);
        this.debouncedEmitChange();
    },
    //  END: Pivot Table
    //  -----------------------------------------------------------------------  //





    //  -----------------------------------------------------------------------  //
    //  START: Component Getters (configuration)

    getStatsMetricType: function (campaignId) {
        return configuration(campaignId).get(['control', 'statsMetricsType']);
    },

    isRelativePercentage: function (campaignId) {
        var statsMetricsUnitType = configuration(campaignId).get(['control', 'statsMetricsUnitType']),
            isRelativePercentage = (statsMetricsUnitType === 'relativePercentage');

        return isRelativePercentage;
    },

    getDimensionSettings: function (campaignId) {
        return configuration(campaignId).get('dimensions2');
    },

    getScopeSettings: function (campaignId) {
        var settings = configuration(campaignId).get('scopes', {});

        var visibleSettings = Object.keys(settings).reduce((sum, scopeName) => {
            if (op.get(settings, `${scopeName}.auto_hidden`)) {
                return sum;
            }

            sum[scopeName] = settings[scopeName];

            return sum;
        }, {});

        return visibleSettings;
    },

    getReportConfiguration: function (campaignId) {
        return configuration(campaignId).get();
    },

    getConfig_scopes: function (campaignId) {
        var scopes = configuration(campaignId).get('scopes', {});
        var selectedScopes = Object.keys(scopes)
            .reduce(function (sum, scopeName) {

                if (!objectPath.get(scopes[scopeName],'auto_hidden')) {
                    sum[scopeName] = {
                        selected: objectPath.get(scopes[scopeName], 'selected', []),
                        nameLookup: objectPath.get(scopes[scopeName], 'nameLookup', {})
                    };
                }

                return sum;
            }, {});

        return selectedScopes;
    },

    getConfig_dimensions: function (campaignId) {
        return {
            date: configuration(campaignId).get('dimensions2.date.selected'),
            gender: configuration(campaignId).get('dimensions2.gender.selected'),
            age: configuration(campaignId).get('dimensions2.age.selected'),
            geo_country: configuration(campaignId).get('dimensions2.geo_country.selected'),
            device_os: configuration(campaignId).get('dimensions2.device_os.selected'),
            geo_country_region: configuration(campaignId).get('dimensions2.geo_country_region.selected'),
            hour: configuration(campaignId).get('dimensions2.hour.selected'),
            day_of_week: configuration(campaignId).get('dimensions2.day_of_week.selected')
        };
    },

    getConfig_pivotTable: function (campaignId) {
        return {
            selectedSplit: configuration(campaignId).get(['pivotTable', 'selectedSplit'], [])
        };
    },

    getPivotTableConfiguration: function (campaignId) {
        return {
            settings: configuration(campaignId).get('pivotTable'),
            stats: flux.store('campaign-report-stats-resource').getStats_type(campaignId, 'pivot_table')
        };
    },
    //  END: Component Getters (configuration)
    //  -----------------------------------------------------------------------  //



    //  -----------------------------------------------------------------------  //
    //  START: Component Getters (pivot table)
    getExport_pivotTable: function (campaignId, exportFormat) {
        return PivotTableBuilder.export(campaignId, exportFormat);
    },
    //  END: Component Getters (pivot table)
    //  -----------------------------------------------------------------------  //



    //  -----------------------------------------------------------------------  //
    //  START: Component Getters (chart)
    getTotalFilteredStats_accretedByDate: function(campaignId) {
        return configuration(campaignId).get('stats_accretedByDate');
    },

    getOverallStats: function (campaignId) {
        var startDate = flux.store('campaigns').getStartDate(campaignId);

        return {
            stats: configuration(campaignId).get('stats_overall', []),
            isLoading: configuration(campaignId).get('dimensions2.date.isLoading'),
            startDate: startDate
        };
    },
    //  END: Component Getters (chart)
    //  -----------------------------------------------------------------------  //



    //  -----------------------------------------------------------------------  //
    //  START: Component Getters (heatmap)
    getHeatmap_dayWeekParts: function(campaignId) {
        var data = {
            items_heatmap: DimensionManager.getUIItems(campaignId, 'hour|day_of_week'),
            items_hour: DimensionManager.getField(campaignId, 'hour|day_of_week', 'items_hour'),
            items_dayOfWeek: DimensionManager.getField(campaignId, 'hour|day_of_week', 'items_day_of_week'),
            isLoading: DimensionManager.getLoadingState(campaignId, 'hour|day_of_week')
        };
        return data;
    },
    //  END: Component Getters (heatmap)
    //  -----------------------------------------------------------------------  //



    //  -----------------------------------------------------------------------  //
    //  START: Component Getters (state)

    getControlState: function (campaignId) {
        return configuration(campaignId).get('control');
    },
    //  END: Component Getters (state)
    //  -----------------------------------------------------------------------  //


    getDictionary () {
        return JSON.parse(JSON.stringify(dictionary));
    },
    getComponents (campaignId) {
        return configuration(campaignId).get('components');
    }
});


var ScopeBuilder = {
    createScopeNameLookup: function (campaignId, responseData) {
        _.each(responseData, function (scope) {

            var nameLookup = scope.groups.reduce(function (sum, group) {
                sum[group.id] = group.name;
                return sum;
            }, {});

            configuration(campaignId).set(['scopes', scope.id, 'nameLookup'], nameLookup);
        });
    },
    assignHiddenScopes: function (campaignId, responseData) {
        var availableSplits = configuration(campaignId).get('pivotTable.availableSplitOptions', []);

        var adGroup = _.find(availableSplits, { label: 'Ad Group' });


        _.each(responseData, function (scope) {

            // Disable Ad Group options where they've been hidden
            if (scope.auto_hidden) {
                _.remove(adGroup.value, { value: scope.id });
            }
            configuration(campaignId).set(['scopes', scope.id, 'auto_hidden'], scope.auto_hidden);
        });

        // Remove Ad Group altogether if none are available
        if (adGroup.value.length === 0) {
            _.remove(availableSplits, { label: 'Ad Group' });
        }

        configuration(campaignId).set('pivotTable.availableSplitOptions', availableSplits);
    }
};


var DimensionManager = (function () {
    var dimensionTypeLookup = _.reduce(configurationSchema.dimensions, function (sum, dimensionItems, dimensionType) {
        _.forEach(dimensionItems, function (dimensionValue, dimensionName) {
            sum[dimensionName] = dimensionType;
        });
        return sum;
    }, {});

    var getDimensionType = function (dimensionName) {
        return dimensionTypeLookup[dimensionName];
    };

    return {
        setLoadingState: function (campaignId, dimensionName, isLoading) {
            configuration(campaignId).set(['dimensions', getDimensionType(dimensionName), dimensionName, 'isLoading'], isLoading);
        },

        setUIItems: function (campaignId, dimensionName, items) {
            configuration(campaignId).set(['dimensions', getDimensionType(dimensionName), dimensionName, 'items'], items);
        },

        setSelected: function (campaignId, dimensionName, selectionField, selection) {
            configuration(campaignId).set(['dimensions', getDimensionType(dimensionName), dimensionName, 'selected', selectionField], selection);

            const selected = configuration(campaignId).get(['dimensions', getDimensionType(dimensionName), dimensionName, 'selected']);
            const selectedLength = Object.keys(selected).length;

            if (selectedLength === 2) {
                // is a heatmap so we flatten the two dimension selections into a one dimension formate
                const dataNestingOrder = op.get(_setting, ['heatMap', 'hour|day_of_week', 'dataNestingOrder']);
                setHeatmapSelected_flatten(campaignId, dimensionName, dataNestingOrder, selected);
            }
        },

        setField: function (campaignId, dimensionName, fieldName, fieldValue) {  // this should be rename to setSelectionField()
            const path = ['dimensions', getDimensionType(dimensionName), dimensionName];
            if (Object.prototype.toString.call(fieldName) === '[object Array]') {
                const pathExtended = path.concat(fieldName);
                configuration(campaignId).set( pathExtended, fieldValue);
            } else {
                configuration(campaignId).set(['dimensions', getDimensionType(dimensionName), dimensionName, fieldName], fieldValue);
            }
        },

        getField: function (campaignId, dimensionName, fieldName, defaultValue) {  // this should be rename to getSelectionField()
            const path = ['dimensions', getDimensionType(dimensionName), dimensionName];
            if (Object.prototype.toString.call(fieldName) === '[object Array]') {
                const pathExtended = path.concat(fieldName);
                return configuration(campaignId).get( pathExtended, defaultValue);
            } else {
                return configuration(campaignId).get(['dimensions', getDimensionType(dimensionName), dimensionName, fieldName], defaultValue);
            }
        },

        getLoadingState: function (campaignId, dimensionName) {
            return configuration(campaignId).get(['dimensions', getDimensionType(dimensionName), dimensionName, 'isLoading'], false);
        },

        getUIItems: function (campaignId, dimensionName) {
            return configuration(campaignId).get(['dimensions', getDimensionType(dimensionName), dimensionName, 'items'], []);
        },

        getSelected: function (campaignId, dimensionName) {
            return configuration(campaignId).get(['dimensions', getDimensionType(dimensionName), dimensionName, 'selected'], []);
        },

        getAllDimensions: function (campaignId) {
            return _.assign(
                {},
                configuration(campaignId).get('dimensions.barGraph', {}),
                configuration(campaignId).get('dimensions.heatmap', {})
            );
        }
    };
}());


var UIBuilder = {
    stats_overall: function (campaignId) {
        var out = [];
        var results = [];

        var campaignReportStats = getStatsForCampaign(campaignId);

        var stats = campaignReportStats;
        var campaignModel = flux.store('campaigns').getById(campaignId);

        var timeZoneOffset = new Date().getTimezoneOffset();
        var campaignStart_utcStartOfDay = moment(campaignModel.start).add(timeZoneOffset, 'm').format('YYYY-MM-DD');
        var campaignEnd_utcStartOfDay   = moment(campaignModel.end).add(timeZoneOffset, 'm').format('YYYY-MM-DD');

        // if (stats && stats.date && stats.date.total && stats.date.total.stats ) {
        //     do stuff with total impressions and total clicks
        // }

        if (stats && stats.date && stats.date.stats && stats.date.stats) {
            var M_range = moment.range(
                moment( campaignStart_utcStartOfDay ),
                moment( campaignEnd_utcStartOfDay )
            );

            M_range.by('days', function(M_day){
                var day_iso = M_day.toISOString();

                var matched = _.find( stats.date.stats, function(statsItem){
                    var date = statsItem.group.date;
                    var M_date = moment(date);
                    var isSame = M_date.isSame(M_day);
                    return isSame;
                });

                if (matched) {
                    results.push({
                        date: day_iso,
                        filtered_clicks: matched.clicks,
                        filtered_impressions: matched.impressions,
                        filtered_spend: matched.spend,
                        unfiltered_clicks: void 0, // to be added later
                        unfiltered_impressions: void 0 // to be added later
                    });
                } else {
                    results.push({
                        date: day_iso,
                        filtered_clicks: 0,
                        filtered_impressions: 0,
                        filtered_spend: 0,
                        unfiltered_clicks: void 0 ,  // to be added later
                        unfiltered_impressions: void 0 // to be added later
                    });
                }
            });
        }

        configuration(campaignId).set('stats_overall', results);
    },

    stats_accretedByDate: function (campaignId) {
        var out = {};
        var campaignReportStats = getStatsForCampaign(campaignId);
        var stats = campaignReportStats;

        if (stats && stats.date && stats.date.stats && stats.date.stats ) {
            var total = _.reduce( stats.date.stats, function( accretion, item){
                return {
                    clicks : accretion.clicks + item.clicks,
                    impressions :  accretion.impressions + item.impressions,
                    spend: accretion.spend + item.spend
                };
            }, {clicks: 0, impressions: 0, spend: 0 });

            var overallCtr = (total.impressions !== 0) ? total.clicks/total.impressions : 0;
            var overallCtrInPercent = overallCtr * 100;

            const overallEcpm = total.impressions ? (total.spend * 1000) / total.impressions : 0;
            const overallEcpc = total.clicks ? total.spend / total.clicks : 0;

            _.assign(out, total, {
                overallCtr: overallCtr,
                presentationClicks: numeral(total.clicks).format('0,0'),
                presentationImpressions: numeral(total.impressions).format('0,0'),
                presentationOverallCtr: numeral(overallCtrInPercent).format('0.00') + '%',
                presentationOverallSpend: numeral(total.spend).format('$0,0.00'),
                presentationOverallEcpm: numeral(overallEcpm).format('$0,0.00'),
                presentationOverallEcpc: numeral(overallEcpc).format('$0,0.00')
            });
        }

        configuration(campaignId).set('stats_accretedByDate', out);
    },
};


var PivotTableBuilder = {
    _expandTopLevel: function (campaignId, selectionList) {
        var stats = flux.store('campaign-report-stats-resource').getStats_type(campaignId, 'pivot_table');
        var pivotTableStats = objectPath.get(stats, ['stats', '0', 'stats'], []);
        var firstSplit = selectionList[0];

        pivotTableStats.forEach(function (statItem) {
            var splitKey = firstSplit.value + '__' + statItem.group[firstSplit.value];
            PivotTableBuilder.expandRow(campaignId, splitKey);
        });
    },

    _cannotAddMoreSplits: function (campaignId) {
        configuration(campaignId).set(['pivotTable', 'canAddMoreSplits'], false);
    },

    _canAddMoreSplits: function (campaignId) {
        configuration(campaignId).set(['pivotTable', 'canAddMoreSplits'], true);
    },

    tryAutoExpandTopLevel: function (campaignId) {
        var selectionList = configuration(campaignId).get(['pivotTable', 'selectedSplit']);

        if (selectionList.length === 2) {
            PivotTableBuilder._expandTopLevel(campaignId, selectionList);
        }
    },

    addSplit: function (campaignId, selection) {
        var selectionList = configuration(campaignId).get(['pivotTable', 'selectedSplit']),
            availableSplitOptions = configuration(campaignId).get(['pivotTable', 'availableSplitOptions']),
            maxSplits = configuration(campaignId).get(['pivotTable', 'maxSplits']);


        selectionList.push(selection);

        var selectedItem, item;

        if (selection.parent) {
            item = _.find(availableSplitOptions, { label: selection.parent });
            if (item) {
                item.visible = false;
            }
        } else {
            item = _.find(availableSplitOptions, { value: selection.value });
            if (item) {
                item.visible = false;
            }
        }


        if (selectionList.length >= maxSplits) {
            PivotTableBuilder._cannotAddMoreSplits(campaignId);
        }
        configuration(campaignId).set(['pivotTable', 'selectedSplit'], selectionList);
        configuration(campaignId).set(['pivotTable', 'availableSplitOptions'], availableSplitOptions);
    },

    removeSplit: function (campaignId, selection) {
        var selectionList = configuration(campaignId).get(['pivotTable', 'selectedSplit']),
            newList = _.reject(selectionList, selection),
            availableSplitOptions = configuration(campaignId).get(['pivotTable', 'availableSplitOptions']);


        var selectedItem, item;

        if (selection.parent) {
            item = _.find(availableSplitOptions, { label: selection.parent });
            if (item) {
                item.visible = true;
            }
        } else {
            item = _.find(availableSplitOptions, { value: selection.value });
            if (item) {
                item.visible = true;
            }
        }

        PivotTableBuilder._canAddMoreSplits(campaignId);
        configuration(campaignId).set(['pivotTable', 'selectedSplit'], newList);
        configuration(campaignId).set(['pivotTable', 'availableSplitOptions'], availableSplitOptions);
    },

    sortColumn: function (campaignId, sortColumn) {
        var currentSortColumn = configuration(campaignId).get(['pivotTable', 'sortColumn']),
            currentSortDirection = configuration(campaignId).get(['pivotTable', 'sortDirection']);

        // reverse directions
        if (currentSortColumn === sortColumn) {
            configuration(campaignId).set(['pivotTable', 'sortDirection'], currentSortDirection * -1);
        } else {
            configuration(campaignId).set(['pivotTable', 'sortDirection'], -1);
            configuration(campaignId).set(['pivotTable', 'sortColumn'], sortColumn);
        }
    },

    highlightParent: function (campaignId, splitKey) {
        var focusedRow = splitKey,
            splitRowKeys = focusedRow.split('||'),
            parents = [];

        splitRowKeys.pop();

        while (splitRowKeys.length > 0) {
            parents.push(splitRowKeys.join('||'));
            splitRowKeys.pop();
        }

        configuration(campaignId).set(['pivotTable', 'focusedParents'], parents);
        configuration(campaignId).set(['pivotTable', 'focusedRow'], focusedRow);
    },

    collapseRow: function (campaignId, splitKey) {
        configuration(campaignId).del(['pivotTable', 'expandedRows', splitKey]);
    },

    expandRow: function (campaignId, splitKey) {
        configuration(campaignId).set(['pivotTable', 'expandedRows', splitKey], true);
    },

    loadingStarted: function (campaignId) {
        configuration(campaignId).set(['pivotTable', 'isLoading'], true);
    },

    loadingDone: function (campaignId) {
        configuration(campaignId).set(['pivotTable', 'isLoading'], false);
    }
};


function getStatsForCampaign(campaignId) {
    var campaignReportStats = flux.store('campaign-report-stats-resource').getStatsForCampaign(campaignId);

    var dimensions = DimensionManager.getAllDimensions(campaignId);
    var dataByDimension;
    _.forOwn(dimensions, function (_configuration, _dimensionName) {
        dataByDimension = campaignReportStats[_dimensionName];

        if (dataByDimension) {
            var dataByDimensionLength = Object.keys(dataByDimension.distinctValues).length;

            if (dataByDimensionLength === 1) { // process 1d data
                dataByDimension.distinctValues  = dataByDimension.distinctValues[_dimensionName];
            }
        }
    });
    return campaignReportStats;
}

function getDistinctValues (dimensionName, campaignId) {
    var campaignReportStats = getStatsForCampaign(campaignId);

    return campaignReportStats[dimensionName].distinctValues;
}

function prepareDictionary() {
    var out = {};

    var geo_country = {};
    var geo_country_region = {};
    _.each(contries, function(item, index){
        var countryCode = item.id;
        var countryName = item.text;
        var regionsInCountry = regions[countryCode];

        _.each(regionsInCountry, function(regionName, regionKey, index2){
            geo_country_region[regionKey] = regionName;
        });

        geo_country[countryCode] = countryName;
    });

    var gender = {
        'F': {
            value: 'Female',
            order: 0
        },
        'M': {
            value: 'Male',
            order: 1
        },
        'D': {
            value: 'Unknown',
            order: 2
        }
    };

    var device_os = {
        'ANDROID': {
            value: 'Android',
            order: 0
        },
        'RIM OS': {
            value: 'Blackberry',
            order: 1
        },
        'IOS': {
            value: 'iOS',
            order: 2
        },
        '': {
            value: 'Unknown',
            order: 3
        }
    };

    var age = {
        '1': {
            value: '13-17',
            order: 0
        },
        '2': {
            value: '18-24',
            order: 1
        },
        '3': {
            value: '25-34',
            order: 2
        },
        '4': {
            value: '35+',
            order: 3
        },
        '-1': {
            value: 'Unknown',
            order: 4
        }
    };

    var dimensionDisplayName = {
        'gender':'Gender',
        'age': 'Age Groups',
        'geo_country': 'Country',
        'device_os': 'Device OS',
        'geo_country_region': 'Region',
        'hour':'Time of Day',
        'day_of_week': 'Day of Week'
    };

    var day_of_week = {
        0: {
            value: 'Sunday',
            order: 6
        },
        1: {
            value: 'Monday',
            order: 0
        },
        2: {
            value: 'Tuesday',
            order: 1
        },
        3: {
            value: 'Wednesday',
            order: 2
        },
        4: {
            value: 'Thursday',
            order: 3
        },
        5: {
            value: 'Friday',
            order: 4
        },
        6: {
            value: 'Saturday',
            order: 5
        }
    };

    var day_of_week_short = {
        0: {
            value: 'Sun',
            order: 1
        },
        1: {
            value: 'Mon',
            order: 2
        },
        2: {
            value: 'Tue',
            order: 3
        },
        3: {
            value: 'Wed',
            order: 4
        },
        4: {
            value: 'Thu',
            order: 5
        },
        5: {
            value: 'Fri',
            order: 6
        },
        6: {
            value: 'Sat',
            order: 7
        }
    };

    var hour = {
        0: {
            value: '12am - 1am',
            order: 0
        },
        1: {
            value: '1am - 2am',
            order: 1
        },
        2: {
            value: '2am - 3am',
            order: 2
        },
        3: {
            value: '3am - 4am',
            order: 3
        },
        4: {
            value: '4am - 5am',
            order: 4
        },
        5: {
            value: '5am - 6am',
            order: 5
        },
        6: {
            value: '6am - 7am',
            order: 6
        },
        7: {
            value: '7am - 8am',
            order: 7
        },
        8: {
            value: '8am - 9am',
            order: 8
        },
        9: {
            value: '9am - 10am',
            order: 9
        },
        10: {
            value: '10am - 11am',
            order: 10
        },
        11: {
            value: '11am - 12pm',
            order: 11
        },
        12: {
            value: '12pm - 1pm',
            order: 12
        },
        13: {
            value: '1pm - 2pm',
            order: 13
        },
        14: {
            value: '2pm - 3pm',
            order: 14
        },
        15: {
            value: '3pm - 4pm',
            order: 15
        },
        16: {
            value: '4pm - 5pm',
            order: 16
        },
        17: {
            value: '5pm - 6pm',
            order: 17
        },
        18: {
            value: '6pm - 7pm',
            order: 18
        },
        19: {
            value: '7pm - 8pm',
            order: 19
        },
        20: {
            value: '8pm - 9pm',
            order: 20
        },
        21: {
            value: '9pm - 10pm',
            order: 21
        },
        22: {
            value: '10pm - 11pm',
            order: 22
        },
        23: {
            value: '11pm - 12am',
            order: 23
        }
    };

    var hourInterval = {
        '0-5'  : {
            value: '12a-6a',
            order: 0
        },
        '6-11' : {
            value: '6a-12p',
            order: 1
        },
        '12-17': {
            value: '12p-6p',
            order: 2
        },
        '18-24': {
            value: '6p-12a',
            order: 3
        }
    };

    var scopes = {
        'ad': 'Ads',
        'ages': 'Age Groups',
        'creative formats': 'Creative Formats',
        'creative sizes': 'Creative Sizes',
        'creatives': 'Creatives',
        'dayparts': 'Dayparts',
        'devices': 'Devices',
        'flight': 'Flights',
        'genders': 'Genders',
        'geotargets': 'Geotargets',
        'languages': 'Languages',
        'weekparts': 'Weekparts'
    };

    return _.assign( out,
        { 'gender':gender },
        { 'geo_country':geo_country },
        { 'device_os':device_os },
        { 'age':age },
        { 'geo_country_region':geo_country_region },
        { 'dimensionDisplayName':dimensionDisplayName },
        { 'hour': hour },
        { 'day_of_week': day_of_week },
        { 'day_of_week_short': day_of_week_short },
        { 'hourInterval': hourInterval },
        { 'scopes': scopes }
    );
}

flux.addStore('campaign-reporting', new ReportStatsStore());
