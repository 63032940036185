'use strict';

var app = require('scripts/core').app;
var AdminView = require('./admin');

var Controller = Mario.Controller.extend({
    admin: function () {
        displayPage(AdminView, 'admin', null, null, true);
    }
});

var controller = new Controller();

exports.start = function () {
    debug('start admin component');
    this.router = new Mario.AppRouter({
        appRoutes: {
            'admin': 'admin'
        },

        controller: controller
    });
    return {
        matcher    : /\/admin/,
        url        : '/admin',
        key        : 'admin',
        add_to_menu: true,
        menu_config: {icon: 'fa-gear', name: 'Admin'}
    };
};


function displayPage(Page, name, model) {
    var page = globalCh.request('available-page', {main_url: '/admin'});

    // TODO handle request without permission
    var orgReq = globalCh.request('cache', 'organizations');

    orgReq.done(function (organizations) {

        page.show(function () {
            return new Page({organizations: organizations});
        });
    });
}

