'use strict';
var op = objectPath;

function statsReducer (state, resources, session, campaignId) {
    const adId = op.get(session, `campaigns.progress.instances.${campaignId}.adId`);
    const statsTableIsLoading = op.get(session, `campaigns.progress.instances.${campaignId}.statsTable.isLoading`, false);

    let stats;
    let isLiveStats;
    let deps;

    if (adId) {

        stats = objectPath.get(resources, `stats.progress.ads.entities.${adId}.liveHourly`, []);
        const ad = objectPath.get(resources, `campaigns.entities.${campaignId}.ads.entities.${adId}`, {});

        if (state.deps.stats === stats && state.deps.ad === ad) {
            return state;
        }

        deps = { stats, ad };
        isLiveStats = (ad.attr && ad.attr.status === 'live');

    } else if (campaignId) {
        stats = objectPath.get(resources, `stats.progress.campaigns.entities.${campaignId}.liveHourly`, []);
        const ads = objectPath.get(resources, `campaigns.entities.${campaignId}.ads.entities`, {});

        if (state.deps.stats === stats && state.deps.ads === ads) {
            return state;
        }

        deps = { stats, ads };
        isLiveStats = _.some(ads, ad => (ad.attr.status === 'live'));
    }

    let totalStats = {
        impressions: 0,
        clicks: 0,
        spend: 0,
        ctr: '-'
    };


    const statsGrouped = _.groupBy(stats, 'date');

    const statsReduced = _.map(statsGrouped, (stats, date) => {

        return _.reduce(stats, (totalForDate, { impressions, clicks, spend }) => ({

            ...totalForDate,
            date,
            impressions: impressions + totalForDate.impressions,
            clicks: clicks + totalForDate.clicks,
            spend: spend + totalForDate.spend

        }), {
            impressions: 0,
            clicks: 0,
            spend: 0
        });
    });

    const statsSorted = _.sortBy(statsReduced, 'date').reverse();

    const statsFormatted = statsSorted.map(statItem => {

        var ctr = '-';

        if (statItem.impressions > 0) {
            ctr = numeral((statItem.clicks / statItem.impressions)).format('0.00%');
        }

        totalStats = {
            ...totalStats,
            impressions: totalStats.impressions + statItem.impressions,
            clicks: totalStats.clicks + statItem.clicks,
            spend: totalStats.spend + (statItem.spend)
        };

        return [
            { name: 'date', value: statItem.date },
            { name: 'impressions', value: numeral(statItem.impressions).format('0,0') },
            { name: 'clicks', value: numeral(statItem.clicks).format('0,0') },
            { name: 'spend', value: numeral(statItem.spend).format('$0,0.00') },
            { name: 'ctr', value: ctr }
        ];
    });


    if (totalStats.impressions > 0) {
        totalStats = {
            ...totalStats,
            ctr: numeral((totalStats.clicks / totalStats.impressions)).format('0.00%')
        };
    }

    return {
        ...state,
        deps,
        isLiveStats,
        bodyItems: statsFormatted,
        headerItems: headerItemsReducer(totalStats),
        isLoading: statsTableIsLoading,
    };
}

function headerItemsReducer (totalStats = {}) {
    return [
        { name: 'Date', value: 'Total' },
        { name: 'Impressions', value: numeral(totalStats.impressions).format('0,0') },
        { name: 'Clicks', value: numeral(totalStats.clicks).format('0,0') },
        { name: 'Spend', value: numeral(totalStats.spend).format('$0,0.00') },
        { name: 'CTR', value: totalStats.ctr }
    ];
}


module.exports = statsReducer;