'use strict';

var ADSUSHI_API_BASES = require('scripts/env').ADSUSHI_API_BASES;
var flux = require('scripts/core/flux');

var bases = ADSUSHI_API_BASES.slice();

function getNextBase () {
    var item = bases.shift();
    bases.push(item);
    return item;
}

var http = {

    get: function (url) {
        return $.ajax({
                method: 'get',
                url: getNextBase() + url,
                headers: {
                    'Authorization': flux.store('session').getAuthToken()
                }
            }, { doNotQueue: true }
        );
    },

    post: function (url, payload) {
        return $.ajax({
                method: 'post',
                url: getNextBase() + url,
                processData: false,
                contentType: 'application/json',
                data: JSON.stringify(payload),
                headers: {
                    'Authorization': flux.store('session').getAuthToken()
                }
            }, { doNotQueue: true }
        );
    },

    patch: function (url, payload) {
        return $.ajax({
                method: 'patch',
                url: getNextBase() + url,
                processData: false,
                contentType: 'application/json',
                data: JSON.stringify(payload),
                headers: {
                    'Authorization': flux.store('session').getAuthToken()
                }
            }, { doNotQueue: true }
        );
    },

    delete: function (url) {
        return $.ajax({
                method: 'delete',
                url: getNextBase() + url,
                headers: {
                    'Authorization': flux.store('session').getAuthToken()
                }
            }, { doNotQueue: true }
        );
    }
};

module.exports = http;
