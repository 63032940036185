'use strict';
var env = require('scripts/env');
var flux = require('scripts/core/flux');

var TimeTraveler = React.createClass({
    getInitialState () {
        return {
            history: flux.store('app-store').getActionHistory(),
            show: false
        };
    },

    replayTo (action) {
        flux.store('app-store').replayActionHistoryTo(action);
    },

    show () {
        this.setState({ show: true });
    },

    hide () {
        this.setState({ show: false });

    },

    render () {

        if (!env.DEBUG) {
            return <span></span>
        }

        return (
            <div className="am-timeTraveler">
                {this.state.show ?
                <div className="am-timeTraveler-history">
                    {this.state.history.map((action, i) =>
                    <p key={i} onClick={()=>this.replayTo(action)}>
                        {i+1}. {action.type}
                    </p>)}
                </div> : null}
                <div className="am-timeTraveler-menu">
                    <button onClick={this.hide}>hide</button> &nbsp;
                    <button onClick={this.show}>show</button>
                </div>
            </div>
        );
    }
});


module.exports = TimeTraveler;