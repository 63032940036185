'use strict';
var BasicInfo = require('./basic-info');
var BulletGraph = require('scripts/widgets/bullet-graph');
var CampaignStatsPreview = require('scripts/widgets/stats-preview');
var PrivateChannel = require('scripts/common/mixins/private-channel');
var CampaignHistory = require('../history');

var Overview = React.createClass({
    
    mixins: [PrivateChannel],
    
    getInitialState: function () {
        return {
            d: this.props.model.computeDisplayData(),
            s: this.props.model.toJSON(),
            showHistory: false
        };
    },

    componentDidMount: function () {
        var campaign = this.props.model;

        this.privateCh.listenTo(campaign, 'stats:loaded', ()=>
            this.isMounted() &&
            this.setState({d: campaign.computeDisplayData()})
        );

        this.privateCh.listenTo(campaign, 'change', ()=>
            this.isMounted() &&
            this.setState({s: campaign.toJSON()})
        );
        this.privateCh.listenTo(this.props.ch, `campaign:${campaign.id}:show:history`, ()=>this.setState({showHistory:true}));
    },
    
    getSettingsForBulletGraph: function () {
        var campaign = this.props.model;
        return campaign.getBulletGraphAttributes();
    },

    onCloseHistoryPopup() {
        this.setState({showHistory: false});
    },
    
    render: function () {

        var showCampaignHistory = this.state.showHistory;

        return (
            <div className="ef-campaign-overview row">
                <div className="col-lg-4">
                    <BasicInfo ch={this.props.ch} campaign={this.props.model}></BasicInfo>
                </div>
                <div className="col-lg-3" style={{marginTop: '10px'}}>
                    <BulletGraph ch={this.props.ch} settings={this.getSettingsForBulletGraph()} paused={this.props.model.get('status') === 'paused'}></BulletGraph>
                </div>
                <div className="col-lg-5">
                    <CampaignStatsPreview ch={this.props.ch} showType={this.props.showType} d={this.state.d} campaign={this.props.model}></CampaignStatsPreview>
                </div>

                {showCampaignHistory? <CampaignHistory ch={this.props.ch} onClosePopup={this.onCloseHistoryPopup} campaign={this.props.model} />: null}
            </div>
        )
    }
});

module.exports = Overview;
