<div class="modal-header">
    <h3>Confirm</h3>
</div>

<div class="modal-body">

    {{msg}}

</div>

<div class="modal-footer">

    <button class="btn btn-action" data-action="proceed">Proceed</button>
    <a href="" data-dismiss="modal">cancel</a>

</div>