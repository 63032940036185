'use strict';

var fns = require('scripts/common/fns');
var langCodes = require('scripts/common/constants/language-codes');
var appCategoryTargeting = require('scripts/common/constants/app-category-targeting');

var Summary = React.createClass({
    render: function () {
        var adJson = this.props.adJson;

        var formatMoney = fns.formatCurrency;
        var formatNumber = fns.formatNumber;



        var geoTargets = <ul key={_.uniqueId()}>{adJson.geotargets.map(formatGeoCountry)}</ul>;
        var ageGroups = formatAgeGroup(adJson.target_age_groups);

        var genders = formatTargetGenders(adJson.target_genders );

        var dayparts = formatDayparts(adJson.dayparts, adJson.timezone);

        var weekparts = formatWeekparts(adJson.weekparts);;

        var geoBoxes = formatGeoBoxes(adJson.geoboxes);

        var creative = this.props.ad.getCreative();
        creative = creative? creative.get('name') : null;

        var formatNum_with0AsUnlimited = function(val){
            if (val !== 0) {
                    return fns.formatNumber(val, 'thousands');
                } else {
                    return 'Unlimited';
                }
        };

        return (
            <div className="ad-summary">
                <div className="panel-heading">
                    <h3 className="panel-title">Summary</h3>
                </div>

                <div className="panel-body">
                    <h6>Basics</h6>
                    <dl className= "dl-horizontal">
                        <dt>Name</dt>
                        <dd summary-id="name">{adJson.name}</dd>
                        <dt>Start</dt>
                        <dd>
                            <span summary-id="start">{formatDate(adJson.start, adJson.timezone)}</span>
                            <span data-abbr="timezone">{formatTimezoneAbbr(adJson.start, adJson.timezone)}</span>
                        </dd>

                        <dt>End</dt>
                        <dd>
                            <span summary-id="end">
                            {formatDate(adJson.end, adJson.timezone)}</span>
                            <span data-abbr="timezone">{formatTimezoneAbbr(adJson.end, adJson.timezone)}</span>
                        </dd>

                        <dt>Timezone</dt>
                        <dd summary-id="timezone">{adJson.timezone}</dd>

                        <dt>Notes</dt>
                        <dd summary-id="notes">{adJson.notes}</dd>
                    </dl>

                    <h6>Budget</h6>

                    <dl className="dl-horizontal">
                        <dt>Max Bid</dt>
                        <dd summary-id="max_bid_cpm">{formatMoney(adJson.max_bid_cpm)}</dd>

                        <dt>Impressions Daily Cap</dt>
                        <dd summary-id="max_daily_impressions">{formatNum_with0AsUnlimited(adJson.max_daily_impressions)}</dd>

                        <dt>Impressions Total Target</dt>
                        <dd summary-id="max_total_impressions">{formatNum_with0AsUnlimited(adJson.max_total_impressions)}</dd>

                        <dt>Clicks Daily Cap</dt>
                        <dd summary-id="max_daily_clicks">{formatNum_with0AsUnlimited(adJson.max_daily_clicks)}</dd>

                        <dt>Clicks Total Target</dt>
                        <dd summary-id="max_total_clicks">{formatNum_with0AsUnlimited(adJson.max_total_clicks)}</dd>

                        <dt>Spend Daily Cap</dt>
                        <dd summary-id="max_daily_spend">{formatNum_with0AsUnlimited(adJson.max_daily_spend)}</dd>

                        <dt>Spend Total Target</dt>
                        <dd summary-id="max_total_spend">{formatNum_with0AsUnlimited(adJson.max_total_spend)}</dd>
                    </dl>

                    <h6>Revenue Management</h6>
                    <dl className="dl-horizontal">

                        <dt>Billing Terms</dt>
                        <dd summary-id="billing_terms">{adJson.billing_terms === 'PPM' ? 'CPM' : 'CPC'}</dd>

                        <dt>Billing Rate</dt>
                        <dd summary-id="billing_rate">{formatMoney(adJson.billing_rate)}</dd>
                    </dl>

                    <h6>Geotargeting</h6>
                    <dl className="dl-horizontal">
                        <dt>Geotargeting</dt>
                        <dd summary-id="geotargets">{geoTargets}</dd>
                    </dl>
                    <h6>Demographics</h6>
                    <dl className="dl-horizontal">
                        <dt>Age Groups</dt>
                        <dd summary-id="target_age_groups">{ageGroups}</dd>

                        <dt>Gender</dt>
                        <dd summary-id="target_genders">{genders}</dd>
                    </dl>

                    <h6> Advanced </h6>
                    <dl className="dl-horizontal">
                        <dt>User Language</dt>
                        <dd summary-id="target_device_language">{formatDeviceLanguage(adJson.target_device_language)} </dd>

                        <dt>Operating System</dt>
                        <dd summary-id="target_device_os">{adJson.target_device_os.join(',')}</dd>

                        <dt>Dayparting</dt>
                        <dd>
                            <div summary-id="dayparts">{dayparts}</div>
                        </dd>

                        <dt>Weekparting</dt>
                        <dd summary-id="weekparts">{weekparts}</dd>

                        <dt>Geofencing</dt>
                        <dd summary-id="geoboxes">{geoBoxes}</dd>

                        <dt>User Frequency</dt>
                        <dd summary-id="max_user_frequency">{adJson.max_user_frequency}</dd>

                        <dt>App Categories</dt>
                        <dd summary-id="target_app_categories">{formatTargetAppCats(adJson.target_app_categories)}</dd>

                    </dl>

                    <h6> Creative </h6>
                    <dl className="dl-horizontal">
                        <dt>Creative</dt>
                        <dd summary-id="creative">{creative}</dd>
                    </dl>
                </div>
            </div>
        );
    }
});

var fns = require('scripts/common/fns');
function formatGeoCountry(obj) {
    var html = '', content, tpl, countryName = fns.getCountryName(obj.country);

    var includes, excludes;
    if (!_.isEmpty(obj.include)) {
        tpl =   '<div class="regions">' +
        '<div><span class="label label-success">i</span></div><div title="<%=content%>"><%=content%></div>' +
        '</div>';

        content = _.compact(_.map(obj.include, _.partial(fns.formatGeoRegionCity, obj.country))).join(', ');
        includes = (
            <div className="regions" key={_.uniqueId()}>
                <div>
                    <span className="label label-success">i</span>
                </div>
                <div title={content}>
                    {content}
                </div>
            </div>

        );
    }

    if (!_.isEmpty(obj.exclude)) {
        tpl =   '<div class="regions">' +
        '<div><span class="label label-danger">e</span></div><div title="<%=content%>"><%=content%></div>' +
        '</div>';

        content = _.compact(_.map(obj.exclude, _.partial(fns.formatGeoRegionCity, obj.country))).join(', ');
        excludes = (
            <div className="regions">
                <div>
                    <span className="label label-danger">e</span>
                </div>
                <div title={content}>
                    {content}
                </div>
            </div>

        );
    }

    return (
        <li key={_.uniqueId()}>
            <div>
                <u>{countryName}</u>
            </div>
        {includes}
        {excludes}
        </li>

    );
}

function formatGeoBoxes(obj) {
    return obj.map(function (box) {
        var shorter = {};
        _.each(box, function (val, key) {
            shorter[key] = val.toFixed(2);
        });
        return <div key={_.uniqueId()}>E: {shorter.E}, W: {shorter.W}, S: {shorter.S}, N: {shorter.N}</div>;
    });
}

function formatAgeGroup(val) {
    if (val.length === 0) {
        return 'Everyone';
    } else {
        var displayTextArray = [];
        _.forEach(val, function(item){
            switch (item) {
                case 1:
                    displayTextArray.push( '13-17' );
                break;
                case 2:
                    displayTextArray.push( '18-24' );
                break;
                case 3:
                    displayTextArray.push( '25-34' );
                break;
                case 4:
                    displayTextArray.push( '35+' );
                break;
                case -1:
                    displayTextArray.push( 'Unknown' );
                break;
                default:
                    throw new Error( 'Age group array is not empthy and contains unregonize value');
            }
        })
        return displayTextArray.join(', ');
    }// End else
}

function formatDeviceLanguage(obj) {
    return _.isEmpty(obj)? 'Any language':langCodes[obj];
}

function formatTargetGenders(val) {
    if (_.isArray(val)) {
        if (val.length === 0 ) {
            return 'Everyone';
        } else {
            var displayTextArray = [];
            _.forEach(val, function(item){
                if (item === 'M') {
                    displayTextArray.push( 'Male' );
                } else if (item === 'F') {
                    displayTextArray.push( 'Female' );
                } else if (item === 'D') {
                    displayTextArray.push( 'Unknown' );
                } else {
                    throw new Error( 'Gender array is not empthy and contains unregonize value');
                }
            })
            return displayTextArray.join(', ');
        } // End else
    }
}

function formatTargetAppCats(obj) {
    return _.isArray(obj)? _.map(obj, function(i) { return appCategoryTargeting[i]}).join(', '):''
}

function formatDayparts(obj, timezone) {
    if (_.isEmpty(obj)) {
        return 'Full day';
    } else {
        obj = fns.convertDaypartsByTimezone(obj, 'UTC', timezone);
        obj = fns.mergeTimeranges(obj);

        var dayparts = _.map(obj, function (part) {
            var tz = moment().tz(timezone).format('z');

            return (
                <li key={_.uniqueId()}>{part.start}
                    <span data-abbr="timezone">{tz}</span>
                    ~{part.end}
                    <span data-abbr="timezone">{tz}</span>
                </li>
            )
        });
        return <ul className="list-unstyled">{dayparts}</ul>;
    }
}

function formatWeekparts(obj) {
    var names = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    return _.compact(_.map(obj, function (bool, idx) {return bool ? names[idx] : '';})).join(', ');
}

function formatDate(obj, timezone) {
    return moment(obj).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
}

/**
 * get the time zone based on date time
 *  @param {string} date - iso date
 *  @param {string} timezone - timezone
 */
function formatTimezoneAbbr(date, timezone) {
    return moment(date).tz(timezone).format('z');
}


module.exports = Summary;
Summary.formatGeoCountry = formatGeoCountry;
Summary.formatGeoBoxes = formatGeoBoxes;
Summary.formatAgeGroup = formatAgeGroup;
Summary.formatDeviceLanguage = formatDeviceLanguage;
Summary.formatTargetGenders = formatTargetGenders;
Summary.formatTargetAppCats = formatTargetAppCats;
Summary.formatDayparts = formatDayparts;
Summary.formatWeekparts = formatWeekparts;
Summary.formatDate = formatDate;
Summary.formatTimezoneAbbr = formatTimezoneAbbr;
