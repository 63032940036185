'use strict';

var flux = new Fluxxor.Flux();

flux.setDispatchInterceptor(function (action, dispatch) {

    dispatch(action);

    dispatch({
        type: 'reduce_action',
        payload: action
    });
});


module.exports = flux;