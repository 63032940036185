'use strict';

var initalState = {
    'schema': {
        'live': [],
        'liveDaily': [],
        'liveHourly': [],
        'standard': [],
        'standardDaily': [],
        'standardHourly': []
    },
    'entities': {}
};

function campaignsReducer (state = initalState, action) {
    var entityLookup = (campaignId) => objectPath.get(state, `entities.${campaignId}`, state.schema);

    return {
        ...state,
        entities: entitiesReducer(state.entities, action, entityLookup)
    };
}


function entitiesReducer (state, action, entityLookup) {
    switch (action.type) {
        case 'progress_campaign_stats_recieved':
        case 'progress_campaign_live_stats_recieved':
            const campaignId = action.payload.campaignId;
            const entity = entityLookup(campaignId);

            const statsMap = _.pick(action.payload, [
                'liveDaily',
                'liveHourly',
                'standardDaily',
                'standardHourly'
            ]);

            return {
                ...state,
                [campaignId]: {
                    ...entity,
                    ...statsMap
                }
            };

        default:
            return state;

    }
}


module.exports = campaignsReducer;


