<table class="table table-hover">
  <thead>
    <tr>
      <th>Email</th>
      <th>First Name</th>
      <th>Last Name</th>
      {{#if can_access_roles}}
      <th>Role</th>
      {{/if}}
      <th>ID</th>
      {{#if can_access_orgs}}
      <th>Organization</th>
      {{/if}}
      <th></th>
    </tr>
  </thead>
  <tbody>
  </tbody>
  <tfoot></tfoot>
</table>

{{#if can_create_user}}
    <button data-action="show-create" class="btn-primary btn">
        <i class="fa fa-user"></i>
        Add a User
    </button>
{{/if}}

