'use strict';

var emptyTemplate = require('./templates/uploader-empty');
var fileItemTemplate = require('./templates/file-item');
var uploaderTemplate = require('./templates/uploader');
var ALLOWED_FILE_TYPES = require('scripts/common/constants/content-types');

var EmptyFileView = Marionette.ItemView.extend({
    className: 'empty',
    template: emptyTemplate
});

var FileItemView = Marionette.ItemView.extend({
    className: 'row',
    template: fileItemTemplate,

    events: {
        'click .fa-times': 'removeFile'
    },

    removeFile: function () {
        this.model.collection.remove(this.model);
        this.remove();
    },

    onRender: function () {

        if (this.model.isUnsupported()) {
            this.$el.addClass('unsupported');
        }
    },

    templateHelpers: function () {
        /* jshint maxcomplexity: 20 */

        var icon = '';

        switch (this.model.get('type')) {
            case 'image/jpeg':
            case 'image/gif':
            case 'image/png':
                icon = 'fa-file-image-o';
                break;

            case 'video/mp4':
                icon = 'fa-file-movie-o';
                break;

            case 'application/pdf':
                icon = 'fa-file-pdf-o';
                break;

            case 'text/plain':
                icon = 'fa-file-text';
                break;

            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                icon = 'fa-file-word-o';
                break;

            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                icon = 'fa-file-excel-o';
                break;
        }

        return {
            icon: icon,
            unsupported: this.model.isUnsupported()
        };
    }
});


/*
 * temporary model and collections
 *
 */
var UploadFile = Mario.Model.extend({

    isUnsupported: function () {
        return !_.include(_.keys(ALLOWED_FILE_TYPES), this.get('type'));
    }

});

var Files = Mario.Collection.extend({

    initialize: function () {
        this.rawFiles = [];
    },

    files: function () {
        var files = [];
        _.each(this.rawFiles, function (fs) {
            _.each(fs, function (f) {
                if (_.include(_.keys(ALLOWED_FILE_TYPES), f.type) &&
                    this.hasFile(f.name) && !hasDuplicate(files, f.name)) {
                    files.push(f);
                }
            }, this);
        }, this);

        return files;

        function hasDuplicate(files, name) {
            return _.any(files, function (ef) {
                return ef.name === name;
            });
        }
    },

    hasFile: function (name) {
        return this.any(function (file) {
            return file.get('name') === name;
        });
    },

    model: UploadFile
});

var FileUploader = Marionette.CompositeView.extend({
    template: uploaderTemplate,
    childView: FileItemView,
    childViewContainer: '.files',
    emptyView: EmptyFileView,

    className: 'file-uploader',

    initialize: function () {
        this.collection = new Files();
    },

    events: {
        'dragstart'             : 'onDragStart',
        'dragend'               : 'onDragEnd',
        'drag'                  : 'onDrag',
        'dragleave'             : 'onDragLeave',
        'dragenter'             : 'onDragEnter',
        'dragover'              : 'onDragOver',
        'drop'                  : 'onDrop',
        'click .empty'          : 'selectFiles',
        'click .upload-trigger' : 'selectFiles',
        'change .select-files input[type=file]' : 'addSelectedFiles'
    },

    onDragEnd: function () {
    },

    onDragStart: function () {
    },

    onDrag: function () {
    },

    onDragLeave: function () {
    },

    onDragEnter: function () {
    },

    onDragOver: function () {
        return false;
    },

    selectFiles: function (ev) {
        var input = this.$('input[type=file]');
        input.click();
    },

    onDrop: function (ev) {

        var files = ev.originalEvent.dataTransfer.files;

        this.addFiles(files);

        return false;
    },

    addSelectedFiles: function (ev) {
        var input = this.$('input[type=file]').get(0);
        this.addFiles(input.files);
    },

    addFiles: function (files) {
        _.each(files, function (file) {
            this.collection.add({name: file.name, size: file.size, type: file.type});
        }, this);

        this.collection.rawFiles.push(files);
    },

    startUpload: function (id) {
        globalCh.request('file-upload', id, this.collection.files());
    }
});

module.exports = FileUploader;
