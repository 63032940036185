'use strict';

/**
 * Props
 *
 * @property ch
 * @property collection
 * @property showType
 * @property toggleDisplay {function}
 * @property numberOfItemToShow {string}
 */

var Pager = require('scripts/widgets/pager');
var RefreshButton = require('scripts/widgets/refresh-button');
var ToggleButtons = require('./toggle-buttons');

var Footer = React.createClass({
    getInitialState: function(){

        return {
            numberOfItemToShow: this.props.collection.state.pageSize || 25,
            presetNumberOfItemToShow : [
                { 'displayName': '10 results per page', 'value': '10'  },
                { 'displayName': '25 results per page', 'value': '25'  },
                { 'displayName': '50 results per page', 'value': '50'  }
            ]
        };
    },

    handleChange: function(e){
        this.props.ch.trigger('dashboard:pagesize:changed', _.parseInt(e.target.value));
    },

    render() {
        // add switch stats toggle
        var hasStats = this.props.itemType === 'ads' || this.props.itemType === 'campaigns';
        // add expand toggle
        var hasExpand = this.props.itemType === 'creatives';
        var showType = this.props.showType;
        var isExpanded = this.props.isExpanded;

        return (
            <div className="list-footer">
                <div className="box-1">
                    <Pager ch={this.props.ch} collection={this.props.collection}></Pager>
                </div>
                <div className="box-2">
                    <div className="list-footer--toggle-unit">
                            {hasStats ? <ToggleButtons btn1={{active: showType === 'percentages', content: '%'}}
                                btn2={{active: showType === 'values', content: '00'}} onClick={this.props.toggleDisplay}/> : null}
                            {hasExpand ? <ToggleButtons btn1={{active: !isExpanded, content: <i className="fa fa-compress"></i>}}
                                btn2={{active: isExpanded, content: <i className="fa fa-arrows-alt"></i>}} onClick={this.props.toggleExpand}/>: null}
                    </div>
                    { this.props.itemType == 'campaigns' ?
                            <div className="list-footer--item-per-page">
                                <select className="ef-dropdown form-control" defaultValue={this.state.numberOfItemToShow} onChange={this.handleChange} >
                                    { _.map( this.state.presetNumberOfItemToShow,
                                        (item) => <option key={_.uniqueId()} value={item.value}>  {item.displayName}  </option> )
                                    }
                                </select>
                            </div>
                    : null }
                    <div className="list-footer--refresh">
                        <RefreshButton collection={this.props.collection}></RefreshButton>
                    </div>
                </div>
            </div>
        );
    }
});

module.exports = Footer;

