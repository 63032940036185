'use strict';

var Creatives = require('./creatives');

var AUDIT_STATUS = Creatives.AUDIT_STATUS;

var Model = Mario.Model.extend({
    modelName: 'Audit',
    initialize: function (attrs, options) {
    },

    defaults: {
        assessment_status: '',
        assessment_explanation: '',
        status: '',
        created: '',
        creative: null,
        campaign: null
    },

    approve: function (explanation) {
        this.save({
            assessment_status: AUDIT_STATUS.APPROVED,
            assessment_explanation: explanation
        }, {patch: true, wait: true});
    },

    reject: function (explanation) {
        this.save({
            assessment_status: AUDIT_STATUS.REJECTED,
            assessment_explanation: explanation
        }, {patch: true, wait: true});
    }
});

var Collection = Mario.Collection.extend({
    model: Model,

    url: 'audits/',

    state: {
        firstPage: 0,
        currentPage: 0,
        pageSize: 25,
        sortKey: '-submitted'
    },

    queryParams: {
        currentPage: 'page',
        pageSize: 'limit',
        sortKey: 'sort',
        totalRecords: 'maxResults',
        status: 'pending'
    },

    initialize: function () {
        this.on('change:filter:autdits', function () {
            this.state.currentPage = 0;
            this.refresh();
        });

        this.on('change:state:autdits', function () {
            this.refresh();
        });
    },

    setState: function (attr, val) {
        var allowedAttrs = ['pageSize', 'currentPage', 'sortKey'];
        var attrs = {};

        if (typeof attr === 'string') {
            attrs[attr] = val;
        } else if (_.isObject(attr)) {
            attrs = attr;
        }

        var setStateAttr = function (val, attr) {

            if (!_.contains(allowedAttrs, attr)) {
                return;
            }

            this.state[attr] = val;
        }

        _.each(attrs, setStateAttr, this);

        this.trigger('change:state:autdits', attrs);
    },

    filterStatus: function (s) {
        if (_.isEmpty(s)) {
            delete this.queryParams['status'];
        } else {
            this.queryParams['status'] = s;
        }
        this.trigger('change:filter:autdits', {status: s});
    },

    setFilter: function (attr, val) {
        var allowedAttrs = ['creative', 'search', 'campaign'];
        var attrs = {};

        if (typeof attr === 'string') {
            attrs[attr] = val;
        } else if (_.isObject(attr)) {
            attrs = attr;
        }

        _.each(allowedAttrs, function (attr) {
            if (!_.isEmpty(attrs[attr])) {
                this.queryParams[attr] = attrs[attr];
            } else {
                delete this.queryParams[attr];
            }
        }, this);

        this.trigger('change:filter:autdits', attrs);
    },

    getPendingCount: function () {
        return this.filter(function (a) {
            return a.get('assessment_status') === AUDIT_STATUS.PENDING;
        }).length;
    },

    refresh: function () {
        this.trigger('refresh:start');

        var that = this;

        return new Promise(function (resolve, reject) {
            that.fetch({merge: true, success: resolve, error: reject});
        }).finally(function () {
                that.trigger('refresh:done');
            });
    }
});

module.exports = Collection;
