<td>
    <span class="name" title="{{name}}">
        {{name}}
    </span>
    {{#unless embed}}

    <div class="input-group hide input-group-sm">
        <input type="text" value="{{name}}" class="form-control" data-id="name">

            <span class="input-group-btn">
            <button class="btn btn-default" type="button" data-action="save">
                <i class="fa fa-floppy-o"></i>
            </button>
            <button class="btn btn-default" type="button" data-action="cancel">
                cancel
            </button>

        </span>
    </div>
    {{else}}
        <div>
            <a target="_blank" href="{{url}}" data-bypass>
                <i class="fa fa-external-link"></i>
            </a>
        </div>

    {{/unless}}

    {{#unless embed}}

        {{#if can_update}}
            <div class="ops">
                <a href="#" class="btn btn-default btn-xs" data-action="edit">
                    <i class="fa fa-pencil"></i>
                    Edit
                </a>
                <a href="#" class="btn btn-default btn-xs" data-action="remove">
                    <i class="fa fa-trash-o"></i>
                    Remove
                </a>
            </div>
        {{/if}}
    {{/unless}}

</td>

<td class="preview">
    {{#unless embed}}
    <a target="_blank" href="{{url}}" data-bypass>
    {{/unless}}

        {{{preview this}}}

    {{#unless embed}}
    </a>
    {{/unless}}
</td>


<td>
    {{width}}px
</td>

<td>
    {{height}}px
</td>

<td>
    <span class="type">
        {{type}}
    </span>
</td>

<td>
    {{#if duration}}
        <span class="duration">
        {{duration}}s
    </span>
    {{/if}}
</td>

