'use strict';

var flux = require('scripts/core/flux');
var fc = flux.constants;

var _reportStats = window.reportstats = {};

var _scopeStats = window.scopeStats = {};
/*
var schema = {
now
    'gender': {
        "campaign_id":4501,
        "dimensions":["gender"],
        "stats":[
            {
                "dimensions":{
                    "gender":"D"
                },
                "clicks":13,
                "impressions":2639
            },
            { "dimensions":{"gender":"D"},"clicks":973,"impressions":397538},
            { "dimensions":{"gender":"M"},"clicks":369,"impressions":12854},
            {"dimensions":{"gender":"F"},"clicks":377,"impressions":16815}]
         ],
        "distinctValues":["M","F","D"]
    },
    'hour-day_of_week':{
        "campaign_id":4501,
        "dimensions":["hour","day_of_week"],
        "stats":[
            {
                "dimensions":{
                    "hour":"3",
                    "day_of_week":5
                },
                "clicks":13,
                "impressions":2639
            },
            {"dimensions":{"hour":"5","day_of_week":5},"clicks":3,"impressions":2247},
            {"dimensions":{"hour":"9","day_of_week":6},"clicks":5,"impressions":3064}
        ]
        "distinctValues":["0","1","2","3","5","14","15","16","18","19","21","20","23","22","4","6","17","8","7","9","11","12","13","10"]
    },

proposal

    'gender': {
        "campaign_id":4501,
        "dimensions":["gender"],
        "stats":[
            {
                "dimensions":{
                    "gender":"D"
                },
                "clicks":13,
                "impressions":2639
            },
            { "dimensions":{"gender":"D"},"clicks":973,"impressions":397538},
            { "dimensions":{"gender":"M"},"clicks":369,"impressions":12854},
            {"dimensions":{"gender":"F"},"clicks":377,"impressions":16815}],
            "distinctValues":{
                "gender": ["M","F","D"]
            }
    },
    },
    'hour-day_of_week':{
        "campaign_id":4501,
        "dimensions":["hour","day_of_week"],
        "stats":[
            {   "dimensions":{
                    "hour":"3",
                    "day_of_week":5
                },
                "clicks":13,"impressions":2639},
            {"dimensions":{"hour":"5","day_of_week":5},"clicks":3,"impressions":2247},
            {"dimensions":{"hour":"9","day_of_week":6},"clicks":5,"impressions":3064}
        ]
        "distinctValues":{
            "hour":["0","1","2","3","5","14","15","16","18","19","21","20","23","22","4","6","17","8","7","9","11","12","13","10"]
            "week_of_day": [" " " " " " " " " " " "]
        }
    },
*/

var CampaignReportStatsResource = Fluxxor.createStore({
    initialize: function (options) {
        this.bindActions(
            fc.reportDimensionStats_fetch_success,  'onDimensionStats_fetch_success',

            fc.reportPivotTableStats_fetch_success, 'onReportPivotTableStats_fetch_success',

            fc.reportScopeStats_fetch_success, 'onReportScopeStats_fetch_success'
        );
    },

    _indexIntoReportStats: function (campaignId, key, value) {
        if (!_reportStats[campaignId]) {
            _reportStats[campaignId] = {};
        }

        _reportStats[campaignId][key] = value;

        this.emit('change');
    },

    _indexIntoScopeStats: function (campaignId, key, value) {
        if (!_scopeStats[campaignId]) {
            _scopeStats[campaignId] = {};
        }

        _scopeStats[campaignId][key] = value;

        this.emit('change');
    },

    onDimensionStats_fetch_success: function (responseData) {
        var campaignId = responseData.campaign_id,
            key = responseData.group.join('|'),
            value = responseData;

        // ====== adding random mock data for spend ====== //
        // const groupType = value.group[0];
        // // if (groupType === 'date') {
        //     const stats = value.stats;
        //     stats.forEach((stat) => {
        //         const randomDecimalLength = + Math.round( Math.random()*10+1 );
        //         const randomSpend = ( Math.random()*800 ).toFixed(randomDecimalLength);
        //         const out = _.assign(stat, {spend: +randomSpend});
        //         // console.log('iiii', out);
        //         return out;
        //     });
        // // }


        this._indexIntoReportStats(campaignId, key, value);
    },


    onReportPivotTableStats_fetch_success: function (responseData) {
        var campaignId = responseData.campaign_id,
            key = 'pivot_table',
            value = responseData;

        this._indexIntoReportStats(campaignId, key, value);
    },

    onReportScopeStats_fetch_success: function (responseData) {
        var campaignId = responseData.campaign_id,
            key = responseData.scope,
            value = responseData;

        this._indexIntoScopeStats(campaignId, key, value);
    },

    getScopeData: function (campaignId, scopeName) {
        var stats = objectPath.get(_scopeStats, [campaignId, scopeName], {});

        var stringified = JSON.stringify(stats);
        if (stringified === undefined) {
            return undefined;
        } else {
            return JSON.parse(stringified);
        }
    },

    getAllScopeData: function (campaignId) {
        var stats = objectPath.get(_scopeStats, [campaignId], {});

        var stringified = JSON.stringify(stats);
        if (stringified === undefined) {
            return undefined;
        } else {
            return JSON.parse(stringified);
        }
    },

    getAllDimensionData: function (campaignId) {
        // return _.cloneDeep(_reportStats[campaignId]);
        var stringified = JSON.stringify(_reportStats[campaignId]);
        if (stringified === undefined) {
            return undefined;
        } else {
            return JSON.parse(stringified);
        }
    },

    getStatsForCampaign: function (campaignId) {
        // return _.cloneDeep(_reportStats[campaignId]);
        var stringified = JSON.stringify(_reportStats[campaignId]);
        if (stringified === undefined) {
            return undefined;
        } else {
            return JSON.parse(stringified);
        }
    },

    getStats_type: function (campaignId, type) {
        var stats = objectPath.get(_reportStats, [campaignId, type], []);
        // return _.cloneDeep(stats);

        var stringified = JSON.stringify(stats);
        if (stringified === undefined) {
            return undefined;
        } else {
            return JSON.parse(stringified);
        }
    }
});

flux.addStore('campaign-report-stats-resource', new CampaignReportStatsResource());
