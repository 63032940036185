/**
 * popup mixin
 *
 * For views that requires a popup container
 *
 *
 * */

'use strict';

var Popup = Mario.Behavior.extend({

    onShow: function () {
        var that = this;
        this.listenTo(globalCh, 'popup:closed', function () {
            if (that.view != null) {
                that.view.destroy();
            }
        });
    }
});

window.Behaviors.Popup = Popup;
