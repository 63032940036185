'use strict';

// prefix 'M_' means time has been wrapped as moment object

var D3Chart = function(){
    // container element
    this._svg = {};

    // data attributre
    this._dataAttr = {
        timeUnit: 'hour',
        // timeUnit: 'day',
    };

    // configuration
    this._conf = {
        // marging, padding, paddingGA is overide by calculation in _calculatePlottingArea
        margin: {
            top   : 0,
            bottom: 0,
            right : 0,
            left  : 0
        },
        padding: {
            top   : 0,
            bottom: 0,
            right : 0,
            left  : 0
        },
        paddingGA: {
            top   : 0,
            bottom: 0,
            right : 0,
            left  : 0
        },
        svg: { w:1000 , h: 400 },
        svgContent:   { w: void 0, h: void 0 },
        plottingArea: { w: void 0, h: void 0 },
        graphArea:    { w: void 0, h: void 0 },

        areaTimeAxis: { h: 60 }
    };
};


D3Chart.prototype._const = {
    healthColor: {
        'good': '#8BC34A',
        'bad' : '#F44336',
        'ok'  : '#FFB74D'
    },
    color_metric: {
        'clicks'      : '#26A69A',
        'impressions' : '#9E9D24',
        'spend'       : '#5C6BC0'
    }
};


////////////////////////////
///    calculations      ///
////////////////////////////
D3Chart.prototype._calculatePlottingArea = function (state){
    const conf = this._conf, svg = this._svg;
    const el = svg.el;

    conf.svg.w = $(el).find('.am-adProgressGraph-svg').width();

    let adId = void 0;
    if (state && state.meta) {
        adId = state.meta.adId;
    }

    // calculate margin;
    conf.margin.top    = conf.margin.bottom    = conf.margin.left    = conf.margin.right    = 0;
    conf.padding.top   = conf.padding.bottom   = conf.padding.left   = conf.padding.right   = 0;
    conf.paddingGA.top = 0;
    conf.paddingGA.left = conf.paddingGA.right = 30;
    conf.paddingGA.bottom = 30;

    // content is space inside margin
    conf.svgContent.w = conf.svg.w - conf.margin.left - conf.margin.right;
    conf.svgContent.h = conf.svg.h - conf.margin.top  - conf.margin.bottom;

    // plotting area is space inside margin and padding
    conf.plottingArea.w = conf.svgContent.w - conf.padding.left - conf.padding.right;
    conf.plottingArea.h = conf.svgContent.h - conf.padding.top  - conf.padding.bottom;

    // graph area
    conf.graphArea.w = conf.plottingArea.w - conf.paddingGA.left - conf.paddingGA.right;
    conf.graphArea.h = conf.plottingArea.h - conf.paddingGA.top  - conf.paddingGA.bottom;
};

D3Chart.prototype._calculateDataSetting = function(state) {
    const that = this,  conf = this._conf, dataAttr = this._dataAttr;

    // Meta
    dataAttr.adId            = state.meta.adId;
    dataAttr.hasDailyCap     = state.meta.hasDailyCap;
    dataAttr.hasTotalTarget  = state.meta.hasTotalTarget;
    dataAttr.metricType      = state.meta.metricType;

    switch (true) {
        case dataAttr.metricType === 'clicks':
            dataAttr.maxTotalMetric  = state.meta.max_total_clicks;
            break;
        case dataAttr.metricType === 'impressions':
            dataAttr.maxTotalMetric  = state.meta.max_total_impressions;
            break;
        case dataAttr.metricType === 'spend':
            dataAttr.maxTotalMetric  = state.meta.max_total_spend;
            break;
    }

    // Client's current time
    const time_current = new Date(state.meta.currentTime_ISO);
    const time_current_utc = new Date(
        time_current.getUTCFullYear(),
        time_current.getUTCMonth(),
        time_current.getUTCDate(),
        time_current.getUTCHours(),
        time_current.getUTCMinutes(),
        time_current.getUTCSeconds()
    );
    dataAttr.time_current = time_current_utc;


    // Client's current time END OF TIME UNIT
    const time_current_endOfUnit = new Date(state.meta.currentTime_endOfUnit_ISO);
    const time_current_endOfUnit_utc = new Date(
        time_current_endOfUnit.getUTCFullYear(),
        time_current_endOfUnit.getUTCMonth(),
        time_current_endOfUnit.getUTCDate(),
        time_current_endOfUnit.getUTCHours(),
        time_current_endOfUnit.getUTCMinutes(),
        time_current_endOfUnit.getUTCSeconds()
    );
    dataAttr.time_current_endOfUnit = time_current_endOfUnit_utc;




    // dataAttr.metricType     = 'spend';// state.meta.metricType;


    // Current time
    // const time_current = new Date(state.meta.currentTime_ISO);
    // const time_current_utc = new Date(
    //     time_current.getUTCFullYear(),
    //     time_current.getUTCMonth(),
    //     time_current.getUTCDate(),
    //     time_current.getUTCHours(),
    //     time_current.getUTCMinutes(),
    //     time_current.getUTCSeconds()
    // );
    // dataAttr.time_current_utc = time_current_utc;

    // Today time
    // const today_EndOfLastSecond = new Date(state.meta.today_EndOfLastSecond_ISO);
    // const time_today_EndOfLastSecond_utc = new Date(
    //     today_EndOfLastSecond.getUTCFullYear(),
    //     today_EndOfLastSecond.getUTCMonth(),
    //     today_EndOfLastSecond.getUTCDate(),
    //     today_EndOfLastSecond.getUTCHours(),
    //     today_EndOfLastSecond.getUTCMinutes(),
    //     today_EndOfLastSecond.getUTCSeconds()
    // );
    // dataAttr.time_today_EndOfLastSecond_utc = time_today_EndOfLastSecond_utc;

    // Convert date to js Date object
    state.data.forEach((d)=>{
        const t = new Date(d.timeISO);
        const t_utc = new Date(t.getUTCFullYear(), t.getUTCMonth(), t.getUTCDate(),  t.getUTCHours(), t.getUTCMinutes(), t.getUTCSeconds());
        d.timeUTC = t_utc;
    });

    // Absicissa (date axis or the x axis)
        const dataX = this._dataX = state.data.map((d)=>{
            return d.timeUTC;
        });
        const dataLength = dataAttr.dataLength = state.data.length;

        const dataX_extent = d3.extent(dataX, function(d) { return d; });
        dataAttr.data_time_min = dataX_extent[0];
        dataAttr.data_time_max = dataX_extent[1];

        // Ploting max min.
        dataAttr.time_start = dataAttr.data_time_min;
        dataAttr.time_end   = dataAttr.data_time_max;

        let diffByTimeUnit;
        if      ( dataAttr.timeUnit === 'day'  ) {
            diffByTimeUnit = 'days';
        } else if ( dataAttr.timeUnit === 'hour' ) {
            diffByTimeUnit = 'hours';
        }

        // count how many time bin
        const moment_time_start = moment(dataAttr.time_start);
        const moment_time_end = moment(dataAttr.time_end);

        const timeBinsLength = moment_time_end.diff(moment_time_start, diffByTimeUnit ) + 1; // include end point
        dataAttr.timeBinsLength = timeBinsLength;

        dataAttr.bandWidth = conf.graphArea.w/timeBinsLength;
        // dataAttr.barWidth  = dataAttr.bandWidth*(80/100);

    // Ordinate (data axis or the y axis)
        const dataY_filled = state.data.map((d)=>{ return d.filled; });
        const dataY_ideal_pace = state.data.map((d)=>{ return d.ideal_pace; });
        const dataY_ideal_unPace = state.data.map((d)=>{ return d.ideal_unPace; });
        const dataY_all = Array.prototype.concat.call(dataY_filled, dataY_ideal_pace, dataY_ideal_unPace);
        dataAttr.ordinate_max  = d3.max(dataY_all);
        dataAttr.ordinate_min  = 0;


    // Has ad started alread?
    const M_time_start = moment(dataAttr.time_start);
    const M_time_current = moment(dataAttr.time_current);
    const hasAdAlreadyStarted = M_time_start.isBefore(M_time_current);
    dataAttr.hasAdAlreadyStarted = hasAdAlreadyStarted;
};


D3Chart.prototype._calculateBand = function() {
    const that = this,  conf = this._conf, dataAttr = this._dataAttr;
    const scale = this._scale_abscissa();

    const dataX = this._dataX;
    const dataLength = dataAttr.dataLength ;

    // don't calculate band if width is illegal (this happend when campaign when user navigate to other campaign)
    let isLegalWidth;
    if (conf.svg.w > 100)  {
        isLegalWidth  = true;
    } else {
        isLegalWidth  = false;
    }

    switch (true && isLegalWidth) {
        case (dataAttr.dataLength >= 4):
            case_typical();
        break;
        case (dataAttr.dataLength === 3):
            case_3dataPoints();
        break;
        case (dataAttr.dataLength === 2):
            case_2dataPoints();
        break;
    }


    function case_typical() {
        const M_1 = moment(dataX[0]);
        const M_2 = moment(dataX[1]);
        const M_3 = moment(dataX[2]);
        const M_4 = moment(dataX[3]);

        const M_last_3 = moment(dataX[dataLength-3]);
        const M_last_2 = moment(dataX[dataLength-2]);
        const M_last_1 = moment(dataX[dataLength-1]);

        // pointDistance unit is in second
        const pointDistance_1st      = M_2.diff(     M_1     /*, diffByTimeUnit, true*/)/1000;
        const pointDistance_2nd      = M_3.diff(     M_2     /*, diffByTimeUnit, true*/)/1000;
        const pointDistance          = M_4.diff(     M_3     /*, diffByTimeUnit, true*/)/1000;
        const pointDistance_last_2nd = M_last_2.diff(M_last_3/*, diffByTimeUnit, true*/)/1000;
        const pointDistance_last     = M_last_1.diff(M_last_2/*, diffByTimeUnit, true*/)/1000;

        // console.log( 'pointDistance_1st       :',  pointDistance_1st      );
        // console.log( 'pointDistance_2nd       :',  pointDistance_2nd      );
        // console.log( 'pointDistance           :',  pointDistance          );
        // console.log( 'pointDistance_last_2nd  :',  pointDistance_last_2nd );
        // console.log( 'pointDistance_last      :',  pointDistance_last     );

        const offSet_1st      = 0;
        const offSet_2nd      = pointDistance_1st/2;
        const offSet          = pointDistance/2;
        const offSet_last_2nd = pointDistance_last_2nd/2;
        const offSet_last     = pointDistance_last/2;

        const bandWidth_1st      = pointDistance_1st/2;
        const bandWidth_2nd      = pointDistance_1st/2      + pointDistance_2nd/2;
        const bandWidth          = pointDistance;
        const bandWidth_last_2nd = pointDistance_last_2nd/2 + pointDistance_last/2;
        const bandWidth_last     = pointDistance_last/2;

        const jsD_offSet_1st      = moment(dataAttr.time_start).add(offSet_1st      , 's').toDate();
        const jsD_offSet_2nd      = moment(dataAttr.time_start).add(offSet_2nd      , 's').toDate();
        const jsD_offSet          = moment(dataAttr.time_start).add(offSet          , 's').toDate();
        const jsD_offSet_last_2nd = moment(dataAttr.time_start).add(offSet_last_2nd , 's').toDate();
        const jsD_offSet_last     = moment(dataAttr.time_start).add(offSet_last     , 's').toDate();

        const jsD_bandWidth_1st      = moment(dataAttr.time_start).add(bandWidth_1st      , 's').toDate();
        const jsD_bandWidth_2nd      = moment(dataAttr.time_start).add(bandWidth_2nd      , 's').toDate();
        const jsD_bandWidth          = moment(dataAttr.time_start).add(bandWidth          , 's').toDate();
        const jsD_bandWidth_last_2nd = moment(dataAttr.time_start).add(bandWidth_last_2nd , 's').toDate();
        const jsD_bandWidth_last     = moment(dataAttr.time_start).add(bandWidth_last     , 's').toDate();

        dataAttr.bandWidth_offSet_1st      = scale(jsD_offSet_1st      ) + conf.margin.left + conf.padding.left + conf.paddingGA.left;
        dataAttr.bandWidth_offSet_2nd      = scale(jsD_offSet_2nd      );
        dataAttr.bandWidth_offSet          = scale(jsD_offSet          );
        dataAttr.bandWidth_offSet_last_2nd = scale(jsD_offSet_last_2nd );
        dataAttr.bandWidth_offSet_last     = scale(jsD_offSet_last     );

        dataAttr.bandWidth_1st      = scale(jsD_bandWidth_1st      ) + conf.margin.left + conf.padding.left + conf.paddingGA.left ;
        dataAttr.bandWidth_2nd      = scale(jsD_bandWidth_2nd      );
        dataAttr.bandWidth          = scale(jsD_bandWidth          );
        dataAttr.bandWidth_last_2nd = scale(jsD_bandWidth_last_2nd );
        dataAttr.bandWidth_last     = scale(jsD_bandWidth_last     ) + conf.margin.left + conf.padding.left + conf.paddingGA.left ;

        // console.log( '- - - - - - - - - - - - - - -');
        // console.log( pointDistance );
    }

    function case_3dataPoints() {
        const M_1 = moment(dataX[0]);
        const M_2 = moment(dataX[1]);
        const M_3 = moment(dataX[2]);

        // pointDistance unit is in second, divided by 1000 as diff's unit is mili-second
        const pointDistance_1st      = M_2.diff(M_1)/1000;
        const pointDistance_2nd      = M_3.diff(M_2)/1000;

            // console.log( 'pointDistance_1st       :',  pointDistance_1st      );
            // console.log( 'pointDistance_2nd       :',  pointDistance_2nd      );

        const offSet_1st      = 0;
        const offSet_2nd      = pointDistance_1st/2;
        const offSet_3rd      = pointDistance_2nd/2;

        const bandWidth_1st      = pointDistance_1st/2;
        const bandWidth_2nd      = pointDistance_1st/2      + pointDistance_2nd/2;
        const bandWidth_3rd      = pointDistance_2nd/2;

        const jsD_offSet_1st      = moment(dataAttr.time_start).add(offSet_1st      , 's').toDate();
        const jsD_offSet_2nd      = moment(dataAttr.time_start).add(offSet_2nd      , 's').toDate();
        const jsD_offSet_3rd      = moment(dataAttr.time_start).add(offSet_3rd      , 's').toDate();

        const jsD_bandWidth_1st      = moment(dataAttr.time_start).add(bandWidth_1st      , 's').toDate();
        const jsD_bandWidth_2nd      = moment(dataAttr.time_start).add(bandWidth_2nd      , 's').toDate();
        const jsD_bandWidth_3rd      = moment(dataAttr.time_start).add(bandWidth_3rd      , 's').toDate();

        dataAttr.bandWidth_offSet_1st      = scale(jsD_offSet_1st ) + conf.margin.left + conf.padding.left + conf.paddingGA.left;
        dataAttr.bandWidth_offSet_2nd      = scale(jsD_offSet_2nd );
        dataAttr.bandWidth_offSet          = scale(jsD_offSet_2nd );
        dataAttr.bandWidth_offSet_last_2nd = scale(jsD_offSet_2nd );
        dataAttr.bandWidth_offSet_last     = scale(jsD_offSet_3rd );

        dataAttr.bandWidth_1st      = scale(jsD_bandWidth_1st ) + conf.margin.left + conf.padding.left + conf.paddingGA.left ;
        dataAttr.bandWidth_2nd      = scale(jsD_bandWidth_2nd );
        dataAttr.bandWidth          = scale(jsD_bandWidth_2nd );
        dataAttr.bandWidth_last_2nd = scale(jsD_bandWidth_2nd );
        dataAttr.bandWidth_last     = scale(jsD_bandWidth_3rd ) + conf.margin.left + conf.padding.left + conf.paddingGA.left ;
    }

    function case_2dataPoints() {
        const M_1 = moment(dataX[0]);
        const M_2 = moment(dataX[1]);

        // pointDistance unit is in second, divided by 1000 as diff's unit is mili-second
        const pointDistance_1st      = M_2.diff(M_1)/1000;

        // console.log( 'pointDistance_1st       :',  pointDistance_1st      );

        const offSet_1st      = 0;
        const offSet_2nd      = pointDistance_1st/2;

        const bandWidth_1st      = pointDistance_1st/2;
        const bandWidth_2nd      = pointDistance_1st/2;

        const jsD_offSet_1st      = moment(dataAttr.time_start).add(offSet_1st      , 's').toDate();
        const jsD_offSet_2nd      = moment(dataAttr.time_start).add(offSet_2nd      , 's').toDate();

        const jsD_bandWidth_1st      = moment(dataAttr.time_start).add(bandWidth_1st      , 's').toDate();
        const jsD_bandWidth_2nd      = moment(dataAttr.time_start).add(bandWidth_2nd      , 's').toDate();

        dataAttr.bandWidth_offSet_1st      = scale(jsD_offSet_1st ) + conf.margin.left + conf.padding.left + conf.paddingGA.left;
        dataAttr.bandWidth_offSet_2nd      = scale(jsD_offSet_2nd );
        dataAttr.bandWidth_offSet          = scale(jsD_offSet_2nd );
        dataAttr.bandWidth_offSet_last_2nd = scale(jsD_offSet_2nd );
        dataAttr.bandWidth_offSet_last     = scale(jsD_offSet_2nd );

        dataAttr.bandWidth_1st      = scale(jsD_bandWidth_1st ) + conf.margin.left + conf.padding.left + conf.paddingGA.left;
        dataAttr.bandWidth_2nd      = scale(jsD_bandWidth_2nd ) + conf.margin.left + conf.padding.left + conf.paddingGA.left;
        dataAttr.bandWidth          = scale(jsD_bandWidth_2nd ) + conf.margin.left + conf.padding.left + conf.paddingGA.left;
        dataAttr.bandWidth_last_2nd = scale(jsD_bandWidth_2nd ) + conf.margin.left + conf.padding.left + conf.paddingGA.left;
        dataAttr.bandWidth_last     = scale(jsD_bandWidth_2nd ) + conf.margin.left + conf.padding.left + conf.paddingGA.left;
    }
};



////////////////////////////
///   plotting cycle     ///
////////////////////////////
D3Chart.prototype.init = function( el, state) {
    const conf = this._conf, svg = this._svg;

    svg.el = el;
    conf.svg.w = $(el).find('.am-adProgressGraph-svg').width();

    this._createPlottingArea(el, state);

    this._events_mount(el);

    // this._firstPlot(el, state)
};

D3Chart.prototype.firstPlot = function(el, state) {
    const that = this;
    const svg = this._svg, conf = this._conf, dataAttr = this._dataAttr;

    this._calculateDataSetting(state);

    this._plotXAxis(el, 'firstPlot');
    this._plot_mouseLine('firstPlot');

    this._calculateBand();
    this._plotData(el, state, 'firstPlot' );

    this._updateLegend();
};

D3Chart.prototype.update = function(el, state) {
    const that = this;
    const svg = this._svg, conf = this._conf, dataAttr = this._dataAttr;

    // update plotting area
    this._calculatePlottingArea(state);
    this._update_plottingArea(el, state);

    this._calculateDataSetting(state);

    // update axis
    this._plotXAxis(el, 'update');

    this._plot_mouseLine('update');

    // update plot
    this._calculateBand();
    this._plotData(el, state, 'update' );
    this._updateLegend();
};

D3Chart.prototype.resize = function(el, state) {
    const that = this;
    const svg = this._svg, conf = this._conf, dataAttr = this._dataAttr;

    // update plotting area
    this._calculatePlottingArea(state);
    this._update_plottingArea(el, state);

    // update axis
    this._plotXAxis(el, 'resize');

    // update plot
    this._calculateBand();
    this._plotData(el, state, 'resize' );
};

D3Chart.prototype.distroy = function(el, state) {
    const that = this,  conf = this._conf, svg = this._svg;
    this._events_unMount(el);
};



/////////////////////////////////
// setup and tear down evetns  //
/////////////////////////////////
D3Chart.prototype._events_mount = function(el) {
    const that = this,  conf = this._conf, svg = this._svg;

    svg.graphArea.on('mousemove', function(e){
        const p = d3.mouse(this);
        const x = p[0];
    });

    // Area event
    svg.graphArea.on('mouseenter', function(e){
        that._plot_mouseLine('mouseenter');
    });

    svg.graphArea.on('mouseleave', function(e){
        that._plot_mouseLine('mouseleave');
    });
};

D3Chart.prototype._events_unMount = function(el) {
    const that = this,  conf = this._conf, svg = this._svg;

    svg.graphArea.on('mousemove', null);
    svg.graphArea.on('mouseenter', null);
    svg.graphArea.on('mouseleave', null);

    svg.plotGroup_band.selectAll('.data-point').on('mouseenter', null);
    svg.plotGroup_band.selectAll('.data-point').on('mouseleave', null);
};





///////////////////////////
///   plotting routine  ///
///////////////////////////
D3Chart.prototype._createPlottingArea = function(el, state) {
    const that = this, conf = this._conf, svg = this._svg;

    conf.el = el;

    svg.container = d3.select(el).select('svg.am-adProgressGraph-svg');

    svg.content = svg.container
        .append('g').classed('svg-content', true);

    svg.contentBg = svg.content.append('rect')
        .attr('class', 'svg-content-bg')
        .attr('fill', '#fff');

    svg.plottingArea = svg.content.append('g')
        .classed('svg-plot-area', true);

    svg.plottingAreaBg = svg.plottingArea.append('rect')
        .attr('class' , 'svg-plotting-area-bg')
        .attr('fill'  , '#fff');

    svg.graphArea = svg.plottingArea.append('g')
        .classed('svg-graph-area', true);

    svg.graphAreaBg = svg.graphArea.append('rect')
        .attr('class' , 'svg-graph-area-bg')
        .attr('fill'  , '#fff');

    this._calculatePlottingArea(state);
    this._update_plottingArea(el, state);

    // set up area for axis
    var axisLocationY = conf.graphArea.h;
    svg.area_xAxis = svg.graphArea.append('g').attr('class', 'x axis')
        .attr('transform', 'translate(0,'+axisLocationY+')');
    svg.area_xAxis.append('text').classed('start', true);
    svg.area_xAxis.append('text').classed('end', true);


    // set up area for data graph
    svg.plotGroup_filled    = svg.graphArea.append('g').classed('data-points group-filled'   , true);
    svg.plotGroup_filled.append('path');

    svg.plotGroup_ideal_p   = svg.graphArea.append('g').classed('data-points group-ideal-pace'  , true);
    svg.plotGroup_ideal_p.append('path');

    svg.plotGroup_ideal_unP = svg.graphArea.append('g').classed('data-points group-ideal-unPace', true);
    svg.plotGroup_scatter   = svg.graphArea.append('g').classed('data-points group-scatter'  , true);
    svg.plotGroup_band      = svg.graphArea.append('g').classed('data-points group-band'     , true);

    // mouse line
    const lineLength = conf.graphArea.h;
    svg.mouseLine_mouseLine = svg.graphArea.append('g').classed('mouse-line', true);
    svg.mouseLine_mouseLine
        .append('line')
        .attr('x1', '0')
        .attr('y1', '0')
        .attr('x2', '0')
        .attr('y2', lineLength)
        // .attr('stroke', 'none')
        .attr('stroke-dasharray', '2,2')
        .attr('stroke-width', 0.5)
        .attr('fill', 'none');
};

D3Chart.prototype._update_plottingArea = function(el, state) {
    const that = this,  conf = this._conf, svg = this._svg;

    let adId = void 0;
    if (state && state.meta) {
        adId = state.meta.adId;
    }

    if (conf.svg.w > 100) {
        // update plotting area
        svg.content
            .attr('transform', 'translate('+conf.margin.left+','+conf.margin.top+')');

        svg.contentBg
            .attr('width',  conf.svgContent.w)
            .attr('height', conf.svgContent.h);

        svg.plottingArea
            .attr('transform', 'translate('+conf.padding.left+','+conf.padding.top+')');

        svg.plottingAreaBg
            .attr('width',  conf.plottingArea.w)
            .attr('height', conf.plottingArea.h);

        svg.graphArea
            .attr('transform', 'translate('+conf.paddingGA.left+','+conf.paddingGA.top+')');

        svg.graphAreaBg
            .attr('width',  conf.graphArea.w)
            .attr('height', conf.graphArea.h);
    }
};

D3Chart.prototype._plotData = function(el, state, mode ) {
    const that = this, conf = this._conf, dataAttr = this._dataAttr, svg = this._svg;

    const hasDailyCap     = dataAttr.hasDailyCap;
    const hasTotalTarget  = dataAttr.hasTotalTarget;
    const metricType      = dataAttr.metricType;
    const adId            = dataAttr.adId;

    const scales = that._scales();

    if ( (mode === 'firstPlot') || (mode === 'update') ) {
        dataAttr.data = state.data;
        d3.select(conf.el).classed('is-metricType-'+metricType, true);
        d3.select(conf.el).attr('data-ad-id', adId);
    }

    // Start: Scatter plot: ideal pace, ideal unpace
        var options_scatter = {
            data      : dataAttr.data,
            el        : svg.plotGroup_scatter,
            scales    : that._scales()
        };
        this._draw_scatterPoints(options_scatter, mode);
    // Start: Scatter plot: ideal pace, ideal unpace


    // Start: band
        var options_band = {
            data      : dataAttr.data,
            el        : svg.plotGroup_band,
            scales    : scales
        };
        this._draw_band(options_band, mode);
    // End  : band


    // Start: Step plot: unPace
        if ( (mode === 'firstPlot') || (mode === 'update') ) {
            const indexOfLastDatum = dataAttr.dataLength - 1;
            dataAttr.data_ideal_unPace = [];
            state.data.forEach( (d, i) => {
                if ( d.state === 'isFuture') {
                    const M_t          = moment.utc(d.timeISO);
                    const date         = moment.utc(M_t).format('YYYY-MM-DD');
                    const M_endOfToday = moment.utc(date, 'YYYY-MM-DD').endOf('day');
                    const isEndOfDay   = M_t.isSame(M_endOfToday);
                    if (isEndOfDay || (i === indexOfLastDatum) ) {
                        dataAttr.data_ideal_unPace.push(d);
                    }
                }
            });
        }
        var options_steps_unPace = {
            data      : dataAttr.data_ideal_unPace,
            el        : svg.plotGroup_ideal_unP,
            scales    : that._scales()
        };
        this._draw_steps_unPace(options_steps_unPace, mode);
    // End  : Step plot: unPace


    // Start: Line plot: ideal pace
        const options_ideal_pace = {
            data       : dataAttr.data,
            el         : svg.plotGroup_ideal_p,
            scales     : that._scales()
        };
        this._draw_line_pace(options_ideal_pace, mode);
    // End  : Line plot: ideal pace


    // Start: Area plot: filled
        if ( (mode === 'firstPlot') || (mode === 'update') ) {
            dataAttr.data_fill = [];
            state.data.forEach( (d) => {
                const isPast = (d.state === 'isPast') ? true : false;
                const isNow = (d.state === 'isNow') ? true : false;
                if (isPast || isNow) { dataAttr.data_fill.push(d); }
            });
        }
        const options_filled = {
            data        : dataAttr.data_fill,
            scales      : that._scales(),
            el          : svg.plotGroup_filled
        };
        this._draw_area(options_filled, mode);
    // End  : Area plot: filled
};

D3Chart.prototype._plot_mouseLine = function(mode) {
    const that = this, conf = this._conf, svg = this._svg;

    // const strokeColor = 'red';
    // const mouseLine= svg.mouseLine_mouseLine.select('line');

    // if (mode === 'mouseenter' ) {
    //     mouseLine.attr('stroke', strokeColor);
    // }

    // if (mode === 'mouseleave' ) {
    //     mouseLine.attr('stroke', 'none');
    // }
};



//////////////////////////
///   drawing routine  ///
//////////////////////////
D3Chart.prototype._draw_scatterPoints = function(opts, mode){
    var that = this,  conf = this._conf, dataAttr = this._dataAttr;
    const {data, color, el, scales } = opts;
    const points = el;

    // const metricsColor = that._const.color_metric[dataAttr.metricType];
    const hasDailyCap  = dataAttr.hasDailyCap;
    const hasTotalTarget  = dataAttr.hasTotalTarget;

    let dataKey;

    let point;

    if (mode !== 'resize' ) {
        point = points.selectAll('g')
            .data(data, function(d) {
                const id = _.uniqueId();
                return id;
            });
        point.enter().append('g').classed('data-point', true);
        point.exit().remove();
    } else {
        point = points.selectAll('g');
    }

    let indexOfLastFilled;
    const dataContainer = point
        .attr('transform', (d, i) => {
            const time = new Date(d.timeUTC);
            const x = scales.x(new Date(d.timeUTC));
            return 'translate('+x+','+0+')';
        })
        .attr('data-date', (d) => {
            const dataId = d.timeUTC;
            return dataId;
        })
        .attr('data-index', (d, i) => i)
        .classed('last-item is-totalTarget', (d, i) => { return ( i === (dataAttr.dataLength - 1)) ? true : false; })
        .each((d, i) => {
            if ( (d.state === 'isPast') || (d.state === 'isNow') ) { indexOfLastFilled = i; }
        });

    // START :: Scatter: target diamond, target-text  //
        if ( hasTotalTarget && (  (mode === 'firstPlot') || (mode === 'update') ) ) {
            dataKey = 'ideal_pace';
            const lastItem = points.select('.data-points.group-scatter .last-item');

            // target diamond
            lastItem
                .append('rect')
                // .attr('fill', metricsColor )
                .attr('fill-opacity', '1')
                .attr('x', -4 )
                .attr('y', -4 )
                .attr('width', 8)
                .attr('height', 8)
                .attr('transform', (d,i) => {
                    const value = d[dataKey];
                    const dataY = scales.y(value);
                    const y = conf.graphArea.h - dataY;
                    return 'translate('+0+','+y+') rotate(45, 0, 0)';
                });

            // text label
            lastItem
                .append('text')
                .attr('y', -20 )
                .text( function (d) {
                    if (dataAttr.metricType === 'spend') {
                        return '$'+numeral(dataAttr.maxTotalMetric).format('0,0.00');
                    } else {
                        return numeral(dataAttr.maxTotalMetric).format('0,0');
                    }
                })
                .attr('font-family', 'sans-serif')
                .attr('font-size', '11px')
                // .attr('fill', metricsColor)
                .attr('style', 'text-anchor: middle')
                .attr('transform', (d,i) => {
                    const value = d[dataKey];
                    const dataY = scales.y(value);
                    const y = conf.graphArea.h - dataY;
                    return 'translate('+0+','+y+')';
                });
        }// END :: mode fisrtPlot, update

        if ( (mode === 'resize') ) {
            // no need to do anything on resize
        }// END :: mode resize
    // END   :: Scatter: target diamond, target-text  //


    // START :: Scatter, last filled  //
        if ( (mode === 'firstPlot') || (mode === 'update') ) {
            dataKey = 'filled';
            // const lastFilled = d3.select('.data-points.group-scatter').select('[data-index="'+indexOfLastFilled+'"]')
            const lastFilled = points.select('[data-index="'+indexOfLastFilled+'"]');

            lastFilled
                .classed('is-lastFilled', function (d) {
                    let health = d.health;
                    health = (health === 'n/a') ? null : health;
                    d3.select(this).classed(`is-${health}Health`, true);
                    return true;
                })
                .append('circle')
                .attr('r', 4  )
                .attr('transform', (d,i) => {
                    const value = d[dataKey];
                    const dataY = scales.y(value);
                    const y = conf.graphArea.h - dataY;
                    return 'translate('+0+','+y+')';
                });
        }
        if ( (mode === 'resize') ) {
            // no need to do anything on resize
        }// END :: mode resize
    // END   :: Scatter, last filled  //


    // START :: Scatter, ideal_unPace  //
        //-------------------------------------------------------//
        //-- uncomment bellow to plot scatter for ideal_unPace --//
        //-------------------------------------------------------//
        // if ( (mode === 'firstPlot') || (mode === 'update') ) {
        //     let dataKey = 'ideal_unPace';
        //     dataContainer
        //         .filter(function(d, i){
        //             if (d.state === 'isFuture') {
        //                 const M_timeISO =  moment.utc(d.timeISO);
        //                 const date =  moment.utc(M_timeISO).format('YYYY-MM-DD');
        //                 const M_endOfToday =  moment.utc(date, 'YYYY-MM-DD').endOf('day');
        //                 const isEndOfDay = M_timeISO.isSame(M_endOfToday);
        //                 return (isEndOfDay) ? true : false;
        //             } else {
        //                 return false;
        //             }
        //         })
        //         .append('circle')
        //         .attr('class', 'ideal_pace')
        //         .attr('r', 3)
        //         .attr('stroke', 'blue')
        //         .attr('stroke-width',1)
        //         .attr('fill', 'none')
        //         .attr('transform', (d,i) => {
        //             const value = d[dataKey];
        //             const dataY = parseInt(scales.y(d[dataKey]));
        //             const y = conf.graphArea.h - dataY;
        //             return 'translate('+0+','+y+')';
        //         })
        //         .attr('data-ideal-p', (d) => d[dataKey]);
        // }// END if
        //
        // if ( (mode === 'resize') ) {
        //     // no need to do anything on resize
        // }// END :: mode resize
    // END   :: Scatter, ideal_unPace  //


    // START :: Scatter, ideal_pace  //
        // //-------------------------------------------------------//
        // //-- uncomment bellow to plot scatter for ideal_unPace --//
        // //-------------------------------------------------------//
        // if ( (mode === 'firstPlot') || (mode === 'update') ) {
        //
        //     dataContainer.append('circle')
        //     .attr('class', 'ideal_pace')
        //     .attr('r', 5)
        //     .attr('stroke', 'red')
        //     .attr('stroke-width', 1)
        //     .attr('fill', 'none')
        //     .attr('transform', (d,i) => {
        //         const value = d[dataKey];
        //         const dataY = parseInt(scales.y(d[dataKey]));
        //         const y = conf.graphArea.h - dataY;
        //         return 'translate('+0+','+y+')';
        //     })
        //     .attr('data-ideal-p', (d) => d[dataKey]);
        // }// END if
        //
        // if ( (mode === 'resize') ) {
        //     // no need to do anything on resize
        // }// END :: mode === resize
    // END   :: Scatter, ideal_pace  //
};

D3Chart.prototype._draw_band = function(opts, mode){
    var that = this,  conf = this._conf, dataAttr = this._dataAttr, svg = this._svg;

    const {data, el, scales } = opts;

    // Enter-exit-cycle
    const points = el;
    let point;
    let point_exit;
    if (mode !== 'resize' ) {
        point = points.selectAll('g')
            .data(data, function(d) {
                const id = _.uniqueId();
                return id;
            });
        point.enter().append('g').classed('data-point', true);
        point_exit = point.exit();

        // Unmount events before remove exited item
        point_exit.on('mouseenter', null);
        point_exit.on('mouseleave', null);

        point_exit.remove();
    } else if (mode === 'resize') {
        point = points.selectAll('g');
    }

    // Achor bands' x position
    const dataContainer = point
        .attr('transform', (d, i) => {
            const time = new Date(d.timeUTC);
            const x = scales.x(time);
            return 'translate('+x+','+0+')';
        })
        .attr('data-date', (d) => {
            const dataId = d.timeUTC;
            return dataId;
        })
        .attr('data-index', (d, i) => i);


    // Start :: Events
        if ( (mode === 'firstPlot') || (mode === 'update'))  {
            // Mount events
            dataContainer.on('mouseenter', cb_onMousenenter);
            dataContainer.on('mouseleave', cb_onMousenleave);
        }

        function cb_onMousenenter(d, i) {
            // snap mouse line to data point
            const time = new Date(d.timeUTC);
            const x =  that._scales().x(time);
            // [!] Note that in the above,  we need to exec D3Chart._scales()
            //     instead of using the scale that past into D3Chart._draw_band
            svg.mouseLineX = x;
            svg.mouseLine_mouseLine.attr('transform', 'translate('+x+','+0+')');

            that._updateToolTipPositionInClient();

            // const time =  moment(d.timeISO).toISOString();
            that._updateToolTips({
                timeUTC      : d.timeUTC,
                timeISO      : d.timeISO,
                metricType   : d.metricType,
                filled       : d.filled,
                ideal_pace   : d.ideal_pace,
                ideal_unPace : d.ideal_unPace,
                health       : d.health,
                state        : d.state
            });
        }

        function cb_onMousenleave(d, i) {}
    // End   :: Events


    // Start :: Band //
        if ( (mode === 'firstPlot') || (mode === 'update') ) {
            const hasNoRect = dataContainer.select('rect').empty();
            if (hasNoRect) {
                dataContainer.append('rect')
                        // FOR DEBUG
                        // .attr('fill', '#aa77aa')
                        // .attr('fill-opacity', '0.4')
                        // .attr('stroke', 'black')
                        // .attr('stroke-width', 0.5)
                    .attr('fill', 'transaprent')
                    .attr('fill-opacity', '0')
                    .attr('x', cb_bandOffSet )
                    .attr('y', function(d, i) { return 0; })
                    .attr('width', cb_bandWidth)
                    .attr('height', function(d, i) { return conf.graphArea.h; });
            }
        }

        if ( (mode === 'resize') ) {
            dataContainer.select('rect')
                .attr('x', cb_bandOffSet )
                .attr('width', cb_bandWidth);
        }

        function cb_bandOffSet(d, i) {
            let offSet;
            if ( i === 0) {
                offSet = - dataAttr.bandWidth_offSet_1st;
            } else if ( i === 1 ) {
                offSet = - dataAttr.bandWidth_offSet_2nd;
            } else if ( i === ( dataAttr.dataLength - 2) ) {
                offSet = - dataAttr.bandWidth_offSet_last_2nd;
            } else if ( i === ( dataAttr.dataLength - 1) ) {
                offSet = - dataAttr.bandWidth_offSet_last;
            } else {
                offSet = - dataAttr.bandWidth_offSet;
            }
            return offSet;
        }

        function cb_bandWidth(d, i) {
            let bandWidth;
            if ( i === 0) {
                bandWidth = dataAttr.bandWidth_1st;
            } else if ( i === 1 ) {
                bandWidth = dataAttr.bandWidth_2nd;
            } else if ( i === ( dataAttr.dataLength - 2 ) ) {
                bandWidth = dataAttr.bandWidth_last_2nd;
            } else if ( i === ( dataAttr.dataLength - 1 ) ) {
                bandWidth = dataAttr.bandWidth_last;
            } else {
                bandWidth = dataAttr.bandWidth;
            }

            // BandWidth can not be negative
            if (bandWidth < 0) {
                return 0;
            } else {
                return bandWidth;
            }
        }
    // END   :: Band //
};

D3Chart.prototype._draw_steps_unPace = function(opts, mode){
    const that = this, conf = this._conf, svg = this._svg, dataAttr = this._dataAttr;
    const scale = this._scale_abscissa();

    const {data, el, scales } = opts;
    // const metricsColor = that._const.color_metric[dataAttr.metricType];
    const hasDailyCap  = dataAttr.hasDailyCap;
    const points = el;

    const dataLength = data.length;
    let dataKey;
    let point;
    if (mode !== 'resize' ) {
        point = points.selectAll('g')
            .data(data, function(d, i) { const id = _.uniqueId(); return id; });
        point.enter().append('g').classed('data-point', true).classed('unPace', true);
        point.exit().remove();
    } else {
        point = points.selectAll('g');
    }


    const dataContainer = point
        .attr('transform', (d, i) => {
            const time = new Date(d.timeUTC);
            const x = scales.x(new Date(d.timeUTC));
            return 'translate('+x+','+0+')';
        })
        .attr('data-date', (d) => {
            const dataId = d.timeUTC;
            return dataId;
        })
        .attr('data-index', (d, i) => i);

    // for debug
    // dataContainer.append('circle')
    //     .attr('r', 4  )
    //     .attr('stroke', 'red')
    //     .attr('stroke-width', 1)

    // Start :: Step, ideal_unPace  //
        if ( hasDailyCap && ( (mode === 'firstPlot') || (mode === 'update')) ) {
            dataKey = 'ideal_unPace';
            // const stepWidth = -dataAttr.bandWidth * 24;
            dataContainer
                .append('line')
                .attr('class', 'ideal_unPace')
                .attr('x1', '0')
                .attr('y1', '0')
                .attr('x2',  function(d,i){ return -1*cb_calculate_stepWidth(d,i); })
                .attr('y2', '0')
                .attr('transform', (d,i) => {
                    const value = d[dataKey];
                    const dataY = scales.y(d[dataKey]);
                    const y = conf.graphArea.h - dataY;
                    return 'translate('+0+','+y+')';
                })
                .attr('data-ideal-unPace', (d) => d[dataKey]);
        }// END if

        if ( hasDailyCap && (mode === 'resize') ) {
            dataKey = 'ideal_unPace';
            dataContainer.select('.ideal_unPace')
                .attr('x1', '0')
                .attr('y1', '0')
                .attr('x2',  function(d,i){ return -1*cb_calculate_stepWidth(d,i); })
                .attr('y2', '0')
                .attr('transform', (d,i) => {
                    const value = d[dataKey];
                    const dataY = scales.y(d[dataKey]);
                    const y = conf.graphArea.h - dataY;
                    return 'translate('+0+','+y+')';
                });
        }// END if

        function cb_calculate_stepWidth(d,i) {
            let stepWidth;

            const statsLength = dataAttr.dataLength;
            const indexOfLastStepDatum = dataLength - 1;
            const M_adStart = moment(dataAttr.time_start);
            const M_adEnd   = moment(dataAttr.time_end);
            const adAgeInHour = M_adEnd.diff(M_adStart, 'seconds')/60/60;

            if ( (statsLength >= 3) ) {
                if ( !dataAttr.hasAdAlreadyStarted &&  i === 0) {
                    // first point
                    const M_t_firstDatum = moment(d.timeUTC);
                    const M_t_firstDatum_startOfHour = M_t_firstDatum.clone().endOf('hour');
                    const diff = M_t_firstDatum_startOfHour.diff( M_adStart, 'seconds');///60/60;
                    const jsD_width = moment(dataAttr.time_start).add(diff, 's').toDate();
                    stepWidth = scale(jsD_width);
                } else {
                    const M_t_lastDatum = moment(d.timeUTC);
                    const M_t_lastDatum_endPreviousDay = M_t_lastDatum.clone().subtract(1, 'day').endOf('day');
                    const diff = M_t_lastDatum.diff( M_t_lastDatum_endPreviousDay, 'seconds');
                    const jsD_width = moment(dataAttr.time_start).add(diff, 's').toDate();
                    stepWidth = scale(jsD_width);
                }
            } else if ( statsLength === 2 ) {
                const diff = M_adEnd.diff( M_adStart, 'seconds');
                const jsD_width = moment(dataAttr.time_start).add(diff, 's').toDate();
                stepWidth = scale(jsD_width);
            }

            return stepWidth;
        }
    // END   :: Step, ideal_pace  //
};

D3Chart.prototype._draw_line_pace = function(opts, mode){
    const that = this, conf = this._conf, svg = this._svg, dataAttr = this._dataAttr;
    const {data, el, scales } = opts;
    const dataKey = 'ideal_pace';
    const pathElement = el.select('path');
    const hasTotalTarget = dataAttr.hasTotalTarget;

    // draw line
    var dataLine = d3.svg.line()
        .x(function(d, i) {
            const time = new Date(d.timeUTC);
            const x = scales.x(new Date(d.timeUTC));
            // if (i === 0) console.log('first x: ', d.timeUTC, x);
            return x;
        })
        .y(function(d) {
            const value = d[dataKey];
            // const dataY = parseInt(scales.y(d[dataKey]), 10);
            const dataY = scales.y(d[dataKey]);
            const y = conf.graphArea.h - dataY;
            return y;
        });
        // .interpolate("cardinal");
        // .interpolate('basis');

    if ( hasTotalTarget && ( (mode === 'resize') || (mode==='update') ) ) {
        pathElement.attr('d', dataLine(data));
    } else {
        pathElement.attr('d', '');
    }
};

D3Chart.prototype._draw_area = function(opts, mode){
    const that = this, conf = this._conf, svg = this._svg, dataAttr = this._dataAttr;
    const { data, scales, el} = opts;

    const datalength = data.length;
    const dataKey = 'filled';
    const pathElement = el.select('path');
    let dataPathArea;

    if (datalength) {
        // draw line
        const dataPath = d3.svg.line()
            .x(function(d, i) {
                const time = new Date(d.timeUTC);
                const x = scales.x(new Date(d.timeUTC));
                // if (i === 0) console.log('first x: ', d.timeUTC, x);
                return x;
            })
            .y(function(d) {
                const value = d[dataKey];
                const dataY = scales.y(d[dataKey]);
                const y = conf.graphArea.h - dataY;
                return y;
            })
            .interpolate('cardinal');
            // .interpolate('basis');

        // close path to form area
        dataPathArea = dataPath(data) + 'V' + conf.graphArea.h + 'H0Z';
    } else {
        dataPathArea = '';
    }

    if ( mode === 'resize' ) {
        pathElement.attr('d', dataPathArea);
    }

    if ( mode === 'update' ) {
        pathElement.attr('d', dataPathArea);
    }
};



/////////////////////
///    Axis       ///
/////////////////////
D3Chart.prototype._plotXAxis_presentation = function(el, mode){
    const that = this, conf = this._conf, svg = this._svg, dataAttr = this._dataAttr;

    var xAxisContainer = svg.area_xAxis;

    var startDate = dataAttr.time_start;
    var endDate   = dataAttr.time_end;

    // setup scale for axis
        var xAxisMargin = 0;

        var dS= startDate;
        var dE= endDate;
        var rS= 0 + xAxisMargin;
        var rE= conf.graphArea.w - xAxisMargin;
        var axisScale = d3.time.scale().domain([dS,dE]).range([rS,rE]);
        // console.log('axisScale.range: ', axisScale.range());
        // console.log('axisScale.domain: ', axisScale.domain());

    // Hacky, but currently startDate and endDate is stored in local time BUT treated as UTC time
    var text_startDate = moment(startDate).format('MMM DD, HH:mm:ss') + ' UTC';
    var text_endDate   = moment(endDate).format('MMM DD, HH:mm:ss') + ' UTC';

    if (mode !== 'resize') {
        xAxisContainer.select('.start')
            .attr('y', 20 )
            .attr('x', 0 )
            .text( text_startDate)
            .attr('font-family', 'sans-serif')
            .attr('font-size', '12px')
            .attr('fill', 'black')
            .attr('style', 'text-anchor: start');

        xAxisContainer.select('.end')
            .attr('y', 20 )
            .attr('x', rE )
            .text( text_endDate)
            .attr('font-family', 'sans-serif')
            .attr('font-size', '12px')
            .attr('fill', 'black')
            .attr('style', 'text-anchor: end');
    }

    if (mode === 'resize') {
        const start = xAxisContainer.select('.start')
            .attr('x', 0 );

        const end = xAxisContainer.select('.end')
            .attr('x', rE );
    }
};

D3Chart.prototype._plotXAxis_debug = function(el, mode){
    var that = this;
    const conf = this._conf;
    const svg = this._svg;
    const dataAttr = this._dataAttr;

    var startDate = dataAttr.time_start;
    var endDate   = dataAttr.time_end;

    // For testing
        // startDate = new Date("01-Jun-15");
        // endDate = new Date("03-Jun-15");
        // console.log(startDate, endDate);

    // parameter for you to tweek
        var minorTickIntervalInHour = 12;
        var numberOfTicksWDate = 20; // this is an approximate number

    // setup scale for axis
        // var xAxisMargin =  parseInt(dataAttr.bandWidth/2, 10);
        // var xAxisMargin =  dataAttr.bandWidth/2;
        var xAxisMargin = 0;

        var dS= startDate;
        var dE= endDate;
        var rS= 0 + xAxisMargin;
        var rE= conf.graphArea.w - xAxisMargin;
        var axisScale = d3.time.scale().domain([dS,dE]).range([rS,rE]);
        // console.log('axisScale.range: ', axisScale.range());
        // console.log('axisScale.domain: ', axisScale.domain());

    // calculate details
        var moment_startDate = moment(startDate);
        var moment_endDate = moment(endDate);
        var howManyDays_btw_startEndDate = moment_endDate.diff(moment_startDate, 'days') + 1; // include end point
        var n = howManyDays_btw_startEndDate; // put it into shorter variable name

    // adjustment for edge cases
        var numOfDataPointsToSkipLabel = ( n <= numberOfTicksWDate ) ? 0 : parseInt(n/numberOfTicksWDate, 10);
        var numberOfTicks = (n === 1) ? n : n-1; // a hack to take care of case when n is unity

    // major ticks
        var xAxis = d3.svg.axis()
            .scale(axisScale)
            .orient('bottom')
            .ticks( numberOfTicks )
            .tickSize(20, 0)  // this ticksize also govern default label distance to axis
            .tickFormat(function(d, index) {
                var mod = index % (numOfDataPointsToSkipLabel + 1);
                var out = (mod === 0) ? d3.time.format('%b %d')(d) : undefined;
                return  out;
            });
        var xAix_g = svg.area_xAxis.call(xAxis);

    // minor ticks
        const hasMinorTicks = false;
        if (hasMinorTicks) {
            // if (mode === 'firstPlot' ) {
            // } else if (mode === 'update') {
            // }

            const mimorTicksData = axisScale.ticks(d3.time.hour, minorTickIntervalInHour);

            const minorTicksGroups = svg.area_xAxis.selectAll('g')
                .data( mimorTicksData, function(d) { return moment(d).toISOString(); })
                .enter().append('g')
                .attr('class', 'tick minor')
                .attr('transform', function(d, i){
                    const x = axisScale(d);
                    const y = 0;
                    return 'translate('+x+','+y+')';
                });

            minorTicksGroups
                .append('line')
                .attr('class', 'minor-tick-line')
                .attr('y1', 0).attr('y2', 5) // minor tick length
                .attr('x1', 0).attr('x2', 0);
        }

    // major ticks with label
        svg.area_xAxis.selectAll('.tick line')
            .classed('tick-w-label',
                function(d, index) {
                    var mod = (index) % (numOfDataPointsToSkipLabel + 1);
                    if (mod === 0) { return d; }
                })
                .attr('y2', function(d,index){
                    // console.log('attr for y2: ', d)
                    var mod = (index) % (numOfDataPointsToSkipLabel + 1);
                    if (mod !== 0) {
                        return 0;
                    } else {
                        return 20;
                    }
                });
};


D3Chart.prototype._plotXAxis = function(el, mode){
    // this._plotXAxis_debug(el, mode);
    this._plotXAxis_presentation(el, mode);
};

///////////////////////
///     Scales      ///
///////////////////////
D3Chart.prototype._scales = function() {
    const that = this;
    const conf = this._conf;
    const dataAttr = this._dataAttr;

    const x = this._scale_abscissa();

    const maxDataY = dataAttr.ordinate_max;
    const minDataY = dataAttr.ordinate_min;
    const maxRangeY = conf.graphArea.h - conf.areaTimeAxis.h;
    const minRangeY = 0;

    var y = d3.scale.linear()
        .domain([minDataY, maxDataY])
        .range([minRangeY, maxRangeY]);

    return {x, y};
};

D3Chart.prototype._scale_abscissa = function() {
    const conf = this._conf, dataAttr = this._dataAttr;

    const maxDataX = dataAttr.time_end;
    const minDataX = dataAttr.time_start;

    // var startRangeX = 0  + dataAttr.bandWidth/2;
    // var endRangeX   = conf.graphArea.w - dataAttr.bandWidth/2;

    var startRangeX = 0;
    var endRangeX   = conf.graphArea.w;

    // var scale = d3.time.scale.utc()
    //     .domain([minDataX, maxDataX])
    //     .range([startRangeX, endRangeX]);

    var scale = d3.time.scale()
        .domain([minDataX, maxDataX])
        .range([startRangeX, endRangeX]);

    // console.log('max min: ', minDataX, maxDataX)
    // console.log('abscissa scale range: ', scale.range());
    // console.log('abscissa scale domain: ', scale.domain());

    return scale;
};



///////////////////////
/// other            //
///////////////////////

D3Chart.prototype._updateToolTips = function(args) {
    const that = this,  conf = this._conf, dataAttr = this._dataAttr, svg = this._svg;

    const metricType = dataAttr.metricType;
    d3.select(conf.el).select('.am-adProgressGraph-tooltip').classed('is-metricType-'+metricType, true);

    const hasDailyCap = dataAttr.hasDailyCap;
    const hasTotalTarget = dataAttr.hasTotalTarget;
    const totalTargetValue =  dataAttr.maxTotalMetric;
    const state = args.state;
    let presentation_percentageFilled = '';
    let presentation_filled = '';

    if ( args.metricType === 'spend') {
        presentation_filled = numeral(args.filled).format('0,0.00');
    } else {
        presentation_filled = numeral(args.filled).format('0,0');
    }

    if ( hasTotalTarget ){
        presentation_percentageFilled = Math.round(args.filled/totalTargetValue*100 * 100 )/100; // round to two decimal place
    } else {
        presentation_percentageFilled = '';
    }
    let presentation_ideal = '';
    let hideIdeal = 'hide';
    if ( hasTotalTarget ){
         hideIdeal = '';
         presentation_ideal = numeral(Math.round(args.ideal_pace)).format('0,0');
    }

    let hideDailyCap = 'hide';
    let presentation_dailyCap = '';
    if (hasDailyCap && (args.ideal_unPace !== 'n/a') ) {
        hideDailyCap = '';
        presentation_dailyCap = numeral(args.ideal_unPace).format('0,0');
    } else {
        presentation_dailyCap = '';
    }

    const metricType_presentation = {
        'impressions' : 'Impressions',
        'clicks' : 'Clicks',
        'spends' : 'Spends'
    };

    const presentation_metricType = metricType_presentation[args.metricType];

    let unit;
    if ( args.metricType === 'spend') {
        unit = '$';
    } else {
        unit = '';
    }

    const t = args.timeUTC;
    const presentation_date = moment(t).format('MMM DD, HH:mm:ss') + ' UTC';

    const $toolTipsContainer = $(conf.el).find('.am-adProgressGraph-tooltip');
    const html = `
    <div class="am-adProgressGraph-tooltip-inner">
        <h6>${presentation_date}</h6>
        <table>
            ${presentation_ideal ? `
                <tr>
                    <th>Ideal</th><td>${unit}${presentation_ideal}</td>
                </tr>
            `:''}
            <tr>
                <th>Filled</th><td>${unit}${presentation_filled}</td>
            </tr>
            ${presentation_ideal ? `
                <tr>
                    <th>Filled Percent</th><td>${presentation_percentageFilled}%</td>
                </tr>
            `:''}
            ${presentation_dailyCap ? `
                <tr><th>Daily Cap</th><td>${presentation_dailyCap}</td><td></td></tr>
            `:''}
        </table>
    </div>
    `;

    // const template =
    //          ' <div class="am-adProgressGraph-tooltipInner">'
    //            + ' <div class="r-date"                          ><span><span><%= presentation_date %></span>  </span></div>'
    //            + ' <div>&nbsp;</div>'
    //            + ' <div class="r-metric-type"                   ><span><span><%= presentation_metricType %></span>  </span></div>'
    //            + ' <div class="r-ideal <%= hideIdeal %>"        ><span><span>Ideal:     </span><span><%= presentation_ideal %></span>  </span></div>'
    //            + ' <div class="r-filled"                        ><span><span>Filled:    </span><span><%= presentation_filled %>&nbsp;<%= presentation_percentageFilled %></span></span></div>'
    //            + ' <div class="r-daily-cap <%= hideDailyCap %>" ><span><span>Daily Cap: </span><span><%= presentation_dailyCap %></span>                       </span></div>'

    //            + ' <div>&nbsp;</div>'
    //            + ' <div>--- raw value -----</div> '
    //            + ' <div class="r1-"><span><%= timeISO %></span></div> '
    //            + ' <div class="r1-"><span><%= metricType %></span></div> '
    //            + ' <div class="r1-"><span>delivery: </span><span><%= rawfilled %></span></div> '
    //            + ' <div class="r1-"><span>ideal pace: </span><span><%= ideal_pace %></span></div> '
    //            + ' <div class="r1-"><span>ideal unpace: </span><span><%= ideal_unPace %></span></div> '
    //            + ' <div class="r1-"><span>health: </span><span><%= health %></span></div> '
    //            + ' <div class="r1-"><span>state: </span><span><%= state %></span></div> '
    //        + ' </div> ';

    // const compiled = _.template(template);

    // const html = compiled({
    //     hideDailyCap,
    //     hideIdeal,

    //     presentation_date,
    //     presentation_percentageFilled,
    //     presentation_filled,
    //     presentation_dailyCap,
    //     presentation_ideal,
    //     presentation_metricType,

    //     // ---------
    //     'ideal_pace': args.ideal_pace,
    //     'timeISO': args.timeISO,
    //     'rawfilled': args.filled,
    //     'ideal_unPace': args.ideal_unPace,
    //     'health': args.health,
    //     'state': args.state,
    //     'metricType': args.metricType
    // });

    $toolTipsContainer.html(html);
};

D3Chart.prototype._updateToolTipPositionInClient = function(el){
    const that = this, conf = this._conf, dataAttr = this._dataAttr, svg = this._svg;

    // x,y is the coordinate of mouseline origin in svg
    const y = 0;
    const x = svg.mouseLineX;

    // Mouse line coordinate in client
    const mouseLineCoord_client = this._getClientCoordinate( {x:x, y:0} );

    // mount this coordinate on react component
    conf.el.mouseLineCoord = mouseLineCoord_client;
};


D3Chart.prototype._updateLegend = function(args) {
    const that = this,  conf = this._conf, dataAttr = this._dataAttr, svg = this._svg;
    const metricType = dataAttr.metricType;

    const metricType_presentation = {
        'impressions' : 'Impressions',
        'clicks' : 'Clicks',
        'spends' : 'Spends'
    };
    d3.select(conf.el).select('.am-adProgressGraph-legend').classed('is-metricType-'+metricType, true);

    const $legendContainer = $(conf.el).find('.am-adProgressGraph-legend');

    const template = `
        <dl class="am-adProgressGraph-linesLegend">
            ${ dataAttr.hasDailyCap ? `
                <dt><svg class="am-adProgressGraph-unpacedFillIcon" width="16px" height="16px"><line x1="0" y1="8" x2="16" y2="8"></line></svg></dt>
                <dd>Daily Cap</dd>
            ` : '' }
            ${ dataAttr.hasTotalTarget ? `
                <dt><svg class="am-adProgressGraph-pacedFillIcon" width="16px" height="16px"><line x1="0" y1="11" x2="16" y2="5"></line></svg></dt>
                <dd>Ideal Delivery</dd>
                <dt><svg class="am-adProgressGraph-totalTargetIcon" width="16px" height="16px"><rect x="4" y="4" width="8" height="8" transform="rotate(45 8 8)" /></svg></dt>
                <dd>Total Target (${metricType})</dd>
            ` : '' }
        </dl>
        <dl class="am-adProgressGraph-healthLegend">
            ${ dataAttr.hasTotalTarget ? `
                <dt><svg class="am-adProgressGraph-goodHealthIcon" width="16px" height="16px"><circle cx="8" cy="8" r="4" /></svg></dt>
                <dd>On Track</dd>
                <dt><svg class="am-adProgressGraph-okHealthIcon" width="16px" height="16px"><circle cx="8" cy="8" r="4" /></svg></dt>
                <dd>Behind</dd>
                <dt><svg class="am-adProgressGraph-badHealthIcon" width="16px" height="16px"><circle cx="8" cy="8" r="4" /></svg></dt>
                <dd>Critical</dd>
            ` : `
                <dt><svg class="am-adProgressGraph-nullHealthIcon" width="16px" height="16px"><circle cx="8" cy="8" r="4" /></svg></dt>
                <dd>Current Delivery</dd>
            ` }
        </dl>
    `;

    var compiled = _.template(template);
    const html = compiled({
        'metricType': metricType_presentation[metricType]
    });
    $legendContainer.html(html);
};



///////////////////////
/// helper           //
///////////////////////
D3Chart.prototype._getMonthName = function(dateObj, type) {
    const _type = type || 'short';
    let _monthNames;
    if (_type === 'long') {
        _monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
    }
    if (_type === 'short') {
        _monthNames = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec' ];
    }
    return _monthNames[dateObj.getMonth()];
};



///////////////////////////
//  Transformation       //
///////////////////////////
D3Chart.prototype._getClientCoordinate = function( { x= null, y = null } ) {
    const that = this, conf = this._conf, dataAttr = this._dataAttr, svg = this._svg;

    const svgNode = svg.container.node();
    const p_user = svgNode.createSVGPoint();
    p_user.x =  x;
    p_user.y =  y;

    const graphAreaBackgroundNode = svg.graphAreaBg.node();
    const m = graphAreaBackgroundNode.getScreenCTM();   // Transformation metrix;

    const p_client = p_user.matrixTransform(m);
    return { 'x': p_client.x, 'y': p_client.y};
};




// module.exports = d3Chart;
module.exports = D3Chart;
