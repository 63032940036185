'use strict';

var cancelTemplate = require('./templates/cancel-audit');
var app = require('scripts/core').app;
var Creatives = require('scripts/entities/collections/creatives');

var CancelAuditView = Mario.ItemView.extend({
    template: cancelTemplate,

    events: {
        'click [data-action=proceed]': 'cancelAudit'
    },

    cancelAudit: function () {
        var AUDIT_STATUS = this.model.collection.constructor.AUDIT_STATUS;
        var that = this;

        this.model.save('audit_status', AUDIT_STATUS.NO_AUDIT, {
            patch: true,
            wait: true,
            success: function () {
                $('#popup').one('hidden.bs.modal', that.options.yes);
                globalCh.trigger('popup:close');
            }
        });
    }
});

module.exports = CancelAuditView;
