'use strict';

var app = require('scripts/core').app;
var AccountingPage = require('./accounting');
var flux = require('scripts/core/flux');

var Controller = Mario.Controller.extend({
    accounting: function () {
        var page = globalCh.request('available-page', {main_url: '/accounting'});


        // Fetch the page data from the server before rendering
        flux.actions.accountingPage.fetchPageDataAndNavigate();

        var routeStore = flux.store('route');

        routeStore.once('change', function () {
            if ('accounting-page' === routeStore.getCurrentRoute()) {
                page.show(function () {
                    return new AccountingPage({ });
                }, function (accountingPage) {
                    accountingPage.pageDidReload();
                });
            }
        });
    }
});

var controller = new Controller();

exports.start = function () {
    debug('start accounting component');
    this.router = new Mario.AppRouter({
        appRoutes: {
            'accounting': 'accounting'
        },

        controller: controller
    });
    return {
        matcher    : /\/accounting/,
        url        : '/accounting',
        key        : 'accounting',
        add_to_menu: true,
        menu_config: {icon: 'fa-credit-card', name: 'Account'}
    };
};
