'use strict';


var PopoverButton = React.createClass({

    propTypes: {
        text: React.PropTypes.string.isRequired,
        placement: React.PropTypes.string
    },

    getInitialState () {
        const id = _.uniqueId();
        return {
            uniqueId: id,
            contentContainerId: 'popover-container-' + id
        };
    },

    getDefaultProps () {
        return { title: ' ', placement: 'left' };
    },

    componentDidMount () {
        this.createPopup(this.props);
    },

    shouldComponentUpdate (nextProps) {
        this.createPopup(nextProps);
        return false;
    },

    createPopup (props) {
        var $button = $(this.getDOMNode()).find('button');

        $button.popover('destroy');

        // Create popover
        $button.popover({
            html: true,
            title: props.title,
            container: '#' + this.state.contentContainerId,
            placement: props.placement,
            template: React.renderToString(props.children)
        });

        // Apply component events after the popover is shown
        $button.on('shown.bs.popover', () => {
            React.render(props.children, document.getElementById(this.state.contentContainerId));
        });
    },

    compnentWillUnmount () {
        $(this.getDOMNode()).find('button').popover('destroy');
    },

    render () {
        return (
            <div className="am-popoverButton">
                <button type="button" className="am-popoverButton-button" data-toggle="popover">
                    {this.props.text}
                </button>
                <div id={this.state.contentContainerId}></div>
            </div>
        );
    }
});


module.exports = PopoverButton;