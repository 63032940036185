'use strict';

var ProgressCalculator = require('scripts/common/progress-calculator');

var intialState = {
    'description': 'Cumulative Fill',
    'ads': [],
    'now': {
        'remainingPlusTodayToFlightRatio': 0,
        'todayToRemainingPlusTodayRatio': 0,
        'elapsedToFlightRatio': 0,
    },
    'flight': {
        'start': null,
        'end': null
    }
};

function campaignProgressGraphReducer (state = intialState, resources, session, campaignId, adsHealth) {
    const campaign = objectPath.get(resources, `campaigns.entities.${campaignId}.attr`);
    const adsResource = objectPath.get(resources, `campaigns.entities.${campaignId}.ads`, {});

    if (!campaign) {
        return state;
    }

    const campaignStart = moment.utc(campaign.start);

    const flightDays = moment.utc(campaign.end).diff(campaignStart, 'hours');

    var scale = d3.scale.linear()
        .domain([0, flightDays])
        .range([0, 100]);


    const ads = _(adsResource.entities).map(ad => {

        const leftOffset = scale(moment.utc(ad.attr.start).diff(campaignStart, 'hours'));
        const width = scale(moment.utc(ad.attr.end).diff(moment.utc(ad.attr.start), 'hours'));

        const health = objectPath.get(adsHealth, `${ad.attr.id}.health`, null);

        return {
            name: ad.attr.name,
            id: ad.attr.id,
            start: ad.attr.start,
            end: ad.attr.end,
            leftOffsetInPercentage: leftOffset,
            widthInPercentage: width,
            status: ad.attr.status,
            health: health
        };
    })
    .value();

    const now = new Date();
    const utcToday = {
        start: ProgressCalculator.getUtcStartOfDay(now),
        end: ProgressCalculator.getUtcEndOfDay(now),
    };
    const campaignFlight = {
        start: new Date(campaign.start),
        end: new Date(campaign.end),
    };
    const todayActive = ProgressCalculator.getIntersection(utcToday, campaignFlight);

    const secondsInToday = todayActive ? Math.max(0, ProgressCalculator.getNumberOfSecondsInRange(todayActive)) : 0;
    const secondsInCampaign = ProgressCalculator.getNumberOfSecondsInRange(campaignFlight);

    const secondsRemainingIncludingToday = todayActive ? Math.max(0, ProgressCalculator.getNumberOfSecondsInRange({
        start: todayActive.start,
        end: campaignFlight.end,
    })) : 0;

    const remainingPlusTodayToFlightRatio = Math.min(100, secondsRemainingIncludingToday / secondsInCampaign * 100);
    const todayToRemainingPlusTodayRatio = Math.min(100, secondsInToday / secondsRemainingIncludingToday * 100);

    const secondsElapsed = Math.max(0, ProgressCalculator.getNumberOfSecondsInRange({
        start: campaignFlight.start,
        end: now,
    }));
    const elapsedToFlightRatio = Math.min(100, secondsElapsed / secondsInCampaign * 100);

    return {
        ...state,
        now: {
            remainingPlusTodayToFlightRatio,
            todayToRemainingPlusTodayRatio,
            elapsedToFlightRatio,
        },
        ads: ads,
        flight: {
            start: moment.utc(campaign.start).format('MMM DD HH:mm:ss') + ' UTC',
            end: moment.utc(campaign.end).format('MMM DD HH:mm:ss') + ' UTC'
        }
    };
}


module.exports = campaignProgressGraphReducer;