/**
 * @typedef {Object} History
 * @property {Meta[]} metas - For each change, shared by all attributes
 * @property {AttrChange[]} attrs - Changes for each attr
 */

/**
 * @typedef {Meta} Meta
 * @property {ISOString} time - Time of the cahnge
 * @property {string} author
 * @property {string} comment
 */

/**
 * All fields should be ready for display
 *
 * @typedef {Object} AttrChange
 * @property {string} name
 *
 * Length should match that of metas; isChanged should be taken from patch
 * @property {{from :string|string[], to:string|string[], isChanged: boolean}[]} changes
 *
 */
'use strict';

var PrivateChannel = require('scripts/common/mixins/private-channel');


var TableColHeadCell = React.createClass({
    mixins: [PrivateChannel],

    getInitialState() {
        return {
            colIsCollapsed: false,
            displayState : 'expanded'
        };
    },

    componentWillMount() {
        this.chCol   = this.props.meta.chCol;
        this.chTable = this.props.meta.chTable;
    },

    componentDidMount() {
        this.privateCh.listenTo( this.chTable, 'table:expandAllCol', this.colToExpand );
        this.privateCh.listenTo( this.chCol, 'cellToColHead:colToCollapse', this.colToCollapse );
        this.privateCh.listenTo( this.chCol, 'cellToColHead:colToExpand', this.colToExpand );
    },

    onClickedHeader() {
        this.tableModeColumn();
        if (this.state.colIsCollapsed) {
            this.colToExpand();
        } else {
            this.colToCollapse();
        }
    },

    tableModeColumn() {
        // column head has been clicked, table is in column mode
        this.chTable.trigger('table:mode:column');
    },

    colToCollapse() {
        this.chCol.trigger('col:collapse');
        this.setState({
            colIsCollapsed: true,
            displayState: 'collapsed'
        })
    },

    colToExpand() {
        this.chCol.trigger('col:expand');
        this.setState({
            colIsCollapsed: false,
            displayState: 'expanded'
        })
    },

    render() {
        var {author, chCol, time } = this.props.meta;
        switch(this.state.displayState){
            case 'expanded':
                return (
                    <th key={_.uniqueId()} onClick={this.onClickedHeader} className={ this.state.colIsCollapsed ? 'collapsed' : '' }>
                        <div>
                            <div className="meta-wrap">
                                <div><strong>{moment.utc(time).format('MMM D, YYYY')}</strong></div>
                                <div>{moment.utc(time).format('HH:mm:ss')}</div>
                                <div><strong>by {author}</strong></div>
                            </div>
                            <div className="button-wrap"><button className="btn btn-default btn-xs" >&gt;&nbsp;&lt;</button></div>
                        </div>
                    </th>
                );
                break;
            case 'collapsed':
                return (
                    <th key={_.uniqueId()} onClick={this.onClickedHeader} className={ this.state.colIsCollapsed ? 'collapsed' : '' }>
                        <div>
                            <div className="meta-wrap"> </div>
                            <div className="button-wrap"><button className="btn btn-default btn-xs" >&lt;&gt;</button></div>
                        </div>
                    </th>
                );
                break;
            default:
                break;
        }
    }
});

var TableRowHeadCell = React.createClass({
    mixins: [PrivateChannel],
    getInitialState() { return { rowIsFiltered: false }; },

    componentWillMount() {
        this._memo = {}; // local memory
        _.extend( this._memo, {rowHeaderId: _.uniqueId('rowHeader') } ); // this hold the id for this row
        this.chRow   = this.props.attr.chRow;
        this.chTable = this.props.attr.chTable;
    },

    componentDidMount() {
        this.privateCh.listenTo(this.chTable, 'table:mode:column'        ,  this.tableModeColumn );
        this.privateCh.listenTo(this.chTable, 'table:onClicked:rowHeader',  this.onClickedSomeRowHeader );
    },

    onClickedRowHeader() {
        // When this row header is clicked broacast this row id,
        this.chTable.trigger( 'table:onClicked:rowHeader', this._memo.rowHeaderId );
    },

    onClickedSomeRowHeader( _clickedRowId ){
        // When there is a row header in table get clicked this method is called.
        var _thisRowId =  this._memo.rowHeaderId;

        if ( _clickedRowId === _thisRowId ) {
            // this row head is clicked, now we have to toggle filter
            if (this.state.rowIsFiltered) {
                this.turnRowFilterOff();
                this.chTable.trigger('table:mode:initial');
            } else {
                // dismiss filter state first
                this.chTable.trigger('table:mode:filter');
                // then set it
                this.turnRowFilterOn();
            }
        } else {
            // other row clicked, this row is no long in filter
            this.setState({ rowIsFiltered: false });
        }
    },

    turnRowFilterOn() {
        // tell all the body cell in this row that filter is on.
        this.chRow.trigger('row:filterIsOn');
        this.setState({ rowIsFiltered: true });
    },
    turnRowFilterOff() {
        // tell all the body in the row that filter is off.
        this.chRow.trigger('row:filterIsOff');
        this.setState({ rowIsFiltered: false });
    },
    tableModeColumn(){
        // This method is called because table is in column mode
        this.setState({ rowIsFiltered: false });
    },

    render() {
        var name = this.props.attr.name;
        return (
            <th key={this._memo.rowHeaderId}
                onClick={this.onClickedRowHeader}
                className={ this.state.rowIsFiltered ? 'filtered' : '' }>
                {name}
            </th>
        );
    }
});



var ButtonToCopyCode = React.createClass({
    mixins: [PrivateChannel],

    componentWillMount() {
        this._hasCopyButton = false;
        var to = this.props.to;
        var rowAttr = this.props.rowAttr;
        var rowDisplayName = '';

        if ( rowAttr != null ) {
            rowDisplayName = rowAttr.name;
        }

        var _hasCopyButton = function( contentToCopy ) {
            return ( contentToCopy === '' ) ? false : true;
        };

        switch (rowDisplayName) {
            case 'Third Party Pixels':
                if ( _.isArray(to)  ) {
                    this._contentToCopy = _.reduce( to, function(acc, item){
                        if ( React.isValidElement(item) ) {
                            var content = React.renderToString(item);
                            if (typeof acc === 'string') {
                                return acc.concat( $(content).html() + '\r\n' );
                            } else {
                                return acc;
                            }
                        }
                    }, '', this);
                    this._hasCopyButton = _hasCopyButton(this._contentToCopy);
                } else {
                    throw Error('Error parsing Third Party Pixels for copy button in history widget');
                }
                break;
            default:
                if (React.isValidElement(to)) {
                    if (to.type === 'pre') {
                        // is Content HTML
                        this._contentToCopy = this.decodeEntities( React.renderToString(to) );
                        this._hasCopyButton = _hasCopyButton(this._contentToCopy);
                    }
                    if (to.type === 'function') {
                        this._contentToCopy = '';
                        this._hasCopyButton = false;
                    }
                } else {
                    // everything else
                    this._contentToCopy = to;
                    this._hasCopyButton = _hasCopyButton(this._contentToCopy);
                } // End: if

        }// End switch

    },

    componentDidMount() {
        // mount zero clipboard
        var $el = $(this.getDOMNode());
        var zc = new ZeroClipboard( $el );
        zc.on( "ready", function( readyEvent ) {
            zc.on( "aftercopy", function( event ) {
                // uncomment next line for debugging
                // alert("Copied text to clipboard: " + event.data["text/plain"] );
                window.toastr.info('Copied to clipboard!', null, { 'positionClass': 'toast-top-right' });
            } );
        } );
    },

    render() {
        if (this._hasCopyButton) {
            return (
                <button
                    data-action="copy-button"
                    data-clipboard-text={this._contentToCopy}
                    title="Click to copy content"
                >Copy</button>
            );
        } else {
            return ( <button style={{display:'none'}}/> );
        }
    },

    decodeEntities : (function() {
        // ref:  http://stackoverflow.com/questions/5796718/html-entity-decode

        // this prevents any overhead from creating the object each time
        var element = document.createElement('div');
        function decodeHTMLEntities (str) {
            if(str && typeof str === 'string') {
                // strip script/html tags
                str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
                str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
                element.innerHTML = str;
                str = element.textContent;
                element.textContent = '';
            }
            return str;
        }
        return decodeHTMLEntities;
    })(),
});

var TableBodyCell = React.createClass({
    mixins: [PrivateChannel],

    getInitialState() {
        return {
            displayState : 'expanded',
            isCellOfFilteredRow: false,
        };
    },

    componentWillMount() {
        this.isChanged = this.props.change.isChanged;
        this.chTable   = this.props.change.chTable;
        this.chCol     = this.props.change.chCol;
        this.chRow     = this.props.change.chRow;
    },

    componentDidMount() {
        var that = this;

        this.privateCh.listenTo( this.chCol, 'col:collapse'    , this.cellToCollapse );
        this.privateCh.listenTo( this.chCol, 'col:expand'      , this.cellToExpand  );
        this.privateCh.listenTo( this.chRow, 'row:filterIsOn'  , this.filterIsOn  );
        this.privateCh.listenTo( this.chRow, 'row:filterIsOff' , this.filterIsOff );

        this.shortenCodeBlock();
    },

    shortenCodeBlock() {
        var $el = $(this.getDOMNode());
        $el.find('code').dotdotdot({height: 200});
    },

    componentDidUpdate() {
        this.shortenCodeBlock();
    },

    filterIsOn() {
        this.setState({isCellOfFilteredRow: true});
        if (this.isChanged) {
            this.chCol.trigger('cellToColHead:colToExpand');
        } else {
            this.chCol.trigger('cellToColHead:colToCollapse');
        }
    },

    filterIsOff() {
        this.setState({isCellOfFilteredRow: false});
        this.chCol.trigger('cellToColHead:colToExpand');
    },

    cellToCollapse() {
        this.setState({ displayState: 'collapsed' });
    },

    cellToExpand() {
        this.setState({ displayState: 'expanded' });
    },

    render() {
        var from = this.props.change.from;
        var to   = this.props.change.to;
        var rowAttr = this.props.rowAttr

        // var title = React.isValidElement(to) ? React.renderToString(to): to;
        // problematic for react component
        var title = React.isValidElement(to) ? '' : to;

        switch(this.state.displayState){
            case 'expanded':
                if (this.isChanged) {
                    return (
                        <td key={_.uniqueId()} className="changed" title={title}>
                            <ButtonToCopyCode to={to} rowAttr={rowAttr} />
                            {to}
                         </td>
                    );
                } else {
                    return (
                        <td key={_.uniqueId()} title={title} >
                            <ButtonToCopyCode to={to} rowAttr={rowAttr} />
                            {to}
                        </td>
                    );
                }
                break;
            case 'collapsed':
                if (this.isChanged) {
                    return ( <td key={_.uniqueId()} className="changed icon"><i className="fa fa-fw fa-pencil"></i></td>);
                } else {
                    return (<td key={_.uniqueId()}>&nbsp;</td>);
                }
                break;
            default:
                break;
        }
    }
});

var TableFootCell = React.createClass({
    mixins: [PrivateChannel],

    getInitialState() {
        return {
            displayState : 'expanded',
            isFilteredRow: false
        };
    },

    componentWillMount() {
        this.chCol   = this.props.meta.chCol;
    },

    componentDidMount() {
        this.privateCh.listenTo( this.chCol, 'col:collapse'    , this.cellToCollapse );
        this.privateCh.listenTo( this.chCol, 'col:expand'      , this.cellToExpand  );

    },

    cellToCollapse() {
        this.setState({ displayState: 'collapsed' });
    },

    cellToExpand() {
        this.setState({ displayState: 'expanded' });
    },

    render() {
        var {comment} = this.props.meta;

        switch(this.state.displayState){
            case 'expanded':
                return (
                    <td key={_.uniqueId()}>
                        {comment}
                    </td>
                );
                break;
            case 'collapsed':
                return (
                    <td key={_.uniqueId()}>
                        &nbsp;
                    </td>
                );
                break;
            default:
                break;
        }
    }
});

var TableHeadRow = React.createClass({
    render() {
        var metas = this.props.metas;
        return (
            <tr key={_.uniqueId()}>
                <th key={_.uniqueId()}></th>
                { _.map( metas, (meta)=> <TableColHeadCell meta={meta} key={_.uniqueId()}/>)}
            </tr>
        );
    }
});

var TableBodyRow = React.createClass({
    mixins: [PrivateChannel],

    componentDidMount() {
        var chRow = this.props.attr.chRow;
        var chTable = this.props.attr.chTable;
        this.privateCh.listenTo(chTable, 'table:mode:filter', this.onUnSelected);
        this.privateCh.listenTo(chRow, 'row:filterIsOn', this.onSelected);
    },

    onUnSelected() {
        var $el = $(this.getDOMNode());
        $el.removeClass('selected');
    },

    onSelected() {
        var $el = $(this.getDOMNode());
        $el.addClass('selected');
    },

    render() {
        var {name, changes} = this.props.attr;
        return (
            <tr key={_.uniqueId()}>
                <TableRowHeadCell attr={this.props.attr}/>
                {_.map( changes, (change)=><TableBodyCell change={change} rowAttr={this.props.attr} key={_.uniqueId()}/>)}
            </tr>
        );
    }
});

var TableFootRow = React.createClass({
    render() {
        var metas = this.props.metas;
        return (
            <tr key={_.uniqueId()}>
                <th key={_.uniqueId()}>Comment</th>
                {_.map( metas, (meta)=><TableFootCell meta={meta} key={_.uniqueId()}/>)}
            </tr>
        );
    }
});

var History = React.createClass({
    mixins: [PrivateChannel],

    componentWillMount() {
        var that = this;
        var {metas, attrs} = this.props.history;

        // Arrays that hold radio channels.
        this.rowChs = [];
        this.colChs = [];

        // Set up radio channels for table.
        var tid = _.uniqueId('history-table');
        this.tableCh = Backbone.Radio.channel(tid);

        // Set up radio channels for table head.
        metas.forEach(function(meta){
            var colCh = Backbone.Radio.channel(_.uniqueId('history-table-col'));
            that.colChs.push(colCh);
            _.extend( meta, {chCol: colCh}, {chTable: that.tableCh});
        });

        // Set up radio channels for each cell.
        attrs.forEach(function(attr){
            var rowCh = Backbone.Radio.channel(_.uniqueId('history-table-row'));
            that.rowChs.push( rowCh );
            _.extend( attr, {chRow: rowCh}, {chTable: that.tableCh} );

            var colLength =  attr.changes.length;
            for (var col=0; col < colLength; col++) {
                _.extend( attr.changes[col], {chCol: that.colChs[col]}, {chRow: rowCh}, {chTable: that.tableCh} );
            }
        });
    },

    componentWillUnmount() {
        // Tear down radio channels.
        this.tableCh.reset();
        this.rowChs.forEach(function(item){ item.reset() });
        this.colChs.forEach(function(item){ item.reset() });
    },

    render() {
        var that = this;
        var {metas, attrs} = this.props.history;
        var tableWidth = {width: 'auto'};
        if (_.isEmpty(metas)) { return null; }
        return (
            <div className="ef-history">
                <table className="table table-bordered" style={tableWidth}>
                    <thead>
                        <TableHeadRow metas={metas} key={_.uniqueId()}/>
                    </thead>
                    <tbody>
                        { _.map( attrs, (attr)=> <TableBodyRow attr={attr} key={_.uniqueId()}/>)}
                    </tbody>
                    <tfoot>
                        <TableFootRow  metas={metas} key={_.uniqueId()} />
                    </tfoot>
                </table>
            </div>
        );
    }
});

module.exports = History;
