'use strict';

// backbone view helper functions
module.exports = {
    /**
     * for backbone form views with stickit enabled
     * to get observed fields values
     *
     * this use the getVal method defined in the binding to get the value
     * this function will not set any attribute in the model
     * it only returns the changed (different from original model) attributes to the caller
     * this is a better way of handling model form, as it will never set invalid values
     *
     * this:-> backbone view object
     */

    stickitGetValues: function (attrNames, options) {
        options = options || {};

        var changes = {};
        _.each(this.bindings, function (v, selector) {
            var namespace = '.stickit.' + this.model.cid;
            var $el = this.$(selector);
            var binding = this.bindings[selector];
            if (_.isString(binding)) {
                binding = {observe: binding};
            }
            var config = Backbone.Stickit.getConfiguration($el, binding);
            var val = config.getVal.call(this, $el, 'manual', config);

            //config.observe may be an array

            if (_.every([config.observe, val], _.isArray) && val.length === config.observe.length) {

                if (_.isEmpty(_.difference(config.observe, attrNames))) {

                    _.each(config.observe, function (attrName, idx) {
                        if (!options.patch || !_.isEqual(this.model.get(attrName), val[idx])) {
                            changes[attrName] = val[idx];
                        }
                    }, this);
                }
            } else if (_.contains(attrNames, config.observe)) {

                if (!options.patch || !_.isEqual(this.model.get(config.observe), val)) {
                    changes[config.observe] = val;
                }
            }

        }, this);

        return changes;
    }
};
