<td>{{resource}}</td>

{{#each perms}}
  <td class="
      {{#if disabled}}
        disabled
      {{/if}}
  ">
  {{#if ../can_update_roles}}
    <input type="checkbox" data-permission="{{name}}" class="input-sm"
      {{#if disabled}}
        disabled
      {{/if}}

      {{#if checked}}
        checked
      {{/if}}
    />

  {{else}}

    {{#if checked}}
      <i class="fa fa-check-square-o"></i>
    {{else}}
      <i class="fa fa-square-o"></i>
    {{/if}}


  {{/if}}
  </td>
{{/each}}
