'use strict';


/**
 * make sure the collection has `refresh` method which emit `refresh:start` and refresh:done` events
 *
 * make sure the list view has the refresh button installed
 *
 * @type {{_init: Function}}
 */

var Refresh = Mario.Behavior.extend({

    events: {
        'click .toolbar-refresh' : function () {
            this.trigger('refresh');
            return false;
        }
    },

    getRefreshable: function () {
        if (  this.view.model &&  this.view.model.ads ) {
            this.view.model.ads.refresh();
        }
        return this.view.refreshable || this.view.collection;
    },

    onShow: function () {

        this.clearRefreshTimer();
        this.off();
        this.stopListening();

        this.view.$('.toolbar-refresh i').removeClass('fa-spin');
        this.view.$('.toolbar-refresh small').text('Updated ' + moment(Date.now()).fromNow());

        this.on('refresh:stoptimer destroy', this.clearRefreshTimer, this);

        this.listenTo(this.view, 'refresh', this.refresh, this);
        this.listenTo(this.view, 'refresh:reset', this.onShow, this);

        this.on('refresh', this.refresh, this);

        if (!this.options.noRefreshOnStart) {
            this.trigger('refresh');
        }
    },

    refresh: function () {
        var refreshable = this.getRefreshable();
        if (refreshable) {
            this.stopListening(refreshable);
            this.listenToOnce(refreshable, 'refresh:start', this.showRefreshProgress);
            refreshable.refresh({reset: false});
        }
    },

    onManualStart: function () {

        this.showRefreshProgress();
    },

    showRefreshProgress: function () {
        this.trigger('refresh:stoptimer');

        this.view.$('.toolbar-refresh i').addClass('fa-spin');
        this.view.$('.toolbar-refresh small').text('Updating now...');

        var refreshable = this.getRefreshable();
        if (refreshable != null) {
            this.stopListening(refreshable);
            this.listenToOnce(refreshable, 'refresh:done', this.stopRefreshProgress);
        }
    },

    stopRefreshProgress : function () {
        var that = this;
        var from = Date.now();

        // stop spin first
        this.view.$('.toolbar-refresh i').removeClass('fa-spin');

        // add refresh timer to show freshness
        this.clearRefreshTimer();
        this._refreshTimer = setInterval(function () {
            that.view.$('.toolbar-refresh small').text('Updated ' + moment(from).fromNow());
        }, 1000);

    },

    clearRefreshTimer : function () {
        if (this._refreshTimer) {
            clearInterval(this._refreshTimer);
            this._refreshTimer = null;
        }
    }

});


window.Behaviors.Refresh = Refresh;
