'use strict';
var flux = require('scripts/core/flux');
var http = require('scripts/core/http');

flux.addActions({

    ads: {

        pause: function () {

        }
    }
});