'use strict';

var creativesTemplate = require('./templates/creatives');
var myCreativesTemplate = require('./templates/my-creatives');
var Form = require('scripts/common/mixins/form');
var Organization = require('scripts/entities/collections/organizations').Organization;

var CreativeView = Mario.ItemView.extend({
    // className: 'creative-setup col-md-12',
    className: 'am-admin-creative',

    template: require('./templates/creative'),

    events: {
        'focus input[type=text]' : 'selectNumber',
        'click [data-action=save]' : 'save',
        'keyup input': 'showSaveButtons',
        'change select': 'showSaveButtons',
        //'click [data-action=cancel]' : 'reRender',   // previous behaviour
        'click [data-action=cancel]' : 'editCreative_cancel',   // previous behaviour
        // 'click [data-action=show-rates]': 'showRates',
        // 'click [data-action=hide-rates]': 'hideRates',
        'click [data-action=editCreative]': 'editCreative'
    },

    triggers: {
        // 'click [data-action=cancel]' : 'cencel:edit',
        'click [data-action=show-rates]': 'show:rates',
        'click [data-action=hide-rates]': 'hide:rates'
    },

    editCreative: function(e) {
        e.preventDefault();

        const $target = $(e.target);
        const $creativeTile = $target.closest('.am-admin-creative--tile');

        $creativeTile.find('.rates input').prop('disabled', false); // enable form
        $creativeTile.find('.edit-creative').addClass('hide');      // hide edit button

        this.$('input[type=text].monetary').autoNumeric('init', {vMin: '-1', wEmpty: 0});
        this.$('[data-id=rate]').autoNumeric();
        this.$('[data-id=default_max_bid_cpm]').autoNumeric();

        this.showSaveButtons();
    },

    editCreative_cancel: function(e) {
        e.preventDefault();

        const $target = $(e.target);
        const $creativeTile = $target.closest('.am-admin-creative--tile');

        $creativeTile.find('.rates input').prop('disabled', true);     // disable form
        $creativeTile.find('.edit-creative').removeClass('hide');      // unhide edit button

        this.hideSaveButtons();
    },

    selectNumber: function (ev) {
        ev.target.setSelectionRange(0, ev.target.value.length);
    },

    showRates: function () {
        this.$('.rates').removeClass('hide');
        this.$('.footer-control-show').addClass('hide');
        this.$('.footer-control-hide').removeClass('hide');
    },

    hideRates: function () {
        this.$('.rates').addClass('hide');
        this.$('.footer-control-show').removeClass('hide');
        this.$('.footer-control-hide').addClass('hide');
    },

    save: function () {
        this.model.trigger('save');
        this.$('.edit').addClass('hide');  // hide save and cancel button
        this.$('.rates input').prop('disabled', true);
        this.$('.edit-creative').removeClass('hide');
    },

    reRender: function () {
        this.render();
        this.onShow();
    },

    showSaveButtons: function () {
        this.$('.edit').removeClass('hide');
    },

    hideSaveButtons: function () {
        this.$('.edit').addClass('hide');
    },

    onShow: function () {
        this.$('input[type=text].monetary').autoNumeric('init', {vMin: '-1', wEmpty: 0});
        this.$('[data-id=rate]').autoNumeric();
        this.$('[data-id=default_max_bid_cpm]').autoNumeric();

        if (this.options.isNew) {
            this.$('.rates input').prop('disabled', false);
            this.$('.rates').removeClass('hide');
            this.$('.footer-control-show').addClass('hide');
            this.$('.footer-control-hide').removeClass('hide');
        }

    },

    getValues: function () {
        const that = this;

        const margins = this.$('[data-id=rate]').map(function () {
            return {size: $(this).data('size'), rate: parseFloat($(this).autoNumeric('get'))};
            }).get();
        const default_max_bid_cpm = this.$('[data-id=default_max_bid_cpm]').map(function () {
            return {size: $(this).data('size'), default_max_bid_cpm: parseFloat($(this).autoNumeric('get'))};
            }).get();

        let rates = [];
        const sizes = _.pluck(margins, 'size');
        sizes.forEach((_size) => {
            const _rate = _.findWhere(margins, {'size':_size}).rate;
            const _default_max_bid_cpm = _.findWhere(default_max_bid_cpm, {'size':_size}).default_max_bid_cpm;
            rates.push( { size: _size, default_max_bid_cpm: _default_max_bid_cpm, rate: _rate});
        });

        const type = 'fixed';
        const allowCreate = this.$('[data-id=allow_create] [type=radio]:checked').val() === 'Yes';
        const autoApprove = this.$('[data-id=auto_approve] [type=radio]:checked').val() === 'Yes';

        return {
            format: this.model.get('format'),
            margin_type : type,
            rates: rates,
            margin_rate: 0, // dummy to prevent server error
            allow_create: allowCreate,
            auto_approve: autoApprove
        };
    },

    templateHelpers: function () {
        var myOrganization = globalCh.request('my-organization');

        return {
            can_update: this.options.isChild && myOrganization.can('edit'),
            is_new: this.options.isNew
        };
    }
});

var CreativesView = Mario.CollectionView.extend({
    className: 'form-horizontal',
    childView: CreativeView,
    childViewOptions: function () {
        return {
            isNew: this.options.isNew,
            isChild: this.options.isChild
        };
    },
    // onChildviewCencelEdit: function() {
    //     this.children.invoke('reRender')
    // },
    onChildviewShowRates: function(opt) {
        this.children.invoke('showRates');
    },
    onChildviewHideRates: function(opt) {
        this.children.invoke('hideRates');
    },
    getValues: function () {
        var raw = this.children.invoke('getValues');
        return _.reduce(raw, function (obj, creative) {
            obj[creative.format] = _.omit(creative, 'format');
            return obj;
        }, {});
    }
});


/**
 * options
 * model<Organization>
 */

var CreativesLayout = Mario.Layout.extend({
    className: 'creatives-setting',

    initialize: function () {
        this._isMyOrganization = this.model.isSelf();
        this.setupCreatives();
    },

    getTemplate: function () {

        if (this._isMyOrganization) {
            return myCreativesTemplate;
        } else {
            return creativesTemplate;
        }
    },

    regions : {
        creativesDisplayRegion: '.creatives-display-region'
    },

    events: {
        'click .edit .btn-success'     : 'save',
        'change [data-id=allow_create]': 'save',
        'change [data-id=auto_approve]': 'save'
    },

    save: function (ev) {
        var creatives = this.getValues();

        var success = function () {
            toastr.success('Creative is saved.');
        };

        this.model.saveCreatives(creatives, success);
    },

    templateHelpers: function () {
        return {
            creatives: this.collection.toJSON()
        };
    },

    setupCreatives: function () {
        var myOrganization = globalCh.request('my-organization');
        var rawCreatives = this.model.get('creatives');
        var myCreatives = myOrganization.get('creatives');
        var creatives;

        if (this.model.isNew()) {
            creatives = _.reduce(myCreatives, function (arr, creative, format) {
                if (creative.allow_create)  {
                    arr.push(_.extend(creative, {format: format, rates: this.model.defaults.creatives[format].rates}));
                }
                return arr;
            }, [], this);
        } else {
            // existing org
            creatives = _.reduce(rawCreatives, function (arr, creative, format) {
                if (this.model.isSelf() && creative.allow_create) {
                    arr.push(_.extend(creative, {format: format}));
                } else if ((this.model.isChild() || this.model.isDescendant()) && myOrganization.hasFormatEnabled(format)) {
                    arr.push(_.extend(creative, {format: format}));
                }

                return arr;
            }, [], this);
        }

        this.collection = new Backbone.Collection(creatives);
    },

    onShow: function () {
        if (!this.model.isChild()) {
            this.undelegateEvents();
        }

        if (!this._isMyOrganization) {
            this.creativesView = new CreativesView({
                collection: this.collection,
                isNew: this.model.isNew(),
                isChild: this.model.isChild()
            });

            this.creativesDisplayRegion.show(this.creativesView);
        }
    },

    getValues: function () {
        var Organization = require('scripts/entities/collections/organizations').Organization;
        var values = this.creativesView.getValues();
        return _.defaults(values, Organization.prototype.defaults.creatives);
    }
});

module.exports = CreativesLayout;
