'use strict';

var Roles = require('./roles');

var OrgRole = Roles.Role.extend({
    initialize: function (attrs, options) {
        _.extend(this, _.pick(options, 'urlRoot'));
    }

});

var OrganizationRoles = Roles.extend({
    model: OrgRole,
    initialize : function (models, options) {
        this.url = 'organizations/' + options.collectionId + '/roles';
    }
});

module.exports = OrganizationRoles;