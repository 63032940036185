'use strict';

/**
* Override libary/system functions here
*/
// validation error messages
_.extend(Backbone.Validation.callbacks, {
    // _getControlID is a wrapper to get the selector of special control
    // for normal control, it will find by [data-id=attr]
    // after gotten the control, it finds the enclosing form group
    // and trigger the error on the form group element
    _getControlID: function (attr) {
        // Note: it's not very good to put application specific logic here
        if (attr.match('geotargets')) {
            return '.geotargets';
        }
        return '[data-id=' + attr + ']';
    },

    valid: function (view, attr, selector) {
        // console.log('Backbone.Validation:valid');
        var $el = view.$(this._getControlID(attr)),
        $group = $el.closest('.form-group');
        $group.removeClass('has-error');
        $group.find('.help-block').html('');
    },

    invalid: function (view, attr, error, selector) {
        // console.log('Backbone.Validation:invalid', arguments);
        var $el = view.$(this._getControlID(attr)),
        $group = $el.closest('.form-group');
        $group.addClass('has-error');
        $group.find('.help-block').html(error);

        if (typeof uTracking !== 'undefined') {
            uTracking.call(null, 'send', 'validation', attr + ':' + error);
        }
    }
});

// in page link handling
// hijack all in page anchor links, unless [data-bypass] is present
$(window.document).on('click', 'a[href]:not([data-bypass])', function(e) {
    if (!e.metaKey && !e.ctrlKey && !e.shiftKey) {

        // always prevent default

        e.preventDefault();
        var protocol = this.protocol + '//';
        var href = this.href;
        href = href.slice(protocol.length);
        href = href.slice(href.indexOf('/') + 1);

        if (isInternalLink.call(this)) {
            e.stopPropagation();
            Backbone.history.navigate(href, {trigger: true});
        }
    }
});

function isInternalLink() {

    // it may be a dummy anchor to trigger some event
    if ($(this).data('action') || _.isEmpty($(this).attr('href'))) {
        return false;
    }

    return true;
}

// override toastr to make it throttle

toastr.options = {
    'closeButton': false,
    'debug': false,
    'positionClass': 'toast-top-left',
    'onclick': null,
    'showDuration': '300',
    'hideDuration': '1000',
    'timeOut': '5000',
    'extendedTimeOut': '1000',
    'showEasing': 'swing',
    'hideEasing': 'linear',
    'showMethod': 'slideDown',
    'hideMethod': 'fadeOut'
};

toastr.success = _.throttle(toastr.success, 1000, {trailing: false});
toastr.info    = _.throttle(toastr.info , 1000, {trailing: false});
toastr.error   = _.throttle(toastr.error, 1000, {trailing: false});
toastr.warning = _.throttle(toastr.warning, 1000, {trailing: false});


$(window).on('show.bs.modal', function () {
    $('body').addClass('has-modal');
});

$(window).on('hidden.bs.modal', function () {
    $('body').removeClass('has-modal');
});

// http://stackoverflow.com/questions/5802467/prevent-scrolling-of-parent-element
// set element class to self-scroll to use this

$(document).on('DOMMouseScroll mousewheel', '.self-scroll', function(ev) {
    var $this = $(this),
        scrollTop = this.scrollTop,
        scrollHeight = this.scrollHeight,
        height = $this.height(),
        delta = (ev.type === 'DOMMouseScroll' ?
            ev.originalEvent.detail * -40 :
            ev.originalEvent.wheelDelta),
        up = delta > 0;

    var prevent = function() {
        ev.stopPropagation();
        ev.preventDefault();
        ev.returnValue = false;
        return false;
    };

    if (!up && -delta > scrollHeight - height - scrollTop) {
        // Scrolling down, but this will take us past the bottom.
        $this.scrollTop(scrollHeight);
        return prevent();
    } else if (up && delta > scrollTop) {
        // Scrolling up, but this will take us past the top.
        $this.scrollTop(0);
        return prevent();
    }
});


// turn on long stack traces
Q.longStackSupport = true;
Q.stopUnhandledRejectionTracking();
