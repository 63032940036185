'use strict';


var Form = Mario.Behavior.extend({

    defaults: {
        enterToSubmit: false,
        autoAddCancelButton: false,
        autoInitNumberFields: true,
        autoInitCurrencyFields: true,
        autoInitResizableFields: true
    },

    ui: {
        'saveBtn': '[data-action=save]:eq(0)',
        'cancelBtn': '[data-action=cancel]:eq(0)',
        'lastTextField': 'input[type=text]:last'
    },

    triggers: {
        // trigger onSave method on view
        'click @ui.saveBtn': {
            event: 'save',
            preventDefault: false,
            stopPropagation: false
        },

        // trigger onCancel method on view
        'click @ui.cancelBtn': 'cancel'
        
    },
    
    listenToEnterSubmit: function () {
        var that = this;
        this.ui.lastTextField.keyup(function (ev) {
            var code = ev.keyCode || ev.which;
            // ENTER
            if (code === 13) {
                that.view.triggerMethod('Save');
            }
            
            return false;
        });
    },

    onBeforeShow: function () {
        if (this.options.autoAddCancelButton === true) {
            this.addDefaultCancelButton();
        }

        if (this.options.autoInitNumberFields === true) {
            this.initNumberFields();
        }

        if (this.options.autoInitCurrencyFields === true) {
            this.initCurrencyFields();
        }
        
        if (this.options.enterToSubmit === true) {
            this.listenToEnterSubmit();    
        }
    },

    onShow: function () {

        if (this.options.autoInitResizableFields === true) {
            this.initAutoResizableFields();
        }

        this.$('[autofocus]:first').focus();
    },

    addDefaultCancelButton: function () {
        var cancelBtn = '<button type="button" class="btn btn-link" data-action="cancel">Cancel</button>';
        this.view.$('[data-action=save]').after(cancelBtn);
    },

    initNumberFields: function () {
        this.view.$('input[type=text].number').autoNumeric('init', {vMin: 0, vMax: 1e14});
    },

    initCurrencyFields: function () {
        this.view.$('input[type=text].monetary').autoNumeric('init', {vMin: '-1', wEmpty: 0});
    },

    initAutoResizableFields: function () {
        this.view.$('textarea').css({'min-height': '50px'}).autosize();
    },

    onSave: function () {
    },

    onCancel: function () {
    },
    
    onDestroy: function () {
        this.ui.lastTextField.off();
    }
});

window.Behaviors.Form = Form;
