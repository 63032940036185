'use strict';


/**
 * {object} props
 * @property {Ad|Campaign|Creative} model
 * @property {string} showType 
 * @property {ch} channel
 * @property {bool} showDetail
*/

var app = require('scripts/core').app;
var PrivateChannel = require('scripts/common/mixins/private-channel');
var ListHeader = React.createClass({
    mixins:[PrivateChannel],

    getInitialState: function () {
        return this.getPageNum();
    },

    getPageNum: function () {
        if (this.props.itemType === 'ads') {
            var len = this.props.collection.length;
            var filteredCount = this.props.filteredCount;
            // local filtering 
            return {from: ~~(filteredCount !== 0), to: filteredCount, total: len};
        }

        if (this.props.collection != null && !this.props.collection.isEmpty()) {
            var state = this.props.collection.state;
            return {
                from: state.currentPage * state.pageSize + 1,

                to: state.currentPage + 1 === state.totalPages ?
                    state.totalRecords : (state.currentPage + 1) * state.pageSize,

                total: state.totalRecords
            }
        } else {
            return {from: 0, to: 0, total: 0};
        }
    },

    componentWillUpdate() {
        this.updatePageState();
    },

    updatePageState: function () {
        this.setState(this.getPageNum());
    },

    componentDidMount: function () {
        var $el = $(this.getDOMNode());
        $el.find('.help').click(()=>this.showHelp());
    },

    showHelp: function () {
        var popup = globalCh.request('popup', {size: 'large', backdrop: true});
        var HelpView = require('scripts/widgets/fill-graph-help');

        popup.show(new HelpView());
    },

    render: function () {

        var {total, to, from} = this.state;
        var itemType = this.props.itemType;

        var md4;
        if (itemType === 'ads') {
            var canEdit = this.props.canEdit;
            md4 = (
                <div>
                    <div className={canEdit? "col-md-1 text-center" : "hide"}>
                        <strong>Pause</strong>
                    </div>
                    <div className={canEdit? "col-md-3" : "col-md-4"}>
                        <strong>{from}–{to} of {total} {itemType}</strong>
                    </div>
                </div>
            );
        } else {
            md4 = (
                <div className="col-md-4">
                    <strong>{from}–{to} of {total} {itemType}</strong>
                </div>
            );
        }

        return (

            <div className="list-header visible-lg">
                <div className="row">

                    {md4}
                    <div className="col-md-3 text-right">
                        <strong>Progress &nbsp;
                            <i className="help fa fa-question-circle text-muted"></i>
                        </strong>
                    </div>
                    <div className="col-md-5">
                        <table className="table table-header">
                            <thead>
                                <tr>
                                    <th width="35%" className="text-right">
                                        <strong>Total</strong>
                                    </th>
                                    <th width="35%" className="text-right">
                                        <strong>Today</strong>
                                    </th>
                                    <th width="30%" className="text-right">
                                        <strong>CTR</strong>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
});

module.exports = ListHeader;
