'use strict';
var flux = require('scripts/core/flux');
var http = require('scripts/core/http');
var campaigns = require('scripts/core/campaign-collection');
var fc = flux.constants;


flux.addActions({

    progressPage: {
        activateTab: function (payload) {
            this.dispatch(fc.campaign_progress_page_activate, {
                campaignId: payload.campaignId,
                adId: payload.adId
            });
        },

        fetchProgressPageData: function (campaignId, adId) {

            // @TODO: isLoading state shuld be set on this action
            this.dispatch(fc.campaign_progress_page_loading, {
                campaignId: campaignId,
                adId: adId
            });


            http.get(`campaigns/${campaignId}/livestats?keys=hour,date&sort=date`)
                .then((liveStats) => {

                    this.dispatch(fc.progress_campaign_live_stats_recieved, {
                        campaignId: campaignId,
                        liveHourly: liveStats
                    });
                });

            Promise.all([
                http.get(`campaigns/${campaignId}`),
                http.get(`campaigns/${campaignId}/ads`),
                http.get(`campaigns/${campaignId}/creatives`)
            ])
            .then(([campaign, ads, creatives]) => {

                this.dispatch(fc.campaign_progress_page_loaded, {
                    campaignId: campaignId,
                    adId: adId,
                    campaign,
                    ads,
                    creatives
                });
                return ads.map(ad => ad.id);
            })
            .then(adIds => {
                const stream = new Bacon.Bus();

                stream.bufferWithTime(200)
                    .onValue(statsResponses => {
                        this.dispatch(fc.progress_ad_live_stats_recieved, {
                            campaignId: campaignId,
                            responses: statsResponses
                        });
                    });

                adIds.forEach(id => {
                    http.get(`campaigns/${campaignId}/ads/${id}/livestats?keys=hour,date&sort=date`)
                        .then(liveStats => {
                            stream.push({
                                campaignId: campaignId,
                                adId: id,
                                liveHourly: liveStats
                            });
                        });
                });
            });
        },

        fetchComponentData: function (campaignId, adId) {
            var self = this;

            //@TODO: deprecate
            campaigns.get(campaignId)
                .refresh()
                .then(function () {
                    campaigns.get(campaignId).ads.refresh()
                        .then(function () {
                            self.dispatch(fc.ads_fetch_success, campaigns.get(campaignId).ads);
                        });
                });
        }
    }
});