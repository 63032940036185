<!-- file: organizations/templates/show.hbs -->
<h3>Organization: <span data-id="name"></span></h3>

<div class="detail-region ">
    <div class="row">
        <div class="col-xs-4">
            <label>Default Max CPM</label>
        </div>
        <div class="col-xs-8">
            <span data-id="default_max_cpm_rate"></span>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-4">
            <label>Credit Limit</label>
        </div>
        <div class="col-xs-8">
            <span data-id="credit_limit"></span>
        </div>
    </div>

    {{#if can_update}}
    <div class="row">
        <div class="col-xs-12">
            <button class="btn btn-primary btn-xs" data-action="edit">
                <i class="fa fa-pencil"></i>
                Edit
            </button>
        </div>
    </div>
    {{/if}}

</div>

