{
    "AE": [
        [
            "Asia/Dubai",
            "Dubai",
            "United Arab Emirates",
            null
        ]
    ],
    "AF": [
        [
            "Asia/Kabul",
            "Kabul",
            "Afghanistan",
            null
        ]
    ],
    "AM": [
        [
            "Asia/Yerevan",
            "Yerevan",
            "Armenia",
            null
        ]
    ],
    "AZ": [
        [
            "Asia/Baku",
            "Baku",
            "Azerbaijan",
            null
        ]
    ],
    "BD": [
        [
            "Asia/Dhaka",
            "Dhaka",
            "Bangladesh",
            null
        ]
    ],
    "BH": [
        [
            "Asia/Bahrain",
            "Bahrain",
            "Bahrain",
            null
        ]
    ],
    "BN": [
        [
            "Asia/Brunei",
            "Brunei",
            "Brunei Darussalam",
            null
        ]
    ],
    "BR": [
        [
            "America/Araguaina",
            "Araguaina",
            "Brazil",
            null
        ],
        [
            "America/Bahia",
            "Bahia",
            "Brazil",
            null
        ],
        [
            "America/Belem",
            "Belem",
            "Brazil",
            null
        ],
        [
            "America/Boa_Vista",
            "Boa Vista",
            "Brazil",
            null
        ],
        [
            "America/Campo_Grande",
            "Campo Grande",
            "Brazil",
            null
        ],
        [
            "America/Cuiaba",
            "Cuiaba",
            "Brazil",
            null
        ],
        [
            "America/Eirunepe",
            "Eirunepe",
            "Brazil",
            null
        ],
        [
            "America/Fortaleza",
            "Fortaleza",
            "Brazil",
            null
        ],
        [
            "America/Maceio",
            "Maceio",
            "Brazil",
            null
        ],
        [
            "America/Manaus",
            "Manaus",
            "Brazil",
            null
        ],
        [
            "America/Noronha",
            "Noronha",
            "Brazil",
            null
        ],
        [
            "America/Porto_Velho",
            "Porto Velho",
            "Brazil",
            null
        ],
        [
            "America/Recife",
            "Recife",
            "Brazil",
            null
        ],
        [
            "America/Rio_Branco",
            "Rio Branco",
            "Brazil",
            null
        ],
        [
            "America/Santarem",
            "Santarem",
            "Brazil",
            null
        ],
        [
            "America/Sao_Paulo",
            "Sao Paulo",
            "Brazil",
            null
        ]
    ],
    "BT": [
        [
            "Asia/Thimphu",
            "Thimphu",
            "Bhutan",
            null
        ]
    ],
    "CA": [
        [
            "America/Atikokan",
            "Atikokan",
            "Canada",
            "ON"
        ],
        [
            "America/Blanc-Sablon",
            "Blanc Sablon",
            "Canada",
            null
        ],
        [
            "America/Cambridge_Bay",
            "Cambridge Bay",
            "Canada",
            "NU"
        ],
        [
            "America/Creston",
            "Creston",
            "Canada",
            "BC"
        ],
        [
            "America/Dawson",
            "Dawson",
            "Canada",
            "YT"
        ],
        [
            "America/Dawson_Creek",
            "Dawson Creek",
            "Canada",
            "BC"
        ],
        [
            "America/Edmonton",
            "Edmonton",
            "Canada",
            "AB"
        ],
        [
            "America/Glace_Bay",
            "Glace Bay",
            "Canada",
            "NS"
        ],
        [
            "America/Goose_Bay",
            "Goose Bay",
            "Canada",
            "NL"
        ],
        [
            "America/Halifax",
            "Halifax",
            "Canada",
            "NS"
        ],
        [
            "America/Inuvik",
            "Inuvik",
            "Canada",
            "NT"
        ],
        [
            "America/Iqaluit",
            "Iqaluit",
            "Canada",
            "NU"
        ],
        [
            "America/Moncton",
            "Moncton",
            "Canada",
            "NB"
        ],
        [
            "America/Montreal",
            "Montreal",
            "Canada",
            "QC"
        ],
        [
            "America/Nipigon",
            "Nipigon",
            "Canada",
            "ON"
        ],
        [
            "America/Pangnirtung",
            "Pangnirtung",
            "Canada",
            "NU"
        ],
        [
            "America/Rainy_River",
            "Rainy River",
            "Canada",
            "ON"
        ],
        [
            "America/Rankin_Inlet",
            "Rankin Inlet",
            "Canada",
            "NU"
        ],
        [
            "America/Regina",
            "Regina",
            "Canada",
            "SK"
        ],
        [
            "America/Resolute",
            "Resolute",
            "Canada",
            "NU"
        ],
        [
            "America/St_Johns",
            "St Johns",
            "Canada",
            null
        ],
        [
            "America/Swift_Current",
            "Swift Current",
            "Canada",
            "NL"
        ],
        [
            "America/Thunder_Bay",
            "Thunder Bay",
            "Canada",
            "ON"
        ],
        [
            "America/Toronto",
            "Toronto",
            "Canada",
            "ON"
        ],
        [
            "America/Vancouver",
            "Vancouver",
            "Canada",
            "BC"
        ],
        [
            "America/Whitehorse",
            "Whitehorse",
            "Canada",
            "YT"
        ],
        [
            "America/Winnipeg",
            "Winnipeg",
            "Canada",
            "MB"
        ],
        [
            "America/Yellowknife",
            "Yellowknife",
            "Canada",
            "NT"
        ],
        [
            "America/Ottawa",
            "Ottawa",
            "Canada",
            "ON"
        ],
        [
            "America/Calgary",
            "Calgary",
            "Canada",
            "AB"
        ],
        [
            "Canada/Atlantic",
            "Atlantic",
            "Canada",
            null
        ],
        [
            "Canada/Central",
            "Central",
            "Canada",
            null
        ],
        [
            "Canada/East-Saskatchewan",
            "East-Saskatchewan",
            "Canada",
            null
        ],
        [
            "Canada/Eastern",
            "Eastern",
            "Canada",
            null
        ],
        [
            "Canada/Mountain",
            "Mountain",
            "Canada",
            null
        ],
        [
            "Canada/Newfoundland",
            "Newfoundland",
            "Canada",
            null
        ],
        [
            "Canada/Pacific",
            "Pacific",
            "Canada",
            null
        ],
        [
            "Canada/Saskatchewan",
            "Saskatchewan",
            "Canada",
            null
        ],
        [
            "Canada/Yukon",
            "Yukon",
            "Canada",
            null
        ]
    ],
    "CN": [
        [
            "Asia/Chongqing",
            "Chongqing",
            "China",
            null
        ],
        [
            "Asia/Harbin",
            "Harbin",
            "China",
            null
        ],
        [
            "Asia/Kashgar",
            "Kashgar",
            "China",
            null
        ],
        [
            "Asia/Shanghai",
            "Shanghai",
            "China",
            null
        ],
        [
            "Asia/Urumqi",
            "Urumqi",
            "China",
            null
        ]
    ],
    "CY": [
        [
            "Asia/Nicosia",
            "Nicosia",
            "Cyprus",
            null
        ]
    ],
    "GB": [
        [
            "Europe/London",
            "London",
            "United Kingdom",
            null
        ]
    ],
    "GE": [
        [
            "Asia/Tbilisi",
            "Tbilisi",
            "Georgia",
            null
        ]
    ],
    "HK": [
        [
            "Asia/Hong_Kong",
            "Hong Kong",
            "Hong Kong",
            null
        ]
    ],
    "ID": [
        [
            "Asia/Jakarta",
            "Jakarta",
            "Indonesia",
            null
        ],
        [
            "Asia/Jayapura",
            "Jayapura",
            "Indonesia",
            null
        ],
        [
            "Asia/Makassar",
            "Makassar",
            "Indonesia",
            null
        ],
        [
            "Asia/Pontianak",
            "Pontianak",
            "Indonesia",
            null
        ]
    ],
    "IL": [
        [
            "Asia/Jerusalem",
            "Jerusalem",
            "Israel",
            null
        ]
    ],
    "IN": [
        [
            "Asia/Kolkata",
            "Kolkata",
            "India",
            null
        ]
    ],
    "IQ": [
        [
            "Asia/Baghdad",
            "Baghdad",
            "Iraq",
            null
        ]
    ],
    "IR": [
        [
            "Asia/Tehran",
            "Tehran",
            "Iran",
            null
        ]
    ],
    "JO": [
        [
            "Asia/Amman",
            "Amman",
            "Jordan",
            null
        ]
    ],
    "JP": [
        [
            "Asia/Tokyo",
            "Tokyo",
            "Japan",
            null
        ]
    ],
    "KG": [
        [
            "Asia/Bishkek",
            "Bishkek",
            "Kyrgyzstan",
            null
        ]
    ],
    "KH": [
        [
            "Asia/Phnom_Penh",
            "Phnom Penh",
            "Cambodia",
            null
        ]
    ],
    "KP": [
        [
            "Asia/Pyongyang",
            "Pyongyang",
            "North Korea",
            null
        ]
    ],
    "KR": [
        [
            "Asia/Seoul",
            "Seoul",
            "South Korea",
            null
        ]
    ],
    "KW": [
        [
            "Asia/Kuwait",
            "Kuwait",
            "Kuwait",
            null
        ]
    ],
    "KZ": [
        [
            "Asia/Almaty",
            "Almaty",
            "Kazakhstan",
            null
        ],
        [
            "Asia/Aqtau",
            "Aqtau",
            "Kazakhstan",
            null
        ],
        [
            "Asia/Aqtobe",
            "Aqtobe",
            "Kazakhstan",
            null
        ],
        [
            "Asia/Oral",
            "Oral",
            "Kazakhstan",
            null
        ],
        [
            "Asia/Qyzylorda",
            "Qyzylorda",
            "Kazakhstan",
            null
        ]
    ],
    "LA": [
        [
            "Asia/Vientiane",
            "Vientiane",
            "Laos",
            null
        ]
    ],
    "LB": [
        [
            "Asia/Beirut",
            "Beirut",
            "Lebanon",
            null
        ]
    ],
    "LK": [
        [
            "Asia/Colombo",
            "Colombo",
            "Sri Lanka",
            null
        ]
    ],
    "MM": [
        [
            "Asia/Rangoon",
            "Rangoon",
            "Myanmar",
            null
        ]
    ],
    "MN": [
        [
            "Asia/Choibalsan",
            "Choibalsan",
            "Mongolia",
            null
        ],
        [
            "Asia/Hovd",
            "Hovd",
            "Mongolia",
            null
        ],
        [
            "Asia/Ulaanbaatar",
            "Ulaanbaatar",
            "Mongolia",
            null
        ]
    ],
    "MO": [
        [
            "Asia/Macau",
            "Macau",
            "Macao",
            null
        ]
    ],
    "MY": [
        [
            "Asia/Kuala_Lumpur",
            "Kuala Lumpur",
            "Malaysia",
            null
        ],
        [
            "Asia/Kuching",
            "Kuching",
            "Malaysia",
            null
        ]
    ],
    "NP": [
        [
            "Asia/Kathmandu",
            "Kathmandu",
            "Nepal",
            null
        ]
    ],
    "OM": [
        [
            "Asia/Muscat",
            "Muscat",
            "Oman",
            null
        ]
    ],
    "PH": [
        [
            "Asia/Manila",
            "Manila",
            "Philippines",
            null
        ]
    ],
    "PK": [
        [
            "Asia/Karachi",
            "Karachi",
            "Pakistan",
            null
        ]
    ],
    "PS": [
        [
            "Asia/Gaza",
            "Gaza",
            "Palestine, State of",
            null
        ],
        [
            "Asia/Hebron",
            "Hebron",
            "Palestine, State of",
            null
        ]
    ],
    "QA": [
        [
            "Asia/Qatar",
            "Qatar",
            "Qatar",
            null
        ]
    ],
    "RU": [
        [
            "Asia/Anadyr",
            "Anadyr",
            "Russia",
            null
        ],
        [
            "Asia/Irkutsk",
            "Irkutsk",
            "Russia",
            null
        ],
        [
            "Asia/Kamchatka",
            "Kamchatka",
            "Russia",
            null
        ],
        [
            "Asia/Khandyga",
            "Khandyga",
            "Russia",
            null
        ],
        [
            "Asia/Krasnoyarsk",
            "Krasnoyarsk",
            "Russia",
            null
        ],
        [
            "Asia/Magadan",
            "Magadan",
            "Russia",
            null
        ],
        [
            "Asia/Novokuznetsk",
            "Novokuznetsk",
            "Russia",
            null
        ],
        [
            "Asia/Novosibirsk",
            "Novosibirsk",
            "Russia",
            null
        ],
        [
            "Asia/Omsk",
            "Omsk",
            "Russia",
            null
        ],
        [
            "Asia/Sakhalin",
            "Sakhalin",
            "Russia",
            null
        ],
        [
            "Asia/Ust-Nera",
            "Ust Nera",
            "Russia",
            null
        ],
        [
            "Asia/Vladivostok",
            "Vladivostok",
            "Russia",
            null
        ],
        [
            "Asia/Yakutsk",
            "Yakutsk",
            "Russia",
            null
        ],
        [
            "Asia/Yekaterinburg",
            "Yekaterinburg",
            "Russia",
            null
        ],
        [
            "Europe/Kaliningrad",
            "Kaliningrad",
            "Russia",
            null
        ],
        [
            "Europe/Moscow",
            "Moscow",
            "Russia",
            null
        ],
        [
            "Europe/Samara",
            "Samara",
            "Russia",
            null
        ],
        [
            "Europe/Simferopol",
            "Simferopol",
            "Russia",
            null
        ],
        [
            "Europe/Volgograd",
            "Volgograd",
            "Russia",
            null
        ]
    ],
    "SA": [
        [
            "Asia/Riyadh",
            "Riyadh",
            "Saudi Arabia",
            null
        ]
    ],
    "SG": [
        [
            "Asia/Singapore",
            "Singapore",
            "Singapore",
            null
        ]
    ],
    "SY": [
        [
            "Asia/Damascus",
            "Damascus",
            "Syria",
            null
        ]
    ],
    "TH": [
        [
            "Asia/Bangkok",
            "Bangkok",
            "Thailand",
            null
        ]
    ],
    "TJ": [
        [
            "Asia/Dushanbe",
            "Dushanbe",
            "Tajikistan",
            null
        ]
    ],
    "TL": [
        [
            "Asia/Dili",
            "Dili",
            "Timor-Leste",
            null
        ]
    ],
    "TM": [
        [
            "Asia/Ashgabat",
            "Ashgabat",
            "Turkmenistan",
            null
        ]
    ],
    "TN": [
        [
            "Africa/Tunis",
            "Tunis",
            "Tunisia",
            null
        ]
    ],
    "TW": [
        [
            "Asia/Taipei",
            "Taipei",
            "Taiwan",
            null
        ]
    ],
    "US": [
        [
            "America/Adak",
            "Adak",
            "United States",
            "AK"
        ],
        [
            "America/Anchorage",
            "Anchorage",
            "United States",
            "AK"
        ],
        [
            "America/Boise",
            "Boise",
            "United States",
            "ID"
        ],
        [
            "America/Chicago",
            "Chicago",
            "United States",
            "IL"
        ],
        [
            "America/Denver",
            "Denver",
            "United States",
            "CO"
        ],
        [
            "America/Detroit",
            "Detroit",
            "United States",
            "MI"
        ],
        [
            "America/Juneau",
            "Juneau",
            "United States",
            "AK"
        ],
        [
            "America/Los_Angeles",
            "Los Angeles",
            "United States",
            "CA"
        ],
        [
            "America/Menominee",
            "Menominee",
            "United States",
            "MI"
        ],
        [
            "America/Metlakatla",
            "Metlakatla",
            "United States",
            "AK"
        ],
        [
            "America/New_York",
            "New York",
            "United States",
            "NY"
        ],
        [
            "America/Nome",
            "Nome",
            "United States",
            "AK"
        ],
        [
            "America/Phoenix",
            "Phoenix",
            "United States",
            "AZ"
        ],
        [
            "America/Shiprock",
            "Shiprock",
            "United States",
            "NM"
        ],
        [
            "America/Sitka",
            "Sitka",
            "United States",
            "AK"
        ],
        [
            "America/Yakutat",
            "Yakutat",
            "United States",
            "AK"
        ],
        [
            "Pacific/Honolulu",
            "Honolulu",
            "United States",
            "HI"
        ],
        [
            "US/Alaska",
            "Alaska",
            "United States",
            "AK"
        ],
        [
            "US/Aleutian",
            "Aleutian",
            "United States",
            "CA"
        ],
        [
            "US/Arizona",
            "Arizona",
            "United States",
            "AZ"
        ],
        [
            "US/Central",
            "Central",
            "United States",
            null
        ],
        [
            "US/East-Indiana",
            "East-Indiana",
            "United States",
            "IN"
        ],
        [
            "US/Eastern",
            "Eastern",
            "United States",
            null
        ],
        [
            "US/Hawaii",
            "Hawaii",
            "United States",
            "HI"
        ],
        [
            "US/Indiana-Starke",
            "Indiana-Starke",
            "United States",
            "IN"
        ],
        [
            "US/Michigan",
            "Michigan",
            "United States",
            "MI"
        ],
        [
            "US/Mountain",
            "Mountain",
            "United States",
            null
        ],
        [
            "US/Pacific",
            "Pacific",
            "United States",
            null
        ],
        [
            "US/Pacific-New",
            "Pacific-New",
            "United States",
            null
        ],
        [
            "US/Samoa",
            "Samoa",
            "United States",
            null
        ]
    ],
    "UTC": [
        [
            "UTC",
            "UTC",
            "Universal Time Coordinated",
            null
        ]
    ],
    "UZ": [
        [
            "Asia/Samarkand",
            "Samarkand",
            "Uzbekistan",
            null
        ],
        [
            "Asia/Tashkent",
            "Tashkent",
            "Uzbekistan",
            null
        ]
    ],
    "VN": [
        [
            "Asia/Ho_Chi_Minh",
            "Ho Chi Minh",
            "Vietnam",
            null
        ]
    ],
    "YE": [
        [
            "Asia/Aden",
            "Aden",
            "Yemen",
            null
        ]
    ]
}
