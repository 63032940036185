'use strict';

var app = require('scripts/core').app;
var ProfileView = require('./profile');

var Controller = Mario.Controller.extend({
    profile: function () {
        displayPage(ProfileView, 'profile', null, null, true);
    }
});

var controller = new Controller();

exports.start = function () {
    debug('start profile component');
    this.router = new Mario.AppRouter({
        appRoutes: {
            'profile': 'profile'
        },

        controller: controller
    });
    return {
        matcher    : /\/profile/,
        url        : '/profile',
        key        : 'profile',
        add_to_menu: true,
        menu_config: {icon: 'fa-user', name: 'Profile'}
    };
};


function displayPage(Page, name, model) {
    var page = globalCh.request('available-page', {main_url: '/profile'});

    // TODO handle request without permission

    page.show(function () {
        return new Page({});
    });
}

