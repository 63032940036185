'use strict';

var app = require('scripts/core').app;
var viewTemplate = require('../templates/reset');
var Form = require('scripts/common/mixins/form');

module.exports = Mario.ItemView.extend({
    template: viewTemplate,

    events: {
        'click button': 'onSave'
    },

    onSave: function (e) {
        var newPassword = this.$('.new-password').val(),
        confirmPassword = this.$('.confirm-password').val();
        if (newPassword !== confirmPassword) {
            toastr.error('Passwords do not match.');
            return false;
        }
        var req = $.ajax({
            type: 'post',
            url: 'password/reset',
            data: JSON.stringify({password: newPassword, reset_token: this.options.resetToken})
        });
        req.then(function (data) {
            toastr.success('Your password has been successfully reset.');
            Backbone.history.navigate('/signin', {trigger: true});
        }, function (e) {
            var resData = e.responseJSON;
            if (resData) {
                // Reset Token was already used. Redirect to the request page
                if (resData.status_code === 404 || resData.status_code === 403) {
                    toastr.error('Your token has expired. Please request a new reset email.');
                }
            }
        });
        return false;
    },

    mixins: [Form]
});
