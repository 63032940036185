'use strict';

var env = require('scripts/env');
var flux = require('scripts/core/flux');
var constants = require('scripts/common/constants/flux-events');

flux.constants = constants;

require('scripts/actions/accounting-page');
require('scripts/actions/ads');
require('scripts/actions/campaigns');
require('scripts/actions/profile');
require('scripts/actions/progress-page');
require('scripts/actions/report-page');
require('scripts/actions/spend-stats');
require('scripts/actions/graph');
require('scripts/actions/router');

require('scripts/stores/ads');
require('scripts/stores/campaigns');

require('scripts/stores/organizations');

require('scripts/stores/route');
require('scripts/stores/session');
require('scripts/stores/spend-stats');
require('scripts/stores/users');
require('scripts/stores/application');


// Resource Stores
require('scripts/stores/resources/campaign-report-stats');

// Domain Stores
require('scripts/stores/domains/campaign-reporting');

if (env.DEBUG) {
    flux.on('dispatch', function(type, payload) {
        console.log('Dispatched', type, payload);
    });
}
