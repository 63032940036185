'use strict';

var groupsCampaignReducer = require('./groups-campaign');
var groupsAdReducer = require('./groups-ad');

var intialState = {
    'isOpen': false,
    'details': {
        'title': '',
        'secondaryTitle': '',
        'callToActions': [],
        'groups': []
    }
};

function summaryPopupReducer (state = intialState, resources, session, campaignId) {
    const summaryPopupSession = objectPath.get(session, `campaigns.progress.instances.${campaignId}.summaryPopup`, {});
    const adId = objectPath.get(session, `campaigns.progress.instances.${campaignId}.adId`, null);

    let details,
        groups = [],
        secondaryTitle;

    const callToActions = [{
        iconType: 'clock',
        action: function () {
            // alert('blah blah blah history');
        }
    }];

    if (adId) {
        details = objectPath.get(resources, `campaigns.entities.${campaignId}.ads.entities.${adId}.attr`);

        if (!details) {
            return state;
        }

        const creative = objectPath.get(resources, `campaigns.entities.${campaignId}.creatives.entities.${details.creative}.attr`);

        if (details && creative) {
            groups = groupsAdReducer(details, creative);
        }

        secondaryTitle = 'Ad Summary';

        callToActions.unshift({
            iconType: 'pencil',
            text: 'Edit',
            action: function () {
                // alert('blah blah blah edit');
            }
        });
    } else {
        details = objectPath.get(resources, `campaigns.entities.${campaignId}.attr`, {});

        if (!details) {
            return state;
        }

        groups = groupsCampaignReducer(details);
        secondaryTitle = 'Campaign Summary';
    }

    return {
        ...state,
        isOpen: summaryPopupSession.isOpen,
        details: {
            ...state.details,
            title: details.name,
            secondaryTitle: secondaryTitle,
            callToActions: callToActions,
            groups: groups
        }
    };
}



module.exports = summaryPopupReducer;