'use strict';
var PrivateChannel = require('scripts/common/mixins/private-channel');
var RefreshButton = require('scripts/widgets/refresh-button');

var SectionDivider = React.createClass({
    mixins: [PrivateChannel],
    getInitialState() {
        return {
            settings: {
                ads: {
                    search: '',
                    sort: ''
                },
                creatives: {
                    search: ''
                },
                files: {
                    search: ''
                }
            }
        }
    },

    componentDidUpdate() {
        if (!_.contains(['ads', 'files', 'creatives'], this.props.section)) return;
        var $el = $(this.getDOMNode());
        if (_.has(this.state.settings, this.props.section)) {
            var val = this.state.settings[this.props.section].search;
            $el.find('input').val(this.state.settings[this.props.section].search);
        }
    },

    onClickNewItem() {
        this.props.ch.trigger(`campaign:${this.props.section}:new`);
    },

    onSort(ev) {
        var by = $(ev.currentTarget).find('option:selected').val();
        var section = this.props.section;
        var settings = {};
        settings[section] = {sort: by};
        this.setState({settings});
        this.props.ch.trigger(`campaign:${section}:sort`, objectPath.get(settings, section));
    },

    onSearch(ev) {
        var by = $(ev.currentTarget).val();

        if (by == this.state.settings.search) return;

        var section = this.props.section;
        var settings = _.clone(this.state.settings);
        settings[section] = {search: by};
        this.setState({settings});
        this.props.ch.trigger(`campaign:${section}:search`, objectPath.get(settings, section));
    },

    render() {
        var campaign = this.props.campaign;
        var section = this.props.section;
        var toolbar;

        switch (section) {
            case 'traffic-sheet':
                toolbar = (
                    <div className="toolbar" style={{display: 'block'}}>
                        <div className="row">
                            <div className="toolbar-actions col-sm-5">
                                <RefreshButton collection={campaign.groupings}/>
                            </div>
                        </div>
                    </div>);
                break;
            case 'ads':
                toolbar = (
                    <div className="toolbar" style={{display: 'block'}}>
                        <div className="row">
                            <div className="toolbar-filter col-sm-5 col-md-4">
                                <input type="search" placeholder="Search" className="toolbar-search form-control" onKeyUp={this.onSearch}/>
                            </div>
                            <div className="toolbar-actions col-sm-7 col-md-8" >

                                <div className="row">

                                    <div className="ctrl-box1" >
                                        <label className="form-control-static visible-lg-inline">Sort:</label>
                                        <select className="form-control" onChange={this.onSort} >
                                            <option value="_etag">Last created</option>
                                            <option value="end">Ending soonest</option>
                                        </select>
                                        <div className="">
                                            <RefreshButton collection={campaign.ads}/>
                                        </div>
                                    </div>
                                    <div className="ctrl-box2">
                                        {campaign.can('edit')?
                                        <button className="btn btn-sm btn-success" onClick={this.onClickNewItem}>
                                            <i className="fa fa-plus"></i>New Ad
                                        </button>
                                        :null }
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>);
                break;
            case 'creatives':
                toolbar = (
                    <div className="toolbar" style={{display: 'block'}}>
                        <div className="row">
                            <div className="toolbar-filter col-sm-5">
                                <input type="search" placeholder="Search" className="toolbar-search form-control" onKeyUp={this.onSearch}/>
                            </div>
                            <div className="toolbar-actions col-sm-7">
                                <div className="row">
                                    <div className="col-xs-6">
                                        <RefreshButton collection={campaign.creatives}/>
                                    </div>
                                    {campaign.can('edit')?
                                    <div className="col-xs-6 text-right">
                                        <button className="btn btn-sm btn-success" onClick={this.onClickNewItem}>
                                            <i className="fa fa-plus"></i>
                                            New Creative</button>
                                    </div>
                                    :null}
                                </div>
                            </div>
                        </div>
                    </div>);
                break;
            case 'files':
                toolbar = (
                    <div className="toolbar" style={{display: 'block'}}>
                        <div className="row">
                            <div className="toolbar-filter col-sm-5">
                                <input type="search" placeholder="Search" className="toolbar-search form-control" onKeyUp={this.onSearch}/>
                            </div>
                            <div className="toolbar-actions col-sm-7">
                                <div className="row">
                                    <div className="col-xs-6">
                                        <RefreshButton collection={campaign.assets}/>
                                    </div>
                                    {campaign.can('edit')?
                                    <div className="col-xs-6 text-right">
                                        <button className="btn btn-sm btn-success" onClick={this.onClickNewItem}>
                                            <i className="fa fa-plus"></i>
                                            New File</button>
                                    </div>
                                    :null}
                                </div>
                            </div>
                        </div>
                    </div>);
                break;


        }


        return (
            <div className="sectional-divider">
                <ul className="nav nav-tabs">
                    <li className={section==='ads'? 'active': ''}>
                        <a href={`/campaigns/${campaign.id}/ads`}>Ads</a>
                    </li>
                    <li className={section==='creatives'? 'active': ''}>
                        <a href={`/campaigns/${campaign.id}/creatives`}>Creatives</a>
                    </li>
                    <li className={section==='files'? 'active': ''}>
                        <a href={`/campaigns/${campaign.id}/files`}>Files</a>
                    </li>
                    <li className={section==='traffic-sheet'? 'active': ''}>
                        <a href={`/campaigns/${campaign.id}/traffic-sheet`}>Traffic Sheet</a>
                    </li>
                </ul>
                {toolbar}
            </div>
        )
    }
});

module.exports = SectionDivider;
