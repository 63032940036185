'use strict';
const op = objectPath;
const flux = require('scripts/core/flux');
const Heatmap = require('scripts/components/campaigns/views/report/graphs/heatmap');
const domainSetting = window.ds = require('./domain-setting');

const DayWeekPartsGraph = React.createClass({
    componentWillMount() {
        this._cache = window.dwp = {};

        //  ===================================
        //  = Documention for items in _cache =
        //  ===================================
            //
            // this._cahce = {
            //     heatmapStats: [  // length 28, refereced to processed.stats, ready to use in generating presentations value for heatmap cells
            //         {
            //               clicks: 0
            //             , group: {'hour|day_of_week': '0-5|0'}
            //             , impressions: 0
            //             , spend: 0
            //         },
            //         {...},
            //         {...}, ...
            //     ],
            //     processed: {             // Processed holds items from which raw data have been grouped into bins and then flattend
            //         campaign_id: 412324,
            //         distinctValues: [ '0-5|0' , '6-11|0' , '12-17|0' , ...  ],  // length = 4*7 = 28
            //         keys: ['hour|day_of_week'],
            //         stats: [ // length = 28
            //             {
            //                   clicks: 0
            //                 , group: { 'hour|day_of_week': '0-5|0' }
            //                 , impressions: 0
            //                 , spend: 0
            //             },
            //             {...},
            //             {...}, ...
            //         ]
            //     },
            //     selected: {
            //         day_of_week: [],   // This array holds selections in the table header
            //         hour: []           // This array holds selections in the side header
            //     },
            //     selectedItems_flatten: [ 0 , 1 , 2 , 3 , 4 , 5 , 6 ... 27 ],   // This array holds selections in the table cell
            // }

        domainSetting.reportDictionary =  this.props.reportDictionary;
    },

    onCheckboxSelected_group0 (selected_intervals) {
        const selections = domainSetting.convertSelection_intervalToHours( selected_intervals);

        flux.actions.reportPage.dimensionSelection_change({
            campaignId: this.props.campaignId,
            selection: selections,
            dimensionName: 'hour'
        });
    },

    onCheckboxSelected_group1 (selected_day_of_week) {
        flux.actions.reportPage.dimensionSelection_change({
            campaignId: this.props.campaignId,
            selection: selected_day_of_week,
            dimensionName: 'day_of_week'
        });
    },

    handleReset(){
        flux.actions.reportPage.dimensionTwoSelections_change({
            campaignId: this.props.campaignId,
            selections: {
                'hour': [],
                'day_of_week': []
            },
        });
    },

    buildPresentation_group_day_of_week() {
        const presentation = [];
        const configDimensions = this.props.configDimensions;
        const selected_day_of_week = configDimensions.day_of_week.selected;
        const binSpecification = domainSetting.day_of_week.bin;
        const binSpecKeys = Object.keys(binSpecification);
        binSpecKeys.forEach((binSpecKey) => {
            let _name;
            let _presentationName;
            let _isSelected_day_of_week;

            _name = +binSpecKey;
            _isSelected_day_of_week = _.contains(selected_day_of_week, _name);
            _presentationName = domainSetting.reportDictionary.day_of_week_short[_name].value;

            presentation.push({
                name: _name,
                presentationName: _presentationName,
                selected: _isSelected_day_of_week
            });
        });
        return presentation;
    },

    buildPresentation_group_interval() {
        const presentation = [];
        const configDimensions = this.props.configDimensions;
        const selected_intervals = domainSetting.convertSelection_hoursToInterval(configDimensions.hour.selected);
        const binSpecification = domainSetting.hour.bin;
        const binSpecKeys = Object.keys(binSpecification);
        binSpecKeys.forEach((binSpecKey) => {
            let _name;
            let _presentationName;
            let _isSelected_day_of_week;

            _name = binSpecKey+'';
            _isSelected_day_of_week = _.contains(selected_intervals, _name);
            const binName = domainSetting.hour.binKeyToBinName[_name];
            _presentationName = domainSetting.reportDictionary.hourInterval[binName].value;

            presentation.push({
                name: _name,
                presentationName: _presentationName,
                selected: _isSelected_day_of_week
            });
        });
        return presentation;
    },

    buildPresentation_stats() {
        let presentation = [];

        const configDimensions = this.props.configDimensions;
        const processed = processData(this.props.rawData);
        const selected = this._cache.selected;

        const dataMap = domainSetting.dataMap;
        let heatmapStats;
        let selectedItems_flatten;
        let selectedItems_flattenLongFormat;
        let totalFilteredStats;

        if ( processed && processed.stats) {
            heatmapStats = processed.stats;
            selectedItems_flatten = setHeatmapSelected_flatten(this.props.campaignId, 'hour|day_of_week', ['hour|day_of_week'], selected);
            selectedItems_flattenLongFormat = _.map(selectedItems_flatten, (item) =>{ return dataMap[item]; });

            this._cache.processed = processed;
            this._cache.heatmapStats = heatmapStats;
            this._cache.selectedItems_flatten  = selectedItems_flatten;

            // Accret all cell values to a total sum so it can be use to caculate percentage
            totalFilteredStats = this.accret_heatmap(this.props.campaignId, 'hour|day_of_week');

            presentation = _.map(heatmapStats, (stat) => {
                let _name;
                let _statsMetrics;
                let _statsMetrics_numType;
                let _isSelected;

                if (stat) {
                    _name = stat.group['hour|day_of_week'];
                    _isSelected = _.contains(selectedItems_flattenLongFormat, stat.group['hour|day_of_week']);

                    // absolute values
                    var clicks      = stat.clicks;
                    var impressions = stat.impressions;
                    var ctr         = (impressions !== 0) ? clicks/impressions : 0;
                    var spend       = stat.spend;
                    var eCPM        = (impressions !== 0) ? (stat.spend * 1000) / impressions : 0;
                    var eCPC        = (clicks !== 0) ? spend / clicks : 0;

                    // total values
                    var totalClicks      = totalFilteredStats.clicks;
                    var totalImpressions = totalFilteredStats.impressions;
                    var totalCtr         = totalFilteredStats.ctr;
                    var totalSpend       = totalFilteredStats.spend;
                    var totalEcpm        = totalFilteredStats.eCPM;
                    var totalEcpc        = totalFilteredStats.eCPC;

                    // calculate percentage
                    var impressions_relativePercentage = (totalImpressions !== 0 ) ? impressions/totalImpressions*100  : 0;
                    var clicks_relativePercentage      = (totalClicks !== 0 ) ? clicks/totalClicks*100  : 0;
                    var ctr_inPercentage               = ctr * 100;

                    const statsMetricsType = this.props.statsMetricType;
                    const isRelativePercentage = this.props.isRelativePercentage ;
                    var spend_inPercentage             = (totalSpend !== 0) ? spend/totalSpend*100 : 0;
                    var eCPM_inPercentage              = (totalEcpm !== 0) ? eCPM/totalEcpm*100 : 0;
                    var eCPC_inPercentage              = (totalEcpc !== 0) ? eCPC/totalEcpc*100 : 0;
                    switch (statsMetricsType) {
                        case 'impressions':
                            _statsMetrics  = isRelativePercentage
                                ? numeral(impressions_relativePercentage).format('0,0.00') + '%'
                                : numeral(impressions).format('0,0');
                            _statsMetrics_numType = +impressions;
                            break;
                        case 'clicks':
                            _statsMetrics  = isRelativePercentage
                                ? numeral(clicks_relativePercentage).format('0,0.00') + '%'
                                : numeral(clicks).format('0,0');
                            _statsMetrics_numType = +clicks;
                            break;
                        case 'ctr':
                            _statsMetrics  = numeral(ctr_inPercentage).format('0,0.00') + '%';
                            _statsMetrics_numType = +ctr_inPercentage;
                            break;
                        case 'spend':
                            _statsMetrics  = isRelativePercentage
                                ? numeral(spend_inPercentage).format('0,0.0000') + '%'
                                : numeral(spend).format('$0,0.0000');
                            _statsMetrics_numType = +spend;
                            break;
                        case 'ecpm':
                            _statsMetrics  = isRelativePercentage
                                ? numeral(eCPM_inPercentage).format('0,0.0000') + '%'
                                : numeral(eCPM).format('$0,0.0000');
                            _statsMetrics_numType = +eCPM;
                            break;
                        case 'ecpc':
                            _statsMetrics  = isRelativePercentage
                                ? numeral(eCPC_inPercentage).format('0,0.0000') + '%'
                                : numeral(eCPC).format('$0,0.0000');
                            _statsMetrics_numType = +eCPC;
                            break;
                    } // End switch
                } else {
                    _name = _distinctValue;
                    _statsMetrics_numType = 0;

                    switch (statsMetricsType) {
                        case 'impressions':
                        case 'clicks':
                        case 'spend':
                        case 'ecpm':
                        case 'ecpc':
                            _statsMetrics  = isRelativePercentage
                                ? numeral(0).format('0,0.00') + '%'
                                : numeral(0).format('0,0');
                            break;
                        case 'ctr':
                            _statsMetrics  = numeral(0).format('0,0.00') + '%';
                        break;
                    } // End switch
                } // End if (stat)

                return  {
                    name: _name,
                    statsMetrics: _statsMetrics,
                    statsMetrics_numType: _statsMetrics_numType,
                    selected: _isSelected
                };
            });
        }
        return presentation;
    },

    render () {
        const configDimensions = this.props.configDimensions;
        const selected_interval = domainSetting.convertSelection_hoursToInterval(configDimensions.hour.selected);
        const selected_day_of_week = configDimensions.day_of_week;

        this._cache.selected = {
            'day_of_week': configDimensions.day_of_week.selected,
            'hour' : selected_interval
        };

        return (
            <div>
                <Heatmap
                    stats={this.props.stats}
                    campaignId={this.props.campaignId}

                    configDimensions = {configDimensions}
                    isLoading={this.props.isLoading}
                    presentation_group0={this.buildPresentation_group_interval()}
                    presentation_group1={this.buildPresentation_group_day_of_week()}
                    presentation_stats={this.buildPresentation_stats()}

                    onCheckboxSelected_group0={this.onCheckboxSelected_group0}
                    onCheckboxSelected_group1={this.onCheckboxSelected_group1}
                    onReset={this.handleReset}
                />
            </div>
        );
    },

    accret_heatmap (campaignId, dimension) {
        // Accret all cell values to a total sum so it can be use to caculate percentage

        const out = {};   // linting error `out` is never modified, use `const` instead
        const selected_flatten = this._cache.selectedItems_flatten;
        const dataMap = domainSetting.dataMap;
        const selected = this._cache.selected;

        const selectedLength = Object.keys(selected).length;
        const selected_name = _.map(selected_flatten, (item) => dataMap[item]  );
        const stats = this._cache.heatmapStats;
        if (selectedLength === 2) {
            let someSelected;
            someSelected = _.some(selected, function(item){ return item.length; });  // true if any of hour or day_of_week has selection
                if (stats) {
                    const total = _.reduce( stats, function( accretion, item){
                        const isSelected = _.contains( selected_name, item.group[dimension].toString() );
                        if ( isSelected  ){
                            var itemCtr = (item.impressions !==0 ) ? item.clicks/item.impressions : 0;
                            var itemEcpm = (item.impressions !==0 ) ? (item.spend * 1000) / item.impressions : 0;
                            var itemEcpc = (item.clicks !==0 ) ? item.spend / item.clicks : 0;
                            return {
                                clicks : accretion.clicks + item.clicks,
                                impressions :  accretion.impressions + item.impressions,
                                ctr: accretion.ctr + itemCtr,
                                spend: accretion.spend + item.spend,
                                eCPM: accretion.eCPM + itemEcpm,
                                eCPC: accretion.eCPC + itemEcpc
                            };
                        } else {
                            return accretion;
                        }
                    }, {clicks: 0, impressions: 0, ctr: 0, spend: 0, eCPM: 0, eCPC: 0 });
                    _.assign(out, total);
                }
        } else if (selectedLength ===1 ) {
            throw new Error('INTERNAL ERROR: heatmap is accreting bar graph data');
        }

        return out;
    }
});


function processData(rawData) {
    const processed = rawData;
    let dataGrouped;
    let dataFlatten;

    if (rawData) {
        const rawDataKeys  =  Object.keys(rawData);
        dataGrouped =  groupHoursIntoBins( 'hour|day_of_week', rawData['hour|day_of_week'] );
        if (dataGrouped) {
            dataFlatten =  flattenDayPartsWeekParts( 'hour|day_of_week', dataGrouped );
            return dataFlatten;
        } else {
            return void 0;
        }
    } else {
        return void 0;
    }
}

function groupHoursIntoBins( _dimensionName, dataDayPartsWeekParts ){
    var hourMap = domainSetting.hour.map; //@TODO
    var day_of_week = domainSetting.day_of_week.bin;
    var daysInWeek = _.map(Object.keys(day_of_week), function(item){ return day_of_week[item]; });

    /*
        Before grouping :
        -----------------
            dataDayPartsWeekParts = {
                "campaign_id":4431,
                "keys":["hour","day_of_week"],
                "stats":[
                    {"keys":{"hour":"1","day_of_week":2}, "clicks":366,"impressions":21711},
                    {"keys":{"hour":"4","day_of_week":5}, "clicks":322,"impressions":18520},
                     ...
                ],
                "distinctValues":{
                    "day_of_week":[4,3,2,1,0,6,5],
                    "hour":["0","1","2",  ...,  "22","23"]
                }
            };
        After grouping :
        ----------------
            dataDayPartsWeekParts = {
                "campaign_id":4431,
                "keys":["hour|day_of_week"],
                "stats":[
                    {"keys":{"hour":"0-5","day_of_week":2}, "clicks":366,"impressions":21711},
                    {"keys":{"hour":"6-11","day_of_week":2}, "clicks":366,"impressions":21711},
                     ...
                ],
                "distinctValues":{
                    "day_of_week":[0,1,2,3,4,5,6],
                    "hour":["0-5","6-11",  ... ,"18-24"]
                }
            };
    */

    var distinctValues_temp  = [];

    if ( dataDayPartsWeekParts  && dataDayPartsWeekParts.stats) {

        var stats = dataDayPartsWeekParts.stats;

        var dataDayPartsWeekParts_reconsctructed = {
            campaign_id: dataDayPartsWeekParts.campaign_id,
            keys: stats.keys,
            stats : [],
            distinctValues: { //@TODO rather the hard wire it here, should reconstruct this with value in setting
                day_of_week: [0, 1, 2, 3, 4, 5, 6],
                hour: ['0-5', '6-11', '12-17', '18-24']
            }
        };

        daysInWeek.forEach(function(_dayOfWeek){
            const dayOfWeek = +_dayOfWeek;

            _.forOwn(hourMap, function( hourMapItem, interval ){
                const bin_byInterval_forEachDay =  _.map(hourMap[interval], function(hour) {
                    const filteredResult = _.filter(stats, function(stat){
                        return (Number(stat.group.hour) === Number(hour)) && (Number(stat.group.day_of_week) === Number(dayOfWeek));
                    });

                    const out = (Object.prototype.toString.call(filteredResult) === '[object Array]' && filteredResult.length === 1)
                        ? filteredResult[0]
                        : null;     // either there is no result or the result is not unique

                    return out;
                });

                const stat_byinterval_forEachDay = bin_byInterval_forEachDay.reduce(function(accreted, item){
                    const out = ( item !== null )
                        ?  {
                            'group': { 'hour': interval, 'day_of_week': dayOfWeek },
                            'clicks': accreted.clicks + item.clicks,
                            'impressions': accreted.impressions + item.impressions,
                            'spend': accreted.spend + item.spend
                        }
                        :  {
                            'group': { 'hour': interval, 'day_of_week': dayOfWeek },
                            'clicks': accreted.clicks,
                            'impressions': accreted.impressions,
                            'spend': accreted.spend
                        };
                    return out;
                }, { 'keys': {}, 'clicks': 0, 'impressions': 0, 'spend': 0});

                dataDayPartsWeekParts_reconsctructed.stats.push(stat_byinterval_forEachDay);
            }); // }// END : interval loop
        }); // END : day loop

        // dataDayPartsWeekParts = dataDayPartsWeekParts_reconsctructed;
        return dataDayPartsWeekParts_reconsctructed;

    } else {
        return void 0;
    }
}

function flattenDayPartsWeekParts(_dimensionName, dataDayPartsWeekParts) {
    const dataDayPartsWeekParts_reconstructed = _.cloneDeep(dataDayPartsWeekParts);

    /*
        ===================
        = Flattening data =
        ===================

            Before flatten:
            ---------------
                campaignReportStats['hour|day_of_week'] = {
                    "campaign_id":4431,
                    "keys":["hour|day_of_week"],
                    "stats":[
                        {"keys":{"hour":"0-5","day_of_week":2}, "clicks":366,"impressions":21711},
                        {"keys":{"hour":"6-11","day_of_week":2}, "clicks":366,"impressions":21711},
                         ...
                    ],
                    "distinctValues":{
                        "day_of_week":[0,1,2,3,4,5,6],
                        "hour":["0-5","6-11",  ... ,"18-24"]
                    }
                };
            After Flatten:
            --------------
                campaignReportStats['hour|day_of_week'] = {
                    "campaign_id":4431,
                    "keys":["hour|day_of_week"],
                    "stats":[
                        {"keys":{"hour|day_of_week":"1|2"},"clicks":366,"impressions":21711},
                        {"keys":{"hour|day_of_week":"4|5"},"clicks":322,"impressions":18520},
                         ...
                    ],
                    "distinctValues":{
                        "hour|day_of_week":["1|2","4|5",  ...  "1|6","13|0"]
                    }
                };
    */

    var distinctValues_temp  = [];
    if (dataDayPartsWeekParts && dataDayPartsWeekParts.stats) {
        var stats = dataDayPartsWeekParts_reconstructed.stats;
        var parts = _dimensionName.split('|');
        stats.forEach( function(stat) {
            var keysToDelete = Object.keys(stat.group);
            var flattenKey = stat.group[parts[0]]+'|'+stat.group[parts[1]];
            distinctValues_temp.push(flattenKey);
            stat.group[_dimensionName] = flattenKey;

            keysToDelete.forEach(function(keyToDelete) { delete stat.group[keyToDelete]; });
        });
        dataDayPartsWeekParts_reconstructed.keys = [_dimensionName];
        dataDayPartsWeekParts_reconstructed.distinctValues = distinctValues_temp;
    }
    return dataDayPartsWeekParts_reconstructed;
}

function setHeatmapSelected_flatten(campaignId, dimensionName, dataNestingOrder, selected) {
    const _selected_flatten = [];
    const item_0 = 'day_of_week';
    const item_1 = 'hour';
    const dim_0 = domainSetting.day_of_week;
    const dim_1 = domainSetting.hour;
    const intervalToKey = domainSetting.hour.binNameToBinKey; // {0-5: "0", 6-11: "1", 12-17: "2", 18-24: "3"}
    const selected_0 = selected[item_0]; // selected['day_of_week']
    const selected_1 = selected[item_1]; // selected['hour']

    const isSomeSelected_0 = !!(selected_0.length)  && !!!(selected_1.length);
    const isSomeSelected_1 = !!!(selected_0.length) && !!(selected_1.length);
    const isBothSelected   = !!(selected_0.length)  && !!(selected_1.length);
    const isNonSelected    = !!!(selected_0.length)  && !!!(selected_1.length);

    const dayWeekIndex_to_dataIndex= function(index_hour, index_weekDay) {
        const _index_hour = +index_hour;
        const _index_weekDay = +index_weekDay;
        return ( _index_weekDay * 4) + _index_hour;
    }; // @TODO the number 4 should not be hard wired

    switch (true) {
        case isSomeSelected_0: // "day_of_week"
            // console.log('case 0: day_of_week');
            selected_0.forEach((el_0, index_0) => {
                Object.keys(dim_1.map).forEach((el_1, index_1) => {
                    // console.log(el_1, intervalToKey[el_1], index_1);
                    const index = dayWeekIndex_to_dataIndex( +intervalToKey[el_1], el_0 );
                    _selected_flatten.push(index);
                });
            });
            break;
        case isSomeSelected_1: // "hour"
            // console.log('case: hour');
            Object.keys(dim_0.map).forEach((el_0, index_0) => {
                // console.log(el_0, index_0, selected_1);
                selected_1.forEach((el_1, index_1) => {
                    const index = dayWeekIndex_to_dataIndex(el_1, el_0);
                    _selected_flatten.push(index);
                });
            });
            break;
        case isBothSelected:   // "day_of_week && "hour"
            // console.log('case: both is selected,');
            selected_0.forEach((el_0, index_0) => {
                selected_1.forEach((el_1, index_1) => {
                    const index = dayWeekIndex_to_dataIndex(el_1, el_0);
                    _selected_flatten.push(index);
                });
            });
            break;
        case isNonSelected:   //  non selected
            // console.log('case: none is selected,');
            Object.keys(dim_0.map).forEach((el_0, index_0) => {
                Object.keys(dim_1.map).forEach((el_1, index_1) => {
                    // console.log(el_1, intervalToKey[el_1], index_1);
                    const index = dayWeekIndex_to_dataIndex( +intervalToKey[el_1], el_0 );
                    _selected_flatten.push(index);
                });
            });
            break;
    }

    // configuration(campaignId).set(['dimensions', 'heatmap', dimensionName, 'selected_flatten', 'hour|day_of_week'], _selected_flatten);
    return _selected_flatten;
}



module.exports = DayWeekPartsGraph;
