<!-- background -->
<div class="time-units">
  {{#each units}}
    <span class='time-unit' data-index='{{id}}' title='{{title}}'>
      {{#if label}}
      <label>{{label}}</label>
      {{/if}}
    </span>
  {{/each}}
</div>

<div class="dividers">
</div>

<i class="add fa fa-plus"></i>
