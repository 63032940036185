<div class="panel-body">
    <div class="row">
        <div class="col-xs-12"><h4 class="pull-left">{{country_name}}</h4>

            <a href="#" class="text-muted pull-right"><i class="fa fa-close close"></i></a>

            <div class="pull-right">
                <div class="pull-right">
                    <label class="switch-light switch-ios">
                        {{#if is_custom}}
                            <input type="checkbox" checked>
                        {{else}}
                            <input type="checkbox"/>
                        {{/if}}
                        <span>
                            <span>AUTO</span>
                            <span>CUSTOM</span>
                        </span>
                        <a></a>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class="setup form-horizontal">
        <hr>
        <div class="form-group">
            <label class="col-xs-2 control-label">Include</label>

            <div class="col-xs-10">
                <div class="where include"></div>
            </div>
        </div>

        <div class="form-group">
            <label class="col-xs-2 control-label">Exclude</label>

            <div class="col-xs-10">
                <div class="where exclude"></div>
            </div>
        </div>
    </div>
</div>
