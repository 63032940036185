'use strict';

var menuTemplate    = require('./templates/menu');
var videoTemplate   = require('./templates/video');
var switchTemplate  = require('./templates/switch');
var fns             = require ('./fns');

Handlebars.registerHelper('format_number'           , fns.formatNumber);
Handlebars.registerHelper('format_number_thousands' , fns.formatNumberThousands );
Handlebars.registerHelper('format_percentage'       , fns.formatPercentage);
Handlebars.registerHelper('format_currency'         , fns.formatCurrency);
Handlebars.registerHelper('format_dimension'        , fns.formatDimension);
Handlebars.registerHelper('rid'                     , fns.randomId);
Handlebars.registerHelper('switch'                  , uiSwitch);
Handlebars.registerHelper('check_selected'          , checkSelected);
Handlebars.registerHelper('check_perm'              , checkPermission);
Handlebars.registerHelper('translate_perm'          , translatePermission);
Handlebars.registerHelper('preview'                 , previewAsset);
Handlebars.registerHelper('generate_menu'           , generateMenu);
Handlebars.registerHelper('humanize'                , fns.humanize);
Handlebars.registerHelper('text_ellipsis'           , fns.textEllipsize);
Handlebars.registerHelper('value'                   , fns.objValue);

// date related
Handlebars.registerHelper('simple_time_year'        , fns.simpleTimeYear);
Handlebars.registerHelper('simple_time'             , fns.simpleTime);
Handlebars.registerHelper('time_ago'                , fns.timeAgo);
Handlebars.registerHelper('nice_time'               , fns.niceTime);

Handlebars.registerHelper('get_country_name'        , fns.getCountryName);
Handlebars.registerHelper('get_region_name'         , fns.getRegionName);
Handlebars.registerHelper('get_week_day_label'      , fns.getWeekdayLabel);
Handlebars.registerHelper('not_empty'               , ifNotEmpty);
Handlebars.registerHelper('is_equal'                , ifIsEqual);
Handlebars.registerHelper('highlight'               , highlight);
Handlebars.registerHelper('contains'                , contains);
Handlebars.registerHelper('contains_string'         , containsString);
Handlebars.registerHelper('decode_IAB_category'     , fns.decodeIABCategory);
Handlebars.registerHelper('any'                     , any);
Handlebars.registerHelper('all'                     , all);
Handlebars.registerHelper('paragraphs'                     , paragraphs);
Handlebars.registerHelper('utc_short'                     , utcShort);
Handlebars.registerHelper('is_odd'                     , isOdd);
Handlebars.registerHelper('is_even'                     , isEven);

function isOdd(num) {
    num = _.parseInt(num);
    if (num % 2 === 1) {
        return _.last(arguments).fn(this);
    }
    return _.last(arguments).inverse(this);
}

function isEven(num) {
    num = _.parseInt(num);
    if (num % 2 === 0) {
        return _.last(arguments).fn(this);
    }
    return _.last(arguments).inverse(this);
}

function utcShort(timeStr) {
    return moment.utc(timeStr).format('YYYY-MM-DD HH:mm z');
}

function paragraphs(text) {
    var ps = _.map(text.split('\n'), function (p) {
        return '<p>' + p + '</p>';
    });

    return ps.join('');
}

function uiSwitch (clazz, pos, neg, posChecked) {
    var uid = _.uniqueId('switch_');
    return switchTemplate({
        uid: uid,
        pos: pos,
        neg: neg,
        pos_checked: !!posChecked,
        clazz: clazz
    });
}

function checkSelected (mine, his) {
    return mine === his ? 'selected' : '';
}

function checkPermission (actions, op) {
    return _.contains(actions, op) ? 'checked' : null;
}

function translatePermission (suspended) {
    return suspended ? 'true' : 'false';
}

// only preview asset
function previewAsset (obj) {
    _.defaults(obj, {type: 'video/mp4'});
    switch (obj.type) {
        case 'image':
            return '<img src="' + obj.preview_url + '"/>';
        case 'video':
            return videoTemplate(obj);
        case 'audio':
            return 'this is an audio';
        default:
            return 'Uploading... please be patient.';
    }
}

function generateMenu (obj) {
    return menuTemplate({menu_groups: obj});
}

function ifNotEmpty () {
    var args = _.toArray(arguments);
    var notEmpties = _.map(args.slice(0, -1), function (a) {return !_.isEmpty(a); });
    if (_.any(notEmpties)) {
        return _.last(args).fn(this);
    }
    return _.last(args).inverse(this);
}

function ifIsEqual () {
    var args = _.toArray(arguments);
    if (args.length > 2 && args[1] === args[0]) {
        return _.last(args).fn(this);
    } else {
        return _.last(args).inverse(this);
    }
}

function highlight (code) {
    code = code || '';
    return hljs.highlight('html', code).value;
}

// check if a is one of the arguments to the right
function contains (a, b, c /* ... */) {
    var args = _.toArray(arguments);

    if (_.contains(args.slice(1, -1), a)) {
        return _.last(args).fn(this);
    }

    return _.last(args).inverse(this);
}

function containsString (a, b) {

    if (_.contains(a, b)) {
        return _.last(arguments).fn(this);
    }
    return _.last(arguments).inverse(this);
}

function any() {
    var args = _.toArray(arguments);

    if (_.any(args.slice(0, -1))) {
        return _.last(args).fn(this);
    }

    return _.last(args).inverse(this);
}

function all() {
    var args = _.toArray(arguments);

    if (_.every(args.slice(0, -1))) {
        return _.last(args).fn(this);
    }

    return _.last(args).inverse(this);
}
