'use strict';

var app = require('scripts/core').app;

var langCodes = require('scripts/common/constants/language-codes');
var appCategoryTargeting = require('scripts/common/constants/app-category-targeting');
var fns = require('scripts/common/fns');

// bindings for ad form
var Bindings = {
    bindings: {

        // textfield
        '[data-id=name]'    : 'name',

        // textarea
        '[data-id=notes]': 'notes',

        // bootstrap-datetimepicker
        '.start': {
            observe: 'start',
            events: ['change'],

            // update the field
            update: function ($el, val) {
                // val is in ISO String
                if (_.isEmpty(val)) {
                    return;
                }

                // convert to user's timezone
                var timezone = this.model.get('timezone');

                // val from server, should be in ISO & UTC format
                var date = moment(val).tz(timezone);

                // show it
                $el.data('DateTimePicker').setDate(date);
            },

            // get date from picker
            getVal: function ($el) {

                if (this.$('[data-toggle=start-now]').is(':checked')) {
                    return 'now';
                }

                // this is moment object in user's timezone
                var date = $el.data('DateTimePicker').getDate();

                if (_.isEmpty(date)) {
                    return null;
                }

                // but it's supposed to be intepreted in user set timezone
                var timezone = this.$('[data-id=timezone] option:selected').val() || this.model.get('timezone');

                return fns.convertTimezoneOnly(date, timezone).second(0).toISOString();
            }
        },

        // bootstrap-datetimepicker
        '.end'                 : {
            observe: 'end',
            events: ['change'],
            update: function ($el, val) {
                // val is in ISO String
                if (_.isEmpty(val)) {
                    return;
                }

                var timezone = this.model.get('timezone');
                var date = moment(val).tz(timezone);

                $el.data('DateTimePicker').setDate(date);
            },

            getVal: function ($el) {
                // this is moment object in user's timezone
                var date = $el.data('DateTimePicker').getDate();

                if (_.isEmpty(date)) {
                    return null;
                }

                var timezone = this.$('[data-id=timezone] option:selected').val() || this.model.get('timezone');
                return fns.convertTimezoneOnly(date, timezone).seconds(59).toISOString();
            }
        },

        '[data-id=max_total_impressions]' : {
            observe: 'max_total_impressions',
            getVal: _getIntValFromInput,
            update: _setNumericValueForInput,
            events: ['keyup']
        },

        '[data-id=max_total_clicks]'      : {
            observe: 'max_total_clicks',
            getVal: _getIntValFromInput,
            update:  _setNumericValueForInput,
            events: ['keyup']
        },

        '[data-id=max_total_spend]'      : {
            observe: 'max_total_spend',
            getVal: _getIntValFromInput,
            update:  _setNumericValueForInput,
            events: ['keyup']
        },

        '[data-id=max_daily_impressions]' : {
            observe: 'max_daily_impressions',
            getVal: _getIntValFromInput,
            update: _setNumericValueForInput,
            events: ['keyup']
        },

        '[data-id=max_daily_clicks]'      : {
            observe: 'max_daily_clicks',
            getVal: _getIntValFromInput,
            update: _setNumericValueForInput,
            events: ['keyup']
        },

        '[data-id=max_daily_spend]'      : {
            observe: 'max_daily_spend',
            getVal: _getIntValFromInput,
            update: _setNumericValueForInput,
            events: ['keyup']
        },

        '[data-id=max_user_frequency]'    : {
            observe: 'max_user_frequency',
            getVal: function ($el) {
                return _toInt($el.val());
            },

            update: function ($el, val) {
                $el.val(val);
            },
            events: ['keyup']
        },

        '.available-creatives-region' : {
            observe: 'creative',
            getVal: function ($el) {
                var value = $el.find('.creative-choice.active').data('creative-id');
                if ( value === '' || value === void 0 || value === null ) {
                    return null;
                } else {
                    return value;
                }
            },
            update: function ($el, val) {
                var creative = this.options.campaign.creatives.get([val]);

                $el.find('.creative-choice').each(function () {
                    if ($(this).data('id') === val) {
                        $(this).addClass('active');
                    }
                });
            },
            events: ['change']
        },

        '[data-id=weekparts]'             : {
            observe: 'weekparts',
            getVal: function ($el, ev, options) {
                if ($el.parent().find('.switch-toggle [type=radio]:eq(0)').prop('checked')) {
                    return _.times(7, function () {return true;});
                } else {
                    var vals = $el.find('[type=checkbox]').map(function (i, el) { return $(el).prop('checked'); });
                    return _.take(vals, 7);
                }
            },

            update: function ($el, val, model, options) {
                if (val.length > 0 && !_.every(val)) {
                    $el.parent().find('.switch-toggle [type=radio]:eq(1)').click();
                    _.each(val, function (v, k) { $($el.find('[type=checkbox]').get(k)).prop('checked', v) ;});
                } else {
                    $el.find('[type=checkbox]').prop('checked', true);
                }
            },
            events: ['change']
        },

        '[data-id=timezone]': {
            observe: 'timezone',
            initialize: function ($el) {
                $el.select2();
            },
            selectOptions: {
                collection: function () {
                    return fns.getFormattedTimezones();
                }
            }
        },

        '.dayparts'      : {
            observe: 'dayparts',
            getVal: function ($el) {
                // need consider timezone
                if (this.sliderView) {
                    var convertedRanges = fns.convertDaypartsByTimezone(this.sliderView.getValues(), this.$('[data-id=timezone]').select2('val'), 'UTC');
                    return fns.mergeTimeranges(convertedRanges);
                } else {
                    return [];
                }
            },

            update: function ($el) {
                if (this.sliderView) {
                    var parts = fns.mergeTimeranges(fns.convertDaypartsByTimezone(this.model.get('dayparts'), 'UTC', this.model.get('timezone')));
                    this.sliderView.setValues(parts);
                }
            },
            events: ['change']
        },

        '[data-id=max_bid_cpm]'  : {
            observe: 'max_bid_cpm',
            getVal: function ($el) {
                return parseFloat($el.autoNumeric('get'));
            },
            update: _setNumericValueForInput,
            events: ['keyup']
        },

        '[data-id=billing_terms]'  : {
            observe: 'billing_terms',
            events: ['change']
        },

        '[data-id=billing_rate]': {
            observe: 'billing_rate',
            update: function ($el, val) {
                $el.autoNumeric('set', val);
            },

            getVal: function ($el) {
                return parseFloat($el.autoNumeric('get'));
            },
            events: ['keyup']
        },

        '[data-id=target_device_language]' : {
            observe: 'target_device_language',
            selectOptions: {
                collection: _.map(langCodes, function (label, val) {return {label: label, value: val}; }),
                defaultOption: {label: 'Any language', value: null}
            }
        },

        '[data-id=target_device_os]': {
            observe: 'target_device_os',
            update: function ($el, val) {
                $el.find('input[type=checkbox]').each(function () {
                    $(this).prop('checked', _.contains(val, $(this).attr('value')));
                });
            },

            getVal: function ($el) {
                return _.toArray($el.find('input[type=checkbox]:checked').map(function () {
                    return $(this).val();
                }));
            },
            events: ['click']
        },
        '.geotargets': {
            observe: 'geotargets',

            update: function () {
            },

            getVal: function () {
                if (this.geoTargetsView) {
                    return this.geoTargetsView.getValues();
                } else {
                    return this.model.get('geotargets');
                }
            },
            events: ['change']
        },
        '.geoboxes': {
            observe: 'geoboxes',

            update: function () {
            },

            getVal: function () {
                if (this.geoboxesView) {
                    return this.geoboxesView.getValues();
                } else {
                    return this.model.get('geoboxes');
                }
            },
            events: ['change']
        },
        '[data-id=target_age_groups]': {
            observe: 'target_age_groups',

            update: function ($el, val) {
                if (_.isEmpty(val)) {
                    $el.find('[type=checkbox]').prop('checked', true);
                    return;
                }
                _.each(val, function (group) {
                    $el.find('[type=checkbox][value=' + group + ']').prop('checked', true);
                });
            },

            getVal: function ($el) {
                // all checked, return empty
                if ($el.find('[type=checkbox]:not(:checked)').length === 0) {
                    return [];
                }
                // else return whatever are selected
                return $el.find('[type=checkbox]:checked').map(function () {return _.parseInt($(this).val());}).get();
            },

            events: ['change']
        },

        '[data-id=target_genders]': {
            observe: 'target_genders',

            update: function ($el, vals) {
                if (_.isArray(vals)) {
                    if (vals.length === 0 ) {
                        $el.find('input[type=checkbox]').prop('checked', true);
                    } else {
                        _.forEach(vals, function(val){
                            if (val === 'M') {
                                $el.find('[value=M]').prop('checked', true);
                            } else if (val === 'F') {
                                $el.find('[value=F]').prop('checked', true);
                            } else if (val === 'D') {
                                $el.find('[value=D]').prop('checked', true);
                            } else {
                                throw new Error( 'gender array is not empthy and contain unregonize value');
                            }
                        })
                    } // End else
                }
            },

            getVal: function ($el) {

                var checked = {};
                $el.find('input[type=checkbox]').each(function () {
                    checked[$(this).val()] = $(this).prop('checked');
                });

                if (checked['M'] && checked['F'] && checked['D']) {
                    return [];
                }

                if (checked['M'] && checked['F']) {
                    return ['M', 'F'];
                }

                if (checked['M'] && checked['D']) {
                    return ['M', 'D'];
                }

                if (checked['F'] && checked['D']) {
                    return ['F', 'D'];
                }

                if (checked['M']) {
                    return ['M'];
                }

                if (checked['F']) {
                    return ['F'];
                }

                if (checked['D']) {
                    return ['D'];
                }

                // this should not be possible
                // in case it occurs, return '', target both gender
                return [];
            },

            events: ['change']
        },

        '[data-id=target_app_categories]': {
            initialize: function ($el, model, options) {
                $el.select2({
                    placeholder: 'Select app categories here...',
                });
                $el.val(model.get('target_app_categories')).trigger('change')
            },
            observe: 'target_app_categories',
            selectOptions: {
                collection: function () {
                    var tmp_array = [];
                    for (var key in appCategoryTargeting) {
                        tmp_array.push({label: appCategoryTargeting[key], value:key})
                    }
                    var sortByLabel = _.sortBy(tmp_array, 'label');
                    return sortByLabel;
                }
            }
        },

        '[data-id=comment]': {
            observe: 'comment',
            getVal: function ($el) {
                return $el.val();
            }
        }
    }
};

function _toInt(val) {
    var parsed = _.parseInt(val);

    return _.isNaN(parsed) ? 0 : parsed;
}

function _toFloat(val) {
    var parsed = parseFloat(val);

    return _.isNaN(parsed) ? 0 : parsed;
}

function _setNumericValueForInput ($el, val) {
    $el.autoNumeric('init', {vMin: 0, vMax: 1e14});
    if (val !== 0) {
        $el.autoNumeric('set', val);
    }
}

function _getIntValFromInput ($el) {
    $el.autoNumeric('init', {vMin: 0, vMax: 1e14});
    return _toInt($el.autoNumeric('get'));
}

function _autoNumericGetFloatVal ($el) {
    return _toFloat($el.autoNumeric('get'));
}

_.each(Bindings['bindings'], function (config, selector) {
    if (_.isString(config)) {
        Bindings['bindings'][selector] = {observe: config};
    }

    Bindings['bindings'][selector]['updateModel'] = function (val, ev, config) {
        if (_.every([config.observe, val], _.isArray) && config.observe.length === val.length) {
            _.each(config.observe, function (attrName, idx) {
                this.model.trigger('updateFormValue', val[idx], attrName);
            }, this);
        } else {
            this.model.trigger('updateFormValue', val, config.observe);
        }
        return false;
    };
});

module.exports = Bindings;
