'use strict';

var flux = require('scripts/core/flux');
var campaigns = require('scripts/core/campaign-collection');


var _spendStats = {};

var SpendStats = Fluxxor.createStore({

    initialize: function (options) {

        this.bindActions(
            flux.constants.spendStats_fetch_success, 'onSpendStats_fetch_success'
        );

        _spendStats = [];
    },

    onSpendStats_fetch_success: function (stats) {
        _spendStats = stats;
        this.emit('change');
    },

    getSpendStats: function () {
        return _spendStats;
    }

});


flux.addStore('spend-stats', new SpendStats());


