<table class="table table-hover" style="margin-top: 10px;">
  <thead>
    <tr>
      <th>Role</th>
      <th>Status</th>
      <th>ID</th>
      {{#if can_access_orgs}}
      <th>Organization</th>
      {{/if}}
      <th></th>
    </tr>
  </thead>
  <tbody></tbody>
  <tfoot></tfoot>

</table>

{{#if can_create_role}}
<button class="btn btn-primary" data-action="show-create">
    <i class="fa fa-key"></i>
    Add a new Role
</button>
{{/if}}

