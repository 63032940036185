'use strict';

var flux = require('scripts/core/flux');

var Control = React.createClass({

    componentWillMount() {},

    componentWillReceiveProps() { },

    componentDidMount: function() { },

    componentDidUpdate: function(prevProps, prevState) {

        this.$el = $(this.getDOMNode());
        var $el = this.$el;

        $el.find('[data-stats-metrics-type]').removeClass('is-active');
        $el.find('[data-stats-metrics-type="'+this.props.control.statsMetricsType+'"]').addClass('is-active');

        $el.find('[data-stats-unit-selection]').removeClass('is-active');
        $el.find('[data-stats-unit-selection="'+this.props.control.statsMetricsUnitType+'"]').addClass('is-active');

        $el.find('[data-tab-type]').removeClass('is-active');
        $el.find('[data-tab-type="'+this.props.control.tabType+'"]').addClass('is-active');
    },

    onClick_statsMetricsTypeBtn (e) {
        var $target = $(e.target);
        var statsMetricsType = $target.closest('[data-stats-metrics-type]').attr('data-stats-metrics-type');

        flux.actions.reportPage.changeStatsMetricsType({
            campaignId: this.props.campaignId,
            statsMetricsType: statsMetricsType
        });
    },

    onClick_unitSelectorBtn (e) {
        var $target = $(e.target);
        var statsMetricsUnitType = $target.closest('[data-stats-unit-selection]').attr('data-stats-unit-selection');


        flux.actions.reportPage.changeStatsUnitSelection({
            campaignId: this.props.campaignId,
            statsMetricsUnitType: statsMetricsUnitType,
        });
    },

    onClick_tabSelectionBtn (e) {
        var tabType = $(e.currentTarget).attr('data-tab-type');

        flux.actions.reportPage.changeTabSelection({
            campaignId: this.props.campaignId,
            tabType: tabType
        });
    },

    render() {
        return (
            <div>
                <div className="am-reports-reportSelector">
                    <ul className="am-reports-reportSelector-tabs">
                        <li className="am-reports-reportSelector-tabs-item" data-tab-type="dimension" onClick={this.onClick_tabSelectionBtn}>
                            <i className="fa fa-th"></i>Explore
                        </li>
                        <li className="am-reports-reportSelector-tabs-item" data-tab-type="pivotTable" onClick={this.onClick_tabSelectionBtn}>
                            <i className="fa fa-table"></i>Pivot Table
                        </li>
                    </ul>
                    <div className="am-reports-unitSelector" onClick={this.onClick_unitSelectorBtn}>
                        <div className="am-reports-unitSelector-btn " data-stats-unit-selection="relativePercentage">%</div>
                        <div className="am-reports-unitSelector-btn "   data-stats-unit-selection="absolute">00</div>
                    </div>
                </div>

                <ul className="am-reports-metricSelector"  onClick={this.onClick_statsMetricsTypeBtn} >
                    <li className="am-reports-metricSelector-btn" data-stats-metrics-type="impressions">
                        <div className="am-reports-metricSelector-btn-inner">
                            <p className="am-reports-metricSelector-btn-key"><i className="fa fa-square"></i>Impressions</p>
                            <p className="am-reports-metricSelector-btn-value">{ this.props.stats.totalFilteredStats.presentationImpressions }</p>
                        </div>
                    </li>
                    <li className="am-reports-metricSelector-btn" data-stats-metrics-type="clicks">
                        <div className="am-reports-metricSelector-btn-inner">
                            <p className="am-reports-metricSelector-btn-key"><i className="fa fa-square"></i>Clicks</p>
                            <p className="am-reports-metricSelector-btn-value">{ this.props.stats.totalFilteredStats.presentationClicks }</p>
                        </div>
                    </li>
                    <li className="am-reports-metricSelector-btn" data-stats-metrics-type="ctr">
                        <div className="am-reports-metricSelector-btn-inner">
                            <p className="am-reports-metricSelector-btn-key"><i className="fa fa-square"></i>CTR</p>
                            <p className="am-reports-metricSelector-btn-value">{ this.props.stats.totalFilteredStats.presentationOverallCtr }</p>
                        </div>
                    </li>

                    <li className="am-reports-metricSelector-btn" data-stats-metrics-type="spend">
                        <div className="am-reports-metricSelector-btn-inner">
                            <p className="am-reports-metricSelector-btn-key"><i className="fa fa-square"></i>Spend</p>
                            <p className="am-reports-metricSelector-btn-value">{ this.props.stats.totalFilteredStats.presentationOverallSpend }</p>
                        </div>
                    </li>
                    <li className="am-reports-metricSelector-btn" data-stats-metrics-type="ecpm">
                        <div className="am-reports-metricSelector-btn-inner">
                            <p className="am-reports-metricSelector-btn-key"><i className="fa fa-square"></i>eCPM</p>
                            <p className="am-reports-metricSelector-btn-value">{ this.props.stats.totalFilteredStats.presentationOverallEcpm }</p>
                        </div>
                    </li>
                    <li className="am-reports-metricSelector-btn" data-stats-metrics-type="ecpc">
                        <div className="am-reports-metricSelector-btn-inner">
                            <p className="am-reports-metricSelector-btn-key"><i className="fa fa-square"></i>eCPC</p>
                            <p className="am-reports-metricSelector-btn-value">{ this.props.stats.totalFilteredStats.presentationOverallEcpc }</p>
                        </div>
                    </li>
                </ul>

            </div>
        );
    }
});

module.exports = Control;
