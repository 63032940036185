{{#if query_params.search}}
<input id="search-campaigns" class="form-control" placeholder="Search campaign name or id" type="text" value="{{query_params.search}}"/>
{{else}}

    {{#if query_params.id}}
        <input id="search-campaigns" class="form-control" placeholder="search name or id" type="text" value="{{query_params.id}}"/>
    {{else}}
        <input id="search-campaigns" class="form-control" placeholder="search name or id" type="text"/>
    {{/if}}
{{/if}}


<i class="fa fa-sort-desc search-toggle"></i>
<!--advance search-->
<div class="search-setup form-horizontal" id="dashboard-advance-search">

    <div class="form-group">
        <div class="col-xs-12">
            <h4>Advance Search</h4>
        </div>
    </div>

    <div class="form-group">
        <div class="col-xs-12">
            <label class="control-label">Creative type</label>
            <select class="form-control" data-search-option="creative-type">
                <option value="">--- choose creative type ---</option>
                <option value="banner">Banner</option>
                <option value="interstitial">Interstitial</option>
                <option value="video interstitial">Video Interstitial</option>
                <option value="native ad">Native Ad</option>
                <option value="mobile">Mobile</option>
                <option value="tablet">Tablet</option>
            </select>
        </div>
    </div>
    <div class="form-group">
        <div class="col-xs-12">
            <label class="control-label">IAB category</label>
            <select class="" data-search-option="category">
                <option value="">--- choose category ---</option>
                {{#each categories}}
                    <option value="{{key}}" {{#is_equal ../query_params.category key}}selected{{/is_equal}}>{{val}}</option>
                {{/each}}
            </select>
        </div>
    </div>
    <div class="form-group">
        <div class="col-xs-12">
            <label class="control-label">Status</label>
            <select class="form-control" data-search-option="status">
                <option value="">--- choose status ---</option>
                <option {{#is_equal query_params.status 'live'}}selected{{/is_equal}} value="live">Live</option>
                <option {{#is_equal query_params.status 'paused'}}selected{{/is_equal}} value="paused">Paused</option>
                <option {{#is_equal query_params.status 'pending'}}selected{{/is_equal}} value="pending">Pending</option>
                <option {{#is_equal query_params.status 'ended'}}selected{{/is_equal}} value="ended">Ended</option>
            </select>
        </div>
    </div>
    <div class="form-group">
        <div class="col-xs-12">
            <button class="btn btn-info" data-action="do-advance-search"><i class="fa fa-search"></i>Search</button>
            <button class="btn btn-default" data-action="reset">Reset</button>
        </div>
    </div>
</div>
