'use strict';


function adsReducer (state, adsAsArray) {
    const adsAsMap = _.reduce(adsAsArray, (map, ad) => ({
        ...map,
        [ad.id]: {
            attr: ad
        }
    }), {});

    return {
        ...state,
        entities: {
            ...state.entities,
            ...adsAsMap
        }
    };
}

module.exports = adsReducer;