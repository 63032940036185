'use strict';

var app = require('scripts/core').app;
var viewTemplate = require('../templates/forgot');
var Form = require('scripts/common/mixins/form');

module.exports = Mario.ItemView.extend({
    template: viewTemplate,
    events: {
        'click button': 'onSave'
    },

    onSave: function (e) {
        var name = this.$('.user-email').val();
        var req = $.ajax({
            type: 'post',
            url: 'password/forgot',
            data: JSON.stringify({email: name})
        });
        req.then(function (data) {
            toastr.success('An email has been sent containing a reset link.');
            Backbone.history.navigate('/signin', {trigger: true});
        }, function (e) {
            var resData = e.responseJSON;
            if (resData) {
                if (resData.status_code === 404 && resData.errors) {
                    toastr.error('The account couldn\'t be found. Please make sure your email address is correct.');
                }
            }
        });
        return false;
    },

    mixins: [Form]
});
