'use strict';

var dataStyle= {
    border: 'none'
};

var StatsPreview = React.createClass({

    getInitialState: function () {
        return {
            showType: this.props.showType || 'percentages'
        };
    },

    switchDisplay: function () {
        if (this.getShowType() === 'percentages') {
            this.setState({showType: 'values'});
        } else {
            this.setState({showType: 'percentages'});
        }
    },

    componentWillReceiveProps: function (nextProps) {
        this.setState({showType: nextProps.showType});
    },

    getShowType: function () {
        return this.state.showType || this.props.showType
    },

    render: function () {

        var tdTotal, tdToday;
        var type = this.getShowType();
        var d, goal;

        if (this.props.d != null) {
            d = this.props.d;
            goal = d.goal;
        } else {
            d = {goal: 'mixed'};
        }

        var formatHour = function (ts) {
            return numeral(moment.duration(ts).asHours()).format('0[.]0');
        };
        var formatDay = function (ts) {
            return numeral(moment.duration(ts).asDays()).format('0[.]0');
        };
        var formatPercent = function (ts) {
            return numeral(ts).format('0[.]00%');
        };
        var formatAbout = function (ts) {
            return numeral(ts).format('0,0[.]0a');
        };
        var formatExact = function (ts) {
            return numeral(ts).format('0,0');
        };

        if (type === 'percentages') {
            tdTotal =
                <td width="35%" className="text-right" style={dataStyle}>
                    {this.props.withLabel ? <strong>Total</strong> : ''}
                    <div className="lead toggable">{goal === 'mixed' ? '- %' : formatPercent(d.totalFilledPercentage)}</div>
                    <div className="text-small">{goal === 'mixed' ? '(mixed goals)' : 'of ' + formatExact(d.totalCount) + ' ' + goal}</div>
                    <div className="text-small text-muted">
                        <span className="toggable">{formatPercent(d.totalElapsePercentage)}</span> of {formatDay(d.totalDuration)} days
                    </div>
                </td>;

            if (d.totalElapsePercentage >= 1) {
                tdToday =
                    <td width="35%" className="text-right" style={dataStyle}>
                        <div className="lead">/</div>
                    </td>;
            } else {
                tdToday =
                    <td width="35%" className="text-right" style={dataStyle}>
    
                        {this.props.withLabel ? <strong>Today</strong> : ''}
                        <div className="lead toggable">{goal === 'mixed' ? '- %' : formatPercent(d.todayFilledPercentage)}</div>
                        <div className="text-small">{goal === 'mixed' ? '(mixed goals)' : 'of ' + formatExact(d.todayCount) + ' ' + goal}</div>
                        <div className="text-small text-muted">
                            <span className="toggable">{formatPercent(d.todayElapsePercentage)}</span> of {formatHour(d.todayDuration)} hrs
                        </div>
                    </td>;
            }

        } else {
            // numbers are explicit
            tdTotal =
                <td width="35%" className="text-right" style={dataStyle}>
                     {this.props.withLabel ? <strong>Total</strong> : ''}
                    <div className="lead toggable">{goal === 'mixed' ? 'N/A' : formatExact(d.totalFilledCount)}</div>
                    <div className="text-small">{ goal === 'mixed' ? '(mixed goals)' : 'of ' + formatExact(d.totalCount) + ' ' + goal} </div>
                    <div className="text-small text-muted">
                        <span className="toggable">{formatDay(d.totalElapse)}</span> of {formatDay(d.totalDuration)} days
                    </div>
                </td>;

            if (d.totalElapsePercentage >= 1) {
                tdToday =
                    <td width="35%" className="text-right" style={dataStyle}>
                        <div className="lead">/</div>
                    </td>;
            } else {
                tdToday =
                    <td width="35%" className="text-right" style={dataStyle}>
                     {this.props.withLabel ? <strong>Today</strong> : ''}
                        <div className="lead toggable">{goal === 'mixed' ? 'N/A' : formatExact(d.todayFilledCount)}</div>
                        <div className="text-small">{goal === 'mixed' ? '(mixed goals)' : 'of ' + formatExact(d.todayCount) + ' ' + goal } </div>
                        <div className="text-small text-muted">
                            <span className="toggable">{formatHour(d.todayElapse)}</span> of {formatHour(d.todayDuration)} hrs
                        </div>
                    </td>;
            }
        }

        return (
            <div>
                <table className="table table-condensed dashboard-stats">
                {this.props.withLabel ? '' :
                    <thead className="hidden-lg">
                        <tr>
                            <th width="35%" className="text-right">
                                <strong>Total </strong>
                            </th>
                            <th width="35%" className="text-right">
                                <strong>Today</strong>
                            </th>
                            <th width="30%" className="text-right">
                                <strong>CTR</strong>
                            </th>
                        </tr>
                    </thead>
                    }
                    <tbody>
                        <tr onClick={this.switchDisplay}>
                            {tdTotal}
                            {tdToday}
                            <td width="30%" style={dataStyle} className="text-right">
                                 {this.props.withLabel ? <strong>CTR</strong> : ''}
                                <div>
                                    <span className="lead">{formatPercent(d.totalCTR)}</span>
                                </div>
                                <div>
                                    <small>{formatPercent(d.todayCTR)} today</small>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

});


module.exports = StatsPreview;
