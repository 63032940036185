'use strict';

var map            = require('scripts/widgets/map');
var boxingTemplate = require('./templates/layout');

/**
 * @class Geobox
 *
 * Implements getValues and setValues interface
 *
 * @param {boolean} readOnly
 *
 */

var Geobox = Mario.ItemView.extend({
    className: 'widget-geoboxes hide',

    initialize: function (options) {
        this.readOnly = this.options.readOnly;
        this.collection = new Mario.Collection();

        this.listenTo(this.collection, 'reset', function (collection) {
            map.setValues(collection.toJSON());
        });

    },

    template: boxingTemplate,

    events: {
        'click .toggle-box [type=radio]' : 'toggleBox',
        'click .show-all-boxes': 'showAllBoxes',
        'click .remove-all-boxes': 'removeAllBoxes',
        'click .remove-selected-box': 'removeSelectedBox'
    },

    toggleBox: function (ev) {
        if ($(ev.currentTarget).val().match(/on/i)) {
            this.showMap();
        } else {
            this.hideMap();
        }
    },

    removeSelectedBox: function () {
        map.removeSelected();
    },

    removeAllBoxes: function () {
        map.clear();
    },

    showAllBoxes: function () {
        map.fitRectanglesInMap();
    },

    onShow: function () {

        this.mapEle = map.init(!this.readOnly);

        this.$('.geoboxes-map').html(this.mapEle);

        if (!this.readOnly) {
            this.mapEle.on('coords-updated', _.bind(function () {
                var coords = _.tail(arguments);
                this.collection.reset(coords, {silent: true});
                this.$el.trigger('change');
            }, this));
        }

        map.fitRectanglesInMap();
        map.resize();

        this.on('realshow', function () {
            this.$el.removeClass('hide').fadeIn();
            map.resize();
        });
    },

    onDestroy: function () {
        map.detach();
    },

    getCoords: function () {
        return map.getCoords();
    },

    templateHelpers: function () {
        return {
            read_only: this.readOnly
        };
    },

    getValues: function () {
        if (!this.readOnly && this.$('[type=radio]:eq(0)').prop('checked')) {
            return [];
        } else {
            return this.collection.toJSON();
        }
    },

    setValues: function (geoboxes) {
        this.collection.reset(geoboxes, {reset: true});

        if (!this.collection.isEmpty()) {
            if (!this.readOnly && this.$('[type=radio]:eq(0)').prop('checked')) {
                this.$('.toggle-box [type=radio]:eq(1)').click();
            } else {
                this.showMap();
            }
        }
    },

    showMap: function () {
        this.$('.geoboxes-map').removeClass('hide');
        map.resize();
        map.fitRectanglesInMap();
    },

    hideMap: function () {
        this.$('.geoboxes-map').addClass('hide');
    }
});


module.exports = Geobox;
