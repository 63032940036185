'use strict';

var app = require('scripts/core').app;
var helpers = require('scripts/common/helpers');
var formTemplate = require('./templates/form');
var Form = require('scripts/common/mixins/form');
var User = require('scripts/entities/collections/users').User;

/**
 * options
 * model<User>?
 * collection<Collection<User>>
 * roles<Collection<Role>>
 */

var UserForm = Mario.ItemView.extend({
    className: 'user-form form-horizontal',
    initialize: function (options) {
        if (!this.model) {
            this.model = new User(null, {urlRoot: this.collection.url});
        }
    },

    template: formTemplate,

    onShow: function () {
        this.stickit();
        Backbone.Validation.bind(this);
    },

    onDestroy: function () {
        Backbone.Validation.unbind(this);
    },

    onSave: function (ev) {
        var that = this;
        var attrs = helpers.stickitGetValues.call(this, this.model.keys());

        // if (!this.model.isNew()) {
        //     attrs = _.omit(attrs, 'password');
        // }

        this.model.save(attrs, {
            wait: true,
            patch: !this.model.isNew(),
            success: function (model) {
                that.collection.set([model], {remove: false});
                toastr.success('An account activation email has been sent to ' + attrs.email + '.');
                globalCh.trigger('popup:close');
            },

            error: function (m, resp, options) {
                if (resp.status === 409) {
                    toastr.error('Conflict: User already exists.');
                }
            }
        });
        return false;
    },

    templateHelpers: function () {
        return {
            roles: this.options.roles && this.options.roles.toJSON(),
            user: this.model.toJSON(),
            can_access_roles: app.can('list', 'view', 'roles'),
            can_suspend_user: app.can('suspend', 'users')
        };
    },

    bindings: {
        '[data-id=email]'      : 'email',
        // '[data-id=password]'   : 'password',
        '[data-id=first_name]' : 'first_name',
        '[data-id=last_name]'  : 'last_name',
        // '[data-id=twitter]'    : 'twitter',
        '[data-id=phone]'      : 'phone',
        '[data-id=suspended]'  : 'suspended',
        '[data-id=role]'       : {
            observe: 'role',
            selectOptions: {
                collection: function () {
                    var roles = this.options.roles.map(function (r) {
                        return {label: r.get('name'), value: r.id};
                    });
                    return roles;
                },

                defaultOption: {
                    label: 'Choose a role ...',
                    value: null
                }
            },
            onGet: function (val, config) {
                if (config.view.$el.is('.user-form')) {
                    return val;
                } else {
                    if (this.options.roles.get(val)) {
                        return this.options.roles.get(val).get('name');
                    }
                    return '';
                }
            }
        }
    },

    onCancel: function () {
        globalCh.trigger('popup:close');
    },

    behaviors: {
        Form: {
            autoAddCancelButton: true
        }
    }
});

module.exports = UserForm;
