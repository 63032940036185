{{#if group_count}}
<td rowspan="{{group_count}}">{{{group_name}}}</td>
{{/if}}


<td><a href="#" data-toggle="popover" data-trigger="click">#{{id}} {{name}}</a></td>

{{#if impression_target_disabled}}
    <td class="disabled"></td>
{{else}}
    <td>{{{format_number_thousands max_total_impressions}}}</td>
{{/if}}

{{#if impression_daily_cap_disabled}}
    <td class="disabled"></td>
{{else}}
    <td>{{format_number_thousands max_daily_impressions}}</td>
{{/if}}

{{#if click_target_disabled}}
    <td class="disabled"></td>
{{else}}
    <td>{{format_number_thousands max_total_clicks}}</td>
{{/if}}

{{#if click_daily_cap_disabled}}
    <td class="disabled"></td>
{{else}}
    <td>{{format_number_thousands max_daily_clicks}}</td>
{{/if}}

