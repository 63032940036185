[
    {
        "id": "CA",
        "text": "Canada"
    },
    {
        "id": "AE",
        "text": "United Arab Emirates"
    },
    {
        "id": "GB",
        "text": "United Kingdom"
    },
    {
        "id": "US",
        "text": "United States"
    },
    {
        "id": "DZ",
        "text": "Algeria"
    },
    {
        "id": "AO",
        "text": "Angola"
    },
    {
        "id": "AU",
        "text": "Australia"
    },
    {
        "id": "BH",
        "text": "Bahrain"
    },
    {
        "id": "TD",
        "text": "Chad"
    },
    {
        "id": "DK",
        "text": "Denmark"
    },
    {
        "id": "EG",
        "text": "Egypt"
    },
    {
        "id": "DE",
        "text": "Germany"
    },
    {
        "id": "GH",
        "text": "Ghana"
    },
    {
        "id": "ID",
        "text": "Indonesia"
    },
    {
        "id": "IQ",
        "text": "Iraq"
    },
    {
        "id": "JO",
        "text": "Jordan"
    },
    {
        "id": "KE",
        "text": "Kenya"
    },
    {
        "id": "KW",
        "text": "Kuwait"
    },
    {
        "id": "LB",
        "text": "Lebanon"
    },
    {
        "id": "LY",
        "text": "Libya"
    },
    {
        "id": "MR",
        "text": "Mauritania"
    },
    {
        "id": "MA",
        "text": "Morocco"
    },
    {
        "id": "MU",
        "text": "Mauritius"
    },
    {
        "id": "NZ",
        "text": "New Zealand"
    },
    {
        "id": "NO",
        "text": "Norway"
    },
    {
        "id": "OM",
        "text": "Oman"
    },
    {
        "id": "PK",
        "text": "Pakistan"
    },
    {
        "id": "PS",
        "text": "Palestine"
    },
    {
        "id": "QA",
        "text": "Qatar"
    },
    {
        "id": "SA",
        "text": "Saudi Arabia"
    },
    {
        "id": "SD",
        "text": "Sudan"
    },
    {
        "id": "SY",
        "text": "Syria"
    },
    {
        "id": "SE",
        "text": "Sweden"
    },
    {
        "id": "TN",
        "text": "Tunisia"
    },
    {
        "id": "TR",
        "text": "Turkey"
    },
    {
        "id": "YE",
        "text": "Yemen"
    },
]