'use strict';



function routeReducer (state = {}, action) {

    switch (action.type) {
        case 'router_navigate':
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}



module.exports = routeReducer;