'use strict';

var app = require('scripts/core').app;
var AuditsView = require('./list');
var Audits = require('scripts/entities/collections/audits');

var Controller = Mario.Controller.extend({
    showAudits: function () {
        var audits = new Audits();
        var page = globalCh.request('available-page', {main_url: '/audits'});
        page.show(function () {
            return new AuditsView({collection: audits});
        });
    }
});

var Router = Mario.AppRouter.extend({
    appRoutes: {
        'audits'     : 'showAudits'
    }
});

var cont = new Controller();
exports.start = function () {
    debug('start audits component');
    this.router = new Router({controller: cont});
    return {
        add_to_menu: true,
        menu_config: {icon: 'fa-check-square', name: 'Audits'},
        key        : 'audits',
        matcher    : /\/audits/,
        url        : '/audits'
    };
};
