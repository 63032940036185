'use strict';


var CategoriesSelectorView = require('./categories-selector');
var CategorySelectorWrapper = React.createClass({

    componentDidMount() {
        var $el = $(this.getDOMNode());
        this.region = new Mario.Region({el: $el.find('[data-id=iab_categories]')});
        this.categoriesSelector = new CategoriesSelectorView({model: this.model});
        this.region.show(this.categoriesSelector);
        this.categoriesSelector.setSelected(this.props.categories);

        this.props.ch.reply('campaign:form:iab_categories',
            ()=>this.categoriesSelector.selectedCategories.map(function (cat) {return cat.id;}));
    },

    componentWillUnmount() {
        this.props.ch.stopReplying('campaign:form:iab_categories');
        this.region.destroy();
    },

    onFocus() {
        this.categoriesSelector.showMenu();
    },

    render() {
        return (
            <div className="form-group">
                <label htmlFor="campaign-iab_categories">Brand Category
                    <i className="fa fa-asterisk text-danger"></i>
                </label>

                <div data-id="iab_categories" style={{width: '100%'}} onFocus={this.onFocus} tabIndex="3"></div>
                <div className="help-text">{"Select at least one IAB category / sub-category which best describes the campaign content. Certain categories like 'Card Games' and 'Cocktails/Beer' may limit traffic to age-restricted apps."}</div>
            </div>
        )
    }
});


module.exports = CategorySelectorWrapper;
