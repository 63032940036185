{{#if embed}}
    <div class="toolbar">
        <div class="toolbar-actions pull-right">
            <div class="toolbar-actions-left refresh-button-region">
            </div>
        </div>
    </div>

    <div class="notice-region pull-right"></div>

    {{#if can_update}}
        <button class="btn btn-primary upload {{#unless embed}} pull-right {{/unless}}" data-type="assets">
            <i class="fa fa-upload"></i> Upload Assets
        </button>
        <input type="file" multiple class="hidden" name="asset" accept="{{formats}}"/>
    {{/if}}

    <div class="assets-region embed"></div>
{{else}}

    <div class="notice-region pull-right"></div>

    <h3>Documents</h3>
    <div class="documents-region"></div>

    <br>
    <br>
    <br>
    {{#if can_update}}
        <input type="file" multiple class="hidden" name="asset" accept="{{formats}}"/>
    {{/if}}

    <h3>Assets</h3>
    <div class="assets-region"></div>


{{/if}}
