'use strict';
var paginatorTemplate = require('./paginator');

var PaginatorView = Mario.ItemView.extend({

    template: paginatorTemplate,

    className: 'paginator',

    events: {
        'click .page-number': 'onClickPageNumber',
        'click .next-page': 'onClickNextPage',
        'click .prev-page': 'onClickPrevPage',
        'change select': 'onChangePageSize'
    },

    initialize: function () {
        this.on('update:pager', function () {
            this.triggerMethod('before:render');
            this.render();
        }, this);
    },

    onBeforeRender: function () {
        this.$el.toggle(!!this.collection.length);
    },


    onChangePageSize: function () {
        var size = this.$('option:selected').val();
        this.collection.setState('pageSize', _.parseInt(size));
    },

    onClickNextPage: function () {
        if (this.collection.hasNextPage()) {
            this.gotoPage(this.collection.state.currentPage + 1);
        }
        return false;
    },

    onClickPrevPage: function () {
        if (this.collection.hasPreviousPage()) {
            this.gotoPage(this.collection.state.currentPage - 1);
        }
        return false;
    },

    onClickPageNumber: function (ev) {
        var pageNum = $(ev.currentTarget).data('page');
        this.gotoPage(_.parseInt(pageNum) - 1);
        return false;
    },

    gotoPage: function (page) {
        this.collection.setState('currentPage', page);
    },

    templateHelpers: function () {
        var state = this.collection.state;
        var pages = this.getPages();

        return _.extend(state, {
            from: state.currentPage * state.pageSize + 1,

            to: state.currentPage + 1 === state.totalPages ?
                state.totalRecords : (state.currentPage + 1) * state.pageSize,

            pages: pages
        });
    },

    getPages: function () {
        var pages;
        var state = this.collection.state;
        var cur = ~~state.currentPage, totalPages = ~~state.totalPages;

        if (totalPages < 10) {
            pages = this.getPageArr(totalPages, cur);
        } else {
            var dotLeft = false, dotRight = false;

            if (cur > 5) {
                dotLeft = true;
            }

            if (totalPages - cur > 5) {
                dotRight = true;
            }

            var left = [], middle = [], right = [];
            if (dotLeft) {
                left = this.getPageArr(2).
                    concat([{dot: true}]).
                    concat(this.getPageArr(cur - 1, null, cur - 2));
            } else {
                left = this.getPageArr(cur - 1);
            }

            if (dotRight) {
                right = this.getPageArr(cur + 3, null, cur + 2).
                    concat([{dot: true}]).
                    concat(this.getPageArr(totalPages, null, totalPages - 2));
            } else {
                right = this.getPageArr(totalPages, null, cur + 2);
            }

            middle = this.getPageArr(cur + 2, cur, cur - 1);

            pages = left.concat(middle).concat(right);
        }
        return pages;
    },

    getPageArr: function (total, current, start) {
        start = start || 0;
        total = Math.min(total, ~~this.collection.state.totalPages);
        start = Math.max(start, 0);

        return _.map(_.range(start + 1, total + 1), function (n) {
            return {num: n, selected: n - 1 === current}; });
    }
});



module.exports = PaginatorView;
