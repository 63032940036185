'use strict';

var flux = require('scripts/core/flux');

var _organizations = {};

var OrganizationsStore = Fluxxor.createStore({

    initialize: function (options) {

        this.bindActions(
            flux.constants.organizationProfile_fetch_success, 'onOrganizationProfile_fetch_success'
        );
    },

    onOrganizationProfile_fetch_success: function (organizationalProfile) {
        _organizations[organizationalProfile.id] = organizationalProfile;

        this.emit('change');
    },

    getOwnOrganization: function () {
        var ownOrgId = flux.store('session').getOrganizationalId();

        return _organizations[ownOrgId];
    }
});

flux.addStore('organizations', new OrganizationsStore());
