'use strict';




// const router = new Mario.AppRouter({
//     controller: {
//         bar: function () {
//             console.log('bar bar bar');
//         }
//     },
//     appRoutes: {
//         'bar': 'bar'
//     }
// });

// module.exports = router;