'use strict';

var app = require('scripts/core').app;
var showTemplate = require('./templates/show');
var optionTemplate = require('./templates/option');
var permTemplate = require('./templates/perm-row');
var Roles = require('scripts/entities/collections/roles');


/**
 * options
 * model<Permission>
 * role<Role>
 * combinedPermissions<Array<{name<String>, campaignOnly<Boolean>}>>
 */

var PermissionRow = Mario.ItemView.extend({
    tagName: 'tr',
    template: permTemplate,

    templateHelpers: function () {
        // make sure the
        var perms = this.options.combinedPermissions.map(function (p) {
            return _.extend({}, p, {
                checked  : _.contains(this.model.get('actions'), p.name),
                disabled : this.options.role.get('_locked') ||
                    p.campaignOnly &&
                    this.model.get('resource') !== 'campaigns'
            });
        }, this);
        return {
            perms: perms,
            can_update_roles: app.can('update', 'roles')
        };
    },

    events: {
        'change [type=checkbox]' : 'permChanged'
    },

    permChanged: function () {
        var newConfig = this.$('[type=checkbox]').map(
            function (i, e) {
                return {action: $(e).data('permission'), checked: $(e).prop('checked')};
            });
        this.model.set('actions', _.map(
            _.filter(
                newConfig,
                function (n) {
                    return n.checked;
                }),
            function (f) {
                return f.action;
            }));
    }
});

/**
 * options
 * model<Resource>
 */

var ResourceOption = Mario.ItemView.extend({
    tagName: 'option',
    template: optionTemplate
});

/**
 * options
 * collection<Collection<Resource>>
 */

var ResourceSelect = Mario.CollectionView.extend({
    tagName: 'select',
    className: 'form-control ',
    childView: ResourceOption,
    events: {
        'change' : 'addResource'
    },

    addResource: function () {
        var index = this.$('option:selected').index();
        if (index > 0) {
            this.collection.remove(this.collection.at(index));
        }
    }
});


/**
 * options
 * model<Role>
 * resources<Collection<Role>>
 */

module.exports = Mario.CompositeView.extend({
    template: showTemplate,
    tagName: 'td',

    childViewContainer: 'tbody',
    childView: PermissionRow,
    childViewOptions: function () {
        return {
            combinedPermissions: this._combinedPermissions,
            role: this.model
        };
    },

    attributes: {
        colspan : 5
    },

    initialize: function (options) {
        this._standardPermissions = Roles.STANDARD_PERMISSIONS;
        this._campaignPermissions = Roles.CAMPAIGN_PERMISSIONS;

        var campaignOnlyPerms = _.difference(this._campaignPermissions, this._standardPermissions);

        this._combinedPermissions = _.map(

            _.union(this._standardPermissions, this._campaignPermissions),

            function (p) {
                return {name: p, campaignOnly: _.contains(campaignOnlyPerms, p)};
            }

        );

        this.collection = this.permissions = new Mario.Collection(this.model.get('permissions'));

        this.listenTo(this.permissions, 'add change', function () {
            this.$el.addClass('changed');
        });

        this.listenTo(this.permissions, 'reset', function () {
            this.$el.removeClass('changed');
            this.renderUnselectedResources();
        });

    },

    getUnselectedResources: function () {
        var selectedResources = this.permissions.map(
            function (p) {
                return p.get('resource');
            });
        var unselectedResource = this.options.resources.filter(
            function (r) {
                return !_.contains(selectedResources, r.get('name'));
            });
        unselectedResource = new Mario.Collection(unselectedResource);
        if (_.isEmpty(unselectedResource)) {
            unselectedResource.unshift({name: 'No more resource to add'});
        } else {
            unselectedResource.unshift({name: 'Add a new resource'});
        }
        return unselectedResource;
    },

    renderUnselectedResources: function () {
        var unselectedResource = this.getUnselectedResources();

        var resourceSelect = new ResourceSelect({
            collection: unselectedResource
        });

        this.selectResourceRegion.show(resourceSelect);
        this.listenTo(unselectedResource, 'remove', function (m) {
            this.permissions.add({resource: m.get('name'), actions: []});
        });
    },

    onRender: function () {
        this.selectResourceRegion = new Mario.Region({el: '.select-resource'});
    },

    onShow: function () {
        if (app.can('update', 'roles')) {
            this.renderUnselectedResources();
        }
    },

    templateHelpers: function () {
        return {
            standardPermissions: this._standardPermissions,
            campaignPermissions: this._campaignPermissions,
            combinedPermissions: this._combinedPermissions,
            can_update_roles: app.can('update', 'roles'),
            can_access_resources: app.can('list', 'view', 'resources'),
            can_delete_role: app.can('delete', 'roles'),
            role: this.model
        };
    },

    events: {
        'click [data-action=save]' : 'saveRoles',
        'click [data-action=cancel]' : 'cancel',
        'click [data-action=delete]' : 'delete'
    },

    delete: function () {
        this.model.destroy();
    },

    cancel: function () {
        this.permissions.reset(this.model.get('permissions'), {reset: true});
    },

    saveRoles: function () {
        var that = this;
        var newConfigs = _.map(this.permissions.toJSON(), function (p) {return _.pick(p, 'resource', 'actions'); });
        // remove permission with empty actions
        newConfigs = _.filter(newConfigs, function (n) {
            return !_.isEmpty(n.actions);
        });
        this.model.save('permissions', newConfigs, {
            patch: true,
            success: function (model) {
                that.permissions.reset(model.get('permissions'), {reset: true});
                toastr.success('Permissions are saved successfully.');
            }
        });
    }
});
