<!-- file: admin/parts/organizations/templates/creative.hbs -->
<div class="am-admin-creative--tile" >

    <div class="am-admin-creative--tile-header">
        <h4 class="tile-title">{{humanize format}}</h4>
    </div>


    <div class="am-admin-creative--tile-body">

        <div class="body-control">
            <div class="am-admin-creative--switch">
                <span>Enabled:</span>
                {{#if can_update}}
                <span data-id="allow_create">
                    {{{switch "switch-console-small" "Yes" "No" allow_create}}}
                </span>
                {{else}}
                <label class="control-label value">
                    {{#if allow_create}}
                        Yes
                    {{else}}
                        No
                    {{/if}}
                </label>
                {{/if}}
            </div>
            <div class="am-admin-creative--switch">
                <span>Auto approve:</span>
                {{#if can_update}}
                <span data-id="auto_approve">
                    {{{switch "switch-console-small" "Yes" "No" auto_approve}}}
                </span>
                {{else}}
                <label class="control-label value">
                    {{#if auto_approve}}
                        Yes
                    {{else}}
                        No
                    {{/if}}
                </label>
                {{/if}}
            </div>
        </div>

        <div class="body-rates rates hide">

            <div class="rate-header">
                <div class="c-1">
                </div>
                <div class="c-2">
                    Default CPM
                </div>
                <div class="c-3">
                    Margin
                </div>
            </div>
            {{#each rates}}
                {{#if ../can_update}}
                    <div data-rate-can-update="true">
                        <div class="c-1">
                            <span class="zzcontrol-label zzcontrol-label-size" data-size="{{size}}">{{size}}</span>
                        </div>
                        <div class="c-2">
                            <input type="text" value="{{default_max_bid_cpm}}" class="ZZform-control monetary" data-id="default_max_bid_cpm" data-size="{{size}}" disabled >
                        </div>
                        <div class="c-3">
                            <input type="text" value="{{rate}}" class="ZZform-control monetary" data-id="rate" data-size="{{size}}" disabled>
                        </div>
                    </div>
                {{else}}
                    <div data-rate-can-update="false">
                        <div class="c-1 text-righht">
                            <span class="zzcontrol-label">{{size}}</span>
                        </div>
                        <div class="c-2">
                            <label class="zzcontrol-label value">
                                {{format_currency form-control monetary}}
                            </label>
                        </div>
                        <div class="c-3">
                            <label class="zzcontrol-label value">
                                {{format_currency rate}}
                            </label>
                        </div>
                    </div>
                {{/if}}
            {{/each}}
        </div>
    </div>


    <div class="am-admin-creative--tile-footer">

        <div class="footer-control-show">
            <div class="btn btn-link" data-action="show-rates">show size rates</div>
        </div>

        <div class="footer-control-hide hide">
            <div class="btn btn-link" data-action="hide-rates">hide size rates</div>

            {{#if can_update}} {{#unless is_new}}

            <div class="edit hide">
                <button class="btn btn-success" data-action="save">Save</button>
                <a class="btn btn-link" href="#" data-action="cancel">cancel</a>
            </div>

            <div class="edit-creative">
                <div class="">
                    <button class="btn btn-success" href="#" data-action="editCreative">Edit</button>
                </div>
            </div>

            {{/unless}} {{/if}}
        </div>
    </div>

</div>
