'use strict';
var flux = require('scripts/core/flux');
var http = require('scripts/core/http');

flux.addActions({

    spendStats: {

        fetch: function () {
            var self = this;

            var organizationProfile = flux.store('profile').getOrganizationalProfile();

            http.get('organizations/' + organizationProfile.id + '/spend')
                .then(function (spendStats) {
                    self.dispatch(
                        flux.constants.spendStats_fetch_success,
                        spendStats
                    );
                })
                .done();
        }
    }
});