'use strict';
var PrivateChannel = require('scripts/common/mixins/private-channel');
var env = require('scripts/env');
var SANDBOX_URL = env.PREVIEW_ENDPOINT;
var hiddenTemplate = require('scripts/components/campaigns/parts/creatives/templates/hidden-form');

var DropdownMenu = require('scripts/widgets/dropdown-menu');
var Status = require('scripts/widgets/status');
var Simulator = require('scripts/widgets/simulator');
var SwitchToggle = require('scripts/widgets/toggle-switch');
var CampaignForm = require('./form-jsx');
var CampaignSetupRegion = require('./setup');
var CampaignDetailRegion = require('./detail');
var CampaignHistory = require('./history');
var flux = require('scripts/core/flux');
var Progressv2 = require('scripts/components/v2/containers/campaigns/progress');

var CampaignView = React.createClass({
    mixins: [PrivateChannel],

    getInitialState() {
        var selectedView = {
            'ads':0,
            'progress':1,
            'report':2,
        };

        return {
            section: this.props.section,
            sectionId: this.props.sectionId,
            isEditingCampaign: false,
            showHistory: false,
            selectedView: selectedView[this.props.section] || 0,
            isLoading: true
        };
    },

    componentDidMount() {
        var campaign = this.props.campaign;
        this.privateCh.listenToOnce(campaign, 'sync', ()=>this.setState({isLoading: false}));
        this.privateCh.listenTo(campaign, 'change', ()=>this.setState({json: campaign.toJSON()}));
    },

    componentDidUpdate() {
        // animate opacity
        var $el = $(this.getDOMNode());
        $el.animate({opacity: 1, display: 'block'});

        this.configureMenu();
    },

    componentWillMount(){
        var campaign = this.props.campaign;
        if (campaign != null) {
            this.setState({json: campaign.toJSON()});
        }

        this.configureMenu();
    },

    configureMenu() {
        var campaign = this.props.campaign;
        if (campaign != null) {
            if ( !campaign.ads || !campaign.ads.length ) {
                this.menuPermission = {
                    'overview': true,
                    'progress': false,
                    'progress-v2': false,
                    'analytic': false,
                    'report': false
                };
            } else {
                this.menuPermission = {
                    'overview': true,
                    'progress': true,
                    'progress-v2': true,
                    'analytic': true,
                    'report':true
                };
            }
        }
        this.menuItems = [
            {enable: this.menuPermission['overview'], keyText: 'overview', keyIndex: 0, icon: 'fa-home' , name: 'Overview', url: `/campaigns/${campaign.id}`},
            {enable: this.menuPermission['progress'], keyText: 'progress', keyIndex: 1, icon: 'fa-tasks', name: 'Progress (Legacy)', url: `/campaigns/${campaign.id}/progress`},
            {enable: this.menuPermission['progress-v2'], keyText: 'progress-v2', keyIndex: 2, icon: 'fa-refresh', name: 'Real-Time Delivery', url: `/campaigns/${campaign.id}/delivery`},
            {enable: this.menuPermission['analytic'], keyText: 'analytics', keyIndex: 3, icon: 'fa-area-chart', name: 'Analytics', url: `/campaigns/${campaign.id}/report`},
            {enable: this.menuPermission['report'], keyText: 'report', keyIndex: 4, icon: 'fa-external-link', name: 'Report', url: `https://advertiser.engagefront.com/campaigns/${campaign.id}`,  dataBypass: 'redirect' }
        ];
    },
    componentWillReceiveProps(nextProps) {
        // Deciding state for dropdown menu
        var keyTextToSearch =
            nextProps.section === 'progress' ? 'progress' :
            nextProps.section === 'delivery' ? 'progress-v2' :
            nextProps.section === 'report' ? 'analytics' : 'overview';
        var currentMenuItem = _.findWhere( this.menuItems, {keyText: keyTextToSearch});

        this.setState({selectedView: currentMenuItem.keyIndex});
    },

    componentWillUnmount() {
    },

    onEditCampaign() {
        this.setState({isEditingCampaign: true});
    },

    onClickProgress() {
        Backbone.history.navigate(`/campaigns/${this.props.campaign.id}/progress`, {trigger: true});
    },

    onClickHistory() {
        this.setState({showHistory: true});
    },

    onCloseHistoryPopup() {
        this.setState({showHistory: false});
    },

    onSaveCampaign() {

        var name = this.props.ch.request('campaign:form:name');
        var advertiser_domain = this.props.ch.request('campaign:form:advertiser_domain');
        var notes = this.props.ch.request('campaign:form:notes');
        var comment = this.props.ch.request('campaign:form:comment');
        var organization = this.props.ch.request('campaign:form:organization');
        var iab_categories = this.props.ch.request('campaign:form:iab_categories');
        var campaign = this.props.campaign;
        var that = this;

        var onCampaignSaveErr = function() {
            that.props.ch.trigger('campaign:onSaveError');
        };

        var onCampaignSaveSucc = function() {
            toastr.success('Campaign is saved');
            that.setState({isEditingCampaign: false});
        };

        campaign.save(
            {iab_categories, name, advertiser_domain, notes, organization, comment},
            {patch: true, error: onCampaignSaveErr, success: onCampaignSaveSucc }
        )
    },

    onCancelEditCampaign() {
        this.setState({isEditingCampaign: false})
    },

    render() {
        if (this.state.isLoading) {
            return <LoadingLabel displayMessage='Loading ...' displayPosition='center' displayTimeout={10000} ch={this.props.ch}/>
        }

        var campaign = this.props.campaign;
        var key = campaign.id;

        var json = campaign.toJSON();

        var campaignSelf;
        if (this.state.isEditingCampaign) {
            campaignSelf = <CampaignForm onCancel={this.onCancelEditCampaign} onSave={this.onSaveCampaign} ch={this.props.ch} campaign={campaign}/>
        } else {
            campaignSelf = <CampaignDetailRegion
                onEditCampaign={this.onEditCampaign}
                onClickProgress={this.onClickProgress}
                onDeleteCampaign={this.onDeleteCampaign}
                onClickHistory={this.onClickHistory}
                campaign={campaign}
                ch={this.props.ch}/>
        }

        var classes = this.props.section !== 'progress' && this.props.section !== 'report' && this.props.section !== 'delivery' ?
            "overview-region" : "overview-region hide";
        var setups = (
            <div className={classes}>
                {campaignSelf}
                <hr className="clearfix"/>
                <CampaignSetupRegion section={this.props.section} sectionId={this.props.sectionId} campaign={campaign} ch={this.props.ch}></CampaignSetupRegion>
            </div>
        )


        if (this.props.campaign.ads == null) {
            setups = null;
        }

        var showCampaignHistory = this.state.showHistory;

        return (
            <div key={key} style={{opacity: 0, display: 'none'}}>
                <div className="row">
                    { campaign.can('edit') ?
                        ( this.state.selectedView !== 3)
                            ? <div className="col-xs-2 col-lg-1"> <SwitchToggle ch={this.props.ch} targetEntity={campaign}/> </div>
                            : null /* case for Report */
                        : null
                    }

                    <div className={
                        campaign.can('edit') ?
                            ( this.state.selectedView !== 3)
                                ? "col-xs-10 col-lg-8"
                                : "col-xs-12 col-lg-9" /* case for Report */
                            : "col-xs-12 col-lg-9"
                    }>
                        <h2 style={{marginTop:0}}><span className="text-muted">#{campaign.id}</span>&nbsp;{campaign.get('name')}</h2>
                        <Status status={json.status}/>
                    </div>

                    <div className="col-xs-12 col-sm-4 col-sm-offset-8 col-lg-3 col-lg-offset-0">
                        <DropdownMenu menuItems={this.menuItems} theme="light" selected={this.state.selectedView}></DropdownMenu>
                    </div>
                </div>

                <hr className="clearfix"/>

                {setups}

                {showCampaignHistory? <CampaignHistory ch={this.props.ch} onClosePopup={this.onCloseHistoryPopup} campaign={campaign} />: null}
            </div>
        );
    }
});

var Form = require('scripts/components/campaigns/parts/ads/form');
var CampaignProgress = require('scripts/components/campaigns/views/progress');
var CampaignReport = require('scripts/components/campaigns/views/report');
var CreativesView = require('../parts/creatives');
var AssetsView = require('../parts/assets');
var TrafficSheetView = require('../parts/traffic-sheet');
var Ads = require('scripts/entities/collections/ads');
var LoadingLabel = require('scripts/widgets/loading-label');

var Layout = Mario.Layout.extend({
    // this is just normal html template
    template: _.template(`

        <div>
            <div class="main-region"></div>
            <div class="form-region"></div>
            <div class="progress-region"></div>
            <div class="progress-v2-region"></div>
            <div class="report-region"></div>
            <div class="files-region tab-pane" style="display:none"></div>
            <div class="traffic-sheet-region tab-pane" style="display:none"></div>
        </div>

        <div class="loading-placeholder-region" style=""></div>
    `),

    regions: {
        // we have to do this because some views are too tedious to convert to React views
        // so we render them inside backbone views here
        formRegion: '.form-region',
        progressRegion: '.progress-region',
        filesRegion: '.files-region',
        trafficSheetRegion: '.traffic-sheet-region',
        loadingPlaceholderRegion: '.loading-placeholder-region',
        reportRegion: '.report-region',
        progressv2Region: '.progress-v2-region'
    },

    className: 'campaign',

    initialize: function () {
        // fetch the campaign
        var campaign = this.model;
        var ch = this.options.ch;

        this.listenTo(campaign, 'notfound', ()=>this.trigger('destroy-page'));

        this.listenTo(ch, 'campaign:setup:files', this.onShowFiles);
        this.listenTo(ch, 'campaign:setup:traffic-sheet', this.onShowTrafficSheet);

        this.listenTo(ch, 'campaign:ads:progress', this.onNavigateToAdProgress);
        this.listenTo(ch, 'campaign:ads:edit', this.onEditAd);
        this.listenTo(ch, 'campaign:ads:delete', this.onDeleteAd);
        this.listenTo(ch, 'campaign:ads:duplicate', this.onDuplicateAd);

        this.listenTo(ch, 'campaign:creatives:audit', this.onAuditCreative);
        this.listenTo(ch, 'campaign:creatives:edit', this.onEditCreative);
        this.listenTo(ch, 'campaign:creatives:delete', this.onDeleteCreative);
        this.listenTo(ch, 'campaign:creatives:duplicate', this.onDuplicateCreative);
        this.listenTo(ch, 'campaign:creatives:simulate', this.onSimulateCreative);

        this.listenTo(ch, 'ToggleSwitch:change:isPause', this.onPauseChanged);
        this.listenTo(ch, 'loading:timeout', function(){ this.onLoadingTimeout() });

        this.campaignReports = {};

        ch.reply('organizations', function () {
            return this.options.organizations;
        }, this);

        // delegate shown events to camapign:route
        this.listenTo(ch, 'shown', function () {
            ch.trigger('campaign:route', this.options.section, this.options.sectionId);
        });

        // start loading camapign
        var that = this;
        campaign.refresh()
            .then(function() {

                that.listenTo(ch, 'campaign:route', that.onCampaignRoute, that);

                var currentRoute = globalCh.request('currentRoute');
                var currentRouteCampaignId = objectPath.get(currentRoute, 'args.0');
                // User may have navigated to another page while this campaign data was being fetched
                if (currentRouteCampaignId === campaign.id.toString()) {
                    ch.trigger('shown'); // synthize a page shown event after collections refreshed
                }


                // Radio message views/setup.jsx to replace its loading view with its content
                campaign.ads && campaign.ads.refresh().then(function(){
                    ch.trigger('campaign:data:loaded');

                    // the following events is listened
                    // in .../components/campaigns/views/progress.jsx
                    // for mounting popover summery
                    ch.trigger('campaign:ads:data:loaded');
                });

            });
    },

    onLoadingTimeout() {
    },

    onAuditCreative(creative) {
        creative.save({audit_status: 'pending'});
    },
    onDeleteCreative(creative) {
        if (confirm(`Delete creative ${creative.get('name')}`)) {
            creative.destroy({wait: true});
        }
    },

    onNavigateToAdProgress(ad) {
        var campaign = this.model;
        Backbone.history.navigate(`/campaigns/${campaign.id}/progress/${ad.id}`, {trigger: true});
    },

    onPauseChanged(entity, paused) {
        entity.save({paused: paused}, {patch: true});
    },

    onEditAd(ad) {
        this.showAdForm(ad);
    },

    onEditCreative(creative) {
        this.showCreativeForm(creative);
    },

    onDeleteAd(ad) {
        if (confirm(`Are you sure to delete ad ${ad.get('name')}?`)) {
            ad.destroy({wait: true});
        }
    },

    onDuplicateAd(ad) {
        var attrs = ad.getDuplicateAttributes();

        // check dates
        if (moment(attrs.start) <= Date.now() ) {
            attrs.start = moment().add('1', 'minute').toString();
        }

        if (moment(attrs.start) >= moment(attrs.end)) {
            attrs.end = moment(attrs.start).add('1', 'minute').toString();
        }
        attrs['name'] = attrs['name'] + '-Copy';
        this.showAdForm(attrs, { duplicate_from: ad.id });
    },
    onDuplicateCreative(creative) {
        var attrs = creative.getDuplicateAttributes();
        this.showCreativeForm(attrs, { duplicate_from: creative.id });
    },

    onShowFiles() {
        var campaign = this.model;
        this.assetsView = this.assetsView || new AssetsView({
            collection: campaign.assets,
            ads: campaign.ads,
            creatives: campaign.creatives,
            assets: campaign.assets,
            campaign: campaign,
            ch: this.options.ch
        });

        this.filesRegion.show(this.assetsView);
        $(this.filesRegion.el).show();
    },

    onShowTrafficSheet() {
        var campaign = this.model;
        this.trafficSheetView = this.trafficSheetView || new TrafficSheetView({
            creatives: campaign.creatives,
            campaign: campaign,
            ch: this.options.ch
        });
        this.trafficSheetRegion.show(this.trafficSheetView);
        $(this.trafficSheetRegion.el).show();
    },

    onShow() {
        var campaign = this.model;
        var props = {
            campaign: campaign,
            ch      : this.options.ch,
            section : this.options.section,
            sectionId : this.options.sectionId
        };

        React.render(React.createFactory(CampaignView)(props), this.$('.main-region').get(0));
        this.updatePageMeta();
    },

    onDestroy() {
        React.unmountComponentAtNode(this.$('.main-region').get(0));

        if (this.$('.loading-placeholder-region').length > 0) {
             React.unmountComponentAtNode(this.$('.loading-placeholder-region').get(0));
        }

    },

    onBeforeShow() {
        this.initModalRegion();
    },

    // for ad forms
    initModalRegion() {
        if ($('#campaign-modal').length === 0) {
            $('body').append('<div id="campaign-modal"></div>');
        }

        $('#campaign-modal').empty();

        var ch = this.options.ch;
        this.listenTo(ch, 'campaign:ads:new', ()=>this.showAdForm());
        this.listenTo(ch, 'campaign:creatives:new', ()=>this.showCreativeForm());
    },

    showCreativeForm: function (creative, options) {
        var CreativeForm = require('../parts/creatives/form');
        var popup = globalCh.request('popup', {size: 'large'});
        var campaign = this.model, ch = this.options.ch;
        var Creatives = require('scripts/entities/collections/creatives');

        this._checkForApprovalStatus(creative).then(function () {

            var popup = globalCh.request('popup', {size: 'large', backdrop: 'static'});

            options = _.assign({
                collection: campaign.creatives,
                campaign: campaign,
                inPopup: true,
                ch: ch,
                duplicate_from: null
            }, options);

            if (creative instanceof Creatives.Creative) {
                options.model = creative;
            } else if (_.isPlainObject(creative)) {
                options.duplicate = creative;
            }
            var creativeForm = new CreativeForm(options);

            popup.show(creativeForm);
        });
    },

    _checkForApprovalStatus: function (creative) {
        var Creatives = require('scripts/entities/collections/creatives');
        var CancelAuditView = require('../parts/creatives/cancel-audit');
        var AUDIT_STATUS = Creatives.AUDIT_STATUS;

        return new Promise(function (res, rej) {
            // if the creative has been submitted before
            if (creative instanceof Backbone.Model && creative.get('audit_status') !== AUDIT_STATUS.NO_AUDIT) {
                var popup = globalCh.request('popup');

                var cancelAudtiView = new CancelAuditView({
                    model: creative, yes: res, no: rej
                });

                popup.show(cancelAudtiView);
            } else {
                res();
            }
        });

    },

    showAdForm(ad, options) {

        options = _.assign({
            collection: this.model.ads,
            assets: this.options.assets,
            creatives: this.options.creatives,
            campaign: this.model,
            ch: this.options.ch,
            duplicate_from: null
        }, options);

        if (ad instanceof Ads.Ad) {
            options.model = ad;
        } else if (_.isObject(ad)) {
            options.duplicate = ad;
        }

        var adForm = new Form(options);

        adForm.once('sync', function () {
            this.triggerMethod('show');
        }, this);

        $(this.formRegion.el).show();
        this.formRegion.show(adForm);
    },

    onCampaignRoute() {

        var {section, sectionId, ch} = this.options;

        this.$('.tab-pane').hide();
        $(this.formRegion.el).hide();
        $(this.reportRegion.el).hide();
        $(this.progressv2Region.el).hide();

        if (section === 'progress') {
            this.showProgress();

        } else if (section === 'delivery') {
            $(this.progressRegion.el).hide();
            this.showProgressv2();

        } else if (section === 'report') {
            $(this.progressRegion.el).hide();
            this.showReport();
        } else {
            $(this.progressRegion.el).hide();

            switch (section) {
                case 'ads':
                    $(this.formRegion.el).show();
                    break;
                case 'files':
                    ch.trigger('campaign:setup:files');
                    break;
                case 'traffic-sheet':
                    ch.trigger('campaign:setup:traffic-sheet');
                    break;
            }
        }

        this.onShow();
    },

    showProgressv2 () {
        var { sectionId } = this.options;

        $(this.progressv2Region.el).show();

        var props = {
            campaignId: this.model.id,
            adId: sectionId
        };

        flux.actions.progressPage.activateTab(props);

        if (sectionId) {
            React.render(<Progressv2 {...props} />, this.$el.find('.progress-v2-region')[0]);
        } else {
            React.render(<Progressv2 {...props} />, this.$el.find('.progress-v2-region')[0]);
        }

        flux.actions.progressPage.fetchProgressPageData(props.campaignId, props.adId);
    },

    showProgress() {
        var { sectionId } = this.options;

        $(this.progressRegion.el).show();

        if (this.model.ads == null) {
            return;
        } else {
            flux.actions.progressPage.fetchComponentData(this.model.id, sectionId);
        }

        if (this.progressView != null) {
            _.extend(this.progressView.options, {sectionId: this.options.sectionId});
            this.progressView.triggerMethod('Show');
        } else {
            var ads = flux.store('ads').getAdsByCampaignId(this.model.id);
            var options = _.extend({collection: ads}, _.pick(this.options, ['model', 'section', 'sectionId', 'ch']));
            this.progressView = new CampaignProgress(options)
            this.progressRegion.show(this.progressView);
        }
    },

    showReport() {
        $(this.reportRegion.el).show();

        var props = {
            campaignId: this.model.id
        };

        if (this.campaignReport) {
            this.campaignReport.setProps(props);
        } else {
            var factory = React.createFactory(CampaignReport);
            this.campaignReport = React.render(factory(props), this.$el.find('.report-region')[0]);
        }
    },

    modelEvents: {
        'sync': 'updatePageMeta'
    },

    updatePageMeta() {
        this.options.ch.trigger('update-page-meta', {resource_id: this.model.id, resource_name: this.model.get('name')});
    },

    onSimulateCreative(creative) {

        Simulator.openInNewWindow(creative.toJSON());
    }

});


module.exports = Layout;
