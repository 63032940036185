'use strict';

var TabItem = React.createClass({
    deleteTab: function (ev) {
        this.props.tab.save('tab_visible', false);
    },
    render: function () {
        var tab = this.props.tab;
        var active = tab.get('active') == true ? 'is-active' : '';

        if (tab.get('main_url').indexOf('/campaigns/new') !== -1) {

            return (
                <li className={"ef-cmpt-list-item " + active + (tab.get('tab_visible') == true? '': ' hide')}>
                    <a href={tab.get('url')} title="New campaign" data-track data-tracking-section="new campaign">
                        <span className="ef-campt-campaign-id">
                            New campaign
                        </span>
                    </a>
                    <div className="ef-cmpt-close" onClick={this.deleteTab}>
                        <i className="fa fa-times-circle fa-lg"></i>
                    </div>
                </li>
            );

        } else {
            return (
                <li className={"ef-cmpt-list-item " + active + (tab.get('tab_visible') == true? '': ' hide')}>
                    <a href={tab.get('url')} title={tab.get('resource_name')} data-track data-tracking-section="campaign" data-tracking-section-id={tab.get('resource_id')}>
                        <span className="ef-cmpt-campaign-id">
                            #{tab.get('resource_id')}
                        </span>
                        <span className="ef-cmpt-campaign-name">{tab.get('resource_name')}</span>
                    </a>
                    <div className="ef-cmpt-close" onClick={this.deleteTab}>
                        <i className="fa fa-times-circle fa-lg"></i>
                    </div>
                </li>
            );

        }
    }
})

var isVisible = function (t) {
    return t.get('main_url') === '/campaigns/new' || t.get('tab_visible') === true ;
};
var isPrimary = function (t) {return t.get('main_url') === '/campaigns';};

var TabList = React.createClass({

    getInitialState() {
        return {
            primaryTab: this.getPrimaryTab(),
            visibleTabs: this.getVisibleTabs()
        }
    },
    
    getPrimaryTab() {
        var tabs = this.props.tabs;
        return _.find(tabs, isPrimary);
    },
    
    getVisibleTabs() {
        var tabs = this.props.tabs;

        tabs = _.reject(tabs, isPrimary);
        tabs = _.filter(tabs, isVisible);

        tabs.sort(function (a, b) {
            // always place empty campaign tab at the top
            if (b.get('resource_id') == '') {
                return 1;
            } else if (a.get('resource_id') == '') {
                return -1;
            } else {
                return a.get('resource_id') > b.get('resource_id') ? -1 : 1;
            }
        });

        return tabs;
    },
    
    componentWillReceiveProps(props) {
        this.setState({
            primaryTab: this.getPrimaryTab(),
            visibleTabs: this.getVisibleTabs()
        })
    },
    
    onCloseAll: function () {
        var visibleTabs = _.filter(this.props.tabs, isVisible);
        if (visibleTabs.length === 0) {
            return;
        }
        visibleTabs.slice(1).forEach(function (tab) {
            tab.save({tab_visible: false}, {silent: true});
        });

        visibleTabs[0].save({tab_visible: false});
    },

    render: function () {

        var tabControls;
        if (this.state.visibleTabs.length > 0) {
            // toggle tab controls
            tabControls = <div className= "ef-cmpt-ctrl" >
                <button onClick={_.bind(this.onCloseAll, this)} className="ef-cmpt-ctrl-btn">
                    <i className="fa fa-fw fa-close"></i>
                    Close tabs
                </button>
            </div>
        }


        var primary = this.state.primaryTab;
        return (
            <div className="ef-campaign-tab">
                <ul className="ef-cmpt-list" >
                    <li className={"ef-cmpt-list-item " + (primary != null && primary.get('active') == true ? 'active is-active': '' )} >
                        <a href="/" data-track data-tracking-section="campaigns">
                            <i className={"fa fa-fw fa-list fa-lg"}></i>
                            <span>All Campaigns</span>
                        </a>
                    </li>
                    {this.state.visibleTabs.map(function (item) {
                        return <TabItem key={item.get('main_url')} tab={item} onClose={this.onClose}></TabItem>
                    }, this)}
                </ul>
                {tabControls}
            </div>
        )

    }

});

module.exports = TabList;
