'use strict';

var progressReducer = require('./progress');

function campaignsReducer (state = {}, resources, session) {
    return {
        progress: progressReducer(state.progress, resources, session)
    };
}

module.exports = campaignsReducer;