'use strict';



var PrivateChannel = {
    componentWillMount() {
        this.privateCh = Backbone.Radio.channel(_.uniqueId('private-channel'));
    },
    
    componentWillUnmount() {
        this.privateCh.reset();
    }
};

module.exports = PrivateChannel;
