<div>
<span>{{date_point}}</span>
</div>

{{#if expected}}
<div>
    <strong>Expected</strong>: <span>{{format_number_thousands expected}}</span>
</div>
{{/if}}

{{#if actual}}

<div>
    <strong>Actual</strong>: <span>{{format_number_thousands actual}} ({{percentage}}%)</span>
</div>
{{/if}}

