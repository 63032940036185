// load additional timezones
// copied from adsushi/scripts/timezones/links.json

var additionalTimezones = [
    [
        "America/Toronto",
        "America/Ottawa"
    ],
    [
        "America/Edmonton",
        "America/Calgary"
    ]
];

moment.tz.link(_.map(additionalTimezones, function (arr) {return arr.join('|');}));
