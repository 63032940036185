<div class="col-xs-12">
    {{#if id}}
        <h2>Edit Campaign</h2>
    {{else}}
        <h2>New Campaign</h2>
    {{/if}}

    <div class="row">
        {{#if with_uploader}}

        <div class="col-sm-7">
        {{else}}
        <div class="col-sm-12">
        {{/if}}
            <fieldset>
                <div class="row">
                    <div class="col-xs-12">
                        <div class="form-group">
                            <label for="campaign-name">Name <i class="fa fa-asterisk text-danger"></i></label>
                            <input class="form-control" data-id="name" tabindex="1" id="campaign-name"/>

                            <div class="help-block "></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-6">
                        <div class="form-group">
                            <label for="campaign-advertiser_domain">Advertiser Domain <i
                                    class="fa fa-asterisk text-danger"></i><i data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="Advertiser's primary or top-level domain for advertiser checking." class="fa fa-info-circle text-info" data-original-title="Advertiser Domain"></i></label>
                            <input class="form-control" tabindex="2" data-id="advertiser_domain"
                                   id="campaign-advertiser_domain" placeholder="example.com"/>
                            <div class="help-text">Do not include the 'http://' or 'www.' prefixes</div>
                            <div class="help-block "></div>
                        </div>
                    </div>
                    <div class="col-xs-6">
                        <div class="form-group">
                            <label for="campaign-iab_categories">Brand Category<i
                                    class="fa fa-asterisk text-danger"></i></label>

                            <div data-id="iab_categories" style="width: 100%" id="campaign-iab_categories"
                                 tabindex="3"></div>
                            <div class="help-text">Select at least one IAB category / sub-category which best describes the campaign content. Certain categories like 'Card Games' and 'Cocktails/Beer' may limit traffic to age-restricted apps.</div>
                            <div class="help-block "></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <div class="form-group">
                            <label for="campaign-notes">Notes</label>
                            <textarea class="form-control" data-id="notes" rows="6"></textarea>
                        </div>
                    </div>
                </div>
                {{#if has_child_organizations}}
                <div class="row">
                    <div class="col-xs-6">
                        <label>Organization</label>
                        <select class="form-control" data-id="owner"></select>
                    </div>
                </div>
                {{/if}}
            </fieldset>
        </div>

        {{#if with_uploader}}
        <div class="col-sm-5 hidden-xs">
            <div class="row">
                <div class="col-sm-6">
                    <label>Uploads</label>
                    <span class="help-text">Manage your campaign-related documents on EngageFront, or take advantage of our <abbr
                    title="Content Delivery Network">CDN</abbr> to serve your assets. You can also upload more files later.</span>
                </div>
                <div class="col-sm-6">
                    <div class="notice-region"></div>
                </div>
            </div>
            <div class="file-upload-region"></div>
        </div>
        {{/if}}
    </div>

    <hr/>
    <div class="row">
        <!--<div class="col-xs-6">-->
            <!--<button type="button" class="btn-link btn" data-action="cancel">Cancel / Discard Changes</button>-->
        <!--</div>-->
        <div class="col-xs-offset-6 col-xs-6 text-right">
            <button type="button" class="btn btn-success" data-action="save" id="create-campaign" data-track data-tracking-section="new-campaign">
                <i class="fa fa-check"></i>
                <span>Create Campaign</span>
            </button>
        </div>
    </div>
</div>
