'use strict';

const MINUTE = 60000;

var RefreshButton = React.createClass({

    propTypes: {
        refreshInterval: React.PropTypes.number, // milliseconds
        pause: React.PropTypes.bool,
        refresh: React.PropTypes.func.isRequired
    },

    getInitialState () {
        return {
            lastUpdateTime: new Date(),
            messageInterval: 1 * MINUTE
        };
    },

    getDefaultProps () {
        return {
            refreshInterval: 3 * MINUTE,
            pause: false
        };
    },

    doRefresh () {
        if (this.props.pause) {
            return;
        }

        this.props.refresh();
        this.setState({
            lastUpdateTime: new Date()
        });
    },

    componentDidMount () {

        this.refreshIntervalId = setInterval(
            () => this.doRefresh(),
            Number(this.props.refreshInterval));


        this.messageIntervalId = setInterval(
            () => this.forceUpdate(),
            this.state.messageInterval);
    },

    componentWillUnmount () {
        clearInterval(this.refreshIntervalId);
        clearInterval(this.messageIntervalId);
    },

    render () {
        const lastUpdateTimeMessage = moment(this.state.lastUpdateTime).fromNow();
        return (
            <a href="#" onClick={()=>this.doRefresh()}>
                Updated {lastUpdateTimeMessage} &nbsp;&nbsp; <i className="fa fa-refresh"></i>
            </a>
        );
    }
});


module.exports = RefreshButton;
