<div class="form-auth reset" role="form">
    <h2 class="form-auth-heading">Reset Password</h2>
    <input type="password" class="form-control new-password" placeholder="New password" value="" required autofocus autocomplete="new-password">
    <input type="password" class="form-control confirm-password" placeholder="Confirm password" required autocomplete="new-password">
    <button class="btn btn-lg btn-primary btn-block" type="submit">Reset Password</button>
    <a href="/signin" class="password-reset">
        <i class="fa fa-long-arrow-left"></i>
        back
    </a>
</div>
