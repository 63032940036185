'use strict';

var app = require('scripts/core').app;
var viewTemplate = require('../templates/activation');
var Form = require('scripts/common/mixins/form');


module.exports = Mario.ItemView.extend({
    template: viewTemplate,

    onSave: function (e) {

        var hasError = false;

        if (!this.$('.user-first_name').val()) {
            this.$('.user-first_name').closest('.form-group').addClass('has-error');
            hasError = true;
        }

        if (!this.$('.user-last_name').val()) {
            this.$('.user-last_name').closest('.form-group').addClass('has-error');
            hasError = true;
        }

        if (!this.$('.user-password').val()) {
            this.$('.user-password').closest('.form-group').addClass('has-error');
            this.$('.user-password')
                .closest('.form-group')
                .find('.help-block')
                .text('Cannot be empty');
            hasError = true;
        }

        if (this.$('.user-password').val() !== this.$('.user-confirm-password').val()) {
            this.$('.user-password').closest('.form-group').addClass('has-error');
            this.$('.user-password')
                .closest('.form-group')
                .find('.help-block')
                .text('');

            this.$('.user-confirm-password').closest('.form-group').addClass('has-error');

            hasError = true;
        }

        if (!hasError) {
            this.$('.form-group').removeClass('has-error');

            var req = $.ajax({
                type: 'post',
                url: 'activation',
                data: JSON.stringify({
                    password: this.$('.user-password').val(),
                    confirmPassword: this.$('.user-confirm-password').val(),
                    first_name: this.$('.user-first_name').val(),
                    last_name: this.$('.user-last_name').val(),
                    activation_token: this.options.activationToken
                })
            });
            req.then(function (data) {
                toastr.success('Activation Complete.');
                Backbone.history.navigate('/signin', {trigger: true});
            }, function (e) {
                // var resData = e.responseJSON;
                // if (e.status === 404) {
                //     toastr.error(resData.join('\n'));
                // }
            });
        }


        return false;
    },

    behaviors: {
        Form: { }
    },

    templateHelpers: function () {
        return this.options;
    }
});
