'use strict';

// in the order or increasing importance
var KPIS = ['creatives', 'creative-format', 'creative-size', 'flight', 'devices', 'geotargets', 'dayparts', 'languages', 'weekparts', 'genders', 'ages'];

var Grouping = Mario.Model.extend({
    modelName: 'Grouping',
    parse: function (resp, options) {
        // normalize kpi
        resp.kpi = resp.kpi.replace(/\W+/g, '-').toLowerCase();
        return resp;
    }

});

var Groupings = Mario.Collection.extend({
    model: Grouping,

    initialize: function (models, options) {
        this.url = 'campaigns/' + options.collectionId + '/groupings';
    },

    refresh: function () {
        var that = this;
        this.trigger('refresh:start');
        return new Promise(function (res, rej) {
            that.fetch({success: res, error: rej});
        }).finally(function () {
                that.trigger('refresh:done');
            });
    },

    findGroupedAttributesForAd: function (adId) {
        return this.reduce(function (mapping, grouping, idx) {

            if (grouping.get('kpi') === 'ad') return mapping;

            var groups = grouping.get('groups');

            var adGroup = _.find(groups, function (group) {
                return _.contains(group.ads, adId);
            });

            if (adGroup) {
                mapping.push({name: adGroup.name, hidden: idx > this.showUntilIndex(), kpi: grouping.get('kpi')});
            } else {
                mapping.push({name: '', hidden: idx > this.showUntilIndex(), kpi: grouping.get('kpi')});
            }

            return mapping;
        }, [], this);
    },

    showUntilIndex: function () {
        return this.reduce(function (count, g) {return count + ~~(_.contains(KPIS, g.get('kpi')) && g.get('groups').length > 1);}, -1);
    },

    getApplicableColumns: function () {
        var showIdx = this.showUntilIndex();
        var columns = this.map(function (g, idx) {return {label: g.get('name'), name: g.get('kpi'), hidden: idx > showIdx};})
        return _.filter(columns, function (c) {return c.name !== 'ad';});
    },

    comparator: function (a, b) {
        /* jshint maxcomplexity: false */

        var aIdx = _.indexOf(KPIS, a.get('kpi'));
        var bIdx = _.indexOf(KPIS, b.get('kpi'));

        if (a.get('groups').length > 1) {
            aIdx -= 100;
        }
        if (b.get('groups').length > 1) {
            bIdx -= 100;
        }

        return aIdx - bIdx < 0 ? -1 : 1;
    }
});

Groupings.KPIS = KPIS;

module.exports = Groupings;
