'use strict';
var helpers   = require('../helpers');
var langCodes = require('scripts/common/constants/language-codes');
var fns       = require('scripts/common/fns');
var app       = require('scripts/core').app;
var Stats     = require('./stats');

/**
 * @typedef GeoTarget
 * @type {object}
 * @property {string} country country code
 * @property {string[]} include list of region/city code
 * @property {string[]} exclude list of region/city code
 */

/**
 * @typedef GeoBox
 * @type {Object}
 * @property {number} E longitude of right edge
 * @property {number} W longitude of left edge
 * @property {number} S latitude of bottom edge
 * @property {number} N latitude of top edge
 */

/**
 * @typedef DayPart
 * @property {string} start hour to start, e.g. "03:30:00"
 * @property {string} end hour to end, e.g. "09:30:00"
 */

var Model = Mario.Model.extend({
    modelName: 'Ad',

    isCampaign: function () {
        return false;
    },
    initialize: function (attrs, options) {
        options = options || {};
        _.extend(this, _.pick(options, 'urlRoot'));

        // campaign id
        var cid = this.url().match(/\d+/)[0];

        // init ad stats
        if (!this.isNew()) {
            this.initStats(cid);
        } else {
            this.on('sync', this.initStats.bind(this, cid));
        }

    },

    initStats: function (cid) {
        this.stats = new Stats([], {collectionId: cid + '/ads/' + this.id});

        this.stats.on('sync', function () {
           this.trigger('stats:loaded');
        }, this);

        this.stats.on('livestats:loaded', function () {
           this.trigger('livestats:loaded');
        }, this);
    },

    defaults: {
        /* jshint camelcase : false */
        name                  : '',
        status                : '', // readonly
        paused                : true,
        notes                 : '',
        // @type {string} ISO date string
        start                 : '',
        // @type {string} ISO date string
        end                   : '',
        timezone              : 'UTC',
        max_total_impressions : 0,
        max_daily_impressions : 0,
        max_total_clicks      : 0,
        max_daily_clicks      : 0,
        max_total_spend       : 0,
        max_daily_spend       : 0,
        creative              : '',
        // @type {GeoTarget[]}
        geotargets            : [],
        // @type {GeoBox[]}
        geoboxes              : [],
        weekparts             : [true, true, true, true, true, true, true],
        // @type {DayPart[]}
        dayparts              : [],
        max_user_frequency    : 4,
        max_cpm_rate: 0,
        billing_terms: 'PPM',
        billing_rate: 0,
        // @type {string[]}
        restrict_ips          : [],
        target_device_language: '',
        target_device_os      : ['iOS', 'Android'],
        target_age_groups     : [], // target all age groups
        target_genders         : [],   // target all gender
        target_app_categories  : [],
        comment                : '', // comment for changes
        max_bid_cpm: 0
    },

    validation: {
        /* jshint camelcase : false */
        name: function (val, attr) {
            if (_.isEmpty(val)) {
                return 'Name is required.';
            }
        },

        start: function (val, attr, computedState) {
            /* jshint maxcomplexity: false */
            // if it's already started ignore validation
            if (this.progress() > 0) {
                return null;
            }

            if (_.isEmpty(val)) {
                return 'Start date is required.';
            }

            if (val === 'now') {
                return null;
            }

            var startDate = moment.utc(val);

            // no longer restrict
            /*if (startDate < Date.now()) {
                return 'Start date must be in the future.';
            }*/

            if (startDate >= moment.utc(computedState['end']))  {
                return 'Start date must be before end date.';
            }
        },

        end: function (val) {
            // if it's finished, ignore validation

            if (this.progress() >= 100) {
                return null;
            }

            if (_.isEmpty(val)) {
                return 'End date is required.';
            }

            // no longer restrict
            /* if (moment.utc(val) <= Date.now() ) {
                return 'End date must be in the future.';
            }*/
        },

        max_total_impressions : function (val, attr, computed) {
            val = parseFloat(val);

            if (_.isNaN(val)) {
                return 'Total impressions is not valid';
            }

            if (val !== 0 && val < computed['max_daily_impressions']) {
                return 'Total impressions should be greater than daily impressions ';
            }

            return checkAtLeastOneClicksOrImpressions(computed);
        },

        max_total_clicks      : function (val, attr, computed) {
            val = parseFloat(val);

            if (_.isNaN(val)) {
                return 'Total clicks is not valid';
            }

            if (val !== 0 && val < computed['max_daily_clicks']) {
                return 'Total clicks should be greater than daily clicks';
            }
            return checkAtLeastOneClicksOrImpressions(computed);
        },

        max_total_spend : function (val, attr, computed) {
            val = parseFloat(val);

            if (_.isNaN(val)) {
                return 'Total spend is not valid';
            }

            if (val !== 0 && val < computed['max_daily_spend']) {
                return 'Total spend should be greater than daily spend';
            }

            return checkAtLeastOneClicksOrImpressions(computed);

        },

        // @TODO: deprecate
        max_cpm_rate : function (val, attr) {
            val = parseFloat(val);

            if (_.isNaN(val)) {
                return 'Max CPM is not valid';
            }
            if (val <= 0) {
                return 'Max CPM should be greater than 0.';
            }
        },

        max_bid_cpm : function (val, attr) {
            val = parseFloat(val);

            if (_.isNaN(val)) {
                return 'Max CPM is not valid';
            }
            if (val <= 0) {
                return 'Max CPM should be greater than 0.';
            }
        },

        restrict_ips: function (ips, attr) {
            if (_.any(ips, function (ip) {return !ip.match(/^(\d{1,3}\.){3}(\d{1,3})$/);})) {
                return 'Some IP address specified is not valid';
            }
        },

        geotargets: function (targets) {
            if (_.isEmpty(targets)) {
                return 'Geo targets cannot be empty';
            }
        },

        billing_rate: function (rates) {
            rates = parseFloat(rates);

            if (_.isNaN(rates)) {
                return 'Billing rate is not valid';
            }

            if (rates < 0) {
                return 'Billing rate cannot negative number';
            }
        }
    },

    duplicate: function (data, cb) {
        if (_.isFunction(data)) {
            cb = data;
            data = {};
        }

        var that = this;
        $.ajax({
            url: this.url() + '/duplicate',
            type: 'POST',
            dataType: 'json',
            headers: {
                'content-type': 'application/json'
            },
            data: JSON.stringify(data),
            success: function (data) {
                var m = new that.constructor(data);
                if (that.collection) {
                    that.collection.add(m);
                }
                cb.call(that, m);
            }
        });
    },

    getDuplicateAttributes: function () {
        var keys = _.keys(_.result(this, 'defaults'));
        return this.pick.apply(this, _.without(keys, 'status', 'paused'));
    },

    computed: function () {
        return {
            _progress: this.progress(),
            _in_progress: this.progress() !== 100,
            _week_days: this.weekDays()
        };
    },

    isUnlimitedSpend: function () {
        return !this.get('max_daily_spend') && !this.get('max_total_spend');
    },

    isUnlimitedImpressions: function () {
        return !this.get('max_daily_impressions') && !this.get('max_total_impressions');
    },

    isUnlimitedClicks: function () {
        return !this.get('max_daily_clicks') && !this.get('max_total_clicks');
    },

    isAnyWeekparting: function () {
        return _.every(this.get('weekparts')) || !this.get('weekparts').length;
    },

    weekDays: function () {
        return _.object(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], this.get('weekparts'));
    },

    formatedLang: function () {
        if (this.get('target_device_language')) {
            var deviceLang = this.get('target_device_language');
            var code = langCodes[this.get('target_device_language')];
            return deviceLang + ' - ' + code;
        }
        return false;
    },

    /**
     * total target will be taken from total attribute only
     * @param format
     * @returns {*}
     */
    getTotalTarget: function () {
        var start = moment.utc(this.get('start'));
        return this.getTargetAfter(start.valueOf());
    },

    /**
     * daily target is the smaller one of daily attribute and
     * inferred daily if there is one
     *
     * @returns {*}
     */
    getDailyTarget: function () {
        var now = moment.utc();
        var calculated = this.getTargetAfter(now.startOf('day').valueOf()) - this.getTargetAfter(now.endOf('day').valueOf());
        var goal = this.goal();
        var dailyCap;

        if (goal === 'impressions') {
            dailyCap = this.get('max_daily_impressions');
        } else {
            dailyCap = this.get('max_daily_clicks');
        }

        if (!dailyCap) {
            dailyCap = Infinity;
        }

        if (!_.isFinite(calculated)) {
            return dailyCap;
        }

        return _.min([calculated, dailyCap]);
    },


    loadSimpleStats: function () {

    },

    getCampaign: function () {
        return objectPath.get(this, 'collection.campaign');
    },

    getCampaignId: function () {
        var campaign = this.getCampaign();
        return objectPath.get(campaign, 'id');
    },

    getCreative: function () {
        var campaign = this.collection.campaign;

        if (!campaign) return null;
        var creatives = campaign.creatives;

        if (!creatives) return null;

        return creatives.get(this.get('creative'));
    },


    getRemainingTargetAfterToday: function () {
        return this.getTargetAfter(moment.utc().endOf('day').valueOf);
    },

    getYesterdayTarget: function () {
        return this.getTargetBefore(moment.utc().subtract(1, 'day').endOf('day').valueOf());
    },

    // An ad's goal is based on whether its limited by impressions or limited by clicks.
    // It should not be limited by both; ads can only have one goal.
    goal: function () {
        return this.get('max_total_impressions') > 0 || this.get('max_daily_impressions') > 0 ? 'impressions': 'clicks';
    },

    searchTerm: function (term) {

        if (typeof term !== 'string') return true;

        term = term.toLowerCase();
        var hay = _.values(this.pick('name', 'id', 'status')).join(' ');
        hay += ' ' + moment.utc(this.get('start')).format('MMM DD') + ' ' + moment.utc(this.get('end')).format('MMM DD');
        return S(hay.toLowerCase()).contains(term);
    },

    _getRawTotal: function () {
        var goal = this.goal();

        var total, duration = this.getDuration();

        if (goal === 'impressions') {
            total = this.get('max_total_impressions');
            if (!total) {
                return this.get('max_daily_impressions') * duration / 3600 / 24/ 1000;
            } else {
                return total;
            }
        } else {
            total = this.get('max_total_clicks');
            if (!total) {
                return this.get('max_daily_clicks') * duration / 3600 / 24/ 1000;
            } else {
                return total;
            }
        }
    },

    /**
     * return targets need to be fufilled before certian time
     * @param ts
     * @returns {*}
     */
    getTargetBefore: function (ts) {
        var start = moment.utc(this.get('start'));
        var end = moment.utc(this.get('end'));
        var total = this._getRawTotal();

        if (end <= ts) {
            // already finshed, it should have all fulfiled
            return total;
        } else if (start < ts) {
            // in progress, it should have a portion fulfiled
            // the portion is equal to the time elapsed
            var duration = this.getDuration();
            return total * (ts - start) / duration;
        } else {
            // not started yet, nothing should be fulfiled
            return 0;
        }
    },

    /**
     * return the remaining targets needs to be fulfilled after certain time
     * @param ts
     * @returns {*}
     */
    getTargetAfter: function (ts) {

        var start = moment.utc(this.get('start'));
        var end = moment.utc(this.get('end'));
        var total = this._getRawTotal();

        if (start >= ts) {
            // not started, yet so return full tota
            return total;
        } else if (end > ts) {
            var duration = this.getDuration();

            // started, not ended yet, return a portion
            return total * (end - ts) / duration;
        } else {
            // finished no more target
            return 0;
        }
    },

    getTimezoneAbbr: function () {
        return moment().tz(this.get('timezone')).format('z');
    },


    /**
     * get duration based on start and end date, return in ms
     * @returns {number}
     */
    getDuration: function () {

        // flight is not set yet
        if (_.isEmpty(this.get('end')) || _.isEmpty(this.get('start'))) {
            return 0;
        }

        return moment(this.get('end')) - moment(this.get('start'));
    },

    getRealStatus: function () {
        var paused = this.get('paused');
        var progress = this.progress();

        var creativeId = this.get('creative'), auditStatus;

        if (creativeId) {
            var creative = this.collection.campaign.creatives.get(creativeId);
            if (creative) {
                auditStatus = creative.get('audit_status');
            }
        }

        var creativeIsOk = auditStatus === 'approved';

        // before
        if (progress === 0) {
            if (!creativeIsOk) {
                return 'incomplete';
            } else if (paused) {
                return 'paused';
            } else {
                return 'pending';
            }
        } else if (progress < 100) {
            // in progress
            if (!creativeIsOk) {
                return 'incomplete';
            } else {
                if (paused) {
                    return 'paused';
                } else {
                    return 'live';
                }
            }
        } else {
            return 'ended';
        }

    },

    getBulletGraphAttributes: function () {
        return {
            start: this.get('start'),
            end: this.get('end'),
            wRemaining: this.getRemainingAfterToday() / this.getDuration(),
            wToday: this.getTodayPortion(),
            wSuccess: this.getTotalFilled(),
            timezone: this.get('timezone')
        };
    },

    getDisplayStatus: function () {
        var status = this.get('status');

        if (status === 'pending') {
            return 'scheduled';
        }

        return status;
    },

    loadRealTimeStats: function (cb) {
        this.stats.loadRealTimeStats(cb);
    },

    can: function (op) {
        // delegate to campaign
        var campaign = this.getCampaign();

        if (!campaign) {
            return false;
        }

        return campaign.can(op);
    }

}).extend(helpers.modelHelpers);

var Collection = Mario.Collection.extend({

    model: Model,

    initialize: function (models, options) {
        this.url = 'campaigns/' + options.collectionId + '/ads';
        if (options.campaign) {
            this.campaign = options.campaign;
        }
    },

    getTotalTarget: function () {
        return this.reduce(function (mem, ad) {
            return mem + ad.getTotalTarget();
        }, 0);
    },

    getDailyTarget: function () {
        return this.reduce(function (mem, ad) {
            return mem + ad.getDailyTarget();
        }, 0);
    },

    getDuration: function () {
        var s = 0, e = 0, start = 0, end = 0;

        this.each(function (ad) {
            start = moment.utc(ad.get('start'));
            end = moment.utc(ad.get('end'));

            if (!s || start < s) {
                s = start;
            }
            if (!e || end > e) {
                e = end;
            }
        });
        return e - s;
    },

    getTargetAfter: function (ts) {
        return this.reduce(function (sum, ad) {
            return sum + ad.getTargetAfter(ts);
        }, 0);
    },

    getTargetBefore: function (ts) {
        return this.reduce(function (sum, ad) {
            return sum + ad.getTargetBefore(ts);
        }, 0);
    },

    comparator: function (ad1, ad2) {
        return ad1.id > ad2.id ? -1 : 1;
    },

    refresh: function () {
        this.trigger('refresh:start');
        var that = this;

        // this is related to ad list view, though it should not be here
        // refresh ad list, creative list, and ad stats
        return new Promise(function (resolve, reject) {
            return that.fetch({success: resolve, error: reject});
        }).then(function () {
                return that.campaign.creatives.refresh();
            }).then(function () {
                return Promise.all(that.map(function (ad) {
                    return new Promise(function (resolve, reject) {
                        if (ad.stats != null) {
                            return ad.stats.fetch({reset: true, success: resolve, error: reject});
                        } else {
                            return true;
                        }
                    });
                }));
            }).finally(function () {
                that.trigger('refresh:done', that);
            });
    }
});

exports = module.exports = Collection;
exports.Ad = Model;

function checkAtLeastOneClicksOrImpressions(computed) {
    if (
        computed.max_daily_clicks === 0 &&
        computed.max_daily_impressions === 0 &&
        computed.max_daily_spend === 0 &&
        computed.max_total_impressions == 0 &&
        computed.max_total_clicks === 0 &&
        computed.max_total_spend === 0
       ) {
        return 'One of impressions, clicks, and spend must be set.';
    }
}
