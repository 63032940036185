<div class="panel panel-default select-files">

    <div class="panel-body">
        <div class="files"></div>
        <input type="file" class="hide" multiple>
    </div>

    <div class="panel-footer text-muted">
        Uploads are limited to 20MB per file.
        <span class="pull-right">
            <i class="fa fa-lg fa-cloud-upload upload-trigger"></i>
        </span>
    </div>
</div>

