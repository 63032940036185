<div class="ef-all-cmpgn--box1" >

    <div class="ef-all-cmpgn-new-btn">
        {{#if can_create_campaign}}
            <a href="/campaigns/new" class="btn btn-success add-campaign " data-track data-tracking-section="new-campaign" data-tracking-section-id={campaign.id}>
                <i class="fa fa-plus"></i>
                <span class="visible-lg-inline">
                    New Campaign
                </span>
            </a>
        {{/if}}
    </div>

</div>

<div class="ef-all-cmpgn--box2" >
    <div class="ef-all-cmpgn-search search-region"></div>
</div>

<div class="ef-all-cmpgn--box3"  >
    <div class="refresh-button-region"></div>
</div>

<div class="ef-all-cmpgn--box4 hidden-sm hidden-xs"  >

    <ul class="nav-pills nav pull-right campaign-filter tool">
        <li {{#is_equal query_params.owner 'self'}}class="active"{{/is_equal}} data-owner="self">
            <a href="">My Campaigns </a>
        </li>
        <li {{#is_equal query_params.owner 'other'}}class="active"{{/is_equal}} data-owner="other">
            <a href="">Other Campaigns</a>
        </li>
    </ul>

</div>
