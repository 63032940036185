<table class="list table table-hover">

    <thead>
        <tr>
            <th>Name</th>
            <th>Type</th>
        </tr>
    </thead>

    <tbody>

    </tbody>

</table>