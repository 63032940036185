'use strict';
var flux = require('scripts/core/flux');
var http = require('scripts/core/http');

var actions = {

    accountingPage: {

        fetchPageDataAndNavigate: function () {
            var self = this;

            http.get('user')
                .then(function (userProfile) {
                    self.dispatch(
                        flux.constants.userProfile_fetch_success,
                        userProfile
                    );

                    return http.get('organizations/' + userProfile.organization);
                })
                .then(function (organizationProfile) {
                    self.dispatch(
                        flux.constants.userProfile_fetch_success,
                        organizationProfile
                    );

                    return http.get('organizations/' + organizationProfile.id + '/spend');

                })
                .then(function (spendStats) {
                    self.dispatch(
                        flux.constants.spendStats_fetch_success,
                        spendStats
                    );
                })
                .then(function () {
                    self.dispatch(flux.constants.navigate, 'accounting-page');
                })
                .done();
        }
    }
};

flux.addActions(actions);