'use strict';
var Tooltip = require('scripts/widgets/tooltip');

const HealthIndicator = require('./health-indicator');

var CampaignOverallProgress = React.createClass({

    propTypes: {

    },

    componentDidMount () {

    },

    render () {
        const {
            campaignId,
            now,
            start,
            end
        } = this.props;

        return (
            <div className="am-campaignProgressGraph">
                <div className="am-campaignProgressGraph-timeProgress">
                    <div className="am-campaignProgressGraph-timeProgressBackground" style={{width: now.remainingPlusTodayToFlightRatio + '%'}}>
                        <div className="am-campaignProgressGraph-today" style={{width: now.todayToRemainingPlusTodayRatio + '%'}}></div>
                    </div>
                    <div className="start">{start}</div>
                    <div className="end">{end}</div>
                </div>
                <div className="am-campaignProgressGraph-adsList">
                    {this.props.ads.map(ad =>
                    <div key={ad.id} className="am-campaignProgressGraph-adsList-row">
                        <a
                            className={classNames('am-campaignProgressGraph-adsList-rowItem', `is-${ad.health}Health`, `is-${ad.status}`)}
                            style={{marginLeft: ad.leftOffsetInPercentage + '%', width: ad.widthInPercentage + '%'}}
                            href={`/campaigns/${campaignId}/delivery/${ad.id}`}>
                            <Tooltip text={`#${ad.id} - ${ad.name}`} viewportSelector=".am-campaignProgressGraph-adsList">
                                <HealthIndicator health={ad.health}/>
                            </Tooltip>
                            #{ad.id} - {ad.name}
                            {ad.status === 'live' ? <span className="am-campaignProgressGraph-adsList-statusLabel is-live">Live</span> : null}
                            {ad.status === 'paused' ? <span className="am-campaignProgressGraph-adsList-statusLabel is-paused">Paused</span> : null}
                        </a>

                    </div>)}
                </div>
                <div className="am-campaignProgressGraph-now" style={{left: now.elapsedToFlightRatio + '%'}}></div>
            </div>
        );
    }
});


module.exports = CampaignOverallProgress;