'use strict';
var BasicInfo = require('./basic-info');
var BulletGraph = require('scripts/widgets/bullet-graph');
var StatsPreview = require('scripts/widgets/stats-preview');

var Overview = React.createClass({

    getInitialState: function () {
        return {
            d: this.props.model.computeDisplayData(),
            s: this.props.model.toJSON()
        };
    },

    componentDidMount: function () {
        var privateCh = this.privateCh = Backbone.Radio.channel(_.uniqueId('private-channel'));

        privateCh.listenTo(this.props.model, 'stats:loaded', ()=>this.setState({d: this.props.model.computeDisplayData()}));
        privateCh.listenTo(this.props.model, 'change', ()=>this.setState({
            s: this.props.model.toJSON(),
            d: this.props.model.computeDisplayData()
        }));

        this.props.ch.comply(`campaign:ads:${this.props.model.id}:focus`, ()=>this.onFocus());
    },

    onFocus() {
        var $el = $(this.getDOMNode());
        var top = $el.offset().top;
        $('html, body').animate({scrollTop: top + 'px'});
        $el.animateHighlight({highlightColor:'rgba(204, 238, 255, 0.5)', isReact:true, duration: 2000 });
    },

    componentWillUnmount() {
        this.privateCh.reset();
        this.props.ch.stopComplying(`campaign:ads:${this.props.model.id}:focus`);
    },

    getSettingsForBulletGraph: function () {
        var ad = this.props.model;
        return ad.getBulletGraphAttributes();
    },

    render: function () {
        var {d, s} = this.state;
        return (
            <div className="ef-ad-overview row">
                <div className="col-lg-4">
                    <BasicInfo ad={this.props.model} ch={this.props.ch}></BasicInfo>
                </div>
                <div className="col-lg-3" style={{marginTop: '10px'}}>
                    <BulletGraph settings={this.getSettingsForBulletGraph()} paused={s.status === 'paused'} ch={this.props.ch} ></BulletGraph>
                </div>
                <div className="col-lg-5">
                    <StatsPreview showType={this.props.showType} d={d} campaign={this.props.model}  ch={this.props.ch}/>
                </div>
            </div>
        );
    }

});


module.exports = Overview;
