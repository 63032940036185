'use strict';

var op = objectPath;
var adsReducer = require('./ads');
var creativesReducer = require('./creatives');

var initialCampaignsState = {
    'schema': {
        'ads': {
            'schema': {
                'attr': {}
            },
            'entities':{}
        },
        'creatives': {
            'schema': {
                'attr': {}
            },
            'entities':{}
        },
        'attr': {}
    },
    'entities': {}
};

function campaignsReducer (state = initialCampaignsState, action) {
    switch (action.type) {
        case 'campaign_progress_page_loaded':
            const campaign = action.payload.campaign;
            const campaignEntity = op.get(state, `entities.${campaign.id}`, state.schema);

            return {
                ...state,
                entities: {
                    ...state.entities,
                    [campaign.id]: {
                        ...campaignEntity,
                        attr: campaign,
                        ads: adsReducer(campaignEntity.ads, action.payload.ads),
                        creatives: creativesReducer(campaignEntity.creatives, action.payload.creatives)
                    }
                }
            };

        default:
            return state;
    }
}



module.exports = campaignsReducer;