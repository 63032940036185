<td>{{email}}</td>
<td>{{first_name}}</td>
<td>{{last_name}}</td>

{{#if can_access_roles}}
<td>{{role}}</td>
{{/if}}

<td>{{id}}</td>

{{#if can_access_orgs}}
<td>{{organization}}</td>
{{/if}}

<td class="ops">
  <i class="fa fa-chevron-down" data-action="toggle"></i>
</td>
