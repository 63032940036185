'use strict';

var User = Mario.Model.extend({
    initialize: function () {
        this.on('change:role', function (m, v) {
            if (_.isEmpty(v)) {
                m.set('role', null);
            }
        });
    },

    urlRoot: 'users/',
    defaults: {
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        twitter: '',
        phone: '',
        suspended: false,
        role: null,
        organization: null
    },

    validation: {
        email: function (val) {
            if (!this.isNew()) {
                return;
            }

            if (!Backbone.Validation.patterns.email.test(val)) {
                return 'Email must be valid.';
            }
        }

        // password: function (passwd) {
        //     if (this.isNew() && _.isEmpty(passwd)) {
        //         return 'Password is required';
        //     }
        // }
    }
});
var Users = Mario.Collection.extend({
    model: User,
    url: 'users/',
    comparator: 'email'
});

exports = module.exports = Users;
exports.User = User;