
<div class="modal-header">
    {{#if id}}
        <h3>Edit User</h3>
    {{else}}
        <h3>Create a user</h3>
    {{/if}}
</div>

<div class="modal-body">
    <div class="form-group">
        <label class="control-label col-xs-2">Name</label>
        <div class="col-xs-4">
            <input class="form-control" type="text" data-id="first_name" placeholder="First name"/>
            <div class="help-block "></div>
        </div>

        <div class="col-xs-4">
            <input class="form-control" type="text" data-id="last_name" placeholder="Last Name" />
            <div class="help-block "></div>
        </div>
    </div>

    <div class="form-group">
        <label class="control-label col-xs-2">Email</label>
        <div class="col-xs-6">
            <input class="form-control" type="email" data-id="email" {{#if id}} disabled {{/if}} placeholder="Email Address"/>
            <div class="help-block "></div>
        </div>
    </div>

    <div class="form-group">
        <label class="control-label col-xs-2">Contacts</label>
        <div class="col-xs-4">
            <input class="form-control" type="text" data-id="phone" placeholder="Phone number" />
            <div class="help-block "></div>
        </div>
    </div>

    <div class="form-group">
    {{#if can_access_roles}}
        <label class="control-label col-xs-2">Role</label>
        <div class="col-xs-4">
            <select class="form-control" data-id="role"></select>
        </div>
    {{/if}}
    </div>
</div>

<div class="modal-footer">
    <button class="btn btn-primary" data-action="save">
        <i class="fa fa-floppy-o"></i>
        Save</button>
</div>
