'use strict';

var Organizations = require('./organizations');

var MyOrg = Organizations.Organization.extend({
    url: 'organization/'
});


module.exports = MyOrg;
