'use strict';


function creativesReducer (state, creativesAsArray) {
    const creativesAsMap = _.reduce(creativesAsArray, (map, creative) => ({
        ...map,
        [creative.id]: {
            attr: creative
        }
    }), {});

    return {
        ...state,
        entities: {
            ...state.entities,
            ...creativesAsMap
        }
    };
}

module.exports = creativesReducer;