'use strict';

var Resource = Mario.Model.extend({
    modelName: 'Resource',
    initialize: function () {
        this.on('invalid', function (model, error) {
            alert(error);
        });
    },

    urlRoot: 'resources/',
    defaults: {
        name: '',
        disabled: true
    }
});
var Resources = Mario.Collection.extend({
    model: Resource,
    url: 'resources/'
});
module.exports = Resources;