'use strict';

var Users = require('./users');

var OrgUser = Users.User.extend({
    initialize: function (attrs, options) {
        _.extend(this, _.pick(options, 'urlRoot'));
    },

    url: function() {
        var base = _.result(this.collection, 'url') || _.result(this, 'urlRoot');

        if (this.isNew()) {
            return base;
        }

        return base.replace('?', '/' + encodeURIComponent(this.id) + '?');
    },
});

var OrganizationUsers = Users.extend({
    model: OrgUser,
    initialize : function (models, options) {
        this.url = 'users?organization=' + options.collectionId;
    }
});


module.exports = OrganizationUsers;