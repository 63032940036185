'use strict';
// singleton map
var template = require('./templates/map');
var editable = true;
var rectangles = [];
var selectedRectangle;
var mapMarkers = [];
var mapDiv = $('<div id="boxing-map" class="hide"></div>');
var map = null;
var drawingManager = null;

var initMap = function () {

    var mapOptions = {
        center           : new google.maps.LatLng(43.6481, -79.4042),
        zoom             : 9,
        streetViewControl: false,
        mapTypeId        : google.maps.MapTypeId.ROADMAP
    };

    mapDiv.appendTo('body');
    mapDiv.html(template());
    map = new google.maps.Map(mapDiv.find('>.map').get(0), mapOptions);
    drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: true, drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER, drawingModes: [google.maps.drawing.OverlayType.RECTANGLE]
        }
    });


    var mapSearch = new google.maps.places.SearchBox(mapDiv.find('#map-search').get(0));
    google.maps.event.addListener(mapSearch, 'places_changed', function () {
        var places = mapSearch.getPlaces(), i, place, marker;
        for (i = 0, marker; (marker = mapMarkers[i]); i = i + 1) {
            marker.setMap(null);
        }
        mapMarkers = [];
        var bounds = new google.maps.LatLngBounds();
        for (i = 0, place; (place = places[i]); i = i + 1) {
            var image = {
                url       : place.icon,
                size      : new google.maps.Size(71, 71),
                origin    : new google.maps.Point(0, 0),
                anchor    : new google.maps.Point(17, 34),
                scaledSize: new google.maps.Size(25, 25)
            };
            var mkr = new google.maps.Marker({
                map: map, icon: image, title: place.name, position: place.geometry.location
            });
            mapMarkers.push(mkr);
            bounds.extend(place.geometry.location);
        }
        map.fitBounds(bounds);
        // Prevent getting too close
        if (map.getZoom() > 15) {
            map.setZoom(15);
        }
    });
    google.maps.event.addListener(drawingManager, 'rectanglecomplete', function (rectangle) {
        rectangles.push(rectangle);
        rectangle.addListener('click', function () {
            selectRectangle(rectangle);
        });
        rectangle.addListener('bounds_changed', function () {
            triggerUpdate();
        });
        triggerUpdate();
    });
};
function unselectRectangle () {
    if (selectedRectangle) {
        selectedRectangle.setOptions({
            strokeColor: '#000000', fillColor: '#000000', editable: false, draggable: false
        });
        selectedRectangle = null;
    }
}

function selectRectangle (rectangle) {
    unselectRectangle();
    selectedRectangle = rectangle;
    selectedRectangle.setOptions({
        strokeColor: '#FF0000', fillColor: '#FF0000', editable: true, draggable: true
    });
    $(':focus').blur();
}

function getCoords () {
    var coords = [];
    _.each(rectangles, function (rectangle) {
        var bounds = rectangle.getBounds(), boundsSW = bounds.getSouthWest(), boundsNE = bounds.getNorthEast();
        coords.push({
            N: boundsNE.lat(), S: boundsSW.lat(), E: boundsNE.lng(), W: boundsSW.lng()
        });
    });
    return coords;
}

function fitRectanglesInMap () {
    var rectangles = getCoords();
    if (rectangles.length > 0) {
        var maxN = rectangles[0].N, maxS = rectangles[0].S, maxE = rectangles[0].E, maxW = rectangles[0].W;
        // Loop through all rectangles and get the max combined
        _.each(rectangles, function (rectangle) {
            maxN = Math.max(maxN, rectangle.N);
            maxS = Math.min(maxS, rectangle.S);
            maxE = Math.max(maxE, rectangle.E);
            maxW = Math.min(maxW, rectangle.W);
        });
        var mapBoundsNE = new google.maps.LatLng(maxN, maxE), mapBoundsSW = new google.maps.LatLng(maxS, maxW);
        map.fitBounds(new google.maps.LatLngBounds(mapBoundsSW, mapBoundsNE));
    }
}

function triggerUpdate () {
    mapDiv.trigger('coords-updated', getCoords());
}

var clearRectangles = function () {
    _.each(rectangles, function (rec) {
        rec.setMap(null);
    });
    rectangles = [];
};

var clear = function () {
    clearRectangles();
    _.each(mapMarkers, function (marker) {
        marker.setMap(null);
    });
    mapMarkers = [];
    triggerUpdate();
};
var removeSelected = function () {
    if (selectedRectangle) {
        selectedRectangle.setMap(null);
        rectangles = _.filter(rectangles, function (_rectangle) {
            return _rectangle !== selectedRectangle;
        });
        selectedRectangle = null;
    }
    triggerUpdate();
};

module.exports = {
    ele: mapDiv, map: map, getCoords: getCoords, fitRectanglesInMap: fitRectanglesInMap, detach: function () {
        mapDiv.detach();
        mapDiv.off();
        clear();
        mapDiv.addClass('hide');
        mapDiv.appendTo('body');
    },

    clear: clear, removeSelected: removeSelected, resize: function () {
        google.maps.event.trigger(map, 'resize');
    },

    init: function (_editable) {
        initMap();
        google.maps.event.trigger(map, 'init');
        editable = _editable;
        if (editable) {
            drawingManager.setMap(map);
            mapDiv.find('.remove-all-boxes, .remove-selected-box').show();
        } else {
            drawingManager.setMap(null);
            mapDiv.find('.remove-all-boxes, .remove-selected-box').hide();
        }
        mapDiv.removeClass('hide');
        return mapDiv.detach();
    },

    setValues: function (recs) {
        clearRectangles();
        rectangles = _.map(recs, function (rectangle) {
            var rectangleBounds, rectangleBoundsSW, rectangleBoundsNE;

            // build bounds
            rectangleBoundsSW = new google.maps.LatLng(rectangle.S, rectangle.W);
            rectangleBoundsNE = new google.maps.LatLng(rectangle.N, rectangle.E);
            rectangleBounds = new google.maps.LatLngBounds(rectangleBoundsSW, rectangleBoundsNE);

            var rect = new google.maps.Rectangle({
                bounds: rectangleBounds, map: map
            });

            if (editable) {
                rect.addListener('click', function () {
                    selectRectangle(rect);
                });
                rect.addListener('bounds_changed', function () {
                    triggerUpdate();
                });
            }
            return rect;
        }, this);

    }
};
