<td>
    <span data-id="name"></span>
    {{#if can_update_role}}
        <i class="fa fa-pencil-square-o" data-action="edit"></i>
    {{/if}}
</td>
<td data-id="status"></td>
<td data-id="id"></td>

{{#if can_access_orgs}}
    <td data-id="organization"></td>
{{/if}}

<td class="ops">
    <i class="fa fa-chevron-down" data-action="toggle"></i>
</td>
