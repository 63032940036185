'use strict';

var assetTemplate    = require('./templates/asset');
var documentTemplate = require('./templates/document');
var editNameTemplate = require('./templates/edit-name');
var nameTemplate     = require('./templates/name');


// shared with AssetItem and DocumentItem
var shared = {
    initialize: function () {
        this.listenTo(this.model, 'change', this.render);
    },
    tagName: 'tr',

    events: {
        'blur .name + .input-group input' : 'cancel',
        'click [data-action=edit]'    : 'edit',
        'click [data-action=cancel]'  : 'cancel',
        'click [data-action=save]'    : 'save',
        'click [data-action=remove]'  : 'delete',
        'keypress input'              : 'checkEnter'
    },

    templateHelpers: function () {
        return {
            embed: this.options.embed,
            id: this.options.radioId,
            can_update: _.include(this.options.campaign.get('_permissions'), 'update')
        };
    },

    checkEnter: function (ev) {
        var code = ev.keyCode || ev.which;
        if (code === 13) {
            this.save();
        }
    },

    delete: function () {
        var r = confirm('Are you sure to remove the file: ' + this.model.get('name'));
        if (r) {
            this.model.destroy({wait: true, success: function () {
                toastr.success('File is deleted successfully.');
            }});
        }
    },

    edit: function (ev) {
        if (this.$el.is('.edit')) {
            return;
        }
        this.$('span.name').hide();
        this.$('span.name + .input-group').removeClass('hide');
        this.$el.addClass('edit');
        this.$('span.name + .input-group input').focus();
    },

    cancel: function (ev) {

        if (!ev || !$(ev.relatedTarget).is('[data-action=save]')) {
            this.$('span.name').show();
            this.$('span.name + .input-group').addClass('hide');

            this.$el.removeClass('edit');
            return false;
        }
    },

    save: function () {
        var that = this;
        this.model.save({name: this.$('[data-id=name]').val()}, {
            success: function (m) {
                toastr.success('Saved successfully');
                that.cancel();
            }
        });
    }
};


var AssetItem = Mario.Layout.extend({
    className: 'asset',
    template: assetTemplate,

    onRender: function () {
        if (this.options.embed) {
            this.$el.addClass(this.model.get('type'));
        }
        this.$el.data('asset', this.model);
        this.$el.attr('data-id', this.$el.data().asset.id)

        this.$el.show();

        if ( this.options.meta_creative && ( this.options.meta_creative.section === 'video')) {
                // all video size are allowed, no need to check size for video.
                this.$el.attr('js-selectable', true);
        }  else {
            if (this.model.matchFilter2(this.options.meta_creative)) {
                this.$el.attr('js-selectable', true);
            } else {
                this.$el.attr('js-selectable', false);
            }
        }
    },

    onShow: function () {
        if (this.options.embed) {
            this.undelegateEvents();
        }

        this.verticalCenterAsset();
    },

    verticalCenterAsset: function () {

        var that = this;

        if (this.options.embed) {

            this.$('.preview > div:first-child > img, .preview > div:first-child > video').on('load', function () {
                var containerHeight = that.$('.preview').height();
                var assetHeight = $(this).height();
                that.$('.preview > div:first-child').css({'padding-top': (containerHeight - assetHeight) / 2 + 'px'});
            });

        }
    }
});
_.extend(AssetItem.prototype, shared);

var DocumentItem = Mario.ItemView.extend({
    className: 'document',
    template: documentTemplate
});
_.extend(DocumentItem.prototype, shared);



exports.AssetItem = AssetItem;
exports.DocumentItem = DocumentItem;
