<div class="row">
    <div class="col-xs-2">
        <label for="">Name</label>
    </div>

    <div class="col-xs-4">
        <span data-id="first_name"></span>
        <span data-id="last_name"></span>
    </div>
</div>


<div class="row">
    <div class="col-xs-2">
        <label for="">Email</label>
    </div>
    <div class="col-xs-3">
        <span data-id="email"></span>
    </div>

    <div class="col-xs-2">
        <label for="">Role</label>
    </div>
    <div class="col-xs-3">
        <span data-id="role"></span>
    </div>
</div>


<div class="row">
    <div class="col-xs-2">
        <label for="">Suspended</label>
    </div>
    <div class="col-xs-3">
        <span data-id="suspended"></span>
    </div>
</div>

<div class="row">
    <div class="col-xs-2">
        <label for="">Phone</label>
    </div>
    <div class="col-xs-3">
        <span data-id="phone"></span>
    </div>
    <div class="col-xs-2">
        <label for="">Twitter</label>
    </div>
    <div class="col-xs-3">
        <span data-id="twitter"></span>
    </div>
</div>

{{#if can_update_user}}
<button class="btn btn-primary btn-sm" data-action="edit">
    <i class="fa fa-pencil"></i>Edit
</button>
{{/if}}
{{#if can_delete_user}}
<button class="btn btn-danger btn-sm" data-action="delete">
    <i class="fa fa-trash"></i>Delete
</button>
{{/if}}
