<ul class="nav nav-tabs">
    {{#if can_access_users}}
    <li class="active"><a href="#org-users-{{org_id}}" data-bypass data-toggle="tab">Users</a> </li>
    {{/if}}
    {{#if can_access_roles}}
    <li><a href="#org-roles-{{org_id}}" data-bypass data-toggle="tab">Roles</a> </li>
    {{/if}}
</ul>

<div class="tab-content">
    {{#if can_access_users}}
    <div id="org-users-{{org_id}}" class="tab-pane active org-users">
          <div class="loader"><i class="fa fa-refresh fa-spin"></i>Loading...</div>
    </div>
    {{/if}}

    {{#if can_access_roles}}
    <div id="org-roles-{{org_id}}" class="tab-pane org-roles">
          <div class="loader"><i class="fa fa-refresh fa-spin"></i>Loading...</div>
    </div>
    {{/if}}
</div>
