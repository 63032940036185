'use strict';

var idAttribute = 'sess-sdk9sak11';
var idAttributeVal = '8dkdj2115kj';
var env = require('scripts/env');

var Session = Mario.Model.extend({
    /* jshint camelcase: false */
    defaults: {
        auth_token     : '',
        auto_renew     : false,
        disabled       : false,
        last_accessed  : '',
        organization   : '',
        permissions    : [],
        session_length : 0,
        user           : '',
        has_child      : false,

        timezone       : 'GMT',
        dateformat    : 'YYYY-MM-DD HH:mm:ss A'
    },

    idAttribute: idAttribute,

    localStorage: new Backbone.LocalStorage(idAttribute),

    initialize: function () {
        this.on('sync change', function () {
            if (typeof uTracking !== 'undefined') {
                // omit sensitive data
                uTracking('set:session', _.omit(this.toJSON(), ['auth_token']));
            }
        });

        this.set(idAttribute, idAttributeVal);

        this.on('change:auth_token', function () {
            this.set('is_valid', this.isValid());
            this.updateRequestToken();
        });

        this.on('destroy', function () {
            this.destroyServerToken();
        });

        this.on('sync', function () {
            if (this.has('user') && this.get('user').email) {
                this.setupTrackingUser();
            }
            // TODO load sessions models

            // sync session token with cookie token to avoid inconsistency
            var authCookie = $.cookie('authorization');
            this.set('auth_token', authCookie, {silent: true});
        });

        this.fetch({reset: true});
    },

    updateRequestToken: function () {
        var that = this;
        $.ajaxPrefilter('json', function (option, originalOption, jqXHR) {
            if (!option.headers) {
                option.headers = {};
            }
            option.headers['Authorization'] = that.get('auth_token');
        });
    },

    isValid: function () {
        return this.has('auth_token') && !_.isEmpty(this.get('auth_token'));
    },

    can: function (/* action1, action2, res */ ) {
        var args = _.toArray(arguments);
        var res = _.last(args);
        var actions = args.slice(0, -1);
        var permittedRes = this.get('permissions');
        var resObj = _.find(permittedRes, function (r) {return r.resource === res;});
        if (!resObj) {
            return false;
        }
        return _.every(actions, function (a) {
            // update == edit
            // update == edit
            if (a === 'edit') {a = 'update';}
            return _.include(resObj.actions, a);
        });
    },

    setupTrackingUser: function () {
        var email = this.get('user').email;

        if (env.BUG_REPORTING_ENABLED) {
            Bugsnag.user = this.get('user');
        }
    },

    destroyServerToken: function () {
        if (!this.has('auth_token')) { return;}
        var token = this.get('auth_token');
        /* jshint newcap: false */
        Q($.ajax({
            type: 'delete',
            url: 'auth/' + token,
            crossDomain: true,
            headers: {
                Authorization: token
            }
        })).then(function () {
            // cross page flash
            localStorage.setItem('_flash', 'Signed out successfully');
            window.location.href = '/signin';
        }, function () {
            window.location.href = '/signin';
        });
        this.unset('auth_token');
    },

    startHeartbeating: function () {
        this._beepStopped = env.NO_BEEPING;

        var that = this;
        setTimeout(function () {
            that.beep();
        }, 1000);
    },

    beep: function () {
        if (this._beepStopped) {
            return;
        }

        var that = this;
        var app = require('scripts/core').app;
        var user = globalCh.request('cache', 'user');
        user.done(function (user) {
            checkClientVersion();
            user.fetch().done(function () {
                    // check heart beat every 5mins
                    that.beeper = _.delay(_.bind(that.beep, that), 300000);
                });
        });


        function checkClientVersion() {
            var host = window.location.host;
            var protocol = window.location.protocol;

            $.get(protocol + '//' +  host + '/version.txt', function (version) {
                var curVersion = env.VERSION;
                if (curVersion != version) {
                    globalCh.trigger('app:update:available', version);
                }
            }, 'text');
        }

    },

    stopHeartbeating: function () {
        this._beepStopped = true;

        if (this.beeper) {
            clearTimeout(this.beeper);
        }
    },

    getOrganizationId: function () {

        if (_.isObject(this.get('organization'))) {
            return this.get('organization').id;
        } else {
            return null;
        }
    },

    getFilterState: function (which) {
        var states = sessionStorage.getItem('filter_states');

        if (states === null) {
            states = {};
        }  else {
            try {
                states = JSON.parse(states);
            } catch (e) {
                states = {};
            }
        }

        if (_.isEmpty(states)) {
            return {};
        }

        if (which != null) {
            return states[which] || {};
        }

        return states;
    },

    saveFilterState: function (which, newState) {
        var states = sessionStorage.getItem('filter_states');

        if (states === null) {
            states = {};
        } else {
            try {
                states = JSON.parse(states);
            } catch (e) {
                states = {};
            }
        }

        if (typeof which === 'string') {
            if (_.isEmpty(states[which])) {
                states[which] = {};
            }

            // reset the object when it is specific
            states[which] = newState;

        }
        if (typeof which === 'object') {
            _.extend(states, which);
        }

        sessionStorage.setItem('filter_states', JSON.stringify(states));
    },

    saveViewPreference: function (change) {
        var vPref = this.getViewPreference();

        /* {object} change
         * @propety {string} show_type_for_ads - percentages or values
         * @propety {string} show_type_for_campaigns - percentages or values
         * @propety {string} show_type_for_creatives - expanded or compact
         */

        if (typeof change === 'object') {
            _.extend(vPref, change);
        }

        localStorage.setItem('view_prefs', JSON.stringify(vPref));
        globalCh.trigger('saved-view-pref');
    },

    getViewPreference: function () {
        var vPref = localStorage.getItem('view_prefs');

        if (vPref === null) {
            vPref = {};
        } else {
            try {
                vPref = JSON.parse(vPref);
            } catch (e) {
                vPref = {};
            }
        };
        return vPref;
    }
});

module.exports = Session;
