'use strict';

var statsReducer = require('./stats');

var initialState = {
    'stats': {
        'bodyItems': [],
        'headerItems': [
            { 'name': 'Date', 'value': 'Total' },
            { 'name': 'Impressions', 'value': '-' },
            { 'name': 'Clicks', 'value': '-' },
            { 'name': 'Spend', 'value': '-' },
            { 'name': 'CTR', 'value': '-' }
        ],
        'deps': {}
    },
    'title': ''
};

function statsTableReducer (state = initialState, resources, session, campaignId) {
    const campaign = objectPath.get(resources, `campaigns.entities.${campaignId}.attr`, {});
    const progressSession = objectPath.get(session, `campaigns.progress.instances.${campaign.id}`, {});
    const adId = progressSession.adId;

    return {
        ...state,
        title: adId ? 'Ad Stats' : 'Overall Stats',
        stats: statsReducer(state.stats, resources, session, campaignId)
    };
}



module.exports = statsTableReducer;