'use strict';

var DDMenuItem = React.createClass({
    render : function () {
        var enable = this.props.enable;
        if (enable && this.props.dataBypass === 'redirect') {
            return (
                <a href={this.props.url} className="ef-sdm-list-group-item" data-bypass='redirect' onClick={this.onClick}>
                    <h5>
                        <i className={"fa fa-lg fa-fw " + this.props.icon}></i>
                        <span>{this.props.name}</span>
                    </h5>
                </a>
            );
        } else if (enable && this.props.dataBypass !== 'redirect') {
            return (
                <a href={this.props.url} className="ef-sdm-list-group-item" onClick={this.onClick}>
                    <h5>
                        <i className={"fa fa-lg fa-fw " + this.props.icon}></i>
                        <span>{this.props.name}</span>
                    </h5>
                </a>
            );
        } else if (!enable) {
            return (
                <a href='' className="ef-sdm-list-group-item disable">
                    <h5>
                        <i className={"fa fa-lg fa-fw " + this.props.icon}></i>
                        <span>{this.props.name}</span>
                    </h5>
                </a>
            );
        }
    },
    onClick: function (ev) {
        this.props.onClick(ev);
    }
});

var DDMenu = React.createClass({
    showMenu: function (ev) {
        $(ev.currentTarget).closest('.ef-slidedown-menu').addClass('is-focus');
    },

    handleMenuSelect: function (idx, ev) {
        var $currentTarget = $(ev.currentTarget);
        var dataBypass = $currentTarget.attr('data-bypass');
        if (dataBypass === 'redirect') {
            var href = $currentTarget.attr('href');
            ev.preventDefault();
            window.open(href);
            return;
        } else {
            $currentTarget.closest('.ef-slidedown-menu').removeClass('is-focus');
        }
    },

    render: function () {
        if (this.props.menuItems.length === 0) {
            return <div></div>;
        }

        var theme = this.props.theme;
        var selected = this.props.selected;
        return (
            <div  tabIndex="0" className={ theme === "light" ? "ef-slidedown-menu" : "ef-slidedown-menu ef-slidedown-menu-dark" }>
                <div className="ef-sdm-list-group ef-slidedown-menu-active" >
                    <a href="#" className="ef-sdm-list-group-item" onClick={this.showMenu}  >
                        <h5>
                            <div>
                                <i className={"fa fa-lg fa-fw " + this.props.menuItems[selected].icon}></i>
                                <span>{this.props.menuItems[selected].name}</span>
                            </div>
                            <i className="fa fa-lg fa-angle-down" ></i>
                        </h5>
                    </a>
                </div>

                <div className="ef-sdm-list-group ef-slidedown-menu-list">
                    {this.props.menuItems.map(function (item, idx) {
                        return <DDMenuItem key={idx} icon={item.icon} name={item.name} dataBypass={item.dataBypass} url={item.url} enable={item.enable}
                            onClick={this.handleMenuSelect.bind(this, idx)}></DDMenuItem>
                    }, this)}
                </div>

            </div>
        );

    }
});

module.exports = DDMenu;
