<form class="form-auth" id="signin-form" role="form" action="/signin" method="post">
    <h2 class="form-auth-heading">Please Sign in</h2>

    <div class="form-group">

        <input type="text" tabindex="1" id="signin-email" class="form-control"
            placeholder="E-mail Address" name="username" value="" required autofocus autocomplete="username">

        <input type="password" tabindex="2" name="password" id="signin-password" class="form-control" placeholder="Password" required autocomplete="current-password">

        <div class="help-block hidden"></div>
    </div>

    <button class="btn btn-lg btn-primary btn-block" id="signin" type="submit" tabindex="3">Sign in</button>
    <a href="/signin/forgot" class="password-reset">Reset Password</a>
</form>
