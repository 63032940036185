'use strict';

var ListHeader = React.createClass({

    render() {
        var total, end, start;
        var total = this.props.collection.length;
        var end = this.props.filteredCount;
        var start = ~~ (end > 0);
        
        return (
            <div className="list-header visible-lg">
                <div className="row">
                    <div className=" col-xs-8 col-lg-6">
                        <strong>{start}–{end} of {total} creatives</strong>
                    </div>
                    <div className=" col-xs-4 col-lg-3">
                        <strong>Preview</strong>
                    </div>
                    <div className="col-lg-1 visible-lg">
                        <strong>Format</strong>
                    </div>
                    <div className="col-lg-1 visible-lg">
                        <strong>Size</strong>
                    </div>
                    <div className="col-lg-1 visible-lg">
                        <strong>Simulator</strong>
                    </div>
                </div>
            </div>

        );
    }
});


module.exports = ListHeader;