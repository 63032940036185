<div class="switch-toggle {{clazz}}">
    <input id="pos_{{uid}}" name="{{uid}}" type="radio" value="{{pos}}"
        {{#if pos_checked}}
           checked
        {{/if}}
            >
    <label for="pos_{{uid}}">{{pos}}</label>

    <input id="neg_{{uid}}" name="{{uid}}" type="radio" value="{{neg}}"
        {{#unless pos_checked}}
           checked
        {{/unless}}
            >
    <label for="neg_{{uid}}">{{neg}}</label>

    <a></a>
</div>
