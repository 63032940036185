'use strict';

// assets are alias to files

var Model = Mario.Model.extend({
    modelName: 'Asset',
    initialize: function (attrs, options) {
        options = options || {};
        _.extend(this, _.pick(options, 'urlRoot'));
    },

    defaults: {
        name: '',
        type: '',
        url: '',
        preview_url: '',
        thumbnail_url: '',
        duration: 0,
        width: 0,
        height: 0
    },

    isAsset: function () {
        return this.get('type').match(/image|video/i);
    },

    isDoc: function () {
        return this.get('type').match(/pdf|txt|doc|xls/i);
    },

    searchTerm: function (term) {
        if (typeof term !== 'string') return true;
        return _.contains(this.get('name'), term);
    },

    matchFilter: function (format) {
        /* jshint maxcomplexity: 8 */
        var formatMatch;

        switch (format) {
            case 'standard':
            case 'native':
                formatMatch = this.get('type') === 'image';
                break;
            case 'video':
                formatMatch = this.get('type') === 'video';
                break;
            default:
                formatMatch = true;
        }

        return formatMatch;
    },


    matchFilter2: function (creativeMeta) {
        /* jshint maxcomplexity: 8 */

        // creative parameters to compare
        var cWidth = creativeMeta.creativeWidth;
        var cHeight = creativeMeta.creativeHeight;
        var cFormat = creativeMeta.format;
        var cSection = creativeMeta.section;

        // asset paramterers to compare
        var aWidth = this.get('width');
        var aHeight = this.get('height');
        var aType = this.get('type');

        var match = false;


        var isSameDpr = function( aWidth, aHeight, cWidth, cHeight ) {
            var cAspectRatio = cWidth / cHeight;
            var aAspectRatio = aWidth / aHeight;
            if (cAspectRatio  === aAspectRatio ) {
                var DevicePixelRatio = aWidth / cWidth;
                if (
                       DevicePixelRatio === 1
                       || DevicePixelRatio === 2
                       || DevicePixelRatio === 3
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }

        switch (cSection) {
            case 'video':
                if ( aType === 'video' && isSameDpr(aWidth, aHeight, cWidth, cHeight) ) match = true;
                break;
            case 'companionAd':
            case 'banner':
            case 'referenceImg':
            case 'content':
            case 'icon':
            case 'mainImg':
                if ( aType === 'image' && isSameDpr(aWidth, aHeight, cWidth, cHeight) ) match = true;
                break;
            default:
                match = false;
        }

        return match;
    },

    getDimension: function () {
        return this.get('width') + 'x' + this.get('height');
    }

});


var Collection = Mario.Collection.extend({
    model: Model,
    comparator: function (a, b) {
        return a.id > b.id ? -1 : 1;
    },

    initialize: function (models, options) {
        this.url = 'campaigns/' + options.collectionId + '/files';
    },

    assets: function () {
        return this.filter(function (c) {
            return c.isAsset();
        });
    },

    documents: function () {
        return this.filter(function (c) {
            return c.isDoc();
        });
    },

    refresh: function () {
        this.trigger('refresh:start');

        var that = this;

        return Q(this.fetch({})).done(function () {
            that.trigger('refresh:done');
        });
    }
});
module.exports = Collection;
