'use strict';

/* *
 * @typedef {Object} Settings
 * @property {float} wRemaining
 * @property {float} wToday
 * @property {float} wSuccess
 * @property {UTCString} start
 * @property {UTCString} end
 * @property {string} timezone
 * @property {boolean} paused
 * *
 */

/**
 * For component props *
 * @param {Settings} settings *
 * @param {boolean} withoutDateLabels 
 * @param {boolean} paused
 * @param {boolean} withProgressLabel
 * @param {string} variation - // can be 'normal' | 'time-only' | 'progress-only'
 */

var BulletGraph = React.createClass({
    
    getProgressBarsAndDateLabels() {
        var {wRemaining, wToday, wSuccess, start, end, timezone} = this.props.settings;

        var startDate, endDate;

        if (timezone != null && start != null && end != null) {
            startDate = moment(start).tz(timezone); // .format('MMM D, HH:mm z');
            endDate = moment(end).tz(timezone); // .format('MMM D, HH:mm z');
        } else {
            startDate = moment.utc(start); 
            endDate = moment.utc(end); // .format('MMM D, HH:mm z');
        }

        if (startDate.isValid()) {
            startDate = startDate.format('MMM D, HH:mm z');
        } else {
            startDate = '';
        }

        if (endDate.isValid()) {
            endDate = endDate.format('MMM D, HH:mm z');
        } else {
            endDate = '';
        }

        var targetProgress;
        var sum = wRemaining + wToday + wSuccess;

        var progressStyle = {width: numeral(wSuccess).format('0.00%')};

        var remainingStyle = {width: numeral(wRemaining).format('0.00%')};

        var todayStyle = {width: numeral(wToday).format('0.00%')};
        
        if (sum > 1) {
            targetProgress = <div style={progressStyle} className="ef-progress-success ef-progress-target"></div>
        } else if (sum < 0.9) {
            targetProgress = <div style={progressStyle} className="ef-progress-danger ef-progress-target"></div>
        } else {
            targetProgress = <div style={progressStyle} className="ef-progress-warning ef-progress-target"></div>
        }

        var remainingProgress = <div style={remainingStyle} className="ef-progress-remaining " ></div>;
        var todayProgress = <div style={todayStyle} className="ef-progress-today" ></div>;

        var dateLabels = (
            <div>
                <small className="ef-progress-date-start">{startDate}</small>
                <small className="ef-progress-date-end">{endDate}</small>
            </div>
        );
        
        return {targetProgress, remainingProgress, todayProgress, dateLabels};
    },
    
    render: function () {
        var {paused, withoutDateLabels, withProgressLabel, variation} = this.props;
        var {targetProgress, remainingProgress, todayProgress, dateLabels} = this.getProgressBarsAndDateLabels();
        
        return (
            <div className="ef-progress-wrap">
                {withProgressLabel ? <div className="ef-progress-label">
                    <strong className="pull-right">Progress</strong>
                </div> : null}
                <div className={"ef-progress " + (paused ? 'ef-progress-paused' : '')}>
                    {variation !== 'progress-only'? remainingProgress:null}
                    {variation !== 'progress-only'? todayProgress:null}
                    {variation !== 'time-only'? targetProgress:null}
                </div>
                    {withoutDateLabels ? null:dateLabels}
            </div>
        );
    }
});

module.exports = BulletGraph;
