<h3>
    {{name}}
</h3>
{{#if _child}} <label class="label label-inverse">child</label>{{/if}}
<div>
    <label for="">Currency</label>
    {{currency}}
</div>


