'use strict';

var app = require('scripts/core').app;
var layoutTemplate = require('./templates/layout');
var RoleShow = require('./show');
var roleRowTemplate = require('./templates/row');
var RoleForm = require('./form');
var inlineEditNameTemplate = require('./templates/inline-edit-name');
var DataTable = require('scripts/common/mixins/datatable');


/**
 * options
 * model<Role>
 * roles<Collection<Role>>
 * organizations<Collection<Organization>>
 * organization<Organization>
 */

var RoleView = Mario.ItemView.extend({
    template: roleRowTemplate,
    tagName: 'tr',
    bindings: {
        '[data-id=id]' : 'id',
        '[data-id=name]' : 'name',
        '[data-id=organization]' : {
            observe: 'organization',
            onGet: function (val) {
                return this.options.organization.get('name');
            }
        },

        '[data-id=status]' : {
            observe: 'suspended',
            onGet: function (val) {
                return val ? 'Suspended' : 'Active';
            }
        }
    },

    events: function () {
        if (app.can('update', 'roles')) {
            return {
                'click .ops [data-action=toggle]'                          : 'toggleDetail',
                'click .ops [data-action=delete]'                          : 'deleteRole',
                'click td:first-child [data-action=save]'                  : 'saveName',
                'click td:first-child [data-action=cancel]'                : 'cancelSaveName',
                'click td:first-child i'                                   : 'showChangeName'
            };
        } else {
            return {'click .ops [data-action=toggle]': 'toggleDetail'};
        }
    },

    deleteRole: function () {
        this.model.destroy({
            success: function () {
                toastr.success('Role is deleted successfully.');
            }
        });
    },

    saveName: function (ev) {
        var td = $(ev.currentTarget).closest('td');
        this.model.save('name', $(td).find('input').val(), {
            success: function () {
                toastr.success('Role is saved successfully.');
            }
        });
        this.cancelSaveName();
    },

    cancelSaveName: function () {
        var td = this.$('td:first-child');
        td.html('<span data-id="name">' +
            this.model.get('name') +
            '</span><i class="fa fa-pencil-square-o" data-action="edit"></i>');
    },

    showChangeName: function (ev) {
        $(ev.currentTarget).parent().html(inlineEditNameTemplate({name: this.model.get('name')}));
    },

    onRender: function () {
        this.stickit();
    },

    toggleDetail: function (ev) {
        $(ev.currentTarget).toggleClass('fa-chevron-up fa-chevron-down');
        if (this.roleShow) {
            this.roleShow.destroy();
            this.roleShow = null;
            return;
        }
        this.trEl = this.trEl || $('<tr class="role-show"></tr>').insertAfter(this.$el);
        var resourcesReq = globalCh.request('cache', 'resources');
        resourcesReq.done(_.bind(function (resources) {

            var options = {
                model: this.model,
                resources: resources
            };

            this.roleShow = new RoleShow(options);
            this.roleShow.render();
            this.roleShow.$el.appendTo(this.trEl);

            this.listenTo(this.roleShow, 'close', function () {
                this.trEl.remove();
                this.trEl = null;
            });
        }, this));
    },

    templateHelpers: function () {
        return {
            can_access_orgs: app.can('list', 'view', 'organizations'),
            can_delete_role: app.can('delete', 'roles'),
            can_update_role: app.can('update', 'roles')
        };
    }
});

/**
 * options
 * collection<Collection<Role>>
 * roles<Collection<Role>> alias
 * users<Collection<User>>
 * organizations<Collection<Role>>
 * organization<Role>
 */

module.exports = Mario.CompositeView.extend({
    events: {
        'click [data-action=show-create]' : 'showCreate'
    },

    childView: RoleView,
    template: layoutTemplate,
    childViewContainer: 'tbody',
    childViewOptions: function () {
        return {
            roles: this.options.roles,
            organizations: this.options.organizations,
            organization: this.options.organization
        };
    },

    templateHelpers: function () {
        return {
            can_create_role: app.can('create', 'roles'),
            can_access_orgs: app.can('list', 'view', 'organizations')
        };
    },

    onShow: function () {
        this.$('table').dataTable();
    },

    showCreate: function () {
        var form = new RoleForm({
            collection: this.options.roles
        });

        var popup = globalCh.request('popup');
        popup.show(form);
    },

    mixins: [DataTable]
});
