'use strict';

var categoriesSelectorTemplate = require('../templates/categories-selector');
var rawCategories = require('scripts/common/constants/IAB-categories');
var catItemTemplate = require('../templates/cat-item');
var catListTemplate = require('../templates/cat-list');
var selectedCatTemplate = require('../templates/cat-selected');
var emptyCategoriesTemplate = require('../templates/cat-empty');
var EmptyCategoriesView = Mario.ItemView.extend({
    template: emptyCategoriesTemplate,
    className: 'empty-item'
});
var SelectedCategoryView = Mario.ItemView.extend({
    className: 'item label label-inverse',
    template: selectedCatTemplate,
    events: {
        'click .remove-category' : 'removeCategory'
    },

    removeCategory: function () {
        this.model.set('selected', false);
    }
});
var CatItemView  = Mario.ItemView.extend({
    template: catItemTemplate,
    className: 'checkbox',
    tagName: 'li',
    onRender: function () {
        this.$el.data('item', this.model);
        this.$el.attr('data-category-id', this.model.id);
        if (this.model.get('selected')) {
            this.$('[type=checkbox]').prop('checked', true);
        }
    }
});
var CatListView = Mario.CompositeView.extend({
    template: catListTemplate,
    childViewContainer: 'ul.list',
    childView: CatItemView,
    templateHelpers: function () {
        if (!this.options.isLevelOne) {
            return {
                title: 'Sub-categories'
            };
        } else {
            return {};
        }
    },

    onShow: function () {
        if (!this.options.isLevelOne) {
            this.$('.arrow').hide();
        }
    },

    initialize: function (options) {
        this.options = options;
    }
});
module.exports = Mario.Layout.extend({
    template: categoriesSelectorTemplate,
    className: 'categories-selector',
    regions: {
        levelOneRegion: '.level-1',
        levelTwoRegion: '.level-2',
        displayRegion: '.display'
    },

    showMenu: function () {
        this.$('.menu').removeClass('hide');
        this.$('.menu').fadeIn();
        this.$('.empty-item').hide();

        $('body').on('click.categoriesSelector', _.bind(this.blurListener, this));
    },

    blurListener: function (ev) {
        if ($.contains(this.el, ev.target)) {
            return;
        }

        this.hideMenu();
    },

    hideMenu: function () {
        $('body').off('.categoriesSelector');

        this.$('.menu').fadeOut();
        this.$('.empty-item').show();

    },

    initialize: function () {
        this.categories = new Mario.Collection();
        this.selectedCategories = new Mario.Collection();
        // note: this is a hash
        this.subCategories = {};
    },

    onBeforeRender: function () {
        this.processCategoriesData();
    },

    onRender: function () {
    },

    onShow: function () {
        var lOneList = new CatListView({
            collection : this.categories,
            className  : 'level-1-categories',
            isLevelOne : true
        });
        this.levelOneRegion.show(lOneList);
        this.$('.level-1-categories ul.list').menuAim({
            activate: _.bind(this.levelOneMenuActivate, this)
        });
        // display selected categories
        this.displayRegion.show(new Mario.CollectionView({
            collection: this.selectedCategories,
            childView: SelectedCategoryView,
            className: 'selected-categories',
            emptyView: EmptyCategoriesView
        }));
    },

    levelOneMenuActivate: function (li) {
        var $li = $(li);
        var m = $li.data('item');
        var list = this.subCategories[m.id];
        $li.addClass('selected');
        $li.siblings().removeClass('selected');
        this.levelTwoRegion.show(new CatListView({collection: list}));
    },

    listenListChanged: function (list) {
        this.listenTo(list, 'change:selected', function (m, selected) {
            if (selected) {
                this.selectedCategories.add(m);
            } else {
                this.selectedCategories.remove(m);
            }
            this.$('li[data-category-id=' + m.id + '] [type=checkbox]').prop('checked', selected);
            this.$el.trigger('change');
        });
    },

    processCategoriesData: function () {
        var pattern = /^(IAB\d+)(-\d+)?$/;
        this.listenListChanged(this.categories);
        _.each(rawCategories, function (val, key) {
            var match = key.match(pattern);
            if (!match) {
                return;
            }
            var cid = match[1];
            var lOne = this.categories.get(cid);
            if (!lOne) {
                // create top category model
                this.categories.add({id: cid, name: val});
                // initialize sub category for the model
                this.subCategories[cid] = new Mario.Collection();
                this.listenListChanged(this.subCategories[cid]);
            }
            var subs = this.subCategories[cid];
            if (match[2]) {
                // this is a sub category
                subs.add({id: key, name: val});
            }
        }, this);
    },

    events: {
        'click [type=checkbox]' : 'selectCategory'
    },

    selectCategory: function (ev) {
        var li = $(ev.currentTarget).closest('li');
        var model = li.data('item');
        if ($(ev.currentTarget).is(':checked')) {
            model.set('selected', true);
        } else {
            model.set('selected', false);
        }
    },

    setSelected: function (catIds) {
        var pattern = /^(IAB\d+)(-\d+)?$/;
        _.each(catIds, function (id) {
            var match = id.match(pattern);
            if (match) {
                if (match[2]) {
                    var subs = this.subCategories[match[1]];
                    subs.get(id).set('selected', true);
                } else {
                    this.categories.get(id).set('selected', true);
                }
            }
        }, this);
    }
});
