'use strict';


function groupsCampaignReducer (campaign) {
    return [
        {
            title: 'Basics',
            items: [
                { name: 'Name', value: campaign.name },
                { name: 'Advertiser Domain', value: campaign.advertiser_domain },
                {
                    name: 'IAB categories',
                    formatValueAs: 'bootstrap-labels',
                    value: campaign.iab_categories
                },
            ]
        },
        {
            title: 'Note',
            items: [
                { name: 'Note', value: campaign.notes }
            ]
        }
    ];
}

module.exports = groupsCampaignReducer;