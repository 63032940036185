{
    "0": "Sports",
    "1": "Finance, Business",
    "2": "Education",
    "3": "Music",
    "4": "Medical",
    "5": "Travel & Hotels",
    "6": "Food & Drink",
    "7": "Health & Fitness",
    "8": "Productivity",
    "9": "Social & Communication",
    "10" : "Books",
    "11" : "Lifestyle, Fashion, Beauty",
    "12" : "News, weather",
    "13" : "Entertainment",
    "14" : "Media",
    "15" : "Art",
    "16" : "Auto",
    "17" : "Advertisement",
    "18" : "Senior",
    "19" : "Smoking & Drinking",
    "20" : "Home & Decoration",
    "21" : "Parenting",
    "22" : "Technology & Computing",
    "23" : "Science",
    "24" : "Sexuality",
    "25" : "Career"
}
