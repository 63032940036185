'use strict';

var flux = require('scripts/core/flux');

var _users = {};

var UsersStore = Fluxxor.createStore({

    initialize: function (options) {

        this.bindActions(
            flux.constants.userProfile_fetch_success, 'onUserProfile_fetch_success'
        );
    },

    onUserProfile_fetch_success: function (user) {
        _users[user.id] = user;

        this.emit('change');
    },

    getOwnUser: function () {
        var ownUserId = flux.store('session').getOwnUserId();

        return _users[ownUserId];
    }
});

flux.addStore('users', new UsersStore());
