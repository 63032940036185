'use strict';
var Status = React.createClass({
    render: function () {
        switch(this.props.status) {
            case 'live':
                return (
                    <p className="ef-status ef-status-style-live">
                        <span>
                            <i className="fa fa-play fa-fw"></i>
                            Live
                        </span>
                    </p>
                );
            case 'scheduled':
                return (
                    <p className="ef-status ef-status-style-schedule">
                        <span>
                            <i className="fa fa-clock-o fa-fw"></i>
                            Scheduled
                        </span>
                    </p>
                );
            case 'paused':
                return (
                    <p className="ef-status ef-status-style-paused">
                        <span>
                            <i className="fa fa-pause fa-fw"></i>
                            Paused
                        </span>
                    </p>
                );
            case 'ended':
                return (
                    <p className="ef-status ef-status-style-ended">
                        <span >
                            <i className="fa fa-check fa-fw"></i>
                            Ended
                        </span>
                    </p>
                );
            case 'incomplete':
                return (
                    <p className="ef-status ef-status-style-incomplete">
                        <span>
                            <i className="fa fa-warning fa-fw"></i>
                            Incomplete
                        </span>
                    </p>
                );
            // for creative audit
            case 'no_audit':
                return (
                    <p className="ef-status ef-status-style-incomplete">
                        <span>
                            <i className="fa fa-file-text-o fa-fw"></i>
                            Draft
                        </span>
                    </p>
                );
            // for creative audit
            case 'rejected':
                return (
                    <p className="ef-status ef-status-style-rejected">
                        <span>
                            <i className="fa fa-remove fa-fw"></i>
                            Rejected
                        </span>
                    </p>
                );
            // for creative audit
            case 'approved':
                return (
                    <p className="ef-status ef-status-style-ended">
                        <span >
                            <i className="fa fa-check fa-fw"></i>
                            Approved
                        </span>
                    </p>
                );
            // for creative audit
            case 'pending':
                return (
                    <p className="ef-status ef-status-style-pending">
                        <span>
                            <i className="fa fa-clock-o fa-fw"></i>
                            Pending
                        </span>
                    </p>
                );
            // for creative audit
            case 'rejected':
                return (
                    <p className="ef-status ef-status-style-rejected">
                        <span >
                            <i className="fa fa-remove fa-fw"></i>
                            Rejected
                        </span>
                    </p>
                );
            default:
                return (
                    <p className="ef-status ef-status-style-unavailable">
                        <span >
                        {this.props.status}
                        </span>
                    </p>
                );
        };
    }
});

module.exports = Status;
