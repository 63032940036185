'use strict';
var op = objectPath;

var SummaryPopoverTemplate = React.createClass({

    propTypes: {
        title: React.PropTypes.string.isRequired,
        secondaryTitle: React.PropTypes.string.isRequired,
        callToActions: React.PropTypes.array,
        iconType: React.PropTypes.string,
        groups: React.PropTypes.array
    },

    formatGroupItemValue (groupItem) {
        switch (groupItem.formatValueAs) {
            case 'bootstrap-labels':
                return [].concat(groupItem.value)
                    .map((item, k) => <span key={item + '-' + k} className="label label-default">{item}</span>);
            default:
                return groupItem.value;
        }
    },

    iconLookup (iconType) {
        switch (iconType) {
            case 'clock':
                return <i className="fa fa-fw fa-clock-o"></i>;
            case 'pencil':
                return <i className="fa fa-pencil"></i>;
        }
    },

    render () {
        var self = this;

        return (
            <div className="popover" role="tooltip">
                <div className="arrow"></div>
                {/*
                <h3 className="summary-title">
                    <div className="pull-right">
                        {this.props.callToActions.map((cta, index) =>
                        <button key={index} onClick={cta.action} className="btn btn-default btn-sm">
                            {self.iconLookup(cta.iconType)} {cta.text}
                        </button>)}
                    </div>
                    <div className="clearfix"></div>
                </h3>
                */}
                <div className="ad-summary">
                    <div className="panel-body">

                        <div className="panel-heading">
                            <h3 className="panel-title">{this.props.title}</h3>
                        </div>

                        {this.props.groups.map((group, i) =>
                        <div key={group.title + i}>
                            <h6>{group.title}</h6>
                            <dl className="dl-horizontal">

                                {group.items.map((groupItem, j) =>
                                <div className="item-group" key={groupItem.name + i + j}>
                                    <dt>{groupItem.name}</dt>
                                    <dd>{self.formatGroupItemValue(groupItem)}</dd>
                                </div>)}
                            </dl>
                        </div>)}
                    </div>
                </div>
            </div>
        );
    }
});


module.exports = SummaryPopoverTemplate;