'use strict';

var CategorySelector = require('./categories-selector-wrapper');
var PrivateChannel = require('scripts/common/mixins/private-channel');

var SaveBtn = React.createClass({

    mixins: [PrivateChannel],

    getInitialState() {
        return {
            btnState: 'default'
        }
    },

    componentDidMount() {
        this.privateCh.listenTo(this.props.ch, 'campaign:onSaveError', this.onCampaignSaveError);
    },

    onSaveCampaign() {
        this.setState({btnState: 'saving'});
        this.props.onSave();
    },

    onCampaignSaveError() {
        this.setState({btnState: 'default'});
    },

    render() {
        var trackingSection = this.props.campaign.isNew()? 'new-campaign':'edit-campaign';
        switch (this.state.btnState) {
            case 'default':
                return (
                    <button type="button" className="btn btn-success" onClick={this.onSaveCampaign} data-track data-tracking-section={trackingSection} data-tracking-section-id={this.props.campaign.id}>
                        <i className="fa fa-check"></i>&nbsp;Save Campaign
                    </button>
                );
            break;
            case 'saving':
                return (
                    <button type="button" className="btn btn-success" disabled="true">
                        <i className="fa fa-refresh fa-spin"></i>&nbsp;Saving ...
                    </button>
                );
            break;
            default:
                //
            break;
        }
    }
});


var CampaignForm = React.createClass({

    componentDidMount() {
        var ch = this.props.ch;
        var $el = $(this.getDOMNode());
        ch.reply('campaign:form:name', ()=>$el.find('[data-id=name]').val());
        ch.reply('campaign:form:notes', ()=>$el.find('[data-id=notes]').val());
        ch.reply('campaign:form:comment', ()=>$el.find('[data-id=comment]').val());
        ch.reply('campaign:form:advertiser_domain', ()=>$el.find('[data-id=advertiser_domain]').val());
        ch.reply('campaign:form:organization', ()=>$el.find('[data-id=organization] option:selected').val());

        var $field_advertiser_domain = $el.find('[data-id=advertiser_domain]');
        $field_advertiser_domain.on('blur', this.checkAdvertiserDomain );
    },


    checkAdvertiserDomain : function(e){
        var $field = $(e.target);
        var val = $field.val();
        var valCorrected = '';
        // strip 'http://', 'https://', 'www.' prefixes
        var r1 = /^https?:\/\/(.*)$/;
        var r2 = /^w{3}\.(.*)/;
        if (typeof val === 'string') {
            val = S(val).trim().s;
            valCorrected = val.replace(r1, '$1' ).replace(r2, '$1');
        } else {
            throw new Error('Error while processing new campaign form.');
        }
        $field.val(valCorrected);
    },

    componentWillUnmount() {
        var ch = this.props.ch;
        ch.stopReplying('campaign:form:name');
        ch.stopReplying('campaign:form:notes');
        ch.stopReplying('campaign:form:advertiser_domain');
        ch.stopReplying('campaign:form:organization');
    },

    render() {
        var organizations = this.props.ch.request('organizations');

        var campaign = this.props.campaign;
        var organizationOptions = organizations.map(
            (o)=><option key={o.id} value={o.id}>{o.get('name')}</option>);

        return (
            <div className="row campaign-form">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-xs-6">
                            <div className="form-group">
                                <label htmlFor="campaign-name">Name
                                    <i className="fa fa-asterisk text-danger"></i>
                                </label>
                                <input className="form-control" defaultValue={campaign.get('name')} tabIndex="1" data-id="name"/>
                                <div className="help-block "></div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="campaign-advertiser_domain">Advertiser Domain
                                    <i className="fa fa-asterisk text-danger"></i>
                                    <i data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="Advertiser's primary or top-level domain htmlFor advertiser checking." className="fa fa-info-circle text-info" data-original-title="Advertiser Domain"></i>
                                </label>
                                <input className="form-control" tabIndex="2" defaultValue={campaign.get("advertiser_domain")}
                                    data-id="advertiser_domain" placeholder="example.com"/>
                                <div className="help-text">{"Do not include the 'http://' or 'www.' prefixes"}</div>
                                <div className="help-block "></div>
                            </div>
                        </div>
                        <div className="col-xs-6">
                            <CategorySelector ch={this.props.ch} categories={campaign.get('iab_categories')}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-6">
                            <div className="form-group">
                                <label htmlFor="campaign-notes">Notes</label>
                                <textarea className="form-control" data-id="notes" rows="6" defaultValue={campaign.get('notes')}></textarea>
                            </div>
                        </div>
                        <div className="col-xs-6">
                            <div className="form-group">
                                <label htmlFor="campaign-change-comment">Change Comment</label>
                                <textarea className="form-control" data-id="comment" rows="1" defaultValue=''></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-4">
                            <label>Organization</label>
                            <select className="form-control" data-id="organization" defaultValue={campaign.get('organization')}>
                                {organizationOptions}
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-offset-6 col-xs-6 text-right">
                            <SaveBtn onSave={this.props.onSave} ch={this.props.ch} campaign={campaign}/>
                            <button type="button" className="btn btn-link" onClick={this.props.onCancel}>
                                cancel
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
});

module.exports = CampaignForm;
