'use strict';

// @TODO use dictionary for display names


var flux = require('scripts/core/flux');
var ReactCSSTransitionGroup = React.addons.CSSTransitionGroup;
var op = objectPath;

var TableRow = React.createClass({

    expandOrCollapse () {
        if (!this.props.data.shouldShowExpandCollapseToggle) return;
        if (this.props.data.isExpanded) {
            flux.actions.reportPage.collapsePivotTableRow({
                campaignId: this.props.campaignId,
                rowKey: this.props.data.splitKey
            });
        } else {
            flux.actions.reportPage.expandPivotTableRow({
                campaignId: this.props.campaignId,
                rowKey: this.props.data.splitKey
            });
        }
    },

    doHighlight () {
        flux.actions.reportPage.focusOnRow({
            campaignId: this.props.campaignId,
            rowKey: this.props.data.splitKey
        });
    },

    shouldComponentUpdate (nextProps, nextState) {
        var shouldRender = nextProps.data.clicks !== this.props.data.clicks ||
            nextProps.data.shouldShowExpandCollapseToggle !== this.props.data.shouldShowExpandCollapseToggle ||
            nextProps.data.isExpanded !== this.props.data.isExpanded ||
            // ((nextProps.focusedRow === this.props.data.splitKey) !== (this.props.focusedRow === this.props.data.splitKey)) ||
            (_.contains(nextProps.focusedParents, this.props.data.splitKey) !== _.contains(this.props.focusedParents, this.props.data.splitKey));

        return shouldRender;
    },

    render () {
        var highlightClass = '';
        // if (this.props.focusedRow === this.props.data.splitKey) {
        //     highlightClass = 'is-focused';
        // } else
        if (_.contains(this.props.focusedParents, this.props.data.splitKey)) {
            highlightClass = 'is-parent';
        }
        // console.log(this.props.data.splitKey, this.props.data.focusedParents, this.props.data.focusedRow);

        return (
            <tr className={highlightClass + (this.props.data.shouldShowExpandCollapseToggle ? ' is-expandable' : '')} onMouseOver={this.doHighlight} onClick={this.expandOrCollapse}>
                <td className={'am-pivotTable-dimensionValue is-split-level-' + this.props.data.level}>
                    <span style={{visibility: this.props.data.shouldShowExpandCollapseToggle ? 'visible' : 'hidden' }}
                        className="am-pivotTable-expandToggle">
                        {this.props.data.isExpanded ?
                            <i className="fa fa-fw fa-minus-square-o"></i> : <i className="fa fa-fw fa-plus-square"></i>}
                    </span>
                    <span>{this.props.data.displayName}</span>
                </td>
                <td>
                    <span>{this.props.data.impressions}</span>
                </td>
                <td>
                    <span>{this.props.data.clicks}</span>
                </td>
                <td>
                    <span>{this.props.data.ctr}</span>
                </td>
                <td>
                    <span>{this.props.data.spend}</span>
                </td>
                <td>
                    <span>{this.props.data.eCPM}</span>
                </td>
                <td>
                    <span>{this.props.data.eCPC}</span>
                </td>
            </tr>
        );
    }
});


var renderMenu = _.template(`
    <ul class="am-dropdown-menu">
        <% _.forEach(items, function(item) { %>
            <% if (_.isArray(item.value) && item.visible) { %>

                <li class="dd-clicker-item">
                    <span><%= item.label %></span> <i class="fa fa-chevron-right"></i>
                    <%= renderMenu({ items: item.value, renderMenu: renderMenu }) %>
                </li>

            <% } else if (item.visible !== false) { %>

                <li class="item"
                    data-parent="<%= item.parent %>"
                    data-label="<%= item.label %>"
                    data-value="<%= item.value %>">

                    <%= item.label %>
                </li>

            <% } %>
        <% }) %>
    </ul>
`);


var DropDownJquery = React.createClass({

    handleOutsideClick (event) {
        var self = this;

        if (!$(event.target).closest('.am-dropdown').length) {
            self.closeMenus();
        }
    },

    template: _.template(`
        <div>
            <%= button %>
            <%= menu %>
        </div>
    `),

    closeMenus () {
        this.$el.find('.am-dropdown-menu').removeClass('am-dropdown-menu__is-visible');

        var rootButtonHeight = this.$el.find('.dd-clicker').height();

        // reset root button to default position
        this.$el.find('.dd-clicker').next().css({
            left: 0,
            top: rootButtonHeight
        });

        this.$el
            .find('.dd-clicker-item')
            .find('.am-dropdown-menu')
            .css({
                left: '100%',
                top: 0
            });
    },

    renderTemplate (props) {
        var self = this;
        var $el = this.$el = $(this.getDOMNode());

        // Remove all existing events
        $el.off();

        // Step 1. Render html
        $el.html(this.template({
            button: $('<div>').append($(React.renderToStaticMarkup(props.children)).addClass('dd-clicker')).html(),
            menu: renderMenu({
                items: props.items,
                renderMenu: renderMenu
            })
        }));

        // Step 2. Apply events
        $el.find('li.item').on('click', function () {
            self.props.onSelect($(this).data());
            self.closeMenus();
        });

        // Root menu
        $el.find('.dd-clicker').on('click', function () {
            var rootButtonHeight = $el.find('.dd-clicker').height();
            var $rootMenu = $(this).next();

            $rootMenu.toggleClass('am-dropdown-menu__is-visible');

            var menuBoundingBox = $rootMenu[0].getBoundingClientRect();

            if (($(window).height() - menuBoundingBox.bottom) < 0) { // menu is below bottom of screen
                $rootMenu.css({
                    top: -1 * menuBoundingBox.height
                });
            } else {
                $rootMenu.css({
                    top: rootButtonHeight
                });
            }
        });

        // Submenu
        $el.find('.dd-clicker-item').on('click', function () {
            var rootButtonHeight = $el.find('.dd-clicker').height();
            var $submenu = $(this).find('.am-dropdown-menu');

            $submenu.toggleClass('am-dropdown-menu__is-visible');

            var menuBoundingBox = $submenu[0].getBoundingClientRect();

            if (($(window).height() - menuBoundingBox.bottom) < 0) { // menu is below bottom of screen
                $submenu.css({
                    left: '100%',
                    top: (-1 * menuBoundingBox.height) + rootButtonHeight
                });
            } else {
                $submenu.css({
                    left: '100%',
                    top: 0
                });
            }
        });
    },

    componentDidMount () {
        $(document).on('click', this.handleOutsideClick);
        this.renderTemplate(this.props);
    },

    componentWillUnmount () {
        $(document).off('click', this.handleOutsideClick);
    },

    shouldComponentUpdate (nextProps, nextState) {

        if (_.isEqual(this.props, nextProps)) {
            return false;
        }

        this.renderTemplate(nextProps);

        return false;
    },

    render () {
        var rootNodeClasses = classNames('am-dropdown', {
            // 'is-active': !this.state.isMenuHidden,
            [this.props.className]: !!this.props.className
        });

        return <div className={rootNodeClasses}></div>;
    }
});


var PivotTable = React.createClass({

    addSplit (selection) {
        flux.actions.reportPage.addSplitToPivotTable({
            campaignId: this.props.campaignId,
            selection: selection
        });
    },

    removeSplit (selection) {
        flux.actions.reportPage.removeSplitToPivotTable({
            campaignId: this.props.campaignId,
            selection: selection
        });
    },

    sortColumn (columnName) {
        flux.actions.reportPage.sortPivotTable({
            campaignId: this.props.campaignId,
            sortColumn: columnName
        });
    },

    onDimensionSelected (item) {
        flux.actions.reportPage.addSplitToPivotTable({
            campaignId: this.props.campaignId,
            selection: item
        });
    },

    onExport (item) {
        const store = flux.store('campaign-reporting');
        const exportFormat = item.value;
        // const exportString = store.getExport_pivotTable(this.props.campaignId, exportFormat);
        const exportString = this.props.doExport(exportFormat);

        // Support IE9
        if (typeof Uint8Array === 'undefined') {
            // Adapated from http://stackoverflow.com/questions/4458119/display-save-as-dialog-and-save-contents-of-a-selected-text-inside-textarea-to/4458807#4458807
            const defaultFilename = 'export_' + exportFormat + '.txt';
            const exportIframe = document.createElement('iframe');
            exportIframe.onload = function () {
                const exportDocument = exportIframe.contentWindow.document;
                exportDocument.open('text/plain', 'replace');
                exportDocument.write(exportString);
                exportDocument.execCommand('SaveAs', true, defaultFilename);
                document.body.removeChild(exportIframe);
            };
            document.body.appendChild(exportIframe);

        // IE10+ and modern browsers
        } else {
            const mimeType = 'text/' + exportFormat + ';charset=utf-8';
            const defaultFilename = 'export.' + exportFormat;
            const blob = new Blob([exportString], { type: mimeType });
            saveAs(blob, defaultFilename);
        }
    },

    render () {
        var sortIcon = 'fa fa-fw ';

        if (this.props.settings.sortDirection === -1) {
            sortIcon += 'fa-caret-down';
        } else {
            sortIcon += 'fa-caret-up';
        }

        var totalCtr = '-';

        if (this.props.statsTotal.impressions > 0) {
            totalCtr = ((this.props.statsTotal.clicks / this.props.statsTotal.impressions) * 100).toFixed(2);
        }

        const totalSpend = this.props.statsTotal.spend;

        const totalEcpm = this.props.statsTotal.impressions > 0 ?
            (this.props.statsTotal.spend * 1000) / this.props.statsTotal.impressions : 0;

        const totalEcpc = this.props.statsTotal.clicks > 0 ?
            this.props.statsTotal.spend / this.props.statsTotal.clicks : 0;

        return (
            <div className="am-pivotTable">
                <div className="am-pivotTable-controls">
                    Split on:
                    <span className="am-pivotTable-splits">
                        {this.props.settings.selectedSplit.map((s, index) =>
                            <span key={s.parent ? s.parent + ': ' + s.label : s.label}>
                                <span className="am-pivotTable-splits-dimension" onClick={this.removeSplit.bind(null, s)}>
                                    {s.parent ? s.parent + ': ' + s.label : s.label}
                                    <i className="fa fa-close"></i>
                                </span>
                                {(index !== (this.props.settings.selectedSplit.length - 1)) || this.props.settings.canAddMoreSplits ?
                                    <span className="am-pivotTable-splits-separator">
                                        <i className="fa fa-lg fa-angle-right"></i>
                                    </span> : null
                                }
                            </span>)
                        }


                        {this.props.settings.canAddMoreSplits ?
                        <DropDownJquery items={this.props.settings.availableSplitOptions} onSelect={this.onDimensionSelected}>
                            <span className="am-pivotTable-splits-addButton">
                                Add Split
                            </span>
                        </DropDownJquery>: null}

                    </span>
                    {this.props.settings.isLoading ?
                    <i className="fa fa-spinner fa-spin fa-lg"></i> : null}

                    <DropDownJquery
                        className="am-pivotTable-exportButton"
                        items={[{ label: 'CSV', value: 'csv' }, { label: 'JSON', value: 'json' }]}
                        onSelect={this.onExport}>

                        <span className="am-pivotTable-exportButton-buttonLabel">
                            <i className="fa fa-cloud-download"></i>Export
                        </span>
                    </DropDownJquery>

                </div>
                <div className="am-pivotTable-inner">
                    <table className="am-pivotTable-table">
                        <thead>
                            <th className="am-pivotTable-header" onClick={this.sortColumn.bind(null, 'dimension')}>
                                Dimensions
                                {this.props.settings.sortColumn === 'dimension' ? <i className={sortIcon}></i> : <i className="fa fa-fw"></i>}
                            </th>
                            <th className="am-pivotTable-header" onClick={this.sortColumn.bind(null, 'impressions')}>
                                Impressions
                                {this.props.settings.sortColumn === 'impressions' ? <i className={sortIcon}></i> : <i className="fa fa-fw"></i>}
                            </th>
                            <th className="am-pivotTable-header" onClick={this.sortColumn.bind(null, 'clicks')}>
                                Clicks
                                {this.props.settings.sortColumn === 'clicks' ? <i className={sortIcon}></i> : <i className="fa fa-fw"></i>}
                            </th>
                            <th className="am-pivotTable-header" onClick={this.sortColumn.bind(null, 'ctr')}>
                                CTR
                                {this.props.settings.sortColumn === 'ctr' ? <i className={sortIcon}></i> : <i className="fa fa-fw"></i>}
                            </th>

                            <th className="am-pivotTable-header" onClick={this.sortColumn.bind(null, 'spend')}>
                                Spend
                                {this.props.settings.sortColumn === 'spend' ? <i className={sortIcon}></i> : <i className="fa fa-fw"></i>}
                            </th>
                            <th className="am-pivotTable-header" onClick={this.sortColumn.bind(null, 'ecpm')}>
                                eCPM
                                {this.props.settings.sortColumn === 'ecpm' ? <i className={sortIcon}></i> : <i className="fa fa-fw"></i>}
                            </th>
                            <th className="am-pivotTable-header" onClick={this.sortColumn.bind(null, 'ecpc')}>
                                eCPC
                                {this.props.settings.sortColumn === 'ecpc' ? <i className={sortIcon}></i> : <i className="fa fa-fw"></i>}
                            </th>
                        </thead>
                        <tbody className="am-pivotTable-body">
                            <tr className="am-pivotTable-totalRow">
                                <td>TOTAL</td>
                                <td>{numeral(this.props.statsTotal.impressions).format('0,0')}</td>
                                <td>{numeral(this.props.statsTotal.clicks).format('0,0')}</td>
                                <td>{totalCtr}%</td>
                                <td>{numeral(this.props.statsTotal.spend).format('$0,0.00')}</td>
                                <td>{numeral(totalEcpm).format('$0,0.00')}</td>
                                <td>{numeral(totalEcpc).format('$0,0.00')}</td>
                            </tr>
                            {this.props.rows.map((row, index) =>
                                <TableRow
                                    data={row}
                                    focusedRow={this.props.settings.focusedRow}
                                    focusedParents={this.props.settings.focusedParents}
                                    key={row.splitKey}
                                    campaignId={this.props.campaignId}/>)}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
});


function composeState (Component) {
    return React.createClass({

        getDefaultProps () {
            return {
                settings: {},
                stats: []
            };
        },

        getInitialState () {
            return {
                dictionary: flux.store('campaign-reporting').getDictionary()
            };
        },

        render () {
            var statsTotal = op.get(this.props.stats, 'stats.0', {});

            var rows = this.getRowItems(this.props.settings, statsTotal.stats);
            // console.log('ROWS', JSON.stringify(rows));
            return (<Component
                campaignId={this.props.campaignId}
                rows={rows}
                statsTotal={statsTotal}
                settings={this.props.settings}
                doExport={this.export} />);
        },

        _flatten (stats, level, settings) {
            var self = this;
            var statsCopy = JSON.parse(JSON.stringify(stats));
            var {
                expandedRows,
                focusedRow,
                focusedParents,
                selectedSplit,
                sortColumn,
                sortDirection
            } = settings;

            if (!selectedSplit[level]) {
                return [];
            }


            var groups,
                dimensionName = selectedSplit[level].value;

            switch (sortColumn) {
                case 'dimension':
                    groups = _.sortBy(statsCopy, function (item) {
                        // return item.group[dimensionName];

                        // No dictionary value, i.e. 'date'
                        if (!self.state.dictionary[dimensionName]) {
                            return item.group[dimensionName];
                        }

                        var dictValue = objectPath.get(self.state.dictionary, [dimensionName, item.group[dimensionName]], 'ZZZZZ');

                        if (_.isObject(dictValue)) {
                            return dictValue.order;
                        } else {
                            return dictValue;
                        }

                    });

                    break;
                case 'impressions':
                case 'clicks':
                case 'spend':
                    groups = _.sortBy(statsCopy, sortColumn);
                    break;
                case 'ctr':
                    groups = _.sortBy(statsCopy, function (n) {
                        if (n.impressions === 0) {
                            return 0;
                        }
                        return (n.clicks / n.impressions) * 100;
                    });
                    break;
                case 'ecpm':
                    groups = _.sortBy(statsCopy, getEcpm);
                    break;

                case 'ecpc':
                    groups = _.sortBy(statsCopy, getEcpc);
                    break;
            }

            if (sortDirection === -1) {
                groups.reverse();
            }


            const statsFormatted = groups.reduce((accumulator, statItem) => {

                if (statItem.group[dimensionName] === undefined || statItem.group[dimensionName] === null) {
                    return accumulator;
                }

                var createOrderedKey = function () {
                    return selectedSplit.reduce(function (keyList, selection) {
                        var statValue = statItem.group[selection.value];
                        if (statValue !== null && statValue !== undefined) {
                            keyList.push(selection.value + '__' + statValue);
                        }

                        return keyList;
                    }, []).join('||');
                };

                var splitKey = createOrderedKey(),
                    isExpanded = !!expandedRows[splitKey],
                    valueCode = statItem.group[dimensionName];

                statItem.isExpanded = isExpanded;
                statItem.shouldShowExpandCollapseToggle = !_.isEmpty(statItem.stats);


                var unknownValue;

                switch (dimensionName) {
                    case 'geo_country_region':
                        var valueSplit = valueCode.split('-');

                        if (valueSplit.length >= 2 && valueSplit[1] === '??') {
                            const countryName = objectPath.get(self.state.dictionary, ['geo_country', valueSplit[0]], '');
                            unknownValue = `Unknown (${countryName})`;
                        }
                        break;
                    default:
                        unknownValue = 'Unknown';
                }


                if (self.state.dictionary[dimensionName]) {
                    statItem.displayName = objectPath.get(self.state.dictionary, [dimensionName, valueCode], unknownValue);

                    // if displayname === unknown && dimensionName === region
                        // use Unknown(Country)
                } else {
                    statItem.displayName = valueCode;
                }

                if (_.isObject(statItem.displayName)) {
                    statItem.displayName = statItem.displayName.value;
                }

                statItem.splitKey = splitKey;
                statItem.level = level;
                statItem.focusedRow = focusedRow;
                statItem.focusedParents = focusedParents;

                if (splitKey === focusedRow) {
                    statItem.isFocused = true;
                }

                if (_.contains(focusedParents, splitKey)) {
                    statItem.isFocusedParent = true;
                }

                if (statItem.impressions === 0) {
                    statItem.ctr = '-';
                } else {
                    statItem.ctr = ((statItem.clicks / statItem.impressions) * 100).toFixed(2) + '%';
                }

                statItem.eCPM = statItem.impressions !== 0 ?
                    numeral(statItem.spend * 1000 / statItem.impressions).format('$0,0.00') : 0;

                statItem.eCPC = statItem.clicks !== 0 ?
                    numeral(statItem.spend / statItem.clicks).format('$0,0.00') : 0;

                statItem.spend = numeral(statItem.spend).format('$0,0.00');



                statItem.impressions = numeral(statItem.impressions).format('0,0');
                statItem.clicks = numeral(statItem.clicks).format('0,0');

                accumulator.push(_.omit(statItem, 'stats'));

                var newAccumulator;
                if (statItem.stats && statItem.isExpanded) {
                    newAccumulator = accumulator.concat(
                        self._flatten(statItem.stats, level + 1, settings));
                } else {
                    newAccumulator = accumulator;
                }

                return newAccumulator;
            }, []);

            return statsFormatted;
        },

        _flattenForExport (stats, settings) {
            var statsCopy = JSON.parse(JSON.stringify(stats)),
                self = this;
            var {
                selectedSplit,
                sortColumn,
                sortDirection
            } = settings;

            var numberOfSplits = selectedSplit.length;
            if (!numberOfSplits) {
                var totalStats = statsCopy[0];
                return [{
                    impressions: totalStats.impressions,
                    clicks: totalStats.clicks,
                    ctr: (totalStats.impressions) ? Math.round(Number(totalStats.clicks) / Number(totalStats.impressions) * 10000) / 10000 : 0,
                }];
            }


            // Throw out intermediary levels by recursively flattening stats
            // If there are stats nested under this level, reach as deep as possible and push a shared accumulator
            // as we traverse through items and levels
            function flatten(statsCopy2, depth) {
                return statsCopy2.reduce(function (accumulator, item) {
                    if (item.stats) {
                        var dimensionName = objectPath.get(selectedSplit, `${depth}.value`, null);
                        if (dimensionName === null) {
                            return accumulator;
                        }

                        var sortedData;

                        switch (sortColumn) {
                        case 'impressions':
                        case 'clicks':
                        case 'ctr':
                        case 'spend':
                            sortedData = _.sortBy(item.stats, sortColumn);
                            break;
                        case 'dimension':
                            // var sortOrder = selectedSplit.map(function (split) { return split.value; });
                            // sortedData = _.sortBy(flattenedData, sortOrder);
                            sortedData = _.sortBy(item.stats, function (item) {
                                // return item.group[dimensionName];
                                if (!self.state.dictionary[dimensionName]) {
                                    return item.group[dimensionName];
                                }

                                var dictValue = objectPath.get(self.state.dictionary, [dimensionName, item.group[dimensionName]], 'ZZZZZ');

                                if (_.isObject(dictValue)) {
                                    return dictValue.order;
                                } else {
                                    return dictValue;
                                }

                            });
                            break;
                        case 'ecpm':
                            sortedData = _.sortBy(item.stats, getEcpm);
                            break;

                        case 'ecpc':
                            sortedData = _.sortBy(item.stats, getEcpc);
                            break;
                        }

                        if (sortDirection === -1) {
                            sortedData.reverse();
                        }
                        console.log(JSON.stringify(sortedData));
                        return accumulator.concat(flatten(sortedData, depth + 1));
                    } else {
                        // Before emitting the record, transform the record into its presentation form
                        var record = {
                            impressions: item.impressions,
                            clicks: item.clicks,
                            ctr: (item.impressions) ? Math.round(Number(item.clicks) / Number(item.impressions) * 10000) / 10000 : 0,
                            ecpm: item.impressions !== 0 ? item.spend * 1000 / item.impressions : 0,
                            ecpc: item.clicks !== 0 ? item.spend / item.clicks : 0,
                            spend: item.spend
                        };
                        _.forOwn(item.group, function (valueCode, dimensionName) {

                            var unknownValue;
                            switch (dimensionName) {
                                case 'geo_country_region':
                                    var valueSplit = valueCode.split('-');

                                    if (valueSplit.length >= 2 && valueSplit[1] === '??') {
                                        const countryName = objectPath.get(self.state.dictionary, ['geo_country', valueSplit[0]], '');
                                        unknownValue = `Unknown (${countryName})`;
                                    }
                                    break;
                                default:
                                    unknownValue = valueCode; // 'Unknown';
                            }

                            record[dimensionName] = objectPath.get(self.state.dictionary, [dimensionName, valueCode], unknownValue);

                            if (_.isObject(record[dimensionName])) {
                                record[dimensionName] = record[dimensionName].value;
                            }
                        });
                        return accumulator.concat(record);
                    }
                }, []);
            }

            var flattenedData = flatten(statsCopy, 0);

            return flattenedData;
        },

        _formatAsCsv: function (flattenedStats, selectedSplit) {
            // The order of fields is important in CSV:
            // Use order of splits specified by user and fixed order for metrics
            var fieldOrder = selectedSplit.map(function (split) { return split.value; })
                .concat(['impressions', 'clicks', 'ctr', 'spend', 'ecpm', 'ecpc']);

            // Important: same order for field names
            var fieldNames = selectedSplit.map(function (split) { return '"' + split.label + '"'; })
                .concat(['"Impressions"', '"Clicks"', '"CTR"', '"Spend"', '"eCPM"', '"eCPC"']);

            var fieldNamesOutput = fieldNames.join(',') + '\r\n';

            var fieldValuesOutput = flattenedStats.map(function (item) {
                return fieldOrder.map(function (field) {
                    return '"' + item[field] + '"';
                }).join(',');
            }).join('\r\n');

            return fieldNamesOutput + fieldValuesOutput;
        },

        getRowItems (settings, stats) {

            if (!stats) {
                return [];
            }

            return this._flatten(stats, 0, settings);
        },

        export (format) {
            var stats = op.get(this.props.stats, 'stats', []);
            var flattenedStatsForExport = this._flattenForExport(stats, this.props.settings);

            switch (format) {
                case 'csv':
                    return this._formatAsCsv(flattenedStatsForExport, this.props.settings.selectedSplit);
                default:
                case 'json':
                    return JSON.stringify(flattenedStatsForExport);
            }
        }
    });
}


PivotTable = composeState(PivotTable);


function addEcpm (item) {
    return {
        ...item,
        ecpm: getEcpm(item)
    };
}

function getEcpm (item) {
    return (item.impressions !== 0 ) ? (item.spend * 1000) / item.impressions : 0;
}


function getEcpc (item) {
    return item.spend / item.clicks;
}



function createTableRows () {

    const accumulator = (accumulation, item) => {
        let newAccumulation = [];
        if (item.stats) {
            newAccumulation = accumulation.concat( flatten(item.stats) );
        }

        return newAccumulation.concat(accumulation).concat( item )
    };

    const flatten = stats =>
        _(stats)
            .sortBy()
            .reduce(accumulator, [])
                .value();

    return flatten;
}


function render (stats) {
    createTableRows('TRANSFORMER', 'TOTAL', 'UNIT', 'METRIC', 'SORTER')(stats)
}

module.exports = PivotTable;














