'use strict';

var helpers = require('scripts/common/helpers');
var GeotargetsView = require('scripts/widgets/geotargets');
var GeoboxesView = require('scripts/widgets/geoboxes');
var Slider = require('scripts/widgets/slider');
var formTemplate = require('./templates/form');
var formBindings = require('./bindings');
var fns = require('scripts/common/fns');
var Form = require('scripts/common/mixins/form');
var Ads = require('scripts/entities/collections/ads');
var SummaryView = require('./summary');
var Creatives = require('scripts/entities/collections/creatives');
var CancelAuditView = require('../creatives/cancel-audit');

/**
 * @class Ad Form
 * options
 * @param {Campaign} campaign
 * @param {boolean} formBehaviorNoInputKeys Mixin option. Whether to use form events.
 */

var AdForm = Mario.Layout.extend({

    className: 'ad-form',

    initialize: function () {

        this.options.formBehaviorNoInputKeys = true;
        this._note = {ctor:'AdForm'};
        this._creativeIds_previous = [];

        if (!this.model || !(this.model instanceof Ads.Ad)) {
            if (_.isObject(this.options.duplicate) && _.has(this.options.duplicate, 'geotargets')) {
                // duplicate
                this.model = new Ads.Ad(this.options.duplicate, {urlRoot: this.collection.url});
            } else {
                this.model = new Ads.Ad(null, {urlRoot: this.collection.url});
            }
        }
        this._summaryScrollThreshold = 10;

        if (this.collection.isEmpty() && this.model.isNew()) {
            this.options.formCannotCancel = true;
        }

        // fix scroll when events is heard and the view is not visible
        this.listenTo(this.options.campaign, 'shown', this.initWidgets);
        this.listenTo(this.options.ch, 'shown', this.checkScroll);
    },

    template: formTemplate,

    events: {
        'change [data-id=billing_type]'      : 'toggleRateInput',
        'click [data-id=restrict_ips] + .btn': 'requestForClientIP',
        'click .add-creative'                : 'addCreative',
        'change [data-toggle=start-now]'     : 'toggleStartNow',
        'focus input.number'                 : 'selectNumber',
        'click [data-id=target_genders]'     : 'restrictMinOne',
        'click [data-id=target_age_groups]'  : 'restrictMinOne',
    },

    regions: {
        geoTargetsRegion    : '.geotargets',
        geoBoxesRegion      : '.geoboxes',
        daypartsRegion      : '.dayparts .embed',
        summaryRegion       : '.ad-setup-summary',
        creativesRegion     : '.available-creatives-region'
    },

    restrictMinOne: function (ev) {
        var checkbox = $(ev.target);
        var isChecked = checkbox.prop('checked');
        var container = checkbox.closest('[data-id]');

        if (!isChecked) {
            var restOfChecked = container.find('input[type=checkbox]:checked');
            if (restOfChecked.length === 1) {
                restOfChecked.prop('disabled', true);
            }
        } else {
            container.find('input[type=checkbox]:disabled').prop('disabled', false);
        }
    },

    selectNumber: function (ev) {
        ev.target.setSelectionRange(0, ev.target.value.length);
    },

    toggleStartNow: function (ev) {
        this.$('.start').trigger('change').prop('disabled', $(ev.currentTarget).is(':checked'));
    },

    onBeforeShow: function() {
        this.showSummary();
    },

    initWidgets: function() {
        this.initNumericFields();
        this.initDatetimePickers();
        this.showDaypartsWidget();
        this.showGeoTargets();
        this.showGeoboxes();
        this.showCreatives();

        this.$('[data-toggle=popover]').popover();

        $(document).off('scroll.adform');
        $(document).on('scroll.adform', _.bind(this.checkScroll, this));

        this.listenTo(this.model, 'updateFormValue', this.updateTimezoneAbbr);

        Backbone.Validation.bind(this);
    },

    onShow: function () {
        _.defer(this.scrollToForm.bind(this));

        this.initWidgets();

        if (!this.model.get('max_bid_cpm')) {
            this.loadMaxCPMFromOrganization();
        }
        this.stickit();
        this.updateTimezoneAbbr(this.model.get('timezone'), 'timezone');

        // fill in default dates
        if (this.model.isNew()) {
            this.$('.start, .end').trigger('change');
        }
    },

    updateTimezoneAbbr: function (val, attr) {

        var updateAbbr = function (tz, start, end) {
            var startAbbr, endAbbr;

            if (_.isEmpty(start)) {
                startAbbr = moment().tz(tz).format('z');
            } else {
                startAbbr = moment(start).tz(tz).format('z');
            }

            if (_.isEmpty(end)) {
                endAbbr = moment().tz(tz).format('z');
            } else {
                endAbbr = moment(end).tz(tz).format('z');
            }

            this.$('[data-id=start] + .timezone-abbr').text(startAbbr);
            this.$('[data-id=end] + .timezone-abbr').text(endAbbr);
        };

        var start = this.$('[data-id=start]').val();
        var end = this.$('[data-id=end]').val();

        if (attr === 'timezone' && val != null) {
            updateAbbr.call(this, val, start, end);
        }

        if (attr === 'start' || attr === 'end') {
            // timezone should not be null
            var tz =this.$('[data-id=timezone]').val() || this.model.get('timezone');
            updateAbbr.call(this, tz, start, end);
        }
    },

    onDestroy: function () {
        Backbone.Validation.unbind(this);
        $(document).off('scroll.adform');
    },

    addCreative: function () {
        var popup = globalCh.request('popup', {size: 'large'});
        var CreativeForm = require('../creatives/form');
        var creatives = this.options.campaign.creatives;
        this._creativeIds_previous = creatives.pluck('id');
        var creativeForm = new CreativeForm({
            collection: creatives,
            inPopup: true,
            ad: this.model,
            ch: this.options.ch,
            campaign: this.options.campaign,
        });
        popup.show(creativeForm);
    },

    initDatetimePickers: function () {

        var timezone = this.model.get('timezone');
        if (_.isEmpty(timezone)) {
            timezone = moment().format('Z');
        }

        this.$('.start').datetimepicker({
            useSeconds: false,
            format: 'YYYY-MM-DD HH:mm'
        });

        this.$('.end').datetimepicker({
            useSeconds: false,
            format: 'YYYY-MM-DD HH:mm'
        });

        // first time set it, adjust minute hour accordingly
        var adjustment = function (min, hour) {
            return function (ev) {
                var date = ev.date, oldDate = ev.oldDate;
                if (!oldDate.isValid()) {
                    date.minutes(min);
                    date.hour(hour);
                    $(ev.currentTarget).data('DateTimePicker').setDate(date);
                }
            };
        };

        this.$('[data-id=end]').on('dp.change', adjustment(59, 23));
        this.$('[data-id=start]').on('dp.change', adjustment(0, 0));

    },

    initNumericFields: function () {
    },

    showDaypartsWidget: function () {
        this.sliderView = new Slider({timezone: this.model.getTimezoneAbbr()});
        this.daypartsRegion.show(this.sliderView);
        // trigger stickit to update view
        this.model.trigger('change:dayparts', this.model, this.model.get('dayparts'));

        var that = this;

        this.listenTo(this.model, 'updateFormValue', function (val, attr) {
            if (attr === 'timezone') {
                this.sliderView.trigger('timezone', moment().tz(val).format('z'));
            }
        });

        this.$el.on('shown.bs.collapse', '#ad-setup-targeting-collapse', function () {

            // get currently selected timezone
            var tz = moment().tz(that.$('[data-id=timezone]').select2('val')).format('z');
            that.sliderView.trigger('timezone', tz);
            that.sliderView.trigger('realshow');

            // trigger stickit to update view
            that.model.trigger('change:dayparts', that.model, that.model.get('dayparts'));
        });
    },

    showSummary: function () {
        this.summaryRegion.show(new SummaryView({
            model: this.model,
            creatives: this.options.campaign.creatives
        }));
    },


    showGeoboxes: function () {
        if (!this.geoboxesView) {
            this.geoboxesView = new GeoboxesView();
            this.geoBoxesRegion.show(this.geoboxesView);
            this.geoboxesView.setValues(this.model.get('geoboxes'));
            var that = this;
            this.$el.on('shown.bs.collapse', '#ad-setup-targeting-collapse', function () {
                that.geoboxesView.trigger('realshow');
                that.geoboxesView.setValues(that.model.get('geoboxes'));
            });
        }
    },

    showGeoTargets: function () {
        if (!this.geoTargetsView) {
            this.geoTargetsView = new GeotargetsView();
            this.geoTargetsRegion.show(this.geoTargetsView);
            this.geoTargetsView.setValues(this.model.get('geotargets'));
        }
    },

    showCreatives: function () {
        var creatives = this.options.campaign.creatives;
        var ch = this.options.ch;

        var tpl = require('./templates/creative-item');
        var CreativeView = Mario.ItemView.extend({
            initialize: function() {
                this._note = {ctor:'CreativeView'}
            },
            template: tpl,
            tagName: 'a',
            attributes: {
                href: '#'
            },
            events: {
                'click': 'selectCreative',
                'click [data-action=edit]': 'editCreative'
            },

            editCreative: function () {

                this.checkForApprovalStatus(doEdit);

                function doEdit() {
                    var popup = globalCh.request('popup', {size: 'large'});
                    var CreativeForm = require('../creatives/form');
                    var creatives = this.model.collection;

                    var creativeForm = new CreativeForm({
                        collection: creatives,
                        model     : this.model,
                        campaign  : this.options.campaign,
                        inPopup   : true,
                        ch        : ch
                    });

                    popup.show(creativeForm);
                }
            },

            checkForApprovalStatus: function (callback) {

                var AUDIT_STATUS = Creatives.AUDIT_STATUS;

                if (this.model.get('audit_status') !== AUDIT_STATUS.NO_AUDIT) {

                    var popup = globalCh.request('popup');

                    var cancelAudtiView = new CancelAuditView({model: this.model, callback: _.bind(callback, this)});

                    popup.show(cancelAudtiView);

                } else {

                    callback.call(this);

                }
            },

            onShow: function () {
                this.$el.data('creative-id', this.model.id);
                if (this.options.existing ) {
                    if (this.options.existing.id === this.model.id) {
                        this.selectCreative();
                    }
                }

                this.stickit();
            },

            className: 'list-group-item creative-choice row',

            selectCreative: function (ev) {
                this.$el.addClass('active');
                this.$el.siblings('.active').removeClass('active');
                this.$el.change();
            },

            bindings: {
                '[data-id=creative-name]': 'name',
                '[data-id=creative-size]': 'size',
                '[data-id=creative-format]': 'format'
            }
        });

        var EmptyView = Mario.ItemView.extend({
            template: _.template('No existing creative.'),
            className: 'list-group-item'
        });

        var CreativesView = Mario.CollectionView.extend({
            initialize: function() {
                this._note = {ctor:'CreativesView'};
            },
            className: 'list-group',
            childView: CreativeView,
            childViewOptions: function () {
                var existing;

                if (this.options.ad ) {
                    existing = this.collection.get(this.options.ad.get('creative'));
                }

                return {
                    existing: existing,
                    campaign: this.options.campaign
                };
            },

            onAddChild: function (view) {
                // The view may be empty
                if (!_.isFunction(view.selectCreative)) return;

                // Auto selecting the newly added creative
                var diff = [];
                var creativeIds_previous = this.options.AdForm._creativeIds_previous;
                if (creativeIds_previous.length) {
                    if (this.collection) {
                        var creativeIds = this.collection.pluck('id');
                    }
                    diff = _.difference(creativeIds, creativeIds_previous);
                    // If diff is not zero length array, then new creative(s) has/have been added.
                }
                var selectedCreative =  this.options.AdForm.model.get('creative')
                if ( !selectedCreative ) {       // If no creative is selected.
                    if ( diff.length === 1 ) {   // Only select creative if it is added by user.
                        view.selectCreative();
                        // what if other user also add crative via another login?
                    }
                }
            },

            events: {
                'click .clear-selection': 'clearSelection'
            },

            clearSelection: function () {
                this.$('>a.active').removeClass('active');
                this.$el.change();
            },

            onRender: function () {
                this.listenTo(this.collection, 'add remove', this.checkAndAppendClearButton);
                this.checkAndAppendClearButton();
            },

            checkAndAppendClearButton: function () {
                if (this.collection.length > 0) {
                    this.appendClearButton();
                } else {
                    this.$('.clear-selection').remove();
                }
            },

            emptyView: EmptyView,

            appendClearButton: function () {
                if (this.$('.clear-selection').length === 0) {
                    this.$el.append('<a href="#" class="list-group-item clear-selection">Clear selection</a>');
                }
            }
        });

        var creativesView = new CreativesView({
            collection: creatives,
            campaign: this.options.campaign,
            ad: this.model,
            AdForm: this
        });

        this.creativesRegion.show(creativesView);

    },

    templateHelpers: function () {
        var assets = this.options.campaign.assets;
        return _.extend(this.model.computed(), {
            cid: this.options.campaign.id,
            dateformat: globalCh.request('settings:dateformat'),
            ads_count: this.collection.length
        });
    },

    getValidAttributes: function () {
        var attrs = this.model.keys();
        var progress = this.model.progress();

        // won't need id and status
        attrs = _.without(attrs, 'id', 'status', 'paused');

        return attrs;
    },

    onSave: function () {

        this.$('[data-action=save]').prop('disabled', true).find('i')
            .removeClass('fa-check').addClass('fa-refresh fa-spin').end()
            .find('span').html('&nbsp;Saving ...');

        var resetSaveBtn = _.bind(function () {
            this.$('[data-action=save]').prop('disabled', false).find('i')
                .addClass('fa-check').removeClass('fa-refresh fa-spin').end()
                .find('span').html(this.model.isNew()? 'Create Ad' : 'Save Ad');
        }, this);


        var attrs = this.getFormData();

        var that = this;

        // validate return undefined when it's valid
        var errors = this.model.validate(attrs);

        if (errors) {
            var errs = _.values(errors);
            toastr.warning(errs.join('<br>'), 'Please fix the following errors:', {duration: 1000});
            resetSaveBtn();
            return;
        }

        if (this.options.duplicate && this.options.duplicate_from) {
            attrs.duplicate_from = this.options.duplicate_from;
        }

        this.model.save(attrs, {
            patch      : !this.model.isNew(),
            wait: true,
            validate: false,
            success    : function (model) {
                that.collection.set([model], {remove: false});
                toastr.success('Ad is saved successfully.');
                that.destroy();
            },
            error: resetSaveBtn
        });
    },

    getFormData: function () {
        var attrs = this.getValidAttributes();
        var data = helpers.stickitGetValues.call(this, attrs, {patch: !this.model.isNew()});
        return data;
    },

    checkScroll: function () {
        var formBody = this.$('>.main-form').get(0);

        var summary = this.$('.ad-setup-summary');
        var summaryToBottom = window.innerHeight - summary.get(0).getBoundingClientRect().bottom;
        var sBoundingRect = formBody.getBoundingClientRect();
        var scrollToTop = sBoundingRect.top;
        var scrollToBottom = window.innerHeight - sBoundingRect.bottom ;

        var threshTop = this._summaryScrollThreshold ;
        var threshBottom = threshTop;

        // scroll down
        if (summary.is(':not(.fixed)') && scrollToTop < threshTop) {
            summary.addClass('fixed').css({top: threshTop + 'px', bottom: 'auto'});
        }

        if (summary.is('.fixed') && scrollToTop > threshTop) {
            summary.removeClass('fixed');
        }
        if (summary.is('.fixed') && scrollToBottom > threshBottom && summaryToBottom < scrollToBottom) {
            summary.css({bottom: scrollToBottom + 'px', top: 'auto'});
        }

        summary.width(function () {return $(this).parent().width();});
    },

    loadMaxCPMFromOrganization: function () {
        var organizationReq = globalCh.request('cache', 'organization'), that = this;
        organizationReq.done(function (organization) {
            var defaultRate = organization.get('default_max_cpm_rate');
            that.model.set('max_bid_cpm', defaultRate);
            that.model.trigger('updateFormValue', defaultRate, 'max_bid_cpm');
            that.$('[data-id=max_bid_cpm]').autoNumeric('set',defaultRate);
        });
    },

    scrollToForm: function () {
        var currentToTop = this.$el.offset().top;
        var desiredToTop = this._summaryScrollThreshold;
        var distToGo = currentToTop - desiredToTop;

        if (distToGo > 0) {
            $('html, body').animate({
                scrollTop: distToGo
            }, 1000);
        }
    },

    behaviors: {
        Form: {
        }
    },

    onCancel: function () {
        this.destroy();
    }
});

_.extend(AdForm.prototype, formBindings);

module.exports = AdForm;
