{
    "ads_fetch_success": "ads_fetch_success",
    // "ads_recieved": "ads_recieved",
    // "ad_stats_recieved": "ad_stats_recieved",
    "ad_live_stats_recieved": "ad_live_stats_recieved",
    // "campaigns_recieved": "campaigns_recieved",
    // "campaign_stats_recieved": "campaign_stats_recieved",
    "campaign_progress_page_activate": "campaign_progress_page_activate",
    "campaign_progress_page_loading": "campaign_progress_page_loading",
    "campaign_live_stats_recieved": "campaign_live_stats_recieved",
    "campaign_progress_page_loaded": "campaign_progress_page_loaded",
    "campaign_pause": "campaign_pause",
    "campaigns_refresh": "campaigns_refresh",

    "progress_ad_stats_recieved": "progress_ad_stats_recieved",
    "progress_ad_live_stats_recieved": "progress_ad_live_stats_recieved",
    "progress_campaign_stats_recieved": "progress_campaign_stats_recieved",
    "progress_campaign_live_stats_recieved": "progress_campaign_live_stats_recieved",

    "campaignsOverview_fetch_success": "campaignsOverview_fetch_success",

    "campaignProgressStats_fetch_success": "campaignProgressStats_fetch_success",

    "organizationProfile_fetch_success": "organizationProfile_fetch_success",

    "spendStats_fetch_success": "spendStats_fetch_success",

    "userProfile_fetch_success": "userProfile_fetch_success",

    "navigate": "navigate",

    "dataForGraph_fetch_success": "dataForGraph_fetch_success",


    "reportScopeDetail_fetch_success"       : "reportScopeDetail_fetch_success",
    "reportScopeStats_fetch_start"          : "reportScopeStats_fetch_start",
    "reportScopeStats_fetch_success"        : "reportScopeStats_fetch_success",

    "reportStats_fetch_start"               : "reportStats_fetch_start",
    "reportDimensionStats_fetch_success"    : "reportDimensionStats_fetch_success",


    "reportPivotTableStats_fetch_success"   : "reportPivotTableStats_fetch_success",
    "reportPivotTableStats_fetch_start"     : "reportPivotTableStats_fetch_start",
    "reportStats_fetch_fail"                : "reportStats_fetch_fail",

    "report_clearAllDimensionFilter"        : "report_clearAllDimensionFilter",
    "report_clearSingleDimensionFilter"     : "report_clearSingleDimensionFilter",

    "report_clearAllScopeFilters"           : "report_clearAllScopeFilters",
    "report_clearSingleScopeFilters"        : "report_clearSingleScopeFilters",
    "report_scopeSelection_change"          : "report_scopeSelection_change",

    "reportGenderSelectionChange"           : "reportGenderSelectionChange",
    "reportGeoCountrySelectionChange"       : "reportGeoCountrySelectionChange",
    "reportDeviceOSSelectionChange"         : "reportDeviceOSSelectionChange",
    "reportAgeSelectionChange"              : "reportAgeSelectionChange",
    "reportGeoRegionSelectionChange"        : "reportGeoRegionSelectionChange",
    "reportWeekPartsSelectionChange"        : "reportWeekPartsSelectionChange",
    "reportDayPartsSelectionChange"         : "reportDayPartsSelectionChange",
    "reportStatsAddSplitToPivotTable"       : "reportStatsAddSplitToPivotTable",
    "reportStatsRemoveSplitToPivotTable"    : "reportStatsRemoveSplitToPivotTable",
    "reportPivotTableExpandRow"             : "reportPivotTableExpandRow",
    "reportPivotTableCollapseRow"           : "reportPivotTableCollapseRow",
    "reportPivotTableSort"                  : "reportPivotTableSort",
    "reportPivotTableFocusOnRow"            : "reportPivotTableFocusOnRow",

    "report_dimensionSelection_change"      : "report_dimensionSelection_change",
    "report_dimensionTwoSelections_change"  : "report_dimensionTwoSelections_change",

    "report_filterSummary_change"           : "report_filterSummary_change",

    "report_statsMetricsType_change"        : "report_statsMetricsType_change",
    "report_statsUnitSelection_change"      : "report_statsUnitSelection_change",
    "report_controlTabSelection_change"     : "report_controlTabSelection_change"

}
