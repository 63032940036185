{{#if id}}
    <h2>Edit Ad</h2>
{{else}}
    <h2>New Ad</h2>
{{/if}}

<div class="row main-form">
<div class="col-lg-8 col-xs-12">

<fieldset class="panel panel-default">
    <div class="panel-heading">
        <legend class="clearfix"><h3 class="panel-title">Basics</h3></legend>
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <!--name-->
                    <label>Name <i class="fa fa-asterisk text-danger"></i></label>
                    <input data-id="name" type="text" class="form-control">
                    <span class="help-text">Describe your set of targeting parameters</span>
                    <span class="help-block"></span>
                </div>
                <div class="form-group">
                    <label>Timezone</label>
                    <select data-id="timezone" style="width: 300px; display: block;"></select>
                    <div class="help-text">Search by nearest major city or name of timezone</div>
                </div>
                <div class="row">
                    <!--start date-->
                    <div class="col-xs-6 form-group">
                        <label>Start <i class="fa fa-asterisk text-danger"></i></label>
                        <div class="input-group">
                            <input type="text" data-format="{{dateformat}}" data-id="start"
                                   class="form-control  start" />
                            <span class="input-group-addon timezone-abbr"></span>
                        </div>
                        <span class="help-block"></span>

                    </div>
                    <!--end date-->
                    <div class="col-xs-6 form-group">
                        <label>End <i class="fa fa-asterisk text-danger"></i></label>
                        <div class="input-group">
                            <input type="text" data-format="{{dateformat}}"
                                   data-id="end"
                                   class="form-control end" />
                            <span class="input-group-addon timezone-abbr"></span>
                        </div>
                        <span class="help-block"></span>

                    </div>
                    <div class="col-xs-12  {{#if _progress}} hide {{/if}}">
                        <label>
                            <input type="checkbox" data-toggle="start-now"> Start as soon as possible
                        </label>
                        <span class="help-text"></span>
                    </div>
                </div>

            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <label for="ad-notes">Notes</label>
                    <!--notes-->
                    <textarea data-id="notes" rows="6" class="form-control"></textarea>
                </div>
            </div>
        </div>
    </div>


<!--budget section-->
    <div class="panel-heading">
        <legend><h3 class="panel-title">Budget</h3></legend>
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="col-md-6">
                <!--max cpm rate-->
                <div class="form-group">
                    <label>Max Bid (CPM) <i class="fa fa-asterisk text-danger"></i></label>

                    <div class="input-group col-xs-6">
                        <span class="input-group-addon">$</span>
                        <input type="text" data-id="max_bid_cpm" class="form-control monetary">
                    </div>
                    <span class="help-text">Maximum price to pay per 1,000 impressions</span>
                    <span class="help-block"></span>
                </div>
            </div>
            <div class="col-md-6">

                <label>Goal <i class="fa fa-asterisk text-danger"></i></label>

                <ul class="list-group hidden-settings-group">
                    <li class="list-group-item">
                        <!--impression toggle-->
                        <div class="form-horizontal">
                            <div class="form-group">
                                <!--daily cap-->
                                <div class="col-xs-12">
                                    <label>Impressions</label>
                                </div>
                                <div class="col-xs-6">
                                    <label>Daily Cap</label>
                                    <input data-id="max_daily_impressions" type="text" placeholder="Unlimited"
                                           class="form-control ">
                                </div>
                                <!--total target-->
                                <div class="col-xs-6">
                                    <label>Total Target</label>
                                    <input data-id="max_total_impressions" type="text" placeholder="Unlimited"
                                           class="form-control ">
                                </div>
                                <div class="col-xs-12 help-block"></div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <!--clicks-->
                        <div class="form-horizontal">
                            <div class="form-group">
                                <!--daily cap-->
                                <div class="col-xs-12">
                                    <label>Clicks</label>
                                </div>
                                <div class="col-xs-6">
                                    <label>Daily Cap</label>
                                    <input data-id="max_daily_clicks" type="text" placeholder="Unlimited"
                                           class="form-control ">
                                </div>
                                <!--total target -->
                                <div class="col-xs-6">
                                    <label>Total Target</label>
                                    <input data-id="max_total_clicks" type="text" placeholder="Unlimited"
                                           class="form-control ">
                                </div>
                                <div class="col-xs-12 help-block"></div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <!--Spend-->
                        <div class="form-horizontal">
                            <div class="form-group">
                                <!--daily cap-->
                                <div class="col-xs-12">
                                    <label>Spend</label>
                                </div>
                                <div class="col-xs-6">
                                    <label>Daily Cap</label>
                                    <input data-id="max_daily_spend" type="text" placeholder="Unlimited"
                                           class="form-control ">
                                </div>
                                <!--total target -->
                                <div class="col-xs-6">
                                    <label>Total Target</label>
                                    <input data-id="max_total_spend" type="text" placeholder="Unlimited"
                                           class="form-control ">
                                </div>
                                <div class="col-xs-12 help-block"></div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="col-xs-12">
                    <div class="help-block"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="panel-heading">
        <a href="#ad-setup-roi-collapse" data-toggle="collapse">
            <legend class="clearfix">
                <h3 class="panel-title pull-left">Revenue Management</h3>
                <small><i class="pull-right fa fa-chevron-down text-muted"></i></small>
            </legend>
        </a>
    </div>
    <div id="ad-setup-roi-collapse" class="collapse in">
        <div class="panel-body">
            <div class="row">
                <div class="col-xs-6">
                    <div class="form-group">
                        <label>Billing Terms</label>

                        <select data-id="billing_terms" class="form-control" style="width: 300px;">
                            <option value="PPM">CPM</option>
                            <option value="PPC">CPC</option>
                        </select>
                        <div class="help-text">How your clients will be billed</div>
                    </div>
                </div>
                <div class="col-xs-6">
                    <div class="form-group">
                        <label>Billing Rate</label>

                        <div class="input-group col-xs-6 billing-fixed">
                            <span class="input-group-addon">$</span>
                            <input type="text" class="form-control monetary" data-id="billing_rate">
                        </div>
                        <span class="help-block"></span>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <input class="hide" data-id="ppc_rate" type="text"/>
    <input class="hide" data-id="ppm_rate" type="text"/>
</fieldset>

<!--targeting-->
<fieldset class="panel panel-default">
    <div class="panel-heading">
        <legend class="clearfix"><h3 class="panel-title">Geotargeting</h3></legend>
    </div>
    <div class="panel-body">
        <div class="form-group">
            <div class="geotargets"></div>
        </div>
    </div>

    <div class="panel-heading">
        <legend class="clearfix">
            <h3 class="panel-title pull-left">Demographics</h3>
        </legend>
    </div>

    <div id="ad-setup-demographics-collapse" class="">
        <div class="panel-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-xs-12"><label>Gender</label></div>
                    </div>
                    <div class="row" data-id="target_genders">
                        <div class="col-xs-3">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="M">Male
                                </label>
                            </div>
                        </div>

                        <div class="col-xs-3">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="F">Female
                                </label>
                            </div>
                        </div>

                        <div class="col-xs-3">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="D">Unknown
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row"><label>Age Group</label></div>
                    <div class="row" data-id="target_age_groups">
                        <div class="col-xs-3">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="1"> 13 - 17
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-3">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="2"> 18 - 24
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-3">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="3"> 25 - 34
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-3">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="4"> 35+
                                </label>
                            </div>
                        </div>
                        <div class="col-xs-3">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" value="-1"> Unknown
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="panel-heading">
        <a href="#ad-setup-targeting-collapse" data-toggle="collapse">
            <legend class="clearfix">
                <h3 class="panel-title pull-left">Advanced Targeting / Constraints</h3>
                <small><i class="pull-right fa fa-chevron-down text-muted"></i></small>
            </legend>
        </a>
    </div>

    <div id="ad-setup-targeting-collapse" class="collapse">
        <div class="panel-body">
            <div class="row">
                <div class="col-md-6"><label>Device Targeting</label>
                    <ul class="list-group hidden-settings-group">
                        <li class="list-group-item" data-id="target_device_os">
                            <div class="row">
                                <div class="col-xs-6"><label>Operating System</label></div>
                            </div>
                            <div class="row">
                                <div class="col-xs-3">
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" value="iOS">iOS
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-3">
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" value="Android">Android
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-3">
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" value="BlackBerry">BlackBerry
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item form-horizontal">
                            <div class="form-group">
                                <div class="col-xs-6">
                                    <label>User Language</label>
                                </div>

                                <div class="col-xs-6">
                                    <select data-id="target_device_language" class="form-control"></select>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <label>Geofencing</label>
                    <ul class="list-group hidden-settings-group">
                        <li class="list-group-item">
                            <div class="row">
                                <div class="col-xs-12 geoboxes"></div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6"><label>Constraints</label>
                    <ul class="list-group hidden-settings-group">
                        <li class="list-group-item dayparts ">
                            <div class="row">
                                <div class="col-xs-6">
                                    <label>Dayparting</label>
                                </div>
                                <div class="col-xs-12 hidden-settings">
                                    <div class="embed"></div>
                                </div>
                                <div class="col-xs-12">
                                    <div class="help-text">
                                        Full day if left unspecified
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row">
                                <div class="col-xs-6"><label>Weekparting</label></div>
                                <div class="weekparts col-xs-12 hidden-settings" data-id="weekparts">
                                    {{#each _week_days}}
                                        <div class="week-day">
                                            <label>{{@key}}
                                                <input type="checkbox">
                                            </label>
                                        </div>
                                    {{/each}}
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="form-group">
                        <label>User Frequency
                            <i data-toggle="popover" data-trigger="hover" data-placement="top" title=""
                               data-content="Maximum number of times a specific user will be targeted by this ad within a 24-hour period. Frequency counts are reset daily at 00:00 UTC."
                               class="fa fa-info-circle text-info" data-original-title="User Frequency"></i>
                        </label>
                        <input data-id="max_user_frequency" type="text" value="4" class="form-control">

                        <div class="help-text">
                            Maximum number of times a specific user will be targeted by this ad within a 24-hour period.
                            Frequency counts are reset daily at 00:00 UTC.
                        </div>
                    </div>

                    <div class="form-group">
                        <label>App Categories</label>
                        <select data-id="target_app_categories" multiple style="width: 300px; display: block;"></select>
                    </div>
                </div>
            </div>
        </div>
    </div>

</fieldset>


<!--creatives-->
<fieldset class="panel panel-default">
    <div class="panel-heading">
        <a href="#ad-setup-creative-collapse" data-toggle="collapse">
            <legend class="clearfix">
                <h3 class="panel-title pull-left">Creative</h3>
                <small><i class="pull-right fa fa-chevron-down text-muted"></i></small>
            </legend>
        </a>
    </div>
    <div id="ad-setup-creative-collapse" class="collapse in">
        <div class="panel-body">
            <div class="ad-creative-list row">
                <div class="col-xs-8 ad-creatives-list">
                    <label for="ad-name">Reuse an existing creative:</label>

                    <div class="available-creatives-region panel panel-default"></div>
                </div>
                <div class="col-xs-4">
                    <div class="form-group">
                        <label>Or, create a new one</label>:
                        <div>
                            <button type="button" class="btn btn-primary add-creative">
                                <i class="fa fa-plus"></i> New Creative
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fieldset>


</div>
<div class="col-lg-4 hidden-sm hidden-xs hidden-md">
    <div class="ad-setup-summary">
    </div>
</div>
</div>
<hr>

<div class="row">
    <div class="col-xs-3">
        {{#if ads_count}}
            <a href="#" data-action="cancel">Cancel / Discard Changes</a>
        {{/if}}
    </div>
    <div class="col-xs-9">
        <div class="row">
            <div class="ad-form-comment">
                <textarea data-id='comment' placeholder='Annotate these changes (optional)'></textarea>
            </div>
            <div class="ad-form-save">
                {{#if id}}
                    <button type="button" class="btn btn-lg btn-primary" data-action="save">
                        <i class="fa fa-check"></i>
                        <span>Save Ad</span>
                    </button>
                {{else}}
                    <button type="button" class="btn btn-lg btn-primary" data-action="save">
                        <i class="fa fa-check"></i>
                        <span>Create Ad</span>
                    </button>
                {{/if}}
            </div>
        </div>
    </div>
</div>
