'use strict';

var D3Chart = require('./d3Chart');

var CampaignProgressAds = React.createClass({

    // ===================
    // Component setup and tear down
    // ===================
    componentWillMount () {
        this.d3ChartUpdate = _.debounce(this.d3ChartUpdate, 100);
    },

    componentDidMount () {
        const that = this;

        const el = this.el = this.getDOMNode();
        const $el = this.$el = $(el);
        this.$tooltip = $el.find('.am-adProgressGraph-tooltip');


        this.d3Chart =  new D3Chart;
        // For Debug
        if (window.cc) {
            window.cc.push(this.d3Chart);
        } else {
            window.cc = [];
            window.cc.push(this.d3Chart);
        }


        const state = this.prepareGraphState(this.props);

        this.d3Chart.init(el, state);
        if (state.data.length !== 0 ) {
            this.d3Chart.firstPlot(el, state);
        }

        this.caculateElementOffsets();

        window.addEventListener('resize', _.debounce( this.windowHandleResize.bind(that), 100));
    },

    componentWillUnmount () {
        const el = this.el;
        window.removeEventListener('resize', this.windowHandleResize);
        this.d3Chart.distroy(el);
    },




    // ===================
    // Window event's callbacks
    // ===================
    windowHandleResize(e) {
        this.caculateElementOffsets();
        this.d3Chart.resize(this.el, this.state);
        this.updateToolTipsCoordinate();
    },





    // ===================
    // React component event's callbacks
    // ===================
    reactHandleMouseMove (e) {
        // const mouseX = e.pageX; // <---- commented out because mouse coordinate in read from svg
        this.caculateElementOffsets();
        this.updateToolTipsCoordinate();
    },





    // ===================
    // tooltips coordinate
    // ===================
    caculateElementOffsets () {
        this.el_offset = this.$el.offset();
        this.el_width  = this.$el.width();
        this.tooltip_width = this.$tooltip.width();
    },

    updateToolTipsCoordinate() {
        let mouseX;
        if (this.el && this.el.mouseLineCoord ) {
            mouseX = this.el.mouseLineCoord.x;

            const remainWidth_left  = mouseX - this.el_offset.left;
            const remainWidth_right = this.el_offset.left + this.el_width - mouseX;

            let leftOffSet = mouseX - this.el_offset.left - this.tooltip_width - 2;

            if ( remainWidth_left < this.tooltip_width ) {
                leftOffSet = mouseX - this.el_offset.left;
            }
            this.$tooltip.css({ left: leftOffSet });

        }
    },






    // ==================
    // Chart data
    // ==================
    shouldComponentUpdate: function (nextProps, nextState) {
        // Do not rerender when neither props or state have changed
        const propMatch = _.every(nextProps, (prop, key) => prop === this.props[key]);
        const stateMatch = _.every(nextState, (state, key) => state === this.state[key]);
        if (propMatch && stateMatch) {
            return false;
        }

        const state = this.prepareGraphState(nextProps);
        if (state.data.length !== 0 ) {
            this.d3ChartUpdate(state);
            // console.log('adprogressgraph', this.props, state);
        }
        return false;
    },

    prepareGraphState (props) {
        const state = {
            data: props.data,
            meta: props.meta
        };

        const adId = this.adId = props.meta.adId;
        return state;
    },

    d3ChartUpdate (state) {
        this.d3Chart.update(this.el, state);
    },





    // ==================
    // React's Views
    // ==================
    render () {
        return (
            <div
                className="am-adProgressGraph"
                onMouseMove={this.reactHandleMouseMove}
            >
                <svg className="am-adProgressGraph-svg" style={{ width:'100%', height:'400px' }} ></svg>
                <div className="am-adProgressGraph-legend"></div>
                <div className="am-adProgressGraph-tooltip"></div>
            </div>
        );
    }
});


module.exports = CampaignProgressAds;
