'use strict';

var app = require('scripts/core').app;
var layoutTemplate = require('./templates/layout');
var rowTemplate = require('./templates/row');
var UserShow = require('./show');
var UserForm = require('./form');
var DataTable = require('scripts/common/mixins/datatable');

/**
 * options
 * model<User>
 * users<Collection<User>>
 * roles<Collection<Role>>
 * organizations<Collection<Role>>
 * organization<Role>
 */

var UserView = Mario.ItemView.extend({
    initialize: function (options) {
        this.listenTo(this.model, 'show', this.show);
        this.listenTo(this.model, 'hide', this.hide);
        this.listenTo(this.model, 'change', function () {
            this.render();
            this.onShow();
        });
    },

    template: rowTemplate,
    tagName: 'tr',
    events: {
        'click .ops [data-action=toggle]' : 'toggleDetail'
    },
    show: function () {
        this.$el.show();
        if (this.userShow) {
            this.userShow.$el.show();
        }
    },

    hide: function () {
        this.$el.hide();
        if (this.userShow) {
            this.userShow.$el.hide();
        }
    },

    onShow: function () {
        this.stickit();
        if (this.model.get('email').match(/^admin$/i)) {
            this.$el.addClass('admin');
        }
    },

    toggleDetail: function (ev) {
        $(ev.currentTarget).toggleClass('fa-chevron-up fa-chevron-down');
        if (this.userShow) {
            this.userShow.destroy();
            this.userShow = null;
            return;
        }
        this.trEl = this.trEl || $('<tr class="user-show"></tr>').insertAfter(this.$el);
        this.userShow = new UserShow({
            model: this.model,
            attributes: {colspan: this.$el.children().length},
            collection: this.options.users,
            roles: this.options.roles
        });

        this.userShow.render();
        this.userShow.$el.appendTo(this.trEl);
        this.listenTo(this.userShow, 'close', function () {
            this.trEl.remove();
            this.trEl = null;
        });
    },

    templateHelpers: function () {
        var organization = this.options.organizations.get(this.model.get('organization')).get('name');
        // NOTE role can be null
        var role = this.options.roles.get(this.model.get('role'));

        return {
            can_access_roles: app.can('list', 'view', 'roles'),
            can_access_orgs: app.can('list', 'view', 'organizations'),
            organization: organization,
            role: role && role.get('name')
        };
    }
});

/**
 * options
 * collection<Collection<User>>
 * users<Collection<User>> alias
 * roles<Collection<Role>>
 * organizations<Collection<Role>>
 * organization<Role>
 */

module.exports = Mario.CompositeView.extend({
    initialize: function () {

    },

    childView: UserView,
    template: layoutTemplate,
    childViewContainer: 'tbody',
    childViewOptions: function () {
        return this.options;
    },

    handleFilter: function (ev) {
        var term = $(ev.currentTarget).val();
        this.collection.trigger('filter', term);
    },

    events: {
        'click [data-action=show-create]' : 'showNewUserForm',
        'keyup [data-action=search]': 'handleFilter'
    },

    onShow: function () {
        this.dataTable = this.$('table').dataTable();
    },

    showNewUserForm: function () {
        var userForm = new UserForm({
            collection: this.collection,
            roles: this.options.roles
        });

        var popup = globalCh.request('popup', {backdrop: true});
        popup.show(userForm);
    },

    templateHelpers: function () {
        return {
            can_access_roles: app.can('list', 'view', 'roles'),
            can_access_orgs: app.can('list', 'view', 'organizations'),
            can_create_user: app.can('create', 'users')
        };
    },

    mixins: [DataTable]

});
