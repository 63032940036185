<div class="col-xs-6 col-md-5">

    <div class="btn-group filter-status" data-toggle="buttons">
        <label for="" class="btn btn-default active">
            <input type="radio" name="audit-filter" value="pending" checked>
            <i class="fa fa-circle-o"></i>
            Pending
        </label>
        <label for="" class="btn btn-default">
            <input type="radio" name="audit-filter" value="approved">
            <i class="fa fa-check"></i>
            Approved
        </label>
        <label for="" class="btn btn-default">
            <i class="fa fa-times"></i>
            <input type="radio" name="audit-filter" value="rejected"> Rejected
        </label>
        <label for="" class="btn btn-default">
            <input type="radio" name="audit-filter" value=""> All
        </label>
    </div>
</div>

<div class="col-xs-4 col-md-4 form-inline hidden-sm hidden-xs">
    <label class="control-label" for="">Filter by: </label>
    <div class="input-group attr-filter">

        <div class="input-group-btn">
            <button type="button" class="btn btn-info dropdown-toggle" data-toggle="dropdown">Creative Name</button>
            <ul class="dropdown-menu">
                <li><a href="" data-filter="Campaign ID">Campaign ID</a></li>
                <li><a href="" data-filter="Creative ID">Creative ID</a></li>
                <li><a href="" data-filter="Creative Name">Creative Name</a></li>
            </ul>
        </div>
        <input class="form-control" type="text" style="margin-left: 0; padding-bottom: 5px;" placeholder="type here..."/>
    </div>
</div>

<div class="col-xs-2  col-md-3 visible-lg-inline-block">
    <button title="Refresh now" class="toolbar-refresh btn btn-link pull-right">
        <i class="fa fa-refresh"></i>
        <small>Updated a few seconds ago</small>
    </button>
</div>
