'use strict';

var app          = require('scripts/core').app;
var formTemplate = require('./templates/form');
var helpers      = require('scripts/common/helpers');
var CreativesView = require('./creatives');
var Organization = require('scripts/entities/collections/organizations').Organization;
var Form = require('scripts/common/mixins/form');

/**
 * options
 * model<Organization>?
 * organizations<Collection<Organization>>
 */
module.exports = Mario.Layout.extend({
    tagName: 'div',
    className: 'organization-form form-horizontal',
    initialize: function () {
        if (!this.model) {
            this.model = new Organization();
        }
        this.options.formBehaviorNoInputKeys = true;
    },

    regions: {
        'creativesRegion' : '[data-id=creatives]'
    },

    ui: {
        unlimitedCreditLimit: '.credit-limit input[type=checkbox]'
    },

    events: {
        'click @ui.unlimitedCreditLimit': 'toggleCreditLimitField'
    },

    template: formTemplate,

    bindings: {
        '[data-id=name]' : 'name',
        '[data-id=default_max_cpm_rate]' : {
            observe: 'default_max_cpm_rate',
            getVal: function ($el) {
                return parseFloat($el.val());
            },
            update: function ($el, val) {
                this.$('input[type=text].monetary').autoNumeric('init', {vMin: '-1', wEmpty: 0});
                this.$('[data-id=rate]').autoNumeric();
                this.$('[data-id=default_max_bid_cpm]').autoNumeric();

                $el.autoNumeric('set', val);
            }
        },
        '[data-id=creatives]' : {
            observe: 'creatives',
            getVal: function () {
                if (this.creativesView) {
                    return this.creativesView.getValues();
                } else {
                    return null;
                }
            },
            update: function ($el, val, model) {
                this.creativesView = new CreativesView({model: model});
                this.creativesRegion.show(this.creativesView);
            }
        },
        '[data-id=auditors]': {
            observe: 'auditors',

            selectOptions: {
                collection: function () {
                    var choices = this.options.users.map(function (u) { return u.pick('id', 'email');});
                    return choices;
                },
                labelPath: 'email',
                valuePath: 'id'
            },
            getVal: function ($el) {
                if (this.model.isNew()) {
                    return [];
                }
                return $el.select2('val');
            },
            initialize: function ($el) {
                $el.select2({
                    placeholder: 'start typing an email addresss..',
                    formatResult: _.partial(auditFormatResult, this.options.users),
                    matcher: _.partial(auditUserMatcher, this.options.users)
                });
            }
        },
        '[data-id=auditees]': {
            observe: 'auditees',

            selectOptions: {
                collection: function () {
                    var choices = this.options.users.map(function (u) { return u.pick('id', 'email');});
                    return choices;
                },
                labelPath: 'email',
                valuePath: 'id'
            },
            getVal: function ($el) {
                if (this.model.isNew()) {
                    return [];
                }
                return $el.select2('val');
            },
            initialize: function ($el) {
                $el.select2({
                    placeholder: 'start typing an email addresss..',
                    formatResult: _.partial(auditFormatResult, this.options.users),
                    matcher: _.partial(auditUserMatcher, this.options.users)
                });
            }
        },
        '[data-id=credit_limit]': {
            observe: 'credit_limit',
            initialize: function ($el, m) {

                if (m.get('credit_limit') < 0) {
                    $el.autoNumeric('set', 0);
                    this.ui.unlimitedCreditLimit.prop('checked', true);
                    $el.prop('disabled', true);
                }
            },

            update: function ($el, val) {
                $el.autoNumeric('set', val);
            },

            getVal: function ($el) {
                if (this.ui.unlimitedCreditLimit.prop('checked')) {
                    return -1;
                }
                return $el.autoNumeric('get');
            }
        }
    },

    toggleCreditLimitField: function () {
        var checked = this.ui.unlimitedCreditLimit.prop('checked');
        this.$('[data-id=credit_limit]').prop('disabled', checked);
    },

    onShow: function () {
        $('#popup .modal-dialog').addClass('modal-lg');
        this.$('input[type=text].monetary').autoNumeric('init', {vMin: '-1', wEmpty: 0});
        this.$('[data-id=rate]').autoNumeric();
        this.$('[data-id=default_max_bid_cpm]').autoNumeric();

        var usersReq = globalCh.request('cache', 'organization-users', null, this.model.id);
        var that = this;

        usersReq.done(function (users) {
            that.options.users = users;
            that.stickit();
        });
        Backbone.Validation.bind(this);
    },

    onDestroy: function () {
        $(window).off('.organization-form');
        Backbone.Validation.unbind(this);
        $('body').removeClass('is-creating-organization');
    },

    onSave: function (ev) {
        var that = this;

        var isNew = this.model.isNew();
        var attrs;
        if (isNew) {
            attrs = ['name', 'default_max_cpm_rate', 'credit_limit', 'creatives'];
        } else {
            attrs = ['name', 'default_max_cpm_rate', 'credit_limit', 'auditors', 'auditees'];
        }
        var vals = helpers.stickitGetValues.call(this, attrs);

        this.model.save(vals, {
            wait: true,
            patch: !isNew,
            success: function (model) {
                that.options.organizations.set(model, {remove: false});
                if (isNew) {
                    toastr.success('Organization is created successfully.');
                } else {
                    toastr.success('Organization is edited successfully.');
                }
                globalCh.trigger('popup:close');
            },
            error: function (model, resp, options) {
                if (resp.statusCode === 403) {
                    toastr.error('Operation is forbidden.');
                }
            }
        });
        return false;
    },

    onCancel: function () {
        this.model.trigger('cancel', this.model);
        globalCh.trigger('popup:close');
        return false;
    },

    behaviors: {
        Form: {
            autoAddCancelButton: true
        }
    }
});

var auditSearchTemplate = _.template('<div class="audit-user-option"><div><%=email%></div><div><%=first_name%> <%=last_name%></div></div>');

function auditFormatResult(users, sel) {
    var userId = sel.id;
    var user = users.find({id: userId});
    return auditSearchTemplate(user.toJSON());
}

function auditUserMatcher(users, term, label, opt) {
    var userId = opt.val();
    var user = users.find({id: userId});
    return user.get('first_name').toLowerCase().indexOf(term) !== -1 ||
        user.get('last_name').toLowerCase().indexOf(term) !== -1 ||
        label.toLowerCase().indexOf(term) !== -1;
}
