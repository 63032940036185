'use strict';

var fns = require('scripts/common/fns');

var Model = Mario.Model.extend({
    modelName: 'Stat',
    defaults: {
        'impressions': 0,
        'clicks': 0,
        'date': null
    }
});

var Stats = Mario.Collection.extend({
    model: Model,
    initialize: function (models, options) {

        if (options.keys) {
            this.url = 'campaigns/' + options.collectionId + '/stats?keys=' + options.keys.join(',') + '&sort=date';
        } else {
            this.url = 'campaigns/' + options.collectionId + '/stats?keys=date&sort=date';
        }

        if (options.query) {
            this.url += options.query;
        }

    },

    fetchHourlyData: function (date) {
        var oldUrl = this.url;
        var qp = oldUrl.indexOf('?');
        var fetchUrl = oldUrl.slice(0, qp) + '?keys=hour,date&start=' + date + '&end=' + date;

        var that = this;
        return new Promise(function (res, rej) {
            $.ajax({
                url     : fetchUrl.replace('stats', 'livestats'),
                dataType: 'json',
                success : res,
                error   : rej
            });
        });
    },

    getTotalImpressions: function () {
        return this.reduce(function (acc, s) {
            return acc + s.get('impressions');
        }, 0);
    },

    getTodayImpressions: function () {
        if (this.length === 0) {
            return 0;
        }
        var todayDate = moment.utc().format('YYYY-MM-DD');
        var filtered = this.filter(function (d) {
            return d.get('date') === todayDate;
        });
        return _.reduce(filtered, function (acc, s) { return acc + s.get('impressions');}, 0);
    },

    getTotalClicks: function () {
        return this.reduce(function (acc, s) {
            return acc + s.get('clicks');
        }, 0);
    },

    getTodayClicks: function () {
        if (this.length === 0) {
            return 0;
        }
        var todayDate = moment.utc().format('YYYY-MM-DD');
        var filtered = this.filter(function (d) {
            return d.get('date') === todayDate;
        });
        return _.reduce(filtered, function (acc, s) { return acc + s.get('clicks');}, 0);
    },

    getTotalCTR: function () {
        var td = this.getTotalImpressions();

        if (td === 0) {
            return 0;
        }

        return this.getTotalClicks() / td;
    },

    getTodayCTR: function () {
        var dd = this.getTodayImpressions();
        if (dd === 0) {
            return 0;
        }

        return this.getTodayClicks() / dd;
    },

    getTotalFilled: function (target, goal) {
        if (target === 0) {
            return 0;
        }
        var total = this.getTotalFilledCount(goal);

        return total / target;
    },

    getTotalFilledCount: function (goal) {
        if (goal === 'impressions') {
            return this.getTotalImpressions(false);
        } else if (goal === 'clicks') {
            return this.getTotalClicks(false);
        } else {
            return 0;
        }
    },

    getTodayFilled: function (target, goal) {
        if (target === 0) {
            return 0;
        }
        var daily = this.getTodayFilledCount(goal);
        return daily / target;
    },

    getTodayFilledCount: function (goal) {
        if (goal === 'impressions') {
            return this.getTodayImpressions();
        } else if (goal === 'clicks') {
            return  this.getTodayClicks();
        } else {
            return 0;
        }
    },

    getTotalDelivered: function (goal) {
        if (goal === 'impressions') {
            return this.getTotalImpressions(false);
        }
        if (goal === 'clicks') {
            return this.getTotalClicks(false);
        }
        return 0;
    },

    refresh: function (res) {
        return this.fetch({success: res});
    },

    loadRealTimeStats: function (res) {

        var that = this;

        $.ajax({
            url: this.url.replace('stats', 'livestats'),
            dataType: 'json',
            success: function (data) {
                that.reset(data);
                that.trigger('livestats:loaded');
                _.isFunction(res) && res();
            }
        });

    }
});

module.exports = Stats;
