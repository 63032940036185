'use strict';

var app = require('scripts/core').app;
var EditUser = require('./form');
var showTemplate = require('./templates/show');
var UserForm = require('./form');


/**
 * options
 * model<User>
 * collection<Collection<User>>
 * roles<Collection<Role>>
 * attributes<Object>?
 */
var UserShow = Mario.Layout.extend({
    tagName: 'td',
    className: 'user-detail',

    template: showTemplate,

    regions: {
        content: '.content'
    },

    events: {
        'click [data-action=edit]': 'editUser',
        'click [data-action=delete]': 'deleteUser'
    },

    deleteUser: function () {
        var that = this;

        if (this.model.get('email').match(/^admin$/i)) {
            return false;
        }

        this.model.destroy({wait: true, success: function (m) {
            toastr.success(m.get('email') + ' is deleted.');
            that.remove();
        }});
    },

    editUser: function () {
        var userForm = new UserForm({
            model: this.model,
            collection: this.collection,
            roles: this.options.roles
        });
        var popup = globalCh.request('popup');
        popup.show(userForm);
    },

    onRender: function () {
        this.stickit();
    },

    templateHelpers: function () {
        var canDelete = app.can('delete', 'users') &&
            this.model.get('email') !== app.session.get('user').email &&
            // not 'admin' nor 'admin@...'
            !/^admin((?=@)|$)/.test(this.model.get('email'));

        return {
            can_update_user: app.can('update', 'users'),
            can_delete_user: canDelete,
            can_access_roles: app.can('list', 'view', 'roles')
        };
    },

    bindings: {
        '[data-id=email]'      : 'email',
        '[data-id=first_name]' : 'first_name',
        '[data-id=last_name]'  : 'last_name',
        '[data-id=twitter]'    : 'twitter',
        '[data-id=phone]'      : 'phone',
        '[data-id=suspended]'  : 'suspended',
        '[data-id=role]'       : {
            observe: 'role',
            update: function ($el, val) {
                var role = this.options.roles.get(val);
                if (role) {
                    $el.text(role.get('name'));
                }
            }
        }
    }

});

module.exports = UserShow;
