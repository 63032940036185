

<select class="page-size">
    <option value="10" {{#is_equal pageSize 10}}selected{{/is_equal}}>10</option>
    <option value="25" {{#is_equal pageSize 25}}selected{{/is_equal}}>25</option>
    <option value="50" {{#is_equal pageSize 50}}selected{{/is_equal}}>50</option>
    <option value="100" {{#is_equal pageSize 100}}selected{{/is_equal}}>100</option>
</select>


<a class="prev-page btn btn-navigate" >
    <i class="fa fa-angle-double-left fa-fw"></i></a>

{{#each pages}}
    {{#if dot}}
        ...
    {{else}}
    <a class="page-number btn btn-default {{#if selected}}selected{{/if}}" data-page="{{num}}">
        {{num}}</a>
    {{/if}}
{{/each}}


<a class="next-page btn btn-navigate">
    <i class="fa fa-angle-double-right fa-fw"></i></a>


{{#if totalRecords}}
{{from}} to {{to}} of {{totalRecords}} results
{{/if}}


