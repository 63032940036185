'use strict';

var progressReducer = require('./progress');


function campaignsReducer (state = {}, action) {
    return {
        ...state,
        progress: progressReducer(state.progress, action)
    };
}


module.exports = campaignsReducer;