'use strict';

var ProgressCalculator = require('scripts/common/progress-calculator');

const M_ = moment;


//-- Date resolution can be day or hour --//
// const timeUnit = 'day';
const timeUnit = 'hour';


export default function getData(args) {
    const meta = args.meta;
    const stats = args.stats;

    const adStart               = meta.start;
    const adEnd                 = meta.end;
    const adZone                = meta.timezone;

    const max_total_impressions = meta.max_total_impressions;

    const max_daily_impressions = meta.max_daily_impressions;

    const max_total_clicks      = meta.max_total_clicks;
    const max_daily_clicks      = meta.max_daily_clicks;

    const max_total_spend       = meta.max_total_spend;
    const max_daily_spend       = meta.max_daily_spend;

    const weekparts             = meta.weekparts;
    const dayparts              = meta.dayparts;

    // -- Put Ad start and end date in to moment --//
    const start = M_.tz(adStart, adZone);
    const end   = M_.tz(adEnd, adZone);


    //-- Date seen by clients(user) in real time (translated in end of time unit) --//
    const currentTime = moment.utc();
    const currentTime_endOfUnit = currentTime.clone().endOf(timeUnit);

    const yesterday_lastSecond = moment.utc( currentTime, 'YYYY-MM-DD' ).subtract(1, 'day').endOf('day');
    const today_lastSecond = moment.utc( currentTime, 'YYYY-MM-DD' ).endOf('day');

    // -- Ad start/end date for edge case use in develop and testing --//
        //test 4 point
        // const start = M_.tz('2015-10-15T18:31:00.000', adZone);
        // const end   = M_.tz('2015-10-15T20:31:00.000', adZone);

        //test 3 point
        // const start = M_.tz('2015-10-15T18:31:00.000Z', adZone);
        // const end   = M_.tz('2015-10-15T19:31:00.000Z', adZone);

        //test 2 point
        // const start = M_.tz('2015-10-15T18:31:00.000', adZone);
        // const end   = M_.tz('2015-10-15T18:50:00.000', adZone);

        // test 2 point (ad has not started)
        // const start = M_.tz('2015-10-30T14:28:00.000', adZone);
        // const end   = M_.tz('2015-10-30T15:28:00.000', adZone);
        // const end   = M_.tz('2015-10-30T15:00:00.000', adZone);


    let startEndDiff;
    if (timeUnit === 'hour' ) {
        startEndDiff = end.diff(start, 'hours', true);
    } else if (timeUnit === 'day') {
        startEndDiff = end.diff(start, 'days', true);
    }



    const graphAttr = new ProgressCalculator({
        start: start.toDate(),
        end  : end.toDate(),

        max_total_impressions,
        max_daily_impressions,

        max_total_clicks,
        max_daily_clicks,

        max_total_spend,
        max_daily_spend,

        weekparts,
        dayparts,
    });


    const metricType = graphAttr.getGoal();
    // const metricType = 'impressions';
    // const metricType = 'clicks';

    const  hasTotalTarget = graphAttr.hasTotalTarget();
    const  hasDailyCap    = graphAttr.hasDailyCap();

    const start_startOfTimeUnit = moment.utc(start).startOf(timeUnit);
    const start_endOfTimeUnit   = moment.utc(start).endOf(timeUnit);

    const end_startOfTimeUnit   = moment.utc(end).startOf(timeUnit);
    const end_endOfTimeUnit     = moment.utc(end).endOf(timeUnit);

    const results = [];
    const resultsDev = [];

    switch (true) {
        case ( startEndDiff < 1 ):
            processEdgeCase();
        break;
        case ( startEndDiff >= 1 ):
            processRange();
        break;
    }


    function processRange() {
        let _filled = 0;

        //-- Looping throu time range --//
            const adFlightRange = M_.range(start_endOfTimeUnit, end_endOfTimeUnit);
            adFlightRange.by( timeUnit, function(_t){
                // All time is end of time unit in the range loop.
                const t = _t;

                const _timeISO = t.toISOString();
                const _state = state(t, currentTime_endOfUnit);
                let _metric;

                //-- Find match for data for each time in the loop --//
                const data = stats;
                const date = t.format('YYYY-MM-DD');
                let hour;
                let matched;
                if ( timeUnit === 'day' ) {
                    matched = _.find(data, { date });
                    _metric = (matched) ? matched[metricType] :  0;
                } else if ( timeUnit === 'hour' ) {
                    hour = +t.format('H');
                    matched = _.find(data, { date, hour });
                    _metric = (matched) ? matched[metricType] :  0;
                }

                //-- Accumulate metric into filled --//
                _filled = _filled + _metric;

                //-- Calculate un-paced step --//
                let _ideal_unPace = 0;
                const date_today = t.format('YYYY-MM-DD');
                const t_today_lastSecond = moment.utc( date_today, 'YYYY-MM-DD' ).endOf('day');
                if ( t.isAfter(yesterday_lastSecond)) {
                    // Un-paced values is calculated for the entire day, thus, we started the calculation on yesterday_lastSecond.
                    // Calculation would work even if the ad's has NOT started
                    _ideal_unPace = graphAttr.getIdealUnpacedFillForEndOfSecond( t_today_lastSecond.toDate(), yesterday_lastSecond.toDate() , _filled );
                    // console.log('ideal unpace in loop',  t_today_lastSecond.toDate(), yesterday_lastSecond.toDate() , _filled, _ideal_unPace )
                } else {
                    _ideal_unPace = 'n/a';
                }

                const out = _.assign( {}, {
                    state       : _state,
                    timeISO     : _timeISO,
                    metric      : _metric,
                    filled      : _filled,
                    ideal_pace  : graphAttr.getIdealPacedFillForEndOfSecond(t.toDate()),
                    ideal_unPace: _ideal_unPace,
                    health      : graphAttr.getPacedFillHealthForEndOfSecond(t.toDate(), _filled),
                    metricType  : metricType,
                });
                results.push(out);
            }); //END range loop

        //-- handle first and last data points --//
            let results_length = +results.length;
            const results_first = results[0];
            const results_last = results[results_length-1];

            const time_first = moment(results_first.timeISO);
            const time_last = moment(results_last.timeISO);

            let date_today;
            let t_today_lastSecond;
            let _ideal_unPace;

            let t;
            //-- First point --//
                t = start;
                if (t.isBefore(time_first)) {
                    // We insert the first point so the start is on the
                    // actual time instead of on the end of time unit.
                    // console.log('inserting first point');

                    //-- Calculate un-paced step --//
                    date_today = t.format('YYYY-MM-DD');
                    t_today_lastSecond = moment.utc( date_today, 'YYYY-MM-DD' ).endOf('day');
                    _ideal_unPace = graphAttr.getIdealUnpacedFillForEndOfSecond( t_today_lastSecond.toDate(), yesterday_lastSecond.toDate() , _filled );
                    const newValue = {
                        state       : state(t, currentTime), //  Note that state is calculated with current time (NOT on the end of time unit)
                        timeISO     : t.toISOString(),
                        metric      : 0, // hard code to zero
                        filled      : 0, // hard code to zero
                        ideal_pace  : graphAttr.getIdealPacedFillForEndOfSecond(t.toDate()),
                        ideal_unPace: _ideal_unPace,
                        health      : graphAttr.getPacedFillHealthForEndOfSecond(t.toDate(), 0),
                        metricType  : results_first.metricType
                    };
                    results.unshift(newValue);
                    results_length = +results.length;
                }

            //-- Last point --//
            // NO need to handle last point ?
                // t = end;
                //
                // //-- Calculate un-paced step --//
                // date_today = t.format('YYYY-MM-DD');
                // t_today_lastSecond = moment.utc( date_today, 'YYYY-MM-DD' ).endOf('day');
                // _ideal_unPace = graphAttr.getIdealUnpacedFillForEndOfSecond( t_today_lastSecond.toDate(), yesterday_lastSecond.toDate() , _filled );
                //
                // if (t.isBefore(time_last)) {
                //     const recalculateValues = {
                //         state       : state(t, currentTime_endOfUnit),
                //         timeISO     : t.toISOString(),
                //         metric      : results_last.metric,
                //         filled      : results_last.filled,
                //         ideal_pace  : graphAttr.getIdealPacedFillForEndOfSecond(t.toDate()),
                //         ideal_unPace: _ideal_unPace,
                //         health      : graphAttr.getPacedFillHealthForEndOfSecond(t.toDate(), results_last.filled ),
                //         metricType  : results_last.metricType
                //     };
                //     results[results_length-1] = recalculateValues;
                // }
    }

    function processEdgeCase() {
        // console.warn('Processing Edge Case: Duration between start and end of an ad is less than time unit');

        let _filled = 0;
        let _timeISO;
        let _metric;
        let _ideal_unPace;


        let date_today;
        let t_today_lastSecond;

        let t;
        //-- First point --//
            t = start;
            _timeISO = t.toISOString();

            //-- Calculate un-paced step --//
            date_today = t.format('YYYY-MM-DD');
            t_today_lastSecond = moment.utc( date_today, 'YYYY-MM-DD' ).endOf('day');
            _ideal_unPace = graphAttr.getIdealUnpacedFillForEndOfSecond( t_today_lastSecond.toDate(), yesterday_lastSecond.toDate() , _filled );
            // console.log('process edge',  t_today_lastSecond.toDate(), yesterday_lastSecond.toDate() , _filled, _ideal_unPace )

            const result_start = {
                state       : state(t, currentTime), //  Note that state is calculated with current time (NOT on the end of time unit)
                timeISO     : _timeISO,
                metric      : 0,
                filled      : 0,
                ideal_pace  : 0,
                ideal_unPace: _ideal_unPace,
                health      : 'n/a',
                metricType  : metricType
            };
            results.push(result_start);

        //-- Last point --//
            t = end;
            _timeISO = t.toISOString();

            //-- Find match for data for each time in the loop --//
            const data = stats;
            const date = t.format('YYYY-MM-DD');
            let hour;
            let matched;
            if ( timeUnit === 'day' ) {
                matched = _.find(data, { date });
                _metric = (matched) ? matched[metricType] :  0;
            } else if ( timeUnit === 'hour' ) {
                hour = t.format('h');
                matched = _.find(data, { date, hour });
                _metric = (matched) ? matched[metricType] :  0;
            }

            _filled = _metric;

            //-- Calculate un-paced step --//
            date_today = t.format('YYYY-MM-DD');
            t_today_lastSecond = moment.utc( date_today, 'YYYY-MM-DD' ).endOf('day');
            _ideal_unPace = graphAttr.getIdealUnpacedFillForEndOfSecond( t_today_lastSecond.toDate(), yesterday_lastSecond.toDate() , _filled );
            // console.log('process edge',  t_today_lastSecond.toDate(), yesterday_lastSecond.toDate() , _filled, _ideal_unPace )

            const t_endOfTimeUnit = t.clone().endOf(timeUnit);
            const result_end = {
                state       : state(t, currentTime_endOfUnit),
                timeISO     : _timeISO,
                metric      : _metric,
                filled      : _filled ,
                ideal_pace  : graphAttr.getIdealPacedFillForEndOfSecond(t_endOfTimeUnit.toDate()),
                ideal_unPace: _ideal_unPace,
                health      : graphAttr.getPacedFillHealthForEndOfSecond(t_endOfTimeUnit.toDate(), _filled),
                metricType  : metricType
            };

            results.push(result_end);
    }

    function state(t, _currentTime) {
        let _state;

        const isPast    = t.isBefore(_currentTime);
        const isNow     = t.isSame(_currentTime);
        const isFuture  = t.isAfter(_currentTime);

        switch (true) {
            case (isPast):
                _state = 'isPast';
            break;
            case (isNow):
                _state = 'isNow';
            break;
            case (isFuture):
                _state = 'isFuture';
            break;
        }
        return _state;
    }

    // Hardcode frist ideal pace value to zero
    results[0].ideal_pace = 0;

    // console.table(results);
    // console.table(resultsDev);

    return {
        data: results,
        meta: {
            today_EndOfLastSecond_ISO : today_lastSecond.toISOString(),
            currentTime_ISO           : currentTime.toISOString(),
            currentTime_endOfUnit_ISO : currentTime_endOfUnit.toISOString(),
            hasTotalTarget            : hasTotalTarget,
            hasDailyCap               : hasDailyCap,
            metricType                : metricType,
            max_total_impressions     : max_total_impressions,
            max_total_clicks          : max_total_clicks,
            max_total_spend           : max_total_spend
        }
    };
}


