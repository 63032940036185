'use strict';

const HealthIndicator = require('./health-indicator');


var HealthMenu = React.createClass({
    propTypes: {
        header: React.PropTypes.shape({
            title: React.PropTypes.string.isRequired,
            link: React.PropTypes.string.isRequired,
            selected: React.PropTypes.bool
        }),
        menuItems: React.PropTypes.array.isRequired
    },

    getInitialState () {
        return {};
    },

    render () {
        const headerClass = classNames('am-healthMenu-item', { 'is-selected': this.props.header.selected });
        return (
            <ul className="am-healthMenu">
                <li>
                    <a href={this.props.header.link} className={headerClass} title={this.props.header.title}>
                        {this.props.header.title}
                    </a>
                </li>
                {this.props.menuItems.map((item, i) => {
                    const menuItemClass = classNames(
                        'am-healthMenu-item',
                        `is-${item.health}Health`,
                        { 'is-selected': item.selected, }
                    );
                    return (
                        <li key={item.id}>
                            <a href={item.link} className={menuItemClass} title={item.name}>
                                <HealthIndicator isLoading={item.isLoading} health={item.health}/>{item.name}
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    }
});


module.exports = HealthMenu;