'use strict';
var app = require('scripts/core').app;
var layoutTemplate = require('./templates/layout');
var OrganizationsView = require('./parts/organizations');
var OrganizationSettingsView = require('./settings');

/**
 * options
 * organizations<Collection<Organization>>
 */

module.exports = Mario.Layout.extend({
    template: layoutTemplate,
    regions: {
        organizationsRegion: '.organizations',
        organizationSettingsRegion: '.organization-settings-region'
    },

    onShow: function () {
        var organizationsView = new OrganizationsView({
            collection: this.options.organizations
        });

        if (app.can('list', 'view', 'users') && app.can('list', 'view', 'roles')) {
            this.listenTo(organizationsView, 'change-organization', function (selectedOrganization) {
                console.log(selectedOrganization)
                var options = {
                    model: selectedOrganization,
                    organizations: this.options.organizations
                };

                var settingsView = new OrganizationSettingsView(options);

                this.organizationSettingsRegion.show(settingsView);
            });
        }

        this.organizationsRegion.show(organizationsView);
    }
});
