'use strict';

var app = require('scripts/core').app;

var controller = {
    notFound: function () {
        var page = globalCh.request('available-page', {main_url: '/errors'});
        page.show(function () {
            return new Mario.ItemView({template: require('./templates/not-found'), className: 'errors'});
        });
    }
};

var Router = Mario.AppRouter.extend({
    appRoutes: {
        'not-found': 'notFound'
    }
});
exports.start = function () {
    debug('start errors component');
    this.router = new Router({controller: controller});
    return {
        key    : 'errors',
        matcher: ['not-found']
    };
};
