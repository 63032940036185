<div class="upload-notice">
    <b>*Upload file limitations</b>

    <div>
        <span>Video</span>: {{video_formats}}
    </div>
    <div>
        <span>Image</span>: {{image_formats}}
    </div>
    <div>
        <span>Document</span>: {{doc_formats}}
    </div>
    <div>
        <span>Maximum file size</span>: {{size_limit}}
    </div>
</div>
