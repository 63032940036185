'use strict';
var PrivateChannel = require('scripts/common/mixins/private-channel');
var Pager = React.createClass({
    mixins: [PrivateChannel],

    getInitialState: function () {
        return {
            pages: this.getPages(),
            current: this.props.collection? this.props.collection.state.currentPage : 0
        }
    },

    componentDidMount: function () {
        var that = this;
        if (this.props.ch) {
            this.privateCh.listenTo(this.props.ch, 'update:pager', ()=>this.setState({pages: this.getPages()}))
        }
    },

    getPages: function () {
        var pages;


        if (this.props.collection == null || this.props.collection.isEmpty()) {
            return [];
        }

        var state = this.props.collection.state;
        var cur = ~~state.currentPage, totalPages = ~~state.totalPages;

        if (totalPages < 10) {
            pages = this.getPageArr(totalPages, cur);
        } else {
            var dotLeft = false, dotRight = false;

            if (cur > 5) {
                dotLeft = true;
            }

            if (totalPages - cur > 5) {
                dotRight = true;
            }

            var left = [], middle = [], right = [];
            if (dotLeft) {
                left = this.getPageArr(2).
                    concat([{dot: true}]).
                    concat(this.getPageArr(cur - 1, null, cur - 2));
            } else {
                left = this.getPageArr(cur - 1);
            }

            if (dotRight) {
                right = this.getPageArr(cur + 3, null, cur + 2).
                    concat([{dot: true}]).
                    concat(this.getPageArr(totalPages, null, totalPages - 2));
            } else {
                right = this.getPageArr(totalPages, null, cur + 2);
            }

            middle = this.getPageArr(cur + 2, cur, cur - 1);

            pages = left.concat(middle).concat(right);
        }
        return pages;
    },

    getPageArr: function (total, current, start) {
        start = start || 0;
        total = Math.min(total, ~~this.props.collection.state.totalPages);
        start = Math.max(start, 0);

        return _.map(_.range(start + 1, total + 1), function (n) {
            return {num: n, selected: n - 1 === current}; });
    },

    onClickPageNumber: function (ev) {
        var pageNum = $(ev.target).data('page');
        pageNum = _.parseInt(pageNum) - 1;
        this.props.collection.setState('currentPage', pageNum);
        this.setState({current: pageNum});
    },

    render: function () {
        var pages = this.state.pages;
        return (
            <div className="ef-toolbar-pages">
                <ul className="pagination">

                {pages.map(function (p, i) {
                    if (p.dot) {
                        return (<li key={_.uniqueId()} className="page-number">
                            <a href="#">...</a>
                        </li>)
                    } else if (this.state.current === i) {
                        return (
                            <li key={_.uniqueId()} className="page-number">
                                <a onClick={this.onClickPageNumber} className="btn btn-default selected" data-page={p.num}>{p.num}</a>
                            </li>
                        );
                    } else {
                        return (
                            <li key={_.uniqueId()} className="page-number">
                                <a onClick={this.onClickPageNumber} className="page-number btn btn-default" data-page={p.num}>{p.num}</a>
                            </li>
                        );
                    }
                }, this)}
                </ul>
            </div>
        )
    }
});

module.exports = Pager;
