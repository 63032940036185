'use strict';

var app = require('./index');
var cache = {
    _store: {},
    /**
    * for retrieving general collections
    * @param  name | name of the collection to load
    * @param  id   | id of the item, not used here, null
    * @param  cid  | parent collection id, used for sub-collection
    * @return collection promise
    */
    getCollection: function (name, id, cid, options) {
        var Clazz = require('scripts/entities/collections/' + name);

        var cacheKey = [name, (cid || 'none')].join('-');

        if (options) {
            cacheKey += '-' + options.cacheKey;
        }

        options = _.extend({}, options, {collectionId: cid, Clazz: Clazz});
        return this._getCollection(cacheKey, options);
    },

    getItem: function (name, id, cid) {
        var def = $.Deferred();
        this.getCollection(name, id, cid).done(function (col) {
            if (col.get(id) != null) {
                def.resolve(col.get(id));
            } else {
                def.reject();
            }
        });
        return def.promise();
    },

    clear: function () {
        this._store = {};
    },

    _getCollection: function (cacheKey, options) {
        var Clazz = options.Clazz;
        if (this._store[cacheKey] == null) {
            // each cache object contains a loader object
            this._store[cacheKey] = {loader: $.Deferred()};
        }
        var target = this._store[cacheKey];
        if (target.loader.state() !== 'resolved') {
            target.collection = new Clazz([], options);
            target.collection.fetch({
                reset: true,
                success: target.loader.resolve,
                error: target.loader.reject
            });
        }
        return target.loader.promise();
    },

    reset: function () {
        this.clear();
    }
};
module.exports = cache;
