/**
 * ref:
 *     http://stackoverflow.com/questions/1694295/jquery-background-color-animate-not-working/5718151#5718151
 */
$(document).ready(function () {
    $.fn.animateHighlight = function (options) {
        var that = this;
        var highlightBg = options.highlightColor || "#FFFF9C";
        var animateMs = options.duration;// || "slow"; // edit is here
        var originalBg = this.css("background-color");

        if (!originalBg || originalBg == highlightBg)
            originalBg = "#FFFFFF"; // default to white

        if (options.isReact) {
            jQuery(this).css("backgroundColor", highlightBg)
            // since we don't have ReactCSSTransistionGroup we fake it with setTimeout
            setTimeout(function(){
                jQuery(that).css("backgroundColor", originalBg);
            }, options.duration);
        } else {
            jQuery(this)
                .css("backgroundColor", highlightBg)
                .animate({ backgroundColor: originalBg }, animateMs, null, function () {
                    jQuery(this).css("backgroundColor", originalBg);
                });
        }
    };
})
