'use strict';
var fns = require('scripts/common/fns');

/**
 * Model helper functions, shared between ad and campaign
 */

var modelHelpers = {
    /**
     * get the progress of the campaign or ad based on start/end date
     *
     * @return {number} between 0 to 100
     */
    progress: function () {
        var s = this.getStamp('start'),
            e = this.getStamp('end'),
            n = Date.now(),
            progress = 0;

        if (s && e) {
            progress = (n - s) / (e - s) * 100;
            progress = Math.max(Math.min(progress, 100), 0);
        }
        return progress;
    },

    isInProgress: function () {
        var progress = this.progress();
        return progress > 0 && progress < 100;
    },

    /**
     * get UTC timestamp in ms
     * @param  {string} attribute name, start/end
     * @return {number} timestamp
     */
    getStamp: function (ref) {
        if (ref !== 'start' && ref !== 'end') {
            return 0;
        }

        return moment(this.get(ref)).valueOf();
    },


    /**
     * get time passed based on current time and start and end date
     * @returns {number}
     */
    timeElapsed: function () {
        var now = moment.utc();

        // flight is not set yet
        if (_.isEmpty(this.get('start')) || _.isEmpty(this.get('end'))) {
            return 0;
        }

        var start = moment.utc(this.get('start'));
        var end = moment.utc(this.get('end'));

        // not started yet
        if (now < start) {
            return 0;
        }

        return Math.min(now - start, this.getDuration());
    },

    formatTimeElapsed: function (format) {
        var t = this.timeElapsed();
        var d = this.getDuration();

        if (format === 'percentage') {
            return fns.formatNumber(Math.min(1, t / d), 'percentage');
        }

        if (format === 'nice') {
            return this.formatInterval(t);
        }
        // 1 decimal place
        return moment.duration(Math.min(t, d)).asDays().toFixed(1);
    },

    timeRemaining: function () {
        return this.getDuration() - this.timeElapsed();
    },

    formatTimeRemaining: function (format) {
        var t = this.timeRemaining();
        var d = this.getDuration();
        t = Math.max(0, t);

        if (format === 'percentage') {
            return fns.formatNumber(t / d, 'percentage');
        }

        if (format === 'nice') {
            return this.formatInterval(t);
        }

        return moment.duration(t).asDays().toFixed(1);
    },
    /**
     * this may not be a whole day when it's at the begining or end of duration
     */
    getDailyElapsed: function () {
        var start = moment.utc(this.get('start'));
        var end = moment.utc(this.get('end'));
        var now = moment.utc();

        if (now < start || now > end) {
            return 0;
        }

        if (now < start.endOf('day')) {
            return start.endOf('day') - now;
        }

        if (now > end.startOf('day')) {
            return now - end.startOf('day');
        }

        return moment.duration(1, 'day').valueOf();
    },

    formatDailyElapsed: function () {
        var duration = this.getDuration();
        var dailyElapsed = this.getDailyElapsed();

        return fns.formatNumber(Math.min(1, dailyElapsed / duration), 'percentage');
    },

    getYesterdayElapsed: function () {
        var start = moment.utc(this.get('start'));
        var now = moment.utc();
        var duration = this.getDuration();

        if (now < start) {
            return 0;
        }

        return Math.max(0, Math.min(now.startOf('day') - start, duration));
    },

    formatYesterdayElapsed: function () {

        var duration = this.getDuration();
        var yesterdayElapsed = this.getYesterdayElapsed();

        return fns.formatNumber(Math.min(1, yesterdayElapsed / duration), 'percentage');
    },

    getRemainingAfterToday: function () {
        if (!this.get('end')) {
            return 0;
        }
        return Math.max(0, moment(this.get('end')) - moment.utc().endOf('day'));
    },

    formatRemainingAfterToday: function () {
        var duration = this.getDuration();
        var remaining = this.getRemainingAfterToday();
        return fns.formatNumber(Math.min(1, remaining / duration), 'percentage');
    },

    /*
    2015-01-01T00:00:00/2015-01-02T11:59:59 should be displayed as 1 day, 12 hours
    2015-01-01T00:00:00/2015-01-02T11:49:59 should be displayed as 1 day, 11.9 hours
    2015-01-01T00:00:00/2015-01-01T00:48:59 should be displayed as 45 minutes
    */
    formatDuration: function () {
        var duration = moment.duration(this.getDuration());

        return this.formatInterval(duration);
    },

    formatInterval: function (interval) {

        if (!moment.isMoment(interval)) {
            interval = moment.duration(interval);
        }

        if (interval < moment.duration(1, 'hour')) {
            return interval.asMinutes().toFixed(1) + ' minutes';
        }

        if (interval < moment.duration(1, 'day')) {
            return interval.asHours().toFixed(1) + ' hours';
        }

        var days = Math.floor(interval.asDays());
        var hours = moment.duration(interval.asDays()  - days, 'days').asHours().toFixed(1);

        var dayPart = days? days + ' ' + countableUnit(days, ['day', 'days']) : '';
        var hourPart = hours? hours + ' ' + countableUnit(hours, ['hour', 'hours']) : '';

        return _.compact([dayPart, hourPart]).join(',');

    },

    toggleStatus: function () {
        return Q(this.save({'paused': !this.get('paused')}, {wait: true,patch: true}));
    },

    isLive: function () {
        return this.get('status') === 'live';
    },

    isRecent: function () {
        var freshness = moment.utc() - moment.utc(this.get('_created'));
        return moment.duration(freshness).asDays() < 10;
    },


    refresh: function () {

        this.trigger('refresh:start');

        var that = this;
        // this trigger stats loading one frame after ad sync
        // so stats collection can get initialized properly

        return new Promise(function (resolve, reject) {
            return that.fetch({success: resolve, error: reject});
        }).then(function () {
                return new Promise(function (resolve) {
                    that.stats.refresh(resolve);
                });
            }).catch(function (e) {
                that.trigger('notfound');
                throw 'Campaign is not found';
            }).finally(function () {
                that.trigger('refresh:done');
            });
    },

    getTodayPortion: function () {

        if (this.getDuration() === 0) {
            return 0;
        }
        return this.getTodayDuration() / this.getDuration();
    },

    getTodayDuration: function () {
        if (this.getDuration() === 0) {
            return 0;
        }

        var timezone = 'UTC';

        var t = this.getDuration(),
            s = moment.utc(this.get('start')).valueOf(),
            e = moment.utc(this.get('end')).valueOf();

        if (isNaN(s) || isNaN(e)) {
            return 0;
        }

        var beginOfToday = moment().tz(timezone).startOf('day').subtract(1, 'seconds').valueOf();
        var endOfToday = moment().tz(timezone).endOf('day').valueOf();

        return Math.max(Math.min(endOfToday, e) - beginOfToday, 0);
    },

    getTotalFilled: function () {
        if (this.stats) {
            return this.stats.getTotalFilled(this.getTotalTarget(), this.goal());
        }

        return 0;
    },

    getTodayElapsed: function () {
        var now = Date.now();
        if (!this.get('end')) {
            return 0;
        }
        return Math.max(0, Math.min(Math.min(now, moment.utc(this.get('end'))) - moment.utc().startOf('day'), 24 * 3600 * 1000));
    },

    getTodayTarget: function () {
        return Math.round(this.getTotalTarget() * this.getTodayPortion());
    },

    getTotalCTR: function () {
        if (this.stats) {
            return this.stats.getTotalCTR();
        }
        return 0;
    },

    getTodayCTR: function () {
        if (this.stats) {
            return this.stats.getTodayCTR();
        }
        return 0;
    },
    
    getTodayFilled: function () {
        if (this.stats) {
            var todayPortion = this.getTodayPortion();
            return this.stats.getTodayFilled(this.getTotalTarget() * todayPortion, this.goal());
        }

        return 0;
    },

    getTotalFilledCount: function () {
        if (this.stats) {
            return this.stats.getTotalFilledCount(this.goal());
        }
        return 0;
    },

    getTodayFilledCount: function () {
        if (this.stats) {
            return this.stats.getTodayFilledCount(this.goal());
        }
        return 0;
    },

    computeDisplayData: function () {
        var todayElapsed = this.getTodayElapsed();
        var totalElapsed = this.timeElapsed();

        var duration = this.getDuration();
        var todayDuration = this.getTodayDuration();
        var totalTarget = this.getTotalTarget();
        var todayTarget = this.getTodayTarget();

        return {
            totalFilledPercentage: this.getTotalFilled(),
            todayFilledPercentage: this.getTodayFilled(),

            totalFilledCount: this.getTotalFilledCount(),
            todayFilledCount: this.getTodayFilledCount(),

            totalElapse: totalElapsed,
            todayElapse: todayElapsed,

            totalElapsePercentage: duration === 0 ? 0: totalElapsed / duration,
            todayElapsePercentage: todayDuration === 0 ? 0: todayElapsed / todayDuration,

            totalDuration: duration,
            todayDuration: todayDuration,

            totalCount: this.getTotalTarget(),
            todayCount: this.getTodayTarget(),

            totalCTR: this.getTotalCTR(),
            todayCTR: this.getTodayCTR(),
            goal: this.goal()
        }
    },
    
    performanceStatus: function () {
        if (!this.stats || this.stats.isEmpty()) {
            return 'default';
        }
        var settings = this.getBulletGraphAttributes();
        var wRemaining = settings.wRemaining;
        var wToday = settings.wToday;
        var wSuccess = settings.wSuccess;
        var sum = wRemaining + wToday + wSuccess;

        if (sum > 1) {
            return 'ok';
        } else if (sum < 0.9) {
            return 'danger';
        } else {
            return 'warning';
        }


    },


    getHistory: function () {
        var that = this;
        return new Promise(function (res, rej) {
            return $.ajax({url: that.url() + '/history', dataType: 'json', success: res, error: rej});
        }).then(function (data) {
            // check data
            // data should be an array of history object {snapshot: Object}
            if (!_.every(data, function (h) {return _.has(h, 'snapshot');})) 
                throw new Error('This entity does not have a snapshot.');
            else
                return data;
        });
    }
    
};
exports.modelHelpers = modelHelpers;


function countableUnit(quauntity, units) {
    if (quauntity <= 1) {
        return units[0];
    }
    return units[1];
}
