'use strict';
var categories = require('scripts/common/constants/IAB-categories');
var Popup = require('scripts/widgets/popup');
var HistoryWidget = require('scripts/widgets/history');
var PrivateChannel = require('scripts/common/mixins/private-channel');

var HistoryView = React.createClass({
    mixins: [PrivateChannel],
    getInitialState() {
        return {
            data: {}
        };
    },

    componentDidMount() {
        this.props.campaign.getHistory().then((history)=>this.setState({data: history})).catch(toastr.error);
        this.privateCh.listenTo(this.props.ch, 'popup:close', ()=>this.props.onClosePopup());
    },

    render() {
        var data = this.state.data;

        if (_.isEmpty(data)) {
            return null;
        }
        var organizations = this.props.ch.request('organizations');
        var historyData = massageDataForCampaign(data, organizations.toJSON(), categories);

        return (
            <Popup ch={this.props.ch}>
                <h1>Campaign History <small>{this.props.campaign.get('name')}</small></h1>
                <HistoryWidget history={historyData} />
                <div className="row"><div className="col-xs-3"><a className="btn btn-success" onClick={this.props.onClosePopup}>Close</a></div></div>
            </Popup>
        );
    }
}) ;

module.exports = HistoryView;

var fns = require('scripts/common/fns');
function massageDataForCampaign(data, organizations, categories) {

    var attrMappings = {
        advertiser_domain: 'Advertiser Domain',
        iab_categories: 'IAB Categories',
        id: 'ID',
        name: 'Name',
        notes: 'Notes',
        organization: 'Organization',
        paused: 'Paused'
    };

    function formatter(key)  {
        switch (key) {
            case 'paused':
                return function (v) {return S(v.toString()).capitalize().s;};
            case 'iab_categories':
                return function (v) {return _.map(v, function (c) {return categories[c];});}
            case 'organization':
                return function (v) {return objectPath.get(_.find(organizations, {id: v}), 'name');};
        };
        return false;
    }

    return fns.historyFormatter(data, attrMappings, formatter);
}
