<div class="modal-header">
    <h3>
        Review: {{creative.name}}
    </h3>
</div>

<div class="modal-body">

    <!--name-->
    <div class="row">
        <div class="col-xs-4">
            <label for="">Name</label>
        </div>
        <div class="col-xs-8">{{creative.name}}</div>
    </div>

    <!--format-->
    <div class="row">
        <div class="col-xs-4">
            <label for="">Format</label>
        </div>
        <div class="col-xs-8">{{creative.format}}</div>
    </div>

    <!--size-->
    <div class="row">
        <div class="col-xs-4">
            <label for="">Size</label>
        </div>
        <div class="col-xs-8">{{creative.size}}</div>
    </div>

    <!--image preview url-->
    <div class="row">
        <div class="col-xs-4">
            <label for="">Image Preview</label>
        </div>
        <div class="col-xs-8">
            <a href="{{creative.image_preview_url}}" data-bypass target="_blank" style="word-wrap: break-word;">{{creative.image_preview_url}}</a>
        </div>
    </div>

    <!--click through url-->
    <div class="row">
        <div class="col-xs-4">
            <label>Clickthrough url</label>
        </div>
        <div class="col-xs-8">
            <a href="{{creative.clickthrough_url}}" data-bypass target="_blank" style="word-wrap: break-word;">{{creative.clickthrough_url}}</a>
        </div>
    </div>

    <!--landing page url-->
    <div class="row">
        <div class="col-xs-4">
            <label>Landing Page Url</label>
        </div>
        <div class="col-xs-8">
            <a href="{{creative.landing_page}}" data-bypass target="_blank" style="word-wrap: break-word;">{{creative.landing_page}}</a>
        </div>
    </div>

    <!--campaign categories-->
    <div class="row">
        <div class="col-xs-4">
            <label for="">Categories</label>
        </div>
        <div class="col-xs-8">
            {{#each campaign.iab_categories}}
                <span class="label label-inverse">{{decode_IAB_category this}}</span>
            {{/each}}
        </div>
    </div>

    <!--campaign id-->
    <div class="row">
        <div class="col-xs-4">
            <label for="">Campaign</label>
        </div>
        <div class="col-xs-8">
            {{campaign.id}}
        </div>
    </div>

    <!--comments-->
    <div class="row">
        <div class="col-xs-4">
            <label for="">Comments</label>
        </div>
        <div class="col-xs-8">
            <textarea class="form-control" placeholder="Rejection must be provided with explanation...">{{assessment_explanation}}</textarea>
        </div>
    </div>
</div>

<div class="modal-footer">
    {{#is_equal status PENDING}}
        <button class="btn pull-left" data-dismiss="modal">Later</button>

        <button class="btn btn-action" data-action="approve">Approve</button>
        <button class="btn" data-action="reject">Reject</button>
    {{else}}
        <div class="pull-left">
            Status: <strong>{{humanize ../status}}</strong>
        </div>
        <button class="btn" data-action="change">Change Status</button>
        <div class="hide">
            {{#is_equal ../status APPROVED}}
                <button class="btn" data-action="reject">Reject</button>
            {{else}}
                <button class="btn btn-action" data-action="approve">Approve</button>
            {{/is_equal}}
            <a class="btn" data-action="cancel-change" href>cancel</a>
        </div>
    {{/is_equal}}
</div>
