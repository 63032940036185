'use strict';

var Form = require('scripts/common/mixins/form');
var Session = require('scripts/entities/session');
var signinTemplate = require('../templates/signin');

module.exports = Mario.ItemView.extend({
    template: signinTemplate,
    events: {
        'click #signin': 'onSave'
    },

    onSave: function (e) {
        var email = this.$('#signin-email').val();
        var password = this.$('#signin-password').val();
        var that = this;
        if (_.isEmpty(email) || _.isEmpty(password)) {
            this.$('.help-block').removeClass('hidden').text('Please fill in email address and password.');
            this.$el.addClass('has-error');
            return;
        }
        this.$('#signin-form').find('button, input').attr('disabled', true);
        this.$('#signin').text('Signing in...');

        /* jshint newcap: false*/
        var req = Q($.ajax({
            type: 'post',
            url: 'auth?populate=user,role,organization',
            data: JSON.stringify({email: email , password: password})
        }));

        req.then(function (data) {

            that.undelegateEvents();

            var session = new Session();
            session.save(data);
            document.cookie = 'authorization=' + data.auth_token + ';path=/';

            localStorage.setItem('_flash', 'You have signed in successfully.');

            // have to enable all input and button in order to trigger password manager in chrome
            $('#signin-form').find('button, input').prop('disabled', false);
            $('#signin').click();
            $('#signin-form').find('button, input').prop('disabled', true);

        }, function (e) {

            if (e.status === 403) {
                that.$('.help-block').removeClass('hidden').text('Email or password does not match.');
                that.$el.addClass('has-error');
            }

            that.$('#signin-form').find('button, input').attr('disabled', false);
            that.$('#signin').text('Sign in');

        });

        return false;
    },

    templateHelpers: function () {
        return {
            errors: function () {
                return false;
            }
        };
    },

    mixins: [Form]
});