<div class="modal-head">
    <h3>User Profile</h3>
</div>

<div class="modal-body">

    <table class="table" style="margin-left: -15px;">

        <tr>
            <th>Email</th>
            <td>{{email}}</td>
        </tr>

        <tr>
            <th>ID</th>
            <td>{{id}}</td>
        </tr>

        <tr>
            <th>IP</th>
            <td>{{client_ip}}</td>
        </tr>

        <tr>
            <th>Organization</th>
            <td>{{session.organization.name}}</td>
        </tr>

        <tr>
            <th>Role</th>
            <td>{{session.role.name}}</td>
        </tr>

        <tr>
            <th>Suspended</th>
            <td>{{# if suspended}} Yes {{else}} No {{/if}}</td>
        </tr>

        <tr>
            <th>Dashboard version</th>
            <td><span class="label label-default">{{VERSION}}</span></td>
        </tr>

        {{#if API_VERSION}}
            <tr>
                <th>Dashboard API version</th>
                <td><span class="label label-default">{{API_VERSION}}</span></td>
            </tr>
        {{/if}}

    </table>
</div>

<div class="modal-footer">
    <div class="btn btn-info" data-action="cancel">Ok</div>
</div>
