'use strict';

var flux = require('scripts/core/flux');
var campaigns = require('scripts/core/campaign-collection');


var _ads = {};

var AdsStore = Fluxxor.createStore({

    initialize: function (options) {
        this.bindActions(
            flux.constants.ads_fetch_success, 'onAds_fetch_success'
        );
    },

    onAds_fetch_success: function (ads) {
        this.emit('change');
    },

    getById: function (adId, campaignId) {
        return campaigns.get(campaignId).ads.get(adId);
    },

    getAdsByCampaignId: function (campaignId) {
        return campaigns.get(campaignId).ads;
    }
});

function mergeAds (ads) {
    ads.forEach(function (ad) {
        _ads[ad.id] = ad;
    });
}

flux.addStore('ads', new AdsStore());


