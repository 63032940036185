'use strict';

var layoutTemplate = require('./templates/layout');

var HourlyGraph = Marionette.LayoutView.extend({
    className: 'hourly-graph',

    template: layoutTemplate,

    onShow: function () {
        var padding = {
            top: 30, bottom: 50, left: 200, right: 150
        };

        var fullWidth = this.$el.width();
        var fullHeight = this.$el.height();

        // 24 hours
        var numOfColumns = 24;

        Object.defineProperties(this, {
            // inner graph width
            'width': {
                get: function () {
                    var maxGraphWidth = fullWidth - padding.left - padding.right;
                    // round down according to number of columns to draw
                    return Math.floor(maxGraphWidth / numOfColumns ) * numOfColumns;
                }
            },
            'height': {
                get: function () {
                    return fullHeight - padding.top - padding.bottom;
                }
            },

            // inner graph
            'graph': {
                get: function () {
                    var node = this.$('svg.graph').get(0);
                    var graph = d3.select(node);

                    graph.style({width: fullWidth, height: fullHeight});

                    var container = graph.select('g.inner').attr('transform', 'translate(' + padding.left + ', ' + padding.top + ')');
                    return container
                }
            },

            'barWidth': {
                get: function () {
                    return this.width / 27;
                }
            },

            'infoPaneWidth': {
                value: 200,
                writable: false
            },

            'infoPaneHeight': {
                value: 100,
                writable: false
            }
        });

        if (this.collection.isEmpty()) {
            this.$('svg').remove();
            this.$el.addClass('empty').append('No data.');
        } else {
            this.renderGraph();
        }
    },

    renderGraph: function () {
        this.initAxis();
        this.drawBars();
        this.drawAxis();
        this.drawExpectedLine();
        this.initHover();
    },
    
    initHover: function () {
        var that = this;
        this.graph.on('mousemove', function () {
            var coord = d3.mouse(this);
            var x = Math.max(0, Math.floor(coord[0] / that.barWidth));
            that.graph.select('.bars path.active').classed('active', false);
            that.graph.select('[data-id=hour-' + x + ']').classed('active', true);

            var datum = that.collection.find(function (d) {return d.get('hour') === x; });
            if (datum != null) {
                var count = datum.get(that.options.type);
                that.showInfoPaneAt(x * that.barWidth, that.yScale(count), x, that.options.date, that.options.type, count);
            }

            if (coord[1] > that.height || coord[1] < 0) {
                that.removeInfoPane();
            }
        });
    },

    removeInfoPane: function () {
        this.graph.select('.info-pane').remove();
    },

    showInfoPaneAt: function (posx, posy, hour, date, type, count) {
        this.removeInfoPane();

        var that = this;
        var boxHeight = 80, cornerRadius = 8;

        // pane padding
        var padding = {
            left: 20, right: 20, top: 20, bottom: 20
        };

        // estimate
        var lineHeight = 18;
        var paneHeight = lineHeight * 3 + padding.top + padding.bottom;
        var paneWidth = 150;
        var paneYPos = posy - (boxHeight - (that.height - posy)) / 2;

        var pane = this.graph.append('g').classed('info-pane', true)
            .attr('transform', 'translate(' + posx + ' , ' + paneYPos + ' )');

        var infoBox = pane.append('g').classed('info-box', true).attr('transform', 'translate(' + this.barWidth / 2 + ', 0)').style('opcaity', 0);

        infoBox.transition().style('opacity', 1);

        var anchorWidth = 20;
        var anchorHeight = 18;

        // main background
        infoBox.append('rect')
            .attr({
                'width': paneWidth,
                height: boxHeight,
                rx: cornerRadius,
                ry: cornerRadius,
                x: anchorWidth
            })
            .style({
                'stroke': '#3399ff',
                'fill': '#fff'
            });

        var anchorOffTop = ( boxHeight - anchorHeight ) / 2;
        var p1 = [anchorWidth + 1, anchorOffTop], p2 = [0, boxHeight / 2], p3 = [anchorWidth + 2, anchorOffTop + anchorHeight];

        // anchor
        infoBox.append('path').classed('anchor', true)
            .attr('d', d3.svg.line()([p1, p2, p3]) + 'Z')
            .style({
                fill: '#fff',
                stroke: '#fff'
            });

        infoBox.append('path').classed('anchor-border', true)
            .attr('d', d3.svg.line()([p1, p2, p3]))
            .style({
                stroke: '#3399ff',
                fill: '#fff'
            });

        var date = moment.utc(date);

        pane.append('text').text(date.format('MMM DD. YYYY')).classed('date', true).attr({
            x: that.barWidth + 20,
            y: 20
        });

        var hour = moment.utc().set('hour', hour).add(1, 'hour');
        var preHour = hour.clone().subtract(1, 'hour');
        pane.append('text').text(preHour.format('HH:00') + ' ~ ' + hour.format('HH:00 a')).classed('hour', true).attr({
            x: that.barWidth + 20,
            y: 40
        });
        pane.append('text').text(type + ': ' + count).classed('count', true).attr({
            x: that.barWidth + 20,
            y: 60
        });
    },

    initAxis: function () {
        var that = this;
        var maxNode = this.collection.max(function (d) {return d.get(that.options.type);});

        var targetCount = this.options.average;

        if (maxNode != null) {
            targetCount = Math.max(maxNode.get(this.options.type), targetCount);
        }
        this.xScale = d3.scale.linear().domain([0, 27]).range([-1, this.width]);
        this.yScale = d3.scale.linear().domain([targetCount, 0]).range([0, this.height]);
    },

    drawExpectedLine: function () {
        var hourlyAverage = this.options.average;
        var y = this.yScale(hourlyAverage);
        var line = d3.svg.line()([[0, y], [this.width, y]])

        this.graph.append('g').classed('average-line', true).append('path').attr('d', line).style('stroke', 'black').attr('stroke-dasharray', '1,1');
    },

    drawAxis: function () {
        var targetCount = this.options.average;
        var xAxis = d3.svg.axis()
            .scale(this.xScale)
            .orient('botttom')
            .innerTickSize(10)
            .outerTickSize(2)
            .ticks(24)
            .tickFormat(function (t) {

                if (t === 25) {
                    return 'HOUR';
                }

                if (t < 0 || t > 24) {
                    return '';
                }

                if (t === 0) {
                    return '00am';
                }

                if (t === 24) {
                    return '24am';
                }


                if (t < 10) {
                    return '0' + t;
                }

                return t;
            });

        var yAxis = d3.svg.axis()
            .scale(this.yScale)
            .outerTickSize(1)
            .tickValues([targetCount])
            .tickFormat(function (t) {
                return 'Expected Hourly Delivery: ' + numeral(targetCount).format('0,0');
            })
            .orient('left');

        this.graph.append('g')
            .attr('class', 'x axis')
            .attr('transform', 'translate(0,' + (this.height + 1 ) + ')')
            .call(xAxis).selectAll('text').style('text-anchor', 'start');

        this.graph.append('g')
            .classed('y axis', true)
            .call(yAxis)

    },

    drawBars: function () {
        var that = this;
        var rect = d3.svg.area().y0(this.height);

        this.graph.append('g').classed('bars', true).selectAll('path').data(this.collection.toJSON()).enter().append('path')
            .attr('data-id', function (d) {
                return 'hour-' + d.hour;
            })
            .attr('d', function (d) {
                var x = that.xScale(d.hour);
                var y = that.yScale(d[that.options.type]);
                return rect([[x, y], [x + that.barWidth , y]]);
            });
    }
});

module.exports = HourlyGraph;
