module.exports = React.createClass({

    getDefaultProps () {
        return {
            health: null,
            isLoading: false,
        };
    },

    render () {
        if (this.props.isLoading) {
            return <i className={`am-healthIndicator is-nullHealth fa fa-fw fa-ellipsis-h`}></i>;
        }

        switch (this.props.health) {
            case null:
                return <i className={`am-healthIndicator is-${this.props.health}Health fa fa-fw fa-circle-o`}></i>;
            default:
                return <i className={`am-healthIndicator is-${this.props.health}Health fa fa-fw fa-circle`}></i>;
        }
    }
});