
<table class="list table table-hover">

    <thead>
        <tr>
            <th>Name</th>
            <th>Preview</th>
            <th>Width</th>
            <th>Height</th>
            <th>Type</th>
            <th>Duration (video)</th>
        </tr>
    </thead>

    <tbody>

    </tbody>

</table>

