// global tracking function

/**
 * How to track? 
 * We embed following data attributes in the markup 
 *
 * Enable Tracking 
 * {null} data-track
 *
 * Context Params
 * {string} data-tracking-section
 * {string} data-tracking-section-id
 * {bool} data-tracking-form
 * {bool} data-tracking-popup
 */

/**
 * Custom Dimensions
 * dimension1. section (session)
 * dimension2. sectionId (session)
 * dimension3. environment (hit)
 */

/**
 * Custom Metric
 * metric1. tabCount
 */

var session, env;

const BROWSER_TIMER_INTERVAL = 200;
const SEND_DELAY = 201;

// Event types
// `trigger` event is initiated by user
// it will change current app context
const trigger = 'trigger';
const state   = 'state';
const error   = 'error';
const view    = 'view';
const validation = 'validation';
const init    = 'init';

// application context of the current data
var appContext = {};

// Browser state
// This includes variables like window width/height, url, etc
// Also the window.navigator.userAgent 
var browser = {
    ua: window.navigator.userAgent 
};

var validationBuffer = [];

var tracking = window.uTracking = function (method) {
    var rest = _.rest(arguments);
    switch (method) {
        case 'init':
            initTracking.apply(null, rest);
            break;
        case 'send':
            prepareToSend.apply(null, rest);
            break;
        case 'set:session':
            session = _.first(rest);
            initTrackingAgent(env);
            break;
    }
};

tracking.types = {trigger,state,error,view};

var sendValidation = _.debounce(function () {
    send(validation, {errors: validationBuffer}).then(function () {
        validationBuffer = [];
    });
}, 100);

function initTracking(_env) {

    env = _env;

    setupDOMListeners();
    setupBrowserTimers();

    initTrackingAgent(env);

    send(init, {});
}

function initTrackingAgent(env) {
    // we use google analytics
    // GA is loaded in the html head
    //
    // Except initialization
    // GA tracking code should only be limited in this file
    
    if (!_.isObject(env)) {
        return;
    }

    var gaOption = {};


    // using staging property
    if (_.contains(['development', 'integration', 'staging'], env.NAME)) {
        _.extend(gaOption, {
            'cookieDomain': 'none'
        });
    } else {
        _.extend(gaOption, {});
    }
    ga('create', env.GOOGLE_GA_UA, gaOption);

    // set environment name
    ga('set', 'dimension3', env.NAME);

    if (session != null) {
        ga('set', '&uid', objectPath.get(session, 'user.id'));
    } else {
        ga('set', '&uid', undefined);
    }
}

function setupDOMListeners() {
    // setup DOM listeners
    $(document).on('click', '[data-track]', prepareToSend.bind(null, trigger));
}

function setupBrowserTimers() {
    setTimeout(function () {
        // url 
        let url = window.location.href;
        if (url !== browser['url']) {
            onUrlChange(browser['url'], url);
            browser['url'] = url;
        }
        let wWidth = $(window).width();
        let wHeight = $(window).height();
        if (wWidth !== browser['width']) {
            browser['width'] = wWidth;
        }
        if (wHeight !== browser['height']) {
            browser['height'] = wHeight;
        }

        // call self 
        setupBrowserTimers();

    }, BROWSER_TIMER_INTERVAL);
}

function prepareToSend(type) {
    var rest = _.rest(arguments);

    switch (type) {
        case trigger:
            sendTrigger.apply(null, rest);
            break;
        case state:
            sendState.apply(null, rest);
            break;
        case error:
            // TODO
            break;
        case view:
            // TODO
            break;
        case validation:
            validationBuffer.push(_.first(rest));
            sendValidation();
            break;
    }
}

function sendTrigger(ev) {
    appContext = getContext(ev);
    var extraAttrs = [
        "type", 
        "timeStamp", 
        "screenY", 
        "screenX", 
        "pageY", 
        "pageX", 
        "offsetY", 
        "offsetX", 
        "clientY", 
        "clientX", 
        "button", 
        "which", 
        "shiftKey", 
        "metaKey",
        "eventPhase", 
        "ctrlKey", 
        "cancelable", 
        "bubbles", 
        "altKey"];

    send(trigger, _.extend({}, eventToMetaData(ev), _.pick(ev, extraAttrs)), {});
}

function sendState(data) {
    // application state

    /*
     * custom metric
     * @property {integer} tabCount 
     */

    if (_.has(data, 'tabCount')) {
        ga('set', 'metric1', data.tabCount);
    }

}

function send(type, object) {
    var objToSend = {
        // data point type
        dp_type: type,
        // data point object
        dp: object,
        // app context
        app_context: appContext,
        // session object
        s: session,
        // browser object
        browser: browser,
        // timestamp
        ts: Date.now()
    };

    return new Promise(function (res, rej) {
        _.delay(analyticAdapter(objToSend, res), SEND_DELAY);
    });
}

// expose data to third party analytics here
function analyticAdapter(objToSend, done) {

    // Google Analytics
    // Metrics
    // userId:  User ID
    // timing
    // page name
    // event

    if (objToSend.dp_type === trigger) {
        ga('send', 'event', 'button', 'click', objectPath.get(objToSend, 'dp'));
    }

    if (objToSend.dp_type === validation) {
        // set validation 
        ga('send', 'event', 'validation', 'errors', objectPath.get(objToSend, 'dp.errors'));
    }

    done();
}

function onUrlChange(from, to) {
    to = to.replace(window.location.origin, '');

    // set current page
    ga('set', 'page', to);

    // pageview
    ga('send', 'pageview');
}


/**
 * @typedef Context - App context
 * @param {string} section
 * @param {string} sectionId
 */

/*
 * Get {appContext} based on ev
 * This is specified by data-tracking-* attribute in the markup
 * @param {jQuery.event} ev
 * @return Context
 **/
function getContext(ev) {
    var target = $(ev.currentTarget);
    var context = {
        section:   target.data('tracking-section') + '',
        sectionId: target.data('tracking-section-id') + ''
    };

    // set CD - section
    // section
    ga('set', 'dimension1', context.section);
    // sectionId
    ga('set', 'dimension2', context.sectionId);
    return context;
}

// modified from Bugsnag
// https://github.com/bugsnag/bugsnag-js/blob/master/src/bugsnag.js#L489-L519 
// Populate the event tab of meta-data.
function eventToMetaData(event) {
    var tab = {
        millisecondsAgo: new Date() - event.timeStamp,
        type: event.type,
        which: event.which,
        currentTarget: targetToString(event.currentTarget)
    };

    return tab;
}

// Convert a DOM element into a string suitable for passing to Bugsnag.
function targetToString(target) {
    if (target) {
        var attrs = target.attributes;

        if (attrs) {
            var ret = "<" + target.nodeName.toLowerCase();
            for (var i = 0; i < attrs.length; i++) {
                if (attrs[i].value && attrs[i].value.toString() != "null") {
                    ret += " " + attrs[i].name + "=\"" + attrs[i].value + "\"";
                }
            }
            return ret + ">";
        } else {
            // e.g. #document
            return target.nodeName;
        }
    }
}
