'use strict';

var Ads = require('./ads');
var Stats = require('./stats');
var Creatives = require('./creatives');
var Files = require('./assets');
var Groupings = require('./campaign-groupings');

var domainCountries = require('scripts/common/constants/domain-country');
var helpers = require('../helpers');
var fns = require('scripts/common/fns');
var app = require('scripts/core').app;


/**
 * campaign load together with snippets ads
 *
 * ads collection will be mounted as campaign.ads
 */

var Campaign = Mario.Model.extend({
    modelName: 'Campaign',
    urlRoot: 'campaigns/',
    isCampaign: function () {
        return true;
    },
    defaults: {
        name              : '',
        advertiser_domain : '',
        notes             : '',
        progress          : null,
        status            : 'paused', // readonly
        paused            : true,
        iab_categories    : [],

        currency          : 'USD',

        // @type {ISOString}
        start             : '',

        // @type {ISOString}
        end               : '',
        owner             : '',
        comment           : '' // change comment
    },

    initialize: function () {
    },

    parse: function (resp, options) {

        if (resp.id && !this.subcollections) {
            // this is only done once

            this.subcollections = {};
            var id = resp.id;
            this.subcollections['ads'] = new Ads([], {collectionId: id, campaign: this});
            this.subcollections['stats'] = new Stats([], {collectionId: id, campaign: this});
            this.subcollections['creatives'] = new Creatives([], {collectionId: id, campaign: this});
            this.subcollections['files'] = new Files([], {collectionId: id, campaign: this});
            this.subcollections['groupings'] = new Groupings([], {collectionId: id, campaign: this});

            // alias
            this.ads = this.subcollections['ads'];
            this.stats = this.subcollections['stats'];
            this.creatives = this.subcollections['creatives'];
            this.groupings = this.subcollections['groupings'];
            this.files = this.assets = this.subcollections['files'];

            this.stats.on('sync', function () {
                this.trigger('stats:loaded');
            }, this);

            this.creatives.on('change:audit_status', function () {
                this.ads.fetch();
            }, this);

            this.ads.on('add remove', function () {
                this.fetch();
            }, this);
        }

        // merge ads data coming from campaign
        if (this.subcollections.ads != null && !this.subcollections.ads.hasSynced()) {
            this.subcollections.ads.set(resp.ads, {silent: true, merge: true});
        }
        // merge creatives data coming from campaign
        if (this.subcollections.creatives != null) {
            this.subcollections.creatives.set(resp.creatives, {silent: true, merge: true});
        }

        return _.omit(resp, ['ads', 'creatives']);
    },

    validation: {
        name: {
            required: true
        },

        advertiser_domain: function (value, attr) {
            /**
             * match the followings
             * google.com
             * google.com.hk
             * geek-reader.com
             * 163.com
             *
             * does not match the followings
             * http://google.com
             * www.google.com
             * http://www.google.com
             * dk^3kdks
             * [empty]
             * a.b.c.d.e
             * abc@def.com
             */

            var lastPartPattern = /^[a-z]+$/i;
            var otherPartPattern = /^[a-z0-9\-]+$/i;
            var parts = value.split('.');

            var regMatch = _.every(parts.slice(0, -1), function (part) {
                return part.match(otherPartPattern);
            }) && _.last(parts).match(lastPartPattern);

            if (!regMatch) {
                return fail();
            }

            if (parts.length === 2 && _.last(parts).match(lastPartPattern)) {
                return null;
            } else if (parts.length === 3 && _.contains(domainCountries, _.last(parts))) {
                // has country name, it should have 3 parts
                return null;
            }

            return fail();

            function fail() {
                return 'You must provide the advertiser\'s domain name, e.g. abc.com';
            }
        },

        iab_categories: function (value, attr) {
            if (_.isEmpty(value)) {
                return 'IAB Category must be provided';
            }
        }
    },

    searchTerm: function (term) {
        if (typeof term !== 'string') return true;

        term = term.toLowerCase();

        if (this.ads.any(function (ad) {return ad.searchTerm(term);})) {
            return true;
        }
        // basic campaign attributes
        var hay = _.values(this.pick('name', 'id', 'notes', 'advertiser_domain', 'status', 'organization')).join(' ');
        // IAB categories
        hay += ' ' + this.getIABCategoryNames().join(' ');
        // start, end date
        hay += ' ' + moment.utc(this.get('start')).format('MMM DD') + ' ' + moment.utc(this.get('end')).format('MMM DD');
        // do a string match
        return S(hay.toLowerCase()).contains(term);
    },

    can: function (op) {
        // alias edit to update
        if (op === 'edit') {
            op = 'update';
        }
        return _.contains(this.get('_permissions'), op);
    },

    computed: function () {
        return {
            _progress: this.progress()
        };
    },

    loadSimpleStats: function () {
        /* jshint newcap: false */
        var statsReq = globalCh.request('cache', 'stats', null, this.id);
        return Q(statsReq);
    },

    getIABCategoryNames: function () {
        var rawCategories = require('scripts/common/constants/IAB-categories');
        return _.map(this.get('iab_categories'), function (cat) {return rawCategories[cat];});
    },

    getTotalTarget: function () {
        var goal = this.goal();
        if (goal === 'mixed') {
            return 0;
        }

        if (this.ads && !this.ads.isEmpty()) {
            return this.ads.getTotalTarget(false);
        }

        return 0;
    },

    goal: function () {
        if (this.ads && !this.ads.isEmpty()) {
            var totalClicks = this.ads.pluck('max_total_clicks');
            var totalImpressions = this.ads.pluck('max_total_impressions');

            if (_.every(totalClicks, function (c) {return c > 0;})) {
                return 'clicks';
            }

            if (_.every(totalImpressions, function (i) {return i > 0;})) {
                return 'impressions';
            }
        }

        return 'mixed';
    },


    /**
     * show the progress status of the campaign
     * 'ok', 'warning' and 'danger'
     *
     * @param ct current achieved target, from stats
     * @returns {string}
     */
/*
    performanceStatus: function (ct) {

        var yesterdayTs = moment.utc().startOf('day');
        var rt = this.getTargetAfter(yesterdayTs.valueOf() + this.getDailyElapsed());
        var y = this.getTargetBefore(yesterdayTs.valueOf());

        if (ct >= y) {
            return 'ok';
        }

        if (rt === 0) {
            return 'danger';
        }
        var missedRate = (y - ct) / rt;

        if (missedRate > 0.1) {
            return 'danger';
        } else {
            return 'warning';
        }
    },
*/

    getTargetAfter: function (ts) {
        if (!this.ads) {
            return 0;
        }

        return this.ads.getTargetAfter(ts);
    },

    getTargetBefore: function (ts) {
        if (!this.ads) {
            return 0;
        }

        return this.ads.getTargetBefore(ts);
    },

    getDuration: function () {
        if (this.ads) {
            return this.ads.getDuration();
        }
        return 0;
    },



    getBulletGraphAttributes: function () {
        return {
            start     : this.get('start'),
            end       : this.get('end'),
            wRemaining: this.getDuration() === 0? 0 : this.getRemainingAfterToday() / this.getDuration(),
            wToday    : this.getTodayPortion(),
            wSuccess  : this.getTotalFilled()
        };
    },

    loadRealTimeStats: function (cb) {
        var that = this;

        return new Promise(function (res, rej) {
            that.stats.loadRealTimeStats(res);
        }).then(function () {
            // load all ad live stats
            return Promise.all(that.ads.map(function (ad) {
                return new Promise(function (res, rej) {
                    return ad.stats.loadRealTimeStats(res);
                });
            }));
        }).then(function () {
            return _.isFunction(cb) && cb();
        });
    }

}).extend(helpers.modelHelpers);


var ALLOWED_FILTER_ATTRS = ['category', 'search', 'status', 'id', 'owner', 'creative_type'];
var ALLOWED_STATE_ATTRS = ['pageSize', 'currentPage', 'sortKey'];

var Campaigns = Mario.Collection.extend({

    refresh: function (options) {
        if (!_.isObject(options)) {
            options = {};
        }

        if (options.reset == null) {
            options.reset = true;
        }

        this.trigger('refresh:start');
        var that = this;

        return new Promise(function (resolve, reject) {
            that.fetch(_.extend(options, {success: resolve, error: reject}));
        }).finally(function () {
                that.trigger('refresh:done');
            });
    },

    initialize: function (models, options) {
        this.on('change:simple-search:dashboard ' +
            'change:advance-search:dashboard ' +
            'change:filter:dashboard ' +
            'reset:search:dashboard',
            function () {
                this.state.currentPage = 0;
                this.refresh();
            });

        this.on('change:state:dashboard', function (data) {
            this.refresh();
            globalCh.command('save-filter', data);
        });

        if (options.filterStates != null) {

            var states = _.pick(options.filterStates, ['pageSize', 'simple-search', 'advance-search', 'filter']);

            if (states.pageSize != null) {
                _.extend(this.state, {pageSize: states.pageSize});
            }

            _.extend(this.queryParams, states['simple-search'], states['advance-search'], states['filter']);
        }
    },

    getSearchTerms: function () {
        return _.pick(_.pick(this.queryParams, ALLOWED_FILTER_ATTRS), _.identity);
    },

    model: Campaign,
    url: 'campaigns',

    state: {
        firstPage: 0,
        currentPage: 0,
        pageSize: 20,
        sortKey: '-created'
    },

    queryParams: {
        currentPage: 'page',
        pageSize: 'limit',
        sortKey: 'sort',
        totalRecords: 'maxResults',

        // filter
        owner: 'self'
    },

    setState: function (attr, val) {
        var attrs = {};

        if (typeof attr === 'string') {
            attrs[attr] = val;
        } else if (_.isObject(attr)) {
            attrs = attr;
        }

        // reset current page number if page size is changed
        if (_.has(attrs, 'pageSize')) {
            attrs['currentPage'] = 0;
        }

        var setStateAttr = function (val, attr) {

            if (!_.contains(ALLOWED_STATE_ATTRS, attr)) {
                return;
            }

            this.state[attr] = val;
        }

        _.each(attrs, setStateAttr, this);

        this.trigger('change:state:dashboard', attrs);
    },

    setOwner: function (who) {
        this.queryParams['owner'] = who;
        this.trigger('change:filter:dashboard', {owner: who});
    },

    setFilter: function (attr, val) {
        var attrs = {};

        if (typeof attr === 'string') {
            attrs[attr] = val;
        } else if (_.isObject(attr)) {
            attrs = attr;
        }

        _.each(ALLOWED_FILTER_ATTRS, function (attr) {
            if (!_.isEmpty(attrs[attr])) {
                this.queryParams[attr] = attrs[attr];
            } else {
                delete this.queryParams[attr];
            }
        }, this);

        this.trigger('change:filter:dashboard', attrs);
    },

    /**
     * Simple search only accept a single value
     */
    doSimpleSearch: function (val, fire) {
        this.resetSimpleSearch(false);

        if (/^[123456789]\d{2,4}$/.test(val)) {
            // id search
            var id = _.parseInt(val);
            this.queryParams['id'] = id;
        } else {
            this.queryParams['search']= val;
        }

        globalCh.command('save-filter', 'simple-search', _.pick(this.queryParams, ['id', 'search']));

        if (fire) {
            this.trigger('change:simple-search:dashboard', val);
        }
    },

    resetSimpleSearch: function (fire) {
        delete this.queryParams['search'];
        delete this.queryParams['id'];

        globalCh.command('save-filter', 'simple-search', {});

        if (fire) {
            this.trigger('change:simple-search:dashboard', '');
        }
    },

    /**
     * Advance search accept an object of values
     */
    doAdvanceSearch: function (obj, fire) {
        this.resetAdvanceSearch(false);
        var o = _.pick(obj, ['category', 'status', 'creative_type']);
        if (o.category != null) {
            this.queryParams['category'] = o.category;
        }
        if (o.status != null) {
            this.queryParams['status'] = o.status;
        }

        if (o.creative_type!= null) {
            this.queryParams['creative_type'] = o.creative_type;
        }

        globalCh.command('save-filter', 'advance-search', _.pick(o, ['category', 'status', 'creative_type']));

        if (fire) {
            this.trigger('change:advance-search:dashboard', o);
        }
    },

    resetAdvanceSearch: function (fire) {
        delete this.queryParams['category'];
        delete this.queryParams['status'];
        delete this.queryParams['creative_type'];

        globalCh.command('save-filter', 'advance-search', {});

        if (fire) {
            this.trigger('change:advance-search:dashboard', {});
        }
    },

    /**
     * Filter accept an object of values
     */
    doFilter: function (obj, fire) {
        this.resetFilter(false);

        var o = _.pick(obj, ['owner']);
        if (o.owner != null) {
            this.queryParams['owner'] = o.owner;
        }

        globalCh.command('save-filter', 'filter', _.pick(o, 'owner'));

        if (fire) {
            this.trigger('change:filter:dashboard', o);
        }
    },

    resetFilter: function (fire) {
        delete this.queryParams['owner'];

        if (fire) {
            this.trigger('change:filter:dashboard', {});
        }
    },

    resetSearch: function (fire) {
        this.resetSimpleSearch(false);
        this.resetAdvanceSearch(false);

        globalCh.command('save-filter', 'simple-search', {});
        globalCh.command('save-filter', 'advance-search', {});

        if (fire) {
            this.trigger('reset:search:dashboard');
        }
    },

    getPaginatedUrl: function () {
        var state = _.clone(this.state);
        // change to 1 based indexing
        state.firstPage += 1;
        state.lastPage += 1;
        state.currentPage += 1;
        return this.url + '?' + $.param(state);
    },

    loadCombinedStats: function () {
        if (this.isEmpty()) {
            return;
        }
        var campaignIds = this.pluck('id');

        return Q($.ajax({
            type: 'GET',
            url: 'stats?keys=date&sort=date&campaigns=' + campaignIds.join(','),
            dataType: 'json'
        })).then(_.bind(this.distributeCombinedStats, this));
    },

    distributeCombinedStats: function (rawStats) {
        var statsHolder = {};

        _.each(rawStats, function (stat) {
            var campaignKey = 'campaign-' + stat.campaign;
            if (_.isEmpty(statsHolder[campaignKey])) {
                statsHolder[campaignKey] = [];
            }
            statsHolder[campaignKey].push(stat);
        });

        _.each(statsHolder, function (stats, key) {
            var campaignId = _.parseInt(key.replace('campaign-', ''));
            var campaign = this.get(campaignId);

            if (campaign) {
                campaign.stats.set(stats, {merge: true});
                campaign.trigger('stats:loaded');
            }

        }, this);

        return true;
    },

    countMatched: function (term) {
        return this.reduce(function (count, campaign) {
            return count + campaign.searchTerm(term);
        }, 0);
    },
    comparator: function (a,b) {
        return a.id > b.id ? -1 : 1;
    }
});
Campaigns.Campaign = Campaign;
module.exports = Campaigns;
