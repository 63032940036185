'use strict';

var fns = require('scripts/common/fns');
var formatMoney = fns.formatCurrency;
var formatNumber = fns.formatNumber;
var langCodes = require('scripts/common/constants/language-codes');
var appCategoryTargeting = require('scripts/common/constants/app-category-targeting');

function groupsAdReducer (ad, creative) {

    return [
        {
            title: 'Basics',
            items: [
                { name: 'Name', value: ad.name },
                { name: 'Start', value: `${formatDate(ad.start, ad.timezone)} ${formatTimezoneAbbr(ad.start, ad.timezone)}` },
                { name: 'End', value: `${formatDate(ad.end, ad.timezone)} ${formatTimezoneAbbr(ad.end, ad.timezone)}` },
                { name: 'Timezone', value: ad.timezone },
                { name: 'Notes', value: ad.notes }
            ]
        },
        {
            title: 'Budget',
            items: [
                { name: 'Max Bid', value: formatMoney(ad.max_bid_cpm) },
                { name: 'Impressions Daily Cap', value: formatNum_with0AsUnlimited(ad.max_daily_impressions) },
                { name: 'Impressions Total Target', value: formatNum_with0AsUnlimited(ad.max_total_impressions) },
                { name: 'Clicks Daily Cap', value: formatNum_with0AsUnlimited(ad.max_daily_clicks) },
                { name: 'Clicks Total Target', value: formatNum_with0AsUnlimited(ad.max_total_clicks) },
                { name: 'Spend Daily Cap', value: formatNum_with0AsUnlimited(ad.max_daily_spend) },
                { name: 'Spend Total Target', value: formatNum_with0AsUnlimited(ad.max_total_spend) }
            ]
        },
        {
            title: 'ROI',
            items: [
                { name: 'Billing Terms', value: ad.billing_terms === 'PPM' ? 'CPM' : 'CPC' },
                { name: 'Billing Rate', value: formatMoney(ad.billing_rate) }
            ]
        },
        {
            title: 'Geotargeting',
            items: [
                { name: 'Geotargeting', value: formatGeoCountry(ad.geotargets) }
            ]
        },
        {
            title: 'Demographics',
            items: [
                { name: 'Age Groups', value: formatAgeGroup(ad.target_age_groups) },
                { name: 'Gender', value: formatTargetGenders(ad.target_genders ) }
            ]
        },
        {
            title: 'Advanced',
            items: [
                { name: 'User Language', value: formatDeviceLanguage(ad.target_device_language) },
                { name: 'Operating System', value: objectPath.get(ad, 'target_device_os', []).join(',') },
                { name: 'Dayparting', value: formatDayparts(ad.dayparts, ad.timezone) },
                { name: 'Weekparting', value: formatWeekparts(ad.weekparts) },
                { name: 'Geofencing', value: formatGeoBoxes(ad.geoboxes) },
                { name: 'User Frequency', value: ad.max_user_frequency },
                { name: 'App Categories', value: formatTargetAppCats(ad.target_app_categories) }
            ]
        },
        {
            title: 'Creative',
            items: [
                { name: 'Creative', value: creative.name }
            ]
        }
    ];
}

function formatGeoCountry(geotargets) {

    function _formatGeoCountry (geotarget) {
        var html = '', content, tpl, countryName = fns.getCountryName(geotarget.country);

        var includes, excludes;
        if (!_.isEmpty(geotarget.include)) {
            tpl =   '<div class="regions">' +
            '<div><span class="label label-success">i</span></div><div title="<%=content%>"><%=content%></div>' +
            '</div>';

            content = _.compact(_.map(geotarget.include, _.partial(fns.formatGeoRegionCity, geotarget.country))).join(', ');
            includes = (
                <div className="regions" key={_.uniqueId()}>
                    <div>
                        <span className="label label-success">i</span>
                    </div>
                    <div title={content}>
                        {content}
                    </div>
                </div>

            );
        }

        if (!_.isEmpty(geotarget.exclude)) {
            tpl =   '<div class="regions">' +
            '<div><span class="label label-danger">e</span></div><div title="<%=content%>"><%=content%></div>' +
            '</div>';

            content = _.compact(_.map(geotarget.exclude, _.partial(fns.formatGeoRegionCity, geotarget.country))).join(', ');
            excludes = (
                <div className="regions">
                    <div>
                        <span className="label label-danger">e</span>
                    </div>
                    <div title={content}>
                        {content}
                    </div>
                </div>

            );
        }

        return (
            <li key={_.uniqueId()}>
                <div>
                    <u>{countryName}</u>
                </div>
            {includes}
            {excludes}
            </li>
        );
    }

    return (
        <ul>
            {geotargets.map(_formatGeoCountry)}
        </ul>
    );
}

function formatGeoBoxes(obj) {
    return obj.map(function (box) {
        var shorter = {};
        _.each(box, function (val, key) {
            shorter[key] = val.toFixed(2);
        });
        return <div key={_.uniqueId()}>E: {shorter.E}, W: {shorter.W}, S: {shorter.S}, N: {shorter.N}</div>;
    });
}

function formatAgeGroup(val) {
    if (val.length === 0) {
        return 'Everyone';
    } else {
        var displayTextArray = [];
        _.forEach(val, function(item){
            switch (item) {
                case 1:
                    displayTextArray.push( '13-17' );
                break;
                case 2:
                    displayTextArray.push( '18-24' );
                break;
                case 3:
                    displayTextArray.push( '25-34' );
                break;
                case 4:
                    displayTextArray.push( '35+' );
                break;
                case -1:
                    displayTextArray.push( 'Unknown' );
                break;
                default:
                    throw new Error( 'Age group array is not empthy and contains unregonize value');
            }
        });

        return displayTextArray.join(', ');
    }// End else
}

function formatDeviceLanguage(obj) {
    return _.isEmpty(obj)? 'Any language':langCodes[obj];
}

function formatTargetGenders(val) {
    if (_.isArray(val)) {
        if (val.length === 0 ) {
            return 'Everyone';
        } else {
            var displayTextArray = [];
            _.forEach(val, function(item){
                if (item === 'M') {
                    displayTextArray.push( 'Male' );
                } else if (item === 'F') {
                    displayTextArray.push( 'Female' );
                } else if (item === 'D') {
                    displayTextArray.push( 'Unknown' );
                } else {
                    throw new Error( 'Gender array is not empthy and contains unregonize value');
                }
            });

            return displayTextArray.join(', ');
        } // End else
    }
}

function formatTargetAppCats(obj) {
    return _.isArray(obj)? _.map(obj, function(i) { return appCategoryTargeting[i];}).join(', '):'';
}

function formatDayparts(obj, timezone) {
    if (_.isEmpty(obj)) {
        return 'Full day';
    } else {
        var obj2 = fns.convertDaypartsByTimezone(obj, 'UTC', timezone);
        var obj3 = fns.mergeTimeranges(obj2);

        var dayparts = _.map(obj3, function (part) {
            var tz = moment().tz(timezone).format('z');

            return (
                <li key={_.uniqueId()}>{part.start}
                    <span data-abbr="timezone">{tz}</span>
                    ~{part.end}
                    <span data-abbr="timezone">{tz}</span>
                </li>
            );
        });
        return <ul className="list-unstyled">{dayparts}</ul>;
    }
}

function formatWeekparts(obj) {
    var names = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    return _.compact(_.map(obj, function (bool, idx) {return bool ? names[idx] : '';})).join(', ');
}

function formatDate(obj, timezone) {
    return moment(obj).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
}

function formatTimezoneAbbr(date, timezone) {
    return moment(date).tz(timezone).format('z');
}


function formatNum_with0AsUnlimited (val) {
    if (val !== 0) {
        return fns.formatNumber(val, 'thousands');
    } else {
        return 'Unlimited';
    }
}


module.exports = groupsAdReducer;