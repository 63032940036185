<div class="col-xs-12 text-right margin-vertical-10" style="margin-bottom: 10px;">
    <span style="margin-right: 10px;">View:</span>

    <div class="btn-group">
        <button title="By Ad" class="btn btn-default active" data-view="by-ad"><i class="fa fa-fw fa-sort-amount-desc"></i></button>
        <button title="By Group" class="btn btn-default" data-view="by-group"><i class="fa fa-fw fa-list"></i></button>
    </div>
</div>

<div class="traffic-sheet-content"></div>
