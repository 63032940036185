'use strict';

var env = require('scripts/env');


/**
 * Loader behavior
 *
 * Bound view should have a `model` attribute to have laoder behavior
 *
 */

var Loader = Mario.Behavior.extend({

    onShow: function () {

        if (!this.view.model) {
            return;
        }

        var that = this.view;
        var postfix = '.loader' + this.cid;

        var modelUrl = S(env.ADSUSHI_API_BASE + _.result(that.model, 'urlRoot')).chompRight('/');

        // remove previously bound events
        $(document).off(postfix);

        // bind ajax listener
        $(document).on('ajaxSend' + postfix, function (ev, jqXHR, settings) {

            if (S(settings.url).contains(modelUrl)) {

                // disable all input, textarea etc.
                that.$('[data-id]:enabled').prop('disabled', true);

                // disable submit button and replace icon with spin
                that.$('[data-action=save] i').toggleClass('fa-floppy-o fa-spinner fa-spin');

                // disabled all event listeners
                that.undelegateEvents();

                jqXHR.always(function () {

                    // restore everything back
                    that.$('[data-id]:disabled').prop('disabled', false);

                    that.$('[data-action=save] i').toggleClass('fa-floppy-o fa-spinner fa-spin');

                    that.delegateEvents();
                });
            }
        });
    }
});

window.Behaviors.Loader = Loader;
